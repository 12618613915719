import React from "react";
import { useGoToUrl } from "@app/hooks/front";
import Styles from "./styles/tvschool-classroom.module.css";
import TimeTableImage from "./styles/imgs/Schedule_Illustration.svg";

export const Timetable = () => {
	const onClick = useGoToUrl("/t/timetable");
	return (
		<section
			className={Styles.sendTestToNewClass}
			onClick={onClick}
			style={{ backgroundColor: "white" }}
		>
			<img
				alt="timetable"
				src={TimeTableImage}
				className={Styles.sendCognitiveAssignmentBuld}
			/>
			<span className={Styles.sendCognitiveTitle}>ჩემი ცხრილი</span>
		</section>
	);
};
