import { ITaskType } from "@app/api/task-types/helper-schemas";
import { BackgroundTwoWaves } from "@app/components/backgrounds";
import { DeepMultipleSelectWithSearch } from "@app/components/widgets/deep-multiple-select-wth-search";
import { useCourseInfo } from "@app/hooks/courses";
import { useBoolean } from "@app/hooks/general";
import { useItemsAndHierarchyWithoutRoot } from "@app/hooks/hierarchies";
import { useAllTaskTypesByCourse } from "@app/hooks/task-types";
import { useTopicsAndHierarchyInfo } from "@app/hooks/topics";
import { ObjectId } from "@app/utils/generics";
import React, { useState, useCallback } from "react";
import { FullCircularProgress } from "../../widgets/circular-progress-bar";
import FancyLoading from "../../widgets/fancy-loading";
import Popup, { PopupContentWithClose } from "../../widgets/popup";
import { ReactComponent as Settings } from "../styles/img/settings.svg";
import styles from "./styles/exercise-page.module.css";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { ReactComponent as NoExerciseFolder } from "./styles/img/no-exercise.svg";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import classNames from "classnames";
import { useUserTaskTypeLevels } from "@app/hooks/user-task-type-level";
import { useCoursesUserId } from "@app/hooks/users";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ReactComponent as HotsaleLogo } from "./styles/img/hotsale.svg";
import { useLocale } from "@app/hooks/intl";
import hotsale from "./styles/img/hotsale.svg";
import { isTaskTypeFinancedByHotsale } from "../tests/sponsor-helpers";
import { NewDesktopAd3, NewMobileAd } from "@app/components/dev/ads";

export const ExercisesPage: React.FC<{ courseId: ObjectId }> = React.memo(
	// eslint-disable-next-line max-lines-per-function
	props => {
		const { courseId } = props;
		const courseInfo = useCourseInfo(courseId);
		const coursesUserId = useCoursesUserId();
		const userTaskTypeLevels = useUserTaskTypeLevels(
			coursesUserId || null,
			courseId
		);
		const rootTaskType = courseInfo.doc
			? courseInfo.doc.rootTaskType
			: null;

		const allTaskTypes = useAllTaskTypesByCourse(props.courseId);

		const { width } = useWindowSize();
		const isMobile = width < 600;

		const history = useHistory();

		const redirectToMainPage = useCallback(() => {
			history.goBack();
		}, [history]);

		if (!allTaskTypes) return null;
		const fisrtHotsaleFinancedTaskTypeId = allTaskTypes.find(
			t => t._id === "5df4ad330695762d8db1ab49"
		);
		const secondHotsaleFinancedTaskTypeId = allTaskTypes.find(
			t => t._id === "5df4ad1b0695762d8db1ab48"
		);
		const secondElement = allTaskTypes[1];
		const thirdElement = allTaskTypes[2];
		if (secondHotsaleFinancedTaskTypeId && fisrtHotsaleFinancedTaskTypeId) {
			const fisrtHotsaleFinancedTaskTypeIdIndex = allTaskTypes.findIndex(
				t => t._id === "5df4ad330695762d8db1ab49"
			);
			const secondHotsaleFinancedTaskTypeIdIndex = allTaskTypes.findIndex(
				t => t._id === "5df4ad1b0695762d8db1ab48"
			);
			allTaskTypes[1] = allTaskTypes[fisrtHotsaleFinancedTaskTypeIdIndex];
			allTaskTypes[fisrtHotsaleFinancedTaskTypeIdIndex] = secondElement;

			allTaskTypes[2] =
				allTaskTypes[secondHotsaleFinancedTaskTypeIdIndex];
			allTaskTypes[secondHotsaleFinancedTaskTypeIdIndex] = thirdElement;
		}

		return (
			<div className={styles.exercisePageMainDiv}>
				<BackgroundTwoWaves />
				<div className={styles.contentDiv}>
					<span
						className={styles.exercisePageHeader}
						onClick={isMobile ? redirectToMainPage : () => {}}
					>
						{!isMobile && (
							<NewDesktopAd3 style={{ marginBottom: 10 }} />
						)}
						{isMobile && <ArrowLeftIcon style={{ fontSize: 34 }} />}
						<FormattedMessage id="student:excercisesPageTitle" />
						<br />
						{!isMobile && (
							<NewDesktopAd3 style={{ marginTop: 10 }} />
						)}
					</span>
					{isMobile && <NewMobileAd style={{}} />}
					<div className={styles.exerciseTypesDiv}>
						{allTaskTypes &&
							userTaskTypeLevels.isSuccessfullyLoaded &&
							courseInfo.isSuccessfullyLoaded &&
							allTaskTypes.map((taskType, i) => {
								if (taskType._id === rootTaskType) {
									return null;
								}
								const levelPercentage = Math.round(
									userTaskTypeLevels.doc.getTotalLevel(
										taskType._id,
										0
									) * 100
								);
								return (
									<TaskType
										key={taskType._id}
										taskType={taskType}
										levelPercentage={levelPercentage}
										courseId={courseId}
									/>
								);
							})}
					</div>
				</div>
			</div>
		);
	}
);

export const EmptyExercisesContainer: React.FC<{
	courseId: ObjectId;
}> = props => {
	const history = useHistory();
	const mainText = getFormattedMessage(
		"student:excercisesNotEnoughQuestionsWarning"
	);
	const buttonText = getFormattedMessage(
		"student:excercisesNotEnoughQuestionsCTA"
	);
	const gotoAllExercises = useCallback(() => {
		if (!props.courseId) return;
		history.push(`/courses/${props.courseId}/lessons`);
	}, [history, props.courseId]);

	return (
		<EmptyFolder
			buttonText={buttonText}
			onClick={gotoAllExercises}
			mainText={mainText}
		/>
	);
};

export const EmptyFolder: React.FC<{
	mainText: string;
	buttonText: string;
	secondButtonText?: string;
	mainTextDividedParts?: string[];
	onClick: () => void;
	onSecondButtonClick?: () => void;
	illustration?: JSX.Element;
	textColor?: string;
	textClassname?: string;
	secondText?: string;
	secondTextClassname?: string;
	buttonClassname?: string;
	icon?: JSX.Element;
	highlitedTextPartsIndex?: number;
}> = props => {
	return (
		<div className={styles.exerciseAndText}>
			<div
				className={styles.noExerciseText}
				style={
					props.textColor
						? { color: props.textColor }
						: { color: "#696969" }
				}
			>
				{props.mainTextDividedParts && (
					<div
						className={
							props.textClassname
								? classNames(
										styles.mainText,
										props.textClassname
								  )
								: styles.mainText
						}
					>
						{props.mainTextDividedParts.map((title, index) => (
							<div
								className={
									props.highlitedTextPartsIndex &&
									index === props.highlitedTextPartsIndex
										? classNames(styles.highlited)
										: styles.dividedParts
								}
							>
								{title}
							</div>
						))}
					</div>
				)}

				{!props.mainTextDividedParts && (
					<span
						className={
							props.textClassname
								? classNames(
										styles.mainText,
										props.textClassname
								  )
								: styles.mainText
						}
					>
						{props.mainText}
					</span>
				)}
				{props.secondText && (
					<span
						className={
							props.secondTextClassname
								? classNames(
										styles.secondText,
										props.secondTextClassname
								  )
								: styles.secondText
						}
					>
						{props.secondText}
					</span>
				)}
				<div className={styles.emptyFolderButtonsContainer}>
					<MainButton
						onClick={props.onClick}
						icon={props.icon}
						buttonClassname={props.buttonClassname}
						buttonText={props.buttonText}
					/>
					{props.secondButtonText && props.onSecondButtonClick && (
						<MainButton
							onClick={props.onSecondButtonClick}
							icon={props.icon}
							buttonClassname={props.buttonClassname}
							buttonText={props.secondButtonText}
						/>
					)}
				</div>
			</div>
			{props.illustration ? (
				<div>{props.illustration}</div>
			) : (
				<NoExerciseFolder className={styles.noExerciseIllustration} />
			)}
		</div>
	);
};

export const MainButton: React.FC<{
	buttonText: string;
	onClick: () => void;
	buttonClassname?: string;
	icon?: JSX.Element;
}> = props => {
	return (
		<button
			className={
				props.buttonClassname
					? props.buttonClassname
					: styles.startExercising
			}
			onClick={props.onClick}
		>
			<div>{props.buttonText}</div>
			{props.icon && props.icon}
		</button>
	);
};

interface ITaskTypeProps {
	taskType: ITaskType;
	levelPercentage: number;
	courseId: ObjectId;
}

const popupStyles = {
	width: 800,
	maxWidth: "90%",
	display: "block",
};

const TaskType: React.FC<ITaskTypeProps> = React.memo(props => {
	const history = useHistory();
	const locale = useLocale();
	const {
		value: isPopupOpen,
		setFalse: closePopup,
		setTrue: openPopup,
	} = useBoolean(false);

	const handlePopupOpen = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			openPopup();
		},
		[openPopup]
	);

	const isFinancedByHotsale = isTaskTypeFinancedByHotsale(props.taskType._id);

	let color: [string, string];
	if (isFinancedByHotsale) {
		color = ["#4373ff", "#4373ff"];
	} else if (props.levelPercentage <= 33) {
		color = ["#cc679b", "#ff6e6e"];
	} else if (props.levelPercentage > 33 && props.levelPercentage <= 66) {
		color = ["#ff927c", "#ffb569"];
	} else {
		color = ["#37e0a3", "#00d492"];
	}

	const taskTypeId = props.taskType._id;

	const handleRedirectionToTest = useCallback(() => {
		history.push(
			getLinkOfTaskTypetest({ courseId: props.courseId, taskTypeId })
		);
	}, [history, props.courseId, taskTypeId]);

	const showIcons = true;

	const { width } = useWindowSize();
	const isMobile = width < 600;
	let logo: JSX.Element = <></>;
	if (isFinancedByHotsale && locale === "ka") {
		logo = (
			<div className={styles.hotSaleLogoWithText}>
				<HotsaleLogo className={styles.hotSaleLogo} />
				<div>HOTSALE</div>
			</div>
		);
	}

	return (
		<>
			<div
				className={styles.exerciseType}
				onClick={handleRedirectionToTest}
			>
				<div className={styles.logoAndSettingsIcon}>
					{!isMobile && logo}

					<Settings
						className={styles.settingsIcon}
						onClick={handlePopupOpen}
					/>
					{isMobile && logo}
				</div>
				<FullCircularProgress
					progress={props.levelPercentage}
					progressStrokeColor={color}
					size={width > 450 ? 120 : 75}
					radius={width > 450 ? 54 : 31}
				>
					<div
						className={styles.iconBackground}
						style={
							isFinancedByHotsale
								? {
										backgroundColor: "white",
										boxShadow:
											"0 0 10px 0 rgba(0, 0, 0, 0.15)",
								  }
								: {
										backgroundColor: color[1],
								  }
						}
					>
						{showIcons &&
							props.taskType.photo &&
							isFinancedByHotsale && (
								<div
									className={styles.iconImg}
									style={{
										backgroundImage: `url(${hotsale})`,
									}}
								/>
							)}
						{showIcons &&
							props.taskType.photo &&
							!isFinancedByHotsale && (
								<div
									className={styles.iconImg}
									style={{
										backgroundImage: `url(${props.taskType.photo})`,
									}}
								/>
							)}
					</div>
				</FullCircularProgress>
				{isMobile ? (
					<div className={styles.textsContainer}>
						<div className={styles.texts}>
							<button className={styles.button}>
								{props.taskType.name}
							</button>
							<div
								className={
									styles.excerciseProgressMainTextInMobile
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<div className={styles.progressNumber}>
										{props.levelPercentage / 10}
									</div>
									/<div>10</div>
								</div>
							</div>
						</div>
						<ArrowRightIcon
							style={{ fontSize: 30, color: "#2437b4" }}
						/>
					</div>
				) : (
					<>
						<span
							className={styles.excerciseProgress}
							style={{ color: color[1] }}
						>
							{props.levelPercentage / 10}
						</span>
						<button
							className={
								isFinancedByHotsale
									? classNames(styles.hotSaleButton)
									: styles.button
							}
						>
							{props.taskType.name}
						</button>
					</>
				)}
			</div>
			{isPopupOpen && (
				<Popup onClose={closePopup}>
					<PopupContentWithClose
						onClose={closePopup}
						style={popupStyles}
					>
						<SelectTopicsAndStartTest
							buttonClassName={styles.startButton}
							dropdownClassName={styles.dropdown}
							courseId={props.courseId}
							taskTypeId={taskTypeId}
						/>
					</PopupContentWithClose>
				</Popup>
			)}
		</>
	);
});

const SelectTopicsAndStartTest: React.FC<{
	courseId: ObjectId;
	taskTypeId: ObjectId;
	buttonClassName?: string;
	dropdownClassName?: string;
}> = props => {
	const history = useHistory();
	const { topics, topicsHierarchyInfo } = useTopicsAndHierarchyInfo(
		props.courseId
	);
	const data = useItemsAndHierarchyWithoutRoot(
		topics || null,
		topicsHierarchyInfo || null
	);
	const [checkedTopics, setCheckedTopics] = useState([] as ObjectId[]);

	const onStart = useCallback(() => {
		if (!checkedTopics || !checkedTopics.length) return;
		history.push(
			getLinkOfTaskTypetest({
				courseId: props.courseId,
				taskTypeId: props.taskTypeId,
				topics: checkedTopics,
			})
		);
	}, [checkedTopics, history, props.courseId, props.taskTypeId]);

	if (!topics || !topicsHierarchyInfo || !data) return <FancyLoading />;
	return (
		<div>
			<DeepMultipleSelectWithSearch
				items={data.items}
				hierarchyInfo={data.hierarchyInfo}
				defaultSelectedItemIds={checkedTopics}
				onChange={setCheckedTopics}
				placeholder={getFormattedMessage("chooseTopics")}
				className={props.dropdownClassName}
			/>
			<StartButton
				buttonClassName={props.buttonClassName}
				onClick={onStart}
				isDisabled={checkedTopics.length === 0}
			/>
		</div>
	);
};

const StartButton: React.FC<{
	onClick: () => void;
	isDisabled: boolean;
	buttonClassName?: string;
}> = React.memo(props => {
	return (
		<div>
			<button
				className={props.buttonClassName}
				disabled={props.isDisabled}
				onClick={props.onClick}
			>
				<span>
					<FormattedMessage id="student:excercisesTopicsTestStartCTA" />
				</span>
			</button>
		</div>
	);
});

const getLinkOfTaskTypetest = ({
	courseId,
	taskTypeId,
	topics,
}: {
	courseId: ObjectId;
	taskTypeId: ObjectId;
	topics?: ObjectId[];
}): string => {
	let additional = "";
	if (topics && topics.length > 0) {
		additional = "&topicIds=" + topics.join(",");
	}
	return `/courses/${courseId}/task-type-tests?taskTypeId=${taskTypeId}${additional}`;
};
