import React from "react";
import Popup, { PopupContentWithClose } from "../widgets/popup";
import styles from "./styles/tv-school-landing.module.css";
import { ReactComponent as CloseIcon } from "./styles/imgs/close.svg";
import { FormattedMessage } from "react-intl";

export const VideoInstruction: React.FC<{
	onClose: () => void;
	videoId?: string;
}> = React.memo(({ onClose, videoId }) => {
	const closeIcon = (
		<div className={styles.closeIconWithTextContainer}>
			<FormattedMessage id="student:prodTour.close" />
			<CloseIcon className={styles.closeIcon} />
		</div>
	);
	return (
		<div>
			<Popup onClose={onClose}>
				<PopupContentWithClose
					onClose={onClose}
					icon={closeIcon}
					popupBodyClassname={styles.popupBodyClassname}
					className={styles.popup}
				>
					<iframe
						className={styles.videoContainer}
						allowTransparency={true}
						frameBorder={0}
						style={{ backgroundColor: "white" }}
						allow="autoplay"
						src={
							videoId
								? `https://www.youtube.com/embed/${videoId}?rel=0;autoplay=1&mute=0&enablejsapi=1;playlist=${videoId}&loop=1;allow='autoplay'`
								: 'https://www.youtube.com/embed/zTIoUoGzzqE?rel=0;autoplay=1&mute=0&transparent=5&enablejsapi=1;playlist=zTIoUoGzzqE&loop=1;allow="autoplay"'
						}
					></iframe>
				</PopupContentWithClose>
			</Popup>
		</div>
	);
});
