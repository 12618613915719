import React from "react";
import { UserType } from "@app/api/helper-schemas";
import { WebsiteOrigin } from "@app/globals";
import UserRoute from "@app/routes/containers/user-route";
import { createRouteList } from "../config";
import { studentLinks } from "./links";

const commonStudentRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.student,
	},
});

const hubuStudentRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.student,
		availableWebsite: WebsiteOrigin.hubu,
	},
});

const hubuExamsRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.student,
		availableWebsite: WebsiteOrigin.hubuExams,
	},
});

const tvSchoolStudentRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.student,
		availableWebsite: WebsiteOrigin.tvSchool,
	},
});

///
const Lessons = React.lazy(() =>
	import("../../components/users/lessons/lessons")
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/lessons",
	component: Lessons,
});

///
const StudentCourseMainPage = React.lazy(() =>
	import("../../components/users/main").then(module => ({
		default: module.StudentCourseMainPage,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId",
	component: StudentCourseMainPage,
});

///
const LessonUnitPage = React.lazy(() =>
	import("../../components/users/lessons/lesson-unit").then(module => ({
		default: module.LessonUnitPage,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/lessons/:folderId/lesson-and-test",
	component: LessonUnitPage,
});

///
const StudentAssignmentsPage = React.lazy(() =>
	import("../../components/users/assignments").then(module => ({
		default: module.StudentAssignmentsPage,
	}))
);

commonStudentRoutes.add({
	path: "/courses/:courseId/assignments",
	component: StudentAssignmentsPage,
});

///
const MyAssignments = React.lazy(() =>
	import("../../components/users/assignments/tv-school/assignments").then(
		module => ({
			default: module.MyAssignments,
		})
	)
);

commonStudentRoutes.add({
	path: "/courses/:courseId/my-assignments",
	component: MyAssignments,
});

///
const ExercisesPage = React.lazy(() =>
	import("../../components/users/exercises").then(module => ({
		default: module.ExercisesPage,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/exercises",
	component: ExercisesPage,
});

///
const TaskTypeTestLoader = React.lazy(() =>
	import("../../components/users/tests/task-types").then(module => ({
		default: module.TaskTypeTestLoader,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/task-type-tests",
	component: TaskTypeTestLoader,
});

///
const FetchStudentAssignment = React.lazy(() =>
	import("../../components/users/tests/assignment").then(module => ({
		default: module.FetchStudentAssignment,
	}))
);

commonStudentRoutes.add({
	path: "/courses/:courseId/assignments/:assignmentId",
	component: FetchStudentAssignment,
});

///
const FolderTestLoader = React.lazy(() =>
	import("../../components/users/tests/test-loader-wrapper").then(module => ({
		default: module.FolderTestLoader,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/folders/:folderId/tests/:testId",
	component: FolderTestLoader,
});

///
const FileReadingLoader = React.lazy(() =>
	import("../../components/users/reading-files/loader").then(module => ({
		default: module.FileReadingLoader,
	}))
);

hubuStudentRoutes.add({
	path: "/courses/:courseId/folders/:folderId/files/:fileId",
	component: FileReadingLoader,
});

///
const Orientation = React.lazy(() =>
	import("../../components/prof-orientation").then(module => ({
		default: module.Orientation,
	}))
);

hubuStudentRoutes.add({
	path: "/orientation-main-page",
	component: Orientation,
});

///
const ProfTest = React.lazy(() =>
	import("../../components/users/tests/prof-orientation").then(module => ({
		default: module.ProfTest,
	}))
);

hubuStudentRoutes.add({
	path: "/prof-test",
	component: ProfTest,
});

///

const FacebookVideosPage = React.lazy(() =>
	import("../../components/users/facebook-videos").then(module => ({
		default: module.FacebookVideosPage,
	}))
);

hubuStudentRoutes.add({
	path: "/fb-videos",
	component: FacebookVideosPage,
});

///

const StudentGradebook = React.lazy(() =>
	import("../../components/users/gardebook").then(module => ({
		default: module.StudentGradebook,
	}))
);

tvSchoolStudentRoutes.add({
	path: "/courses/:courseId/gradebook",
	component: StudentGradebook,
	routeProps: {
		availableWebsite: WebsiteOrigin.tvSchool,
	},
});

///
const MyCognitiveTestsContainer = React.lazy(() =>
	import("../../components/users/cognitive-tests/my-tests-container").then(
		module => ({
			default: module.MyCognitiveTestsContainer,
		})
	)
);

tvSchoolStudentRoutes.add({
	path: studentLinks.cognitiveTests,
	component: MyCognitiveTestsContainer,
});

///

const OffersPage = React.lazy(() => import("../../components/users/offers"));

tvSchoolStudentRoutes.add({
	path: "/student-offers",
	component: OffersPage,
});

///
const StudentPersonalTimetablePage = React.lazy(() =>
	import("../../components/users/timetables").then(module => ({
		default: module.StudentPersonalTimetablePage,
	}))
);

tvSchoolStudentRoutes.add({
	path: "/s/timetable",
	component: StudentPersonalTimetablePage,
});

const HubuExamsMainPage = React.lazy(() =>
	import("../../components/users/hubu-exams-main-page").then(module => ({
		default: module.default,
	}))
);
hubuExamsRoutes.add({
	path: "/courses",
	component: HubuExamsMainPage,
});

const HubuExamsSubjectPage = React.lazy(() =>
	import("../../components/users/hubu-exams-subject-page").then(module => ({
		default: module.default,
	}))
);
hubuExamsRoutes.add({
	path: "/subject/:subjectId/",
	component: HubuExamsSubjectPage,
});

const studentRoutes = commonStudentRoutes
	.toArray()
	.concat(tvSchoolStudentRoutes.toArray())
	.concat(hubuStudentRoutes.toArray())
	.concat(hubuExamsRoutes.toArray());

export default studentRoutes;
