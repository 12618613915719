import LandingFooter from "@app/components/landing/landingFooter";
import {
	animateWindowScroll,
	getHTMLElementCoords,
} from "@tests-core/utils/dom";
import React, { useEffect, useRef, useState } from "react";
import { FaqContainer } from "../widgets/faq";
import { ReactComponent as ScrollDown } from "./styles/imgs/scroll-down.svg";
import styles from "./styles/tv-school-landing.module.css";
import { DivReference, TVSchoolHeader } from "./tv-school-header";
import { Role } from "./role-test";

export const TVSchoolLandingTest: React.FC<{}> = props => {
	const faqRef = useRef<HTMLDivElement | null>(null);
	const [isLoginFieldVissible, setLoginFieldVissible] = useState(true);
	const [isRegistrationFieldVissible, setRegistrationVissible] = useState(
		false
	);

	useEffect(() => {
		let hasScrolled = false;

		const scrollTofaq = (e: WheelEvent, ref: DivReference) => {
			if (e.deltaY <= 0) {
				// scrolling up
				return;
			}
			if (!ref) return;
			if (!ref.current) return;
			const coordinates = getHTMLElementCoords(ref.current);

			if (
				Math.floor(window.pageYOffset) > 0 &&
				Math.floor(window.pageYOffset) < 80
			) {
				hasScrolled = false;

				window.addEventListener("mouseup", e => {
					hasScrolled = true;
					window.removeEventListener("mousedown", () => {
						hasScrolled = false;
					});
				});
				window.addEventListener("mousedown", e => {
					hasScrolled = false;
					window.removeEventListener("mouseup", () => {
						hasScrolled = true;
					});
				});
			}

			if (hasScrolled) {
				return;
			} else {
				setTimeout(() => {
					animateWindowScroll(coordinates.top - 90, 300);
					hasScrolled = true;
				}, 300);
			}
		};

		const fn = (e: WheelEvent) => scrollTofaq(e, faqRef);

		window.addEventListener("wheel", fn);

		return () => window.removeEventListener("wheel", fn);
	}, []);

	return (
		<div>
			<TVSchoolHeader
				faqRef={faqRef}
				openLoginPage={setLoginFieldVissible}
				openRegistarionPage={setRegistrationVissible}
			/>
			<Role
				isLoginFieldVissible={isLoginFieldVissible}
				isRegistrationField={isRegistrationFieldVissible}
				openLoginPage={setLoginFieldVissible}
				openRegistarionPage={setRegistrationVissible}
			/>
			{!isRegistrationFieldVissible && (
				<div className={styles.scroollArrowContainer}>
					<ScrollDown style={{ width: "20px" }} />
				</div>
			)}

			<div ref={faqRef}>
				<FaqContainer isOnlyTitleWithoutHalfMoon={true} />
			</div>
			<LandingFooter />
		</div>
	);
};
