/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-max-depth */
import React from "react";
import { NewDesignBackground } from "@app/components/backgrounds";
import { GeneralContext } from "@app/components/general-context";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { ReactComponent as TVSchoolNL } from "@app/components/tv-school-landing/styles/imgs/tv-school-nl.svg";
import HeaderStyles from "../../users/header/styles/header.module.css";
import { IAnyObj } from "@app/utils/generics";
import classNames from "classnames";
import LogOutComponent from "@app/components/widgets/LogOutComponent";
import { useWindowSize, useGoToUrl } from "@app/hooks/front";
import user from "@app/redux/reducers/user";
import { useClassroomsUser } from "@app/hooks/users";
import { useSchoolById } from "@app/hooks/cities-and-schools";

export const HeadmasterHeader: React.FC<IAnyObj> = props => {
	const { width } = useWindowSize();
	const user = useClassroomsUser()!;
	const school = useSchoolById(user.school);
	const isMobile = width < 600;
	const gotoMainPage = useGoToUrl("/");

	return (
		<>
			<NewDesignBackground />
			<div
				className={HeaderStyles.container}
				style={{
					padding: "10px 30px",
					backgroundColor:
						props.backgroundColor || "rgba(239, 241, 247)",
				}}
			>
				<div className={HeaderStyles.coursesAndLogoContainer}>
					<div className={HeaderStyles.logo} onClick={gotoMainPage}>
						<TVSchoolLogo
							style={{
								height: "100%",
								width: 112,
								color: "#5273e6",
							}}
						/>
					</div>
					<div className={HeaderStyles.schoolContainer}>
						{`| ${school.doc?.name}`}
					</div>
				</div>

				<div className={HeaderStyles.faqAndOptionsContainer}>
					<div className={HeaderStyles.teacherInfo_wrapper}>
						<div
							style={{
								display: "inline-block",
								fontFamily: "Roboto Geo Caps",
								verticalAlign: "middle",
								textAlign: "right",
								color: "#626a84",
							}}
						>
							{/* <span style={{ fontSize: 12 }}>მოსავლე</span>
							<br /> */}
							<span style={{ fontSize: 15, fontWeight: "bold" }}>
								დირექცია
							</span>
						</div>

						<div
							className={
								width > 1024
									? HeaderStyles.teacherUserICon
									: classNames(HeaderStyles.teacherUserICon)
							}
							// onClick={width > 1200 ? onGoToProfile : undefined}
							style={{
								backgroundColor: "white",
								width: 50,
								height: 50,
								display: "inline-block",
								verticalAlign: "middle",
							}}
						>
							<img
								src="/imgs/SchoolAvatar.svg"
								alt="chools"
								className={HeaderStyles.iconImg}
							/>
						</div>
						<LogOutComponent />
					</div>
				</div>
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};
