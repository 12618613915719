interface IDataHolder {
	[id: string]: Promise<any> | undefined;
}

class PromisesKeeperAPI<
	IdType extends string | number,
	DOC extends {},
	ExtraInfo = any
> {
	private data: IDataHolder = {};
	private dataCounter: Record<string, number | undefined> = {};
	private cbForLoadingMany?: (
		docs: { id: IdType; extraInfo?: ExtraInfo }[]
	) => (Promise<DOC> | undefined)[];
	private dealyTimeInMilliseconds?: number;
	private callbackData: {
		id: IdType;
		extraInfo?: ExtraInfo;
		cb: (promise: Promise<DOC>) => void;
		loadOneCb: () => Promise<DOC>;
	}[] = [];
	tresholdForCallingIndividuals = 1;

	constructor(
		cbForLoadingMany: (
			docs: { id: IdType; extraInfo?: ExtraInfo }[]
		) => Promise<DOC>[],
		dealyTimeInMilliseconds: number
	);
	constructor();
	constructor(
		cbForLoadingMany?: (
			docs: { id: IdType; extraInfo?: ExtraInfo }[]
		) => Promise<DOC>[],
		dealyTimeInMilliseconds?: number
	) {
		this.cbForLoadingMany = cbForLoadingMany;
		this.dealyTimeInMilliseconds = dealyTimeInMilliseconds;
	}

	existsPromise(docId: IdType | symbol) {
		if (this.data[docId as string]) return true;
		return false;
	}

	getPromise(docId: IdType): Promise<DOC> | undefined;
	getPromise(docId: symbol): Promise<any> | undefined;
	getPromise(docId: IdType | symbol): Promise<any> | undefined {
		return this.data[docId as any];
	}

	async getOrSetPromise(
		docId: IdType,
		cb: () => Promise<DOC>,
		extraInfo?: ExtraInfo
	): Promise<DOC>;
	async getOrSetPromise<K>(
		docId: IdType,
		cb: () => Promise<DOC>,
		extraInfo: ExtraInfo | undefined,
		callLoadManyCallback?: true
	): Promise<DOC>;
	async getOrSetPromise<T>(symbol: symbol, cb: () => Promise<T>): Promise<T>;
	async getOrSetPromise<K>(
		docId: IdType | symbol,
		cb: () => Promise<any>,
		extraInfo?: ExtraInfo,
		callLoadManyCallback = true
	): Promise<any> {
		let promise = this.data[docId as string];
		if (promise) return promise;
		if (
			!this.cbForLoadingMany ||
			!callLoadManyCallback ||
			typeof docId === "symbol"
		) {
			promise = cb();
			this.setPromise(docId, promise as Promise<DOC>);
			return promise;
		}
		promise = this.addToLoadMany(
			docId as IdType,
			extraInfo as ExtraInfo,
			cb as () => Promise<DOC>
		);
		this.setPromise(docId, promise as Promise<DOC>);
		return promise;
	}

	setPromise(docId: IdType | symbol, promise: Promise<DOC>);
	setPromise(docId: symbol, promise: Promise<any>);
	setPromise(docId: IdType | symbol, promise: Promise<any>) {
		this.increaseAndGetNewDataCount(docId);
		promise
			.then(data => {
				this.clearPromise(docId as any);
			})
			.catch(e => {
				this.clearPromise(docId as any);
			});
		this.data[docId as string] = promise;
	}

	private clearPromise(docId: IdType | symbol) {
		const newCount = this.decreaseAndGetNewDataCount(docId);
		if (newCount <= 0) {
			delete this.data[docId as string];
		}
	}

	private decreaseAndGetNewDataCount(docId: IdType | symbol): number {
		const count = this.dataCounter[docId as string];
		if (count === undefined) return 0;
		if (count <= 1) {
			delete this.dataCounter[docId as string];
			return 0;
		}
		this.dataCounter[docId as string] = count - 1;
		return count - 1;
	}

	private increaseAndGetNewDataCount(docId: IdType | symbol): number {
		const newCount = (this.dataCounter[docId as string] || 0) + 1;
		this.dataCounter[docId as string] = newCount;
		return newCount;
	}

	private async addToLoadMany(
		docId: IdType,
		extraInfo: ExtraInfo,
		loadOneCb: () => Promise<DOC>
	): Promise<DOC> {
		this.increaseAndGetNewDataCount(docId);
		const shouldPlanCallback = this.callbackData.length === 0;
		if (shouldPlanCallback) {
			setTimeout(
				() => this.callLoadings(),
				this.dealyTimeInMilliseconds || 1
			);
		}
		return new Promise<DOC>(resolve => {
			this.callbackData.push({
				id: docId,
				extraInfo,
				cb: resolve,
				loadOneCb,
			});
		});
	}

	private async callLoadings() {
		if (
			this.callbackData.length > 0 &&
			this.callbackData.length <= this.tresholdForCallingIndividuals
		) {
			for (let i = 0; i < this.callbackData.length; ++i) {
				const cbData = this.callbackData[i];
				cbData.cb(cbData.loadOneCb());
				this.clearPromise(cbData.id);
			}
		} else if (
			this.callbackData.length > this.tresholdForCallingIndividuals
		) {
			const promises = this.cbForLoadingMany!(
				this.callbackData.map(e => ({
					id: e.id,
					extraInfo: e.extraInfo,
				}))
			);
			for (let i = 0; i < this.callbackData.length; ++i) {
				const cbData = this.callbackData[i];
				const myPromise = promises[i];
				cbData.cb(myPromise ? myPromise : cbData.loadOneCb());
				this.clearPromise(cbData.id);
			}
		}
		this.callbackData = [];
	}
}

export const getManyResources = <
	IdType extends string | number,
	DOC extends {},
	GetManyResult,
	Error,
	ExtraInfo = undefined
>(
	getMany: (
		docs: { id: IdType; extraInfo?: ExtraInfo }[]
	) => Promise<GetManyResult>,
	findOne: (
		data: GetManyResult,
		id: IdType,
		extraInfo?: ExtraInfo
	) => DOC | undefined,
	notFoundError?: (id: IdType) => Error
) => {
	return (docs: { id: IdType; extraInfo?: ExtraInfo }[]): Promise<DOC>[] => {
		const mainPromise = getMany(docs);
		const promises: Promise<DOC>[] = [];
		for (const doc of docs) {
			promises.push(
				mainPromise.then(data => {
					const myData = findOne(data, doc.id, doc.extraInfo);
					if (!myData) {
						if (notFoundError) {
							throw notFoundError(doc.id);
						}
						throw new Error("Not found");
					}
					return myData;
				})
			);
		}
		return promises;
	};
};

export { PromisesKeeperAPI };
