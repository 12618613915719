import {
	IAnsweredQuestion,
	IAssignmentSettings,
	ShowAnswersStandardTime,
	IStartedStudentAssignmentEdge,
} from "@app/api/assignments/helper-schemas";
import {
	IRGETPublicAssignmentByCode,
	IRPOSTSubmitPublicAssignment,
} from "@app/api/assignments/validators";
import { ItemType } from "@app/api/folders/helper-schemas";
import { TestTypeAnswersShowTime } from "@app/api/test-types/helper-schemas";
import {
	IRTest,
	IUserTestQuestionInfo,
	ITestSettingContentId,
} from "@app/api/tests/helper-schemas";
import { IRGETTestContents, IRGETUserTest } from "@app/api/tests/validators";
import { addLoader } from "@app/common-javascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import { ITestFinishArgs, display } from "@tests-core/components/tests";
import { History } from "history";
import memoizeOne from "memoize-one";
import React from "react";
import {
	suffleContentIdsForTest,
	sortContentIdsBySortedTestContentIds,
} from "../../tests/assignment/shuffle";
import {
	AssignmentTestWrapper,
	TestNavigationInnerItemComponent,
} from "../../tests/assignment/wrapper";
import { UserWAssignments } from "./helpers/utils";
import { User } from "@app/user";
import { UserType } from "@app/api/helper-schemas";
import { arrayToObject } from "@app/utils/common";
import { IInnerItemContainerProps } from "@tests-core/components/tests/navigation";
import { ReactComponent as TouLogo } from "./styles/imgs/tou-logo.svg";
import { getLocale } from "@app/hooks/intl";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import testStyles from "./styles/test-styles.module.css";
import { getGradableItemsByEditor } from "@tests-core/components/questions/contents/grading";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";

interface PublicAssignmentTestProps {
	assignmentInfo: IRGETPublicAssignmentByCode;
	fullname: string;
	history: History;
	user: User | null;
	userTest: IRGETUserTest | null;
	test: IRTest;
}

interface PublicAssignmentTestState {
	hasSubmitted: boolean;
	assignmentTimerToken?: string;
}

export class PublicAssignmentTest extends React.PureComponent<
	PublicAssignmentTestProps,
	PublicAssignmentTestState
> {
	state = {
		hasSubmitted: false,
		assignmentTimerToken: undefined,
	};

	defaultUserAnswers: IUserTestQuestionInfo[] = [];

	constructor(props: PublicAssignmentTestProps) {
		super(props);
		const userTest = props.userTest;
		if (props.assignmentInfo.writtenAssignment && userTest) {
			this.startedAt = props.assignmentInfo.writtenAssignment.startedAt;
			const userAnswersObj = arrayToObject(userTest.questions, "id");
			for (const qId in props.assignmentInfo.writtenAssignment
				.answeredQuestions) {
				const ans = userAnswersObj[qId];
				if (!ans) continue;
				const answeredQuestionInfo = props.assignmentInfo
					.writtenAssignment.answeredQuestions[qId]!;
				this.defaultUserAnswers.push({
					...answeredQuestionInfo,
					id: qId,
					userAnswer: ans.userAnswer,
					numAttempts: ans.numAttempts,
				});
			}
		}
	}

	getTest = memoizeOne(
		(
			test: IRTest,
			settings: IAssignmentSettings,
			content: IRGETTestContents,
			studentEdge: IStartedStudentAssignmentEdge | null
		): IRTest => {
			const isLoggedInStudent =
				this.props.user?.isStudent();
			const { deadlinePassed } = this.props.assignmentInfo;
			const contentIds: ITestSettingContentId[] = content.questions.map(
				q => ({
					id: q._id,
					type: ItemType.question,
				})
			);
			return {
				...test,
				settings: {
					contentIds:
						isLoggedInStudent && studentEdge && !deadlinePassed
							? studentEdge.info.questions.map(questionId => ({
									id: questionId,
									type: ItemType.question,
							  }))
							: !deadlinePassed
							? suffleContentIdsForTest(contentIds, content, true)
							: sortContentIdsBySortedTestContentIds(
									contentIds,
									test.settings?.contentIds || []
							  ),
					isContentSorted: true,
				},
				testTypeSettings: {
					allowSwitchingToSubmittedQuestions: true,
					allowSwitchingToUnsubmittedQuestions: true,
					checkInBackEnd: true,
					maxNumOfWritingTests: 1,
					showAnswersAt: this.props.assignmentInfo.assignment
						.feedBackId
						? TestTypeAnswersShowTime.immediately
						: settings.showAnswersAt instanceof Date ||
						  settings.showAnswersAt ===
								ShowAnswersStandardTime.deadline
						? TestTypeAnswersShowTime.afterDeadline
						: TestTypeAnswersShowTime.afterFinish,
					submitAnswerAfterAnswering: false,
					...test.testTypeSettings,
				},
			};
		}
	);

	_isMounted = false;

	removeLoader?: () => void;

	componentDidMount() {
		this._isMounted = true;
		if (
			!!this.props.assignmentInfo.assignment.settings.timer &&
			(!this.props.assignmentInfo.writtenAssignment ||
				this.props.assignmentInfo.writtenAssignment.canBeRewritten)
		) {
			window.addEventListener("beforeunload", this.onLeaveAttempt);
			inject("AssignmentsController")
				.getStartAssignmentToken({
					assignmentId: this.props.assignmentInfo.assignment._id,
				})
				.then(e => {
					if (e.hasTimer) {
						this.setState(cur => ({
							...cur,
							assignmentTimerToken: e.assignmentTimerToken,
						}));
					}
				})
				.catch(e => console.log("erro", e));
		}
	}

	onLeaveAttempt = (ev: BeforeUnloadEvent) => {
		ev.preventDefault();
		//idk why but alert does not come up without this
		ev.returnValue = "diff";
		return " ";
	};

	startedAt = new Date();

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.onLeaveAttempt);
		this._isMounted = false;
		if (this.removeLoader) {
			this.removeLoader();
		}
	}

	private AssignmentsController = inject("AssignmentsController");

	lastMillisecondsSpentBeforeLastAnswerByQuestions: Record<
		string,
		number | undefined
	> = {};

	saveTest = async (
		args: ITestFinishArgs,
		submit: boolean
	): Promise<{ submitted: boolean }> => {
		const isLoggedStudent = this.props.user?.isStudent();
		if (isLoggedStudent && !args.hasChangedAtLeastOneAnswer) {
			if (!submit) {
				return { submitted: false };
			} else {
				return this.rawSubmitWithoutSave().then(() => ({
					submitted: true,
				}));
			}
		} else if (
			this.props.assignmentInfo.deadlinePassed &&
			!args.hasChangedAtLeastOneAnswer &&
			!submit
		) {
			return { submitted: false };
		}
		const isMounted = this._isMounted;
		if (isMounted && !this.removeLoader) {
			this.removeLoader = addLoader();
		}
		const questionsByIds = arrayToObject(args.questions, "_id");
		const answers: IAnsweredQuestion[] = args.userAnswers
			.filter(
				e =>
					e.userAnswer !== null &&
					e.userAnswer !== undefined &&
					!!questionsByIds[e.questionId]
			)
			.map(
				(e): IAnsweredQuestion => {
					const question = questionsByIds[e.questionId]!;

					const gradableContent = getGradableItemsByEditor(
						(question as IFullQuestion).content ||
							(question as IShortQuestion).shortContent,
						{ justByEditor: true }
					);

					let tSpent = e.dates.millisecondsSpentBeforeLastAnswer || 0;
					if (
						this.lastMillisecondsSpentBeforeLastAnswerByQuestions[
							e.questionId
						]
					) {
						tSpent -= this
							.lastMillisecondsSpentBeforeLastAnswerByQuestions[
							e.questionId
						]!;
					}
					this.lastMillisecondsSpentBeforeLastAnswerByQuestions[
						e.questionId
					] = e.dates.millisecondsSpentBeforeLastAnswer;
					return {
						id: e.questionId,
						numAttempts: 1,
						userAnswer: e.userAnswer || null,
						answeredAt: e.dates.lastAnsweredAt || new Date(),
						timeSpent: Math.floor(tSpent / 1000),
						completedAnyGradableItem: gradableContent.some(
							({ itemId }) => {
								const uAns = e.userAnswer?.[itemId];
								if (!uAns) return false;
								return (
									uAns.text ||
									(uAns.files && uAns.files.length > 0)
								);
							}
						),
						hasGradableItem: gradableContent.length > 0,
					};
				}
			);
		const promise = !isLoggedStudent
			? this.AssignmentsController.submitPublic({
					assignmentId: this.props.assignmentInfo.assignment._id,
					answers,
					userCredentials: {
						name: this.props.fullname,
					},
					startedAt: this.startedAt,
			  })
			: this.AssignmentsController.saveProgress({
					assignmentId: this.props.assignmentInfo.assignment._id,
					answers,
					submit,
					startedAt: this.startedAt,
					assignmentTimerToken: this.state.assignmentTimerToken,
			  });
		return promise.then(data => {
			if (this.removeLoader) this.removeLoader();
			this.handleSubmitEffects(data);
			if (submit) {
				return new Promise<{ submitted: boolean }>(resolve => {
					this.setState(
						{
							hasSubmitted: true,
						},
						() => resolve({ submitted: submit })
					);
				});
			}
			return { submitted: submit };
		});
	};

	handleSubmitEffects = (data: IRPOSTSubmitPublicAssignment) => {
		if (!this.props.user) {
			UserWAssignments.push(
				this.props.assignmentInfo.assignment,
				data.userWrittenAssignmentId
			);
		}
	};

	handleTestError = (e: any) => {
		console.log(e);
		openConfirmationPopup({
			text: getFormattedMessage("student:test.errorWhileSaving"),
		});
		if (this.removeLoader) this.removeLoader();
		throw e;
	};

	onFinish = (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.saveTest(args, true),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};
	onSave = (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			///if has timer => submit anyway
			getPromise: () =>
				this.saveTest(args, !!this.state.assignmentTimerToken),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	rawSubmitWithoutSave = async (): Promise<void> => {
		if (this.state.hasSubmitted) return;
		const isMounted = this._isMounted;
		let removeLoader: () => void;
		if (isMounted) {
			removeLoader = addLoader();
			this.removeLoader = removeLoader;
		}
		return this.AssignmentsController.submit(
			this.props.assignmentInfo.assignment._id
		).then(() => {
			if (removeLoader) removeLoader();
			return new Promise<void>(resolve => {
				this.setState(
					{
						hasSubmitted: true,
					},
					resolve
				);
			});
		});
	};

	onGotoNext = () => {
		this.props.history.push(`/`);
	};

	onTimeRunOut = (args: ITestFinishArgs) => {
		if (
			!this.props.assignmentInfo.settings.timer ||
			!this.state.assignmentTimerToken
		) {
			return;
		}
		this.saveTest(args, true).catch(e => console.log(e));
		openConfirmationPopup({
			text: "თქვენ დრო ამოგეწურათ",
			onClose: this.onGotoNext,
		});
	};

	render() {
		const { assignmentInfo } = this.props;
		const isLoggedStudent = this.props.user?.isStudent();
		const canBeRewritten = !!this.props.assignmentInfo.writtenAssignment
			?.canBeRewritten;

		const test = this.getTest(
			this.props.test,
			assignmentInfo.settings,
			assignmentInfo.content,
			assignmentInfo.studentEdge || null
		);

		return (
			<AssignmentTestWrapper
				dontSubmitOnFirstNextClick={
					!!this.props.assignmentInfo.assignment.feedBackId
				}
				test={test}
				testTypeSettings={test.testTypeSettings!}
				content={assignmentInfo.content}
				testFeedBack={this.props.assignmentInfo.assignment.feedBackId}
				defaultUserAnswers={this.defaultUserAnswers}
				onFinish={this.onFinish}
				onSave={this.onSave}
				onGotoNext={isLoggedStudent ? this.onGotoNext : undefined}
				courseId={assignmentInfo.courseInfo.courseId}
				deadline={assignmentInfo.settings.deadline}
				deadlinePassed={assignmentInfo.deadlinePassed}
				isSubmitted={
					this.state.hasSubmitted ? true : assignmentInfo.isSubmitted
				}
				disableShufflingAnswers={
					assignmentInfo.settings.shuffleAnswers === false
				}
				settings={assignmentInfo.settings}
				onTimeRunOut={this.onTimeRunOut}
				testNavigationProps={{
					components: {
						itemComponent: this.props.assignmentInfo.assignment
							.feedBackId
							? undefined
							: {
									InnerItemContainer,
							  },
					},
					styles: {
						item: {
							container: testStyles.itemContainer,
							isFinishPage: testStyles.finishItem,
							isStartPage: testStyles.startItem,
							isSelected: testStyles.isSelected,
							containsGradableItemsByEditor:
								testStyles.containsGradableItemsByEditor,
							isAccessible: testStyles.isAccessible,
							isNotAccessible: testStyles.isNotAccessible,
							hasAnswered: testStyles.hasAnswered,
							hasAnsweredFully: testStyles.hasAnsweredFully,
							hasAnsweredCorreclty:
								testStyles.hasAnsweredCorreclty,
							hasAnsweredPartiallyCorreclty:
								testStyles.hasAnsweredPartiallyCorreclty,
							hasAnsweredIncorreclty:
								testStyles.hasAnsweredIncorreclty,
						},
						OuterContainer: testStyles.outerContainer,
						ContainerWithScrollbar:
							testStyles.containerWithScrollbar,
					},
				}}
			/>
		);
	}
}

const InnerItemContainer: React.FC<IInnerItemContainerProps> = React.memo(
	props => {
		if (props.isTestFinished || !shouldDisplayTou(props.display)) {
			return <TestNavigationInnerItemComponent {...props} />;
		}

		return (
			<>
				{props.isSelected && (
					<div className={testStyles.touTitle}>
						შეკითხვა თბილისის
						<br />
						ღია უნივერსიტეტისგან
					</div>
				)}
				<TouLogo
					style={{
						width: 25,
					}}
				/>
			</>
		);
	}
);

const shouldDisplayTou = (d: display): boolean => {
	if (getLocale() !== "ka") return false;
	if (getCurrentWebsite() !== WebsiteOrigin.tvSchool) return false;
	if (d.type !== "question") return false;
	if (d.index !== 4) return false;
	return true;
};
