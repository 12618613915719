/* eslint-disable max-lines */
import { UserType } from "@app/api/helper-schemas";
import UserRoute from "@app/routes/containers/user-route";
import React from "react";
import { createRouteList } from "../config";
import { adminLinks } from "./links";

const adminRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		dontRedirectIfNotAuthenticated: true,
		availableUserTypes: UserType.mainAdmin,
	},
});

///

const AdminMainPage = React.lazy(() =>
	import("@app/components/admin").then(module => ({
		default: module.AdminMainPage,
	}))
);

adminRoutes.add({
	path: adminLinks.mainPage,
	component: AdminMainPage,
	avoidSwitch: true,
});

///

const ClassroomsBulkDeletePage = React.lazy(() =>
	import("@app/components/admin/classrooms/bulk-delete").then(module => ({
		default: module.ClassroomsBulkDeletePage,
	}))
);

adminRoutes.add({
	path: adminLinks.classrooms.bulkDelete,
	component: ClassroomsBulkDeletePage,
});

///

const ChangeClassroomSchools = React.lazy(() =>
	import("@app/components/admin/classrooms/change-schools").then(module => ({
		default: module.ChangeClassroomSchools,
	}))
);

adminRoutes.add({
	path: "update-classroom-schools",
	component: ChangeClassroomSchools,
});

///

const ClassTimesImport = React.lazy(() =>
	import("@app/components/admin/classrooms/class-times").then(module => ({
		default: module.ClassTimesImport,
	}))
);

adminRoutes.add({
	path: adminLinks.classrooms.importClassTimes,
	component: ClassTimesImport,
});

///

const CreatePremiumClassrooms = React.lazy(() =>
	import(
		"@app/components/admin/classrooms/premium-classrooms"
	).then(module => ({ default: module.CreatePremiumClassrooms }))
);

adminRoutes.add({
	path: adminLinks.classrooms.premium,
	component: CreatePremiumClassrooms,
});

///

const AdminCognitiveAssignmentsList = React.lazy(() =>
	import("@app/components/admin/cognitive-assignments").then(module => ({
		default: module.AdminCognitiveAssignmentsList,
	}))
);

adminRoutes.add({
	path: adminLinks.cognitiveAssignments.list,
	component: AdminCognitiveAssignmentsList,
});

///

const CognitiveAssignmentStatsContainer = React.lazy(() =>
	import(
		"@app/components/admin/cognitive-assignments/stats/container"
	).then(module => ({ default: module.CognitiveAssignmentStatsContainer }))
);

adminRoutes.add({
	path: adminLinks.cognitiveAssignments.stats,
	component: CognitiveAssignmentStatsContainer,
});

///

const ComplexAssignmentUtils = React.lazy(() =>
	import("@app/components/admin/complex-assignments").then(module => ({
		default: module.ComplexAssignmentUtils,
	}))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.utils,
	component: ComplexAssignmentUtils,
});

///

const CreateComplexAdmin = React.lazy(() =>
	import("@app/components/admin/complex-assignments/create").then(module => ({
		default: module.CreateComplexAdmin,
	}))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.create,
	component: CreateComplexAdmin,
});

///

const TeacherCreatedComplexAssignments = React.lazy(() =>
	import("@app/components/admin/complex-assignments/review").then(module => ({
		default: module.TeacherCreatedComplexAssignments,
	}))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.review.list,
	component: TeacherCreatedComplexAssignments,
});

///

const ReviewComplexAssignment = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/review/review-complex-assignment"
	).then(module => ({ default: module.ReviewComplexAssignment }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.review.test.routePath,
	component: ReviewComplexAssignment,
});

///

const ReviewComplexAssignments = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/review/review-complex-assignments"
	).then(module => ({ default: module.ReviewComplexAssignments }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.review.courseTests.routePath,
	component: ReviewComplexAssignments,
});

///

const VerifiedComplexAssignments = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/review/verified-complex-assignments"
	).then(module => ({ default: module.VerifiedComplexAssignments }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.review.verifiedCourseTests.routePath,
	component: VerifiedComplexAssignments,
});

///
const AdminAssignmentResultsList = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/results/list"
	).then(module => ({ default: module.AdminAssignmentResultsList }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.results.list,
	component: AdminAssignmentResultsList,
});

///

const SaveComplexAssignmentResultGroups = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/results/save"
	).then(module => ({ default: module.SaveComplexAssignmentResultGroups }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.results.create,
	component: SaveComplexAssignmentResultGroups,
});

adminRoutes.add({
	path: adminLinks.complexAssignments.results.edit.routePath,
	component: SaveComplexAssignmentResultGroups,
});

///

const AdminTargetConceptsList = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/target-concepts/list"
	).then(module => ({ default: module.AdminTargetConceptsList }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.targetConcepts.list,
	component: AdminTargetConceptsList,
});

///

const SaveTargetConcept = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/target-concepts/save"
	).then(module => ({ default: module.SaveTargetConcept }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.targetConcepts.create,
	component: SaveTargetConcept,
});

adminRoutes.add({
	path: adminLinks.complexAssignments.targetConcepts.edit.routePath,
	component: SaveTargetConcept,
});

///
const AdminAssignmentTopicsList = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/topics/list"
	).then(module => ({ default: module.AdminAssignmentTopicsList }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.topics.list,
	component: AdminAssignmentTopicsList,
});

///

const SaveAssignmentTopic = React.lazy(() =>
	import(
		"@app/components/admin/complex-assignments/utils/topics/save"
	).then(module => ({ default: module.SaveAssignmentTopic }))
);

adminRoutes.add({
	path: adminLinks.complexAssignments.topics.create,
	component: SaveAssignmentTopic,
});

adminRoutes.add({
	path: adminLinks.complexAssignments.topics.edit.routePath,
	component: SaveAssignmentTopic,
});

///

const AdminCoursesList = React.lazy(() =>
	import("@app/components/admin/courses").then(module => ({
		default: module.AdminCoursesList,
	}))
);

adminRoutes.add({
	path: adminLinks.courses.list,
	component: AdminCoursesList,
});

///

const SaveCourse = React.lazy(() =>
	import("@app/components/admin/courses/save").then(module => ({
		default: module.SaveCourse,
	}))
);

adminRoutes.add({
	path: adminLinks.courses.create,
	component: SaveCourse,
});

adminRoutes.add({
	path: adminLinks.courses.edit.routePath,
	component: SaveCourse,
});

///

const FeedbackList = React.lazy(() =>
	import("@app/components/admin/feedbacks/list").then(module => ({
		default: module.FeedbackList,
	}))
);

adminRoutes.add({
	path: adminLinks.feedbacks.list,
	component: FeedbackList,
});

///

const CreateFeedback = React.lazy(() =>
	import("@app/components/admin/feedbacks/create").then(module => ({
		default: module.CreateFeedback,
	}))
);

adminRoutes.add({
	path: adminLinks.feedbacks.create,
	component: CreateFeedback,
});

///

const UpdateFeedback = React.lazy(() =>
	import("@app/components/admin/feedbacks/update").then(module => ({
		default: module.UpdateFeedback,
	}))
);

adminRoutes.add({
	path: adminLinks.feedbacks.edit.routePath,
	component: UpdateFeedback,
});

///

const AdminCategoriesList = React.lazy(() =>
	import(
		"@app/components/admin/grades-and-categories/categories/list"
	).then(module => ({ default: module.AdminCategoriesList }))
);

adminRoutes.add({
	path: adminLinks.gradeCategories.list,
	component: AdminCategoriesList,
});

///

const SaveCategory = React.lazy(() =>
	import(
		"@app/components/admin/grades-and-categories/categories/save"
	).then(module => ({ default: module.SaveCategory }))
);

adminRoutes.add({
	path: adminLinks.gradeCategories.crate,
	component: SaveCategory,
});

adminRoutes.add({
	path: adminLinks.gradeCategories.edit.routePath,
	component: SaveCategory,
});

///

const AdminGradesList = React.lazy(() =>
	import(
		"@app/components/admin/grades-and-categories/grades/list"
	).then(module => ({ default: module.AdminGradesList }))
);

adminRoutes.add({
	path: adminLinks.grades.list,
	component: AdminGradesList,
});

///

const SaveGrade = React.lazy(() =>
	import(
		"@app/components/admin/grades-and-categories/grades/save"
	).then(module => ({ default: module.SaveGrade }))
);

adminRoutes.add({
	path: adminLinks.grades.create,
	component: SaveGrade,
});

adminRoutes.add({
	path: adminLinks.grades.edit.routePath,
	component: SaveGrade,
});

///

const LinkRequestsList = React.lazy(() =>
	import("@app/components/admin/link-requests").then(module => ({
		default: module.LinkRequestsList,
	}))
);

adminRoutes.add({
	path: adminLinks.linkRequests.list,
	component: LinkRequestsList,
});

///

const FacebookVideosAdminPage = React.lazy(() =>
	import("@app/components/admin/facebook-videos").then(module => ({
		default: module.FacebookVideosAdminPage,
	}))
);

adminRoutes.add({
	path: adminLinks.facebookVideos,
	component: FacebookVideosAdminPage,
});

///

const ReservationsList = React.lazy(() =>
	import(
		"@app/components/admin/offer-reservations/reservations"
	).then(module => ({ default: module.ReservationsList }))
);

adminRoutes.add({
	path: adminLinks.offerReservations,
	component: ReservationsList,
});

///

const ProposalList = React.lazy(() =>
	import("@app/components/admin/proposals").then(module => ({
		default: module.ProposalList,
	}))
);

adminRoutes.add({
	path: adminLinks.proposals,
	component: ProposalList,
});

///

const ReviewCoursesList = React.lazy(() =>
	import("@app/components/admin/review").then(module => ({
		default: module.ReviewCoursesList,
	}))
);

adminRoutes.add({
	path: adminLinks.publicTests.courseList,
	component: ReviewCoursesList,
});

///

const RevievwTest = React.lazy(() =>
	import("@app/components/admin/review/review-test").then(module => ({
		default: module.RevievwTest,
	}))
);

adminRoutes.add({
	path: adminLinks.publicTests.review.test.routePath,
	component: RevievwTest,
});

///

const ReviewTests = React.lazy(() =>
	import("@app/components/admin/review/review-tests").then(module => ({
		default: module.ReviewTests,
	}))
);

adminRoutes.add({
	path: adminLinks.publicTests.review.course.routePath,
	component: ReviewTests,
});

///

const VerifiedTests = React.lazy(() =>
	import("@app/components/admin/review/verified-test").then(module => ({
		default: module.VerifiedTests,
	}))
);

adminRoutes.add({
	path: adminLinks.publicTests.review.courseVerifiedTests.routePath,
	component: VerifiedTests,
});

///

const ImportClassroomsPage = React.lazy(() =>
	import("@app/components/admin/schools/import-classrooms").then(module => ({
		default: module.ImportClassroomsPage,
	}))
);

adminRoutes.add({
	path: adminLinks.classrooms.import,
	component: ImportClassroomsPage,
});

///

const ImportClassroomLinksPage = React.lazy(() =>
	import("@app/components/admin/schools/import-links").then(module => ({
		default: module.ImportClassroomLinksPage,
	}))
);

adminRoutes.add({
	path: adminLinks.videoLessons.import,
	component: ImportClassroomLinksPage,
});

///

const AdminUserStats = React.lazy(() =>
	import("@app/components/admin/stats").then(module => ({
		default: module.AdminUserStats,
	}))
);

adminRoutes.add({
	path: adminLinks.users.stats,
	component: AdminUserStats,
});

///

const FixStudentsInfo = React.lazy(() =>
	import("@app/components/admin/students-info/fix").then(module => ({
		default: module.FixStudentsInfo,
	}))
);

adminRoutes.add({
	path: adminLinks.students.fixStudentsInfo,
	component: FixStudentsInfo,
});

///

const AdminSubjectsList = React.lazy(() =>
	import("@app/components/admin/subjects/list").then(module => ({
		default: module.AdminSubjectsList,
	}))
);

adminRoutes.add({
	path: adminLinks.subjects.list,
	component: AdminSubjectsList,
});

///

const SaveSubject = React.lazy(() =>
	import("@app/components/admin/subjects/save").then(module => ({
		default: module.SaveSubject,
	}))
);

adminRoutes.add({
	path: adminLinks.subjects.create,
	component: SaveSubject,
});

adminRoutes.add({
	path: adminLinks.subjects.edit.routePath,
	component: SaveSubject,
});

///

const UserBulkDeletePage = React.lazy(() =>
	import("@app/components/admin/users/bulk-delete").then(module => ({
		default: module.UserBulkDeletePage,
	}))
);

adminRoutes.add({
	path: adminLinks.users.bulkDelete,
	component: UserBulkDeletePage,
});

///

const UserGetBulkPage = React.lazy(() =>
	import("@app/components/admin/users/get-bulk").then(module => ({
		default: module.UserGetBulkPage,
	}))
);

adminRoutes.add({
	path: adminLinks.users.bulkGet,
	component: UserGetBulkPage,
});

///

const AdminCitiesList = React.lazy(() =>
	import("@app/components/admin/cities/list").then(module => ({
		default: module.AdminCitiesList,
	}))
);

adminRoutes.add({
	path: adminLinks.cities.list,
	component: AdminCitiesList,
});

///

const AdminSaveCity = React.lazy(() =>
	import("@app/components/admin/cities/save").then(module => ({
		default: module.SaveCity,
	}))
);

adminRoutes.add({
	path: adminLinks.cities.add,
	component: AdminSaveCity,
});

adminRoutes.add({
	path: adminLinks.cities.edit.routePath,
	component: AdminSaveCity,
});

///

const AdminSchoolsList = React.lazy(() =>
	import("@app/components/admin/schools/list").then(module => ({
		default: module.AdminSchoolsList,
	}))
);

adminRoutes.add({
	path: adminLinks.schools.list,
	component: AdminSchoolsList,
});

///

const AdminSaveSchool = React.lazy(() =>
	import("@app/components/admin/schools/save").then(module => ({
		default: module.SaveSchool,
	}))
);

adminRoutes.add({
	path: adminLinks.schools.add,
	component: AdminSaveSchool,
});

adminRoutes.add({
	path: adminLinks.schools.edit.routePath,
	component: AdminSaveSchool,
});

///

const AdminGradebookCategoryList = React.lazy(() =>
	import("@app/components/admin/gradebook/categories/list").then(module => ({
		default: module.AdminGradebookCategoryList,
	}))
);

adminRoutes.add({
	path: adminLinks.gradebookCategories.list,
	component: AdminGradebookCategoryList,
});

///

const SaveGradebookGradeCategory = React.lazy(() =>
	import("@app/components/admin/gradebook/categories/save").then(module => ({
		default: module.SaveGradebookGradeCategory,
	}))
);

adminRoutes.add(
	{
		path: adminLinks.gradebookCategories.create,
		component: SaveGradebookGradeCategory,
	},
	{
		path: adminLinks.gradebookCategories.edit.routePath,
		component: SaveGradebookGradeCategory,
	}
);

///
const SchoolsMainPage = React.lazy(() =>
	import("../../components/schools/schools-main-page").then(module => ({
		default: module.SchoolsMainPage,
	}))
);

adminRoutes.add({
	path: "/school-main-page",
	component: SchoolsMainPage,
});

///
const SchoolRegisterPage = React.lazy(() =>
	import("../../components/auth/school-registration-page").then(module => ({
		default: module.SchoolRegisterPage,
	}))
);

adminRoutes.add({
	path: "/school-registration",
	component: SchoolRegisterPage,
});

///
const CreateBlog = React.lazy(() =>
	import("../../components/users/blog/create-blog").then(module => ({
		default: module.CreateBlog,
	}))
);

adminRoutes.add({
	path: "/create-blog",
	component: CreateBlog,
});

///

export default adminRoutes;
