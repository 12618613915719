import { getLocale } from "@app/hooks/intl";
import { ObjectId } from "@app/utils/generics";

const PalitraFinancedCourseIds: string[] = [];

const HotsaleFinancedCourseIds: string[] = [];
const HotsaleFinancedTaskTypeIds: string[] = [];

export const isCourseFinancedByPalitra = (courseId: ObjectId): boolean => {
	const lang = getLocale();
	if (lang !== "ka") return false;
	return PalitraFinancedCourseIds.some(e => e === courseId);
};

export const isCourseFinancedByHotsale = (courseId: ObjectId): boolean => {
	const lang = getLocale();
	if (lang !== "ka") return false;
	return HotsaleFinancedCourseIds.some(e => e === courseId);
};

export const isTaskTypeFinancedByHotsale = (tasktypeId: ObjectId): boolean => {
	const lang = getLocale();
	if (lang !== "ka") return false;
	return HotsaleFinancedTaskTypeIds.some(e => e === tasktypeId);
};

const CoursesNotFinancedByTou = ["5e33c4a1dd33074a75179ff5"];

export const isCourseFinancedByTou = (courseId: ObjectId): boolean => {
	const lang = getLocale();
	if (lang !== "ka") return false;
	return CoursesNotFinancedByTou.every(e => e !== courseId);
};
