import React, { useRef, useEffect } from "react";
import { TVSchoolHeader, DivReference } from "./tv-school-header";
import { Role } from "./role";
import { ReactComponent as ScrollDown } from "./styles/imgs/scroll-down.svg";
import styles from "./styles/tv-school-landing.module.css";
import { FaqContainer } from "../widgets/faq";
import {
	getHTMLElementCoords,
	animateWindowScroll,
} from "@tests-core/utils/dom";
import { useLocale } from "@app/hooks/intl";
import LandingFooter from "@app/components/landing/landingFooter";

export const TVSchoolLanding: React.FC<{}> = props => {
	const faqRef = useRef<HTMLDivElement | null>(null);
	const locale = useLocale();
	const isGeo = locale === "ka";

	useEffect(() => {
		let hasScrolled = false;
		const scrollTofaq = (e: WheelEvent, ref: DivReference) => {
			if (e.deltaY <= 0) {
				// scrolling up
				return;
			}
			if (!ref) return;
			if (!ref.current) return;
			const coordinates = getHTMLElementCoords(ref.current);

			if (
				Math.floor(window.pageYOffset) > 0 &&
				Math.floor(window.pageYOffset) < 80
			) {
				hasScrolled = false;

				window.addEventListener("mouseup", e => {
					hasScrolled = true;
					window.removeEventListener("mousedown", () => {
						hasScrolled = false;
					});
				});
				window.addEventListener("mousedown", e => {
					hasScrolled = false;
					window.removeEventListener("mouseup", () => {
						hasScrolled = true;
					});
				});
			}

			if (hasScrolled) {
				return;
			} else {
				setTimeout(() => {
					animateWindowScroll(coordinates.top - 90, 300);
					hasScrolled = true;
				}, 300);
			}
		};

		const fn = (e: WheelEvent) => scrollTofaq(e, faqRef);

		window.addEventListener("wheel", fn);

		return () => window.removeEventListener("wheel", fn);
	}, []);

	return (
		<div>
			<TVSchoolHeader faqRef={faqRef} />
			<Role />
			<div className={styles.scroollArrowContainer}>
				<ScrollDown style={{ width: "20px" }} />
			</div>
			<div ref={faqRef}>
				<FaqContainer isOnlyTitleWithoutHalfMoon={true} />
			</div>
			<LandingFooter />
		</div>
	);
};
