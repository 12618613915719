import * as React from "react";
import Popup, { PopupContentWithClose } from "../../widgets/popup";
import { useEffect, useState } from "react";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { useCoursesUserId } from "@app/hooks/users";
import {
	ItemType,
	ITestItem,
	IRFolder,
	IFileItem,
	IFolderItem,
} from "@app/api/folders/helper-schemas";
import FancyLoading from "../../widgets/fancy-loading";
import { useItemProgress } from "@app/hooks/user-folder-progress";
import styles from "./styles/lesson-tests.module.css";
import { arrayToObject } from "@app/utils/common";
import { useHistory } from "@app/hooks/front";
import { getFormattedMessage } from "@app/utils/locale";
import { addLoader } from "@app/common-javascript";
import { redirectToItem } from "./items/redirections";

interface IContinueToExercisesPopupProps {
	courseId: ObjectId;
	folderId: ObjectId;
	onClose: () => void;
}
export function getProgressAffectingItems(
	startFolderId: ObjectId,
	folders: IRFolder[],
	omitFolders?: false
): ({
	item: ITestItem | IFileItem | IFolderItem;
	parentFolderId: ObjectId;
	parentFolderName: string;
})[];
export function getProgressAffectingItems(
	startFolderId: ObjectId,
	folders: IRFolder[],
	omitFolders: true
): ({
	item: ITestItem | IFileItem;
	parentFolderId: ObjectId;
	parentFolderName: string;
})[];
export function getProgressAffectingItems(
	startFolderId: ObjectId,
	folders: IRFolder[],
	omitFolders = false
) {
	const arrTest: ({
		item: ITestItem | IFileItem | IFolderItem;
		parentFolderId: ObjectId;
		parentFolderName: string;
	})[] = [];
	const foldersObj = arrayToObject(folders, "_id");
	const FolderItemsService = inject("FolderItemsService");
	const addForFolders = (folderId: ObjectId) => {
		const folder = foldersObj[folderId];
		if (!folder || !folder.items) return;
		for (const item of folder.items) {
			if (item.isHidden) continue;
			if (item.type === ItemType.folder) {
				addForFolders(item.id);
			}
			if (FolderItemsService.isProgressAffectingType(item.type)) {
				if (omitFolders && item.type === ItemType.folder) {
					continue;
				}
				arrTest.push({
					item: item as ITestItem | IFileItem | IFolderItem,
					parentFolderId: folder._id,
					parentFolderName: folder.name,
				});
			}
		}
	};
	addForFolders(startFolderId);
	return arrTest;
}

const LessonTestsPopup: React.FC<IContinueToExercisesPopupProps> = props => {
	const history = useHistory();
	const [tests, setTests] = useState<
		({
			item: ITestItem | IFileItem;
			parentFolderId: ObjectId;
			parentFolderName: string;
		})[]
	>();

	const coursesUserId = useCoursesUserId();
	useEffect(() => {
		setTests(undefined);
		if (!coursesUserId) return;
		let isCanceled = false;
		const CourseFetchingController = inject("CourseFetchingController");
		CourseFetchingController.loadWholeCourseSkeleton({
			courseId: props.courseId,
			folderId: props.folderId,
		}).then(data => {
			if (isCanceled) return;
			const arrTest = getProgressAffectingItems(
				props.folderId,
				data.folders,
				true
			);
			if (arrTest.length > 0) {
				const { id: itemId, type: itemType } = arrTest[0].item;
				const parentFolderId = arrTest[0].parentFolderId;
				let redirect = false;
				if (arrTest.length === 1) {
					redirect = true;
				} else {
					const UserFolderProgressModel = inject(
						"UserFolderProgressModel"
					);
					let hasFoundProgressOfAtLeasOneItem = false;
					for (const { item, parentFolderId } of arrTest) {
						const itemProgress = UserFolderProgressModel.findItemProgress(
							{
								courseId: props.courseId,
								parentFolderId,
								itemId: item.id,
								itemType: item.type,
								userId: coursesUserId,
							}
						);
						if (itemProgress && itemProgress.progress > 0) {
							hasFoundProgressOfAtLeasOneItem = true;
							break;
						}
					}
					// TODO: check if all displayed items have progress of 0.
					redirect = !hasFoundProgressOfAtLeasOneItem;
				}
				if (redirect) {
					setTimeout(() => {
						redirectToItem({
							history,
							courseId: props.courseId,
							itemId,
							itemType,
							parentFolderId,
						});
					}, 0);
				}
			}
			setTests(arrTest);
		});
		return () => {
			isCanceled = true;
		};
	}, [coursesUserId, history, props.courseId, props.folderId]);

	useEffect(() => {
		if (tests && tests.length !== 1) return;
		return addLoader();
	}, [tests]);

	let greyLine = 0;

	if (!tests || tests.length === 1) return null;

	return (
		<Popup onClose={props.onClose}>
			<PopupContentWithClose
				onClose={props.onClose}
				className={styles.exercisePopupStyles}
			>
				{!tests ? (
					<FancyLoading />
				) : (
					<div>
						{tests.map(test => {
							greyLine++;
							return (
								<div key={test.item.id}>
									<TestOrFile
										item={test.item}
										courseId={props.courseId}
										parentFolderId={test.parentFolderId}
									/>
									{greyLine < tests.length && (
										<div className={styles.greyLine} />
									)}
								</div>
							);
						})}
					</div>
				)}
			</PopupContentWithClose>
		</Popup>
	);
};

export const TestOrFile: React.FC<{
	item: ITestItem | IFileItem;
	parentFolderId: ObjectId;
	courseId: ObjectId;
}> = props => {
	const history = useHistory();
	const coursesUserId = useCoursesUserId();
	const testProgress = useItemProgress({
		coursesUserId: coursesUserId || null,
		parentFolderId: props.parentFolderId,
		courseId: props.courseId,
		itemId: props.item.id,
		itemType: props.item.type,
	});
	const progress = testProgress ? testProgress.progress : 0;

	const onTestSTart = React.useCallback(() => {
		redirectToItem({
			history,
			courseId: props.courseId,
			itemId: props.item.id,
			itemType: props.item.type,
			parentFolderId: props.parentFolderId,
		});
	}, [
		history,
		props.courseId,
		props.item.id,
		props.item.type,
		props.parentFolderId,
	]);
	return (
		<div className={styles.test}>
			<span className={styles.testName}>{props.item.name}</span>
			<div className={styles.percent}>
				<span>{Math.floor(progress * 100)}%</span>
			</div>
			<button className={styles.viewTest} onClick={onTestSTart}>
				{progress === 0
					? getFormattedMessage("start")
					: getFormattedMessage("continue")}
			</button>
		</div>
	);
};
export default LessonTestsPopup;
