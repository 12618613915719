import { IRequest } from "@app/api/requests";
import {
	IRPOSTClassroomEnrollmentCode,
	IAPUTConnectToClassroomEnrollmentCodes,
	IRPUTConnectToClassroomEnrollmentCodes,
	IAGETTeacherInfoUsingCode,
	IRGETTeacherInfoUsingCode,
	AGETTeacherInfoUsingCodeSchema,
	RGETTeacherInfoUsingCodeSchema,
} from "./validators";
import { UserInfo } from "@app/models/user-info";
import { inject } from "@app/modules";

export class ClassroomEnrollmentCodesController {
	private readonly Request: IRequest;
	constructor(request: IRequest) {
		this.Request = request;
	}

	private _UserInfoModel = inject("UserInfoModel");

	create = async (): Promise<IRPOSTClassroomEnrollmentCode> =>
		this.Request.send("POST", "/api/classrooms/enrollments/codes");

	connectToCode = async (
		args: IAPUTConnectToClassroomEnrollmentCodes
	): Promise<IRPUTConnectToClassroomEnrollmentCodes> =>
		this.Request.send("PUT", "/api/classrooms/enrollments/codes", args);

	getAuthorInfo = async (
		args: IAGETTeacherInfoUsingCode
	): Promise<UserInfo> =>
		this.Request.send(
			"GET",
			"/api/classrooms/enrollments/codes/author-info",
			args,
			null,
			{
				requestSchema: AGETTeacherInfoUsingCodeSchema,
				responseSchema: RGETTeacherInfoUsingCodeSchema,
			}
		).then((data: IRGETTeacherInfoUsingCode) => {
			return this._UserInfoModel.loadOneSync(data);
		});
}
