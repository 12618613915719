import { IRUser } from "@app/api/users/helper-schemas";
import { WebsiteOrigin } from "@app/globals";

export class CountriesService {
	// getAvailableLanguagesForCountry
	getCountiesForLanguage(language: string) {
		if (language === "ka") {
			return ["geo"];
		} else if (language === "nl") {
			return ["nld"];
		}
		return ["nld", "geo"];
	}

	getAllCounties() {
		return ["nld", "geo"];
	}

	getMinAndMaxGrades(
		country: IRUser["country"],
		webOrigin: IRUser["registrationOrigin"]
	): { minGrade: number; maxGrade: number } {
		if (country === "geo" || !country) {
			if (!webOrigin || webOrigin === WebsiteOrigin.hubu) {
				return { minGrade: 11, maxGrade: 12 };
			} else if (webOrigin === WebsiteOrigin.tvSchool) {
				return { minGrade: 1, maxGrade: 12 };
			}
		}
		return { minGrade: 1, maxGrade: 12 };
	}
}
