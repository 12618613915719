import { Subscription } from "./utils/subscription";

export enum WebsiteOrigin {
	hubu = "hubu",
	tvSchool = "tv-school",
	hubuExams = "hubu-exams",
}

export const WebsiteOrigins = [WebsiteOrigin.hubu, WebsiteOrigin.tvSchool];

export const DomainsByWebsiteOrigins: Record<WebsiteOrigin, string[]> = {
	[WebsiteOrigin.hubu]: ["hubu.ge"],
	[WebsiteOrigin.tvSchool]: ["tvschool.ge", "mijntaken.com", "lupiai.com"],
	[WebsiteOrigin.hubuExams]: ["hubu.exams.ge"],
};

export const isLupi = () => {
	const url = window.location.href.substr(
		window.location.href.indexOf("://") + 3
	);
	return url.indexOf("lupi") === 0 || url.indexOf("www.lupi") === 0;
};

export const isHubu = () => {
	const url = window.location.href.substr(
		window.location.href.indexOf("://") + 3
	);
	return url.indexOf("hubu") === 0 || url.indexOf("www.hubu") === 0;
};

export const isTVSchool = () => {
	const url = window.location.href.substr(
		window.location.href.indexOf("://") + 3
	);
	return (
		url.indexOf("tv") === 0 ||
		url.indexOf("www.tv") === 0 ||
		isDutchTVSchool() ||
		isLupi()
	);
};

const isDutchTVSchool = () => {
	const url = window.location.href.substr(
		window.location.href.indexOf("://") + 3
	);
	return url.indexOf("mijn") === 0 || url.indexOf("www.mijn") === 0;
};

export const isLocalhost = () => {
	return (
		window.location.href
			.substr(window.location.href.indexOf("://") + 3)
			.indexOf("localhost") === 0
	);
};

const defaultOrigin =
	(sessionStorage.getItem("websiteOrigin") as WebsiteOrigin) ||
	(process.env.REACT_APP_DEFAULT_ORIGIN as WebsiteOrigin | undefined) ||
	WebsiteOrigin.hubu;

export const getCurrentWebsite = (): WebsiteOrigin => {
	if (isHubu()) return WebsiteOrigin.hubu;
	if (isTVSchool() || isLupi() || isDutchTVSchool()) {
		return WebsiteOrigin.tvSchool;
	}
	return defaultOrigin;
};

export const getInitialLocale = () => {
	return (
		localStorage.locale ||
		(isDutchTVSchool() ? "nl" : isLupi() ? "en" : "ka")
	);
};

export const techDiffForStudents =
	typeof process.env.REACT_APP_STUDENTS_TECH_DIFF === "string" &&
	process.env.REACT_APP_STUDENTS_TECH_DIFF.toLowerCase() === "true";

export const fbSubscription = new Subscription({
	loaded: false,
});
fbSubscription.subscribe(data => {
	fbSubscription.setMetaData({
		loaded: true,
	});
});
export const broadcastFBLoad = () => {
	fbSubscription.broadcast(null);
};

export enum CertificateId {
	tvSchoolCertificate = 1,
	profOrientationPrimaryCertificate = 2,
	profOrientationSecondaryCertificate = 3,
}
