import React from "react";
import { createRouteList } from "../config";
import UserRoute from "@app/routes/containers/user-route";
import { UserType } from "@app/api/helper-schemas";
import { headmasterLinks } from "./link";

const headMasterRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		dontRedirectIfNotAuthenticated: true,
		availableUserTypes: UserType.headmaster,
	},
});

// const HeadmasterMainPage = React.lazy(() =>
// 	import("@app/components/headmaster/main").then(module => ({
// 		default: module.HeadmasterMainPage,
// 	}))
// );

// headMasterRoutes.add({
// 	path: headmasterLinks.mainPage,
// 	component: HeadmasterMainPage,
// });

const TeacherGroupTimetablePage = React.lazy(() =>
	import("@app/components/teachers/timetables/groups").then(module => ({
		default: module.TeacherGroupTimetablePage,
	}))
);

headMasterRoutes.add({
	path: headmasterLinks.groups.timetable.routePath,
	component: TeacherGroupTimetablePage,
});

const GroupGradebook = React.lazy(() =>
	import("@app/components/teachers/gradebook/group").then(module => ({
		default: module.GroupGradebook,
	}))
);

headMasterRoutes.add({
	path: headmasterLinks.groups.gradebook.routePath,
	component: GroupGradebook,
});

const headMasterRoutesArray = headMasterRoutes.toArray();

export default headMasterRoutesArray;
