import { GeneralContext } from "@app/components/general-context";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { ReactComponent as TVSchoolNL } from "@app/components/tv-school-landing/styles/imgs/tv-school-nl.svg";
import { ReactComponent as TVSchoolEn } from "@app/components/tv-school-landing/styles/imgs/tv-school-en.svg";
import { ReactComponent as LupiLogo } from "@app/components/landing/styles/img/lupi-ai.svg";
import { useSeeingSpecialCourse } from "@app/hooks/special-courses";
import { useStudentInfo } from "@app/hooks/users";
import { ObjectId } from "@app/utils/generics";
import React, { useCallback, useContext, useEffect } from "react";
import { useGoToUrl, useHistory, useWindowSize } from "../../../hooks/front";
import { getLastCourseId } from "../last-course-saver";
import { HeaderOptions } from "./options";
import HeaderStyles from "./styles/header.module.css";
import { FormattedMessage } from "react-intl";
import { inject } from "@app/modules";
import { useLocale } from "@app/hooks/intl";
import { useMyInfoAsStudent } from "@app/hooks/student";
import { Classroom } from "@app/models/classroom";

export const TVSchoolStudentHeader: React.FC<{
	courseId?: ObjectId;
}> = props => {
	const history = useHistory();
	const { width } = useWindowSize();
	const isMobile = width < 600;
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const isEn = locale === "en";

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const courseId = props.courseId || getLastCourseId();

	useSeeingSpecialCourse(courseId);

	const studentInfo = useStudentInfo();
	const courseIds = studentInfo ? studentInfo.courseIds : undefined;
	useEffect(() => {
		if (!courseIds || !courseId) return;
		if (courseIds.indexOf(courseId) === -1) {
			history.push("");
		}
	}, [history, courseId, courseIds]);

	const gotoMainPage = useCallback(() => {
		history.push(`/`);
	}, [history]);
	const gotoFAQPage = useCallback(() => {
		history.push(`/faq`);
	}, [history]);

	if (isHeaderHidden) {
		return null;
	}

	return (
		<>
			<div className={HeaderStyles.container}>
				<div className={HeaderStyles.coursesAndLogoContainer}>
					<div className={HeaderStyles.logo} onClick={gotoMainPage}>
						{isGeo ? (
							<TVSchoolLogo
								style={{ height: "100%", width: 112 }}
							/>
						) : isNl ? (
							<TVSchoolNL
								style={{ height: "100%", width: 112 }}
							/>
						) : (
							<LupiLogo style={{ height: "100%", width: 112 }} />
						)}
					</div>
				</div>

				<div className={HeaderStyles.faqAndOptionsContainer}>
					<div
						className={HeaderStyles.faqButton}
						onClick={gotoFAQPage}
					>
						<FormattedMessage id="faq" />
					</div>
					<HeaderOptions
						courseId={courseId}
						isPrimariOptionsInvisible={true}
						isMobile={isMobile}
					/>
				</div>
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};

export const ResponsiveHeader: React.FC<{
	onClose: () => void;
}> = ({ onClose }) => {
	const history = useHistory();
	const { height } = useWindowSize();
	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				//
			});
		history.push(`/`);
		onClose();
	}, [history, onClose]);

	return (
		<div>
			<div
				className={HeaderStyles.userOptionsInvDiv}
				style={{ height: height }}
			>
				<div className={HeaderStyles.userOptions}>
					<span onClick={onLogout}>
						<FormattedMessage id="logout" />
					</span>
				</div>
			</div>
		</div>
	);
};
