import { BackgroundTwoWaves } from "@app/components/backgrounds";
import { useHistory } from "@app/hooks/front";
import { useUserActions } from "@app/hooks/user-actions";
import { useClassroomsUser } from "@app/hooks/users";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback } from "react";
import { match } from "react-router";
import { Header } from ".";
import { EmptyFolder, MainButton } from "../exercises";
import HeaderStyles from "./styles/header.module.css";
import { ReactComponent as NotificationIcon } from "./styles/img/notification.svg";
import { ReactComponent as Illustration } from "./styles/img/illustration.svg";
import { ReactComponent as HomeIcon } from "./styles/img/home.svg";
import { useBoolean } from "@app/hooks/general";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import classNames from "classnames";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";

export const EmptyCourse: React.FC<{
	match: match<{ [key: string]: string | undefined }>;
}> = props => {
	const HistoryCourseId = "5dbbed65cdf7ad1af80c8846";
	const history = useHistory();
	const mainText = getFormattedMessage("courses:englishUpperFirstPart");
	const mainTextDate = getFormattedMessage("courses:englishDatePart");
	const mainTextLastWord = getFormattedMessage(
		"courses:englishUpperLastPart"
	);
	const mainTextDividedParts: string[] = [
		mainText,
		mainTextDate,
		mainTextLastWord,
	];

	const buttonText = getFormattedMessage("courses:georgianCourseButton");
	const user = useClassroomsUser();
	const {
		value: ispopupVisible,
		setTrue: openPopup,
		setFalse: hidePopup,
	} = useBoolean(false);
	const userActions = useUserActions(user ? user.id : null);
	const requestedGeorgianCourse = !userActions.isSuccessfullyLoaded
		? null
		: !!userActions.doc.actions.requestGeorgianCourse;

	const gotMainPage = useCallback(() => {
		history.push(`/courses/${HistoryCourseId}`);
	}, [history]);

	const openNotificationPopup = useCallback(() => {
		openPopup();
		triggerEvent(
			{
				category: "Button",
				action: "request notification about english course",
				label: "notification about english course",
			},
			{}
		);
		if (requestedGeorgianCourse === null || requestedGeorgianCourse) return;
		const UserActionsController = inject("UserActionsController");
		UserActionsController.performUserAction({
			name: "requestGeorgianCourse",
			data: new Date(),
		});
	}, [openPopup, requestedGeorgianCourse]);

	return (
		<>
			<BackgroundTwoWaves />
			<Header {...props.match.params} isPrimariOptionsInvisible={true} />
			<EmptyFolder
				buttonText={"შემატყობინეთ"}
				mainText={mainText}
				mainTextDividedParts={mainTextDividedParts}
				onClick={openNotificationPopup}
				textColor="white"
				textClassname={HeaderStyles.georgianTextClassname}
				secondText={
					"გსურს, შეგატყობინოთ თუ როდის დაემატება ინგლისურის კურსი?"
				}
				secondTextClassname={HeaderStyles.secondTextClassname}
				buttonClassname={HeaderStyles.notificationButtonClassname}
				icon={
					<NotificationIcon
						className={HeaderStyles.notificationIcon}
					/>
				}
				illustration={
					<Illustration className={HeaderStyles.illustration} />
				}
				highlitedTextPartsIndex={1}
			/>
			{ispopupVisible && (
				<Popup onClose={hidePopup}>
					<PopupContentWithClose onClose={hidePopup}>
						<div
							className={HeaderStyles.notificationPopupContainer}
						>
							<div className={HeaderStyles.notificationMainText}>
								მადლობა დაინტერესებისთვის. ინგლისურის კურსის
								დამატებისთანავე მიიღებ შეტყობინებას
							</div>
							<MainButton
								buttonText={buttonText}
								onClick={gotMainPage}
								buttonClassname={classNames(
									HeaderStyles.mainPageButton,
									HeaderStyles.notificationButtonClassname
								)}
								icon={
									<HomeIcon
										className={
											HeaderStyles.notificationIcon
										}
									/>
								}
							/>
						</div>
					</PopupContentWithClose>
				</Popup>
			)}
		</>
	);
};
