import { useMountingInfo } from "./general";
import { useState, useCallback, useEffect, useMemo, useRef } from "react";

const defaultResourceKey = "doc";
export type DefaultResourceKey = typeof defaultResourceKey;

export type IResourceLoadingInfo<
	DOC extends {},
	LoadFunc extends (() => void) | undefined = () => void,
	ErrorType = any,
	ExtraProps = {},
	ResourceKey extends string = DefaultResourceKey
> = (ExtraProps extends {} ? ExtraProps : {}) &
	(
		| ({
				isSuccessfullyLoaded: true;
				isIdentificationKnown: true;
				hasFoundError: false;
		  } & Record<ResourceKey, DOC>)
		| ({
				isSuccessfullyLoaded: false;
				hasFoundError: false;
				isIdentificationKnown: boolean;
		  } & Record<ResourceKey, undefined>)
		| ({
				isSuccessfullyLoaded: false;
				hasFoundError: true;
				isIdentificationKnown: true;
				error: ErrorType;
		  } & Record<ResourceKey, undefined> &
				(LoadFunc extends undefined ? {} : { loadAgain: LoadFunc }))
	);

export function getResourceLoadingInfo<
	DOC extends {},
	LoadFunc extends (() => void) | undefined = undefined,
	ErrorType = any,
	ExtraProps = {},
	ResourceKey extends string = DefaultResourceKey
>({
	resource,
	error,
	loadAgain,
	isIdentificationKnown,
	extraProps,
	resourceKey,
}: {
	resource: DOC | null | undefined;
	error: ErrorType;
	loadAgain: LoadFunc;
	isIdentificationKnown: boolean;
	extraProps?: ExtraProps;
	resourceKey?: ResourceKey;
}): IResourceLoadingInfo<DOC, LoadFunc, ErrorType, ExtraProps, ResourceKey> {
	const keyOfDoc = ((!resourceKey
		? defaultResourceKey
		: resourceKey) as unknown) as ResourceKey;

	if (resource) {
		return {
			...extraProps,
			isSuccessfullyLoaded: true,
			isIdentificationKnown: true,
			hasFoundError: false,
			[keyOfDoc]: resource,
		};
	}
	if (error) {
		return {
			...extraProps,
			[keyOfDoc]: undefined,
			isSuccessfullyLoaded: false,
			hasFoundError: true,
			error,
			isIdentificationKnown: true,
			...({ loadAgain } as any),
		} as Extract<
			IResourceLoadingInfo<
				DOC,
				LoadFunc,
				ErrorType,
				ExtraProps,
				ResourceKey
			>,
			{ hasFoundError: true }
		>;
	}
	return {
		...extraProps,
		[keyOfDoc]: undefined,
		isSuccessfullyLoaded: false,
		hasFoundError: false,
		isIdentificationKnown,
	};
}

export function useResourceInfoWithLoading<
	DOC extends {},
	ExtraProps extends {},
	FetchingInfo extends any,
	Fetch extends (args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>(args: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs?: false;
	forcefullyFetch?: boolean;
	extraProps?: ExtraProps;
}): IResourceLoadingInfo<DOC, () => void, ErrorType, ExtraProps>;
export function useResourceInfoWithLoading<
	DOC extends {},
	ExtraProps extends {},
	FetchingInfo extends readonly any[],
	Fetch extends (...args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>(args: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs: true;
	forcefullyFetch?: boolean;
	extraProps?: ExtraProps;
}): IResourceLoadingInfo<DOC, () => void, ErrorType, ExtraProps>;
export function useResourceInfoWithLoading<
	DOC extends {},
	ExtraProps extends {},
	FetchingInfo extends any,
	Fetch extends (args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>({
	resource,
	fetchingArg,
	fetch,
	isIdentificationKnown,
	onResourceLoading,
	multiArgs,
	forcefullyFetch,
	extraProps,
}: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs?: boolean;
	forcefullyFetch?: boolean;
	extraProps?: ExtraProps;
}): IResourceLoadingInfo<DOC, () => void, ErrorType, ExtraProps> {
	const [error, setError] = useState<ErrorType | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const mountingInfo = useMountingInfo();

	const argsRef = useRef(fetchingArg);
	argsRef.current = fetchingArg;

	const resourceRef = useRef(resource);
	resourceRef.current = resource;

	const errorRef = useRef(error);
	errorRef.current = error;

	const fetchRef = useRef(fetch);
	fetchRef.current = fetch;

	const resourceLoading = useRef(onResourceLoading);
	resourceLoading.current = onResourceLoading;

	const calledAtLeastOnceRef = useRef(false);

	const resCallingCountRef = useRef(0);

	const getResource = useCallback(() => {
		if (mountingInfo.hasFinishedFirstMountingCycle) {
			if (errorRef.current !== null) setError(null);
			if (resourceLoading.current) resourceLoading.current(null);
		}
		const args = argsRef.current;
		const promise = (!multiArgs
			? fetchRef.current!(args)
			: (fetchRef.current as any)(...((args as any) as any[]))) as
			| Promise<DOC>
			| undefined;
		if (!promise) {
			return;
		}
		setIsLoading(true);
		const callingCount = resCallingCountRef.current;
		promise
			.then((data: DOC) => {
				calledAtLeastOnceRef.current = true;
				if (
					!mountingInfo.isMounted ||
					resCallingCountRef.current !== callingCount
				) {
					return;
				}
				setIsLoading(false);
				if (resourceLoading.current) resourceLoading.current(data);
				return data;
			})
			.catch(e => {
				calledAtLeastOnceRef.current = true;
				if (
					!mountingInfo.isMounted ||
					resCallingCountRef.current !== callingCount
				) {
					return;
				}
				setError(e);
				setIsLoading(false);
				if (resourceLoading.current) resourceLoading.current(undefined);
			});
	}, [
		mountingInfo.hasFinishedFirstMountingCycle,
		mountingInfo.isMounted,
		multiArgs,
	]);

	const getResourceRef = useRef(getResource);
	getResourceRef.current = getResource;

	const memoizedFetchingArgs = useMemo(
		() => JSON.stringify(multiArgs ? [fetchingArg] : fetchingArg),
		[fetchingArg, multiArgs]
	);

	useEffect(() => {
		if (
			isIdentificationKnown &&
			(mountingInfo.hasFinishedFirstMountingCycle ||
				!resourceRef.current ||
				forcefullyFetch)
		) {
			resCallingCountRef.current++;
			calledAtLeastOnceRef.current = false;
			getResourceRef.current();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedFetchingArgs, isIdentificationKnown, forcefullyFetch]);

	const resourceNotReady = forcefullyFetch && !calledAtLeastOnceRef.current;

	const stringifiedExtraProps = JSON.stringify(extraProps);

	return useMemo(() => {
		return getResourceLoadingInfo({
			resource: isLoading || resourceNotReady ? null : resource,
			error: (isLoading ? null : error) as ErrorType,
			loadAgain: getResource,
			isIdentificationKnown,
			extraProps,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isLoading,
		resourceNotReady,
		resource,
		error,
		getResource,
		isIdentificationKnown,
		stringifiedExtraProps,
	]);
}

export const useMemoizedResponse = <Fn extends (...args: any) => Promise<R>, R>(
	promiseFn: Fn
): Fn => {
	const promiseFnRef = useRef(promiseFn);
	promiseFnRef.current = promiseFn;
	const momoizedResultRef = useRef({ called: false } as
		| { called: false }
		| { called: true; serializedArgs: string; result: R });
	return useCallback(
		((...args) => {
			const serializedArgs = JSON.stringify(args);
			if (
				momoizedResultRef.current.called &&
				momoizedResultRef.current.serializedArgs === serializedArgs
			) {
				return Promise.resolve(momoizedResultRef.current.result);
			}
			return promiseFnRef.current(...args).then(
				(data): R => {
					momoizedResultRef.current = {
						called: true,
						result: data,
						serializedArgs,
					};
					return data;
				}
			);
		}) as Fn,
		[]
	);
};

export type FetchingDoc<T, DOCKey extends string = "doc"> =
	| ({ hasFound: true } & Record<DOCKey, T>)
	| ({ hasFound: false } & { [key in DOCKey]?: undefined });
