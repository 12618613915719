import React, { useMemo } from "react";
import { useBoolean } from "@app/hooks/general";
import HeaderStyles from "./styles/hoverable-select.module.css";

interface HoverableSelectProps<Value extends string | number> {
	options: { value: Value; label: JSX.Element | string }[];
	title: JSX.Element | string;
	onChange?: (selectedOption: {
		value: Value;
		label: JSX.Element | string;
	}) => void;
	selected?: Value;
	actOnHover: boolean;
	actOnHeaderClick: boolean;
	styles?: React.CSSProperties;
	icon?: JSX.Element;
}

export const HoverableSelect = <Value extends string | number>(
	props: HoverableSelectProps<Value>
) => {
	const {
		value: isMenuVisible,
		setTrue: showMenu,
		setFalse: hideMenu,
		switchValue: switchMenu,
	} = useBoolean(false);
	const menuStyles = useMemo(
		(): React.CSSProperties => ({
			width: "max-content",
			fontFamily: "Roboto Geo Nus",
			color: "#5273e6",
			minWidth: 150,
			fontSize: 14,
			display: isMenuVisible ? "block" : "none",
		}),
		[isMenuVisible]
	);
	return (
		<div
			className={HeaderStyles.swithcerContainer}
			onMouseEnter={props.actOnHover ? showMenu : undefined}
			onClick={props.actOnHeaderClick ? switchMenu : undefined}
			onMouseLeave={props.actOnHover ? hideMenu : undefined}
			style={{
				cursor: props.options.length > 0 ? "pointer" : "initial",
			}}
		>
			<div className={HeaderStyles.selected} style={{ ...props.styles }}>
				<div className={HeaderStyles.title}>{props.title}</div>
				<div style={{ marginLeft: 5 }}>
					{props.options.length > 0 && props.icon && props.icon}
				</div>
			</div>
			{props.options.length > 0 && (
				<MenuOptions<Value>
					style={menuStyles}
					options={props.options}
					onSelect={(option, e) => {
						e.stopPropagation();
						hideMenu();
						if (props.onChange) {
							props.onChange(option);
						}
					}}
					selected={props.selected}
				/>
			)}
		</div>
	);
};

interface MenuOptionsProps<Value extends string | number> {
	style?: React.CSSProperties;
	options: { value: Value; label: JSX.Element | string }[];
	onSelect: (
		option: { value: Value; label: JSX.Element | string },
		e: React.MouseEvent
	) => void;
	selected?: Value;
}

export const MenuOptions = <Value extends string | number>(
	props: MenuOptionsProps<Value>
) => {
	return (
		<div className={HeaderStyles.listContainer} style={props.style}>
			<div className={HeaderStyles.list}>
				{props.options.map(option => (
					<div
						className={HeaderStyles.item}
						key={option.value}
						onClick={e => props.onSelect(option, e)}
						style={
							props.selected === option.value
								? { backgroundColor: "#445eea", color: "white" }
								: undefined
						}
					>
						{option.label}
					</div>
				))}
			</div>
		</div>
	);
};
