import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { useModelDocByQuery } from "m-model-react";
import { useMemo, useEffect } from "react";
import { useResourceInfoWithLoading } from "./fetch";

const fetchTaskTypeLevels = (courseId: ObjectId) => {
	const TaskTypesController = inject("TaskTypesController");
	return TaskTypesController.getLevels({ courseId });
};

export const useUserTaskTypeLevels = (
	coursesUserId: number | null,
	courseId: ObjectId | null
) => {
	const query = useMemo(
		() =>
			!coursesUserId || !courseId
				? null
				: { userId: coursesUserId, courseId },
		[courseId, coursesUserId]
	);
	const taskTypeLevels = useModelDocByQuery(
		inject("UserTaskTypeLevelModel"),
		query
	);

	const needsRecalculation = !!(
		taskTypeLevels && taskTypeLevels.needsRecalculation
	);

	useEffect(() => {
		if (!needsRecalculation || !courseId) return;
		fetchTaskTypeLevels(courseId);
	}, [courseId, needsRecalculation]);

	const resourceInfo = useResourceInfoWithLoading({
		resource: taskTypeLevels,
		fetchingArg: courseId!,
		fetch: fetchTaskTypeLevels,
		isIdentificationKnown: !!coursesUserId && !!courseId,
	});
	return resourceInfo;
};
