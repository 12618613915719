import { useCallback, useMemo } from "react";
import { useStoreProperty } from "./store";
import { User } from "@app/user";
import { IRUser, IRCoursesUser } from "@app/api/users/helper-schemas";
import { CoursesUser } from "@app/user/courses-user";
import { inject } from "@app/modules";
import { useModelDocById } from "m-model-react";
import { useResourceInfoWithLoading, IResourceLoadingInfo } from "./fetch";
import { UserInfo } from "@app/models/user-info";
import { useFetch } from "./fetch3";

export const useClassroomsUser = (): User | undefined => {
	const user = useStoreProperty("user");
	const getUserInstance = useCallback((userObj: IRUser) => {
		try {
			return User.createUserInstance(userObj);
		} catch (e) {
			const AuthController = inject("AuthController");
			AuthController.logout();
			return undefined;
		}
	}, []);
	const userInfo = useMemo(() => {
		if (!user || !user.userData) return undefined;
		return getUserInstance(user.userData);
	}, [getUserInstance, user]);
	return userInfo;
};

export const useClassroomsUserId = (): number | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.id;
};

export const useMurtskuUserId = (): number | null | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.murtskuId;
};

export const useCoursesUser = (): CoursesUser | undefined => {
	const user = useStoreProperty("user");
	const getUserInstance = useCallback((userObj: IRCoursesUser) => {
		return CoursesUser.createUserInstance(userObj);
	}, []);
	const userInfo = useMemo(() => {
		if (!user || !user.coursesUserData) return undefined;
		return getUserInstance(user.coursesUserData);
	}, [getUserInstance, user]);
	return userInfo;
};

export const useCoursesUserId = (): number | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.coursesUserData) return undefined;
	return user.coursesUserData.id;
};

export const useStudentInfo = () => {
	const userId = useClassroomsUserId();
	const studentInfo = useModelDocById(
		inject("StudentInfoModel"),
		userId || null
	);
	return studentInfo;
};

const fetchUserShortInfo = (userId: number | null) => {
	const UserInfoModel = inject("UserInfoModel");
	if (!userId) return;
	const UsersController = inject("UsersController");
	return UsersController.getUserShortInfo({ userId }).then(data => {
		return new UserInfoModel(data);
	});
};

export const useUserShortInfo = (
	userId: number | null
): IResourceLoadingInfo<UserInfo> => {
	const userShortInfo = useModelDocById(
		inject("UserInfoModel"),
		userId || null
	);

	return useResourceInfoWithLoading({
		resource: userShortInfo,
		fetchingArg: userId!,
		fetch: fetchUserShortInfo,
		isIdentificationKnown: !!userId,
	});
};

export const useHasUserAgreedOnTerms = (): boolean | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return false;
	return user.userData.hasAgreedOnTerms;
};

export const useManyUsersShortInfo = (userIds: number[]) => {
	return useFetch(
		() => inject("UsersController").getUsersShortInfo({ userIds }),
		[userIds]
	);
};
