import { useMemo, useEffect } from "react";
import { useClassroomsUser, useClassroomsUserId } from "./users";
import { useUserActions } from "./user-actions";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";

const specialCourseIds = ["5e33c4a1dd33074a75179ff5"];

export const useUnseenSpecialCourses = (): ObjectId[] | null => {
	const user = useClassroomsUser();
	const userActions = useUserActions(user ? user.id : null);
	const courses = user ? user.getAccessibleCourseIds() : null;
	return useMemo(() => {
		if (!courses || !userActions.isSuccessfullyLoaded || !courses.isKnown) {
			return null;
		}
		const courseIds = courses.hasAll
			? specialCourseIds
			: courses.ids.filter(
					courseId => specialCourseIds.indexOf(courseId) > -1
			  );
		return courseIds.filter(
			courseId =>
				!userActions.doc.actions[`seenSpecialCourse-${courseId}`]
		);
	}, [courses, userActions]);
};

export const useSeeingSpecialCourse = (courseId: ObjectId | null) => {
	const userId = useClassroomsUserId();
	const userActions = useUserActions(userId || null);
	const isAboutToView =
		!courseId || !userActions.isSuccessfullyLoaded
			? false
			: !userActions.doc.actions[`seenSpecialCourse-${courseId}`];
	useEffect(() => {
		if (!isAboutToView || !courseId) return;
		const UserActionsController = inject("UserActionsController");
		if (specialCourseIds.indexOf(courseId) === -1) {
			return;
		}
		UserActionsController.performUserAction({
			name: `seenSpecialCourse-${courseId}`,
			data: new Date(),
		}).then();
	}, [courseId, isAboutToView]);
};
