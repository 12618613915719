import React, { useEffect } from "react";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";

const lastCourseIdKey = "lastVisitedCourseId";

export const LastCourseSaver: React.FC<{
	match: match<{ courseId: ObjectId }>;
}> = React.memo(props => {
	const courseId = props.match.params.courseId;
	useEffect(() => {
		if (!courseId) return;
		localStorage.setItem(lastCourseIdKey, courseId);
	}, [courseId]);
	return null;
});

export const getLastCourseId = (): ObjectId | null => {
	return localStorage.getItem(lastCourseIdKey);
};

export const getCourseIdFromUrl = (url: string): ObjectId | null => {
	const matchKeyword = "courses/";
	const index = url.indexOf(matchKeyword);
	const closingSlashIndex = url.indexOf(
		matchKeyword,
		index + matchKeyword.length
	);
	if (closingSlashIndex > 0) {
		return url.substring(index + matchKeyword.length, closingSlashIndex);
	}
	return url.substring(index + matchKeyword.length);
};
