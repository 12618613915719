import {
	UserShortInfoSchema,
	IUserShortInfo,
} from "@app/api/users/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "id";
type IdKey = typeof keyOfId;
type DOC = IUserShortInfo;
export type IStateUsersInfo = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(UserShortInfoSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("userInfo", "usersInfo");
const storageSettings = getDefaultStorageSettings("usersInfo");
const metaInformationName = "usersInfoMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().usersInfo) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", UserShortInfoSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class UserInfo extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByUserIdSync = Model.findByIdSync;
	static findManyByUserIdsSync = Model.findManyByIdsSync;

	getFullName = (
		arg: { priority: "firstname" | "username" } = {
			priority: "firstname",
		}
	) => {
		return getNameOfUser(this, arg);
	};

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, UserInfo.meta);

export const usersInfoReducer = getDefaultReducer(
	storageSettings,
	() => UserInfo
);

//

const getNameOfUser = (
	user: Pick<IUserShortInfo, "firstname" | "lastname" | "username">,
	arg: { priority: "firstname" | "username" } = { priority: "firstname" }
): string => {
	const { priority } = arg;
	const fName =
		!user.firstname && !user.lastname
			? null
			: `${user.firstname || ""} ${user.lastname || ""}`;
	if (priority === "firstname") {
		return fName || user.username || "";
	}
	return user.username || fName || "";
};
