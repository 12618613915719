import { UserType } from "@app/api/helper-schemas";
import { useBoolean } from "@app/hooks/general";
import classNames from "classnames";
import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { LoginPopup } from "../landing/login";
import { RegisterPopup } from "../landing/register-type-selection";
import { ReactComponent as TVSchoolLogo } from "./styles/imgs/tv-school-logo.svg";
import styles from "./styles/tv-school-landing.module.css";
import { ReactComponent as VideoIcon } from "./styles/imgs/video.svg";
import { VideoInstruction } from "./video-instruction";
import { useWindowSize } from "@app/hooks/front";
import { Calls } from "../widgets/calls";
import { ReactComponent as LupiLogo } from "@app/components/landing/styles/img/lupi-ai.svg";
import { ReactComponent as TvschoolNL } from "./styles/imgs/tv-school-nl.svg";
import { ReactComponent as TvschoolEN } from "./styles/imgs/tv-school-en.svg";

import {
	getHTMLElementCoords,
	animateWindowScroll,
} from "@tests-core/utils/dom";
import { useLocale } from "@app/hooks/intl";

export type DivReference = React.MutableRefObject<HTMLDivElement | null>;

export const TVSchoolHeader: React.FC<{
	variant?: "white" | "colored";
	isStudentHeader?: boolean;
	faqRef?: DivReference;
	openLoginPage?: React.Dispatch<React.SetStateAction<boolean>>;
	openRegistarionPage?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
	variant = "white",
	isStudentHeader,
	faqRef,
	openLoginPage,
	openRegistarionPage,
}) => {
	const [defaultSelectedUserType, setDefaultSelectedUserType] = useState<
		UserType
	>();

	const history = useHistory();

	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const isEn = locale === "en";

	const isMainPage = history.location.pathname === "/";

	const onClickLogo = useCallback(() => {
		history.push(`/`);
		// window.location.reload(false);
	}, [history]);

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(
		(userType?: UserType) => {
			setDefaultSelectedUserType(userType);
			closeLoginPopup();
			openRegistrationPopup();
		},
		[closeLoginPopup, openRegistrationPopup]
	);

	const {
		value: isVideoInstructioVissible,
		setFalse: hideVideoInstruction,
		setTrue: setVideoInstructionVissible,
	} = useBoolean(false);

	const { width } = useWindowSize();
	const isResponsive = width < 1100;

	const scrollToFaq = useCallback((ref: DivReference | null) => {
		if (!ref) return;
		if (!ref.current) return;
		const coordinates = getHTMLElementCoords(ref.current);
		animateWindowScroll(coordinates.top - 90, 300);
	}, []);

	const isVideoInstructionButtonInvissible = isStudentHeader || isNl;

	const videoInstructionButton = isVideoInstructionButtonInvissible ? null : (
		<div
			className={styles.videoInstructionButton}
			onClick={setVideoInstructionVissible}
		>
			{isResponsive ? (
				<FormattedMessage id="instruction" />
			) : (
				<>
					<VideoIcon className={styles.videoIcon} />
					<FormattedMessage id="videoInstruction" />
				</>
			)}
		</div>
	);

	const videoId = isEn ? "-RLUtRSKc-4" : "zTIoUoGzzqE";

	const onRedirectToLogin = useCallback(() => {
		if (openLoginPage && openRegistarionPage) {
			openLoginPage(true);
			openRegistarionPage(false);
		}
	}, [openLoginPage, openRegistarionPage]);

	const onRedirectToRegistration = useCallback(() => {
		if (openLoginPage && openRegistarionPage) {
			openLoginPage(false);
			openRegistarionPage(true);
		}
	}, [openLoginPage, openRegistarionPage]);

	return (
		<>
			<div className={classNames(styles.header, styles[variant])}>
				<div className={styles.logoAndFaq}>
					{isGeo ? (
						<TVSchoolLogo
							className={styles.logo}
							onClick={onClickLogo}
						/>
					) : isNl ? (
						<TvschoolNL
							className={styles.nllogo}
							onClick={onClickLogo}
						/>
					) : (
						<LupiLogo style={{ height: "100%", width: 112 }} />
					)}
					{width > 800 && !isStudentHeader && (
						<div>
							<Calls
								containerClassname={
									variant === "colored"
										? styles.callsColored
										: styles.calls
								}
							/>
						</div>
					)}
				</div>

				<div className={styles.headerButtonsContainer}>
					{videoInstructionButton}

					{width > 800 && (
						<div
							className={styles.faqButton}
							onClick={() => scrollToFaq(faqRef || null)}
						>
							<FormattedMessage id="faq" />
						</div>
					)}

					{width > 800 && (
						<div
							className={styles.headerButton}
							onClick={
								openLoginPage ? onRedirectToLogin : openLogin
							}
						>
							<FormattedMessage id="signInTitle" />
						</div>
					)}

					<div className={styles.signUpAndSignInButtons}>
						<div
							onClick={() =>
								openLoginPage
									? onRedirectToRegistration()
									: openRegisterPage(
											isMainPage
												? undefined
												: UserType.student
									  )
							}
							className={styles.headerRegistrationButton}
						>
							<FormattedMessage id="signUp" />
						</div>
					</div>

					{isRegistrationPopupOn && (
						<RegisterPopup
							onClose={closeRegistrationPopup}
							isOnlyPopup={true}
							hideLogin={closeLoginPopup}
							isLoginPopupOpen={isLoginPopupOn}
							openLogin={openLogin}
							inputClassname={StudentRegisterStyles.inputInPopup}
							registrationButtonClassname={
								StudentRegisterStyles.registrationButtoInPopup
							}
							isCustomStylesInvissible={true}
							isRegisterIllustrationVissible={true}
							userType={isMainPage ? undefined : UserType.student}
							isCountryInputInvissible={true}
							isToTest={!!openLoginPage}
						/>
					)}
					{isLoginPopupOn && (
						<LoginPopup
							onClose={closeLoginPopup}
							hideRegisterPage={closeRegistrationPopup}
							isRegisterPageOpen={isRegistrationPopupOn}
							openRegisterPage={openRegisterPage}
							isIconVissible={false}
							isUpperTextVissible={true}
							placeholder={"   "}
							inputClassname={StudentRegisterStyles.inputForLogin}
							isLowerMainQuestionVissible={true}
							isFromTvSchoolPopUp={isGeo}
						/>
					)}
					{isVideoInstructioVissible && (
						<VideoInstruction
							onClose={hideVideoInstruction}
							videoId={videoId}
						/>
					)}
				</div>
			</div>

			<div className={styles.containerPlaceholder} />
		</>
	);
};
