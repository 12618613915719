import {
	IADELETECourse,
	IAGETCourse,
	IAGETCourseHierarchy,
	IAGETCourseReminder,
	IAGETGetCourseFolders,
	IAPOSTCourseReminder,
	IAPOSTCreateCourse,
	IAPOSTUploadCourseMedia,
	IAPUTCourse,
	IAPUTCourseReminder,
	IRGETAllCourses,
	IRGETCourse,
	IRGETCourseFolders,
	IRGETCourseHierarchy,
	IRGETCourseReminder,
	IRPOSTCreateCourse,
	IRPOSTGetCourseMedia,
	IRPOSTUploadCourseMedia,
	RGETAllCoursesSchema,
	RGETCourseFoldersSchema,
	RGETCourseHierarchySchema,
	RGETCourseSchema,
	RPOSTCreateCourseSchema,
	RPOSTGetCourseMediaSchema,
	RPOSTUploadCourseMediaSchema,
	IAGETManyCoursesByIds,
	IRGETManyCoursesByIds,
	RGETManyCoursesByIdsSchema,
	IAGETCourseFilteredContent,
	IRGETCourseFilteredContent,
	RGETCourseFilteredContentSchema,
} from "./validators";
import { inject } from "@app/modules";
import { IRequest } from "../requests";
import { PromisesKeeperAPI } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";
import { HierarchyToFoldersComparer } from "@app/services/hierarchy-info/folders/comparer";
import { Course } from "@app/models/course";
import { FolderHierarchy } from "@app/models/folder-hierarchy";
import { Folder } from "@app/models/folder";

export class CoursesController {
	private readonly Request: IRequest;

	private readonly _CourseModel = inject("CourseModel");
	private readonly _FolderModel = inject("FolderModel");
	private readonly _FolderHierarchyModel = inject("FolderHierarchyModel");
	private readonly _TopicHierarchyModel = inject("TopicHierarchyModel");
	private readonly _TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	private readonly _TopicModel = inject("TopicModel");
	private readonly _TaskTypeModel = inject("TaskTypeModel");

	private coursePromises = new PromisesKeeperAPI<ObjectId, Course>();

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCourse): Promise<IRPOSTCreateCourse> =>
		this.Request.send("POST", "/api/courses", args, null, {
			responseSchema: RPOSTCreateCourseSchema,
		}).then((data: IRPOSTCreateCourse) => {
			this._CourseModel.loadOneSync(data.course);
			this._FolderModel.loadOneSync(data.rootFolder);
			this._TopicModel.loadOneSync(data.rootTopic);
			this._TaskTypeModel.loadOneSync(data.rootTaskType);
			this._FolderHierarchyModel.loadOneSync(
				data.hierarchies.folderHierarchy
			);
			this._TopicHierarchyModel.loadOneSync(
				data.hierarchies.topicHierarchy
			);
			this._TaskTypeHierarchyModel.loadOneSync(
				data.hierarchies.taskTypeHierarchy
			);
			return data;
		});

	getById = async (
		args: IAGETCourse,
		loadFresh?: boolean
	): Promise<Course> => {
		if (!loadFresh) {
			const course = this._CourseModel.findByIdSync(args._id);
			if (course) return course;
		}
		return this.coursePromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/courses/:_id", args, null, {
				responseSchema: RGETCourseSchema,
			}).then((data: IRGETCourse) => {
				return this._CourseModel.loadOneSync(data);
			})
		);
	};

	getByIds = async (
		args: IAGETManyCoursesByIds,
		loadFresh?: boolean
	): Promise<Course[]> => {
		if (!loadFresh) {
			const courses = this._CourseModel.findManyByIdsSync(args.ids);
			if (courses.length === args.ids.length) {
				return courses;
			}
		}
		return this.Request.send(
			"POST",
			"/api/courses/get-by-ids",
			args,
			null,
			{
				responseSchema: RGETManyCoursesByIdsSchema,
			}
		).then((data: IRGETManyCoursesByIds) => {
			return this._CourseModel.loadManySync(data, args.ids);
		});
	};

	getHierarchies = async (
		args: IAGETCourseHierarchy,
		loadFresh?: boolean
	): Promise<IRGETCourseHierarchy> => {
		if (!loadFresh) {
			const folderHierarchy = this._FolderHierarchyModel.findOneByCourseSync(
				args._id
			);
			if (folderHierarchy) {
				const topicHierarchy = this._TopicHierarchyModel.findOneByCourseSync(
					args._id
				);
				if (topicHierarchy) {
					const taskTypeHierarchy = this._TaskTypeHierarchyModel.findOneByCourseSync(
						args._id
					);
					if (taskTypeHierarchy) {
						return {
							folderHierarchy,
							topicHierarchy,
							taskTypeHierarchy,
						};
					}
				}
			}
		}
		return this.coursePromises.getOrSetPromise(
			Symbol.for("course " + args._id),
			() =>
				this.Request.send(
					"GET",
					"/api/courses/:_id/hierarchies",
					args,
					null,
					{
						responseSchema: RGETCourseHierarchySchema,
					}
				).then((data: IRGETCourseHierarchy) => {
					this._FolderHierarchyModel.loadOneSync(
						data.folderHierarchy
					);
					this._TopicHierarchyModel.loadOneSync(data.topicHierarchy);
					this._TaskTypeHierarchyModel.loadOneSync(
						data.taskTypeHierarchy
					);

					const areFoldersWithSync = HierarchyToFoldersComparer.areFoldersWithSync(
						{
							hierarchy: data.folderHierarchy,
							folders: this._FolderModel.getAllSync(),
						}
					);
					if (!areFoldersWithSync) {
						this._FolderModel.clearAllSync();
					}
					return data;
				})
		);
	};

	update = async (args: IAPUTCourse): Promise<Course | null> =>
		this.Request.send("PUT", "/api/courses/:_id", args).then(() =>
			this._CourseModel.updateOneSync({ _id: args._id }, args)
		);

	deleteById = async (args: IADELETECourse): Promise<void> =>
		this.Request.send("DELETE", "/api/courses/:_id", args).then(() =>
			this._CourseModel.deleteByIdSync(args._id)
		);

	getAll = async (): Promise<Course[]> =>
		this.Request.send("GET", "/api/courses", undefined, null, {
			responseSchema: RGETAllCoursesSchema,
		}).then((data: IRGETAllCourses) => {
			this._CourseModel.meta.updateLoadTime();
			return this._CourseModel.loadManySync(data, "replaceAll");
		});

	uploadPhotos = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTUploadCourseMedia> =>
		this.Request.send("POST", "/api/photos/courses/:courseId", args, null, {
			responseSchema: RPOSTUploadCourseMediaSchema,
		});

	getMedia = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTGetCourseMedia> =>
		this.Request.send(
			"GET",
			"/api/photos/courses/:courseId/user",
			args,
			null,
			{
				responseSchema: RPOSTGetCourseMediaSchema,
			}
		);

	getAllMedia = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTGetCourseMedia> =>
		this.Request.send("GET", "/api/photos/courses/:courseId", args, null, {
			responseSchema: RPOSTGetCourseMediaSchema,
		});

	getAllFolders = async (
		args: IAGETGetCourseFolders
	): Promise<{
		rootFolderId: ObjectId;
		allFolders: Folder[];
		folderHierarchy: FolderHierarchy;
	}> =>
		this.coursePromises.getOrSetPromise(
			Symbol.for(`all-folders-${args.courseId}`),
			() =>
				this.Request.send(
					"GET",
					"/api/courses/:courseId/folders",
					args,
					null,
					{
						responseSchema: RGETCourseFoldersSchema,
					}
				).then((data: IRGETCourseFolders) => {
					this._FolderModel.meta.updateLoadTime(args.courseId);
					const allFolders = this._FolderModel.loadManySync(
						data.allFolders
					);
					const folderHierarchy = this._FolderHierarchyModel.loadOneSync(
						data.folderHierarchy
					);
					return {
						rootFolderId: data.rootFolderId,
						allFolders,
						folderHierarchy,
					};
				})
		);

	createReminder = async (args: IAPOSTCourseReminder): Promise<void> =>
		this.Request.send("POST", "/api/courses/:courseId/reminder", args);

	updateReminder = async (args: IAPUTCourseReminder): Promise<void> =>
		this.Request.send("PUT", "/api/courses/:courseId/reminder", args);

	getReminder = async (
		args: IAGETCourseReminder
	): Promise<IRGETCourseReminder> =>
		this.Request.send("GET", "/api/courses/:courseId/reminder", args);

	recreateFolderParentInfoObj = async (
		args: IAGETGetCourseFolders
	): Promise<void> =>
		this.Request.send(
			"PUT",
			"/api/courses/:courseId/recreate-folder-parent-info-obj",
			args
		);

	getCourseFilteredContent = async (
		args: IAGETCourseFilteredContent
	): Promise<IRGETCourseFilteredContent> =>
		this.Request.send(
			"POST",
			"/api/courses/:courseId/contents/filter",
			args,
			null,
			{
				responseSchema: RGETCourseFilteredContentSchema,
			}
		);
}
