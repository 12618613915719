import {
	IRGETCountForTeacher,
	IStudentHomeworkListItem,
} from "@app/api/assignments/validators";
import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { Assignment } from "@app/models/assignment";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById } from "m-model-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	getResourceLoadingInfo,
	IResourceLoadingInfo,
	useResourceInfoWithLoading,
} from "./fetch";
import { useForceUpdate, useMountingInfo } from "./general";
import { UserTestFetchInfo } from "./tests";
import { IFinalGETHomeworkListForStudent } from "@app/api/assignments/controller";
import { useFetch } from "./fetch3";

const fetchAssignment = (assignmentId: ObjectId) => {
	const AssignmentsController = inject("AssignmentsController");
	return AssignmentsController.getOneById(assignmentId);
};

export const useAssignment = (
	assignmentId: ObjectId | null
): IResourceLoadingInfo<Assignment> => {
	const assignment = useModelDocById(inject("AssignmentModel"), assignmentId);

	return useResourceInfoWithLoading({
		resource: assignment,
		fetchingArg: assignmentId!,
		fetch: fetchAssignment,
		isIdentificationKnown: !!assignmentId,
	});
};

export const useStudentClassroomAssignmentsInfo = (
	classroomId: ObjectId | null
) => {
	const [studentAssignmentsInfo, setStudentAssignmentsInfo] = useState<
		IFinalGETHomeworkListForStudent
	>();
	const mountingInfo = useMountingInfo();

	useEffect(() => {
		let isCancelled = false;
		if (mountingInfo.hasFinishedFirstMountingCycle) {
			setStudentAssignmentsInfo(undefined);
		}
		if (!classroomId) return;
		const AssignmentsController = inject("AssignmentsController");
		AssignmentsController.getStudentHomeworks({
			classroomId,
		}).then(data => {
			if (isCancelled) return;
			setStudentAssignmentsInfo(getSortedAssignmentsForStudent(data));
		});
		return () => {
			isCancelled = true;
		};
	}, [mountingInfo, classroomId]);
	return studentAssignmentsInfo;
};

export const useStudentClassroomCognitiveAssignmentsInfo = (
	cognitiveClassroomIds: ObjectId[] | null
) => {
	const [studentAssignmentsInfo, setStudentAssignmentsInfo] = useState<
		IFinalGETHomeworkListForStudent[]
	>([] as IFinalGETHomeworkListForStudent[]);

	useEffect(() => {
		let isCancelled = false;

		if (!cognitiveClassroomIds) return;
		const AssignmentsController = inject("AssignmentsController");

		cognitiveClassroomIds.map((id, i) => {
			if (i === cognitiveClassroomIds.length) return null;
			AssignmentsController.getStudentHomeworks({
				classroomId: id,
			}).then(data => {
				if (isCancelled) return;
				setStudentAssignmentsInfo(x => [
					...x,
					getSortedAssignmentsForStudent(data),
				]);
			});
		});

		return () => {
			isCancelled = true;
		};
	}, []);

	return studentAssignmentsInfo;
};

type IStudentHomeworkExistence =
	| { exists: false }
	| ({ exists: true } & IStudentHomeworkListItem);
export const useCurrentAssignmentInfo = (
	classroomId: ObjectId | null
): IResourceLoadingInfo<IStudentHomeworkExistence, undefined> => {
	const assignmentsInfo = useStudentClassroomAssignmentsInfo(classroomId);
	return useMemo(() => {
		if (!assignmentsInfo || assignmentsInfo.length === 0) {
			return getResourceLoadingInfo<IStudentHomeworkExistence, undefined>(
				{
					resource: !assignmentsInfo ? null : { exists: false },
					error: null,
					loadAgain: undefined,
					isIdentificationKnown: classroomId !== null,
				}
			);
		}
		const mostImportantAssignmentInfo = assignmentsInfo[0];
		return getResourceLoadingInfo<IStudentHomeworkExistence, undefined>({
			resource: Object.assign(mostImportantAssignmentInfo, {
				exists: true,
			} as const),
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: classroomId !== null,
		});
	}, [assignmentsInfo, classroomId]);
};

export const getSortedAssignmentsForStudent = <
	T extends IStudentHomeworkListItem
>(
	data: T[]
): T[] => {
	// TODO: implement more sophistically
	return [...data].sort((a, b) => {
		return (
			b.assignment.createdAt.getTime() - a.assignment.createdAt.getTime()
		);
	});
};

export const getDescSortedAssignmentsForStudent = <
	T extends IStudentHomeworkListItem
>(
	data: T[]
): T[] => {
	// TODO: implement more sophistically
	return [...data].sort((a, b) => {
		return (
			a.assignment.createdAt.getTime() - b.assignment.createdAt.getTime()
		);
	});
};

export const useStudentTestForTeacher = ({
	classroom,
	testId,
	studentId,
}: {
	classroom: IClassroom | null;
	testId: ObjectId | null;
	studentId: number;
}) => {
	const courseId = classroom ? classroom.course.courseId : null;
	const folderId = classroom ? classroom.course.customFolderIds.tests : null;

	const [studentTest, setStudentTest] = useState<UserTestFetchInfo>();

	const getStudentTest = useCallback(
		({
			courseId,
			folderId,
			testId,
			studentId,
		}: {
			courseId: ObjectId | null;
			folderId: ObjectId | null;
			testId: ObjectId | null;
			studentId: number | null;
		}): undefined | Promise<UserTestFetchInfo> => {
			if (!courseId || !folderId || !testId || !studentId) return;
			const AssignmentsController = inject("AssignmentsController");
			return AssignmentsController.getUserTestForTeacher({
				attempt: 1,
				courseId,
				folderId,
				testId,
				studentId,
			})
				.then(data => {
					if (data) {
						return { hasFound: true as true, test: data };
					}
					return { hasFound: false as false };
				})
				.then(state => {
					setStudentTest(state);
					return state;
				});
		},
		[]
	);
	return useResourceInfoWithLoading<
		UserTestFetchInfo,
		{},
		Parameters<typeof getStudentTest>[0],
		any
	>({
		resource: studentTest,
		fetchingArg: useMemo(
			() => ({ courseId, folderId, testId, studentId }),
			[courseId, folderId, studentId, testId]
		),
		fetch: getStudentTest,
		isIdentificationKnown:
			!!courseId && !!folderId && !!testId && !!studentId,
	});
};

const fetchteacherAssignmentsCount = () => {
	return inject("AssignmentsController").getCountForTeacher();
};

export const useTeacherAssignmentsCount = () => {
	const forceUpdate = useForceUpdate();
	const cnt = inject("AssignmentModel").meta.data.numOfCreatedAssignments;
	const assignmentCount = useMemo(
		(): IRGETCountForTeacher | null =>
			cnt === undefined || cnt === null ? null : { count: cnt },
		[cnt]
	);

	const assignmentsCountFetchInfo = useResourceInfoWithLoading({
		resource: assignmentCount,
		fetchingArg: undefined,
		fetch: fetchteacherAssignmentsCount,
		isIdentificationKnown: true,
	});

	useEffect(() => {
		let isCancelled = false;
		const unsubscribe = inject("AssignmentModel").meta.subscribeDataChange(
			() => {
				if (isCancelled) return;
				forceUpdate();
			}
		);
		return () => {
			isCancelled = true;
			unsubscribe();
		};
	}, [forceUpdate]);

	return assignmentsCountFetchInfo;
};

export const useAssignemntsByStudentAndClassroom = (
	student?: number,
	classroom?: ObjectId
) => {
	return useFetch(
		() =>
			inject("AssignmentsController").getHomeworksByStudentId({
				classroomId: classroom!,
				studentId: student!,
			}),
		[classroom, student]
	);
};
