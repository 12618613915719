import { ObjectId } from "@app/utils/generics";
import Settings from "@material-ui/icons/Settings";
import React, { useCallback, useContext, useRef, useState } from "react";
import { HomeworkContext, TopicsContext } from ".";
import styles from "../styles/styles.module.css";
import { AdvancesSettingsPopup } from "./small-components/advanced-settings";
import { AssignmentName } from "./small-components/name";
import { TotalNumOfQuestionsSlider } from "./small-components/num-of-questions-slider";
import { TopicsSelect } from "./small-components/topics-select";
import { GoBackButton } from "@app/components/profile/go-back-button";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { FormattedMessage } from "react-intl";
interface IOwnProps {
	onNextPage: () => void;
	classroomId: ObjectId;
}

export const CreatingAssignmentFirstStep = React.memo((props: IOwnProps) => {
	const [homework, dispatch] = useContext(HomeworkContext);
	const topicsInfo = useContext(TopicsContext);
	const defaultSelectedTopicIds = useRef(homework.topics.map(id => id));

	const [openPopup, setOpenPopup] = useState<boolean | null>(null);

	const onNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			dispatch({ type: "CHANGE_NAME", value: e.target.value });
		},
		[dispatch]
	);

	const onNumOfQuestionsChange = useCallback(
		(value: number) => {
			dispatch({
				type: "CHANGE_NUM_OF_QUESTIONS",
				value,
			});
		},
		[dispatch]
	);

	const onTopicsChange = useCallback(
		(_ids: ObjectId[]) => {
			dispatch({ type: "CHANGE_SELECTED_TOPICS", _ids });
		},
		[dispatch]
	);

	const history = useHistory();
	const goBack = useCallback(() => {
		history.push(`/classrooms/${props.classroomId}`);
	}, [history, props.classroomId]);
	const { width } = useWindowSize();

	return (
		<>
			<div className={styles.createHomeworkContainerSecondShadow} />
			<div className={styles.createHomeworkContainerFirstShadow} />
			<div className={styles.createHomeworkContainer}>
				<AssignmentName value={homework.name} onChange={onNameChange} />
				<TotalNumOfQuestionsSlider
					value={homework.totalNumOfQuestions}
					onChange={onNumOfQuestionsChange}
				/>
				<TopicsSelect
					topicsInfo={topicsInfo}
					defaultSelectedTopicIds={defaultSelectedTopicIds.current}
					onChange={onTopicsChange}
					className={styles.topicsSelectContainer}
					iconClassName={styles.multipleSelectIcon}
					multipleSelectClassName={styles.multipleSelect}
				/>
				<div className={styles.buttonsAndNextContainer}>
					{width > 1200 && (
						<div>
							<GoBackButton onClick={goBack} />
						</div>
					)}

					<div className={styles.buttonsContainer}>
						<NextButton onClick={props.onNextPage} />
						<button
							className={styles.settingStyles}
							onClick={() => setOpenPopup(true)}
						>
							<FormattedMessage id="teacher:assignment.advancedSettings" />
						</button>
					</div>
				</div>
			</div>
			{openPopup && (
				<AdvancesSettingsPopup onClose={() => setOpenPopup(false)} />
			)}
		</>
	);
});

const NextButton: React.FC<{
	onClick: () => void;
}> = React.memo(props => {
	return (
		<button className={styles.buttonStyles} onClick={props.onClick}>
			<div style={{ display: "inline-block" }}>
				<FormattedMessage id="continue" />
			</div>
		</button>
	);
});
