import { UserType } from "@app/api/helper-schemas";
import { IRootState } from "@app/redux";
import { History, Path } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { removeKeys } from "@app/utils/common";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	availableUserTypes?: UserType | UserType[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const GuestRoute = (props: IProps) => {
	return (
		<Route {...removeKeys(props as any, "component", "children")}>
			<RouteRendered {...props} />
		</Route>
	);
};

const RouteRendered = ({
	component: Component,
	isAuthenticated,
	location,
	...rest
}: IProps) => {
	const history = useHistory();
	const match = useRouteMatch();
	if (isAuthenticated) {
		if (location.pathname !== "/") {
			return <Redirect to="/" />;
		}
		return null;
	}
	return (
		<div>
			<Component
				{...rest}
				history={history}
				location={history.location}
				match={match}
				{...match.params}
			/>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	GuestRoute
);
