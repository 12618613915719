import { IClassroomJoinRequest } from "@app/api/classrooms/join-requests/helper-schemas";
import { addLoader } from "@app/common-javascript";
import { useClassroomInvitations } from "@app/hooks/classroom-join-requests";
import { useClassroom } from "@app/hooks/classrooms";
import { useUserShortInfo } from "@app/hooks/users";
import { inject } from "@app/modules";
import React, { useCallback } from "react";
import invitationStyles from "./styles/index.module.css";
import { useHistory } from "@app/hooks/front";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";

export const StudentInvitationsContainer: React.FC<{}> = React.memo(() => {
	const invitations = useClassroomInvitations({
		excludeUserAsRequester: true,
	});

	if (!invitations || invitations.length === 0) return null;

	return (
		<div>
			{invitations.map(e => (
				<StudentInvitation key={e._id} info={e} />
			))}
		</div>
	);
});

const StudentInvitation: React.FC<{ info: IClassroomJoinRequest }> = React.memo(
	props => {
		const history = useHistory();
		const teacherInfo = useUserShortInfo(props.info.requester.id);
		const classroomId = props.info.classroomId;
		const classroom = useClassroom(classroomId || null);

		const ClassroomJoinRequestsController = inject(
			"ClassroomJoinRequestsController"
		);

		const onApprove = useCallback(() => {
			const removeLoader = addLoader();
			ClassroomJoinRequestsController.approve(props.info._id)
				.then(() => {
					removeLoader();
					if (classroomId) {
						const ClassroomModel = inject("ClassroomModel");
						const classroom = ClassroomModel.findByIdSync(
							classroomId
						);
						if (classroom) {
							history.push(
								`/courses/${classroom.course.courseId}`
							);
						}
					}
				})
				.catch(e => {
					console.log(e);
					removeLoader();
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					});
				});
		}, [
			ClassroomJoinRequestsController,
			classroomId,
			history,
			props.info._id,
		]);

		const onReject = useCallback(() => {
			const removeLoader = addLoader();
			ClassroomJoinRequestsController.reject(props.info._id)
				.then(removeLoader)
				.catch(e => {
					console.log(e);
					removeLoader();
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					});
				});
		}, [ClassroomJoinRequestsController, props.info._id]);

		return (
			<div className={invitationStyles.invitationContainer}>
				<div>
					{!teacherInfo.isSuccessfullyLoaded &&
					teacherInfo.hasFoundError ? (
						<>
							<FormattedMessage id="student:errorOnLoaddingTeacher" />
							{". "}
							<u onClick={teacherInfo.loadAgain}>
								<FormattedMessage id="tryAgain" />
							</u>
						</>
					) : (
						<>
							<FormattedMessage id="teacher" />:{" "}
							{!teacherInfo.isSuccessfullyLoaded
								? "..."
								: teacherInfo.doc.getFullName()}
						</>
					)}
				</div>
				{classroomId && (
					<div>
						<FormattedMessage id="classroom" />:{" "}
						{!classroom.isSuccessfullyLoaded
							? "..."
							: classroom.doc.name}
					</div>
				)}
				<div className={invitationStyles.invitationButtons}>
					<button
						onClick={onApprove}
						className={invitationStyles.invitationButton}
					>
						<FormattedMessage id="approve" />
					</button>
					<button
						onClick={onReject}
						className={invitationStyles.invitationButton}
						style={{
							backgroundImage:
								"linear-gradient(90deg, #ff74a0, #ffa387)",
						}}
					>
						<FormattedMessage id="reject" />
					</button>
				</div>
			</div>
		);
	}
);
