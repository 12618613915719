import React from "react";
import ContentLoader from "react-content-loader";
import { ReactComponent as CalendarIcon } from "../../styles/img/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../styles/img/icons/clock.svg";
import { ReactComponent as DeadlineIcon } from "../../styles/img/icons/deadline.svg";
import { ReactComponent as QuestionIcon } from "../../styles/img/icons/question.svg";
import HomeworkStyles from "../styles/homework.module.css";
import { FormattedMessage } from "react-intl";

export const StudentAssignmentLoader: React.FC = () => {
	return (
		<div className={HomeworkStyles.background}>
			<div className={HomeworkStyles.banner}>
				<div className={HomeworkStyles.homeworkHeadline}>
					<div className={HomeworkStyles.currentLessonName} />
					<div className={HomeworkStyles.topicName}>
						<ContentLoader
							style={{ width: 140, height: 20 }}
							width={140}
							height={20}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="140"
								height="20"
							/>
						</ContentLoader>
					</div>
					<div>
						<ContentLoader
							style={{ width: 160, height: 40 }}
							width={160}
							height={40}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="160"
								height="40"
							/>
						</ContentLoader>
					</div>
				</div>
				<div className={HomeworkStyles.homeworkInfoDiv}>
					<div className={HomeworkStyles.deadlineDiv}>
						<div
							style={{
								display: "flex",
								width: "100px",
								margin: " 0px auto",
							}}
						>
							<div
								style={{
									color: "#58607C",
									width: "12px",
									height: "12px",
									marginRight: "4px",
								}}
							>
								<DeadlineIcon />
							</div>
							<div
								style={{ fontSize: 15 }}
								className={HomeworkStyles.questions}
							>
								<FormattedMessage id="deadline" />
							</div>
						</div>

						<div className={HomeworkStyles.deadlines}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<div
									style={{
										color: "#58607C",
										width: "20px",
										height: "20px",
										marginRight: "14px",
									}}
								>
									<CalendarIcon />
								</div>
								<div>
									<div className={HomeworkStyles.deadlineDay}>
										<ContentLoader
											style={{ width: 170, height: 30 }}
											width={170}
											height={30}
										>
											<rect
												x="0"
												y="0"
												rx="5"
												ry="5"
												width="170"
												height="30"
											/>
										</ContentLoader>
									</div>

									<div
										className={HomeworkStyles.deadlineDate}
									>
										<ContentLoader
											style={{ width: 70, height: 20 }}
											width={70}
											height={20}
										>
											<rect
												x="0"
												y="0"
												rx="5"
												ry="5"
												width="70"
												height="20"
											/>
										</ContentLoader>
									</div>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<div
									style={{
										color: "#58607C",
										width: "15px",
										height: "15px",
										marginRight: "14px",
									}}
								>
									<ClockIcon />
								</div>
								<div className={HomeworkStyles.deadlineHour}>
									<ContentLoader
										style={{ width: 110, height: 50 }}
										width={110}
										height={50}
									>
										<rect
											x="0"
											y="0"
											rx="5"
											ry="5"
											width="110"
											height="50"
										/>
									</ContentLoader>
								</div>
							</div>
						</div>
					</div>
					<div className={HomeworkStyles.greyLine}></div>
					<div className={HomeworkStyles.numQuestionsDiv}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<div
								style={{
									color: "#58607C",
									width: "15px",
									height: "15px",
									marginRight: "4px",
								}}
							>
								<QuestionIcon />
							</div>
							<div className={HomeworkStyles.questions}>
								<FormattedMessage id="numOfQuestions" />
							</div>
						</div>
						<div className={HomeworkStyles.progressBarLoader}>
							<ContentLoader
								style={{
									width: 150,
									height: 45,
									marginBottom: 5,
								}}
								width={150}
								height={45}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="5"
									width="150"
									height="45"
								/>
							</ContentLoader>
							<ContentLoader
								style={{ width: 210, height: 25 }}
								width={210}
								height={25}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="20"
									width="210"
									height="25"
								/>
							</ContentLoader>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
