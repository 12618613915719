import * as React from "react";
import { FullCircularProgress } from "../../widgets/circular-progress-bar";

interface ICurrentLessonCircularProcessProps {
	progress: number;
	progressStrokeWidth?: number;
	size?: number;
	numberFontSize?: number;
	progressStrokeColor?: [string, string];
}
export const CurrentLessonCircularProcess: React.FC<ICurrentLessonCircularProcessProps> = props => {
	const progress = props.progress;
	const progressStrokeColor: [string, string] = ["#536edb", "#fe6ea6"];

	return (
		<div>
			<FullCircularProgress
				progress={progress * 100}
				progressStrokeColor={
					props.progressStrokeColor || progressStrokeColor!
				}
				strokeWidth={1.5}
				progressStrokeWidth={props.progressStrokeWidth || 30}
				endCircleRadius={0}
				endCircleStrokeWidth={0}
				size={props.size || 210}
				animateTiming={1000}
			>
				{props.children}
			</FullCircularProgress>
		</div>
	);
};
