import { IRequest, ICredentials } from "../requests";
import {
	IAPOSTLogin,
	IRPOSTLogin,
	RPOSTLoginSchema,
	IAPUTLoginByAccessToken,
	IRPUTLoginByAccessToken,
	IAPOSTLogout,
	IRPOSTLogout,
	IAPUTAccessTokens,
	IRPUTAccessTokens,
	RPUTAccessTokensSchema,
	IAPUTCoursesAccessTokens,
	IRPUTCoursesAccessTokens,
	RPUTCoursesAccessTokensSchema,
	IAPOSTRegisterUser,
	APOSTRegisterUserSchema,
	APOSTLoginSchema,
	IAPUTPassword,
	IAPOSTForgotPassword,
	IAPOSTConfirmationCode,
	IRPOSTForgotPassword,
	IAGETConfrimationCode,
	IRGETConfrimationCode,
	RGETConfrimationCodeSchema,
	IAPOSTCheckParent,
	IRGETCheckParent,
	IRGETCheckParentSchema,
} from "./validators";
import {
	loginAction,
	logoutAction,
	updateUserDataAction,
} from "@app/redux/actions/user";
import { setUserForAnalytics } from "@app/utils/events";
import { inject } from "@app/modules";
import { delayPromise } from "@app/utils/common";

export class AuthController {
	private readonly Request: IRequest;
	private readonly CoursesRequest: IRequest;
	private readonly dispatch = inject("rootDispatch");
	private readonly getAllModels = inject("getAllModels");

	constructor(request: IRequest, coursesRequest: IRequest) {
		this.Request = request;
		this.CoursesRequest = coursesRequest;
		(window as any).logout = this.logout;
	}

	login = (args: IAPOSTLogin): Promise<IRPOSTLogin> =>
		this.Request.send(
			"POST",
			"/api/auth/login",
			args,
			null,
			{
				responseSchema: RPOSTLoginSchema,
				requestSchema: APOSTLoginSchema,
			},
			{
				avoidAuthentification: true,
			}
		).then((data: IRPOSTLogin) => {
			this.dipatchLoginActions(data);
			return delayPromise(100).then(() => data);
		});

	loginByToken = (
		args: IAPUTLoginByAccessToken
	): Promise<IRPUTLoginByAccessToken> =>
		this.Request.send(
			"POST",
			"/api/auth/login/:token",
			args,
			{
				headers: {
					"access-token": undefined,
				},
			},
			undefined,
			{
				avoidAuthentification: true,
			}
		).then((data: IRPUTLoginByAccessToken) => {
			this.dipatchLoginActions(data);
			return data;
		});

	logout = async (): Promise<IRPOSTLogout> => {
		const allModels = this.getAllModels();
		for (const Model of allModels) {
			try {
				Model.clearAllSync();
				if (
					(Model as any).meta &&
					typeof (Model as any).meta.clearStorage === "function"
				) {
					if (typeof (Model as any).meta.clear === "function") {
						(Model as any).meta.clear();
					} else {
						(Model as any).meta.clearStorage();
					}
				}
			} catch (e) {}
		}
		window.scroll(0, 0);
		this.dispatch(logoutAction());
		try {
			setUserForAnalytics(null);
		} catch (e) {
			//
		}
		try {
			const classroomsCredentials = JSON.parse(
				localStorage.credentials
			) as ICredentials;
			const coursesCredentials = JSON.parse(
				localStorage.coursesCredentials
			) as ICredentials;
			const args: IAPOSTLogout = {
				userId: classroomsCredentials.userId,
				refreshToken: classroomsCredentials.refreshToken,
				coursesUserId: coursesCredentials.userId,
				coursesRefreshToken: coursesCredentials.refreshToken,
				coursesAccessToken: coursesCredentials.accessToken,
			};
			const locale = localStorage.locale;
			localStorage.clear();
			localStorage.locale = locale;
			const clear = () => {
				this.CoursesRequest.clearAccessToken();
				this.Request.clearAccessToken();
			};
			return this.Request.send("POST", "/api/auth/logout", args)
				.then(clear)
				.catch(clear);
		} catch (e) {
			return;
		}
	};

	updateAccessToken = (args: IAPUTAccessTokens): Promise<IRPUTAccessTokens> =>
		this.Request.send(
			"PUT",
			"/api/auth/access-tokens",
			args,
			undefined,
			{
				responseSchema: RPUTAccessTokensSchema,
			},
			{
				avoidAuthentification: true,
			}
		).then(data => {
			this.renewCredentials({
				userId: args.userId,
				accessToken: data.classroomTokens.accessToken,
				refreshToken: data.classroomTokens.refreshToken,
			});
			return data;
		});

	updateCoursesAccessToken = (
		args: IAPUTCoursesAccessTokens
	): Promise<IRPUTCoursesAccessTokens> =>
		this.CoursesRequest.send(
			"PUT",
			"/api/auth/access-tokens",
			args,
			undefined,
			{
				responseSchema: RPUTCoursesAccessTokensSchema,
			},
			{
				avoidAuthentification: true,
			}
		).then(data => {
			this.renewCoursesCredentials({
				userId: args.userId,
				accessToken: data.accessToken,
				refreshToken: data.refreshToken,
			});
			return data;
		});

	renewCredentials = (credentials: ICredentials) => {
		localStorage.setItem(
			"credentials",
			JSON.stringify({
				userId: credentials.userId,
				accessToken: credentials.accessToken,
				refreshToken: credentials.refreshToken,
			})
		);
		this.Request.renewConfigByCredentials(credentials);
	};

	renewCoursesCredentials = (credentials: ICredentials) => {
		localStorage.setItem(
			"coursesCredentials",
			JSON.stringify({
				userId: credentials.userId,
				accessToken: credentials.accessToken,
				refreshToken: credentials.refreshToken,
			})
		);
		this.CoursesRequest.renewConfigByCredentials(credentials);
	};

	dipatchLoginActions = (res: IRPOSTLogin) => {
		this.renewCredentials({
			userId: res.userData.id,
			accessToken: res.classroomTokens.accessToken,
			refreshToken: res.classroomTokens.refreshToken,
		});
		this.renewCoursesCredentials({
			userId: res.coursesUserData.id,
			accessToken: res.courseTokens.accessToken,
			refreshToken: res.courseTokens.refreshToken,
		});
		setUserForAnalytics(res.userData);
		this.dispatch(loginAction(res.userData, res.coursesUserData));
	};

	updateUserDataAction = (
		userData?: IRPOSTLogin["userData"],
		coursesUserData?: IRPOSTLogin["coursesUserData"]
	) => {
		if (userData) {
			setUserForAnalytics(userData);
		}
		this.dispatch(updateUserDataAction(userData, coursesUserData));
	};

	registerStudent = async (args: IAPOSTRegisterUser): Promise<IRPOSTLogin> =>
		this.Request.send(
			"POST",
			"/api/auth/student/register",
			args,
			undefined,
			{
				responseSchema: RPOSTLoginSchema,
				requestSchema: APOSTRegisterUserSchema,
			}
		).then((data: IRPOSTLogin) => {
			this.dipatchLoginActions(data);
			return delayPromise(100).then(() => data);
		});

	registerTeacher = async (args: IAPOSTRegisterUser): Promise<IRPOSTLogin> =>
		this.Request.send(
			"POST",
			"/api/auth/teacher/register",
			args,
			undefined,
			{
				responseSchema: RPOSTLoginSchema,
				requestSchema: APOSTRegisterUserSchema,
			}
		).then((data: IRPOSTLogin) => {
			this.dipatchLoginActions(data);
			return delayPromise(100).then(() => data);
		});

	registerParent = async (args: IAPOSTRegisterUser): Promise<IRPOSTLogin> =>
		this.Request.send(
			"POST",
			"/api/auth/parent/register",
			args,
			undefined,
			{
				responseSchema: RPOSTLoginSchema,
				requestSchema: APOSTRegisterUserSchema,
			}
		).then((data: IRPOSTLogin) => {
			this.dipatchLoginActions(data);
			return delayPromise(100).then(() => data);
		});

	validateParentRegistration = async (
		args: IAPOSTCheckParent
	): Promise<IRGETCheckParent> => {
		return this.Request.send(
			"POST",
			"/api/auth/parent/check-mobile",
			args,
			undefined,
			{
				responseSchema: IRGETCheckParentSchema,
				requestSchema: APOSTRegisterUserSchema,
			}
		).then((data: IRGETCheckParent) => {
			return data;
		});
	};

	updatePassword = async (args: IAPUTPassword): Promise<void> => {
		return this.Request.send("PUT", "/api/auth/password", args);
	};

	sendConfirmationCode = async (
		args: IAPOSTConfirmationCode
	): Promise<void> => {
		return this.Request.send(
			"POST",
			"/api/auth/login/forgot-password",
			args
		);
	};

	resetPassword = async (
		args: IAPOSTForgotPassword
	): Promise<IRPOSTForgotPassword> => {
		return this.Request.send(
			"POST",
			"/api/auth/login/forgot-password/reset-password",
			args
		).then((data: IRPOSTForgotPassword) => {
			this.dipatchLoginActions(data);
			return data;
		});
	};

	getCode = async (
		args: IAGETConfrimationCode
	): Promise<IRGETConfrimationCode> => {
		return this.Request.send(
			"GET",
			"/api/auth/login/user-confirmation-code",
			args,
			{
				responseSchema: RGETConfrimationCodeSchema,
			}
		).then((data: IRGETConfrimationCode) => {
			return data;
		});
	};
}
