import { UserType } from "@app/api/helper-schemas";
import { TeacherOptions } from "@app/components/teachers/header/teacher-header";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import React from "react";
import genStyles from "../general_components/styles/general_styles.module.css";
import UserSvgIcon from "./general_imgs/user.svg";
import { StudentOptions } from "@app/components/users/header/options";
import { getLastCourseId } from "@app/components/users/last-course-saver";

export const MobileLogOut: React.FC<{}> = () => {
	const isTvschool =
		useLocale() === "ka" && getCurrentWebsite() === WebsiteOrigin.tvSchool;

	const user = useClassroomsUser();

	const isTeacher = user?.isTeacher();

	return (
		<section className={genStyles.mobile_log_out_container}>
			{isTeacher ? (
				<TeacherOptions
					isTvschool={isTvschool}
					isVideoInstructionVissible={true}
					icon={<UserLogo />}
					isAllAssignmentsAndNotProfileInfo={true}
				/>
			) : (
				<StudentOptions
					courseId={getLastCourseId()}
					isFaqAndNotProfile={true}
					icon={<UserLogo />}
				/>
			)}
		</section>
	);
};

const UserLogo = () => {
	return (
		<img src={UserSvgIcon} alt="" className={genStyles.user_log_out__img} />
	);
};
