import { techDiffForStudents } from "@app/globals";
import UserOrGuestRoute from "@app/routes/containers/user-or-guest-route";
import React from "react";
import { AboutUs } from "../../components/about-us/about-us";
import {
	StudentRegistrationPage,
	TeacherRegistrationPage,
} from "../../components/auth/student-register-page";
import { HowItWorks } from "../../components/how-it-works/how-it-works";
import { SpecialRegistration } from "../../components/landing/special-registration-popup";
import { MC } from "../../components/multiple-select";
import { ProfOrientationLanding } from "../../components/prof-orientation/landing";
import { Confidentiality } from "../../components/terms/confidentiality";
import { Terms } from "../../components/terms/terms";
import { TermsPageForApp } from "../../components/terms/termsPageForApp";
import { TVSchoolLandingTest } from "../../components/tv-school-landing/index-test";
import { StudentPublicAssignment } from "../../components/users/assignments/tv-school";
import { Blogs } from "../../components/users/blog/blogs";
import { WholeBlog } from "../../components/users/blog/whole-blog";
import { EmptyCourse } from "../../components/users/header/georgian-course";
import { LastCourseSaver } from "../../components/users/last-course-saver";
import {
	TeacherInvitationPopupMobile,
	TouInfo,
} from "../../components/users/main/responsive";
import { RegistrationInviteLangingPage } from "../../components/users/register-invitation/register-invitation";
import { TVSChoolWrapper } from "../../components/users/tests/tv-quiz";
import { FaqContainer } from "../../components/widgets/faq";
import { publicLinks } from "./links";
import { StudentsTechDiffPage } from "../../tech-difficulties";
import { createRouteList } from "../config";
import GuestRoute from "../containers/guest-route";
import { Events } from "../../components/events";
import ChooseSpecialityPage from "@app/components/choose-speciality";

const publicRoutes = createRouteList({
	exact: true,
	RouteComponent: UserOrGuestRoute,
});

///

publicRoutes.add({
	path: "",
	exact: false,
	component: Events,
	avoidSwitch: true,
});

///

const LoginPopup = React.lazy(() =>
	import("../../components/landing/login").then(module => ({
		default: module.LoginPopup,
	}))
);

publicRoutes.add({
	path: publicLinks.login,
	component: LoginPopup,
	RouteComponent: GuestRoute,
});

///

const LandingMainPage = React.lazy(() => import("../../components/landing"));

publicRoutes.add({
	path: publicLinks.landingPage,
	component: LandingMainPage,
	RouteComponent: GuestRoute,
	avoidSwitch: true,
});

///

publicRoutes.add({
	path: "/registration-with-code",
	component: SpecialRegistration,
});

///

publicRoutes.add({
	path: "/terms-and-conditions",
	component: TermsPageForApp,
});

///

publicRoutes.add({
	path: "/tv-quiz/:level",
	component: TVSChoolWrapper,
	routeProps: {
		headerProps: {
			variant: "colored",
			isStudentHeader: true,
		},
	},
});

///

publicRoutes.add({
	path: "/prof",
	component: ProfOrientationLanding,
});

///

publicRoutes.add({
	path: "/courses/:courseId",
	component: LastCourseSaver,
	avoidSwitch: true,
});

///

publicRoutes.add({
	path: "/registration",
	component: StudentRegistrationPage,
});

///

publicRoutes.add({
	path: "/teacher-registration",
	component: TeacherRegistrationPage,
});

///

publicRoutes.add({
	path: "/rame",
	component: MC,
});

///

publicRoutes.add({
	path: "/mobile-join-classroom",
	component: TeacherInvitationPopupMobile,
});

///

publicRoutes.add({
	path: "/tou-info",
	component: TouInfo,
});

///

publicRoutes.add({
	path: "/how-student-page-works",
	component: HowItWorks,
});

///

publicRoutes.add({
	path: "/about-us",
	component: AboutUs,
});

///

publicRoutes.add({
	path: "/english-course",
	component: EmptyCourse,
});

///

publicRoutes.add({
	path: "/terms",
	component: Terms,
});

///

publicRoutes.add({
	path: "/confidentiality",
	component: Confidentiality,
});

///

publicRoutes.add({
	path: "/blogs-main-page",
	component: Blogs,
	routeProps: {
		priorityOfEmptyPopup: 2010,
	},
});

///

publicRoutes.add({
	path: "/blogs/:blogId",
	component: WholeBlog,
	routeProps: {
		priorityOfEmptyPopup: 201,
	},
});

///

publicRoutes.add({
	path: "/s/hw/:assignmentCode",
	component: techDiffForStudents
		? StudentsTechDiffPage
		: StudentPublicAssignment,
	routeProps: {
		headerProps: {
			variant: "colored",
			isStudentHeader: true,
		},
		priorityOfEmptyPopup: 2010,
	},
});

///

publicRoutes.add({
	path: "/rs/:invitationCode",
	component: RegistrationInviteLangingPage,
});

///

publicRoutes.add({
	path: "/faq",
	component: FaqContainer,
	routeProps: {
		headerProps: { variant: "colored" },
	},
});

///

publicRoutes.add({
	path: "/test",
	component: TVSchoolLandingTest,
});

///

const TouVideosPage = React.lazy(() =>
	import("../../components/tou-videos").then(module => ({
		default: module.TouVideosPage,
	}))
);

const TouCertificatePage = React.lazy(() =>
	import("../../components/tou-certificates").then(module => ({
		default: module.TouCertificatePage,
	}))
);

publicRoutes.add({
	path: publicLinks.TOU.certificate.routePath,
	component: TouCertificatePage,
});

///

publicRoutes.add({
	path: publicLinks.TOU.video,
	component: TouVideosPage,
});

publicRoutes.add({
	path: publicLinks.chooseSpeciality,
	component: ChooseSpecialityPage,
});

///

export default publicRoutes;
