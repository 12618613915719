import { useHistory } from "@app/hooks/front";
import { useSubjectsByIds } from "@app/hooks/subjects";
import { ObjectId } from "@app/utils/generics";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import styles from "./subjects/styles/user-subject-list.module.css";

export const HubuExamsSubjectIds: ObjectId[] = [
	"5e88934f7907d86fa27d9584",
	"5e4d992693bf9e77e42154c9",
];

const HubuExamsMainPage: React.FC<{}> = () => {
	const subjects = useSubjectsByIds(HubuExamsSubjectIds);
	const [currSubj, setCurrSubj] = useState();
	const onSubjectClick = useCallback(subjId => () => setCurrSubj(subjId), []);
	const history = useHistory();
	const next = useCallback(() => {
		if (!currSubj) alert("აირჩიეთ საგანი");
		else history.push(`/subject/${currSubj}`);
	}, [currSubj, history]);
	return (
		<div style={{ padding: 30 }}>
			{subjects.doc?.map(subj => (
				<div>
					<button
						className={classNames(
							styles.subjectButton,
							currSubj === subj._id && styles.chosenSubject
						)}
						onClick={onSubjectClick(subj._id)}
					>
						{subj.name}
					</button>
				</div>
			))}
			<button
				className={classNames(
					styles.subjectButton,
					styles.chooseSubjectButton
				)}
				onClick={next}
			>
				შემდეგი
			</button>
		</div>
	);
};

export default HubuExamsMainPage;
