import { School } from "@app/models/schools";
import { inject } from "@app/modules";
import { IRequest } from "../requests";
import {
	IRGETSchools,
	RGETSchoolsSchema,
	IAGETSchoolsByCity,
	IRGETSchoolsByCity,
	RGETSchoolsByCitySchema,
	IAPOSTRegisterSchool,
	APOSTRegisterSchoolSchema,
	IRGETSchool,
	RGETSchoolSchema,
	IADELETESchool,
	IAPOSTCreateSchool,
	IAPUTUpdateSchool,
	IRPOSTCreateSchool,
	IRPUTUpdateSchool,
	RPUTUpdateSchoolSchema,
	RPOSTCreateSchoolSchema,
	IAGETManySchoolsByIds,
	IRGETManySchoolsByIds,
	IAGETExportedClassroomXLSX,
} from "./validators";

export class SchoolsController {
	private readonly Request: IRequest;

	private readonly _SchoolModel = inject("SchoolModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	getAll = async (): Promise<IRGETSchools> => {
		return this.Request.send("GET", "/api/schools", undefined, null, {
			responseSchema: RGETSchoolsSchema,
		});
	};

	getManyByIds = async (
		args: IAGETManySchoolsByIds,
		loadFresh?: boolean
	): Promise<School[]> => {
		if (!loadFresh) {
			const schools = this._SchoolModel.findManyByIdsSync(args.ids);
			if (schools.length === args.ids.length) {
				return schools;
			}
		}
		return this.Request.send(
			"POST",
			"/api/schools/get-by-ids",
			args,
			null,
			{
				responseSchema: RGETSchoolsSchema,
			}
		).then((schools: IRGETManySchoolsByIds) => {
			return this._SchoolModel.loadManySync(schools);
		});
	};

	getById = async (id: number, loadFresh?: boolean): Promise<School> => {
		if (!loadFresh) {
			const school = this._SchoolModel.findByIdSync(id);
			if (school) return school;
		}
		return this.Request.send("GET", "/api/schools/:id", { id }, null, {
			responseSchema: RGETSchoolSchema,
		}).then((school: IRGETSchool) => {
			return this._SchoolModel.loadOneSync(school);
		});
	};

	getByCity = async (
		args: IAGETSchoolsByCity
	): Promise<IRGETSchoolsByCity> => {
		return this.Request.send("GET", "/api/schools/by-city", args, null, {
			responseSchema: RGETSchoolsByCitySchema,
		});
	};

	async create(args: IAPOSTCreateSchool): Promise<IRPOSTCreateSchool> {
		return this.Request.send("POST", "/api/schools", args, null, {
			responseSchema: RPOSTCreateSchoolSchema,
		});
	}

	async update(args: IAPUTUpdateSchool): Promise<IRPUTUpdateSchool> {
		return this.Request.send("PUT", "/api/schools/:id", args, null, {
			responseSchema: RPUTUpdateSchoolSchema,
		});
	}

	async delete(args: IADELETESchool) {
		return this.Request.send("DELETE", "/api/schools/:id", args);
	}

	async registerSchool(args: IAPOSTRegisterSchool) {
		return this.Request.send("POST", "/api/schools/register", args, null, {
			requestSchema: APOSTRegisterSchoolSchema,
		});
	}

	async uploadClassrooms(args: FormData): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/schools/import-classrooms",
			args
		);
	}

	async exportClassrooms(args: IAGETExportedClassroomXLSX): Promise<any> {
		return this.Request.send(
			"GET",
			"/api/schools/export-classrooms",
			args,
			{
				responseType: "blob",
			}
		);
	}
}
