import { combinePaths } from "@app/utils/common";

type R<T> = {
	[key in string]: T | R<T>;
};

export const addBasePath = (basePath: string) => {
	return <T extends R<string | ((...args: any) => string)>>(obj: T): T => {
		const addBasePathHelper = (obj: any): any => {
			const newObj = {};
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					if (typeof obj[key] === "object") {
						newObj[key] = addBasePathHelper(obj[key]);
					} else {
						newObj[key] = addPath(basePath, obj[key]);
					}
				}
			}
			return newObj;
		};
		return addBasePathHelper(obj);
	};
};

const addPath = (basePath: string, hey: string | CallableFunction) => {
	if (typeof hey === "string") {
		return combinePaths(basePath, hey);
	} else if (typeof hey === "function") {
		const newFn = ((...args: any[]) => {
			const pth = hey(...args);
			return combinePaths(basePath, pth);
		}) as any;
		if ((hey as any)[routeUrkKey] !== undefined) {
			newFn[routeUrkKey] = addPath(basePath, (hey as any)[routeUrkKey]);
		}
		return newFn;
	}
};

const routeUrkKey = "routePath";

export const withParams = <
	Fn extends (...args: any[]) => string,
	RoutePath extends string | ((...args: any[]) => string)
>(
	getUrl: Fn,
	routePath: RoutePath
): Fn & { [key in typeof routeUrkKey]: RoutePath } => {
	const newFn = (...args: any) => {
		return getUrl(...args);
	};
	(newFn as any)[routeUrkKey] = routePath;
	return newFn as any;
};
