import { assignmentsReducer } from "@app/models/assignment";
import { cardsReducer } from "@app/models/card";
import { cardTemplatesReducer } from "@app/models/card-template";
import { classroomsReducer } from "@app/models/classroom";
import { classroomJoinRequestsReducer } from "@app/models/classroom-join-request";
import { coursesReducer } from "@app/models/course";
import { coursesInfoReducer } from "@app/models/course-info";
import { filesReducer } from "@app/models/file";
import { foldersReducer } from "@app/models/folder";
import { folderHierarchiesReducer } from "@app/models/folder-hierarchy";
import { studentsInfoReducer } from "@app/models/student-info";
import { subjectsReducer } from "@app/models/subject";
import { taskTypesReducer } from "@app/models/task-type";
import { taskTypeHierarchiesReducer } from "@app/models/task-type-hierarchy";
import { testsReducer } from "@app/models/test";
import { testTypesReducer } from "@app/models/test-type";
import { topicsReducer } from "@app/models/topic";
import { topicHierarchiesReducer } from "@app/models/topic-hierarchy";
import { userActionsReducer } from "@app/models/user-action";
import { userFolderLevelsReducer } from "@app/models/user-folder-level";
import { userFolderProgressesReducer } from "@app/models/user-folder-progress";
import { usersInfoReducer } from "@app/models/user-info";
import { userTaskTypeLevelsReducer } from "@app/models/user-task-type-level";
import { userTopicLevelsReducer } from "@app/models/user-topic-level";
import { userViewsReducer } from "@app/models/user-view";
import { UserActions } from "@app/redux/actions/user";
import { blogsReducer } from "@app/models/blog";
import { IntlAction, intlReducer } from "react-intl-redux";
import { combineReducers } from "redux";
import user from "./reducers/user";
import { coursesSettingsReducer } from "@app/models/course-settings";
import { appealsReducer } from "@app/models/appeal";
import { gradesReducer } from "@app/models/grade";
import { gradeCategoriesReducer } from "@app/models/grade-category";
import { feedbackReducer } from "@app/models/feedback";
import { reservationsReducer } from "@app/models/reservation";
import { videoLessonsReducer } from "@app/models/video-lesson";
import { schoolsReducer } from "@app/models/schools";
import { groupsReducer } from "@app/models/group";

export const appRootReducer = combineReducers({
	intl: intlReducer,
	user,
	courses: coursesReducer,
	folders: foldersReducer,
	testTypes: testTypesReducer,
	tests: testsReducer,
	files: filesReducer,
	cardTemplates: cardTemplatesReducer,
	cards: cardsReducer,
	userFolderProgresses: userFolderProgressesReducer,
	folderHierarchies: folderHierarchiesReducer,
	topics: topicsReducer,
	topicHierarchies: topicHierarchiesReducer,
	taskTypes: taskTypesReducer,
	taskTypeHierarchies: taskTypeHierarchiesReducer,
	subjects: subjectsReducer,
	classrooms: classroomsReducer,
	assignments: assignmentsReducer,
	coursesInfo: coursesInfoReducer,
	studentsInfo: studentsInfoReducer,
	usersInfo: usersInfoReducer,
	classroomJoinRequests: classroomJoinRequestsReducer,
	userFolderLevels: userFolderLevelsReducer,
	userTaskTypeLevels: userTaskTypeLevelsReducer,
	userTopicLevels: userTopicLevelsReducer,
	userViews: userViewsReducer,
	userActions: userActionsReducer,
	blogs: blogsReducer,
	coursesSettings: coursesSettingsReducer,
	appeals: appealsReducer,
	grades: gradesReducer,
	gradeCategories: gradeCategoriesReducer,
	feedback: feedbackReducer,
	reservations: reservationsReducer,
	gradeBookCategory: gradeCategoriesReducer,
	videoLessons: videoLessonsReducer,
	schools: schoolsReducer,
	groups: groupsReducer,
});

export type IRootState = ReturnType<typeof appRootReducer>;

export type IRootActions = IntlAction | UserActions;
