import { inject } from "@app/modules";
import { useModelDocs } from "m-model-react";
import { useResourceInfoWithLoading } from "./fetch";

const fetchAllGradeCategories = () => {
	return inject("GradesController").getCategories();
};

export const useGradeCategories = () => {
	const gradeCategories = useModelDocs(inject("GradeCategoryModel"));

	const gradeCategoriesFetchInfo = useResourceInfoWithLoading({
		resource: gradeCategories,
		fetchingArg: undefined,
		fetch: fetchAllGradeCategories,
		isIdentificationKnown: true,
		forcefullyFetch: true,
	});
	return gradeCategoriesFetchInfo;
};
