import { CitySchema, ICity } from "./helper-schemas";
import Joi from "@app/utils/joi";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
} from "../helper-schemas";

export const AGETCitiesSchema = Joi.object({
	country: Joi.string()
		.allow(null)
		.empty(["", "null"])
		.default(null),
});

export interface IAGETCities {
	country?: string | null;
}

export const RGETCitiesSchema = Joi.array().items(CitySchema);
export type IRGETCities = ICity[];

//create city

export const APOSTCitySchema = CitySchema.keys(insertStripKeys);
export type IAPOSTCity = ToInsertKeys<ICity>;

export const RPOSTCitySchema = CitySchema;
export type IRPOSTCity = ICity;

///

export const ADELETECitySchema = Joi.object({
	id: Joi.number().required(),
});

export interface IADELETECity {
	id: number;
}

///

export const APUTCitySchema = CitySchema.keys(updateStripKeys);
export type IAPUTCity = ToUpdateKeys<ICity>;

export const RPUTCitySchema = CitySchema;
export type IRPUTCity = ICity;
