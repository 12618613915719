import { addLoader } from "@app/common-javascript";
import { AuthInput, AuthSelect } from "@app/components/auth/input-forms";
import {
	openConfirmationPopup,
	openConfirmationPopupWithFormattedMessage,
} from "@app/components/widgets/confirmation-popup";
import { useCitiesAndSchools } from "@app/hooks/cities-and-schools";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { StylesConfig } from "react-select";
import genStyles from "../general_components/styles/general_styles.module.css";
import { ChangePassword } from "../general_components/change-password";
import { Mobile } from "../general_components/mobile";
import { useGrades } from "@app/hooks/grades";
import { StudentProfileRightPanel } from "./right-panel";
import { useWindowSize } from "@app/hooks/front";
import classnames from "classnames";
import BrandLogo from "../general_components/logo";
import { MobileLogOut } from "../general_components/mobile_logOut";

export const StudentProfileUpdated: React.FC = props => {
	const { width } = useWindowSize();
	return (
		<section className={genStyles.profile_container}>
			<BrandLogo />
			{width <= 1000 && <MobileLogOut />}
			<StudentProfileLeftPanel />
			{/* <StudentProfileRightPanel /> */}
		</section>
	);
};

interface SchoolOfCity {
	value: number;
	cityId: number;
	label: string;
}

interface StudentProfileLeftPanelState {
	name: string;
	surname: string;
	newPassword: string;
	repeatedPassword: string;
	isChanged: boolean;
	grade: number | null;
	selectedCityId: number | null;
	selectedSchoolId: number | null;
}

const StudentProfileLeftPanel: React.FC = React.memo(props => {
	const isGeo = useLocale() === "ka";
	const user = useClassroomsUser()!;
	const grades = useGrades();
	const { cities, schools } = useCitiesAndSchools(user ? user.country : null);
	const [state, setState] = useState<StudentProfileLeftPanelState>();
	const { width } = useWindowSize();

	useEffect(() => {
		if (!user) return;
		setState({
			isChanged: false,
			repeatedPassword: "",
			newPassword: "",
			grade: user.grade,
			name: user.firstname,
			surname: user.lastname,
			selectedCityId: user.city,
			selectedSchoolId: user.school,
		});
	}, [user]);

	const handleStateChange = <K extends keyof StudentProfileLeftPanelState>(
		key: K,
		value: StudentProfileLeftPanelState[K]
	) => {
		setState(c => (c ? { ...c, [key]: value, isChanged: true } : c));
	};

	let schoolsOfSelectedCity: SchoolOfCity[];

	if (!state || state.selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		if (schools && state) {
			schoolsOfSelectedCity = schools.filter(
				s => s.cityId === state.selectedCityId
			);
		}
	}

	const onSave = useCallback(() => {
		if (!state) return;
		const {
			grade,
			name,
			newPassword,
			repeatedPassword,
			selectedCityId,
			selectedSchoolId,
			surname,
		} = state;
		const UsersController = inject("UsersController");
		const sendRequest = newPassword && newPassword === repeatedPassword;
		if (newPassword && !sendRequest) {
			openConfirmationPopupWithFormattedMessage("passwordDontMatch");
			return;
		}
		if (sendRequest && newPassword.length < 5) {
			openConfirmationPopup({
				text: getFormattedMessage("passwordLength", {
					requiredLength: 5,
				}),
			});
			return;
		}
		const removeLoader = addLoader();
		UsersController.saveDetailedInfo({
			firstname: name,
			lastname: surname,
			city: selectedCityId || undefined,
			school: selectedSchoolId || undefined,
			newPassword: sendRequest ? newPassword : undefined,
			grade: grade || undefined,
		})
			.then(() => {
				removeLoader();
				openConfirmationPopupWithFormattedMessage("refreshInfo");
				setState({ ...state, isChanged: false });
			})
			.catch(() => {
				openConfirmationPopupWithFormattedMessage("errorAlert");
				removeLoader();
			});
	}, [state]);

	const gradeOptions = useMemo(
		() =>
			grades.doc
				? grades.doc.map(e => ({ label: String(e.id), value: e.id }))
				: [],
		[grades.doc]
	);

	if (!state || state.selectedCityId === null) {
		schoolsOfSelectedCity = [];
	} else {
		schoolsOfSelectedCity =
			(schools &&
				schools.filter(s => s.cityId === state.selectedCityId)) ||
			[];
	}

	if (!state) return null;

	return (
		<section className={genStyles.profile_left__sidebar_container}>
			<section className={genStyles.profile_left__sidebar}>
				<section className={genStyles.profile_avatar__wrapper}>
					<section className={genStyles.profile_avatar}>
						<img src={"/imgs/StudentAvatar.svg"} alt="avatar" />
					</section>
					<p className={genStyles.profile_person}>
						<FormattedMessage id="student" />
					</p>
				</section>

				<form className={genStyles.personal_info_form}>
					<h2 className={genStyles.personal_info_form__title}>
						<FormattedMessage id="personalInformation" />
					</h2>
					<section
						className={genStyles.personal_info_form__input_wrapper}
					>
						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.firstname" />
							</label>

							<AuthInput
								defaultValue={state.name}
								onChange={e =>
									handleStateChange("name", e.target.value)
								}
								type={"text"}
								className={classnames(
									genStyles.input,
									genStyles.studentRegisterWrapper
								)}
								fullWidthInput={{ width: "100%" }}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__input}
						>
							<label>
								<FormattedMessage id="inputs.lastname" />
							</label>
							<AuthInput
								defaultValue={state.surname}
								onChange={e =>
									handleStateChange("surname", e.target.value)
								}
								type={"text"}
								className={classnames(
									genStyles.input,
									genStyles.studentRegisterWrapper
								)}
								fullWidthInput={{ width: "100%" }}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__select}
							style={{ cursor: "pointer" }}
						>
							<label>
								<FormattedMessage id="inputs.city" />
							</label>
							<AuthSelect
								onChange={(v: {
									label: string;
									value: number;
								}) =>
									handleStateChange("selectedCityId", v.value)
								}
								options={cities}
								value={
									(cities &&
										state &&
										cities.find(
											c =>
												c.value === state.selectedCityId
										)) ||
									null
								}
								containerClassName={genStyles.input}
								styles={
									width <= 1000
										? selectorMobStyles
										: width > 1500
										? selectorStyles
										: selectorResStyles
								}
								isSearchable={false}
							/>
						</section>

						<section
							className={genStyles.personal_info_form__select}
							style={{ cursor: "pointer" }}
						>
							<label>
								<FormattedMessage id="inputs.school" />
							</label>
							<AuthSelect
								onChange={(v: {
									label: string;
									value: number;
								}) =>
									handleStateChange(
										"selectedSchoolId",
										v.value
									)
								}
								options={schoolsOfSelectedCity}
								value={
									(state &&
										schoolsOfSelectedCity.find(
											s =>
												s.value ===
												state.selectedSchoolId
										)) ||
									null
								}
								containerClassName={genStyles.input}
								styles={
									width <= 1000
										? selectorMobStyles
										: width > 1500
										? selectorStyles
										: selectorResStyles
								}
								isIconVissible={true}
								isSearchable={false}
							/>
						</section>
						<section
							className={genStyles.personal_info_form__select}
							style={{ cursor: "pointer" }}
						>
							<label>
								<FormattedMessage id="inputs.grade" />
							</label>
							<AuthSelect
								onChange={(v: {
									label: string;
									value: number;
								}) => handleStateChange("grade", v.value)}
								options={gradeOptions}
								value={
									gradeOptions.find(
										e => e.value === state.grade
									) || null
								}
								containerClassName={genStyles.input}
								styles={
									width <= 1000
										? selectorMobStyles
										: width > 1500
										? selectorStyles
										: selectorResStyles
								}
								isIconVissible={true}
								isSearchable={false}
							/>
						</section>
					</section>
				</form>

				{isGeo && <Mobile />}

				<ChangePassword
					onNewPasswordChange={e =>
						handleStateChange("newPassword", e.target.value)
					}
					onRepeatedNewPasswordChange={e =>
						handleStateChange("repeatedPassword", e.target.value)
					}
				/>
				<div className={genStyles.saveButtonContainer}>
					{state.isChanged ? (
						<button
							onClick={onSave}
							className={genStyles.saveButtonAfterConfirmed}
							style={{ cursor: "pointer" }}
						>
							<FormattedMessage id="save" />
						</button>
					) : (
						<button
							className={genStyles.saveButtonBeforeConfirmed}
							style={{ cursor: "not-allowed" }}
						>
							<FormattedMessage id="save" />
						</button>
					)}
				</div>
			</section>
		</section>
	);
});

const selectorStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "10px 0 0",
		display: "flex",
		alignItems: "center",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "20px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};

const selectorResStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "0",
		display: "flex",
		alignItems: "center",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "16px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},

	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};

const selectorMobStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "5px 8px",
		fontSize: 14,
	}),
	control: () => ({
		backgroundColor: "white",
		margin: "5px 0 0",
		display: "flex",
		alignItems: "center",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		const color = "#626a84";
		const fontWeight = "normal";
		const fontSize = "16px";
		const fontFamily = "Roboto Geo Nus";
		const marginLeft = "3px";

		return {
			...provided,
			opacity,
			transition,
			color,
			fontWeight,
			fontSize,
			fontFamily,
			marginLeft,
		};
	},
	valueContainer: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	placeholder: (provided, state) => ({
		...provided,
		color: "#5273e6",
		fontSize: 14,
		fontWeight: "bold",
		fontFamily: "Roboto Geo Nus",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	indicatorsContainer: (provided, state) => {
		return { color: "#626a84" };
	},
};
