import { useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { LoginButton, LoginPopup } from "./login";
import { RegisterButton, RegisterPopup } from "./register-type-selection";
import { ReactComponent as LogoIcon } from "./styles/img/hubu-logo.svg";
import { ReactComponent as LupiLogo } from "./styles/img/lupi-ai.svg";
import styles from "./styles/landing-header.module.css";

export interface ILandinHeaderProps {
	backgroundColor?: string;
}

const responsiveWidth = 1030;

// TODO: Fix this later
// eslint-disable-next-line max-lines-per-function
export const LandingHeader: React.FC<ILandinHeaderProps> = props => {
	const locale = useLocale();
	const history = useHistory();
	const { width } = useWindowSize();
	const backToLandingPage = useCallback(() => {
		history.push(`/`);
	}, [history]);

	const {
		value: isResponsiveOptionsVisible,
		setTrue: showResponsiveOptions,
		setFalse: hideResponsiveOptions,
	} = useBoolean();

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);

	return (
		<>
			<div
				className={styles.header}
				style={
					props.backgroundColor
						? {
								backgroundColor: props.backgroundColor,
						  }
						: { backgroundColor: "#5273e6" }
				}
			>
				<div className={styles.headerContent}>
					<div className={styles.logoAndHowItWorksButton}>
						<div
							className={styles.logo}
							onClick={backToLandingPage}
						>
							{locale === "ka" ? (
								<LogoIcon style={{ height: "90%" }} />
							) : (
								<LupiLogo style={{ height: "90%" }} />
							)}
						</div>
					</div>
					<ResponsiveHeaderOptions
						hideResponsiveOptions={hideResponsiveOptions}
						isResponsiveOptionsVisible={isResponsiveOptionsVisible}
						responsiveWidth={responsiveWidth}
						showResponsiveOptions={showResponsiveOptions}
						openLoginPopup={openLoginPopup}
						openRegistrationPopup={openRegistrationPopup}
						width={width}
					/>
				</div>
				{isRegistrationPopupOn && (
					<RegisterPopup
						onClose={closeRegistrationPopup}
						isOnlyPopup={true}
						hideLogin={closeLoginPopup}
						isLoginPopupOpen={isLoginPopupOn}
						openLogin={openLogin}
						inputClassname={StudentRegisterStyles.inputInPopup}
						registrationButtonClassname={
							StudentRegisterStyles.registrationButtoInPopup
						}
						isCustomStylesInvissible={true}
						isRegisterIllustrationVissible={true}
					/>
				)}
				{isLoginPopupOn && (
					<LoginPopup
						onClose={closeLoginPopup}
						hideRegisterPage={closeRegistrationPopup}
						isRegisterPageOpen={isRegistrationPopupOn}
						openRegisterPage={openRegisterPage}
						isIconVissible={false}
						isUpperTextVissible={true}
						placeholder={"   "}
						inputClassname={StudentRegisterStyles.inputForLogin}
						isLowerMainQuestionVissible={true}
					/>
				)}
			</div>
			<div className={styles.containerPlaceholder} />
		</>
	);
};

const ResponsiveHeaderOptions: React.FC<{
	width: number;
	responsiveWidth: number;
	isResponsiveOptionsVisible: boolean;
	openRegistrationPopup: () => void;
	openLoginPopup: () => void;
	showResponsiveOptions: () => void;
	hideResponsiveOptions: () => void;
}> = props => {
	return (
		<>
			{/* {props.width > props.responsiveWidth && <ProfOrientationButton />} */}
			{props.width <= props.responsiveWidth && (
				<>
					{/* <RedirectToBlogMainPage /> */}
					<LoginAndRegisterButtons
						openLoginPopup={props.openLoginPopup}
						openRegistrationPopup={props.openRegistrationPopup}
					/>
				</>
			)}
			{props.width > props.responsiveWidth && (
				<LandingUserOptions
					openLoginPopup={props.openLoginPopup}
					openRegistrationPopup={props.openRegistrationPopup}
				/>
			)}
		</>
	);
};

export const HeaderWithLogInAndRegister: React.FC<{
	backgroundColor?: string;
}> = props => {
	return <LandingHeader backgroundColor={props.backgroundColor} />;
};

const LandingUserOptions: React.FC<{
	openRegistrationPopup: () => void;
	openLoginPopup: () => void;
	buttonsContainerClassname?: string;
	howItWorksClassname?: string;
}> = props => {
	const history = useHistory();

	const locale = useLocale();

	const onViewHowItWorks = useCallback(() => {
		history.push(`/how-student-page-works`);
		console.log(history);
	}, [history]);

	const onAboutUs = useCallback(() => {
		history.push(`/about-us`);
		console.log(history);
	}, [history]);
	return (
		<div
			className={
				props.buttonsContainerClassname
					? props.buttonsContainerClassname
					: locale === "ka"
					? styles.buttons
					: styles.buttonsEng
			}
		>
			{/* <RedirectToBlogMainPage /> */}

			{locale === "ka" && (
				<>
					<div onClick={onAboutUs}>
						<button
							className={
								props.howItWorksClassname
									? props.howItWorksClassname
									: styles.howItWorksButton
							}
						>
							<FormattedMessage id="aboutUs.title" />
						</button>
					</div>
					<div onClick={() => onViewHowItWorks()}>
						<button
							className={
								props.howItWorksClassname
									? props.howItWorksClassname
									: styles.howItWorksButton
							}
						>
							<FormattedMessage id="howItWorks.title" />
						</button>
					</div>
				</>
			)}

			<LoginAndRegisterButtons
				openLoginPopup={props.openLoginPopup}
				openRegistrationPopup={props.openRegistrationPopup}
			/>
		</div>
	);
};

const LoginAndRegisterButtons: React.FC<{
	openRegistrationPopup: () => void;
	openLoginPopup: () => void;
	buttonClassname?: string;
}> = props => {
	return (
		<div style={{ width: "max-content" }}>
			<LoginButton openLoginPopup={props.openLoginPopup} />
			<RegisterButton
				openRegistrationPopup={props.openRegistrationPopup}
			/>
		</div>
	);
};
