import DoneIcon from "@material-ui/icons/Done";
import classNames from "classnames";
import * as React from "react";

interface ICheckBoxProps<IdType extends string | number | undefined | null> {
	onChange: (_id: IdType) => void;
	checkmarkContainerClassName?: string | string[];
	selectedCheckmarkContainerClassName?: string | string[];
	isSelected: boolean;
	id: IdType;
}
export const CheckBox = <IdType extends string | number | undefined | null>(
	props: ICheckBoxProps<IdType>
) => {
	return (
		<div
			className={classNames(
				props.checkmarkContainerClassName,
				props.isSelected && props.selectedCheckmarkContainerClassName
			)}
			onClick={() => props.onChange(props.id)}
		>
			{props.isSelected && (
				<div>
					<DoneIcon />
				</div>
			)}
		</div>
	);
};
