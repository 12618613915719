/* eslint-disable @typescript-eslint/no-namespace */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@app/extra";
import { isMSExplorer } from "small-polyfills";
import * as React from "react";
import * as ReactGA from "react-ga";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "@app/app";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { createModules } from "@app/modules";
import { Provider } from "react-redux";
import "@app/components/styles/common-styles.css";
import { appRootReducer, IRootState, IRootActions } from "@app/redux";
import { handleActionOfOtherTab } from "m-model-common";
import { CoursesRequests, Requests } from "@app/api";
import { loadUser } from "@app/redux/actions/user";
import { GlobalHistory } from "@app/global_history";
import { IntlProvider, updateIntl } from "react-intl-redux";
import { loadLocale } from "locale";
import Helmet from "react-helmet";
import { getFormattedMessage } from "@app/utils/locale";
import {
	getInitialLocale,
	getCurrentWebsite,
	isTVSchool,
	WebsiteOrigin,
	isLocalhost,
	broadcastFBLoad,
} from "@app/globals";
import { InterpolationWithTheme } from "@emotion/core";
import { useLocale } from "@app/hooks/intl";
import { TechDifficulties } from "@app/tech-difficulties";
import { useEffect, useMemo } from "react";
import { ScriptInBody } from "@app/components/widgets/scripts";
import { TeacherSchool } from "@app/components/teachers/contexts/teacher-school";
import "./tailwind.output.css";
import EduFairPopup from "@app/components/edu-fair-popup";

const store = createStore(
	appRootReducer,
	{
		intl: {
			locale: getInitialLocale(),
			messages: {},
		},
	},
	composeWithDevTools(
		applyMiddleware(reduxThunk as ThunkMiddleware<IRootState, IRootActions>)
	)
);
createModules({
	store,
	coursesRequest: CoursesRequests,
	classroomsRequest: Requests,
});
export { store };
export const ReduxStoreContext = React.createContext(store);

window.addEventListener("storage", (e: StorageEvent) => {
	handleActionOfOtherTab(e, store.dispatch);
});

if (localStorage.user) {
	try {
		const user = JSON.parse(localStorage.user);
		store.dispatch(loadUser(user));
	} catch (e) {
		console.error(e);
	}
}

const updateIntlMessages = (locale: string, messages: any) => {
	store.dispatch(updateIntl({ locale, messages }));
	localStorage.locale = locale;
};

export const loadAndUpdateIntlMessages = async (locale: string) => {
	const { messages } = await loadLocale(locale);
	updateIntlMessages(locale, messages);
};
(window as any).loadAndUpdateIntlMessages = loadAndUpdateIntlMessages;

// TODO: Fix this later
// eslint-disable-next-line max-lines-per-function
const MetaInformation: React.FC = React.memo(() => {
	const web = getCurrentWebsite();
	const locale = useLocale();
	const hotjarIds: Record<WebsiteOrigin, Record<string, number>> = {
		[WebsiteOrigin.hubu]: {
			ka: 1493689,
		},
		[WebsiteOrigin.tvSchool]: {
			ka: 1765981,
			en: 1841495,
			nl: 1823491,
		},
		[WebsiteOrigin.hubuExams]: { ka: 1528473 }, // TODO: Assign a real hotjarId, this is a random number!
	};
	const freshChatIds: Record<WebsiteOrigin, Record<string, string>> = {
		[WebsiteOrigin.hubu]: {},
		[WebsiteOrigin.tvSchool]: {
			en: "aadf1bce-4eac-45b0-b486-921dd01d0396",
			nl: "9bb3efe0-3701-4a6c-a697-39bb166df1fd",
		},
		[WebsiteOrigin.hubuExams]: {},
	};

	const appId = useMemo(() => {
		switch (web) {
			case WebsiteOrigin.tvSchool:
				return 262857528245872;
			case WebsiteOrigin.hubu:
				return 667144137298315;
			default:
				return 0;
		}
	}, [web]);

	useEffect(() => {
		if (isLocalhost()) return;
		if (isTVSchool()) {
			if (locale === "nl") {
				ReactGA.initialize("UA-71053074-6");
			} else if (locale === "ka") {
				ReactGA.initialize("UA-71053074-4");
			} else if (locale === "en") {
				ReactGA.initialize("UA-71053074-7");
			}
		} else {
			ReactGA.initialize("UA-71053074-3");
		}
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [locale]);

	let fbPixelId = "";
	if (web === WebsiteOrigin.hubu) {
		fbPixelId = "539279803297391";
	} else if (locale === "ka" && web === WebsiteOrigin.tvSchool) {
		fbPixelId = "2734328523556269";
	}

	return (
		<>
			<>
				<div id="fb-root" />
				<ScriptInBody>
					{`
							window.fbAsyncInit = function() {
								FB.init({
								  autoLogAppEvents : true,
								  appId            : ${appId},
								  xfbml            : true,
								  version          : 'v7.0'
								});
							};

							(function(d, s, id) {
								var js, fjs = d.getElementsByTagName(s)[0];
								if (d.getElementById(id)) return;
								js = d.createElement(s); js.id = id;
								js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
								fjs.parentNode.insertBefore(js, fjs);
							  }(document, 'script', 'facebook-jssdk'));`}
				</ScriptInBody>
				<ScriptInBody
					async
					defer
					crossOrigin="anonymous"
					nonce="0fDZs442"
					src={`https://connect.facebook.net/en_US/all.js#xfbml=1&version=v7.0&appId=${appId}&autoLogAppEvents=1`}
					onLoad={broadcastFBLoad}
				/>
			</>

			<>
				<div id="fb-root"></div>
				<ScriptInBody
					async
					defer
					crossOrigin="anonymous"
					src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=${appId}&autoLogAppEvents=1`}
					nonce="QA6234L8"
				></ScriptInBody>
			</>
			<Helmet>
				{web === WebsiteOrigin.tvSchool && locale === "ka" && (
					<script async src="//counter.top.ge/counter.js"></script>
				)}
				{/* facebook */}

				{fbPixelId && (
					<ScriptInBody>
						<script>
							{`!(function(f, b, e, v, n, t, s) {
								if (f.fbq) return;
								n = f.fbq = function() {
									n.callMethod
										? n.callMethod.apply(n, arguments)
										: n.queue.push(arguments);
								};
								if (!f._fbq) f._fbq = n;
								n.push = n;
								n.loaded = !0;
								n.version = "2.0";
								n.queue = [];
								t = b.createElement(e);
								t.async = !0;
								t.src = v;
								s = b.getElementsByTagName(e)[0];
								s.parentNode.insertBefore(t, s);
							})(
								window,
								document,
								"script",
								"https://connect.facebook.net/en_US/fbevents.js"
							);
							fbq("init", "${fbPixelId}");
							fbq("track", "PageView");
							`}
						</script>
					</ScriptInBody>
				)}

				{fbPixelId && (
					<noscript>
						{`<img
							height="1"
							width="1"
							style="display:none"
							src="https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1"
						/>`}
					</noscript>
				)}
				<meta property="fb:app_id" content={`${appId}`} />
				<title>{getFormattedMessage("webTitle-" + web)}</title>
				<link
					rel="shortcut icon"
					href={getFormattedMessage("favicon-" + web)}
				/>
				<meta
					name="description"
					content={getFormattedMessage(
						"metaInformation.content-" + web
					)}
				/>
				{!isLocalhost() && freshChatIds[web]![locale] && (
					<script>
						{`
					function initFreshChat() {
						window.fcWidget.init({
						token: "${freshChatIds[web]![locale]!}",
						host: "https://wchat.freshchat.com"
						});
					}
					function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
					`}
					</script>
				)}
				{!isLocalhost() && hotjarIds[web]?.[locale] && (
					<script>{`
				(function(h,o,t,j,a,r){
					h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
					h._hjSettings={hjid:${hotjarIds[web]![locale]!},hjsv:6};
					a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');r.async=1;
					r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
					a.appendChild(r);
				})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
			`}</script>
				)}
			</Helmet>
		</>
	);
});

// eslint-disable-next-line max-lines-per-function
(async () => {
	const theme = createMuiTheme({
		palette: {
			primary: {
				light: "#63bed9",
				main: "#278ea7",
				dark: "#023e5a",
			},
			secondary: {
				light: "#ffd64a",
				main: "#ffa500",
				dark: "#c67600",
			},
		},
		typography: {
			useNextVariants: true,
			fontFamily: "Roboto Geo Nus",
		},
		overrides: {
			MuiButton: {
				root: {
					textTransform: "initial",
				},
			},
		},
	});

	const locale = store.getState().intl.locale;
	await loadAndUpdateIntlMessages(locale);

	const techDiff =
		typeof process.env.REACT_APP_TECH_DIFF === "string" &&
		process.env.REACT_APP_TECH_DIFF.toLowerCase() === "true";

	const renderApp = () => {
		ReactDOM.render(
			<MuiThemeProvider theme={theme}>
				<ReduxStoreContext.Provider value={store}>
					<Provider store={store}>
						<IntlProvider {...store.getState().intl}>
							<>
								<BrowserRouter>
									<TeacherSchool>
										<MetaInformation />
										<GlobalHistory />
										<Switch>
											{techDiff && (
												<Route
													component={TechDifficulties}
												/>
											)}
											<Route component={App} />
										</Switch>
									</TeacherSchool>
								</BrowserRouter>
							</>
						</IntlProvider>
					</Provider>
				</ReduxStoreContext.Provider>
			</MuiThemeProvider>,
			document.getElementById("root")
		);
	};
	const renderUnsupportedApp = () => {
		ReactDOM.render(
			<div style={{ textAlign: "center", padding: 20, marginTop: 50 }}>
				{getFormattedMessage("browserSuggestion")}
			</div>,
			document.getElementById("root")
		);
	};

	if (!isMSExplorer()) {
		renderApp();
	} else {
		renderUnsupportedApp();
	}
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
	namespace React {
		interface DOMAttributes<T> {
			css?: InterpolationWithTheme<any>;
		}
	}
}
