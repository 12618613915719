import React from "react";
import { useGoToUrl } from "@app/hooks/front";
import { Group } from "@app/models/group";
import styles from "./styles/tvschool-classroom.module.css";

export const GroupBox = React.memo<{ group: Group }>(({ group }) => {
	const goToGroup = useGoToUrl(`/group/${group._id}/timetable`);

	return (
		<div className={styles.groupBox}>
			<img
				alt="buld"
				src="/imgs/classhead_illustration.svg"
				className={styles.illustration}
			/>
			<div className={styles.leftContainer}>
				<div className={styles.classInfo}>
					<div className={styles.type}> სადამრიგებლო </div>
					<div className={styles.grade}>{group.name}</div>
				</div>
				<button onClick={goToGroup} className={styles.newHomework}>
					კლასში შესვლა
				</button>
			</div>
		</div>
	);
});
