import React from "react";
import styles from "./styles/parent.module.css";
import { ReactComponent as Hat } from "./styles/img/student-avatar.svg";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { TeacherAvatarIcon } from "@app/icons";

export const RightSide: React.FC<{}> = props => {
	return (
		<div className={styles.rightSideContainer}>
			<div className={styles.studentAndParentInfo}>
				<ParentInfo />
				<StudentInfo />
			</div>
			<Verification />
			<div
				className={styles.notificationsContainer}
				style={{ marginTop: 35 }}
			>
				<Notifications />
			</div>
		</div>
	);
};

const StudentInfo: React.FC<{}> = props => {
	return (
		<div className={styles.studentInfo}>
			<div className={styles.hatContainerCirle}>
				<Hat style={{ width: 38 }} />
			</div>
			<div className={styles.studentName}>
				მოსწავლე
				<span>მარიამ ჯიჯავაძე</span>
			</div>
			<ArrowDownIcon />
		</div>
	);
};

const ParentInfo: React.FC<{}> = props => {
	return (
		<div className={styles.parentInfoContainer}>
			<span className={styles.parentName}>
				მშობელი
				<span>მარიამ ჯიჯავაძე</span>
			</span>
			<span className={styles.teacherAvatarContainer}>
				<TeacherAvatarIcon style={{ width: 32 }} />
				<span>!</span>
			</span>
		</div>
	);
};

const Verification: React.FC<{}> = props => {
	return (
		<div className={styles.verificationContainer}>
			<span>ვერიფიკაცია</span>
		</div>
	);
};

const Notifications: React.FC<{}> = props => {
	return <span>შეტყობინებები</span>;
};
