import { IStudentHomeworkListItem } from "@app/api/assignments/validators";
import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { useCurrentAssignmentInfo } from "@app/hooks/assignments";
import { useBoolean } from "@app/hooks/general";
import { IResourceLoadingInfo } from "@app/hooks/fetch";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useCallback, useMemo } from "react";
import { useHistory, useWindowSize } from "../../../hooks/front";
import { ProgressBar } from "../../widgets/progress-bar";
import { getNumStatsOfStudentAssignment } from "../assignments";
import { TeacherInvitationPopup } from "../invitations/outgoing";
import HomeworkStyles from "../styles/homework.module.css";
import { CurrentHomeworkLoader } from "./loaders";
import styles from "./styles/students-main-page.module.css";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import React from "react";

interface ICurrentHomeworkProps {
	classroom: IResourceLoadingInfo<IClassroom>;
	courseId: ObjectId;
	containerRef?: React.MutableRefObject<HTMLElement | null>;
	isJoinClassroomInvissible?: boolean;
	isContentLoaderInvissible?: boolean;
}

export const CurrentHomework: React.FC<ICurrentHomeworkProps> = React.memo(
	props => {
		const { width } = useWindowSize();

		const isMobile = width < 600;

		const { classroom, courseId } = props;
		const assignmentInfo = useCurrentAssignmentInfo(
			classroom.doc ? classroom.doc._id : null
		);

		const isRegistered = classroom.isIdentificationKnown;
		if (!isRegistered && props.isJoinClassroomInvissible) return null;

		if (!isRegistered && !props.isJoinClassroomInvissible) {
			return <BlurredCurrentHomework containerRef={props.containerRef} />;
		}

		if (isRegistered && assignmentInfo.doc && !assignmentInfo.doc.exists) {
			return <EmptyCurrentAssignment />;
		}
		if (!assignmentInfo.doc || !assignmentInfo.doc.exists) {
			return (
				<div style={{ position: "relative" }}>
					<CurrentHomeworkLoader
						courseId={courseId}
						isMobile={isMobile}
					/>
				</div>
			);
		}

		return (
			<CurrentHomeWorkBox
				courseId={courseId}
				assignmentInfo={assignmentInfo.doc}
			/>
		);
	}
);

const CurrentHomeWorkBox: React.FC<{
	courseId: ObjectId;
	assignmentInfo: IStudentHomeworkListItem;
}> = React.memo(props => {
	const { width } = useWindowSize();
	const progressWidth = width > 800 ? "100%" : 100;
	const { assignmentInfo } = props;
	const { assignment, writtenAssignment } = assignmentInfo;
	const AssignmentPermissionsService = inject("AssignmentPermissionsService");
	const {
		classroomEdge,
		studentEdge,
	} = AssignmentPermissionsService.getClassroomAndStudentEdge(
		assignmentInfo.edges
	);
	const settings = AssignmentPermissionsService.getSettings({
		assignmentSettings: assignment.settings,
		classroomEdge,
		studentEdge,
	});
	const {
		deadlinePassed,
		isSubmitted,
	} = AssignmentPermissionsService.getAssignmentStatus({
		settings,
		studentEdge,
	});

	const { totalNumOfQuestions, answeredNumOfQuestions } = useMemo(
		() =>
			getNumStatsOfStudentAssignment({
				assignment: assignment,
				writtenAssignment: writtenAssignment,
			}),
		[assignment, writtenAssignment]
	);
	const history = useHistory();
	const progress =
		totalNumOfQuestions === 0
			? 0
			: answeredNumOfQuestions / totalNumOfQuestions;

	const redirectToAssignment = useCallback(() => {
		history.push(
			`/courses/${props.courseId}/assignments/${assignment._id}`
		);
	}, [history, props.courseId, assignment._id]);

	const isMobile = width < 800;

	return (
		<div className={styles.currentHomeworkMainContainer}>
			<div className={styles.currentHomework}>
				<div className={styles.homeworkInnerBody}>
					<div className={styles.homeworkName}>{assignment.name}</div>
					<div className={styles.progressContainer}>
						<div style={{ width: "90%" }}>
							<ProgressBar progress={progress}></ProgressBar>
						</div>

						<div className={styles.progressNumber}>
							{Math.floor(progress * 100)}%
						</div>
					</div>
					<div className={styles.currentHomeworkText}>
						<div>
							<button
								className={styles.homeworkButton}
								onClick={redirectToAssignment}
							>
								{deadlinePassed || isSubmitted
									? getFormattedMessage("review")
									: getFormattedMessage("write")}
							</button>
						</div>
						<div className={styles.homeworkCircle}></div>
						<div
							className={styles.buttonAllInCircle}
							style={{
								justifyContent: "flex-end",
								alignItems: "center",
							}}
							onClick={() => {
								history.push(
									`/courses/${props.courseId}/assignments`
								);
							}}
						>
							<FormattedMessage id="seeAll" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

const BlurredCurrentHomework: React.FC<{
	containerRef?: React.MutableRefObject<HTMLElement | null>;
	isResponsive?: boolean;
}> = props => {
	const {
		value: isInvitationPopupOpen,
		setTrue: openInvitationPopup,
		setFalse: closeInvitationPopup,
	} = useBoolean(false);

	return (
		<>
			<div
				style={{ position: "relative" }}
				ref={
					props.containerRef as React.MutableRefObject<HTMLDivElement | null>
				}
			>
				<div
					className={styles.currentHomework}
					style={{
						filter: "blur(3px)",
					}}
				>
					<div className={styles.currentHomeworkText}>
						<div className={styles.homeworkCircle}></div>
					</div>
					<div className={styles.progressContainer}>
						<div style={{ width: 170 }}>
							<ProgressBar progress={1}>100%</ProgressBar>
						</div>
					</div>
					<div className={styles.currentLessonText}>
						<div style={{ textAlign: "right" }}>
							<div className={HomeworkStyles.currentLessonName}>
								<FormattedMessage id="student:currentAssignment" />
							</div>
							<div className={HomeworkStyles.topicName}>
								<FormattedMessage id="student:nameOfAssignment" />
							</div>
							<div>
								<button className={styles.homeworkButton}>
									<FormattedMessage id="write" />
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.isNotRegisteredDiv}>
					<button
						className={styles.addToClassroomButton}
						onClick={openInvitationPopup}
					>
						<FormattedMessage id="student:joinClassroom" />
					</button>
				</div>
			</div>

			{isInvitationPopupOpen && (
				<TeacherInvitationPopup onClose={closeInvitationPopup} />
			)}
		</>
	);
};

const EmptyCurrentAssignment: React.FC<{}> = React.memo(() => {
	return (
		<div className={styles.emptyCurrentHomework}>
			<FormattedMessage id="student:noAssignments" />
		</div>
	);
});
