import { IRequest } from "../requests";
import {
	IAPOSTStudentGrades,
	IRPOSTStudentGrades,
	IAGETClassroomStudentGradebooks,
	AGETClassroomStudentGradebooksSchema,
	IRGETClassroomStudentGradebooks,
	APOSTStudentGradesSchema,
	IAGETStudentGradebook,
	IRGETStudentGradebook,
	AGETStudentGradebookSchema,
	IADELETEStudentGrades,
	ADELETEStudentGradesSchema,
	IRDELETEStudentGrades,
	RGETClassroomStudentGradebooksSchema,
	IAPUTUpdateGradesDisability,
	IRPUTUpdateGradesDisability,
	APUTUpdateGradesDisabilitySchema,
} from "./validators";

export class GradebookController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	saveStudentGrades = async (
		args: IAPOSTStudentGrades
	): Promise<IRPOSTStudentGrades> =>
		this.Request.send(
			"POST",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{ requestSchema: APOSTStudentGradesSchema }
		);

	getStudentGradebook = async (
		args: IAGETStudentGradebook
	): Promise<IRGETStudentGradebook> =>
		this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/students/:userId/grades",
			args,
			null,
			{
				requestSchema: AGETStudentGradebookSchema,
			}
		);

	getClassroomGradebooks = async (
		args: IAGETClassroomStudentGradebooks
	): Promise<IRGETClassroomStudentGradebooks> =>
		this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{
				requestSchema: AGETClassroomStudentGradebooksSchema,
				responseSchema: RGETClassroomStudentGradebooksSchema,
			}
		);

	deleteStudentGrades = async (
		args: IADELETEStudentGrades
	): Promise<IRDELETEStudentGrades> =>
		this.Request.send(
			"DELETE",
			"/api/classrooms/:classroomId/grades",
			args,
			null,
			{ requestSchema: ADELETEStudentGradesSchema }
		);

	updateGradesDisability = async (
		args: IAPUTUpdateGradesDisability
	): Promise<IRPUTUpdateGradesDisability> =>
		this.Request.send(
			"PUT",
			"/api/classrooms/:classroomId/grades/disablity",
			args,
			null,
			{ requestSchema: APUTUpdateGradesDisabilitySchema }
		);
}
