import { useLocationQuery } from "@app/hooks/front";
import { SetState } from "@app/hooks/state-management";
import { useTeacherClassroomsSchoolIds } from "@app/hooks/teacher";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import React, {
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";

interface TeacherSchoolContextValue {
	schoolId: number | null;
	setSchoolId: SetState<number | null>;
}

const TeacherSchoolContext = React.createContext<TeacherSchoolContextValue>(
	{} as any
);

export const useTeacherSelectedSchoolId = () => {
	return useContext(TeacherSchoolContext).schoolId;
};
export const useTeacherSchoolSetterFn = () => {
	return useContext(TeacherSchoolContext).setSchoolId;
};

export const TeacherSchool = ({ children }) => {
	const [schoolId, setSchoolId] = useState<number | null>(null);
	const user = useClassroomsUser();
	const value = useMemo(
		() => ({
			schoolId,
			setSchoolId,
		}),
		[schoolId]
	);

	return (
		<TeacherSchoolContext.Provider value={value}>
			{children}
			{user?.isTeacher() && (
				<TeacherSchoolInitializer
					schoolId={schoolId}
					setSchoolId={setSchoolId}
				/>
			)}
		</TeacherSchoolContext.Provider>
	);
};

const LAST_SELECTED_SCHOOL_ID_KEY = "LAST_SELECTED_SCHOOL_ID";
export const SELECTED_SCHOOL_ID_QUERY_KEY = "tSchoolId";

const TeacherSchoolInitializer = React.memo<TeacherSchoolContextValue>(
	({ schoolId, setSchoolId }) => {
		const userSchool = useClassroomsUser()!.school;
		const teacherSchoolsInfo = useTeacherClassroomsSchoolIds();

		const hasFetchedLastSchoolAtLEastOnceRef = useRef(false);
		const hasMounted = useRef(false);

		const {
			[SELECTED_SCHOOL_ID_QUERY_KEY]: unparsedSchoolIdFromQuery,
		} = useLocationQuery(true);

		const schoolIdFromQuery = parseIdFromStr(
			unparsedSchoolIdFromQuery as string | null | undefined
		);

		useLayoutEffect(() => {
			if (!teacherSchoolsInfo) return;

			if (schoolIdFromQuery !== undefined) {
				if (isValidSchool(schoolIdFromQuery, teacherSchoolsInfo)) {
					setSchoolId(schoolIdFromQuery);
					return;
				}
			}

			const parsedSchoolId = parseIdFromStr(
				sessionStorage[LAST_SELECTED_SCHOOL_ID_KEY]
			);
			if (parsedSchoolId !== undefined) {
				if (isValidSchool(parsedSchoolId, teacherSchoolsInfo)) {
					setSchoolId(parsedSchoolId);
					return;
				}
			}

			const checkLastPriorities = () => {
				if (teacherSchoolsInfo.schoolIds.length > 0) {
					const randomSchoolId = teacherSchoolsInfo.schoolIds[0];
					setSchoolId(randomSchoolId);
					return;
				}
				setSchoolId(userSchool);
			};

			let isCancelled = false;

			if (hasFetchedLastSchoolAtLEastOnceRef.current === true) {
				checkLastPriorities();
			} else {
				getLastSchoolInDatabase().then(id => {
					if (isCancelled) return;

					hasFetchedLastSchoolAtLEastOnceRef.current = true;
					if (id !== undefined) {
						if (isValidSchool(id, teacherSchoolsInfo)) {
							setSchoolId(id);
							return;
						}
					}

					checkLastPriorities();
				});
			}

			return () => {
				isCancelled = true;
			};
		}, [teacherSchoolsInfo, schoolIdFromQuery, setSchoolId, userSchool]);

		useEffect(() => {
			sessionStorage[LAST_SELECTED_SCHOOL_ID_KEY] = schoolId + "";
			if (hasMounted.current) {
				saveLastSchoolInDatabase(schoolId);
			}
			hasMounted.current = true;
		}, [schoolId]);

		return null;
	}
);

const isValidSchool = (
	schoolId: number | null,
	teacherSchoolsInfo: NonNullable<
		ReturnType<typeof useTeacherClassroomsSchoolIds>
	>
): boolean => {
	if (schoolId === null) return teacherSchoolsInfo.hasSchoollessClassroom;
	return teacherSchoolsInfo.schoolIds.includes(schoolId);
};

const parseIdFromStr = (
	value: string | null | undefined
): number | null | undefined => {
	if (value === "null") return null;
	return !value ? undefined : +(value as string);
};

const saveLastSchoolInDatabase = (schoolId: number | null) => {
	inject("UserActionsController")
		.performUserAction({
			name: "lastVisitedSchoolId",
			data: schoolId,
		})
		.catch(() => {
			//
		});
};

const getLastSchoolInDatabase = (): Promise<number | null | undefined> => {
	return inject("UserActionsController")
		.getUserAction()
		.then(data => {
			return data.actions["lastVisitedSchoolId"];
		});
};
