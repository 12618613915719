import { getLocale } from "@app/hooks/intl";
import { inject } from "@app/modules";
import { WebsiteOrigin } from "@app/globals";

export const createGrades = (
	country: string | null,
	regOrigin: WebsiteOrigin | null
) => {
	const locale = getLocale();
	const { minGrade, maxGrade } = inject(
		"CountriesService"
	).getMinAndMaxGrades(country, regOrigin);
	const classrooms: {
		value: number;
		labelForRomanNums: string;
		label: string;
	}[] = [];
	for (let i = minGrade; i <= maxGrade; i++) {
		classrooms.push({
			label: getOrdinalNumber(i, locale),
			labelForRomanNums: getRomanNumber(i, locale),
			value: i,
		});
	}
	return classrooms;
};

const getOrdinalNumber = (num: number, locale: string): string => {
	if (locale === "ka") {
		if (num === 1) return "1-ლი";
		if (num <= 20 || num % 20 === 0) return "მე-" + num;
		return num + "-ე";
	} else if (locale === "en") {
		if (num === 1) return "1st";
		else if (num === 2) return "2nd";
		else if (num === 3) return "3rd";
		else return num + "th";
	}
	return num + "";
};

const getRomanNumber = (num: number, locale: string): string => {
	if (locale === "ka") {
		if (num === 1) return "I";
		if (num === 2) return "II";
		if (num === 3) return "III";
		if (num === 4) return "IV";
		if (num === 5) return "V";
		if (num === 6) return "VI";
		if (num === 7) return "VII";
		if (num === 8) return "VIII";
		if (num === 9) return "IX";
		if (num === 10) return "X";
		if (num === 11) return "XI";
		if (num === 12) return "XII";
	}
	return num + "";
};
