import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { useSetIsNewSidebarVisible } from "@app/components/general-context";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { InputWithSaveButton } from "@app/components/widgets/input-with-save-button";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { useClassroom } from "@app/hooks/classrooms";
import { useClassroomsUserId } from "@app/hooks/users";
import { useVideoLesson } from "@app/hooks/video-lessons";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { isTestTeacher } from "@app/utils/testing";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { History } from "history";
import { match } from "react-router-dom";
import {
	getTeacherSidebarItems,
	isTeacherSidebarPath,
	TeacherSidebarType,
} from "./sidebar-items";
import styles from "../../layouts/sidebar/styles/index.module.scss";
import { ISideBarProps } from "@app/components/layouts/sidebar/helpers/interfaces";
import { SideBar } from "@app/components/layouts/sidebar";
import { onTeacherOpenVideoLesson } from "@app/common-javascript";
import { VideoLinkButton } from "@app/components/widgets/video-link-button";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { useWindowSize } from "@app/hooks/front";

export const TeacherSideBarWrapper = React.memo<{
	history: History;
	match: match<any>;
}>(({ history, match }) => {
	const userId = useClassroomsUserId();
	const isTest = isTestTeacher(userId!);
	const classroomId: ObjectId | null = match.params.classroomId || null;
	const groupId = match.params.groupId || null;
	const displaySideBar =
		(classroomId || groupId) && isTeacherSidebarPath(match.path);
	useSetIsNewSidebarVisible(displaySideBar);

	const classroom = useClassroom(classroomId);

	const items = useMemo(() => {
		if (classroomId && classroom.doc && classroom.doc.isPremium()) {
			return getTeacherSidebarItems(
				TeacherSidebarType.classroom,
				classroomId,
				isTest,
				classroom.doc
			);
		} else if (groupId) {
			return getTeacherSidebarItems(
				TeacherSidebarType.group,
				groupId,
				isTest
			);
		}
		return null;
	}, [classroom.doc, classroomId, groupId, isTest]);

	if (!displaySideBar || !items) {
		return null;
	}

	return <TeacherSideBar history={history} items={items} match={match} />;
});

const StartVideoLessonForSidebar: React.FC<{
	classroomId: ObjectId;
}> = React.memo(({ classroomId }) => {
	const { width } = useWindowSize();
	const videoLesson = useVideoLesson(
		classroomId,
		VideoLessonResourceTypes.CLASSROOM
	);
	const videoLink = videoLesson.doc?.url;

	const handleVideoLessonClick = useCallback(() => {
		if (classroomId && videoLink) {
			onTeacherOpenVideoLesson(
				classroomId,
				VideoLessonResourceTypes.CLASSROOM
			);
		}
	}, [classroomId, videoLink]);

	return (
		<VideoLinkButton
			style={
				width > 1400
					? { width: "335px", height: "50px" }
					: { width: "82%", fontSize: "14px" }
			}
			lessonStatus={
				videoLink
					? VideoLessonStatus.ACTIVE
					: VideoLessonStatus.NOT_AVAILABLE
			}
			onClick={handleVideoLessonClick}
		/>
	);
});

export const TeacherSideBar: React.FC<ISideBarProps> = (
	props: ISideBarProps
) => {
	const resourceId =
		props.match.params.classroomId || props.match.params.groupId || null;
	const resourceType = props.match.params.classroomId
		? VideoLessonResourceTypes.CLASSROOM
		: VideoLessonResourceTypes.GROUP;

	return (
		<SideBar {...props}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					marginTop: "20px",
				}}
			>
				{resourceType === VideoLessonResourceTypes.CLASSROOM && (
					<StartVideoLessonForSidebar classroomId={resourceId} />
				)}
				{!!resourceId && (
					<VideoLessonLink
						resourceId={resourceId}
						resourceType={resourceType}
					/>
				)}
			</div>
		</SideBar>
	);
};

export const VideoLessonLink = React.memo<{
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
}>(({ resourceId, resourceType }) => {
	const [displayPopup, setDisplayPopup] = useState(false);
	const onPopupToggle = useCallback(
		() => setDisplayPopup(displayPopup => !displayPopup),
		[]
	);
	const onVideoURLChange = useCallback(
		(newLink: string) => {
			const VideoLessonsController = inject("VideoLessonsController");
			VideoLessonsController.add({
				resourceId,
				resourceType,
				url: newLink,
			})
				.then(() => {
					openConfirmationPopup({
						text: `${
							resourceType === VideoLessonResourceTypes.CLASSROOM
								? "ვიდეოგაკვეთილის"
								: "სადამრიგებლო საათის"
						} ბმული წარმატებით დაემატა`,
						approveTitle: "გასაგებია",
					});
				})
				.catch(e => alert("დაფიქსირდა შეცდომა"));
		},
		[resourceId, resourceType]
	);

	const onTemporaryLinkRequest = useCallback(() => {
		const VideoLessonsController = inject("VideoLessonsController");
		VideoLessonsController.requestTemporaryLink({
			resourceId,
			resourceType,
		})
			.then(() => {
				openConfirmationPopup({
					text:
						"თქვენ ჩაანაცვლეთ ძველი ბმული. შეგიძლიათ, შეხვიდეთ გაკვეთილზე. " +
						"ბმული ჩანაცვლებულია მოსწავლეებთანაც. სთხოვეთ მათ ხელახლა დააწკაპუნონ " +
						`ღილაკს "${
							resourceType === VideoLessonResourceTypes.CLASSROOM
								? "ვიდეოგაკვეთილზე"
								: "სადამრიგებლოში"
						} შესვლა".`,
					approveTitle: "გასაგებია",
				});
			})
			.catch(e => alert("დაფიქსირდა შეცდომა"));
		setDisplayPopup(displayPopup => !displayPopup);
	}, [resourceId, resourceType]);

	const videoLesson = useVideoLesson(resourceId, resourceType);
	const [videoLink, setVideoLink] = useState(
		videoLesson.doc ? videoLesson.doc.url : ""
	);

	useEffect(() => {
		if (videoLesson.doc) {
			setVideoLink(videoLesson.doc.url);
		}
	}, [videoLesson.doc]);

	const texts = useMemo((): { text1: string; text2: string } => {
		switch (resourceType) {
			case VideoLessonResourceTypes.CLASSROOM:
				return {
					text1: "ვიდეოგაკვეთილის ბმული",
					text2: "გთხოვთ, ჩააკოპიროთ დაგეგმილი ვიდეოგაკვეთილის ბმული",
				};
			case VideoLessonResourceTypes.GROUP:
				return {
					text1: "სადამრიგებლო საათის ბმული",
					text2:
						"გთხოვთ, ჩააკოპიროთ დაგეგმილი სადამრიგებლო საათის ბმული",
				};
			default:
				throw new Error(`Type ${resourceType} not supported`);
		}
	}, [resourceType]);

	return (
		<div className={styles["video-link-container"]}>
			<div className={styles["video-link-button"]}>
				<img
					src="/imgs/TuTorial.svg"
					className={styles.video_icon}
					alt=""
				/>
				<span>{texts.text1}</span>
			</div>
			<div className={styles["instruction"]}>{texts.text2}</div>
			<InputWithSaveButton
				onSave={onVideoURLChange}
				defaultValue={videoLink || undefined}
			/>
			{videoLink && (
				<div
					className={styles["video-link-not-working"]}
					onClick={onPopupToggle}
				>
					არ მუშაობს ბმული?
				</div>
			)}
			{displayPopup && (
				<VideoLinkReplacePopup
					onConfirm={onTemporaryLinkRequest}
					onClose={onPopupToggle}
				/>
			)}
		</div>
	);
});

interface IVideoLinkReplacePopup {
	onConfirm: () => void;
}

const VideoLinkReplacePopup: React.FC<IVideoLinkReplacePopup & {
	onClose: () => void;
}> = React.memo(({ onConfirm, onClose }) => {
	return (
		<Popup onClose={() => onClose()}>
			<PopupContentWithClose onClose={() => onClose()}>
				<div>
					<p>
						თუ არსებული ბმული აღარ მუშაობს, შეგიძლიათ ჩაანაცვლოთ
						ახლით.
						<br />
						გსურთ ბმულის ჩანაცვლება?
					</p>
					<div className={styles["popup-buttons"]}>
						<button
							className={styles["confirm"]}
							onClick={onConfirm}
						>
							კი
						</button>
						<button className={styles["reject"]} onClick={onClose}>
							არა
						</button>
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});
