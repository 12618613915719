import { useWindowSize } from "@app/hooks/front";
import React, { useEffect, useState } from "react";

const commonStyles =
	"<style>body{margin: 0; text-align: center;} image, img{display: block; width: 100% !important; height: 100% !important;}</style>";

export const TestAd = React.memo<{}>(function AdButler({}) {
	const { width } = useWindowSize();
	const isWide = width > 800;
	const [show, setShow] = useState(false);
	useEffect(() => {
		setTimeout(() => setShow(true), 10);
	}, []);
	return (
		<div className="main main2">
			<div className="border-2 border-primary rounded-xl p-4 bg-primary-100">
				ტექსტი
			</div>
			{show && <DesktopAd />}
		</div>
	);
});

export const DesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	return (
		<iframe
			title="tvvvv"
			src={"data:text/html;charset=utf-8," + encodeURI(desktopAd)}
			className={"mx-auto " + (className || "")}
			style={{
				width: 745,
				height: 108,
				...style,
			}}
		/>
	);
});

export const NewDesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const { width } = useWindowSize();
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newDesktopAd) +
				commonStyles
			}
			className={"mx-auto " + (className || "")}
			style={{
				width: 728,
				maxWidth: "100%",
				height: (Math.min(width, 728) * 90) / 728,
				...style,
			}}
		/>
	);
});

export const NewDesktopAd2 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const { width } = useWindowSize();
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newDesktopAd2) +
				commonStyles
			}
			className={"mx-auto " + (className || "")}
			style={{
				width: 728,
				maxWidth: "100%",
				height: (Math.min(width, 728) * 90) / 728,
				...style,
			}}
		/>
	);
});

export const NewDesktopAd3 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const { width } = useWindowSize();
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newDesktopAd3) +
				commonStyles
			}
			className={"mx-auto " + (className || "")}
			style={{
				width: 728,
				maxWidth: "100%",
				height: (Math.min(width, 728) * 90) / 728,
				...style,
			}}
		/>
	);
});

export const MobileAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function MobileAd({ style, className }) {
	return (
		<iframe
			title="tvvvv2"
			src={"data:text/html;charset=utf-8," + encodeURI(mobileAd)}
			className={"mx-auto " + (className || "")}
			style={{ width: 314, height: 270, ...style }}
		/>
	);
});

export const NewMobileAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function NewMboileAd({ style, className }) {
	const { width } = useWindowSize();
	return (
		<iframe
			title="tvvvv2"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newMobileAd) +
				(width < 600 ? commonStyles : "")
			}
			className={"mx-auto " + (className || "")}
			style={{
				width: 300,
				maxWidth: "100%",
				height: (Math.min(300, width) * 5) / 6,
				...style,
			}}
		/>
	);
});

export const Butler = React.memo<{
	desktopStyle?: React.CSSProperties;
	desktopClassName?: string;
	mobileStyle?: React.CSSProperties;
	mobileClassName?: string;
}>(function ButlerAd({
	desktopStyle,
	mobileStyle,
	desktopClassName,
	mobileClassName,
}) {
	const { width } = useWindowSize();
	if (width > 780) {
		return <DesktopAd style={desktopStyle} className={desktopClassName} />;
	}
	return <MobileAd style={mobileStyle} className={mobileClassName} />;
});

const desktopAd = `<!-- Desktop Banner [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc472550 = window.plc472550 || 0;
document.write('<'+'div id="placement_472550_'+plc472550+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 472550, [728,90], 'placement_472550_'+opt.place, opt); }, opt: { place: plc472550++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const mobileAd = `<!-- Mobile Banner [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc472551 = window.plc472551 || 0;
document.write('<'+'div id="placement_472551_'+plc472551+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 472551, [300,250], 'placement_472551_'+opt.place, opt); }, opt: { place: plc472551++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const newDesktopAd = `<!-- Dashboard Desktop [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483991 = window.plc483991 || 0;
document.write('<'+'div id="placement_483991_'+plc483991+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483991, [728,90], 'placement_483991_'+opt.place, opt); }, opt: { place: plc483991++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>
`;

const newDesktopAd2 = `
<!-- Dashboard Desktop 2 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483992 = window.plc483992 || 0;
document.write('<'+'div id="placement_483992_'+plc483992+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483992, [728,90], 'placement_483992_'+opt.place, opt); }, opt: { place: plc483992++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>
`;

const newDesktopAd3 = `<!-- Dashboard Desktop 3 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483993 = window.plc483993 || 0;
document.write('<'+'div id="placement_483993_'+plc483993+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483993, [728,90], 'placement_483993_'+opt.place, opt); }, opt: { place: plc483993++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>
`;

const newMobileAd = `
<!-- Mobile 300x250 1 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483994 = window.plc483994 || 0;
document.write('<'+'div id="placement_483994_'+plc483994+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483994, [300,250], 'placement_483994_'+opt.place, opt); }, opt: { place: plc483994++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>

`;
