import { UserType } from "@app/api/helper-schemas";
import {
	IRUser,
	IUserPermissions,
	RUserSchema,
} from "@app/api/users/helper-schemas";
import { flatten, pickKeys } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import Joi, { getJoiObjectKeys } from "@app/utils/joi";
import { UserPermissions } from "./permissions";
import { DeveloperPermissions } from "./permissions/developer";
import { HeadmasterPermissions } from "./permissions/headmaster";
import { IRAccessibleIds, RCanAccess } from "./permissions/interfaces";
import { MainAdminPermissions } from "./permissions/main-admin";
import { ParentPermissions } from "./permissions/parent";
import { StudentPermissions } from "./permissions/student";
import { TeacherPermissions } from "./permissions/teacher";

type OwnIdsGetterKeys = Extract<
	keyof UserPermissions,
	"getAvailableClassrooms" | "getOwnClassrooms" | "getOwnGroups"
>;
type CanAccessGetterKeys = Extract<keyof UserPermissions, "canAccessFolder">;

export class User implements Omit<IRUser, "permissions"> {
	public static createUserInstance(user: IRUser): User {
		const validationResult = this.validateUserObject(user);
		const permissions = User.getPermissions(validationResult);
		return new User(user, [permissions], user.permissions);
	}

	private static getPermissions(user: IRUser): UserPermissions {
		const rawPermissions = user.permissions;
		switch (rawPermissions.userType) {
			case UserType.teacher:
				return new TeacherPermissions(rawPermissions);
			case UserType.student:
				return new StudentPermissions(rawPermissions);
			case UserType.parent:
				return new ParentPermissions(rawPermissions);
			case UserType.mainAdmin:
				return new MainAdminPermissions();
			case UserType.developer:
				return new DeveloperPermissions();
			case UserType.headmaster:
				return new HeadmasterPermissions(user.school);
			default:
				return new StudentPermissions(rawPermissions);
		}
	}

	getRawUser(): IRUser {
		return pickKeys(
			{ ...(this as any), permissions: this.rawPermission } as IRUser,
			...(getJoiObjectKeys(RUserSchema) as (keyof IRUser)[])
		);
	}

	private static validateUserObject(userObject: IRUser): IRUser {
		const validationResult = RUserSchema.keys({
			iat: Joi.number()
				.integer()
				.optional(),
			exp: Joi.number()
				.integer()
				.optional(),
		}).validate(userObject, { stripUnknown: true });
		if (validationResult.error) {
			throw validationResult.error;
		}
		return validationResult.value;
	}

	id: IRUser["id"];
	murtskuId: IRUser["murtskuId"];
	mobile: IRUser["mobile"];
	mail: IRUser["mail"];
	isValidMobile: IRUser["isValidMobile"];
	isValidMail: IRUser["isValidMail"];
	username: IRUser["username"];
	firstname: IRUser["firstname"];
	lastname: IRUser["lastname"];
	grade: IRUser["grade"];
	city: IRUser["city"];
	school: IRUser["school"];
	country: IRUser["country"];
	language: IRUser["language"];
	registrationOrigin: IRUser["registrationOrigin"];
	hasAgreedOnTerms: IRUser["hasAgreedOnTerms"];

	constructor(
		user: IRUser,
		private readonly permissions: UserPermissions[],
		private readonly rawPermission: IUserPermissions
	) {
		this.id = user.id;
		this.murtskuId = user.murtskuId;
		this.mobile = user.mobile;
		this.mail = user.mail || null;
		this.isValidMobile = user.isValidMobile;
		this.isValidMail = user.isValidMail;
		this.username = user.username;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.grade = user.grade;
		this.city = user.city;
		this.school = user.school;
		this.country = user.country;
		this.language = user.language;
		this.registrationOrigin = user.registrationOrigin;
		this.hasAgreedOnTerms = user.hasAgreedOnTerms;
	}

	canAccessAllClassrooms(): boolean {
		return this.permissions.some(p => p.canAccessAllClassrooms());
	}

	canAccessClassroom(classroomId: ObjectId): boolean {
		return this.permissions.some(p => p.canAccessClassroom(classroomId));
	}

	canStudyInClassroom(classroomId: ObjectId): boolean {
		return this.permissions.some(p => p.canStudyInClassroom(classroomId));
	}

	isOwnGroup(groupId: ObjectId): boolean {
		return this.permissions.some(p => p.canAccessGroup(groupId));
	}

	getAvailableClassrooms(): ObjectId[] {
		return this.getOwnIds("getAvailableClassrooms");
	}

	getOwnClassrooms(): ObjectId[] {
		return this.getOwnIds("getOwnClassrooms");
	}

	getOwnGroups(): ObjectId[] {
		return this.getOwnIds("getOwnGroups");
	}

	private getOwnIds(fnKey: OwnIdsGetterKeys): ObjectId[] {
		const idsOfPermission = this.permissions.map(p => p[fnKey]());
		return flatten(idsOfPermission, "unique");
	}

	private getCanAccess<K extends CanAccessGetterKeys>(
		fnKey: K
	): UserPermissions[K] {
		return (...args: Parameters<UserPermissions[K]>): RCanAccess => {
			const perm: RCanAccess[] = this.permissions.map(p =>
				(p[fnKey] as any)(...args)
			);
			const knownPerms = perm.filter(isKnown);
			if (knownPerms.some(ids => ids.canAccess)) {
				return {
					isKnown: true,
					canAccess: true,
				};
			}
			if (knownPerms.length !== perm.length) {
				return {
					isKnown: false,
				};
			}
			return {
				isKnown: true,
				canAccess: false,
			};
		};
	}

	hasConfirmedChild(childId: number): boolean {
		return this.permissions.some(p => p.hasConfirmedChild(childId));
	}

	isTeacher(): boolean {
		return this.hasPermissionsOfInstance(TeacherPermissions);
	}

	isStudent(): boolean {
		return this.hasPermissionsOfInstance(StudentPermissions);
	}

	isMainAdmin(): boolean {
		return this.hasPermissionsOfInstance(MainAdminPermissions);
	}

	isDeveloper(): boolean {
		return this.hasPermissionsOfInstance(DeveloperPermissions);
	}

	isParent(): boolean {
		return this.hasPermissionsOfInstance(ParentPermissions);
	}

	isHeadmaster(): boolean {
		return this.hasPermissionsOfInstance(HeadmasterPermissions);
	}

	getPacket() {
		const withPacket = this.permissions.find(
			e => e instanceof StudentPermissions && e.getPacket()
		);
		if (!withPacket) return null;
		return (withPacket as StudentPermissions).getPacket();
	}

	getRandomType() {
		if (this.isTeacher()) return UserType.teacher;
		if (this.isParent()) return UserType.parent;
		if (this.isMainAdmin()) return UserType.mainAdmin;
		if (this.isDeveloper()) return UserType.developer;
		if (this.isHeadmaster()) return UserType.headmaster;
		return UserType.student;
	}

	private hasPermissionsOfInstance<
		T extends new (...args: any) => UserPermissions
	>(cls: T) {
		return this.permissions.some(p => p instanceof cls);
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		const idsOfPermission = this.permissions.map(p =>
			p.getAccessibleCourseIds()
		);
		const knownIdsOfPermission = idsOfPermission.filter(isKnown);
		if (knownIdsOfPermission.some(ids => ids.hasAll)) {
			return {
				isKnown: true,
				hasAll: true,
			};
		}
		if (knownIdsOfPermission.length !== idsOfPermission.length) {
			return {
				isKnown: false,
			};
		}
		return {
			isKnown: true,
			hasAll: false,
			ids: flatten(
				knownIdsOfPermission.map(e => (e.hasAll ? [] : e.ids)),
				"unique"
			),
		};
	}

	canAccessFolder(args: {
		courseId: ObjectId;
		folderId: ObjectId;
	}): RCanAccess {
		return this.getCanAccess("canAccessFolder")(args);
	}

	// school permissions
	canViewSchool(schoolId: number) {
		return this.permissions.some(p => p.canViewSchool(schoolId));
	}
}

const isKnown = <T extends { isKnown: boolean }>(
	el: T
): el is Extract<T, { isKnown: true }> => {
	return el.isKnown;
};
