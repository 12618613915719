import { IRequest } from "../requests";
import { IFacebookVideo } from "./helper-schemas";
import {
	IADELETEFacebookVideo,
	IAPOSTFacebookVideos,
	IAPUTUpdateFacebookVideos,
	IRGETFacebookVideosSchema,
	RGETFacebookVideosSchema,
} from "./validators";

const URL = "/api/facebook-videos/";

export class FacebookVideosController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTFacebookVideos): Promise<IFacebookVideo[]> =>
		this.Request.send("POST", URL, args, null, {
			responseSchema: RGETFacebookVideosSchema,
		});

	getAll = async (): Promise<IRGETFacebookVideosSchema> =>
		this.Request.send("GET", URL, {}, null, {
			responseSchema: RGETFacebookVideosSchema,
		});

	update = async (args: IAPUTUpdateFacebookVideos): Promise<void> =>
		this.Request.send("PUT", URL, args, null);

	delete = async (args: IADELETEFacebookVideo): Promise<void> =>
		this.Request.send("DELETE", URL, args, null);
}
