import { IRequest } from "../requests";
import {
	IAPOSTPublicTest,
	IRPOSTPublicTest,
	IAGETPublicTests,
	IRGETPublicTests,
	RPOSTPublicTestSchema,
	AGETPublicTestsSchema,
	IAPUTPublicTestStatus,
	IRPUTPublicTestStatus,
	IAGETPublicTestCountByOriginalCourseId,
	IRGETPublicTestCountByOriginalCourseId,
	IAGETPublicTestsForTeacher,
	IRGETPublicTestsForTeacher,
	IAPOSTImportCourseTests,
	IAPUTPublicTest,
	IRPUTPublicTest,
	IRGETPublicTestChangesByAuthor,
	IAPUTPublicTestChangeSeen,
	IAPUTPublicTestChangeSeenForMany,
	IAGETPublicTest,
	IRGETPublicTest,
	APUTPublicTestType,
	IAPUTCognitiveAssignmentMetaData,
} from "./validators";
import { IRPublicTest } from "./helper-schemas";

export class PublicTestController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (args: IAPOSTPublicTest): Promise<IRPublicTest> =>
		this.Request.send("POST", "/api/public-tests", args, null, {
			responseSchema: RPOSTPublicTestSchema,
		}).then((data: IRPOSTPublicTest) => data);

	getMany = async (args: IAGETPublicTests): Promise<IRGETPublicTests> =>
		this.Request.send("GET", "/api/public-tests", args, null, {
			requestSchema: AGETPublicTestsSchema,
		});

	getById = async (args: IAGETPublicTest): Promise<IRGETPublicTest> =>
		this.Request.send("GET", "/api/public-tests/get-by-id/:id", args).then(
			(data: IRGETPublicTest) => data
		);

	getManyForTeacher = async (
		args: IAGETPublicTestsForTeacher
	): Promise<IRGETPublicTestsForTeacher> =>
		this.Request.send("GET", "/api/public-tests/for-teacher", args, null, {
			requestSchema: AGETPublicTestsSchema,
		});

	getCountByOriginalCourseId = async (
		args: IAGETPublicTestCountByOriginalCourseId
	): Promise<IRGETPublicTestCountByOriginalCourseId> =>
		this.Request.send(
			"GET",
			"/api/public-tests/count-by-original-course-id",
			args
		);

	updateStatus = async (
		args: IAPUTPublicTestStatus
	): Promise<IRPUTPublicTestStatus> =>
		this.Request.send("PUT", "/api/public-tests/status", args);

	update = async (args: IAPUTPublicTest): Promise<IRPUTPublicTest> =>
		this.Request.send("PUT", "/api/public-tests", args);

	importCourseTests = async (args: IAPOSTImportCourseTests): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/public-tests/import-course-tests",
			args
		);

	getChangesByAuthor = async (): Promise<IRGETPublicTestChangesByAuthor> =>
		this.Request.send("GET", "/api/public-tests/changes").then(
			(data: IRGETPublicTestChangesByAuthor) => data
		);

	markChangeSeen = async (args: IAPUTPublicTestChangeSeen) =>
		this.Request.send(
			"PUT",
			"/api/public-tests/changes/:changeId/seen",
			args
		);

	markChangeSeenForMany = async (args: IAPUTPublicTestChangeSeenForMany) =>
		this.Request.send("PUT", "/api/public-tests/changes/seen", args);

	updateType = async (args: APUTPublicTestType) =>
		this.Request.send("PUT", "/api/public-tests/type", args);

	updateCognitiveMeta = async (args: IAPUTCognitiveAssignmentMetaData) =>
		this.Request.send("PUT", "/api/public-tests/cognitive-meta/", args);
}
