import { useMemo } from "react";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useStudentInfo, useClassroomsUserId } from "./users";
import { useStoreProperty } from "./store";
import {
	useModelDocById,
	useModelDocsByQuery,
	useModelDocsByIds,
} from "m-model-react";
import { IResourceLoadingInfo, useResourceInfoWithLoading } from "./fetch";
import { Classroom } from "@app/models/classroom";
import { CourseType } from "@app/api/courses-info/helper-schemas";

const fetchClassroom = (classroomId: ObjectId) => {
	const ClassroomsController = inject("ClassroomsController");
	return ClassroomsController.getById({ _id: classroomId });
};

export function useClassroom(
	classroomId: ObjectId | null
): IResourceLoadingInfo<Classroom> {
	const classroom = useModelDocById(inject("ClassroomModel"), classroomId);

	return useResourceInfoWithLoading({
		resource: classroom,
		fetchingArg: classroomId!,
		fetch: fetchClassroom,
		isIdentificationKnown: !!classroomId,
	});
}

export const useClassroomByCourseId = (
	courseId: ObjectId | null
): IResourceLoadingInfo<Classroom> => {
	const userInfo = useStudentInfo();
	const classroomsObj = useStoreProperty("classrooms");
	const classroomId = useMemo((): ObjectId | null | undefined => {
		if (!userInfo) return undefined;
		const ClassroomModel = inject("ClassroomModel");
		const classrooms = ClassroomModel.findManyByIdsSync(
			userInfo.classroomIds
		);
		for (const classroom of classrooms) {
			if (classroom.course.courseId === courseId) {
				return classroom._id;
			}
		}
		if (classrooms.length !== userInfo.classroomIds.length) {
			// has not found all
			return null;
		}
		if (classroomsObj) {
		}
		return undefined;
	}, [courseId, userInfo, classroomsObj]);
	const classroom = useClassroom(
		classroomId === undefined ? null : classroomId || ""
	);
	if (classroomId === undefined) {
		return {
			doc: undefined,
			isSuccessfullyLoaded: false,
			hasFoundError: false,
			isIdentificationKnown: false,
		};
	}
	return classroom;
};

export const useMyClassroomsAsTeacher = (onlyOrdinaryClassrooms?: boolean) => {
	const userId = useClassroomsUserId();
	const query = useMemo(
		() =>
			userId
				? {
						teacherId: userId,
				  }
				: null,
		[userId]
	);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return onlyOrdinaryClassrooms
		? docs?.filter(e => e.course.type !== CourseType.cognitive)
		: docs;
};

export const useMyClassroomsAsStudent = (onlyOrdinaryClassrooms?: boolean) => {
	const userId = useClassroomsUserId();
	const query = useMemo(() => (userId ? {} : null), [userId]);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return onlyOrdinaryClassrooms
		? docs?.filter(e => e.course.type !== CourseType.cognitive)
		: docs;
};

export const useMyCognitiveClassroomsAsStudent = () => {
	const userId = useClassroomsUserId();
	const query = useMemo(() => (userId ? {} : null), [userId]);
	const docs = useModelDocsByQuery(inject("ClassroomModel"), query);
	return docs?.filter(e => e.course.type === CourseType.cognitive);
};

const fetchClassroomsByIds = (classroomIds: ObjectId[]) => {
	return inject("ClassroomsController").getManyByIds({ ids: classroomIds });
};

export function useClassroomsByIds(classroomIds: ObjectId[] | null) {
	const classrooms = useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds
	);

	const classroomsFetchInfo = useResourceInfoWithLoading({
		resource: classrooms,
		fetchingArg: classroomIds!,
		fetch: fetchClassroomsByIds,
		isIdentificationKnown: !!classroomIds,
		forcefullyFetch: true,
	});
	return classroomsFetchInfo;
}

const fetchClassroomsByIdsWithoutFiltering = (classroomIds: ObjectId[]) => {
	return inject("ClassroomsController").getManyByIdsWithoutFiltering({
		ids: classroomIds,
	});
};

/**
 * endpoint which is used by useClassroomsByIds hook filters classrooms if user making request can not access it
 * this hook returns all classrooms without filtering
 */
export function useClassroomsByIdsWithourFiltering(
	classroomIds: ObjectId[] | null
) {
	const classrooms = useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds
	);

	const classroomsFetchInfo = useResourceInfoWithLoading({
		resource: classrooms,
		fetchingArg: classroomIds!,
		fetch: fetchClassroomsByIdsWithoutFiltering,
		isIdentificationKnown: !!classroomIds,
		forcefullyFetch: true,
	});
	return classroomsFetchInfo;
}
