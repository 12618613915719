import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import React from "react";
import { Link } from "react-router-dom";
import BrandLogoSvg from "./general_imgs/TVSchoolLogoBlue.svg";
import { ReactComponent as HubuLogo } from "../../landing/styles/img/hubu-logo.svg";
import genStyles from "./styles/general_styles.module.css";

const BrandLogo = () => {
	return (
		<Link to="/" className={genStyles.brandLogo}>
			{getCurrentWebsite() === WebsiteOrigin.hubu && (
				<HubuLogo width={150} height={100} fill={"#5273e6"} />
			)}
			{getCurrentWebsite() === WebsiteOrigin.tvSchool && (
				<img src={BrandLogoSvg} alt="TV school Logo" />
			)}
		</Link>
	);
};

export default BrandLogo;
