import * as React from "react";
import FillingBlanks from "./filling-blanks/class";
import GroupingItems from "./grouping-items/class";
import memoizeOne from "memoize-one";
import MultipleChoice from "./multiple-choice/class";
import MultipleContents from "./multiple-contents/class";
import QuestionContentEditMode, {
	IQContentEditPassableProps,
	ICommonTexts,
} from "./edit";
import Select from "react-select";
import SortItems from "./sort-items/class";
import { ContentType } from "../../../schemas/questions/contnets/common-schemas";
import { IFullQuestion } from "../../../schemas/questions/helper-schemas";
import { IRawQuestionContent } from "../../../schemas/questions/contnets/schemas";
import { Language } from "../../../schemas/questions/const";
import { MCContentDesignStructure } from "../../../schemas/questions/contnets/multiple-choice/schema";
import { ContentError } from "./interfaces";
import { FBContentDesignStructure } from "../../../schemas/questions/contnets/filling-blanks/schema";
import { AnyComponent } from "../../../utils/generics";

export type GetChangedContentFN = (args: {
	old: { content?: IRawQuestionContent; designStructure?: string };
	new: { contentType: ContentType; designStructure?: string };
}) => IRawQuestionContent | undefined;

interface IProps {
	defaultQuestion?: Pick<IFullQuestion, "content">;
	onSave?: (errors: ContentError[] | null, data: IFullQuestion) => void;
	customized?: IQContentEditPassableProps;
	defaultContentType?: ContentType | null;
	hideContentTypeSelector?: boolean;
	ContentTypeSelector?: AnyComponent<IChooseQuestionContentTypeProps>;
	getChangedContent?: GetChangedContentFN;
	MathEquationsInstruction?: AnyComponent<IMathEquationsInstructionProps>;
	onCheckFullInstruction?: () => void;
}

interface IState {
	contentType?: ContentType;
	designStructure?: string;
	content?: IRawQuestionContent;
}

class QuestionEditwrapper extends React.PureComponent<IProps, IState> {
	state: IState = {
		contentType: this.props.defaultQuestion
			? this.props.defaultQuestion.content.type
			: this.props.defaultContentType || undefined,
		content: this.props.defaultQuestion
			? this.props.defaultQuestion.content
			: undefined,
	};

	ref: React.RefObject<QuestionContentEditMode> = React.createRef();

	getContent = memoizeOne(
		(
			getContent: IProps["getChangedContent"],
			contentType?: ContentType,
			designStructure?: string
		) => {
			if (contentType === undefined) return undefined;
			return (getContent || getChangedContent)({
				old: {},
				new: { contentType, designStructure },
			});
		}
	);

	getData = () => {
		if (!this.ref.current) return;
		const component = this.ref.current;
		const content = component.getData() as IRawQuestionContent;
		return {
			...this.props.defaultQuestion,
			content,
			reviewed: 0,
			visible: true,
			lang: Language.Georgian,
		} as IFullQuestion;
	};

	getErrors = (): ContentError[] | null => {
		if (!this.ref.current) return null;
		return this.ref.current.getErrors();
	};

	onSave = () => {
		if (!this.props.onSave) return;
		const data = this.getData();
		if (!data) return;
		this.props.onSave(this.getErrors(), data);
	};

	onContentChange = (
		contentType: ContentType,
		designStructure: string | undefined
	) => {
		this.setState({
			contentType,
			designStructure,
			content: (this.props.getChangedContent || getChangedContent)({
				old: {
					content: this.state.content,
					designStructure: this.state.designStructure,
				},
				new: { contentType, designStructure },
			}),
		});
	};

	render() {
		const currentContent = this.state.content;
		const commonStyles =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonStyles;
		const commonTexts =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonTexts;
		const commonComponetns =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonComponents;
		if (
			this.props.defaultQuestion &&
			currentContent &&
			(currentContent as any).designStructure
		) {
			if (
				this.state.designStructure !==
				(currentContent as any).designStructure
			) {
				setTimeout(() => {
					this.setState({
						designStructure: (currentContent as any)
							.designStructure,
					});
				}, 0);
			}
		}
		const ContentTypeSelector =
			this.props.ContentTypeSelector || ChooseQuestionContentType;
		const MathEquationsInstruction = this.props.MathEquationsInstruction;
		return (
			<div style={{ textAlign: "left" }}>
				{!this.props.hideContentTypeSelector && (
					<ContentTypeSelector
						onChange={this.onContentChange}
						selectedType={this.state.contentType}
						selectedDesignStructure={this.state.designStructure}
						contentTypeTexts={
							this.props.customized?.commonProps?.commonTexts
								?.contentTypes
						}
					/>
				)}
				{MathEquationsInstruction &&
					this.props.onCheckFullInstruction && (
						<MathEquationsInstruction />
					)}
				{this.state.contentType !== undefined && (
					<>
						<QuestionContentEditMode
							key={
								this.state.contentType +
								"-" +
								(this.state.designStructure || "")
							}
							content={
								currentContent ||
								this.getContent(
									this.props.getChangedContent,
									this.state.contentType,
									this.state.designStructure
								)
							}
							contentType={this.state.contentType}
							ref={this.ref}
							customized={this.props.customized}
						/>
						{commonComponetns &&
							commonComponetns.beforeSaveButton && (
								<commonComponetns.beforeSaveButton />
							)}
						{!!this.props.onSave && (
							<button
								onClick={this.onSave}
								className={
									commonStyles && commonStyles.saveButton
								}
							>
								{(commonTexts && commonTexts.saveButton) ||
									"Save"}
							</button>
						)}
					</>
				)}
			</div>
		);
	}
}

export interface IChooseQuestionContentTypeProps {
	selectedType?: ContentType;
	selectedDesignStructure?: string;
	contentTypeTexts?: ICommonTexts["contentTypes"];
	onChange: (
		contentType: ContentType,
		designStructure: string | undefined
	) => void;
}

export interface IMathEquationsInstructionProps {}

type CTypeOption = [ContentType, string | undefined];

class ChooseQuestionContentType extends React.PureComponent<
	IChooseQuestionContentTypeProps
> {
	getOptions = memoizeOne(
		(
			contentTypeTexts: NonNullable<ICommonTexts["contentTypes"]>
		): {
			value: CTypeOption;
			label: string;
		}[] => [
			{
				value: [ContentType.MultipleChoice, undefined],
				label:
					contentTypeTexts.MultipleChoice?.main || "Multiple Choice",
			},
			{
				value: [ContentType.SortItems, undefined],
				label: contentTypeTexts.SortItems?.main || "Sorting Items",
			},
			{
				value: [ContentType.FillingBlanks, undefined],
				label: contentTypeTexts.FillingBlanks?.main || "Filling Blanks",
			},
			{
				value: [ContentType.MultipleContents, undefined],
				label:
					contentTypeTexts.MultipleContents?.main ||
					"Multiple Contents",
			},
			{
				value: [
					ContentType.FillingBlanks,
					FBContentDesignStructure.essay,
				],
				label: contentTypeTexts.FillingBlanks?.essay || "Essay",
			},
			{
				value: [
					ContentType.FillingBlanks,
					FBContentDesignStructure.essayWithFiles,
				],
				label:
					contentTypeTexts.FillingBlanks?.essayWithFiles ||
					"Essay With Files",
			},
		]
	);

	onChange = (select: { value: CTypeOption; label: string }) => {
		this.props.onChange(select.value[0], select.value[1]);
	};

	emptyObj = {};

	render() {
		const { contentTypeTexts: contentTypes } = this.props;
		const options = this.getOptions(contentTypes || this.emptyObj);
		const selectedOption =
			this.props.selectedType === undefined
				? undefined
				: options.find(
						e =>
							e.value[0] === this.props.selectedType &&
							e.value[1] === this.props.selectedDesignStructure
				  );
		return (
			<div style={{ marginBottom: 30 }}>
				<Select
					value={selectedOption}
					onChange={this.onChange}
					options={options}
					placeholder={
						(contentTypes && contentTypes.selectPlaceholder) ||
						"Choose Question Type"
					}
					styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
				/>
			</div>
		);
	}
}

export const getEmptyContent = (
	contentType: ContentType,
	designStructure: string | null
) => {
	switch (contentType) {
		case ContentType.MultipleChoice:
			return MultipleChoice.getEmptyContent(
				designStructure as MCContentDesignStructure
			);
		case ContentType.FillingBlanks:
			return FillingBlanks.getEmptyContent(
				designStructure as FBContentDesignStructure
			);
		case ContentType.SortItems:
			return SortItems.getEmptyContent();
		case ContentType.GroupingItems:
			return GroupingItems.getEmptyContent();
		case ContentType.MultipleContents:
			return MultipleContents.getEmptyContent();
		default:
			throw new Error(`content type ${contentType} is not supported`);
	}
};

const getChangedContent = ({
	old,
	new: newC,
}: {
	old: { content?: IRawQuestionContent; designStructure?: string };
	new: { contentType: ContentType; designStructure?: string };
}): IRawQuestionContent | undefined => {
	if (!old.content) {
		return getEmptyContent(newC.contentType, newC.designStructure || null);
	}
	if (old.content.type === ContentType.MultipleContents) {
		const innerItem = old.content.items.find(
			e => e.content.type === newC.contentType
		);
		if (innerItem) {
			return innerItem.content as IRawQuestionContent;
		}
	}
	return getEmptyContent(newC.contentType, newC.designStructure || null);
};

export default QuestionEditwrapper;
