import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import React, { useEffect } from "react";

export const MessengerChat: React.FC<{}> = props => {
	const isGeo = useLocale() === "ka";
	const isTvSchool = getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const user = useClassroomsUser();
	const myPathName = window.location.pathname.slice(0, 6);
	const isTermsAndConditionsHeaderlessPage =
		window.location.pathname.indexOf("/terms-and-conditions") === 0;

	const isStudent = user?.isStudent() || myPathName === "/s/hw/";

	useEffect(() => {
		setTimeout(() => {
			if ((window as any)?.FB?.CustomerChat) {
				(window as any)?.FB?.CustomerChat.show({
					shouldShowDialog: true,
				});
			}
			(window as any)?.FB?.Event?.subscribe?.(
				"customerchat.load",
				function(response) {
					console.log("load custumer chat", response);
				}
			);
			(window as any)?.FB?.Event?.subscribe?.(
				"customerchat.show",
				function(response) {
					//
				}
			);
		}, 2000);
	}, []);

	if (
		!isGeo ||
		!isTvSchool ||
		isStudent ||
		isTermsAndConditionsHeaderlessPage
	) {
		return null;
	}

	return (
		<div>
			<div
				className="fb-customerchat"
				data-attribution={"install_email"}
				data-page_id="102966228071683"
				data-theme_color="#5273E6"
				data-greeting_dialog_display="hide"
				data-logged_in_greeting="გამარჯობა! შეგიძლიათ დაგვიტოვოთ თქვენი შეკითხვა."
				data-logged_out_greeting="გამარჯობა! შეგიძლიათ დაგვიტოვოთ თქვენი შეკითხვა."
			></div>
		</div>
	);
};
