import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { useClassroom } from "@app/hooks/classrooms";
import { useHistory } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useUserShortInfo } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useCallback, useMemo } from "react";
import Popup, { PopupContentWithClose } from "../../widgets/popup";
import { Settings } from "../../widgets/settings";
import { CreateClassroomPage } from "../classrooms/create";
import { EditClassroomTime } from "../classrooms/create/schedule";
import { InvitationsOfStudentsByTeacher } from "../invitations/outgoing";
import { ClassroomBoxLoader } from "./classroom-loader";
import styles from "./styles/classrooms.module.css";
import { ReactComponent as Clock } from "./styles/img/clock.svg";
import { ReactComponent as User } from "./styles/img/user.svg";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { useLocale } from "@app/hooks/intl";
import { FormattedMessage } from "react-intl";
import { useDays } from "@app/hooks/dates";

interface IClassroomBox {
	classroomId: ObjectId;
}

enum ClassroomSettingsOption {
	editClassroom,
	editSchedule,
	deleteClassroom,
	inviteStudents,
	archive,
}

interface IClassTime {
	start: {
		hour: number;
		minute: number;
	};
	end: {
		hour: number;
		minute: number;
	};
}
interface IWeekDays {
	indexOfTheDay: number;
	isSelected: boolean;
	classTimes: IClassTime[];
}

export const ClassroomBox: React.FC<IClassroomBox> = React.memo(props => {
	const locale = useLocale();
	const classroom = useClassroom(props.classroomId);
	const isArchived = classroom.doc ? classroom.doc.isArchived : false;
	const {
		value: isEditClassroomVisible,
		setTrue: setClassroomVisible,
		setFalse: setClassroomInvisible,
	} = useBoolean();

	const {
		value: isInviteStudentsVisible,
		setTrue: setInviteStudentsVisible,
		setFalse: setInviteStudentsInvisible,
	} = useBoolean();

	const {
		value: isSchedulePopupOpen,
		setTrue: openSchedulePopup,
		setFalse: closeSchedulePopup,
	} = useBoolean();

	const onSelect = useCallback(
		({ value }: { value: ClassroomSettingsOption }) => {
			if (value === ClassroomSettingsOption.editClassroom) {
				setClassroomVisible();
			} else if (value === ClassroomSettingsOption.deleteClassroom) {
				openConfirmationPopup({
					text: getFormattedMessage("teacher:classroomDeleteAlert"),
					rejectTitle: getFormattedMessage("no"),
					approveTitle: getFormattedMessage("yes"),
					displayRejectButtonAsPrimary: true,
					onApprove: () => {
						const ClassroomsController = inject(
							"ClassroomsController"
						);
						return ClassroomsController.delete(props.classroomId);
					},
				});
			} else if (value === ClassroomSettingsOption.editSchedule) {
				openSchedulePopup();
			} else if (value === ClassroomSettingsOption.inviteStudents) {
				setInviteStudentsVisible();
			} else if (value === ClassroomSettingsOption.archive) {
				openConfirmationPopup({
					text: `ნამდვილად გსურთ ჯგუფის ${
						isArchived ? "ამოარქივება" : "დაარქივება"
					}?`,
					rejectTitle: getFormattedMessage("no"),
					approveTitle: getFormattedMessage("yes"),
					displayRejectButtonAsPrimary: true,
					onApprove: () => {
						inject("ClassroomsController").update({
							_id: props.classroomId,
							isArchived: !isArchived,
						});
					},
				});
			}
		},
		[
			isArchived,
			openSchedulePopup,
			props.classroomId,
			setClassroomVisible,
			setInviteStudentsVisible,
		]
	);
	const settingsOptions = useMemo(() => {
		return [
			{
				value: ClassroomSettingsOption.inviteStudents,
				label: getFormattedMessage("teacher:inviteStudents"),
				icon: <AddIcon />,
			},
			{
				value: ClassroomSettingsOption.editClassroom,
				label: getFormattedMessage("teacher:editClassroom"),
				icon: <EditIcon />,
			},
			{
				value: ClassroomSettingsOption.editSchedule,
				label: getFormattedMessage("teacher:editSchedule"),
				icon: <EditIcon />,
			},
			{
				value: ClassroomSettingsOption.archive,
				label: isArchived ? "ამოარქვება" : "დაარქივება",
				icon: <EditIcon />,
			},

			{
				value: ClassroomSettingsOption.deleteClassroom,
				label: getFormattedMessage("teacher:deleteClassroom"),
				icon: <DeleteIcon />,
			},
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isArchived, locale]);

	if (!classroom.isSuccessfullyLoaded) {
		return <ClassroomBoxLoader />;
	}
	const isPopulatedClassroom = classroom.doc.studentIds.length !== 0;
	return (
		<>
			<div className={styles.classroomBox}>
				<div style={{ display: "flex" }}>
					<div className={styles.classroomName}>
						{classroom.doc.name}
					</div>
					<Settings
						icon={<SettingsIcon className={styles.settingsIcon} />}
						options={settingsOptions}
						onSelect={onSelect}
						optionsIconPosition="right"
					/>
				</div>
				{isPopulatedClassroom ? (
					<div className={styles.lists}>
						<ClassroomTimes classTimes={classroom.doc.classTimes} />
						<ClassroomStudents
							studentIds={classroom.doc.studentIds}
						/>
					</div>
				) : (
					<div>
						<AddStudentsToClassroomButton
							onClick={setInviteStudentsVisible}
							text={getFormattedMessage("teacher:addStudents")}
						/>
					</div>
				)}
				{classroom.doc.classTimes.length === 0 && (
					<AddStudentsToClassroomButton
						onClick={openSchedulePopup}
						text={getFormattedMessage("teacher:addSchedule")}
					/>
				)}
				<EnterClassroomButton classroomId={props.classroomId} />
			</div>
			{isEditClassroomVisible && (
				<Popup onClose={setClassroomInvisible}>
					<CreateClassroomPage
						classroomId={props.classroomId}
						onClassroomAdd={setClassroomInvisible}
					/>
				</Popup>
			)}
			{isInviteStudentsVisible && (
				<InvitationsOfStudentsByTeacher
					onClose={setInviteStudentsInvisible}
					classroomId={props.classroomId}
				/>
			)}
			{isSchedulePopupOpen && (
				<Popup onClose={closeSchedulePopup}>
					<PopupContentWithClose
						onClose={closeSchedulePopup}
						className={styles.SchedulePopupContentStyles}
					>
						<EditClassroomTime
							classroomId={props.classroomId}
							onSave={closeSchedulePopup}
						/>
					</PopupContentWithClose>
				</Popup>
			)}
		</>
	);
});

const AddStudentsToClassroomButton: React.FC<{
	onClick: () => void;
	text: string;
}> = React.memo(props => {
	return (
		<div className={styles.addStudentsButtonContainer}>
			<button
				className={styles.addStudentsButton}
				onClick={props.onClick}
			>
				{props.text} <AddIcon style={{ marginLeft: 10 }} />
			</button>
		</div>
	);
});

const EnterClassroomButton: React.FC<{ classroomId: ObjectId }> = React.memo(
	props => {
		const history = useHistory();

		const onClassroomEnter = useCallback(() => {
			history.push(`/classrooms/${props.classroomId}`);
		}, [history, props.classroomId]);

		return (
			<div className={styles.classroomButtonContainer}>
				<button
					className={styles.classroomButton}
					onClick={onClassroomEnter}
				>
					<FormattedMessage id="teacher:enterClassroom" />
				</button>
			</div>
		);
	}
);

interface IClassroomTimesProps {
	classTimes: IClassroom["classTimes"];
}

const ClassroomTimes: React.FC<IClassroomTimesProps> = props => {
	const days = useDays();
	let counter = 0;
	return (
		<div className={styles.classroomTimes}>
			<div className={styles.listTextandIcon}>
				<Clock style={{ width: 20, height: 20 }} />
				<p className={styles.listText}>
					<FormattedMessage id="teacher:schedule" />
				</p>
			</div>
			{props.classTimes.map((e, i) => {
				while (counter < 2) {
					counter++;
					return (
						<div className={styles.classroomTimeListLine} key={i}>
							<p className={styles.classTimeDay}>{days[e.day]}</p>
							<div className={styles.classTimeTime}>
								{e.start.hour}:
								{e.start.minute < 10 && (
									<div style={{ display: "inline" }}>
										0{e.start.minute}
									</div>
								)}
								{e.start.minute >= 10 && (
									<div style={{ display: "inline" }}>
										{e.start.minute}
									</div>
								)}
							</div>
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

interface IClassroomUsers {
	studentIds: IClassroom["studentIds"];
}

const ClassroomStudents: React.FC<IClassroomUsers> = props => {
	const visibleUsers = props.studentIds.slice(0, 2);
	const user = useUserShortInfo(visibleUsers[visibleUsers.length - 1]);
	const nameLength = user.doc && user.doc.getFullName().length;

	return (
		<div className={styles.ClassroomStudents}>
			<div className={styles.listTextandIcon}>
				<User style={{ width: 20, height: 20 }} />
				<p className={styles.listText}>
					<FormattedMessage id="teacher:students" />
				</p>
			</div>
			{visibleUsers.map((studentId, index) => {
				return (
					<div
						key={studentId}
						className={styles.ClassroomStudentsInline}
					>
						<ClassroomStudent studentId={studentId} />
						{index === 1 && nameLength! < 12 && (
							<div className={styles.points}>
								<div className={styles.point} />
								<div className={styles.point} />
								<div className={styles.point} />
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export const ClassroomStudent: React.FC<{ studentId: number }> = props => {
	const student = useUserShortInfo(props.studentId);
	if (!student.isSuccessfullyLoaded) return null;
	return (
		<div className={styles.classroomUserLine}>
			{student.doc.getFullName()}
		</div>
	);
};
