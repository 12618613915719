import { IRequest } from "../requests";
import {
	IRGETCities,
	RGETCitiesSchema,
	IAGETCities,
	IADELETECity,
	IAPOSTCity,
	IAPUTCity,
	IRPOSTCity,
	IRPUTCity,
	RPOSTCitySchema,
	RPUTCitySchema,
} from "./validators";

export class CitiesController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	getAll = async (args: IAGETCities): Promise<IRGETCities> => {
		return this.Request.send("GET", "/api/cities", args, null, {
			responseSchema: RGETCitiesSchema,
		});
	};

	async create(args: IAPOSTCity): Promise<IRPOSTCity> {
		return this.Request.send("POST", "/api/cities", args, null, {
			responseSchema: RPOSTCitySchema,
		});
	}

	async update(args: IAPUTCity): Promise<IRPUTCity> {
		return this.Request.send("PUT", "/api/cities/:id", args, null, {
			responseSchema: RPUTCitySchema,
		});
	}

	async delete(args: IADELETECity): Promise<void> {
		return this.Request.send("DELETE", "/api/cities/:id", args);
	}
}
