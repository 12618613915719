import { useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import triggerEvent from "@app/utils/events";
import UserIcon from "@material-ui/icons/Person";
import {
	animateWindowScroll,
	getHTMLElementCoords,
} from "@tests-core/utils/dom";
import classnames from "classnames";
import { default as React, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { DivReference } from ".";
import { ProfOrientationInfoPopup } from "../prof-orientation/landing";
import { ReactComponent as MedalIcon } from "./styles/img/medal.svg";
import { ReactComponent as TeacherIcon } from "./styles/img/professor.svg";
import styles from "./styles/landing-main-page.module.css";

interface ILandingButtonProps {
	onClick?: () => void;
}

export const LandingButton: React.FC<ILandingButtonProps> = props => {
	return (
		<div onClick={props.onClick}>
			<button className={styles.registerButton}>{props.children}</button>
		</div>
	);
};

export const WhichAreYou: React.FC<{
	refs: {
		studentRef: DivReference;
		teacherRef: DivReference;
		// schoolRef: DivReference;
	};
}> = props => {
	const { value: isClicked, setTrue: setIsClickedVisible } = useBoolean(
		false
	);
	const local = useLocale();
	const scrollToReference = useCallback(
		(ref: DivReference) => {
			if (!ref.current) return;
			const coordinates = getHTMLElementCoords(ref.current);
			setIsClickedVisible();
			animateWindowScroll(coordinates.top - 100, 300);
		},
		[setIsClickedVisible]
	);

	const { width } = useWindowSize();

	const onSelectingUserType = () => {
		scrollToReference(props.refs.studentRef);
		triggerEvent(
			{
				category: "Button",
				action: "choosing `I am`",
				label: "student",
			},
			{}
		);
	};

	return (
		<div className={styles.whichAreYouContainer}>
			<div className={local === "ka" ? styles.iAm : styles.iAmEn}>
				<FormattedMessage id="landing:chooseWhoYouAre" />
			</div>
			<div
				className={
					width > 600
						? styles.whichAreYouButtonsDiv
						: classnames(
								styles.whichAreYouButtonsDiv,
								styles.whichAreYouButtonsDivInLandingResponsive
						  )
				}
			>
				{local === "ka" && (
					<>
						<button
							className={
								!isClicked
									? classnames(
											styles.whichAreYouYellowButton,
											styles.whichAreYouButton
									  )
									: styles.whichAreYouButton
							}
							onClick={onSelectingUserType}
						>
							<FormattedMessage id="student" />
							<UserIcon style={{ width: 22 }} />
						</button>
						<button
							className={styles.whichAreYouButton}
							onClick={() =>
								scrollToReference(props.refs.teacherRef)
							}
						>
							<FormattedMessage id="teacher" />
							<TeacherIcon className={styles.teacherIcon} />
						</button>
						{/* <button
							className={styles.whichAreYouButton}
							onClick={() =>
								scrollToReference(props.refs.schoolRef)
							}
							style={{ padding: "0 20px" }}
						>
							<FormattedMessage id="school" />
							<SchoolIcon style={{ width: 22 }} />
						</button> */}
					</>
				)}
				{local === "en" && (
					<>
						<button
							className={
								!isClicked
									? classnames(
											styles.whichAreYouYellowButton,
											styles.whichAreYouButton
									  )
									: styles.whichAreYouButton
							}
							onClick={() =>
								scrollToReference(props.refs.teacherRef)
							}
						>
							<FormattedMessage id="teacher" />
							<TeacherIcon className={styles.teacherIcon} />
						</button>
						<button
							className={styles.whichAreYouButton}
							onClick={onSelectingUserType}
						>
							<FormattedMessage id="student" />
							<UserIcon style={{ width: 22 }} />
						</button>
					</>
				)}
			</div>
		</div>
	);
};

export const ProfOrientationButton: React.FC<{}> = props => {
	const locale = useLocale();
	const history = useHistory();
	const user = useClassroomsUser();

	const {
		value: isPopupOn,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean(false);

	if (locale !== "ka") return null;

	return (
		<>
			<div
				className={styles.orientationButtonContainer}
				onClick={() => {
					if (user) {
						history.push(`/prof-test`);
					} else {
						openPopup();
					}
				}}
			>
				<span className={styles.orientationTextContainer}>
					პროფ-ორიენტაციის ტესტი
				</span>

				<MedalIcon
					style={{
						marginLeft: 20,
						verticalAlign: "middle",
						display: "inline-block",
					}}
					width={20}
					height={20}
				/>
			</div>
			{isPopupOn && <ProfOrientationInfoPopup onClose={closePopup} />}
		</>
	);
};

export const ProfOrientationButtonSmall = () => {
	const locale = useLocale();
	const history = useHistory();
	const user = useClassroomsUser();

	const {
		value: isPopupOn,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean(false);

	if (locale !== "ka") return null;

	return (
		<>
			<div
				style={{
					border: "1px solid #5273e6",
					padding: "5px 15px",
					borderRadius: 7,
					background: "white",
					cursor: "pointer",
					width: "fit-content",
					margin: "0 auto",
					fontFamily: "Roboto Geo Nus",
				}}
				onClick={() => {
					if (user) {
						history.push(`/prof-test`);
					} else {
						openPopup();
					}
				}}
			>
				<span
					style={{ verticalAlign: "middle", display: "inline-block" }}
				>
					პროფ-ორიენტაციის ტესტი
				</span>
				<MedalIcon
					style={{
						marginLeft: 20,
						verticalAlign: "middle",
						display: "inline-block",
					}}
					width={20}
					height={20}
				/>
			</div>
			{isPopupOn && <ProfOrientationInfoPopup onClose={closePopup} />}
		</>
	);
};
