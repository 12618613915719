import { ObjectId } from "@app/utils/generics";
import { useState, useEffect, useMemo } from "react";
import { IHierarchyInfo } from "@app/services/hierarchy-info/interfaces";
import { useMountingInfo } from "./general";
import { inject } from "@app/modules";
import { Topic } from "@app/models/topic";
import { IResourceLoadingInfo, getResourceLoadingInfo } from "./fetch";
import { useModelDocById } from "m-model-react";

export const useTopicsAndHierarchyInfo = (courseId: ObjectId | null) => {
	const [topics, setTopics] = useState<Topic[]>();
	const [topicsHierarchyInfo, setTopicsHierarchyInfo] = useState<
		IHierarchyInfo
	>();
	const mountingInfo = useMountingInfo();

	useEffect(() => {
		if (mountingInfo.hasFinishedFirstMountingCycle) {
			setTopics(undefined);
			setTopicsHierarchyInfo(undefined);
		}
		if (!courseId) {
			return;
		}
		const TopicsController = inject("TopicsController");
		TopicsController.getAllByCourseId({ courseId }).then(data => {
			setTopics(data);
		});
		TopicsController.getHierarchy({ courseId }).then(data => {
			const TopicHierarchyService = inject("TopicHierarchyService");
			const hierarchyInfo = TopicHierarchyService.getHierarchyInfoObjectSync(
				courseId
			);
			setTopicsHierarchyInfo(hierarchyInfo);
		});
	}, [courseId, mountingInfo.hasFinishedFirstMountingCycle]);
	return { topics, topicsHierarchyInfo };
};

interface UseTopicInfoArgs {
	topicId: ObjectId | null;
	courseId: ObjectId | null;
}

export function useTopic({
	topicId,
	courseId,
}: UseTopicInfoArgs): IResourceLoadingInfo<Topic, undefined> {
	const topic = useModelDocById(inject("TopicModel"), topicId);
	useTopicsAndHierarchyInfo(courseId);
	return useMemo(() => {
		return getResourceLoadingInfo<Topic, undefined>({
			resource: topic,
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: !!topicId && !!courseId,
		});
	}, [courseId, topic, topicId]);
}
