import { IAGETSubjects } from "@app/api/subjects/validators";
import { inject } from "@app/modules";
import { useMemo, useState, useEffect } from "react";
import { useResourceInfoWithLoading } from "./fetch";
import { useModelDocById, useModelDocsByIds } from "m-model-react";
import { ObjectId } from "@app/utils/generics";

export const useSubjectOptions = (query: IAGETSubjects | null) => {
	const subjects = useSubjects(query);
	return useMemo(() => {
		if (!subjects.doc) return null;
		return subjects.doc.map(subject => ({
			label: subject.name,
			value: subject._id,
		}));
	}, [subjects.doc]);
};

const fetchAllSubjects = (query: IAGETSubjects) => {
	return inject("SubjectsController").getAll(query);
};

export const useSubjects = (query: IAGETSubjects | null) => {
	const SubjectModel = inject("SubjectModel");

	const [subjects, setSubjects] = useState(() =>
		!query ? null : SubjectModel.searchSync(query)
	);

	useEffect(() => {
		if (!query) {
			setSubjects(null);
			return;
		}
		setSubjects(SubjectModel.searchSync(query));
		return SubjectModel.subscribeChange(() => {
			setSubjects(SubjectModel.searchSync(query));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(query), SubjectModel]);

	const subjectsFetchInfo = useResourceInfoWithLoading({
		resource: subjects,
		fetchingArg: query,
		fetch: fetchAllSubjects,
		isIdentificationKnown: !!query,
		forcefullyFetch: true,
	});
	return subjectsFetchInfo;
};

export const fetchSubject = (subjectId: ObjectId) => {
	return inject("SubjectsController").getById({ _id: subjectId });
};

export function useSubject(subjectId: ObjectId | null) {
	const subject = useModelDocById(inject("SubjectModel"), subjectId);

	const subjectFetchInfo = useResourceInfoWithLoading({
		resource: subject,
		fetchingArg: subjectId!,
		fetch: fetchSubject,
		isIdentificationKnown: !!subjectId,
	});
	return subjectFetchInfo;
}

const fetchSubjectsByIds = (subjectIds: ObjectId[]) => {
	return inject("SubjectsController").getManyByIds(subjectIds);
};

export function useSubjectsByIds(subjectIds: ObjectId[] | null) {
	const subjects = useModelDocsByIds(inject("SubjectModel"), subjectIds);

	const subjectsFetchInfo = useResourceInfoWithLoading({
		resource: subjects,
		fetchingArg: subjectIds!,
		fetch: fetchSubjectsByIds,
		isIdentificationKnown: !!subjectIds,
		forcefullyFetch: true,
	});
	return subjectsFetchInfo;
}
