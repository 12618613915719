import Joi from "@app/utils/joi";
import { UserType } from "../helper-schemas";
import { UserPacketSchema, IUserPacket } from "./packet-schemas";
import { ObjectId } from "@app/utils/generics";
import { WebsiteOrigin } from "@app/globals";

export const TeacherPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.teacher)
		.required(),
	accessibleClassrooms: Joi.array()
		.items(Joi.objectId())
		.required(),
	accessibleGroups: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface ITeacherPermissions {
	userType: UserType.teacher;
	accessibleClassrooms: ObjectId[];
	accessibleGroups: ObjectId[];
}

export const StudentPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.student)
		.required(),
	availableClassrooms: Joi.array()
		.items(Joi.objectId())
		.required(),
	availableCourses: Joi.array()
		.items(Joi.objectId())
		.required(),
	packet: UserPacketSchema.allow(null).required(),
});
export interface IStudentPermissions {
	userType: UserType.student;
	availableClassrooms: ObjectId[];
	availableCourses: ObjectId[];
	packet: IUserPacket | null;
}

export const ParentPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.parent)
		.required(),
	confirmedChildrenIds: Joi.array()
		.items(
			Joi.number()
				.integer()
				.required()
		)
		.optional(),
	unConfirmedChildrenIds: Joi.array()
		.items(
			Joi.number()
				.integer()
				.required()
		)
		.optional(),
});
export interface IParentPermissions {
	userType: UserType.parent;
	confirmedChildrenIds?: number[];
	unConfirmedChildrenIds?: number[];
}

export const AdminPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.mainAdmin)
		.required(),
});
export interface IAdminPermissions {
	userType: UserType.mainAdmin;
}

export const DeveloperPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.developer)
		.required(),
});
export interface IDeveloperPermissions {
	userType: UserType.developer;
}

export const HeadmasterPermissionsSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.headmaster)
		.required(),
});
export interface IHeadmasterPermissions {
	userType: UserType.headmaster;
}

export const UserPermissionsSchema = Joi.alternatives([
	TeacherPermissionsSchema,
	StudentPermissionsSchema,
	ParentPermissionsSchema,
	AdminPermissionsSchema,
	DeveloperPermissionsSchema,
	HeadmasterPermissionsSchema,
]);

export type IUserPermissions =
	| ITeacherPermissions
	| IStudentPermissions
	| IParentPermissions
	| IAdminPermissions
	| IDeveloperPermissions
	| IHeadmasterPermissions;

export const RUserSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	mobile: Joi.string()
		.required()
		.allow(null),
	isValidMobile: Joi.boolean().required(),
	isValidMail: Joi.boolean(),
	mail: Joi.string().allow(null),
	username: Joi.string().allow(null),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	grade: Joi.number()
		.allow(null)
		.required(),
	city: Joi.number()
		.allow(null)
		.required(),
	school: Joi.number()
		.allow(null)
		.required(),
	country: Joi.string()
		.allow(null)
		.required(),
	language: Joi.string()
		.allow(null)
		.required(),
	registrationOrigin: Joi.string()
		.allow(null)
		.required(),
	permissions: UserPermissionsSchema.required(),
	hasAgreedOnTerms: Joi.boolean().required(),
});
export interface IRUser {
	id: number;
	murtskuId: number | null;
	username?: string | null;
	mail?: string | null;
	mobile: string | null;
	isValidMobile: boolean;
	isValidMail?: boolean;
	firstname: string;
	lastname: string;
	grade: number | null;
	city: number | null;
	school: number | null;
	country: string | null;
	language: string | null;
	registrationOrigin: WebsiteOrigin | null;
	permissions: IUserPermissions;
	hasAgreedOnTerms: boolean;
}

export const UserEssentialsSchema = Joi.object().keys({
	userData: RUserSchema.required(),
	extraInfo: Joi.object(),
});
export interface IUserEssentials {
	userData: IRUser;
	extraInfo?: Record<any, any>;
}

///

export const CoursesMainAdminPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean()
		.valid(true)
		.required(),
	isMainAdmin: Joi.boolean()
		.valid(true)
		.required(),
});
export interface ICoursesMainAdminPermissions {
	isAdmin: true;
	isMainAdmin: true;
}

export const CourseAdminPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean()
		.valid(true)
		.required(),
	isMainAdmin: Joi.boolean().valid(false),
	courseIds: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface ICourseAdminPermissions {
	isAdmin: true;
	isMainAdmin?: false;
	courseIds: ObjectId[];
}

export const CoursesStudentPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean().valid(false),
	isMainAdmin: Joi.boolean().valid(false),
	availableCourses: Joi.array()
		.items(Joi.objectId())
		.required(),
}).allow(null);
export type ICoursesStudentPermissions = {
	isAdmin: false;
	isMainAdmin: false;
	availableCourses: ObjectId[];
} | null;

export const CoursesUserPermissionsSchema = Joi.alternatives([
	CoursesMainAdminPermissionsSchema,
	CourseAdminPermissionsSchema,
	CoursesStudentPermissionsSchema,
]);
export type ICoursesUserPermissions =
	| ICoursesMainAdminPermissions
	| ICourseAdminPermissions
	| ICoursesStudentPermissions;

export type ICoursesAdminPermissions =
	| ICoursesMainAdminPermissions
	| ICourseAdminPermissions;

export const RCoursesUserSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	mobile: Joi.string()
		.allow(null)
		.required(),
	mail: Joi.string()
		.allow(null)
		.allow(""),
	username: Joi.string(),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	permissions: CoursesUserPermissionsSchema.allow(null),
});
export interface IRCoursesUser {
	id: number;
	murtskuId: number | null;
	mobile: string | null;
	mail?: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	permissions?: ICoursesUserPermissions | null;
}

///

export const StudentInfoSchema = Joi.object({
	userId: Joi.number()
		.integer()
		.required(),
	classroomIds: Joi.array()
		.items(Joi.objectId())
		.required(),
	courseIds: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IStudentInfo {
	userId: number;
	classroomIds: ObjectId[];
	courseIds: ObjectId[];
}

export const UserShortInfoSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	username: Joi.string().allow(null),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	mobile: Joi.string().allow(null),
	grade: Joi.number()
		.allow(null)
		.required(),
});
export interface IUserShortInfo {
	id: number;
	username?: string | null;
	firstname?: string | null;
	lastname?: string | null;
	mobile: string | null;
	grade: number | null;
}

export const APUTChangeUserPasswordSchema = Joi.object({
	userId: Joi.number().required(),
	newPassword: Joi.string().required(),
});
export interface IAPUTChangeUserPassword {
	userId: number;
	newPassword: string;
}

// User
export interface IUser {
	id: number;
	type: UserType;
	murtsku_id: number;
	firstname: string;
	lastname: string;
	username: string | null;
	mobile: string | null;
	isValidMobile: boolean;
	isValidMail: boolean;
	mail: string | null;
	hashed_password: string;
	hash_algo: string;
	city: number | null;
	school: number | null;
	grade: number | null;
	country: string | null;
	language: string | null;
	registrationOrigin: string | null;
	hasAgreedOnTerms: boolean;
	isVerified: boolean;
	createdAt: Date;
	updatedAt: Date;
}

// User Full Info
export const userFullInfoAttributes = [
	"id",
	"type",
	"mobile",
	"isValidMobile",
	"isValidMail",
	"mail",
	"username",
	"firstname",
	"lastname",
	"grade",
	"city",
	"school",
	"country",
	"language",
	"registrationOrigin",
	"hasAgreedOnTerms",
] as const;
export type UserFullInfoKeys = typeof userFullInfoAttributes[number];

export const UserFullInfoSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	mobile: Joi.string()
		.required()
		.allow(null),
	isValidMobile: Joi.boolean().required(),
	isValidMail: Joi.boolean(),
	mail: Joi.string().allow(null),
	username: Joi.string().allow(null),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	grade: Joi.number()
		.allow(null)
		.required(),
	city: Joi.number()
		.allow(null)
		.required(),
	school: Joi.number()
		.allow(null)
		.required(),
	country: Joi.string()
		.allow(null)
		.required(),
	language: Joi.string()
		.allow(null)
		.required(),
	registrationOrigin: Joi.string()
		.allow(null)
		.required(),
	hasAgreedOnTerms: Joi.boolean().required(),
});
export type IUserFullInfo = Pick<IUser, UserFullInfoKeys>;

export const UserSpecialitySchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	code: Joi.string().required(),
	speciality: Joi.number()
		.integer()
		.required(),
	created_at: Joi.date().required(),
});

export interface IUserSpeciality {
	id: number;
	code: string;
	speciality: number;
	created_at: Date;
}

export const APOSTUserSpecialitySchema = Joi.object().keys({
	code: Joi.string().required(),
	speciality: Joi.number()
		.integer()
		.required(),
});

export interface IAPOSTUserSpeciality {
	code: string;
	speciality: number;
}
