import EditIcon from "@material-ui/icons/Create";
import classNames from "classnames";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React, { useCallback, useRef } from "react";
import styles from "../../styles/styles.module.css";

export interface IAssignmentNameProps {
	edit?: boolean;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AssignmentName: React.FC<IAssignmentNameProps> = React.memo(
	props => {
		const inputRef = useRef<HTMLInputElement>(null);

		const focusMethod = useCallback(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, []);

		return (
			<div
				className={
					props.edit
						? classNames(styles.inputContainer, styles.edit)
						: styles.inputContainer
				}
			>
				<input
					className={styles.inputStyle}
					value={props.value}
					onChange={props.onChange}
					ref={inputRef}
					placeholder={"ჩაწერეთ დავალების სათაური"}
				/>
				<EditIcon
					className={styles.createStyle}
					onClick={focusMethod}
				/>
			</div>
		);
	}
);
