export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_AUTHORIZATION_SUCCESS = "USER_AUTHORIZATION_SUCCESS";
export const LOAD_USER = "LOAD_USER";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const LOAD_TEMP_INFO = "LOAD_TEMP_INFO";
export const USERDATA_CHANGE = "USERDATA_CHANGE";

export const UPDATE_TESTTYPE = "UPDATE_TESTTYPE";
export const UPDATE_MANY_TESTTYPES = "UPDATE_MANY_TESTTYPES";
export const LOAD_TESTTYPE = "LOAD_TESTTYPE";
export const LOAD_TESTTYPES = "LOAD_TESTTYPES";
export const DELETE_TESTTYPE = "DELETE_TESTTYPE";
export const DELETE_MANY_TESTTYPES = "DELETE_MANY_TESTTYPES";
export const CLEAR_TESTTYPES = "CLEAR_TESTTYPES";

export const LOAD_TEST = "LOAD_TEST";
export const UPDATE_TEST = "UPDATE_TEST";
export const UPDATE_MANY_TESTS = "UPDATE_MANY_TESTS";
export const LOAD_TESTS = "LOAD_TESTS";
export const DELETE_TEST = "DELETE_TEST";
export const DELETE_MANY_TESTS = "DELETE_MANY_TESTS";
export const CLEAR_TESTS = "CLEAR_TESTS";

export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_MANY_COURSES = "UPDATE_MANY_COURSES";
export const LOAD_COURSES = "LOAD_COURSES";
export const LOAD_COURSE = "LOAD_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const DELETE_MANY_COURSES = "DELETE_MANY_COURSES";
export const CLEAR_COURSES = "CLEAR_COURSES";

export const LOAD_FOLDER = "LOAD_FOLDER";
export const LOAD_FOLDERS = "LOAD_FOLDERS";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_MANY_FOLDERS = "UPDATE_MANY_FOLDERS";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_MANY_FOLDERS = "DELETE_MANY_FOLDERS";
export const CLEAR_FOLDERS = "CLEAR_FOLDERS";

export const LOAD_USER_FOLDER_PROGRESS = "ADD_USER_FOLDER_PROGRESS";
export const UPDATE_USER_FOLDER_PROGRESS = "UPDATE_USER_FOLDER_PROGRESS";
export const UPDATE_MANY_USER_FOLDER_PROGRESSES =
	"UPDATE_MANY_USER_FOLDER_PROGRESSES";
export const LOAD_USER_FOLDER_PROGRESSES = "LOAD_USER_FOLDER_PROGRESSES";
export const DELETE_USER_FOLDER_PROGRESS = "DELETE_USER_FOLDER_PROGRESS";
export const DELETE_MANY_USER_FOLDER_PROGRESSES =
	"DELETE_MANY_USER_FOLDER_PROGRESSES";
export const CLEAR_USER_FOLDER_PROGRESSES = "CLEAR_USER_FOLDER_PROGRESSES";

export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_MANY_FILES = "UPDATE_MANY_FILES";
export const LOAD_FILE = "LOAD_FILE";
export const LOAD_FILES = "LOAD_FILES";
export const DELETE_FILE = "DELETE_FILE";
export const DELETE_MANY_FILES = "DELETE_MANY_FILES";
export const CLEAR_FILES = "CLEAR_FILES";

export const UPDATE_CARD_TEMPLATE = "UPDATE_CARD_TEMPLATE";
export const UPDATE_MANY_CARD_TEMPLATES = "UPDATE_MANY_CARD_TEMPLATES";
export const LOAD_CARD_TEMPLATE = "LOAD_CARD_TEMPLATE";
export const LOAD_CARD_TEMPLATES = "LOAD_CARD_TEMPLATES";
export const DELETE_CARD_TEMPLATE = "DELETE_CARD_TEMPLATE";
export const DELETE_MANY_CARD_TEMPLATES = "DELETE_MANY_CARD_TEMPLATES";
export const CLEAR_CARD_TEMPLATES = "CLEAR_CARD_TEMPLATES";

export const UPDATE_CARD = "UPDATE_CARD";
export const UPDATE_MANY_CARDS = "UPDATE_MANY_CARDS";
export const LOAD_CARD = "LOAD_CARD";
export const LOAD_CARDS = "LOAD_CARDS";
export const DELETE_CARD = "DELETE_CARD";
export const DELETE_MANY_CARDS = "DELETE_MANY_CARDS";
export const CLEAR_CARDS = "CLEAR_CARDS";

export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_MANY_QUESTIONS = "UPDATE_MANY_QUESTIONS";
export const LOAD_QUESTION = "LOAD_QUESTION";
export const LOAD_QUESTIONS = "LOAD_QUESTIONS";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_MANY_QUESTIONS = "DELETE_MANY_QUESTIONS";
export const CLEAR_QUESTIONS = "CLEAR_QUESTIONS";

export const UPDATE_FOLDER_HIERARCHY = "UPDATE_FOLDER_HIERARCHY";
export const UPDATE_MANY_FOLDER_HIERARCHIES = "UPDATE_MANY_FOLDER_HIERARCHIES";
export const LOAD_FOLDER_HIERARCHY = "LOAD_FOLDER_HIERARCHY";
export const LOAD_FOLDER_HIERARCHIES = "LOAD_FOLDER_HIERARCHIES";
export const DELETE_FOLDER_HIERARCHY = "DELETE_FOLDER_HIERARCHY";
export const DELETE_MANY_FOLDER_HIERARCHIES = "DELETE_MANY_FOLDER_HIERARCHIES";
export const CLEAR_FOLDER_HIERARCHIES = "CLEAR_FOLDER_HIERARCHIES";

export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const UPDATE_MANY_TOPICS = "UPDATE_MANY_TOPICS";
export const LOAD_TOPIC = "LOAD_TOPIC";
export const LOAD_TOPICS = "LOAD_TOPICS";
export const DELETE_TOPIC = "DELETE_TOPIC";
export const DELETE_MANY_TOPICS = "DELETE_MANY_TOPICS";
export const CLEAR_TOPICS = "CLEAR_TOPICS";

export const LOAD_TOPIC_HIERARCHY = "LOAD_TOPIC_HIERARCHY";
export const LOAD_TOPIC_HIERARCHIES = "LOAD_TOPIC_HIERARCHIES";
export const UPDATE_TOPIC_HIERARCHY = "UPDATE_TOPIC_HIERARCHY";
export const UPDATE_MANY_TOPIC_HIERARCHIES = "UPDATE_MANY_TOPIC_HIERARCHIES";
export const DELETE_TOPIC_HIERARCHY = "DELETE_TOPIC_HIERARCHY";
export const DELETE_MANY_TOPIC_HIERARCHIES = "DELETE_MANY_TOPIC_HIERARCHIES";
export const CLEAR_TOPIC_HIERARCHIES = "CLEAR_TOPIC_HIERARCHIES";

export const UPDATE_TASKTYPE = "UPDATE_TASKTYPE";
export const UPDATE_MANY_TASKTYPES = "UPDATE_MANY_TASKTYPES";
export const LOAD_TASKTYPE = "LOAD_TASKTYPE";
export const LOAD_TASKTYPES = "LOAD_TASKTYPES";
export const DELETE_TASKTYPE = "DELETE_TASKTYPE";
export const DELETE_MANY_TASKTYPES = "DELETE_MANY_TASKTYPES";
export const CLEAR_TASKTYPES = "CLEAR_TASKTYPES";

export const LOAD_TASKTYPE_HIERARCHY = "LOAD_TASKTYPE_HIERARCHY";
export const LOAD_TASKTYPE_HIERARCHIES = "LOAD_TASKTYPE_HIERARCHIES";
export const UPDATE_TASKTYPE_HIERARCHY = "UPDATE_TASKTYPE_HIERARCHY";
export const UPDATE_MANY_TASKTYPE_HIERARCHIES =
	"UPDATE_MANY_TASKTYPE_HIERARCHIES";
export const DELETE_TASKTYPE_HIERARCHY = "DELETE_TASKTYPE_HIERARCHY";
export const DELETE_MANY_TASKTYPE_HIERARCHIES =
	"DELETE_MANY_TASKTYPE_HIERARCHIES";
export const CLEAR_TASKTYPE_HIERARCHIES = "CLEAR_TASKTYPE_HIERARCHIES";

export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const UPDATE_MANY_SUBJECTS = "UPDATE_MANY_SUBJECTS";
export const LOAD_SUBJECT = "LOAD_SUBJECT";
export const LOAD_SUBJECTS = "LOAD_SUBJECTS";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const DELETE_MANY_SUBJECTS = "DELETE_MANY_SUBJECTS";
export const CLEAR_SUBJECTS = "CLEAR_SUBJECTS";

export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const UPDATE_MANY_CLASSROOMS = "UPDATE_MANY_CLASSROOMS";
export const LOAD_CLASSROOM = "LOAD_CLASSROOM";
export const LOAD_CLASSROOMS = "LOAD_CLASSROOMS";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const DELETE_MANY_CLASSROOMS = "DELETE_MANY_CLASSROOMS";
export const CLEAR_CLASSROOMS = "CLEAR_CLASSROOMS";

export const LOAD_ASSIGNMENT = "LOAD_ASSIGNMENT";
export const UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT";
export const UPDATE_MANY_ASSIGNMENTS = "UPDATE_MANY_ASSIGNMENTS";
export const LOAD_ASSIGNMENTS = "LOAD_ASSIGNMENTS";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";
export const DELETE_MANY_ASSIGNMENTS = "DELETE_MANY_ASSIGNMENTS";
export const CLEAR_ASSIGNMENTS = "CLEAR_ASSIGNMENTS";

export const LOAD_COURSE_INFO = "LOAD_COURSE_INFO";
export const UPDATE_COURSE_INFO = "UPDATE_COURSE_INFO";
export const UPDATE_MANY_COURSE_INFOS = "UPDATE_MANY_COURSE_INFOS";
export const LOAD_COURSE_INFOS = "LOAD_COURSE_INFOS";
export const DELETE_COURSE_INFO = "DELETE_COURSE_INFO";
export const DELETE_MANY_COURSE_INFOS = "DELETE_MANY_COURSE_INFOS";
export const CLEAR_COURSE_INFOS = "CLEAR_COURSE_INFOS";

export const LOAD_STUDENT_INFO = "LOAD_STUDENT_INFO";
export const UPDATE_STUDENT_INFO = "UPDATE_STUDENT_INFO";
export const UPDATE_MANY_STUDENT_INFOS = "UPDATE_MANY_STUDENT_INFOS";
export const LOAD_STUDENT_INFOS = "LOAD_STUDENT_INFOS";
export const DELETE_STUDENT_INFO = "DELETE_STUDENT_INFO";
export const DELETE_MANY_STUDENT_INFOS = "DELETE_MANY_STUDENT_INFOS";
export const CLEAR_STUDENT_INFOS = "CLEAR_STUDENT_INFOS";

export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_MANY_USER_INFOS = "UPDATE_MANY_USER_INFOS";
export const LOAD_USER_INFOS = "LOAD_USER_INFOS";
export const DELETE_USER_INFO = "DELETE_USER_INFO";
export const DELETE_MANY_USER_INFOS = "DELETE_MANY_USER_INFOS";
export const CLEAR_USER_INFOS = "CLEAR_USER_INFOS";

export const UPDATE_CLASSROOM_JOIN_REQUEST = "UPDATE_CLASSROOM_JOIN_REQUEST";
export const UPDATE_MANY_CLASSROOM_JOIN_REQUESTS =
	"UPDATE_MANY_CLASSROOM_JOIN_REQUESTS";
export const LOAD_CLASSROOM_JOIN_REQUEST = "LOAD_CLASSROOM_JOIN_REQUEST";
export const LOAD_CLASSROOM_JOIN_REQUESTS = "LOAD_CLASSROOM_JOIN_REQUESTS";
export const DELETE_CLASSROOM_JOIN_REQUEST = "DELETE_CLASSROOM_JOIN_REQUEST";
export const DELETE_MANY_CLASSROOM_JOIN_REQUESTS =
	"DELETE_MANY_CLASSROOM_JOIN_REQUESTS";
export const CLEAR_CLASSROOM_JOIN_REQUESTS = "CLEAR_CLASSROOM_JOIN_REQUESTS";

export const LOAD_USER_FOLDER_LEVEL = "LOAD_USER_FOLDER_LEVEL";
export const LOAD_USER_FOLDER_LEVELS = "LOAD_USER_FOLDER_LEVELS";
export const UPDATE_USER_FOLDER_LEVEL = "UPDATE_USER_FOLDER_LEVEL";
export const UPDATE_MANY_USER_FOLDER_LEVELS = "UPDATE_MANY_USER_FOLDER_LEVELS";
export const DELETE_USER_FOLDER_LEVEL = "DELETE_USER_FOLDER_LEVEL";
export const DELETE_MANY_USER_FOLDER_LEVELS = "DELETE_MANY_USER_FOLDER_LEVELS";
export const CLEAR_USER_FOLDER_LEVELS = "CLEAR_USER_FOLDER_LEVELS";

export const LOAD_USER_TOPIC_LEVEL = "LOAD_USER_TOPIC_LEVEL";
export const LOAD_USER_TOPIC_LEVELS = "LOAD_USER_TOPIC_LEVELS";
export const UPDATE_USER_TOPIC_LEVEL = "UPDATE_USER_TOPIC_LEVEL";
export const UPDATE_MANY_USER_TOPIC_LEVELS = "UPDATE_MANY_USER_TOPIC_LEVELS";
export const DELETE_USER_TOPIC_LEVEL = "DELETE_USER_TOPIC_LEVEL";
export const DELETE_MANY_USER_TOPIC_LEVELS = "DELETE_MANY_USER_TOPIC_LEVELS";
export const CLEAR_USER_TOPIC_LEVELS = "CLEAR_USER_TOPIC_LEVELS";

export const LOAD_USER_TASK_TYPE_LEVEL = "LOAD_USER_TASK_TYPE_LEVEL";
export const LOAD_USER_TASK_TYPE_LEVELS = "LOAD_USER_TASK_TYPE_LEVELS";
export const UPDATE_USER_TASK_TYPE_LEVEL = "UPDATE_USER_TASK_TYPE_LEVEL";
export const UPDATE_MANY_USER_TASK_TYPE_LEVELS =
	"UPDATE_MANY_USER_TASK_TYPE_LEVELS";
export const DELETE_USER_TASK_TYPE_LEVEL = "DELETE_USER_TASK_TYPE_LEVEL";
export const DELETE_MANY_USER_TASK_TYPE_LEVELS =
	"DELETE_MANY_USER_TASK_TYPE_LEVELS";
export const CLEAR_USER_TASK_TYPE_LEVELS = "CLEAR_USER_TASK_TYPE_LEVELS";

export const UPDATE_USERVIEW = "UPDATE_USERVIEW";
export const UPDATE_MANY_USERVIEWS = "UPDATE_MANY_USERVIEWS";
export const LOAD_USERVIEW = "LOAD_USERVIEW";
export const LOAD_USERVIEWS = "LOAD_USERVIEWS";
export const DELETE_USERVIEW = "DELETE_USERVIEW";
export const DELETE_MANY_USERVIEWS = "DELETE_MANY_USERVIEWS";
export const CLEAR_USERVIEWS = "CLEAR_USERVIEWS";

export const UPDATE_USERACTION = "UPDATE_USERACTION";
export const UPDATE_MANY_USERACTIONS = "UPDATE_MANY_USERACTIONS";
export const LOAD_USERACTION = "LOAD_USERACTION";
export const LOAD_USERACTIONS = "LOAD_USERACTIONS";
export const DELETE_USERACTION = "DELETE_USERACTION";
export const DELETE_MANY_USERACTIONS = "DELETE_MANY_USERACTIONS";
export const CLEAR_USERACTIONS = "CLEAR_USERACTIONS";
