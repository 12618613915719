import React, { useCallback, useState } from "react";
import styles from "./styles/how-it-works.module.css";
import { FormattedMessage } from "react-intl";
import { BackgroundOneWave } from "../backgrounds";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import classNames from "classnames";
import { getIntlValue, getFormattedMessage } from "@app/utils/locale";
import { JSXElement } from "@babel/types";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from "@material-ui/core/styles";
import { HeaderWithLogInAndRegister } from "../landing/header";
import { ReactComponent as BoardIcon } from "./styles/imgs/murtsku-logo-board-blue.svg";
import classnames from "classnames";
import { useBoolean } from "@app/hooks/general";
import { RegisterPopup } from "../landing/register-type-selection";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { LoginPopup } from "../landing/login";

export const HowItWorks: React.FC = () => {
	const steps: HowItWorksStep[] = getIntlValue("howItWorks.steps");
	return (
		<>
			<HeaderWithLogInAndRegister />
			<BackgroundOneWave />
			<div className={styles.container}>
				<ExplanationText
					text={getFormattedMessage("howItWorks.text")}
				/>
				<WindowStepsAndImgs steps={steps} />
			</div>
		</>
	);
};

export const ExplanationText: React.FC<{
	text: JSXElement | string;
}> = props => {
	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);

	return (
		<div className={styles.textAndRegisterNowButtonContainer}>
			<BoardIcon className={styles.boardIcon} />
			<div className={styles.text}>{props.text}</div>
			<button
				className={styles.registerNowButtonContainer}
				onClick={openRegistrationPopup}
			>
				<FormattedMessage id="registerNow" />
			</button>
			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
				/>
			)}
		</div>
	);
};

export const WindowStepsAndImgs: React.FC<{
	steps: HowItWorksStep[];
}> = props => {
	const [currentStep, setCurrentStep] = useState(0);
	const steps = props.steps;
	const numberOfSteps = steps.length;

	const onClickOnNextStep = useCallback(() => {
		setCurrentStep(c => (c + 1) % numberOfSteps);
	}, [numberOfSteps]);

	const onClickOnPreviousStep = useCallback(() => {
		setCurrentStep(c => (c - 1 + numberOfSteps) % numberOfSteps);
	}, [numberOfSteps]);

	return (
		<div className={styles.howItWorksContainer}>
			<div className={styles.computerScreen}>
				<div
					className={styles.arrowLeftIcon}
					onClick={onClickOnPreviousStep}
				>
					<ArrowLeftIcon />
				</div>
				<StepWindow step={steps[currentStep]} />
				<div
					className={styles.arrowRightIcon}
					onClick={onClickOnNextStep}
				>
					<ArrowRightIcon />
				</div>
			</div>
			<div className={styles.stepsContainer}>
				{new Array(numberOfSteps).fill(0).map((step, i) => (
					<div
						key={i}
						className={
							i === currentStep
								? classNames(styles.currentStep, styles.step)
								: styles.step
						}
						onClick={() => setCurrentStep(i)}
					>
						{i + 1}
					</div>
				))}
			</div>
		</div>
	);
};

const StepWindow: React.FC<{
	step: HowItWorksStep;
}> = React.memo(props => {
	return (
		<div className={styles.howPagesWork}>
			<img style={{ width: "100%" }} src={props.step.img} alt="" />
			<StepWindowPoints points={props.step.points} />
		</div>
	);
});

const StepWindowPoints: React.FC<{
	points: HowItWorksPoint[];
}> = React.memo(props => {
	return (
		<div>
			{props.points.map((point, index) => (
				<StepWindowSinglePoint
					point={point}
					key={index}
					label={index + 1}
					classNames={index === 0 ? styles.point1 : styles.point}
				/>
			))}
		</div>
	);
});

const StepWindowSinglePoint: React.FC<{
	point: HowItWorksPoint;
	label: string | number;
	classNames?: string;
}> = ({ point, label, classNames }) => {
	return (
		<LightTooltip title={point[Point.text]}>
			<div
				className={classnames(styles.point, classNames)}
				style={point[Point.style]}
			>
				{label}
			</div>
		</LightTooltip>
	);
};

enum Point {
	style = 0,
	text = 1,
}

interface HowItWorksPoint {
	[Point.style]: React.CSSProperties;
	[Point.text]: string;
}

interface HowItWorksStep {
	img: string;
	points: HowItWorksPoint[];
}

const LightTooltip = withStyles((theme: Theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 14,
	},
}))(Tooltip);
