import { ObjectId } from "../generics";
import { IHierarchyInfo } from "@app/services/hierarchy-info/interfaces";
import { IMultipleSelectItem } from "@app/components/widgets/deep-multiple-select";

export function getItemsAndHierarchyWithoutRoot<
	Item extends { _id: ObjectId; name: string },
	Hierarchy extends IHierarchyInfo = IHierarchyInfo
>(
	info: null
): {
	items: null;
	hierarchyInfo: null;
};
export function getItemsAndHierarchyWithoutRoot<
	Item extends { _id: ObjectId; name: string },
	Hierarchy extends IHierarchyInfo = IHierarchyInfo
>(info: {
	items: Item[];
	hierarchyInfo: Hierarchy;
}): {
	items: IMultipleSelectItem<string>[];
	hierarchyInfo: Hierarchy;
};
export function getItemsAndHierarchyWithoutRoot<
	Item extends { _id: ObjectId; name: string },
	Hierarchy extends IHierarchyInfo = IHierarchyInfo
>(
	info: {
		items: Item[];
		hierarchyInfo: Hierarchy;
	} | null
):
	| {
			items: IMultipleSelectItem<string>[];
			hierarchyInfo: IHierarchyInfo;
	  }
	| {
			items: null;
			hierarchyInfo: null;
	  } {
	if (!info) {
		return { items: null, hierarchyInfo: null };
	}
	const rootItemId = info.hierarchyInfo.rootId;
	const items: IMultipleSelectItem<string>[] = [];
	for (const topic of info.items) {
		if (topic._id === rootItemId) continue;
		items.push({ id: topic._id, name: topic.name });
	}
	const hierarchyInfo = { ...info.hierarchyInfo };
	hierarchyInfo.childrenInfo = { ...hierarchyInfo.childrenInfo };
	hierarchyInfo.parentInfo = { ...hierarchyInfo.parentInfo };
	const rootsChildren = hierarchyInfo.childrenInfo[rootItemId];
	delete hierarchyInfo.childrenInfo[rootItemId];
	if (rootsChildren) {
		for (const childId of rootsChildren) {
			delete hierarchyInfo.parentInfo[childId];
		}
	}
	return { items, hierarchyInfo };
}
