import React, { useCallback } from "react";
import { Group } from "@app/models/group";
import styles from "../../styles/index.module.css";
import { useUserShortInfo } from "@app/hooks/users";
import {
	HeadmasterGroupInfoContainer,
	HeadmasterGroupBoxInfoType,
} from "./info-container";
import { useGoToUrl } from "@app/hooks/front";

export const HeadmasterGroupBox: React.FC<{ group: Group }> = ({ group }) => {
	const mentorId = group.mentorIds.length >= 1 ? group.mentorIds[0] : null;
	const mentor = useUserShortInfo(mentorId);
	const goToGroup = useGoToUrl(`/headmaster/group/${group._id}/timetable`);

	return (
		<div className={styles.boxContainer}>
			<div className={styles.groupName}>{group.name}</div>
			<HeadmasterGroupInfoContainer
				info={{
					type: HeadmasterGroupBoxInfoType.mentor,
					name: mentor.doc?.getFullName() || "",
				}}
			/>
			<HeadmasterGroupInfoContainer
				info={{
					type: HeadmasterGroupBoxInfoType.students,
					quantity: group.studentIds.length,
				}}
			/>
			<EnterButton onClick={goToGroup} />
		</div>
	);
};

const EnterButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	return (
		<div className={styles.enterButton} onClick={onClick}>
			შესვლა
		</div>
	);
};
