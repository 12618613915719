import { ObjectId } from "@app/utils/generics";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import React, { useContext, useState, useCallback, useMemo } from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import {
	SortedContentListContext,
	SortedContentSingleItem,
	ContentContext,
	SortedContentList,
	SortedContentNestedItem,
	Content,
} from "../";
import styles from "../../styles/styles.module.css";
import { QuestionContentContainer, TextContainer } from "./content";
import {
	AdditionalContentPopup,
	SelectedContentArr,
} from "./additional-content";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import { ItemType } from "@app/api/folders/helper-schemas";
import {
	getPartitionedContent,
	getNewContentAfterDropEnd,
	getRemovedContent,
	getInsertedContent,
	getReplacedContent,
} from "../helper-functions";
import { useBoolean } from "@app/hooks/general";

export interface IQuestionsSectionProps {
	onPreviousPage?: () => void;
	isCreating: boolean;
	classroomId: ObjectId;
	courseId: ObjectId;
}

export const QuestionsSection: React.FC<IQuestionsSectionProps> = React.memo(
	props => {
		const [sortedContent, setSortedContent] = useContext(
			SortedContentListContext
		);

		const onContentRemove = useCallback(
			(contentItem: SortedContentList[number]) => {
				setSortedContent(content =>
					getRemovedContent(content, contentItem)
				);
			},
			[setSortedContent]
		);

		const handleContentAdding = (
			selectedContent: SelectedContentArr,
			content: Content
		) => {
			setSortedContent(contentList =>
				getInsertedContent({ contentList, selectedContent, content })
			);
			hideExtraContentPopup();
		};

		const handleContentReplacement = (
			replaceableContent: SortedContentList[number],
			selectedContent: SelectedContentArr,
			content: Content
		) => {
			setSortedContent(contentList =>
				getReplacedContent({
					replaceableContent,
					contentList,
					selectedContent,
					content,
				})
			);
			setReplaceableContentItem(undefined);
		};

		const {
			value: isAddingExtraQuestion,
			setTrue: showExtraQuestionPopup,
			setFalse: hideExtraContentPopup,
		} = useBoolean();
		const [replaceableContent, setReplaceableContentItem] = useState<
			SortedContentList[number] | undefined
		>();

		const handleQuestionReplaceShow = useCallback(
			(contentItem: SortedContentList[number]) => {
				setReplaceableContentItem(contentItem);
			},
			[]
		);

		const hideReplacableContentPopup = useCallback(() => {
			setReplaceableContentItem(undefined);
		}, []);

		const containerClassName = props.isCreating
			? styles.transparentContainer2
			: classNames(styles.transparentContainer2, styles.edit);

		const partitionedContent: PartitionedContentList = useMemo(
			() => getPartitionedContent(sortedContent),
			[sortedContent]
		);

		const onDragEnd = useCallback(
			(result: DropResult): void => {
				const newContent = getNewContentAfterDropEnd(
					result,
					sortedContent,
					partitionedContent
				);
				setSortedContent(newContent);
			},
			[partitionedContent, setSortedContent, sortedContent]
		);

		return (
			<>
				<div className={containerClassName}>
					{props.isCreating && (
						<KeyboardArrowLeft
							className={styles.keyboardArrowLeftStyles}
							onClick={props.onPreviousPage}
						/>
					)}
					<ContentDragAndDrop
						partitionedContent={partitionedContent}
						handleQuestionReplaceShow={handleQuestionReplaceShow}
						onDragEnd={onDragEnd}
						onContentRemove={onContentRemove}
						courseId={props.courseId}
					/>
					<AddQuestionButton onClick={showExtraQuestionPopup} />
				</div>
				{(replaceableContent || isAddingExtraQuestion) && (
					<AdditionalContentPopup
						onClose={
							isAddingExtraQuestion
								? hideExtraContentPopup
								: hideReplacableContentPopup
						}
						classroomId={props.classroomId}
						onSubmit={(selection, content) =>
							isAddingExtraQuestion
								? handleContentAdding(selection, content)
								: handleContentReplacement(
										replaceableContent!,
										selection,
										content
								  )
						}
					/>
				)}
			</>
		);
	}
);

const ContentDragAndDrop: React.FC<{
	partitionedContent: PartitionedContentList;
	handleQuestionReplaceShow: (contentItem: SortedContentList[number]) => void;
	onDragEnd: (result: DropResult) => void;
	onContentRemove: (contentItem: SortedContentList[number]) => void;
	courseId: ObjectId;
}> = props => {
	return (
		<div>
			{props.partitionedContent.map((partition, i) => {
				if (partition.type === "singles") {
					return (
						<SingleContentsSortableList
							key={partition.id}
							partitionId={partition.id}
							singleContents={partition.items}
							handleQuestionReplaceShow={
								props.handleQuestionReplaceShow
							}
							onDragEnd={props.onDragEnd}
							onItemRemove={props.onContentRemove}
						/>
					);
				}
				return (
					<TextContainer
						key={partition.id}
						partitionId={partition.id}
						contentItem={partition.item}
						handleQuestionReplaceShow={
							props.handleQuestionReplaceShow
						}
						onDragEnd={props.onDragEnd}
						onItemRemove={props.onContentRemove}
						courseId={props.courseId}
					/>
				);
			})}
		</div>
	);
};

const AddQuestionButton: React.FC<{ onClick: () => void }> = React.memo(
	props => {
		return (
			<button className={styles.addButton} onClick={props.onClick}>
				<AddIcon style={{ fontSize: 33 }} />
			</button>
		);
	}
);

export const droppableIdPrefix = "droppable";

export const SingleContentsSortableList: React.FC<{
	singleContents: SortedContentSingleItem[];
	partitionId: number;
	onDragEnd: (result: DropResult) => void;
	onItemRemove: (content: SortedContentList[number]) => void;
	handleQuestionReplaceShow?: (content: SortedContentList[number]) => void;
}> = props => {
	const [content] = useContext(ContentContext);
	return (
		<DragDropContext onDragEnd={props.onDragEnd}>
			<Droppable droppableId={droppableIdPrefix + props.partitionId}>
				{(provided, snapshot) => (
					<div ref={provided.innerRef}>
						<div>
							{props.singleContents.map((contentItem, index) => {
								if (
									contentItem.itemType !== ItemType.question
								) {
									return null;
								}
								const question =
									content.questions[contentItem.itemId];
								if (!question) return null;
								return (
									<QuestionDraggable
										key={contentItem.itemId}
										index={index}
										question={question}
										onQuestionDelete={props.onItemRemove}
										handleQuestionReplaceShow={
											props.handleQuestionReplaceShow
										}
										contentItem={contentItem}
									/>
								);
							})}
						</div>
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

const QuestionDraggable: React.FC<{
	question: IFullQuestion;
	index: number;
	onQuestionDelete: (content: SortedContentList[number]) => void;
	handleQuestionReplaceShow?: (content: SortedContentList[number]) => void;
	contentItem: SortedContentList[number];
}> = React.memo(props => {
	const question = props.question;
	return (
		<Draggable draggableId={question._id} index={props.index}>
			{(provided2, snapshot2) => (
				<div
					ref={provided2.innerRef}
					{...provided2.draggableProps}
					{...provided2.dragHandleProps}
				>
					<QuestionContentContainer
						key={question._id}
						question={question}
						contentItem={props.contentItem}
						onItemRemove={props.onQuestionDelete}
						handleQuestionReplaceShow={
							props.handleQuestionReplaceShow
						}
					/>
				</div>
			)}
		</Draggable>
	);
});

export interface PartitionedSingles {
	id: number;
	type: "singles";
	items: SortedContentSingleItem[];
}
export interface PartitionedNested {
	id: number;
	type: "nested";
	item: SortedContentNestedItem;
}
export type PartitionedContentList = (PartitionedSingles | PartitionedNested)[];
