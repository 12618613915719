import { UserType } from "@app/api/helper-schemas";
import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StudentLogin } from "../auth/login";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { RegisterPopup } from "../landing/register-type-selection";
import Illustration from "./styles/imgs/illustration.png";
import IllustrationNL from "./styles/imgs/illustration-nl.png";
import IllustrationEN from "./styles/imgs/illustration-en.png";
import styles from "./styles/tv-school-landing.module.css";

export const Role: React.FC<{ isForgetPassword?: boolean }> = props => {
	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const locale = useLocale();
	const isGeo = locale === "ka";
	const isDutch = locale === "nl";
	const isEng = locale === "en";
	const isDifferentTitle = locale === "nl" || locale === "en";

	const [defaultSelectedUserType, setDefaultSelectedUserType] = useState<
		UserType
	>();

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(
		(userType?: UserType) => {
			setDefaultSelectedUserType(userType);
			closeLoginPopup();
			openRegistrationPopup();
		},
		[closeLoginPopup, openRegistrationPopup]
	);

	const [isForgotPassword, setForgotPassword] = useState(false);

	const switchForgotPassword = useCallback(e => {
		setForgotPassword(!e);
	}, []);
	const { width } = useWindowSize();
	const isResponsive = width <= 800;

	return (
		<div
			className={
				isGeo
					? styles.roleContainer
					: isDutch
					? styles.roleContainerNL
					: styles.roleContainerEN
			}
		>
			<div className={styles.roleTexts}>
				{isEng && <div className={styles.welcomeText}>स्वागत हे</div>}
				<div
					className={
						isDifferentTitle
							? styles.mainTitleDutch
							: isEng
							? styles.mainTitleEng
							: styles.mainTitle
					}
				>
					<FormattedMessage id="tvSchoolLanding.mainTitle" />
				</div>

				<div className={styles.landingTitle}>
					<h1
						className={styles.landingTitle__h1}
						style={{
							fontFamily: isDifferentTitle
								? "Dosis"
								: "Roboto Geo Nus",
						}}
					>
						<FormattedMessage
							id="tvSchoolLanding.secondaryTitle"
							values={{ br: <br /> }}
						/>
					</h1>
				</div>

				<StudentLogin
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isRegisterButtonVissible={true}
					isDivTitleHidden={true}
					inputClassname={styles.loginInput}
					forgetPasswordQuestionContainerClassname={
						styles.forgetPassword
					}
					signInButtonClassname={styles.signInButton}
					registerButtonClassname={styles.registerButtonClassname}
					isIconVissible={false}
					isForgotPassword={isForgotPassword}
					switchForgotPassword={switchForgotPassword}
					isFromTvSchoolLanding={isGeo}
				/>
			</div>

			{isResponsive && (
				<img
					src={
						isGeo
							? Illustration
							: isDutch
							? IllustrationNL
							: IllustrationEN
					}
					alt={
						isGeo
							? "Illustration"
							: isDutch
							? "IllustrationNL"
							: "IllustrationEN"
					}
					className={styles.illustration}
				/>
			)}

			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
					userType={defaultSelectedUserType}
					isCountryInputInvissible={true}
				/>
			)}
		</div>
	);
};
