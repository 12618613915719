import { IHWState, IHWSettings } from ".";

import { ObjectId } from "@app/utils/generics";

function divideTaskTypeCounts(
	totalNumOfQuestions: number,
	selectedTaskTypes: IHWState["taskTypesInAdvancedSettings"]
): IHWState["taskTypesInAdvancedSettings"] {
	if (!selectedTaskTypes) return null;
	let numOfFixedTaskTypes = 0;
	let totalNumOfFixedQuestions = 0;
	for (let taskType of selectedTaskTypes) {
		if (taskType.isFixed) {
			numOfFixedTaskTypes++;
			if (taskType.countType === "question") {
				totalNumOfFixedQuestions += taskType.count;
			} else {
				if (taskType.approximateNumOfQuestionsPerText) {
					totalNumOfFixedQuestions +=
						taskType.count *
						taskType.approximateNumOfQuestionsPerText;
				}
			}
		}
	}
	let numOfAvaliableQuestions =
		totalNumOfQuestions - totalNumOfFixedQuestions;
	let numOfAvaliableTaskTypes =
		selectedTaskTypes.length - numOfFixedTaskTypes;
	const arr: IHWState["taskTypesInAdvancedSettings"] = [];
	for (let taskType of selectedTaskTypes) {
		if (taskType.isFixed) {
			arr.push(taskType);
			continue;
		}

		const avg = Math.max(
			1,
			Math.floor(numOfAvaliableQuestions / numOfAvaliableTaskTypes)
		);
		if (taskType.countType === "question") {
			arr.push({
				_id: taskType._id,
				count: avg,
				countType: taskType.countType,
				isFixed: false,
				approximateNumOfQuestionsPerText:
					taskType.approximateNumOfQuestionsPerText,
			});
			numOfAvaliableQuestions -= avg;
		} else {
			if (!taskType.approximateNumOfQuestionsPerText) {
				arr.push({
					_id: taskType._id,
					count: taskType.count,
					countType: taskType.countType,
					isFixed: false,
				});
			} else {
				const avgNumOfTexts = Math.round(
					avg / taskType.approximateNumOfQuestionsPerText
				);
				arr.push({
					_id: taskType._id,
					count: avgNumOfTexts,
					countType: taskType.countType,
					isFixed: false,
					approximateNumOfQuestionsPerText:
						taskType.approximateNumOfQuestionsPerText,
				});
				numOfAvaliableQuestions -=
					avgNumOfTexts * taskType.approximateNumOfQuestionsPerText;
			}
		}
		numOfAvaliableTaskTypes--;
	}
	return arr;
}
export type IHWActions =
	| {
			type: "CHANGE_NAME";
			value: string;
	  }
	| {
			type: "CHANGE_NUM_OF_QUESTIONS";
			value: number;
	  }
	| {
			type: "CHANGE_SELECTED_TOPICS";
			_ids: ObjectId[];
	  }
	| {
			type: "CHANGE_SETTINGS";
			newSettings: IHWSettings;
	  }
	| {
			type: "NULLIFY_TASKTYPES_COUNT";
	  }
	| {
			type: "MAKE_TASKTYPES_COUNTABLE";
			taskTypes: {
				_id: ObjectId;
				countType: "text" | "question";
				approximateNumOfQuestionsPerText?: number | null;
			}[];
	  }
	| {
			type: "CHANGE_HW_DEADLINE";
			date: Date;
	  }
	| {
			type: "CHANGE_SELECTED_CLASSROOMS";
			classroomIds: ObjectId[];
	  }
	| {
			type: "CHANGE_SELECTED_TASKTYPES";
			_id: ObjectId;
	  }
	| {
			type: "CHANGE_TASKTYPES_COUNT";
			newCount: number;
			taskTypeId: ObjectId;
	  };

export const reducer = (state: IHWState, action: IHWActions): IHWState => {
	switch (action.type) {
		case "CHANGE_NAME":
			return { ...state, name: action.value };
		case "CHANGE_NUM_OF_QUESTIONS":
			return {
				...state,
				totalNumOfQuestions: action.value,
				taskTypesInAdvancedSettings: divideTaskTypeCounts(
					action.value,
					state.taskTypesInAdvancedSettings
				),
			};
		case "CHANGE_SELECTED_TOPICS":
			return {
				...state,
				topics: action._ids,
			};
		case "CHANGE_SETTINGS":
			return { ...state, settings: action.newSettings };
		case "CHANGE_HW_DEADLINE":
			return {
				...state,
				settings: { ...state.settings, deadline: action.date },
			};
		case "CHANGE_SELECTED_CLASSROOMS":
			const classrooms: Record<string, true> = {};
			for (const classroomId of action.classroomIds) {
				classrooms[classroomId] = true;
			}
			return {
				...state,
				classrooms,
			};
		case "NULLIFY_TASKTYPES_COUNT":
			return {
				...state,
				taskTypesInAdvancedSettings: null,
			};
		case "MAKE_TASKTYPES_COUNTABLE":
			return {
				...state,
				taskTypesInAdvancedSettings: divideTaskTypeCounts(
					state.totalNumOfQuestions,
					action.taskTypes.map(e => ({
						count: 0,
						_id: e._id,
						countType: e.countType,
						isFixed: false,
						approximateNumOfQuestionsPerText:
							e.approximateNumOfQuestionsPerText,
					}))
				),
			};
		case "CHANGE_TASKTYPES_COUNT":
			if (!state.taskTypesInAdvancedSettings) return state;
			const newTaskTypesInAdvancedSettings = divideTaskTypeCounts(
				state.totalNumOfQuestions,
				state.taskTypesInAdvancedSettings.map(tastType => {
					if (tastType._id !== action.taskTypeId) {
						return tastType;
					}
					return {
						...tastType,
						count: action.newCount,
						isFixed: true,
					};
				})
			);

			let count = 0;

			for (const taskType of newTaskTypesInAdvancedSettings!) {
				if (taskType.countType === "question") {
					count += taskType.count;
				} else if (taskType.approximateNumOfQuestionsPerText) {
					count +=
						taskType.count *
						taskType.approximateNumOfQuestionsPerText;
				}
			}
			return {
				...state,
				totalNumOfQuestions: count,
				taskTypesInAdvancedSettings: newTaskTypesInAdvancedSettings,
			};

		default:
			return state;
	}
};
