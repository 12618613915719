import { MenuOptions } from "@app/components/widgets/hoverable-select";
import { useGoToUrl, useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { inject } from "@app/modules";
import { IAnyObj } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import classNames from "classnames";
import React, { useCallback, useMemo, useContext } from "react";
import { ReactComponent as LogoIcon } from "../../landing/styles/img/hubu-logo.svg";
import { ReactComponent as Hubu } from "../../landing/styles/img/Hubu.svg";
import { ReactComponent as LupiLogo } from "../../landing/styles/img/lupi-ai.svg";
import HeaderStyles from "../../users/header/styles/header.module.css";
import { ReactComponent as UserIcon } from "../../users/styles/img/user.svg";
import { GeneralContext } from "@app/components/general-context";

export const MainAdminHeader: React.FC<IAnyObj> = props => {
	const locale = useLocale();
	const { width } = useWindowSize();
	const gotoMainPage = useGoToUrl(`/`);

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	if (isHeaderHidden) return null;

	return (
		<>
			<div
				className={
					width > 500
						? HeaderStyles.container
						: classNames(HeaderStyles.container)
				}
			>
				<div className={HeaderStyles.logo} onClick={gotoMainPage}>
					{locale === "ka" && width >= 500 && (
						<LogoIcon style={{ height: "100%", width: 112 }} />
					)}{" "}
					{locale === "ka" && width < 500 && (
						<Hubu
							style={{
								height: "100%",
								width: 50,
								color: "white",
							}}
						/>
					)}
					{locale === "en" && (
						<LupiLogo style={{ height: "100%", width: 112 }} />
					)}
				</div>
				<div>
					<div>
						<UserOptions />
					</div>
				</div>
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};

enum UserOption {
	courses,
	profile,
	logout,
}

const useTeacherHeaderOptions = () => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: UserOption.courses,
				label: getFormattedMessage("courses"),
			},
			{
				value: UserOption.profile,
				label: getFormattedMessage("profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

const UserOptions: React.FC = props => {
	const history = useHistory();
	const userHeaderOptions = useTeacherHeaderOptions();
	const {
		value: isOptionsVisible,
		setTrue: showOptions,
		setFalse: hideOptions,
	} = useBoolean(false);

	const { width } = useWindowSize();
	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				///
			});
		history.push(`/`);
	}, [history]);

	const onGoToProfile = useCallback(() => {
		history.push(`/profile`);
	}, [history]);

	const onGoToCourses = useCallback(() => {
		history.push(`/courses`);
	}, [history]);

	const onSelect = useCallback(
		(option: { value: UserOption; label: JSX.Element | string }) => {
			if (option.value === UserOption.profile) {
				onGoToProfile();
			} else if (option.value === UserOption.logout) {
				onLogout();
			} else if (option.value === UserOption.courses) {
				onGoToCourses();
			}
		},
		[onGoToProfile, onLogout, onGoToCourses]
	);

	const menuStyles = useMemo(
		(): React.CSSProperties => ({
			right: 0,
			width: "max-content",
			fontFamily: "Roboto Geo Nus",
			color: "#5273e6",
			minWidth: 150,
			fontSize: 14,
		}),
		[]
	);

	return (
		<div onMouseEnter={showOptions} onMouseLeave={hideOptions}>
			<div
				className={
					width > 1024
						? HeaderStyles.teacherUserICon
						: classNames(HeaderStyles.teacherUserICon)
				}
				onClick={width > 1200 ? onGoToProfile : showOptions}
				style={{
					backgroundColor: !isOptionsVisible ? "#5273e6" : "white",
				}}
			>
				<UserIcon
					style={{ fill: isOptionsVisible ? "#5273e6" : "white" }}
				/>
			</div>
			{isOptionsVisible && (
				<MenuOptions
					onSelect={onSelect}
					options={userHeaderOptions}
					style={menuStyles}
				/>
			)}
		</div>
	);
};
