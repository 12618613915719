import { css } from "emotion";

export const PopupCSS = css`
	display: table;
	min-width: 300px;
	max-width: 600px;
	background-color: white;
	border-radius: 10px;
	text-align: center;
	margin: 0 auto;
	margin-top: 80px;
	margin-bottom: 80px;
	position: relative;
	font-family: "Roboto Geo Nus";
`;

export const PopupHeader = css`
	height: 40px;
	> h3 {
		padding: 10px 0px;
		text-align: center;
	}
`;

export const Cancel = css`
	float: right;
	color: #c0c0c0;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	&:hover {
		color: grey;
	}
`;

export const IconContainer = css`
	height: max-content;
	text-align: right;
	padding: 2px 6px 0 0;
`;

export const PopupBody = css`
	padding: 10px;
	padding-bottom: 40px;
	> p {
		text-align: left;
	}
`;
