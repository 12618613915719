import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { FacebookVideoSchema, IFacebookVideo } from "./helper-schemas";

export const APOSTFacebookVideosSchema = Joi.object({
	videoHTMLs: Joi.array()
		.items(Joi.string().required())
		.required(),
});

export interface IAPOSTFacebookVideos {
	videoHTMLs: string[];
}

///

export const RGETFacebookVideosSchema = Joi.array()
	.items(FacebookVideoSchema)
	.required();

export type IRGETFacebookVideosSchema = IFacebookVideo[];

///

export const APUTUpdateFacebookVideosSchema = Joi.array()
	.items(
		Joi.object()
			.keys({
				_id: Joi.objectId().required(),
				videoHTML: Joi.string().required(),
				title: Joi.string().optional(),
				href: Joi.string().optional(),
			})
			.required()
	)
	.required();

export type IAPUTUpdateFacebookVideos = {
	_id: ObjectId;
	videoHTML: string;
	title?: string;
	href?: string;
}[];

///

export const ADELETEFacebookVideoSchema = Joi.object().keys({
	_id: Joi.objectId().required(),
});

export interface IADELETEFacebookVideo {
	_id: ObjectId;
}
