import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import {
	useModelDocByQuery,
	useModelDocsByIds,
	useModelDocById,
} from "m-model-react";
import { useMemo, useCallback, useEffect, useState } from "react";
import {
	useResourceInfoWithLoading,
	IResourceLoadingInfo,
	getResourceLoadingInfo,
} from "./fetch";
import { TaskTypeHierarchy } from "@app/models/task-type-hierarchy";
import { TaskType } from "@app/models/task-type";

export const useTaskTypeHierarchy = (
	courseId: ObjectId | null
): IResourceLoadingInfo<TaskTypeHierarchy> => {
	const TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	const query = useMemo(() => (!courseId ? null : { courseId }), [courseId]);
	const hierarchy = useModelDocByQuery(TaskTypeHierarchyModel, query);
	const fetchResource = useCallback((info: { courseId: ObjectId }) => {
		const TaskTypesController = inject("TaskTypesController");
		if (!info.courseId) return;
		return TaskTypesController.getHierarchy({
			courseId: info.courseId,
		});
	}, []);
	const resourceInfo = useResourceInfoWithLoading({
		resource: hierarchy,
		fetchingArg: useMemo(() => ({ courseId }), [courseId]),
		fetch: fetchResource,
		isIdentificationKnown: courseId !== null,
	});
	return resourceInfo;
};

export const useAllTaskTypesByCourse = (
	courseId: ObjectId | null
): TaskType[] | null => {
	const hierarchy = useTaskTypeHierarchy(courseId);
	const taskTypeIds = useMemo(
		() =>
			hierarchy.isSuccessfullyLoaded
				? Object.keys(hierarchy.doc.parentInfo).concat(
						hierarchy.doc.rootId
				  )
				: null,
		[hierarchy]
	);

	const [areTaskTypesLoaded, setAreTaskTypesLoaded] = useState(false);
	useEffect(() => {
		let isCanceled = false;
		setAreTaskTypesLoaded(false);
		if (!courseId) return;
		const TaskTypesController = inject("TaskTypesController");
		TaskTypesController.getAllByCourseId({ courseId })
			.then(() => {
				if (isCanceled) return;
				setAreTaskTypesLoaded(true);
			})
			.catch(e => {
				if (isCanceled) return;
				setAreTaskTypesLoaded(false);
			});
		return () => {
			isCanceled = true;
		};
	}, [courseId]);

	const taskTypes = useModelDocsByIds(inject("TaskTypeModel"), taskTypeIds);
	if (!areTaskTypesLoaded) {
		return null;
	}
	return taskTypes;
};

interface UseTaskTypeInfoArgs {
	taskTypeId: ObjectId | null;
	courseId: ObjectId | null;
}
export function useTaskType({
	taskTypeId,
	courseId,
}: UseTaskTypeInfoArgs): IResourceLoadingInfo<TaskType, undefined> {
	const taskType = useModelDocById(inject("TaskTypeModel"), taskTypeId);
	useAllTaskTypesByCourse(courseId);
	return useMemo(() => {
		return getResourceLoadingInfo<TaskType, undefined>({
			resource: taskType,
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: !!taskTypeId && !!courseId,
		});
	}, [courseId, taskType, taskTypeId]);
}
