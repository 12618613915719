import { UserType } from "@app/api/helper-schemas";
import { ProfOrientationInfoPopup } from "@app/components/prof-orientation/landing";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { useCourseInfo, useFullCourseSkeleton } from "@app/hooks/courses";
import { useHistory } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import React, { useCallback, useEffect } from "react";
import { CurrentHomework } from "./assignment";
import { MainPageCurrentLesson } from "./lesson";
import { CurrentLessonLoader, CurrentCourseLoader } from "./loaders";
import { ReactComponent as CancelIcon } from "./styles/imgs/cancel.svg";
import { ReactComponent as JoinClassroomBig } from "./styles/imgs/join-classroom-big.svg";
import { ReactComponent as JoinClassroomSmall } from "./styles/imgs/join-classroom-small.svg";
import { ReactComponent as ProfSmall } from "./styles/imgs/prof-small.svg";
import { ReactComponent as TouBig } from "./styles/imgs/tou-big.svg";
import { ReactComponent as TouSmall } from "./styles/imgs/tou-small.svg";
import styles from "./styles/responsive-students-main-page.module.css";
import { TeacherInvitation } from "../invitations/outgoing";
import triggerEvent from "@app/utils/events";
import { CurrentCourse } from "./current-course";

export const ResponsiveInnerBody: React.FC<{ courseId: ObjectId }> = props => {
	const classroom = useClassroomByCourseId(props.courseId || null);
	const courseInfo = useCourseInfo(props.courseId);
	const courseFullInfo = useFullCourseSkeleton(props.courseId);
	const isRegistered = classroom.isIdentificationKnown;

	useEffect(() => {
		const UsersController = inject("UsersController");
		UsersController.getMyInfo(UserType.student)
			.then()
			.catch();
	}, []);
	const currentLessonRef = React.useRef<HTMLElement | null>(null);
	const addClassroomRef = React.useRef<HTMLElement | null>(null);

	return (
		<div>
			<UpperBanner isJoinClassroomInvissible={true} />
			<div className={styles.banners}>
				<div>
					{courseFullInfo && courseInfo.isSuccessfullyLoaded ? (
						<>
							<CurrentCourse
								courseId={props.courseId}
								courseInfo={courseInfo.doc}
								classroom={classroom}
							/>
							<MainPageCurrentLesson
								courseId={props.courseId}
								courseInfo={courseInfo.doc}
								containerRef={currentLessonRef}
							/>
						</>
					) : (
						<>
							<CurrentCourseLoader isMobile={true} />
							<CurrentLessonLoader
								courseId={props.courseId}
								isMobile={true}
							/>
						</>
					)}
				</div>
				<div>
					<CurrentHomework
						courseId={props.courseId}
						classroom={classroom}
						containerRef={addClassroomRef}
						isJoinClassroomInvissible={true}
						isContentLoaderInvissible={!isRegistered}
					/>
				</div>
			</div>
		</div>
	);
};

const UpperBanner: React.FC<{
	isJoinClassroomInvissible?: boolean;
}> = props => {
	const locale = useLocale();
	return (
		<div className={styles.upperBanner}>
			{locale === "ka" && (
				<>
					<ProfOrientation />
				</>
			)}
			{!props.isJoinClassroomInvissible && <JoinClassroomResponsive />}
		</div>
	);
};

export const Frame: React.FC<{
	text: string;
	illustration: JSX.Element;
}> = props => {
	return (
		<div className={styles.frameContainer}>
			{props.illustration}
			<div className={styles.frameMainText}>{props.text}</div>
		</div>
	);
};

const Sponsore: React.FC<{}> = props => {
	const history = useHistory();
	const redirectToTouInfo = useCallback(() => {
		history.push(`/tou-info`);
		triggerEvent(
			{
				category: "Button",
				action: "redirect to tou info",
				label: "tou info",
			},
			{}
		);
	}, [history]);

	return (
		<div onClick={redirectToTouInfo} className={styles.frameContainer}>
			<TouSmall style={{ width: 110, height: 107 }} />
		</div>
	);
};

export const TouInfo: React.FC<{}> = props => {
	const history = useHistory();
	const goBack = useCallback(() => {
		history.goBack();
	}, [history]);

	return (
		<div className={styles.touInfoContainer}>
			<div className={styles.cancelIconContainer}>
				<div className={styles.cancelIcon} onClick={goBack}>
					<CancelIcon className={styles.cancelIcon} />
				</div>
			</div>
			<div className={styles.touUpperBody}>
				<TouBig style={{ width: "100%" }} />
			</div>
			<div className={styles.touLowerBody}>
				<div className={styles.touLowerBodyMainText}>
					თბილისის ღია უნივერსიტეტი-ატმოსფერო შენი ინსპირაციისთვის!
				</div>
				<div className={styles.lowerBodyListContainer}>
					<li style={{ marginBottom: 5 }}>
						სრული აკრედიტაცია, ყველა სასწავლო პროგრამაზე;
					</li>
					<li style={{ marginBottom: 5 }}>
						პრაქტიკაზე დაფუძნებული სწავლება;
					</li>
					<li style={{ marginBottom: 5 }}>
						მრავალფეროვანი გაცვლითი პროგრამები;
					</li>
					<li style={{ marginBottom: 5 }}>
						ულტრათანამედროვე მატერიალური-ტექნიკურ ბაზა;
					</li>
				</div>
			</div>
			<div className={styles.touButtonContainer}>
				<button
					className={styles.touButton}
					onClick={() =>
						window.open("https://www.tou.edu.ge/", "_blank")
					}
				>
					აბიტურიენტებისთვის ყველა საჭირო კითხვაზე პასუხი მიიღე აქ
				</button>
			</div>
		</div>
	);
};

const ProfOrientation: React.FC<{}> = props => {
	const history = useHistory();
	const user = useClassroomsUser();

	const redirectToProfTest = useCallback(() => {
		triggerEvent(
			{
				category: "Button",
				action: "redirect to prof test",
				label: "prof test",
			},
			{}
		);
		if (user) {
			history.push(`/prof-test`);
		}
	}, [history, user]);

	return (
		<div className={styles.frameContainer} onClick={redirectToProfTest}>
			<ProfSmall style={{ width: 110, height: 107 }} />
		</div>
	);
};

//

const JoinClassroomResponsive: React.FC<{}> = props => {
	const history = useHistory();
	const redirectToJoinClassroom = useCallback(() => {
		triggerEvent(
			{
				category: "Button",
				action: "redirect to mobile join classroom",
				label: "mobile join classroom",
			},
			{}
		);

		history.push(`/mobile-join-classroom`);
	}, [history]);

	return (
		<>
			<div
				onClick={redirectToJoinClassroom}
				className={styles.frameContainer}
			>
				<JoinClassroomSmall style={{ width: 110, height: 107 }} />
			</div>
		</>
	);
};

export const TeacherInvitationPopupMobile: React.FC<{}> = props => {
	const history = useHistory();
	const goBack = React.useCallback(() => {
		history.goBack();
	}, [history]);
	return (
		<div className={styles.touInfoContainer}>
			<div className={styles.touUpperBody}>
				<div className={styles.cancelIconContainer}>
					<div className={styles.cancelIcon} onClick={goBack}>
						<CancelIcon className={styles.cancelIcon} />
					</div>
				</div>
				<JoinClassroomBig style={{ width: "100%" }} />
			</div>
			<div className={styles.sendRequestLowerBody}>
				<TeacherInvitation />
			</div>
		</div>
	);
};
