import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import {
	ITemporaryLinkRequests,
	IVideoLesson,
	TemporaryLinkRequestsSchema,
	ValidVideoLessonResourceTypes,
	VideoLessonResourceTypes,
	VideoLessonSchema,
} from "./helper-schemas";

export const APUTSaveVideoLessonSchema = Joi.object().keys({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
	url: Joi.string()
		.allow("")
		.required(),
});
export interface IAPUTSaveVideoLesson {
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
	url: string;
}

export const RPUTSaveVideoLessonSchema = VideoLessonSchema;
export type IRPUTSaveVideoLesson = IVideoLesson;

///

export const AGETVideoLessonSchema = Joi.object().keys({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
});

export interface IAGETVideoLesson {
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
}

export const RGETVideoLessonSchema = VideoLessonSchema;

export type IRGETVideoLesson = IVideoLesson;

///

export const AGETVideoLessonsManySchema = Joi.object().keys({
	resourceIds: Joi.array().items(Joi.objectId().required()),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
});
export interface IAGETVideoLessonsMany {
	resourceIds: ObjectId[];
	resourceType: VideoLessonResourceTypes;
}

export const RGETVideoLessonsManySchema = Joi.array().items(VideoLessonSchema);

export type IRGETVideoLessonsMany = IVideoLesson[];

///

export const APOSTAddTemporaryVideoLinksSchema = Joi.object().keys({
	links: Joi.array().items(Joi.string()),
});
export interface IAPOSTAddTemporaryVideoLinks {
	links: string[];
}

export const AGETPopTemporaryVideoLinkSchema = Joi.object().keys({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
});
export interface IAGETPopTemporaryVideoLink {
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
}

export const RGETPopTemporaryVideoLinkSchema = VideoLessonSchema.required();

export type IRRGETPopTemporaryVideoLink = IVideoLesson;

export const APOSTAddTemporaryLinkRequestSchema = Joi.object().keys({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
});

export interface IAPOSTAddTemporaryLinkRequest {
	resourceId: ObjectId;
	esourceType: VideoLessonResourceTypes;
}

export const RGETAllTemporaryLinkRequestsSchema = TemporaryLinkRequestsSchema.required();
export type IRGETAllTemporaryLinkRequests = ITemporaryLinkRequests;

///

export const APUTSaveTeacherVisitTimeSchema = Joi.object().keys({
	resourceId: Joi.objectId().required(),
	resourceType: Joi.string()
		.valid(...ValidVideoLessonResourceTypes)
		.required(),
	timeOffset: Joi.number()
		.integer()
		.required(),
});

export interface IAPUTSaveTeacherVisitTime {
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
	timeOffset: number;
}

///

export const AGETGroupsByStudentId = Joi.object({
	studentId: Joi.number().integer(),
});
export interface IAGETGroupsByStudentId {
	studentId?: number;
}
