import React from "react";
import { useWindowSize } from "@app/hooks/front";
import { RightSide } from "./right-side";
import genStyles from "../general_components/styles/general_styles.module.css";
import { TeacherLeftSide } from "./teacher-left-side";
import BrandLogo from "../general_components/logo";
import { MobileLogOut } from "../general_components/mobile_logOut";

const TeacherProfile: React.FC<{}> = props => {
	const { width } = useWindowSize();
	return (
		<section className={genStyles.profile_container}>
			<BrandLogo />
			{width <= 1000 && <MobileLogOut />}
			<TeacherLeftSide />
			{/* <RightSide /> */}
		</section>
	);
};

export default TeacherProfile;
