import {
	IDisplaySideBarPaths,
	ISidebarItem,
} from "@app/components/layouts/sidebar/helpers/interfaces";
import { ObjectId } from "@app/utils/generics";

export enum HeadmasterSidebarType {
	group = "headmaster-group",
}

type IHeadmasterDisplaySideBarPath = IDisplaySideBarPaths<
	HeadmasterSidebarType
>;
export const HeadmasterSidebarItems: IHeadmasterDisplaySideBarPath[] = [
	{
		type: HeadmasterSidebarType.group,
		paths: [
			{
				name: "კლასის ცხრილი",
				href: "/headmaster/group/:groupId/timetable",
			},
			{
				name: "კლასის ჟურნალი",
				href: "/headmaster/group/:groupId/gradebook",
			},
		],
	},
];

export const isHeadmasterSidebarPath = (path: string) => {
	for (let i = 0; i < HeadmasterSidebarItems.length; i++) {
		const element = HeadmasterSidebarItems[i];
		for (let a = 0; a < element.paths.length; a++) {
			const curPath = element.paths[a];
			if (curPath.href === path) {
				return true;
			}
		}
	}
	return false;
};

const getKey = (type: HeadmasterSidebarType) => {
	if (type === HeadmasterSidebarType.group) {
		return "groupId";
	}
};

export const getHeadmasterSidebarItems = ({
	type,
	id,
	isTestUser,
}: {
	type: HeadmasterSidebarType;
	id: ObjectId;
	isTestUser: boolean;
}): ISidebarItem[] => {
	const res: ISidebarItem[] = [];
	const paths =
		HeadmasterSidebarItems.find(e => e.type === type)?.paths || [];
	const key = getKey(type)!;

	for (let i = 0; i < paths.length; i++) {
		const element = paths[i];
		const newItem = {
			name: element.name,
			href: element.href.replace(`:${key}`, id),
		};
		let show = true;
		if (element.isAvaliableForOnlyTestUsers && !isTestUser) {
			show = false;
		}

		if (show) {
			res.push(newItem);
		}
	}
	return res;
};
