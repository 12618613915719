import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { ITopic } from "@app/api/topics/helper-schemas";
import { inject } from "@app/modules";
import { IHierarchyInfo } from "@app/services/hierarchy-info/interfaces";
import { ObjectId } from "@app/utils/generics";
import { History } from "history";
import React, {
	useEffect,
	useReducer,
	useState,
	useCallback,
	useMemo,
} from "react";
import { CreatingAssignmentFirstStep } from "./first-step";
import { CreatingAssignmentSecondStep } from "./second-step";
import { ITaskType } from "@app/api/task-types/helper-schemas";
import { IHWActions, reducer } from "./reducer";
import { useClassroomsUserId } from "@app/hooks/users";
import { BigBackground } from "@app/components/backgrounds";
import { useClassroom } from "@app/hooks/classrooms";
import { addLoader } from "@app/common-javascript";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { ItemType } from "@app/api/folders/helper-schemas";
import { arrayToObject } from "@app/utils/common";
import {
	getNextDayOfClassroom,
	getSortedQuestionIdsFromContentList,
	getNumQuestionsByTaskTypesFromHomework,
	getInitialSortedContent,
} from "./helper-functions";
import { useUnsafeDynamicRef } from "@app/hooks/general";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
export enum ShowAnswersStandardTime {
	deadline = "DEADLINE",
	submission = "SUBMIT",
}

interface IProps {
	history: History;
	classroomId: ObjectId;
}

export interface IHWSettings {
	deadline: Date;
	shuffleQuestions: boolean;
	shuffleAnswers: boolean;
	showAnswersAt: Date | ShowAnswersStandardTime;
}

export interface IHWState {
	name: string;
	totalNumOfQuestions: number;
	topics: ObjectId[];
	settings: IHWSettings;
	classrooms: {
		[_id: string]: boolean;
	};
	taskTypesInAdvancedSettings:
		| {
				_id: ObjectId;
				count: number;
				isFixed: boolean;
				countType: "text" | "question";
				approximateNumOfQuestionsPerText?: number | null;
		  }[]
		| null;
}

export const HomeworkContext = React.createContext<
	[IHWState, React.Dispatch<IHWActions>]
>([
	{} as IHWState,
	(() => {
		//
	}) as any,
]);

export const TopicsContext = React.createContext<{
	topics: ITopic[];
	hierarchyInfo: IHierarchyInfo;
} | null>(null);

export const TaskTypesContext = React.createContext<{
	taskTypes: ITaskType[];
	hierarchyInfo: IHierarchyInfo;
} | null>(null);

export const ClassroomsContext = React.createContext<IClassroom[] | null>(null);

export interface SortedContentSingleItem {
	type: "single";
	itemType: ItemType.question;
	itemId: ObjectId;
}

export interface SortedContentNestedItem {
	type: "nested";
	itemType: ItemType.text;
	itemId: ObjectId;
	childItems: SortedContentSingleItem[];
}

export type SortedContentItem =
	| SortedContentSingleItem
	| SortedContentNestedItem;

export type SortedContentList = SortedContentItem[];

export const SortedContentListContext = React.createContext<
	[SortedContentList, React.Dispatch<React.SetStateAction<SortedContentList>>]
>([] as any);

export interface Content {
	questions: Record<string, IFullQuestion | undefined>;
	texts: Record<string, IText | undefined>;
}

export const initialEmptyContent: Content = { questions: {}, texts: {} };

export const ContentContext = React.createContext<
	[Content, (additionalContent: Partial<Content>) => Content]
>([
	initialEmptyContent,
	(() => {
		//
	}) as any,
]);

//TODO: Fix this later
// eslint-disable-next-line max-lines-per-function
export const CreateHomework: React.FC<IProps> = props => {
	const classroom = useClassroom(props.classroomId);
	const courseId = classroom.doc ? classroom.doc.course.courseId : null;
	const userId = useClassroomsUserId();

	const [page, setPage] = useState(0);
	const [topicsInfo, setTopicsInfo] = useState<{
		topics: ITopic[];
		hierarchyInfo: IHierarchyInfo;
	} | null>(null);
	const [taskTypesInfo, setTaskTypesInfo] = useState<{
		taskTypes: ITaskType[];
		hierarchyInfo: IHierarchyInfo;
	} | null>(null);

	const [classrooms, setClassrooms] = useState<IClassroom[] | null>(null);
	const contentListInfo = useState<SortedContentList>([]);
	const [content, setContent] = useState<Content>(initialEmptyContent);
	const contentRef = useUnsafeDynamicRef(content);

	const addExtraContent = useCallback(
		(extraContent: Content): Content => {
			const newContent = {
				questions: {
					...contentRef.current.questions,
					...extraContent.questions,
				},
				texts: {
					...contentRef.current.texts,
					...extraContent.texts,
				},
			};
			setContent(newContent);
			return newContent;
		},
		[contentRef]
	);

	const setContentList = contentListInfo[1];

	const homeworkAndDispatch = useReducer(reducer, {
		name: getFormattedMessage("teacher:assignment.assignmentName", {
			count: 1,
		}),
		totalNumOfQuestions: 20,
		topics: [],
		settings: {
			showAnswersAt: ShowAnswersStandardTime.deadline,
			shuffleAnswers: true,
			shuffleQuestions: true,
			deadline: new Date("2020"),
		},
		classrooms: {
			[props.classroomId]: true,
		},
		taskTypesInAdvancedSettings: null,
	});

	const [isAssignmentCountLoaded, setIsAssignmentCountLoaded] = useState(
		false
	);
	const [areMainInfoLoaded, setAreMainInfoLoaded] = useState(false);
	const dispatch = homeworkAndDispatch[1];
	const [isSecondStepSubmitted, setIsSecondStepSubmitted] = useState(false);

	useEffect(() => {
		const AssignmentsController = inject("AssignmentsController");
		AssignmentsController.getClassroomCount({
			classroomId: props.classroomId,
		}).then(({ count }) => {
			dispatch({
				type: "CHANGE_NAME",
				value: getFormattedMessage(
					"teacher:assignment.assignmentName",
					{ count: count + 1 }
				),
			});
			setIsAssignmentCountLoaded(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.classroomId]);

	useEffect(() => {
		if (!classroom.isSuccessfullyLoaded) return;
		const deadline: Date = getNextDayOfClassroom(classroom.doc.classTimes);
		console.log(deadline);
		dispatch({
			type: "CHANGE_HW_DEADLINE",
			date: deadline,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classroom, dispatch]);

	const onHomeworkSubmit = useCallback(() => {
		let isCandeled = false;
		const AssignmentsController = inject("AssignmentsController");
		const homework = homeworkAndDispatch[0];
		const classroomIds = Object.keys(homework.classrooms).filter(
			classroomId => !!homework.classrooms[classroomId]
		);
		if (classroomIds.length === 0) {
			openConfirmationPopup({ text: "აირჩიეთ მინიმუმ 1 კლასი" });
			return;
		}
		setIsSecondStepSubmitted(true);
		AssignmentsController.create({
			name: homework.name,
			settings: homework.settings,
			questions: getSortedQuestionIdsFromContentList(contentListInfo[0]),
			classroomIds,
		}).then(() => {
			if (isCandeled) return;
			setIsSecondStepSubmitted(false);
			props.history.push(`/classrooms/${props.classroomId}`);
		});
		return () => {
			isCandeled = true;
		};
	}, [
		homeworkAndDispatch,
		props.classroomId,
		props.history,
		contentListInfo,
	]);

	useEffect(() => {
		if (!userId || !courseId) return;
		const TopicsController = inject("TopicsController");
		const TaskTypesController = inject("TaskTypesController");
		const TaskTypeHierarchyService = inject("TaskTypeHierarchyService");
		const TopicHierarchyService = inject("TopicHierarchyService");
		const ClassroomsController = inject("ClassroomsController");
		Promise.all([
			TopicsController.getAllByCourseId({ courseId }),
			ClassroomsController.getAll({ teacherId: userId }),
			TaskTypesController.getAllByCourseId({ courseId }),
			TaskTypesController.getHierarchy({ courseId }),
			TopicsController.getHierarchy({ courseId }),
		]).then(([topics, classrooms, taskTypes]) => {
			const topicHierarchyInfo = TopicHierarchyService.getHierarchyInfoObjectSync(
				courseId
			);
			setTopicsInfo({
				hierarchyInfo: topicHierarchyInfo,
				topics,
			});
			setClassrooms(classrooms);
			const taskTypeHierarchyInfo = TaskTypeHierarchyService.getHierarchyInfoObjectSync(
				courseId
			);
			const leafTaskTypes = TaskTypeHierarchyService.getLeafIdsSync(
				courseId
			);
			setTaskTypesInfo({
				hierarchyInfo: taskTypeHierarchyInfo,
				taskTypes: taskTypes.filter(taskType =>
					leafTaskTypes.has(taskType._id)
				),
			});
			setAreMainInfoLoaded(true);
		});
	}, [courseId, setContentList, userId]);

	const OnGotoNextSecondPage = useCallback(() => {
		const homework = homeworkAndDispatch[0];
		const removeLoader = addLoader();
		const AssignmentsController = inject("AssignmentsController");
		const numQuestionsByTaskTypes = getNumQuestionsByTaskTypesFromHomework(
			homework.taskTypesInAdvancedSettings
		);
		const taskTypes = Object.keys(numQuestionsByTaskTypes || {});
		AssignmentsController.getTaskTypeContents({
			classroomId: props.classroomId,
			getAnswers: true,
			numQuestions: {
				total: homework.totalNumOfQuestions,
				byTaskTypes: numQuestionsByTaskTypes,
			},
			topicIds: homework.topics.length > 0 ? homework.topics : undefined,
			taskTypes: taskTypes.length > 0 ? taskTypes : undefined,
		})
			.then(data => {
				setContent({
					questions: arrayToObject(
						data.questions as IFullQuestion[],
						"_id"
					),
					texts: arrayToObject(data.texts, "_id"),
				});
				setContentList(
					getInitialSortedContent({
						questions: data.questions as IFullQuestion[],
						texts: data.texts,
					})
				);
				removeLoader();
				window.scroll(0, 0);
				setPage(1);
			})
			.catch(removeLoader);
	}, [homeworkAndDispatch, props.classroomId, setContentList]);

	const contextProviderValue = useMemo(
		() =>
			[content, addExtraContent] as [
				Content,
				(additionalContent: Content) => Content
			],
		[addExtraContent, content]
	);

	return (
		<div>
			<HomeworkContext.Provider value={homeworkAndDispatch}>
				<TopicsContext.Provider value={topicsInfo}>
					<TaskTypesContext.Provider value={taskTypesInfo}>
						<ClassroomsContext.Provider value={classrooms}>
							<BigBackground bgColor="#e7efff" />
							{!isAssignmentCountLoaded || !areMainInfoLoaded ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<SvgloadingWhite />
								</div>
							) : (
								<>
									{page === 0 && (
										<CreatingAssignmentFirstStep
											onNextPage={OnGotoNextSecondPage}
											classroomId={props.classroomId}
										/>
									)}
									<ContentContext.Provider
										value={contextProviderValue}
									>
										<SortedContentListContext.Provider
											value={contentListInfo}
										>
											{page === 1 && courseId && (
												<CreatingAssignmentSecondStep
													classroomId={
														props.classroomId
													}
													onPreviousPage={() =>
														setPage(0)
													}
													onHomeworkSubmit={
														onHomeworkSubmit
													}
													isSecondStepSubmitted={
														isSecondStepSubmitted
													}
													courseId={courseId}
												/>
											)}
										</SortedContentListContext.Provider>
									</ContentContext.Provider>
								</>
							)}
						</ClassroomsContext.Provider>
					</TaskTypesContext.Provider>
				</TopicsContext.Provider>
			</HomeworkContext.Provider>
		</div>
	);
};
