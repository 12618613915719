import Popup from "@app/components/widgets/popup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Delete from "@material-ui/icons/DeleteForever";
import FindReplace from "@material-ui/icons/FindReplace";
import OpenWith from "@material-ui/icons/OpenWith";
import classNames from "classnames";
import React, { useMemo, useEffect, useCallback, useContext } from "react";
import styles from "../../styles/styles.module.css";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { useBoolean } from "@app/hooks/general";
import { IQuestionContent } from "@tests-core/schemas/questions/contnets/schemas";
import QuestionContentTestMode, {
	IQContentPassableProps,
} from "@tests-core/components/questions/contents";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import { mergeRecursive, arrayToObject } from "@app/utils/common";
import {
	SortedContentList,
	SortedContentNestedItem,
	ContentContext,
	SortedContentListContext,
} from "..";
import { DropResult } from "react-beautiful-dnd";
import { SingleContentsSortableList } from "./questions-sections";
import { inject } from "@app/modules";
import { addLoader } from "@app/common-javascript";
import { ObjectId } from "@app/utils/generics";
import { getInsertedContent } from "../helper-functions";
import { useLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";

export interface IQuestionContentContainerProps {
	question: IFullQuestion;
	contentItem: SortedContentList[number];
	onItemRemove: (content: SortedContentList[number]) => void;
	showContent?: boolean;
	handleQuestionReplaceShow?: (content: SortedContentList[number]) => void;
}

export const QuestionContentContainer: React.FC<IQuestionContentContainerProps> = React.memo(
	props => {
		const locale = useLocale();
		const {
			value: isDeletePopupVisible,
			setTrue: showDeletePopup,
			setFalse: hideDeletePopup,
		} = useBoolean(false);

		const {
			value: isQuestionContentShown,
			setTrue: showQuestionContent,
			setFalse: hideQuestionContent,
			setValue: setQuestionContentVisibility,
			switchValue: switchQuestions,
		} = useBoolean(false);

		useEffect(() => {
			setQuestionContentVisibility(!!props.showContent);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [!!props.showContent]);

		const customizedProps = useMemo(
			() => getQuestionsCustomizedPropsForTeacher(locale),
			[locale]
		);

		const qContent = useMemo(() => {
			return newContent(props.question.content);
		}, [props.question.content]);

		return (
			<>
				<div>
					<div className={styles.questionContainer}>
						<OpenWith
							className={classNames(
								styles.iconsStyles,
								styles.openWith
							)}
						/>
						<QuestionContent
							content={qContent}
							isContentFullyVisible={isQuestionContentShown}
							className={styles.textStyles}
							customizedProps={customizedProps}
							onClick={switchQuestions}
						/>
						<div className={styles.iconsContainer}>
							{!isQuestionContentShown ? (
								<Visibility
									className={classNames(
										styles.iconsStyles,
										styles.create
									)}
									onClick={showQuestionContent}
								/>
							) : (
								<VisibilityOff
									className={classNames(
										styles.iconsStyles,
										styles.create
									)}
									onClick={hideQuestionContent}
								/>
							)}
							{props.handleQuestionReplaceShow && (
								<FindReplace
									className={findAndReplaceClassName}
									onClick={() =>
										props.handleQuestionReplaceShow!(
											props.contentItem
										)
									}
								/>
							)}
							<Delete
								className={deleteIconClassName}
								onClick={showDeletePopup}
							/>
						</div>
					</div>
				</div>
				{isDeletePopupVisible && (
					<DeletionConfirmPopup
						onDelete={props.onItemRemove}
						onClose={hideDeletePopup}
						contentItem={props.contentItem}
					/>
				)}
			</>
		);
	}
);

export const getQuestionsCustomizedPropsForTeacher = (
	locale: string,
	isViewingForOneStudent = false
) =>
	mergeRecursive(
		getQuestionsCustomizedProps(locale, false, isViewingForOneStudent),
		{
			fbProps: {
				defaultViewMode: "correctAnswer",
				hideViewMode: true,
			},
			siProps: {
				defaultViewMode: "correctAnswer",
				hideViewMode: true,
			},
		} as IQContentPassableProps
	);

export const QuestionContent: React.FC<{
	content: IQuestionContent;
	isContentFullyVisible: boolean;
	className?: string;
	customizedProps?: IQContentPassableProps;
	onClick: () => void;
}> = React.memo(props => {
	const onUserAnswerChange = useCallback(() => {
		//
	}, []);

	if (!props.isContentFullyVisible) {
		return (
			<div
				className={
					props.className ? props.className : styles.textStyles
				}
				onClick={props.onClick}
				style={{ flex: 1 }}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: props.content.getShortStat(),
					}}
				/>
			</div>
		);
	}

	return (
		<div
			className={styles.textStyles}
			style={{ flex: 1 }}
			onClick={props.onClick}
		>
			<QuestionContentTestMode
				content={props.content}
				displayAnswer={true}
				onUserAnswerChange={onUserAnswerChange}
				customized={props.customizedProps}
				shuffleKey={0}
			/>
		</div>
	);
});

const DeletionConfirmPopup: React.FC<{
	onDelete: (contentItem: SortedContentList[number]) => void;
	contentItem: SortedContentList[number];
	onClose: () => void;
}> = React.memo(props => {
	return (
		<Popup onClose={props.onClose}>
			<div className={styles.popupContent} style={{ padding: 20 }}>
				<div style={{ padding: 30 }}>
					{getFormattedMessage("deleteQuestion")}
				</div>
				<div
					onClick={props.onClose}
					className={classNames(styles.buttonStyles, styles.no)}
					style={{ marginRight: 10 }}
				>
					არა
				</div>
				<div
					onClick={() => {
						props.onClose();
						props.onDelete(props.contentItem);
					}}
					className={classNames(styles.buttonStyles, styles.yes)}
				>
					დიახ
				</div>
			</div>
		</Popup>
	);
});

export const TextContainer: React.FC<{
	contentItem: SortedContentNestedItem;
	onDragEnd: (result: DropResult) => void;
	onItemRemove: (content: SortedContentList[number]) => void;
	handleQuestionReplaceShow?: (content: SortedContentList[number]) => void;
	partitionId: number;
	courseId: ObjectId;
}> = React.memo(props => {
	const [content] = useContext(ContentContext);

	const {
		value: isDeletePopupVisible,
		setTrue: showDeletePopup,
		setFalse: hideDeletePopup,
	} = useBoolean(false);

	const [, addExtraContent] = useContext(ContentContext);
	const [, setSortedContent] = useContext(SortedContentListContext);

	const textId = props.contentItem.itemId;
	const text = content.texts[textId];

	const missinQuestions = useMemo(
		() =>
			text
				? text.questions.filter(
						e =>
							!props.contentItem.childItems.find(
								a => a.itemId === e
							)
				  )
				: [],
		[props.contentItem.childItems, text]
	);

	const addMissingQuestionsOfText = useCallback(() => {
		const QuestionsController = inject("QuestionsController");
		const removeLoader = addLoader();
		QuestionsController.getBulk({
			courseId: props.courseId,
			getAnswers: true,
			ids: missinQuestions,
		})
			.then(questions => {
				const content = addExtraContent({
					questions: arrayToObject(
						questions as IFullQuestion[],
						"_id"
					),
				});
				setSortedContent(contentList =>
					getInsertedContent({
						contentList,
						selectedContent: {
							questions: missinQuestions.filter(
								qId => !!questions.find(e => e._id === qId)
							),
							texts: [],
						},
						content,
					})
				);
				removeLoader();
			})
			.catch(() => {
				removeLoader();
			});
	}, [addExtraContent, missinQuestions, props.courseId, setSortedContent]);
	if (!text) return null;

	return (
		<>
			<div style={{ padding: 10 }}>
				<div className={styles.textCotentContainer}>
					<div className={styles.textCotent}>
						<div style={{ textAlign: "right" }}>
							{props.handleQuestionReplaceShow && (
								<FindReplace
									className={findAndReplaceClassName}
									onClick={() =>
										props.handleQuestionReplaceShow!(
											props.contentItem
										)
									}
								/>
							)}
							<Delete
								className={deleteIconClassName}
								onClick={showDeletePopup}
							/>
						</div>
						<div dangerouslySetInnerHTML={{ __html: text.text }} />
					</div>
					<div>
						<SingleContentsSortableList
							onDragEnd={props.onDragEnd}
							onItemRemove={props.onItemRemove}
							partitionId={props.partitionId}
							singleContents={props.contentItem.childItems}
						/>
					</div>
					{missinQuestions.length > 0 && (
						<div
							className={styles.addMissingQuestionsOfText}
							onClick={addMissingQuestionsOfText}
						>
							ტექსტი არასრულადაა წარმოდგენილი.{" "}
							<span>
								დაამატეთ {missinQuestions.length} კითხვა
							</span>
						</div>
					)}
				</div>
			</div>
			{isDeletePopupVisible && (
				<DeletionConfirmPopup
					onDelete={props.onItemRemove}
					onClose={hideDeletePopup}
					contentItem={props.contentItem}
				/>
			)}
		</>
	);
});

const findAndReplaceClassName = classNames(
	styles.iconsStyles,
	styles.findReplace
);

const deleteIconClassName = classNames(styles.iconsStyles, styles.delete);
