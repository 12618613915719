import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const FacebookVideoSchema = Joi.object({
	_id: Joi.objectId().required(),
	videoHTML: Joi.string().required(),
	title: Joi.string().optional(),
	href: Joi.string().optional(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IFacebookVideo {
	_id: ObjectId;
	videoHTML: string;
	title?: string;
	href?: string;
	createdAt: Date;
	updatedAt: Date;
}
