import { MenuOptions } from "@app/components/widgets/hoverable-select";
import { useClassroom } from "@app/hooks/classrooms";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { inject } from "@app/modules";
import { IAnyObj } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import classNames from "classnames";
import React, { useCallback, useMemo, useContext } from "react";
import { ReactComponent as HubuLogo } from "../../landing/styles/img/hubu-logo.svg";
import { ReactComponent as Hubu } from "../../landing/styles/img/Hubu.svg";
import { ReactComponent as LupiLogo } from "../../landing/styles/img/lupi-ai.svg";
import HeaderStyles from "../../users/header/styles/header.module.css";
import { ReactComponent as UserIcon } from "../../users/styles/img/user.svg";
import styles from "../classrooms/styles/classroom-page.module.css";
import TeacherHeaderStyles from "./styles/teacher-header.module.css";
import { GeneralContext } from "@app/components/general-context";
import { WebsiteOrigin, getCurrentWebsite } from "@app/globals";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { romanize } from "@app/utils/common";
import { ReactComponent as VideoIcon } from "./styles/imgs/video.svg";
import { VideoInstruction } from "@app/components/tv-school-landing/video-instruction";
import { ReactComponent as BurgerIcon } from "./styles/imgs/Burger.svg";
import { Calls, CallsPopup } from "@app/components/widgets/calls";
import { FormattedMessage } from "react-intl";
import { ReactComponent as TVschoolNLIcon } from "./styles/imgs/Logo.svg";
import { useClassroomsUser } from "@app/hooks/users";
import { useLocation } from "react-router-dom";

// TODO: Fix this later
// eslint-disable-next-line max-lines-per-function
export const TeacherHeader: React.FC<IAnyObj> = props => {
	const loc = useLocation();
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const isEng = locale === "en";
	const history = useHistory();
	const { width } = useWindowSize();
	const gotoMainPage = useCallback(() => {
		history.push(`/`);
	}, [history]);

	const gotoFaqPage = useCallback(() => {
		history.push(`/faq`);
	}, [history]);

	const classroom = useClassroom(props.classroomId || null);
	const currentWebsite = getCurrentWebsite();

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const classroomName =
		props.classroomId &&
		classroom.isSuccessfullyLoaded &&
		(currentWebsite === WebsiteOrigin.tvSchool
			? `${romanize(classroom.doc.grade)}  ${getFormattedMessage(
					"inputs.grade"
			  )}`
			: classroom.doc.name);

	const {
		value: isVideoInstructioVissible,
		setFalse: hideVideoInstruction,
		setTrue: setVideoInstructionVissible,
	} = useBoolean(false);

	const isResponsive = width < 1100;
	const isMobile = width < 930;
	const isVideoInstructionButtonInvissible = isNl;

	const videoInstructionButton = isVideoInstructionButtonInvissible ? null : (
		<div
			className={TeacherHeaderStyles.videoInstructionButton}
			onClick={setVideoInstructionVissible}
		>
			<VideoIcon className={TeacherHeaderStyles.videoIcon} />

			{!isMobile && (
				<>
					{isResponsive ? (
						<div>
							<FormattedMessage id="instruction" />
						</div>
					) : (
						<>
							<div>
								<FormattedMessage id="videoInstruction" />
							</div>
						</>
					)}
				</>
			)}
		</div>
	);

	const videoId = isEng ? "-RLUtRSKc-4" : "zTIoUoGzzqE";

	const faqButton = (
		<div
			className={TeacherHeaderStyles.videoInstructionButton}
			onClick={gotoFaqPage}
		>
			{!isMobile && (
				<>
					<div>
						<FormattedMessage id="faq" />
					</div>
				</>
			)}
		</div>
	);

	const showValidateNumeberNotification = useMemo(
		() => !loc.pathname.includes("profile"),
		[loc.pathname]
	);

	const showValidateNumeberTextNotification = useMemo(
		() => loc.pathname === "/",
		[loc.pathname]
	);

	if (isHeaderHidden) return null;

	return (
		<>
			<div
				style={
					props.backgroundColor
						? {
								backgroundColor: props.backgroundColor,
						  }
						: { backgroundColor: "#5273e6" }
				}
				className={
					currentWebsite === WebsiteOrigin.tvSchool
						? TeacherHeaderStyles.header
						: width > 500
						? HeaderStyles.container
						: classNames(
								HeaderStyles.container,
								TeacherHeaderStyles.container
						  )
				}
			>
				<div className={TeacherHeaderStyles.logoWithCalls}>
					<div className={HeaderStyles.logo} onClick={gotoMainPage}>
						{(currentWebsite === WebsiteOrigin.hubu ||
							currentWebsite === WebsiteOrigin.hubuExams) &&
							width >= 500 && (
								<HubuLogo
									style={{ height: "100%", width: 112 }}
								/>
							)}
						{currentWebsite === WebsiteOrigin.tvSchool && (
							<>
								{isGeo ? (
									<TVSchoolLogo
										className={
											TeacherHeaderStyles.tvschoolLogo
										}
									/>
								) : isNl ? (
									<TVschoolNLIcon
										className={
											TeacherHeaderStyles.tvschoolLogo
										}
									/>
								) : (
									<LupiLogo
										style={{ height: "100%", width: 112 }}
									/>
								)}
							</>
						)}{" "}
						{currentWebsite === WebsiteOrigin.hubu && width < 500 && (
							<Hubu
								style={{
									height: "100%",
									width: 50,
									color: "white",
								}}
							/>
						)}
					</div>
					{isGeo && (
						<div className={TeacherHeaderStyles.callsContainer}>
							<Calls />
						</div>
					)}
				</div>

				<div
					className={
						TeacherHeaderStyles.inviteStudentsInHeaderAndUserLogoContainer
					}
				>
					{/* <RequestTest /> */}
					{currentWebsite === WebsiteOrigin.tvSchool && !isMobile && (
						<>
							{videoInstructionButton}
							{faqButton}
						</>
					)}
					{width < 600 &&
						props.classroomId &&
						classroom.isSuccessfullyLoaded && (
							<div className={styles.classroomName}>
								{classroomName}
							</div>
						)}
					<div>
						<TeacherOptions
							isTvschool={
								WebsiteOrigin.tvSchool && isMobile && isGeo
							}
							showValidateNumeberNotification={
								showValidateNumeberNotification
							}
							showValidateNumeberTextNotification={
								showValidateNumeberTextNotification
							}
							isVideoInstructionVissible={true}
						/>
					</div>
				</div>
			</div>
			{isVideoInstructioVissible && (
				<VideoInstruction
					onClose={hideVideoInstruction}
					videoId={videoId}
				/>
			)}
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};

enum UserOption {
	profile,
	logout,
}

const useTeacherHeaderOptions = () => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: UserOption.profile,
				label: getFormattedMessage("profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

enum TvschoolUserOption {
	profile,
	logout,
	videoInstruction,
	faq,
	calls,
	assignments,
}

const useTvschoolTeacherHeaderOptions = (
	isAllAssignmentsAndNotProfileInfo?: boolean
) => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: TvschoolUserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: TvschoolUserOption.calls,
				label: "ცხელი ხაზი",
			},
			{
				value: TvschoolUserOption.videoInstruction,
				label: getFormattedMessage("instruction"),
			},
			{
				value: TvschoolUserOption.faq,
				label: getFormattedMessage("faq"),
			},
			{
				value: isAllAssignmentsAndNotProfileInfo
					? TvschoolUserOption.assignments
					: TvschoolUserOption.profile,
				label: isAllAssignmentsAndNotProfileInfo
					? getFormattedMessage("teacher:createAssignment")
					: getFormattedMessage("profile"),
			},
			{
				value: TvschoolUserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const TeacherOptions: React.FC<{
	isTvschool?: boolean;
	isVideoInstructionVissible?: boolean;
	showValidateNumeberNotification?: boolean;
	showValidateNumeberTextNotification?: boolean;
	icon?: JSX.Element;
	isAllAssignmentsAndNotProfileInfo?: boolean;
}> = props => {
	const videoId = useLocale() === "en" ? "-RLUtRSKc-4" : "zTIoUoGzzqE";
	const history = useHistory();
	const userHeaderOptions = useTeacherHeaderOptions();
	const tvschoolHeadetOptions = useTvschoolTeacherHeaderOptions(
		props.isAllAssignmentsAndNotProfileInfo
	);
	const {
		value: isOptionsVisible,
		setTrue: showOptions,
		setFalse: hideOptions,
	} = useBoolean(false);

	const user = useClassroomsUser()!;

	const hasNotVarifiedMobile = useLocale() === "ka" && !user.isValidMobile;

	const showValidateNumeberTextNotification =
		props.showValidateNumeberTextNotification && !isOptionsVisible;

	const { width } = useWindowSize();
	const gotoFaqPage = useCallback(() => {
		history.push(`/faq`);
	}, [history]);

	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				///
			});
		history.push(`/`);
	}, [history]);

	const onGoToProfile = useCallback(() => history.push(`/profile`), [
		history,
	]);

	const gotoNewAssignmentsPage = useCallback(() => {
		history.push(`/assignments/new`);
	}, [history]);

	const {
		value: isCallsPopupOpen,
		setTrue: setCallsPopupOpen,
		setFalse: closeCallsPopup,
	} = useBoolean(false);

	const {
		value: isVideoInstructioVissible,
		setFalse: hideVideoInstruction,
		setTrue: setVideoInstructionVissible,
	} = useBoolean(false);

	const onSelect = useCallback(
		(option: {
			value: UserOption | TvschoolUserOption;
			label: JSX.Element | string;
		}) => {
			if (option.value === UserOption.profile) {
				onGoToProfile();
			} else if (option.value === UserOption.logout) {
				onLogout();
			} else if (option.value === TvschoolUserOption.faq) {
				gotoFaqPage();
			} else if (
				option.value === TvschoolUserOption.videoInstruction &&
				props.isVideoInstructionVissible
			) {
				setVideoInstructionVissible();
			} else if (option.value === TvschoolUserOption.calls) {
				setCallsPopupOpen();
			} else if (option.value === TvschoolUserOption.assignments) {
				gotoNewAssignmentsPage();
			}
		},
		[
			onGoToProfile,
			onLogout,
			gotoFaqPage,
			setCallsPopupOpen,
			setVideoInstructionVissible,
			props.isVideoInstructionVissible,
			gotoNewAssignmentsPage,
		]
	);

	const menuStyles = useMemo(
		(): React.CSSProperties => ({
			right: 0,
			width: "max-content",
			fontFamily: "Roboto Geo Nus",
			color: "#5273e6",
			minWidth: 150,
			fontSize: 14,
		}),
		[]
	);

	const isMobile = width < 930;

	return (
		<>
			<div>
				<div onMouseEnter={showOptions} onMouseLeave={hideOptions}>
					{props.icon ? (
						<div onClick={showOptions}> {props.icon} </div>
					) : isMobile && props.isTvschool ? (
						<BurgerIcon
							onClick={width > 1200 ? onGoToProfile : showOptions}
							className={TeacherHeaderStyles.burgerIconContainer}
						/>
					) : (
						<div
							className={
								width > 1024
									? HeaderStyles.teacherUserICon
									: classNames(
											HeaderStyles.teacherUserICon,
											TeacherHeaderStyles.teacherIcon
									  )
							}
							onClick={width > 1200 ? onGoToProfile : showOptions}
							style={{
								backgroundColor: !isOptionsVisible
									? "#5273e6"
									: "white",
							}}
						>
							<UserIcon
								style={{
									fill: isOptionsVisible
										? "#5273e6"
										: "white",
								}}
							/>
							{hasNotVarifiedMobile &&
								props.showValidateNumeberNotification && (
									<div
										onMouseEnter={hideOptions}
										onClick={onGoToProfile}
										className={
											TeacherHeaderStyles.alertIconContainer
										}
									>
										!
									</div>
								)}
						</div>
					)}

					{isOptionsVisible && (
						<MenuOptions
							onSelect={onSelect}
							options={
								props.isTvschool
									? tvschoolHeadetOptions
									: userHeaderOptions
							}
							style={menuStyles}
						/>
					)}
					{isCallsPopupOpen && (
						<CallsPopup onClose={closeCallsPopup} />
					)}
				</div>
				{hasNotVarifiedMobile &&
					showValidateNumeberTextNotification && (
						<div
							className={
								TeacherHeaderStyles.mobileVerificationWarning
							}
						>
							გთხოვთ, დაადასტუროთ მობილურის ნომერი
						</div>
					)}
			</div>
			{isVideoInstructioVissible && (
				<VideoInstruction
					onClose={hideVideoInstruction}
					videoId={videoId}
				/>
			)}
		</>
	);
};
