import "react-day-picker/lib/style.css";
import Create from "@material-ui/icons/Create";
import React, { useState } from "react";
import DayPicker from "react-day-picker";
import styles from "../../styles/styles.module.css";
import { ObjectId } from "@app/utils/generics";
import { FormattedMessage } from "react-intl";
import { useMonths, useDays, useDaysShort } from "@app/hooks/dates";
import { addLeadingZeroes } from "@app/common-javascript";
import { useLocale } from "@app/hooks/intl";
import { TimePicker } from "@app/components/widgets/time-picker";

interface IDeadlineProps {
	date: Date;
	onDateChange: (date: Date) => void;
	classroomId: ObjectId;
}

export const Deadline = React.memo((props: IDeadlineProps) => {
	const [isChangingDate, setIsChangingDate] = useState(false);
	const locale = useLocale();

	const months = useMonths();
	const days = useDays();
	const daysSHort = useDaysShort();

	const onDateChange = () => {
		setIsChangingDate(!isChangingDate);
	};

	const modifiers = {
		selected: props.date,
	};
	const modifiersStyles = {
		selected: {
			color: "white",
			backgroundColor: "#5c5de8",
			outline: "none",
		},
	};

	return (
		<div className={styles.dateAndChangeDeadline}>
			<div className={styles.deadlineTextAndDate}>
				<div className={styles.deadlineText}>
					<FormattedMessage id="deadline" />
				</div>
				<div className={styles.deadlineDateAndTimeContainer}>
					<div className={styles.dateContainer}>
						{`
						${new Date(props.date).getDate()} 
							${months[new Date(props.date).getMonth()].substr(0, 3)},
							${days[props.date.getDay()]}
						`}
					</div>
					<div className={styles.hoursContainer}>
						<span>
							{addLeadingZeroes(props.date.getHours(), 2)}
						</span>
						:
						<span>
							{addLeadingZeroes(props.date.getMinutes(), 2)}
						</span>
					</div>

					<Create
						style={{ fontSize: 18, marginTop: -4 }}
						className={styles.createIcon}
						onClick={onDateChange}
					/>
				</div>
			</div>

			{isChangingDate && (
				<div className={styles.changeDeadlineContainer}>
					<DayPicker
						modifiers={modifiers}
						modifiersStyles={modifiersStyles}
						selectedDays={props.date}
						onDayClick={(day: Date) => props.onDateChange(day)}
						locale={locale}
						months={months}
						weekdaysLong={days}
						weekdaysShort={daysSHort}
						firstDayOfWeek={1}
					/>

					<TimePicker
						className={styles.timeStyles}
						value={props.date}
						onChange={date => props.onDateChange(date)}
						showSecond={false}
					/>

					<div className={styles.buttonStyles} onClick={onDateChange}>
						<FormattedMessage id="formDoneCTA" />
					</div>
				</div>
			)}
		</div>
	);
});

interface ITime {
	addon: any;
}
export const Time: React.FC<ITime> = props => {
	return (
		<div style={{ cursor: "pointer" }} onClick={() => props.addon.close()}>
			<button className={styles.calendarButton}>
				<FormattedMessage id="formCompleteCTA" />
			</button>
		</div>
	);
};
