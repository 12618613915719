import React from "react";
import { History } from "history";
import { match } from "react-router-dom";
import { useClassroomsUser } from "@app/hooks/users";
import { isTestUser } from "@app/utils/testing";
import { ObjectId } from "@app/utils/generics";
import { useSetIsNewSidebarVisible } from "@app/components/general-context";
import { useMemo } from "react";
import { SideBar } from "@app/components/layouts/sidebar";
import {
	isHeadmasterSidebarPath,
	getHeadmasterSidebarItems,
	HeadmasterSidebarType,
} from "./sidebar-items";

export const HeadmasterSideBarWrapper = React.memo<{
	history: History;
	match: match<any>;
}>(({ history, match }) => {
	const user = useClassroomsUser()!;
	const isTest = isTestUser(user);
	const groupId: ObjectId | null = match.params.groupId || null;
	const displaySideBar = !!groupId && isHeadmasterSidebarPath(match.path);
	useSetIsNewSidebarVisible(displaySideBar);

	const items = useMemo(() => {
		if (groupId) {
			return getHeadmasterSidebarItems({
				type: HeadmasterSidebarType.group,
				id: groupId,
				isTestUser: isTest,
			});
		}
	}, [groupId, isTest]);

	if (!displaySideBar || !items) {
		return null;
	}

	return <SideBar items={items} history={history} match={match} />;
});
