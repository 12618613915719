import { UserType } from "@app/api/helper-schemas";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import UserRoute from "@app/routes/containers/user-route";
import React from "react";
import { createRouteList } from "../config";

const commonTeacherRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.teacher,
	},
});

const hubuTeacherRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.teacher,
		availableWebsite: WebsiteOrigin.hubu,
	},
});

const tvsChoolTeacherRoutes = createRouteList({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		availableUserTypes: UserType.teacher,
		availableWebsite: WebsiteOrigin.tvSchool,
	},
});

///

const TVSchoolClassroomPage = React.lazy(() =>
	import("../../components/teachers/main/tvschool/in-classroom").then(
		module => ({
			default: module.TVSchoolClassroomPage,
		})
	)
);

const ClassroomPage = React.lazy(() =>
	import("../../components/teachers/classrooms").then(module => ({
		default: module.ClassroomPage,
	}))
);

commonTeacherRoutes.add({
	path: "/classrooms/:classroomId",
	component:
		getCurrentWebsite() === WebsiteOrigin.hubu
			? ClassroomPage
			: TVSchoolClassroomPage,
});

///

tvsChoolTeacherRoutes.add({
	path: "/classrooms/:classroomId/videoURL/:videoURL",
	component: TVSchoolClassroomPage,
	routeProps: {
		availableWebsite: WebsiteOrigin.tvSchool,
	},
});

///

const CourseTestsList = React.lazy(() =>
	import("../../components/teachers/main/course-tests-list").then(module => ({
		default: module.CourseTestsList,
	}))
);

commonTeacherRoutes.add({
	path: "/courses/:courseId/items",
	component: CourseTestsList,
});

///

const TestView = React.lazy(() =>
	import("../../components/teachers/main/test-view").then(module => ({
		default: module.TestView,
	}))
);

commonTeacherRoutes.add({
	path: "/courses/:courseId/items/folders/:folderId/tests/:testId/view",
	component: TestView,
});

///

const Statistics = React.lazy(() =>
	import("../../components/teachers/statistics/statistics").then(module => ({
		default: module.Statistics,
	}))
);

hubuTeacherRoutes.add({
	path: "/classrooms/:classroomId/students/:studentId/statistics",

	component: Statistics,
});

///
const TeacherAssignmentReview = React.lazy(() =>
	import("../../components/teachers/assignments/review").then(module => ({
		default: module.TeacherAssignmentReview,
	}))
);

commonTeacherRoutes.add({
	path: "/classrooms/:classroomId/assignments/:assignmentId/review",
	component: TeacherAssignmentReview,
});

///
const TeacherAssignmentIndividualUserReview = React.lazy(() =>
	import("../../components/teachers/assignments/review/individual").then(
		module => ({
			default: module.TeacherAssignmentIndividualUserReview,
		})
	)
);

commonTeacherRoutes.add({
	path:
		"/classrooms/:classroomId/assignments/:assignmentId/users/:userId/review",
	component: TeacherAssignmentIndividualUserReview,
});

///
const TeacherPublicWrittenAssignment = React.lazy(() =>
	import("../../components/teachers/assignments/review/individual").then(
		module => ({
			default: module.TeacherPublicWrittenAssignment,
		})
	)
);

commonTeacherRoutes.add({
	path:
		"/classrooms/:classroomId/passignments/:assignmentId/wt/:writtenAssignmentId/review",
	component: TeacherPublicWrittenAssignment,
});

///
const CreateHomework = React.lazy(() =>
	import("../../components/teachers/assignments/create").then(module => ({
		default: module.CreateHomework,
	}))
);

hubuTeacherRoutes.add({
	path: "/classrooms/:classroomId/assignments/add",
	component: CreateHomework,
});

///

const HubuTeacherClassroomsPage = React.lazy(() =>
	import("../../components/teachers/main/index").then(module => ({
		default: module.HubuTeacherClassroomsPage,
	}))
);
hubuTeacherRoutes.add({
	path: "/classrooms",
	component: HubuTeacherClassroomsPage,
});

///
const EditHomework = React.lazy(() =>
	import("../../components/teachers/assignments/edit").then(module => ({
		default: module.EditHomework,
	}))
);

hubuTeacherRoutes.add({
	path: "/classrooms/:classroomId/assignments/:assignmentId/review/edit",
	component: EditHomework,
});

///
const ClassroomGradebook = React.lazy(() =>
	import("../../components/teachers/gradebook").then(module => ({
		default: module.ClassroomGradebook,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/classrooms/:classroomId/gradebook",
	component: ClassroomGradebook,
});

///
const NewAssignment2 = React.lazy(() =>
	import("../../components/teachers/assignments-2").then(module => ({
		default: module.NewAssignment2,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/assignments/new",
	component: NewAssignment2,
});

///
const RequestSubject = React.lazy(() =>
	import("../../components/users/request-subject").then(module => ({
		default: module.RequestSubject,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/request-test/:subjectId?/:grade?/:courseId?",
	component: RequestSubject,
});

///
const StudentCertificateCandidatesFrontPage = React.lazy(() =>
	import(
		"../../components/teachers/certificates/certificate-candidates"
	).then(module => ({
		default: module.StudentCertificateCandidatesFrontPage,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/certificate-candidates",
	component: StudentCertificateCandidatesFrontPage,
});

///
const StudentCertificateCandidatesListPage = React.lazy(() =>
	import(
		"../../components/teachers/certificates/certificate-candidates"
	).then(module => ({
		default: module.StudentCertificateCandidatesListPage,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/certificate-candidates-list",
	component: StudentCertificateCandidatesListPage,
});

///
const CreateCognitiveAssignmentContainer = React.lazy(() =>
	import(
		"../../components/teachers/assignments-2/cognitive-assignments/create-container"
	).then(module => ({
		default: module.CreateCognitiveAssignmentContainer,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/cognitive-assignment/new",
	component: CreateCognitiveAssignmentContainer,
});

///
const CognitiveAssignmentResults = React.lazy(() =>
	import(
		"../../components/teachers/assignments-2/cognitive-assignments/results"
	).then(module => ({
		default: module.CognitiveAssignmentResults,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/cognitive-assignment/results/:classroomId",
	component: CognitiveAssignmentResults,
});

///
const CreateClassroom = React.lazy(() =>
	import(
		"../../components/teachers/classrooms/tvschool-create-classroom"
	).then(module => ({
		default: module.CreateClassroom,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/new-classroom",
	component: CreateClassroom,
});

///
const TeacherGroupTimetablePage = React.lazy(() =>
	import("../../components/teachers/timetables/groups").then(module => ({
		default: module.TeacherGroupTimetablePage,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/group/:groupId/timetable",
	component: TeacherGroupTimetablePage,
});

///
const GroupGradebook = React.lazy(() =>
	import("@app/components/teachers/gradebook/group").then(module => ({
		default: module.GroupGradebook,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/group/:groupId/gradebook",
	component: GroupGradebook,
});

///
const TeacherPersonalTimetablePage = React.lazy(() =>
	import("../../components/teachers/timetables/personal").then(module => ({
		default: module.TeacherPersonalTimetablePage,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/t/timetable",
	component: TeacherPersonalTimetablePage,
});

///
const StudentListing = React.lazy(() =>
	import("../../components/teachers/student-listing/index").then(module => ({
		default: module.StudentListing,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/classrooms/:classroomId/students",
	component: StudentListing,
});

///

const StudentStatistics = React.lazy(() =>
	import("../../components/users/stats").then(module => ({
		default: module.StudentStatistics,
	}))
);

hubuTeacherRoutes.add({
	path: "/student-statistics",
	component: StudentStatistics,
});

///

const StudentPortfolio = React.lazy(() =>
	import("../../components/teachers/portfolio").then(module => ({
		default: module.StudentPortfolio,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/classrooms/:classroomId/portfolio/:studentId",
	component: StudentPortfolio,
});

///

///

const GroupStudentList = React.lazy(() =>
	import("../../components/teachers/student-listing/group").then(module => ({
		default: module.GroupStudentList,
	}))
);

tvsChoolTeacherRoutes.add({
	path: "/group/:groupId/student-list",
	component: GroupStudentList,
});

///

const teacherRoutes = commonTeacherRoutes
	.toArray()
	.concat(tvsChoolTeacherRoutes.toArray())
	.concat(hubuTeacherRoutes.toArray());

export default teacherRoutes;
