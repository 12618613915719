import { ICourseInfo } from "@app/api/courses-info/helper-schemas";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useMyClassroomsAsTeacher } from "@app/hooks/classrooms";
import { useLocale } from "@app/hooks/intl";
import { useTeacherInfo } from "@app/hooks/teacher";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { BigBackgroundLittleUp } from "../../backgrounds";
import { CreateClassroomPage } from "../classrooms/create";
import { TeachersInvitations } from "../invitations/container";
import { ClassroomBoxLoader } from "./classroom-loader";
import { ClassroomBox } from "./classrooms";
import { NewClassroomBox } from "./new-classroom-box";
import classroomStyles from "./styles/classrooms.module.css";
import styles from "./styles/index.module.scss";
import { TVSchoolTeacherMainPage } from "./tvschool";
import { ReactComponent as BGSVG } from "./styles/img/Illustration_Hubu.svg";
import { ReactComponent as TOU085 } from "./styles/img/Tou085.svg";

export const TeacherClassroomsPage: React.FC<{}> = () => {
	const user = useClassroomsUser();
	if (!user) return null;
	if (getCurrentWebsite() === WebsiteOrigin.tvSchool) {
		return <TVSchoolTeacherMainPage user={user} />;
	}
	return <HubuTeacherClassroomsPage />;
};

export const TeacherMainPage: React.FC<{}> = () => {
	if (getCurrentWebsite() === WebsiteOrigin.tvSchool) {
		return null;
	}
	return <HubuTeacherMainPage />;
};

// eslint-disable-next-line max-lines-per-function
const HubuTeacherMainPage: React.FC<{}> = () => {
	const [courses, setCourses] = useState<ICourseInfo[]>([]);
	const locale = useLocale();
	useEffect(() => {
		inject("CoursesInfoController")
			.getAll({
				website: getCurrentWebsite(),
				language: locale,
			})
			.then(setCourses);
	}, [setCourses, locale]);

	const history = useHistory();

	const [height, setWidth] = useState<number>(window.innerHeight);
	window.onresize = useCallback(() => setWidth(window.innerHeight), []);
	const redirect = useCallback(() => history.push("classrooms"), [history]);
	return (
		<div className={styles.teacherMainPage}>
			<BGSVG
				className={styles.background}
				style={{
					width: (height * 1255) / 1080,
					height,
				}}
			/>
			<div className={styles.contentWrapper}>
				<div className={styles.instructions}>
					<h1>ძვირფასო მასწავლებელო!</h1>
					ჰუბუ არის პლატფორმა, რომელსაც გამოვიყენებთ როგორც სასკოლო,
					ისე რეპეტიტორულ საქმიანობაში!
				</div>
				<div className={styles.box}>
					<h1>ჰუბუს დახმარებით თქვენ შეძლებთ:</h1>
					<br />
					<ul>
						<li>
							შეარჩიოთ მზა დავალებები სასურველ თემატიკაზე და
							მარტივად გადმოტვირთოთ ან ამობეჭდოთ ნებისმიერი ტესტი!
						</li>
						<br />
						<li>
							შექმნათ ვირტუალური ჯგუფი და აქედანვე გაუგზავნოთ
							მოსწავლეებს დავალებები!
						</li>
					</ul>
				</div>
				<CourseList courses={courses} />
				<br />
				<div className={styles.instructions}>
					<span>დავალებების გასაგზავნად გთხოვთ, შექმნათ ჯგუფი </span>
					<button
						className={styles.customButton}
						style={{ marginLeft: 10 }}
						onClick={redirect}
					>
						ჯგუფის შექმნა
					</button>
					<br />
					<div
						style={{
							textAlign: "center",
							marginTop: 30,
						}}
					>
						ჰუბუს გამოყენება მარტივია!
					</div>
					<TOU085 style={{ float: "right", marginTop: 30 }} />
				</div>
			</div>
		</div>
	);
};

const CourseList: React.FC<{
	courses: ICourseInfo[];
}> = ({ courses }) => {
	const history = useHistory();

	const redirectToTestsPage = useCallback(
		(courseId: ObjectId) => () =>
			courseId
				? history.push(`/courses/${courseId}/items/`)
				: alert("აირჩიეთ კურსი"),
		[history]
	);
	return (
		<div>
			<h1 className={styles.title}>აირჩიეთ კურსი</h1>
			{courses.map((courseInfo, index) => (
				<div
					key={index}
					className={styles.courseHyperLink}
					onClick={redirectToTestsPage(courseInfo.courseId)}
				>
					{courseInfo.name}
				</div>
			))}
		</div>
	);
};

export const HubuTeacherClassroomsPage: React.FC<{}> = () => {
	const myClassrooms = useMyClassroomsAsTeacher();
	const teacherInfo = useTeacherInfo();

	const classroomIds = useMemo(
		() => (!myClassrooms ? [] : myClassrooms.map(e => e._id)),
		[myClassrooms]
	);

	return (
		<div className={classroomStyles.mainPage}>
			<BigBackgroundLittleUp />

			<div className={classroomStyles.userInterface}>
				{!teacherInfo || !myClassrooms ? (
					<ClassroomsLoader />
				) : (
					<>
						{classroomIds.length !== 0 ? (
							<>
								<TeachersInvitations
									classrooms={myClassrooms}
								/>
								<ClassroomBoxesContainer
									classroomIds={classroomIds}
								/>
							</>
						) : (
							<CreateClassroomPage
								firstShadowContainerClassname={
									classroomStyles.firstShadow
								}
								secondShadowContainerClassname={
									classroomStyles.secondShadow
								}
								classroomNameContainer={classNames(
									classroomStyles.inputContainer2,
									classroomStyles.edit
								)}
								isFirstClassroom={true}
								inputStyles={classroomStyles.inputStyles}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const ClassroomsLoader = React.memo(() => (
	<>
		{new Array(6).fill(0).map((box, i) => (
			<ClassroomBoxLoader key={i} />
		))}
	</>
));

const ClassroomBoxesContainer: React.FC<{
	classroomIds: ObjectId[];
}> = React.memo(props => (
	<div>
		{props.classroomIds.map(classroomId => (
			<ClassroomBox key={classroomId} classroomId={classroomId} />
		))}
		<NewClassroomBox />
	</div>
));
