import * as React from "react";
import { IAnyObj } from "../../../../utils";

export interface IGIEditPassableProps {}

interface IProps extends IAnyObj {}

class GroupingItemsEditor extends React.PureComponent<IProps> {
	render() {
		return <div>dfsd</div>;
	}
}

export default GroupingItemsEditor;
