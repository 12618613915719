import { ObjectId } from "@app/utils/generics";
import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import {
	HomeworkContext,
	ShowAnswersStandardTime,
	TaskTypesContext,
} from "../";
import Popup from "../../../../widgets/popup";
import styles from "../../styles/styles.module.css";
import { TaskTypeSliders } from "./advances-sliders";
import { AssignmentGeneralSetting } from "./general-settings";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";

export enum SettingsAndIds {
	ShuffleQuestions = "5d24ac4b102edf766d140539",
	ShuffleAnswers = "5d24ac91102edf766d14053b",
	showAnswersAt = "5d27070532092441e654d662",
}

interface IAdvancesSettingsPopupProps {
	onClose: () => void;
}
export const AdvancesSettingsPopup: React.FC<IAdvancesSettingsPopupProps> = React.memo(
	props => {
		const [homework, dispatch] = useContext(HomeworkContext);
		const taskTypeInfo = useContext(TaskTypesContext);

		const isSwitched = !!homework.taskTypesInAdvancedSettings;

		const onSettingsChange = useCallback(
			id => {
				const key =
					id === SettingsAndIds.ShuffleQuestions
						? "shuffleQuestions"
						: id === SettingsAndIds.ShuffleAnswers
						? "shuffleAnswers"
						: "showAnswersAt";
				if (key === "showAnswersAt") {
					return dispatch({
						type: "CHANGE_SETTINGS",
						newSettings: {
							...homework.settings,
							[key]:
								homework.settings[key] ===
								ShowAnswersStandardTime.deadline
									? ShowAnswersStandardTime.submission
									: ShowAnswersStandardTime.deadline,
						},
					});
				}
				dispatch({
					type: "CHANGE_SETTINGS",
					newSettings: {
						...homework.settings,
						[key]: !homework.settings[key],
					},
				});
			},
			[dispatch, homework.settings]
		);

		const onSwitchedChange = () => {
			if (isSwitched) {
				dispatch({
					type: "NULLIFY_TASKTYPES_COUNT",
				});
			} else {
				if (!taskTypeInfo) return null;

				const taskTypeIds: {
					_id: ObjectId;
					countType: "text" | "question";
					approximateNumOfQuestionsPerText?: number | null;
				}[] = [];

				for (const taskType of taskTypeInfo.taskTypes) {
					taskTypeIds.push({
						_id: taskType._id,
						countType: taskType.consistsOfMultipleQuestions
							? "text"
							: "question",
						approximateNumOfQuestionsPerText:
							taskType.approximateNumOfQuestionsPerText,
					});
				}

				dispatch({
					type: "MAKE_TASKTYPES_COUNTABLE",
					taskTypes: taskTypeIds,
				});
			}
		};

		const switchContainerStyles = () => {
			if (isSwitched) {
				return classNames(styles.switchContainer, styles.on);
			}
			return styles.switchContainer;
		};

		const switchStyles = () => {
			if (isSwitched) {
				return classNames(styles.switcher, styles.on);
			}
			return styles.switcher;
		};

		return (
			<Popup onClose={() => props.onClose()}>
				<div className={styles.popupContent}>
					<div
						className={styles.textStyles3}
						style={{
							textAlign: "center",
							padding: "30px 0px",
						}}
					>
						<FormattedMessage id="teacher:assignment.advancedSettings" />
					</div>
					<AssignmentGeneralSetting
						settings={homework.settings}
						onChange={onSettingsChange}
					/>
					<div
						className={styles.textStyles3}
						style={{
							textAlign: "center",
							padding: "30px 0px",
							fontWeight: "normal",
						}}
					>
						<div
							className={switchContainerStyles()}
							onClick={onSwitchedChange}
						>
							<div className={switchStyles()} />
						</div>

						<span>
							<FormattedMessage id="select.chooseNumberOfQuestionsByTaskTypes" />
						</span>
					</div>
					{isSwitched && (
						<TaskTypeSliders
							topics={homework.topics}
							dispatch={dispatch}
							homework={homework}
						/>
					)}
					<div
						className={styles.buttonsContainer}
						style={{ margin: "20px auto" }}
					>
						<button
							className={styles.buttonStyles}
							onClick={() => props.onClose()}
						>
							<div style={{ display: "inline-block" }}>
								<FormattedMessage id="student:excercisesTopicsTestStartCTA" />
							</div>
						</button>
					</div>
				</div>
			</Popup>
		);
	}
);
