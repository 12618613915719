import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as HubuLogo } from "../../landing/styles/img/hubu-logo.svg";

const HubuExamsHeader: React.FC<{}> = () => {
	const history = useHistory();
	const onClick = useCallback(() => history.replace(""), [history]);
	return (
		<div
			style={{
				padding: 30,
				// position: "fixed",
				backgroundColor: "transparent",
			}}
		>
			<HubuLogo
				width={120}
				height={"100%"}
				style={{ cursor: "pointer" }}
				onClick={onClick}
			/>
		</div>
	);
};

export default HubuExamsHeader;
