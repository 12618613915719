import { addBasePath, withParams } from "../link-utils";

const HEADMASTER_BASE_PATH = "";

export const headmasterLinks = addBasePath(HEADMASTER_BASE_PATH)({
	mainPage: "/",

	groups: {
		timetable: withParams(
			({ groupId }: { groupId: string }) => `/group/${groupId}/timetable`,
			`/headmaster/group/:groupId/timetable`
		),
		gradebook: withParams(
			({ groupId }: { groupId: string }) => `/group/${groupId}/gradebook`,
			"/headmaster/group/:groupId/gradebook"
		),
	},
});
