import {
	IAPOSTCreateClassroom,
	IRPOSTCreateClassroom,
	IAGETAllClassrooms,
	IRGETGetAllClassrooms,
	IAPUTUpdateClassroom,
	IAPUTRemoveStudentsFromClassroom,
	IRPUTRemoveStudentsFromClassroom,
	IAPOSTEnrollByAssignmentCode,
	IAPOSTAffectingCoursesExistance,
	IRPOSTAffectingCoursesExistance,
	APOSTAffectingCoursesExistanceSchema,
	RPOSTAffectingCoursesExistanceSchema,
	IAGETManyClassroomsByIds,
	RGETManyClassroomsByIdsSchema,
	IRGETManyClassroomsByIds,
	IAGETClassroomByCourse,
	IRGETClassroomByCourse,
	IAGETManyClassroomsByIdsWithoutFiltering,
	RGETManyClassroomsByIdsWithoutFilteringSchema,
} from "./validators";
import { IRequest } from "../requests";
import { inject } from "@app/modules";
import { ObjectId, ReplaceIn } from "@app/utils/generics";
import { Classroom } from "@app/models/classroom";
import { removeKeys } from "@app/utils/common";
import { PromisesKeeperAPI, getManyResources } from "../promises-keeper";
import { IClassroom } from "./helper-schemas";

export class ClassroomsController {
	private readonly Request: IRequest;

	private readonly _ClassroomModel = inject("ClassroomModel");
	private readonly _ClassroomJoinRequestModel = inject(
		"ClassroomJoinRequestModel"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	private classroomPromises = new PromisesKeeperAPI<ObjectId, Classroom>(
		getManyResources(
			docs => this.getManyByIds({ ids: docs.map(e => e.id) }),
			(data, id) => data.find(e => e._id === id)
		),
		15
	);

	add = (args: IAPOSTCreateClassroom): Promise<Classroom> =>
		this.Request.send("POST", "/api/classrooms", args).then(
			(data: IRPOSTCreateClassroom) => {
				return this._ClassroomModel.loadOneSync(data);
			}
		);

	getAll = (args: IAGETAllClassrooms): Promise<Classroom[]> =>
		this.Request.send("GET", "/api/classrooms", args).then(
			(data: IRGETGetAllClassrooms) => {
				return this._ClassroomModel.loadManySync(data);
			}
		);

	getById = async (
		args: { _id: ObjectId },
		loadFresh?: boolean
	): Promise<Classroom> => {
		if (!loadFresh) {
			const classroom = this._ClassroomModel.findByIdSync(args._id);
			if (classroom) return classroom;
		}
		return this.classroomPromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/classrooms/:_id", args).then(
				(data: IClassroom) => {
					return this._ClassroomModel.loadOneSync(data);
				}
			)
		);
	};

	getByCourseId = async (
		args: IAGETClassroomByCourse,
		loadFresh?: boolean
	): Promise<Classroom> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.getAllSync();
			const classroom = classrooms.find(
				e => e.course.courseId === args.classroomCourseId
			);
			if (classroom) return classroom;
		}
		return this.Request.send(
			"GET",
			"/api/classrooms/by-course/:classroomCourseId",
			args
		).then((data: IRGETClassroomByCourse) => {
			return this._ClassroomModel.loadOneSync(data);
		});
	};

	getManyByIds = async (
		args: IAGETManyClassroomsByIds,
		loadFresh?: boolean
	): Promise<Classroom[]> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.findManyByIdsSync(args.ids);
			if (classrooms.length === args.ids.length) {
				return classrooms;
			}
		}
		return this.Request.send("POST", "/api/classrooms/by-ids", args, null, {
			responseSchema: RGETManyClassroomsByIdsSchema,
		}).then((data: IRGETManyClassroomsByIds) => {
			return this._ClassroomModel.loadManySync(data, args.ids);
		});
	};

	getManyByIdsWithoutFiltering = async (
		args: IAGETManyClassroomsByIdsWithoutFiltering,
		loadFresh?: boolean
	): Promise<Classroom[]> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.findManyByIdsSync(args.ids);
			if (classrooms.length === args.ids.length) {
				return classrooms;
			}
		}
		return this.Request.send(
			"GET",
			"/api/classrooms/all-by-ids",
			args,
			null,
			{
				responseSchema: RGETManyClassroomsByIdsWithoutFilteringSchema,
			}
		).then((data: IRGETManyClassroomsByIds) => {
			return this._ClassroomModel.loadManySync(data, args.ids);
		});
	};

	delete = (classroomId: ObjectId): Promise<void> =>
		this.Request.send("DELETE", "/api/classrooms/:_id", {
			_id: classroomId,
		}).then(() => {
			this._ClassroomModel.deleteByIdSync(classroomId);
			this._ClassroomJoinRequestModel.deleteClassroomRequests(
				classroomId
			);
		});

	update = (args: IAPUTUpdateClassroom): Promise<Classroom | null> =>
		this.Request.send("PUT", "/api/classrooms/:_id", args).then(() =>
			this._ClassroomModel.updateOneSync({ _id: args._id }, args)
		);

	enrollByAssignmentCode = async (
		args: IAPOSTEnrollByAssignmentCode
	): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/classrooms/enroll-by-assignment-code",
			args
		).then();

	removeStudents = async (
		args: IAPUTRemoveStudentsFromClassroom
	): Promise<Classroom> => {
		return this.Request.send(
			"PUT",
			"/api/classrooms/:_id/remove-students",
			args
		).then((data: IRPUTRemoveStudentsFromClassroom) => {
			return this._ClassroomModel.loadOneSync(data);
		});
	};

	ensureAffectingCoursesExistance = async (
		args: IAPOSTAffectingCoursesExistance
	): Promise<IRAffectingCoursesExistance> => {
		return this.Request.send(
			"POST",
			"/api/classrooms/ensure-courses",
			args,
			null,
			{
				requestSchema: APOSTAffectingCoursesExistanceSchema,
				responseSchema: RPOSTAffectingCoursesExistanceSchema,
			}
		).then((data: IRPOSTAffectingCoursesExistance) => {
			if (data.newHiddenClassroom) {
				const newHiddenClassroom = this._ClassroomModel.loadOneSync(
					data.newHiddenClassroom
				);
				return {
					...data,
					newHiddenClassroom,
				};
			}
			return removeKeys(data, "newHiddenClassroom");
		});
	};
}

export type IRAffectingCoursesExistance = ReplaceIn<
	IRPOSTAffectingCoursesExistance,
	{ newHiddenClassroom?: Classroom }
>;
