import { useWindowSize } from "@app/hooks/front";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DivReference } from ".";
import { WhichAreYou, ProfOrientationButtonSmall } from "./buttons";
import mainIllustration from "./styles/img/mainIllustration.png";
import styles from "./styles/landing-main-page.module.css";
import classNames from "classnames";

export const ProjectDiv: React.FC<{
	refs: {
		studentRef: DivReference;
		teacherRef: DivReference;
		schoolRef: DivReference;
	};
}> = props => {
	const { width } = useWindowSize();

	return (
		<>
			{/* {width < 1030 && <ProfOrientationButtonSmall />} */}
			<div className={styles.infoDivs} style={{ marginTop: 15 }}>
				<div style={{}}>
					<div
						className={styles.infoDivsText}
						style={
							width > 800
								? { width: 500 }
								: { textAlign: "center" }
						}
					>
						<h1
							className={styles.hubu}
							style={{ alignSelf: "flex-start" }}
						>
							<FormattedMessage id="landing:mainTitle" /> <br />{" "}
							<span
								style={
									width > 350
										? { fontSize: 21 }
										: { fontSize: 18 }
								}
							>
								{/* <FormattedMessage id="landing:secondaryTitle" /> */}
							</span>
							<div className={styles.headerUnderline}></div>
						</h1>

						<p>
							<FormattedMessage id="landing:secondaryMessage" />
						</p>
						{/* <WhichAreYou refs={props.refs} /> */}
					</div>
				</div>
				{width > 1050 && (
					<div className={styles.mainIllustrationDiv}>
						<img
							src={mainIllustration}
							alt="Main Illustration"
							width="420px"
						/>
					</div>
				)}
			</div>
		</>
	);
};

export const ProjectDivEnVersion: React.FC<{
	refs: {
		studentRef: DivReference;
		teacherRef: DivReference;
		schoolRef: DivReference;
	};
}> = props => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const { width } = useWindowSize();

	const lastWords = useMemo(
		() => [
			getFormattedMessage("landing:mainTitleLastWord.1"),
			getFormattedMessage("landing:mainTitleLastWord.2"),
			getFormattedMessage("landing:mainTitleLastWord.3"),
			getFormattedMessage("landing:mainTitleLastWord.4"),
			getFormattedMessage("landing:mainTitleLastWord.5"),
			getFormattedMessage("landing:mainTitleLastWord.6"),
			getFormattedMessage("landing:mainTitleLastWord.7"),
			getFormattedMessage("landing:mainTitleLastWord.8"),
		],
		[]
	);

	const lastWordColor = useMemo(() => {
		if (currentIndex === 0) return "#02BEDC";
		if (currentIndex === 1) return "#37E0BF";
		if (currentIndex === 2) return "#FF907E";
		if (currentIndex === 3) return "#F16EAA";
		if (currentIndex === 4) return "#02BEDC";
		if (currentIndex === 5) return "#37E0BF";
		if (currentIndex === 6) return "#FF907E";
		if (currentIndex === 7) return "#F16EAA";
	}, [currentIndex]);

	useEffect(() => {
		const millisecondsToChangeMessages = 1000 * 1.2;
		const changeIndex = () => {
			setCurrentIndex(x => (x + 1) % lastWords.length);
		};
		const planned = setInterval(changeIndex, millisecondsToChangeMessages);
		return () => {
			clearInterval(planned);
		};
	}, [setCurrentIndex, currentIndex, lastWords.length]);

	const currentWord = lastWords[currentIndex];
	const firstLetter = currentWord.substr(0, 1);
	const restWord = currentWord.substr(1);

	return (
		<div
			className={classNames(styles.infoDivs, styles.eng)}
			style={{ marginTop: 15 }}
		>
			<div>
				<div
					className={styles.infoDivsText}
					style={
						width > 800 ? { width: 594 } : { textAlign: "center" }
					}
				>
					<h1 className={styles.hubuEn}>
						<FormattedMessage id="landing:mainTitle" />{" "}
						<span
							className={styles.hubuEnLastWord}
							style={{ color: lastWordColor }}
						>
							<span className={styles.hubuEnLastWordFirstLetter}>
								{firstLetter}
							</span>
							{restWord}
						</span>
					</h1>
					{/* <WhichAreYou refs={props.refs} /> */}
				</div>
			</div>
		</div>
	);
};
