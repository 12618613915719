import React from "react";
import { Link } from "react-router-dom";
import styles from "../cognitive-tests/styles/cognitive-assignments.module.css";

export const OffersBox = () => {
	return (
		<Link to="/student-offers" className={styles.boxContainer}>
			<img
				alt="buld"
				src="/imgs/offerings.svg"
				className={styles.sendCognitiveAssignmentBuld}
			/>
			<div className={styles.boxContainer_title}>შეთავაზებები</div>
		</Link>
	);
};
