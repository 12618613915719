import { ObjectId } from "@app/utils/generics";
import { css } from "emotion";
import React, { useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { StylesConfig } from "react-select";
import { createGrades } from "@app/components/widgets/grades";
import { useSubjectOptions } from "@app/hooks/subjects";
import { inject } from "@app/modules";
import { useUnsafeDynamicRef } from "@app/hooks/general";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { useWindowSize } from "@app/hooks/front";
import { useClassroomsUser } from "@app/hooks/users";
import { getLocale } from "@app/hooks/intl";
import { getCurrentWebsite } from "@app/globals";

const customSelectStyles: StylesConfig = {
	control: provided => ({
		...provided,
		background: "#e1e6ec",
		border: "none",
		color: "#252423",
	}),
	indicatorSeparator: (provided, state) => {
		return { display: "none" };
	},
	placeholder: (provided, state) => {
		return {
			...provided,
			fontFamily: "Roboto Geo Nus",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
		};
	},
	dropdownIndicator: (provided, state) => {
		return {
			...provided,
			color: "#4d3737",
		};
	},
};

const EachProp = css`
	margin: 10px auto;
	width: 82%;
`;
interface ISelectSubjectsAndGrade {
	selectedSubjects: ObjectId[] | undefined;
	onChangeSubjects: (subjects: ObjectId[]) => void;
	selectedCourse: ObjectId | undefined;
	onChangeCourse?: (courseId: ObjectId | undefined) => void;
	onSelectedGradesChange: (grade: number | undefined) => void;
	selectedGrade: number | undefined;
	classroomId?: ObjectId;
}

export const SelectSubjectsAndGrade: React.FC<ISelectSubjectsAndGrade> = React.memo(
	props => {
		const user = useClassroomsUser();
		const gradeOptions = useMemo(
			() => createGrades(user ? user.country : null, getCurrentWebsite()),
			[user]
		);
		const subjectOptions = useSubjectOptions({
			country: user ? user.country : null,
			grade: props.selectedGrade ?? null,
			language: getLocale(),
			website: getCurrentWebsite(),
		});
		const [courseOptions, setCourseOptions] = useState<
			{ value: string; label: string }[]
		>();

		const onChangeCourse = useUnsafeDynamicRef(props.onChangeCourse);

		useEffect(() => {
			const CourseInfoModel = inject("CourseInfoModel");
			if (props.classroomId) return;
			if (!props.selectedGrade) return;
			if (!props.selectedSubjects) return;
			const courseInfos = CourseInfoModel.searchSync({
				grade: props.selectedGrade,
				subject: props.selectedSubjects,
				language: getLocale(),
				website: getCurrentWebsite(),
				country: user?.country || null,
			});
			const newCourseOptions: {
				value: string;
				label: string;
			}[] = courseInfos.map(course => ({
				value: course.courseId,
				label: course.name,
			}));
			setCourseOptions(newCourseOptions);
			onChangeCourse.current &&
				onChangeCourse.current(
					newCourseOptions.length !== 1
						? undefined
						: newCourseOptions[0].value
				);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			onChangeCourse,
			props.classroomId,
			props.selectedGrade,
			props.selectedSubjects,
			user?.country,
		]);

		const { width } = useWindowSize();
		return (
			<>
				<div
					className={EachProp}
					style={width > 380 ? { width: "82%" } : { width: "94%" }}
				>
					<Select
						isMulti={false}
						styles={customSelectStyles}
						options={gradeOptions}
						value={gradeOptions.find(
							g => g.value === props.selectedGrade
						)}
						onChange={sel =>
							props.onSelectedGradesChange(
								!sel
									? undefined
									: (sel as { value: number; label: string })
											.value
							)
						}
						placeholder={getFormattedMessage("teacher:chooseGrade")}
						isSearchable={false}
					/>
				</div>
				<div
					className={EachProp}
					style={width > 380 ? { width: "82%" } : { width: "94%" }}
				>
					{subjectOptions && (
						<Select
							styles={customSelectStyles}
							options={subjectOptions}
							value={
								!props.selectedSubjects
									? undefined
									: subjectOptions.find(
											s =>
												props.selectedSubjects!.indexOf(
													s.value
												) > -1
									  )
							}
							onChange={(sel: { value: string; label: string }) =>
								props.onChangeSubjects([sel.value])
							}
							placeholder={getFormattedMessage(
								"teacher:chooseSubjects"
							)}
						/>
					)}
				</div>
				{!props.classroomId &&
					courseOptions &&
					courseOptions.length > 1 && (
						<div
							className={EachProp}
							style={
								width > 380
									? { width: "82%" }
									: { width: "94%" }
							}
						>
							<Select
								isMulti={false}
								styles={customSelectStyles}
								options={courseOptions}
								value={courseOptions.find(
									e => e.value === props.selectedCourse
								)}
								onChange={(
									sel:
										| {
												value: ObjectId;
												label: string;
										  }
										| undefined
										| null
								) => {
									props.onChangeCourse &&
										props.onChangeCourse(
											!sel ? undefined : sel.value
										);
								}}
								placeholder={getFormattedMessage(
									"chooseCourse"
								)}
							/>
						</div>
					)}
				{!props.classroomId &&
					!props.selectedCourse &&
					courseOptions &&
					courseOptions.length === 0 &&
					props.selectedGrade &&
					props.selectedSubjects && (
						<div style={{ color: "red" }}>
							<FormattedMessage id="teacher:coursesNotFound" />
						</div>
					)}
			</>
		);
	}
);
