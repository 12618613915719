import React from "react";
import { UserType } from "@app/api/helper-schemas";
import { TeacherHeader } from "@app/components/teachers/header/teacher-header";
import { Header as StudentHeader } from "@app/components/users/header";
import { MainAdminHeader } from "@app/components/admin/header";
import { LandingHeader } from "@app/components/landing/header";
import { TVSchoolHeader } from "@app/components/tv-school-landing/tv-school-header";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { TVSchoolStudentHeader } from "@app/components/users/header/tv-school";
import { match, useLocation } from "react-router-dom";
import { History } from "history";
import { useClassroomsUser } from "@app/hooks/users";
import { TeacherTransparentHeader } from "@app/components/teachers/header/teacher-gradebook-header";
import { shoudlRenderNewTeacherHeader } from "@app/components/teachers/sidebar/sidebar-items";
import {
	useGeneralContext,
	useSetIsHeaderHidden,
} from "@app/components/general-context";
import { shoudlRenderNewStudentHeader } from "@app/components/users/sidebar/sidebar-items";
import { StudentTransparentHeader } from "@app/components/users/header/student-transparent-header";
import { HeadmasterHeader } from "@app/components/headmaster/header";
import HubuExamsHeader from "@app/components/users/header/hubu-exams-header";

export const HeaderDistributorWrapper = React.memo<{
	history: History;
	match: match;
}>(({ history, match, ...rest }) => {
	const user = useClassroomsUser();
	const userType = user?.getRandomType() ?? null;

	const isHeaderHidden = useGeneralContext("isHeaderHidden");
	if (isHeaderHidden) return null;

	return (
		<HeaderDistributor
			{...rest}
			history={history}
			location={history.location}
			match={match}
			{...match.params}
			$$userType={userType}
		/>
	);
});

export const HeaderDistributor: React.FC<{
	$$userType: UserType | null;
} & Record<any, any>> = ({ $$userType, ...rest }) => {
	const location = useLocation();
	const path = location.pathname;
	const isNewSidebarVisible = useGeneralContext("isNewSidebarVisible");

	const currentWebsite = getCurrentWebsite();
	if ($$userType === UserType.student) {
		if (currentWebsite === WebsiteOrigin.tvSchool) {
			return isNewSidebarVisible || shoudlRenderNewStudentHeader(path) ? (
				<StudentTransparentHeader {...rest} />
			) : (
				<TVSchoolStudentHeader {...rest} />
			);
		} else if (currentWebsite === WebsiteOrigin.hubu) {
			return <StudentHeader {...rest} />;
		} else {
			return <HubuExamsHeader />;
		}
	}
	if ($$userType === UserType.teacher) {
		return currentWebsite === WebsiteOrigin.tvSchool &&
			(isNewSidebarVisible || shoudlRenderNewTeacherHeader(path)) ? (
			<TeacherTransparentHeader {...rest} />
		) : (
			<TeacherHeader
				{...rest}
				backgroundColor={path === "/" && "transparent"}
			/>
		);
	}

	if ($$userType === UserType.headmaster) {
		return <HeadmasterHeader {...rest} />;
	}
	if ($$userType === UserType.mainAdmin) return <MainAdminHeader {...rest} />;

	if ($$userType === UserType.parent) return null;

	if (currentWebsite === WebsiteOrigin.tvSchool) {
		return <TVSchoolHeader {...rest} />;
	}
	return <LandingHeader {...rest} />;
};

export const HeaderHider = React.memo<{ hideHeader: boolean | undefined }>(
	({ hideHeader }) => {
		useSetIsHeaderHidden(hideHeader, typeof hideHeader !== "boolean");
		return null;
	}
);
