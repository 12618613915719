import { combinePaths, removeKeys } from "@app/utils/common";
import { AnyRoute, AnyRouteOptional, BasicRoute } from "./interfaces";

interface RouteConfig {
	basePath?: string;
	exact: boolean;
	RouteComponent: BasicRoute["RouteComponent"];
	componentProps?: Record<any, any>;
	routeProps?: Record<any, any>;
	avoidSwitch?: boolean;
}

export const createRouteList = (config: RouteConfig) => {
	const list: AnyRoute[] = [];

	return {
		add: (...routes: AnyRouteOptional<"RouteComponent" | "exact">[]) => {
			for (const route of routes) {
				const anyRoute = turnToRoute(route, config);
				list.push(anyRoute);
			}
		},
		addRaw: (route: AnyRoute) => list.push(route),
		toArray: () => list,
	};
};

const turnToRoute = (
	route: AnyRouteOptional<"RouteComponent" | "exact">,
	config: RouteConfig
): AnyRoute => {
	if (route.$switch) {
		return {
			...route,
			$switch: route.$switch.map(r =>
				turnToRoute(r, removeKeys(config, "avoidSwitch"))
			),
		};
	}
	const { basePath, ...restConfig } = config;
	return {
		...restConfig,
		...(route as BasicRoute),
		path: (basePath ? "" : "/") + combinePaths(basePath, route.path),
	};
};
