import { addLoader } from "@app/common-javascript";
import { useHistory } from "@app/hooks/front";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import React, { useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import StudentRegisterStyles from "../../auth/styles/student-register-page.module.css";
import messageImg from "./imgs/message.png";
import touImg from "./imgs/tou-people.png";
import trophyImg from "./imgs/trophy.png";
import { getFormattedMessage } from "@app/utils/locale";
import { useBoolean } from "@app/hooks/general";
import triggerEvent from "@app/utils/events";
import { getProgressAffectingItems } from "../lessons/lesson-tests";
import { useCourseInfo } from "@app/hooks/courses";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { redirectToItem } from "../lessons/items/redirections";

export const RequestGrant: React.FC<{}> = props => {
	const {
		value: isPaymentPopupVisible,
		setTrue: setPaymentPopupVisible,
		setFalse: hidePaymentPopup,
	} = useBoolean(false);
	const requestGrant = useCallback(() => {
		const removeLoader = addLoader();
		const UserActionsController = inject("UserActionsController");
		// const UsersController = inject("UsersController");
		UserActionsController.performUserAction({
			name: "grant",
			data: {
				type: "request",
				requestedAt: new Date(),
				uni: "tou",
			},
		})
			.then(data => {
				removeLoader();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
			});

		// UsersController.sendTextAfterRequestGrant();
	}, []);

	return (
		<div className={StudentRegisterStyles.popUp}>
			<img src={touImg} alt="tou" style={{ maxWidth: "90%" }} />
			{!isPaymentPopupVisible ? (
				<FormattedMessage id="grant.requestGrantText" />
			) : (
				<FormattedMessage id="grant.emptyPaymentPageText" />
			)}
			<div className={StudentRegisterStyles.actionButtonsContainer}>
				<ActionButton
					onClick={requestGrant}
					text={getFormattedMessage("grant.requestGrantButton")}
				/>
				{/* {!isPaymentPopupVisible && (
					<ActionButton
						onClick={setPaymentPopupVisible}
						text={getFormattedMessage("grant.paymentButton")}
					/>
				)} */}
			</div>
		</div>
	);
};

export const ActionButton: React.FC<{
	onClick: () => void;
	text: string;
	isLoading?: boolean;
}> = props => {
	return (
		<button
			onClick={!props.isLoading ? props.onClick : undefined}
			className={StudentRegisterStyles.popUpButton}
		>
			{props.text}
			{props.isLoading && "..."}
		</button>
	);
};

export const RequestedGrant: React.FC<{
	isFirstLessonVisible: boolean;
	onActionClick?: () => void;
}> = props => {
	const user = useClassroomsUser();
	const history = useHistory();
	const matchedurl = history.location.pathname.match(
		/\/courses\/([\da-f]{24})/
	);
	const courseId = !matchedurl ? null : matchedurl[1];

	const courseInfo = useCourseInfo(courseId);
	const classroom = useClassroomByCourseId(courseId || null);
	const lessonsRootFolder = classroom.isIdentificationKnown
		? classroom.isSuccessfullyLoaded
			? classroom.doc.course.customFolderIds.lessons
			: null
		: courseInfo.isSuccessfullyLoaded
		? courseInfo.doc.rootFolder
		: null;

	const redirectToFirstLesson = useCallback(() => {
		triggerEvent({
			category: "button",
			action: "goto first lesson",
			label: "from grant popup",
		});
		const CourseFetchingController = inject("CourseFetchingController");
		const folderId = lessonsRootFolder;
		if (courseId && folderId) {
			const removeLoader = addLoader();
			CourseFetchingController.loadWholeCourseSkeleton({
				courseId,
				folderId,
			})
				.then(data => {
					removeLoader();
					if (props.onActionClick) props.onActionClick();
					const arrTest = getProgressAffectingItems(
						folderId,
						data.folders,
						true
					);
					if (arrTest.length === 0) {
						history.push(`/`);
						if (props.onActionClick) props.onActionClick();
					}

					redirectToItem({
						history,
						courseId,
						itemId: arrTest[0].item.id,
						itemType: arrTest[0].item.type,
						parentFolderId: arrTest[0].parentFolderId,
					});
					if (props.onActionClick) props.onActionClick();
				})
				.catch(() => {
					removeLoader();
					history.push(`/`);
					if (props.onActionClick) props.onActionClick();
				});
		} else {
			history.push(`/`);
			if (props.onActionClick) props.onActionClick();
		}
	}, [courseId, history, lessonsRootFolder, props]);

	const redirectToHome = useCallback(() => {
		if (props.onActionClick) props.onActionClick();
		history.push(`/`);
	}, [history, props]);

	return (
		<div className={StudentRegisterStyles.popUp}>
			<img src={messageImg} alt="grant" style={{ maxWidth: "80%" }} />
			{`${user && user.firstname}, `}

			{!props.isFirstLessonVisible ? (
				<>
					<FormattedMessage id="grant.requestedGrant" />
					<ActionButton
						onClick={redirectToFirstLesson}
						text={getFormattedMessage("grant.firstLessonButton")}
					/>
				</>
			) : (
				<>
					<FormattedMessage id="grant.hasFinishedFirstLesson" />
					<ActionButton
						onClick={redirectToHome}
						text={getFormattedMessage("returnToHomePage")}
					/>
				</>
			)}
		</div>
	);
};

export const GrantInformation: React.FC<{
	grantAction: Record<any, any> | null;
}> = props => {
	const user = useClassroomsUser();
	const previousGrantActions = 1 < 2 ? null : props.grantAction;

	const directToMainPage = useCallback(() => {
		const UserActionsController = inject("UserActionsController");
		UserActionsController.performUserAction({
			name: "grant",
			data: !previousGrantActions
				? {
						type: "claim",
						claimAt: new Date(),
				  }
				: {
						...previousGrantActions,
						claimAt: new Date(),
				  },
		});
	}, [previousGrantActions]);

	return (
		<div className={StudentRegisterStyles.popUp}>
			<img src={trophyImg} alt="trophy" />
			{`${user && user.firstname}, `}
			<FormattedMessage id="grant.GrantInformation" />
			<br />
			<br />
			<FormattedMessage id="grant.BestWishes" />
			<button
				onClick={directToMainPage}
				className={StudentRegisterStyles.popUpButton}
			>
				<FormattedMessage id="grant.GrantInformationButton" />
			</button>
		</div>
	);
};
