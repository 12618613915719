import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { IClassroomJoinRequest } from "@app/api/classrooms/join-requests/helper-schemas";
import { addLoader } from "@app/common-javascript";
import { useUserShortInfo } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import Select from "react-select";
import { StylesConfig } from "react-select";
import styles from "./styles/index.module.css";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";

const customStyles: StylesConfig = {
	option: (provided, state) => ({
		...provided,
		padding: "7px 10px",
	}),
	control: provided => ({
		...provided,
		padding: "7px 10px",
		border: "none",
		backgroundColor: "#e1e6ec",
		borderRadius: "  5px 0px 0px 5px",
		margin: "5px 0",
		outline: "none",
		boxShadow: "none",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		return { ...provided, opacity, transition };
	},
};

interface IncomingInvitationOfTeacherProps {
	info: IClassroomJoinRequest;
	classrooms: IClassroom[];
}

export const IncomingInvitationOfTeacher: React.FC<
	IncomingInvitationOfTeacherProps
> = props => {
	const studentUserInfo = useUserShortInfo(props.info.requester.id);
	const classrooms = useMemo(():
		| {
				value: ObjectId;
				label: string;
		  }[]
		| null => {
		if (!studentUserInfo.isSuccessfullyLoaded) return null;
		const studentId = studentUserInfo.doc.id;
		return props.classrooms
			.filter(
				classroom =>
					classroom.grade === studentUserInfo.doc!.grade &&
					classroom.studentIds.indexOf(studentId) === -1
			)
			.map(c => ({
				value: c._id,
				label: c.name,
			}));
	}, [props.classrooms, studentUserInfo]);
	const [selectedClassroom, setSelectedClassroom] = useState<{
		value: ObjectId;
		label: string;
	}>();

	useEffect(() => {
		if (!classrooms || classrooms.length !== 1) return;
		setSelectedClassroom(classrooms[0]);
	}, [classrooms]);

	const ClassroomJoinRequestsController = inject(
		"ClassroomJoinRequestsController"
	);

	const onApprove = useCallback(() => {
		if (!selectedClassroom) return;
		const removeLoader = addLoader();
		ClassroomJoinRequestsController.approve(
			props.info._id,
			selectedClassroom.value
		)
			.then(() => {
				removeLoader();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	}, [ClassroomJoinRequestsController, props.info._id, selectedClassroom]);

	const onReject = useCallback(() => {
		const removeLoader = addLoader();
		ClassroomJoinRequestsController.reject(props.info._id)
			.then(removeLoader)
			.catch(e => {
				console.log(e);
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	}, [ClassroomJoinRequestsController, props.info._id]);

	const ArrowDown = useCallback(
		() => (
			<HOComponent>
				<ArrowDownIcon />
			</HOComponent>
		),
		[]
	);

	return (
		<div className={styles.invitation}>
			<div>
				{studentUserInfo.isSuccessfullyLoaded && (
					<div className={styles.invitationStudentName}>
						{studentUserInfo.doc.getFullName()}
					</div>
				)}
			</div>

			<div className={styles.invitationSelectorAndButton}>
				{classrooms && (
					<Select
						styles={customStyles}
						options={classrooms}
						value={selectedClassroom}
						onChange={(sel: { value: ObjectId; label: string }) =>
							setSelectedClassroom(sel)
						}
						placeholder="აირჩიეთ ჯგუფი"
						className={styles.invitationSelector}
						components={{
							DropdownIndicator: ArrowDown,
						}}
					/>
				)}
				<button
					className={styles.invitationButtonApprove}
					onClick={onApprove}
				>
					{getFormattedMessage("approve")}
					<CheckIcon style={{ marginLeft: 10 }} />
				</button>
			</div>
			<div>
				<button
					className={styles.invitationButtonDisapprove}
					onClick={onReject}
				>
					{getFormattedMessage("reject")}
					<ClearIcon style={{ marginLeft: 10 }} />
				</button>
			</div>
		</div>
	);
};

const HOComponent: React.FC<any> = props => {
	return <>{props.children}</>;
};
