import { IGroup, GroupSchema } from "@app/api/groups/helper-schemas";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IGroup;
export type IStateGroups = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(GroupSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("group");
const storageSettings = getDefaultStorageSettings("groups");
const metaInformationName = "groupsMetaInformation";

const MAX_LOAD_TIME_DIFF = 5 * 60 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().groups) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", GroupSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Group extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByStudentId(studentId: number): Group[] {
		const docs = this.findManySync({});
		return docs.filter(doc => doc.studentIds.includes(studentId));
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);

	isVisible = () => {
		return true;
	};

	matchesSchoolId = (schoolId: number | null) => {
		return this.schoolId === schoolId;
	};
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Group.meta);

export const groupsReducer = getDefaultReducer(storageSettings, () => Group);
