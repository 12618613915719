import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { onTeacherOpenVideoLesson } from "@app/common-javascript";
import { CertificateBox } from "@app/components/profile/certificate";
import { MarginContainer } from "@app/components/ui/containers";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { isCertificateServicesAvaliable } from "@app/consts";
import { useClassroom, useMyClassroomsAsTeacher } from "@app/hooks/classrooms";
import { useHistory } from "@app/hooks/front";
import { useGradeById } from "@app/hooks/grades";
import { useLocale } from "@app/hooks/intl";
import { useSubjectsByIds } from "@app/hooks/subjects";
import { useVideoLesson, useVideoLessonStatus } from "@app/hooks/video-lessons";
import { SentHomeworkIcon } from "@app/icons";
import { Classroom } from "@app/models/classroom";
import { Group } from "@app/models/group";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import DuoIcon from "@material-ui/icons/Duo";
import { ObjectId } from "@tests-core/utils/joi";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ApprovedPublicAssignments } from "../../assignments-2/approved-public-assignments";
import { StudentCertificatesBox } from "../../certificates/certificate-candidates/student-certificates-box";
import { TVSchoolClassroomBoxLoader } from "./classroom-loader";
import {
	ClassTimesPreview,
	IClassTimesPreviewCoordinates,
} from "./classtimes-preview";
import { CreateClassroomBox } from "./create-classroom-box";
import { GroupBox } from "./group-box";
import styles from "./styles/tvschool-classroom.module.css";
import { Timetable } from "./Timetable";

interface ClassroomBoxProps {
	classroomId: ObjectId;
	enableArchive?: boolean;
}

export const TVSchoolClassroomBox: React.FC<ClassroomBoxProps> = React.memo(
	({ classroomId, enableArchive = false }) => {
		const history = useHistory();
		const classroom = useClassroom(classroomId);
		const subjects = useSubjectsByIds(classroom.doc?.subjects || []);
		const [classTimesPrev, setClassTimesPrev] = useState<
			IClassTimesPreviewCoordinates
		>();
		const handleNewAssignmentAdd = useCallback(() => {
			if (!classroom.doc) return;
			const url = getNewAssignmentUrl(classroom.doc);
			history.push(url!);
		}, [classroom.doc, history]);

		const handleVideoLessonButtonOver = (
			e: React.MouseEvent<HTMLButtonElement>
		) => {
			if (classTimesPrev) return;
			setClassTimesPrev({
				top: e.clientY,
				left: e.clientX,
			});
		};

		const videoLesson = useVideoLesson(
			classroomId,
			VideoLessonResourceTypes.CLASSROOM
		);
		const videoLink = !videoLesson.doc ? null : videoLesson.doc.url;
		const isPremium = classroom.doc ? classroom.doc.isPremium() : false;

		const lessonStatus = useVideoLessonStatus(
			videoLesson.doc,
			classroom.doc?.classTimes
		);
		const isVideoLessonWithinAcademicRange =
			lessonStatus === VideoLessonStatus.ACTIVE;

		const enterAssignemnts = useCallback(() => {
			history.push(`/classrooms/${classroom.doc?._id}`);
		}, [history, classroom.doc]);

		const gradeId = classroom.doc?.grade || null;
		const grade = useGradeById(gradeId);

		const handleOpenVideolink = useCallback(() => {
			onTeacherOpenVideoLesson(
				classroomId,
				VideoLessonResourceTypes.CLASSROOM
			);
		}, [classroomId]);

		const isArchiveable = useMemo(() => {
			if (classroom.doc?.isPremium() && !classroom.doc?.isArchived) {
				return false;
			}
			return true;
		}, [classroom.doc]);

		const handleArchiveClick = useCallback(() => {
			if (classroom.doc) {
				const updateArchiveStatus = () => {
					inject("ClassroomsController").update({
						_id: classroomId,
						isArchived: !classroom.doc.isArchived,
					});
				};
				openConfirmationPopup({
					text: classroom.doc.isArchived
						? getFormattedMessage("unarchiveQuestion")
						: getFormattedMessage("archiveQuestion"),
					approveTitle: getFormattedMessage("yes"),
					rejectTitle: getFormattedMessage("no"),
					onApprove: updateArchiveStatus,
					displayRejectButtonAsPrimary: !classroom.doc.isArchived,
				});
			}
		}, [classroom.doc, classroomId]);

		if (
			!classroom.isSuccessfullyLoaded ||
			!subjects.isSuccessfullyLoaded ||
			!grade.isSuccessfullyLoaded
		) {
			return <TVSchoolClassroomBoxLoader />;
		}

		const subjectTitle = subjects.doc
			.map(subject => subject.name)
			.join(", ");
		const useFullName = classroom.doc.name !== subjectTitle; // use this logic temporarily

		return (
			<div className={styles.classroomBox} onClick={enterAssignemnts}>
				{useFullName ? (
					<div className={styles.classname}>{classroom.doc.name}</div>
				) : (
					<div
						className={styles.classname}
						dangerouslySetInnerHTML={{
							__html: grade.doc.getFullName() || "",
						}}
					/>
				)}
				<div className={styles.subject}>
					{subjects.doc.map(subject => subject.name).join(", ")}
				</div>
				<button
					onClick={event => {
						event.stopPropagation();
						enterAssignemnts();
					}}
					className={styles.sentHomeworks}
				>
					<SentHomeworkIcon className={styles.icon} />
					<span>
						<FormattedMessage id="sentAssignments" />
					</span>
				</button>
				{!isPremium && (
					<button
						onClick={event => {
							event.stopPropagation();
							handleNewAssignmentAdd();
						}}
						className={styles.newHomework}
					>
						<FormattedMessage id="newAssignmentForTeacher" />
					</button>
				)}
				{classTimesPrev && (
					<ClassTimesPreview
						{...classTimesPrev}
						classTimes={classroom.doc.classTimes}
						displacement={15}
					/>
				)}
				{isPremium && (
					<button
						onClick={event => {
							event.stopPropagation();
							handleOpenVideolink();
						}}
						onMouseOver={handleVideoLessonButtonOver}
						onMouseOut={() => setClassTimesPrev(undefined)}
						className={
							!videoLink
								? classNames(styles.videolink, styles.nolink)
								: isVideoLessonWithinAcademicRange
								? classNames(
										styles.videolink,
										styles.activelink
								  )
								: styles.videolink
						}
					>
						ვიდეოგაკვეთილი
						<DuoIcon className={styles.icon} />
					</button>
				)}
				{enableArchive && (
					<ArchiveToggleButton
						isArchiveable={isArchiveable}
						isArchived={!!classroom.doc.isArchived}
						onClick={handleArchiveClick}
					/>
				)}
			</div>
		);
	}
);

const ArchiveToggleButton = React.memo<{
	isArchived: boolean;
	isArchiveable: boolean;
	onClick: () => void;
}>(function ArchiveToggleButton({ isArchived, isArchiveable, onClick }) {
	return (
		<div
			style={{
				height: "16px",
			}}
		>
			{isArchiveable && (
				<div className={styles.makeClassroomArchive}>
					<span
						onClick={event => {
							event.stopPropagation();
							onClick();
						}}
					>
						{isArchived
							? getFormattedMessage("makeClassroomUnarchive")
							: getFormattedMessage("makeClassroomArchive")}
					</span>
				</div>
			)}
		</div>
	);
});

export const TVSchoolClassroomBoxesContainer: React.FC<{
	classroomIds: ObjectId[];
	enableArchive?: boolean;
	groups: Group[] | null;
	displayTimetable: boolean;
}> = React.memo(
	({ classroomIds, groups, enableArchive = false, displayTimetable }) => {
		const locale = useLocale();
		const isHeadTeacher = !!groups && groups.length > 0;
		return (
			<React.Fragment>
				{isHeadTeacher && (
					<>
						<div className={styles.classroomsContainer}>
							<div className={styles.marginContainerStart}>
								{groups!.map(gr => (
									<GroupBox group={gr} key={gr._id} />
								))}
								<SendCognitiveAssignmentBox />
							</div>
						</div>
					</>
				)}
				<div className={styles.classroomsContainer}>
					<div
						className={styles.marginContainerStart}
						style={
							isHeadTeacher
								? {
										borderTop: "1.5px solid #aeb5c9",
										paddingTop: "20px",
								  }
								: {
										borderTop: "initial",
										paddingTop: "initial",
								  }
						}
					>
						{displayTimetable && <Timetable />}
						{classroomIds.map(classroomId => (
							<TVSchoolClassroomBox
								key={classroomId}
								classroomId={classroomId}
								enableArchive={enableArchive}
							/>
						))}
						<CreateClassroomBox />
						{locale === "ka" && isCertificateServicesAvaliable && (
							<CertificateBox imgSize={150} />
						)}
						{locale === "ka" && isCertificateServicesAvaliable && (
							<StudentCertificatesBox />
						)}
						{locale === "ka" && <ApprovedPublicAssignments />}
						{!isHeadTeacher && <SendCognitiveAssignmentBox />}
					</div>
				</div>
			</React.Fragment>
		);
	}
);

export const TVSchoolClassroomBoxesLoaderContainer = () => {
	return (
		<div className={styles.classroomsContainer}>
			<MarginContainer itemsMargin={20} style={{ textAlign: "center" }}>
				{new Array(7).fill(0).map((e, i) => (
					<TVSchoolClassroomBoxLoader key={i} />
				))}
			</MarginContainer>
		</div>
	);
};

const SendCognitiveAssignmentBox = React.memo(() => {
	const history = useHistory();
	const classroms = useMyClassroomsAsTeacher();
	const [cognitiveClassroomId, setCognitiveClassroomId] = useState<string>();

	useEffect(() => {
		inject("CoursesInfoController")
			.getCognitiveCourseInfo()
			.then(e =>
				classroms?.forEach(x => {
					if (x.course.originalCourseId === e.courseId) {
						setCognitiveClassroomId(x._id);
					}
				})
			)
			.catch(e =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, [classroms]);

	const handleRedirection = useCallback(
		() =>
			cognitiveClassroomId
				? history.push(
						`/cognitive-assignment/results/${cognitiveClassroomId}`
				  )
				: history.push(`/cognitive-assignment/new`),
		[cognitiveClassroomId, history]
	);

	return (
		<div
			className={styles.sendTestToNewClass}
			onClick={handleRedirection}
			style={{ backgroundColor: "white" }}
		>
			<img
				alt="buld"
				src="/imgs/cognitive-bulb.svg"
				className={styles.sendCognitiveAssignmentBuld}
			/>
			<span className={styles.sendCognitiveTitle}>
				შემეცნებითი ტესტები
			</span>
		</div>
	);
});

export const getNewAssignmentUrl = (classroom: Classroom): string | null => {
	const grade = classroom.grade;
	const subject = classroom.subjects[0];
	const courseId = classroom.course.originalCourseId;
	if (!subject) return null;
	return `/assignments/new?g=${grade}&s=${subject}&originalCourseId=${courseId}&classroomIds=${classroom._id}`;
};
