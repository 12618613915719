import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import FillingBalnksEditor from "../filling-blanks/edit";
import FillingBlanks from "../filling-blanks/class";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import memoizeOne from "memoize-one";
import MultipleChoice from "../multiple-choice/class";
import MultipleChoiceEditor from "../multiple-choice/edit";
import MultipleContents from "./class";
import Select from "react-select";
import SortItems from "../sort-items/class";
import SortItemsEditor from "../sort-items/edit";
import {
	addButtonClassName,
	IEditableStat,
	IItemEditClassNames,
	ItemEdit,
	itemEditClassName,
	itemEditContainerClassName,
} from "../common/items-edit";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	Omit,
} from "../../../../utils/generics";
import {
	ContentType,
	ITextStatement,
} from "../../../../schemas/questions/contnets/common-schemas";
import { css } from "emotion";
import {
	EditorText,
	IAnyObj,
	mergeComponentObjects,
	mergeStylesObjects,
} from "../../../../utils";
import {
	IContentEditor,
	IContentEditorProps,
	ContentError,
} from "../interfaces";
import { IEditCommonProps, IQContentEditPassableProps } from "../edit";
import { IExplanationTexts } from "../common/explanation";
import {
	IMultipleContents,
	IRMultipleContents,
} from "../../../../schemas/questions/contnets/multiple-contents/schema";
import { IProps as MUCTestMode } from "./";
import { IProps as IMCProps } from "../multiple-choice/edit";
import { IProps as IFBProps } from "../filling-blanks";
import { IProps as ISIProps } from "../sort-items";
import { ItemAfterLabel } from "../common/items";
import { removeKeys } from "../../../../utils/common";

interface IMUCEditComponents {
	contentsContainers?: {
		mc?: AnyComponent<IMCProps>;
		si?: AnyComponent<ISIProps>;
		fb?: AnyComponent<IFBProps>;
		muc?: AnyComponent<IProps>;
	};
}

interface ITexts {
	explanation?: IExplanationTexts;
}

type MUCTestModeOmits =
	| "content"
	| "onUserAnswerChange"
	| "disableEditingAnswer"
	| "displayAnswer"
	| "mucProps";

export type IProps = Partial<IEditCommonProps> &
	Omit<MUCTestMode, MUCTestModeOmits> & {
		content: IMultipleContents;
		toHTML: (editorState: EditorText) => string;
		toEditorState: (html: string) => EditorText;
		components?: IMUCEditComponents;
	} & IContentEditorProps &
	IQContentEditPassableProps;

interface IState {
	itemRefs: React.RefObject<any>[];
	items: IMultipleContents["items"];
	allowPartialCredit?: IMultipleContents["allowPartialCredit"];
	explanation?: Omit<
		NotUndefined<IMultipleContents["explanation"]>,
		"text"
	> & { text: EditorText };
	restrictViewingPagesBeforehand?: IMultipleContents["restrictViewingPagesBeforehand"];
	isAddingNewItem: boolean;
}

class MultipleContentsEditor extends React.PureComponent<IProps, IState>
	implements IContentEditor<IMultipleContents> {
	defaultComponents = {
		contentsContainers: {
			mc: MultipleChoiceEditor,
			si: SortItemsEditor,
			fb: FillingBalnksEditor,
			muc: MultipleContentsEditor,
		},
	} as NotUndefinedAtAll<IMUCEditComponents>;

	getComponents = memoizeOne((components: IProps["components"]) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	constructor(props: IProps) {
		super(props);
		this.state = {
			...props.content,
			itemRefs: props.content.items.map(e => React.createRef()),
			explanation: props.content.explanation
				? {
						...props.content.explanation,
						text: this.props.toEditorState(
							props.content.explanation.text
						),
				  }
				: undefined,
			isAddingNewItem: false,
		};
	}

	getCurrentIds = () => {
		const ids: number[] = [];
		this.state.items.forEach(e => ids.push(e.id));
		this.state.itemRefs.forEach(
			e =>
				e.current &&
				typeof e.current.getCurrentIds === "function" &&
				ids.push(...e.current!.getCurrentIds())
		);
		if (this.state.explanation) ids.push(this.state.explanation.id);
		return ids;
	};

	getData = () => {
		const expText = this.state.explanation
			? this.props.toHTML(this.state.explanation.text)
			: undefined;
		return {
			...removeKeys(this.state, "itemRefs", "isAddingNewItem"),
			type: ContentType.MultipleContents as ContentType.MultipleContents,
			items: this.state.itemRefs.map((ref, i) => ({
				...this.state.items[i],
				content: ref.current!.getData(),
			})),
			explanation: expText
				? { ...this.state.explanation!, text: expText }
				: undefined,
		};
	};

	getErrors = () => {
		const errors: ContentError[] = [];
		for (let i = 0; i < this.state.itemRefs.length; ++i) {
			const ref = this.state.itemRefs[i];
			errors.push(
				...(ref.current!.getErrors() as ContentError[]).map(e => ({
					...e,
					details: {
						index: i,
						itemDetails: e.details,
					},
				}))
			);
		}
		return errors;
	};

	getAppropriateComponent = (
		type: Pick<
			| IRMultipleContents["items"][number]
			| IMultipleContents["items"][number],
			"content"
		>["content"]["type"]
	): { Component: AnyComponent<IAnyObj>; customProps?: IAnyObj } => {
		const components = this.getComponents(this.props.components);
		switch (type) {
			case ContentType.MultipleChoice:
				return {
					Component: components.contentsContainers.mc,
					customProps: this.props.mcProps,
				};
			case ContentType.SortItems:
				return {
					Component: components.contentsContainers.si,
					customProps: this.props.siProps,
				};
			case ContentType.FillingBlanks:
				return {
					Component: components.contentsContainers.fb,
					customProps: this.props.fbProps,
				};
			case ContentType.Text:
				return { Component: TextEditor };
			case ContentType.MultipleContents:
				return {
					Component: components.contentsContainers.muc,
					customProps: this.props,
				};
			default:
				throw new Error(`Content Type ${type} is not supported`);
		}
	};

	onAddItemClick = () => {
		this.setState({
			isAddingNewItem: true,
		});
	};

	onAddItemCancel = () => {
		this.setState({
			isAddingNewItem: false,
		});
	};

	getContent = (type: ContentType) => {
		switch (type) {
			case ContentType.Text:
				return {
					text: "",
					type,
				} as ITextStatement;
			case ContentType.MultipleChoice:
				return MultipleChoice.getEmptyContent();
			case ContentType.SortItems:
				return SortItems.getEmptyContent();
			case ContentType.FillingBlanks:
				return FillingBlanks.getEmptyContent();
			case ContentType.MultipleContents:
				return MultipleContents.getEmptyContent();
			default:
				throw new Error(`item type ${type} is not supported`);
		}
	};

	onItemAdd = (type: ContentType) => {
		const newIds = this.props.generateUniqueIds(1);
		this.setState({
			isAddingNewItem: false,
			itemRefs: [...this.state.itemRefs, React.createRef()],
			items: [
				...this.state.items,
				{
					id: newIds[0],
					content: this.getContent(type),
					settings: {},
				},
			],
		});
	};

	onItemRemove = (index: number) => {
		this.setState({
			items: this.state.items.filter((e, i) => i !== index),
			itemRefs: this.state.itemRefs.filter((e, i) => i !== index),
		});
	};

	onNextContentOnSamePageChange = (index: number, val: boolean) => {
		this.setState({
			items: this.state.items.map((e, i) =>
				i !== index
					? e
					: {
							...e,
							settings: {
								...e.settings,
								nextContentOnSamePage: val,
							},
					  }
			),
		});
	};

	onRestrictChange = (e, val: boolean) => {
		this.setState({
			restrictViewingPagesBeforehand: val ? true : undefined,
		});
	};

	render() {
		return (
			<div>
				{this.state.items.map((item, i) => {
					const {
						Component,
						customProps,
					} = this.getAppropriateComponent(item.content.type);
					return (
						<div
							key={item.id}
							className={itemEditContainerClassName}
						>
							<CloseIcon onClick={() => this.onItemRemove(i)} />
							<Component
								{...this.props}
								{...customProps}
								content={item.content}
								key={item.id}
								ref={this.state.itemRefs[i]}
								userAnswer={
									this.props.userAnswer &&
									this.props.userAnswer[item.id]
								}
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											item.settings
												? !!item.settings
														.nextContentOnSamePage
												: false
										}
										onChange={(e, c) =>
											this.onNextContentOnSamePageChange(
												i,
												c
											)
										}
									/>
								}
								label="Next Content On Same Page"
							/>
						</div>
					);
				})}
				{!this.state.isAddingNewItem ? (
					<button
						onClick={this.onAddItemClick}
						className={addButtonClassName}
					>
						<AddIcon />
						<span>Add</span>
					</button>
				) : (
					<ChooseNewItem
						onChange={this.onItemAdd}
						onCancel={this.onAddItemCancel}
					/>
				)}
				<FormControlLabel
					control={
						<Checkbox
							checked={
								!!this.state.restrictViewingPagesBeforehand
							}
							onChange={this.onRestrictChange}
						/>
					}
					label="Restrict viewing contents beforehand"
				/>
			</div>
		);
	}
}

interface IChooseNewItemTexts {
	text?: string;
	mc?: string;
	si?: string;
	fb?: string;
	muc?: string;
	chooseItemType?: string;
}
interface IChooseNewItemProps {
	onChange: (value: ContentType) => void;
	onCancel: () => void;
	texts?: IChooseNewItemTexts;
}

const chooseNewItemClassName = css`
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #ccc;
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
`;

const selectCont = css({
	position: "relative",
	zIndex: 2,
	display: "flex",
	alignItems: "center",
	minWidth: 200,
	"&>div:first-child": {
		width: "100%",
	},
});

class ChooseNewItem extends React.PureComponent<IChooseNewItemProps> {
	getOptions = memoizeOne((texts: IChooseNewItemTexts = {}) => {
		return [
			{ value: ContentType.Text, label: texts.text || "text" },
			{
				value: ContentType.MultipleChoice,
				label: texts.mc || "Multiple Choice",
			},
			{
				value: ContentType.SortItems,
				label: texts.si || "Sorting Items",
			},
			{
				value: ContentType.FillingBlanks,
				label: texts.fb || "Filling Blanks",
			},
			{
				value: ContentType.MultipleContents,
				label: texts.muc || "Multiple Contents",
			},
		];
	});

	getComponents = memoizeOne(() => {
		return {
			selectAfter: props => (
				<ItemAfterLabel
					{...props}
					id={null}
					onDelete={this.props.onCancel}
					deleteText="Delete"
				/>
			),
		};
	});

	onChange = (checked: { value: ContentType; label: string }) => {
		this.props.onChange(checked.value);
	};

	render() {
		const options = this.getOptions(this.props.texts);
		const chooseItemsText =
			(this.props.texts && this.props.texts.chooseItemType) ||
			"Choose item type";
		const components = this.getComponents();
		return (
			<div className={chooseNewItemClassName}>
				<div className={selectCont}>
					<Select
						onChange={this.onChange}
						options={options}
						placeholder={chooseItemsText}
					/>
					<components.selectAfter />
				</div>
			</div>
		);
	}
}

type ITextEditorStyles = IItemEditClassNames;
interface ITextEditorTexts {
	placeholder?: string;
}

export type ITextEditorProps = Partial<IEditCommonProps> & {
	toHTML: (editorState: EditorText) => string;
	toEditorState: (html: string) => EditorText;
	content: Omit<ITextStatement, "id">;
	styles?: ITextEditorStyles;
	texts?: ITextEditorTexts;
} & IContentEditorProps;

interface ITextEditorStats {
	text: EditorText;
}

class TextEditor extends React.PureComponent<
	ITextEditorProps,
	ITextEditorStats
> {
	defaultStyles = {
		container: itemEditContainerClassName,
		text: itemEditClassName,
	} as NotUndefinedAtAll<ITextEditorProps["styles"]>;

	getStyles = memoizeOne((styles: ITextEditorProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	defaultTexts = {
		placeholder: "Enter statement",
	} as NotUndefinedAtAll<ITextEditorProps["texts"]>;

	getTexts = memoizeOne((texts: ITextEditorProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	getErrors() {
		return [];
	}

	constructor(props: ITextEditorProps) {
		super(props);
		this.state = {
			text: this.props.toEditorState(props.content.text),
		};
	}

	onChange = (stat: IEditableStat) => {
		this.setState({
			text: stat.text,
		});
	};

	getData() {
		return {
			...this.props.content,
			text: this.props.toHTML(this.state.text),
		};
	}

	getCurrentIds() {
		return [] as number[];
	}

	render() {
		const styles = this.getStyles(this.props.styles);
		const texts = this.getTexts(this.props.texts);
		return (
			<div>
				<ItemEdit
					galleryComponent={this.props.galleryComponent}
					stat={{ id: 1, text: this.state.text }}
					onChange={this.onChange}
					styles={styles}
					placeholder={texts.placeholder}
				/>
			</div>
		);
	}
}

export default MultipleContentsEditor;
