import { UserType } from "@app/api/helper-schemas";
import { getCurrentWebsite } from "@app/globals";
import { useHistory } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { inject } from "@app/modules";
import React, { useCallback, useState } from "react";
import { StudentLogin } from "../auth/login";
import { StudentRegisterFirstStep } from "../auth/student-register-page";
import LandingMainPage from "../landing";
import { MobileEnterLogic } from "../profile/mobile-verification";
import touImg from "../users/tests/styles/imgs/tou-logo.png";
import Popup, { PopupContent, PopupContentWithClose } from "../widgets/popup";
import { PopupsNullifier } from "../widgets/prioritizer";
import { ReactComponent as Loginllustration } from "./styles/imgs/35.svg";
import { ReactComponent as DesignFeedback } from "./styles/imgs/design_feedback.svg";
import styles from "./styles/orientation.module.css";

enum State {
	initial,
	existingUser,
	newUser,
}
export const ProfOrientationLanding = () => {
	return (
		<>
			<PopupsNullifier />
			<LandingMainPage />
			<ProfOrientationLanding2 />
		</>
	);
};

const ProfOrientationLanding2: React.FC<{ onClose?: () => void }> = props => {
	const [mobile, setMobile] = useState("");
	const [state, setState] = useState(State.initial);
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const handleSubmission = () => {
		const UsersController = inject("UsersController");
		startLoading();
		UsersController.getMobileExistence({
			mobile,
		})
			.then(data => {
				stopLoading();
				setState(data.exists ? State.existingUser : State.newUser);
				console.log(data);
			})
			.catch(() => {
				stopLoading();
			});
		console.log("aa");
	};

	return (
		<div>
			{state === State.initial && (
				<Popup onClose={props.onClose}>
					<PopupContent
						style={{
							backgroundColor: "#eaecf4",
							color: "#252423",
						}}
					>
						<MobileEnterLogic
							onSubmit={handleSubmission}
							onChange={e => setMobile(e.target.value)}
							isLoading={isLoading}
						/>
					</PopupContent>
				</Popup>
			)}
			{state === State.existingUser && (
				<SuggestLogin
					mobile={mobile}
					title="თქვენ უკვე გაქვთ ჰუბუს ექაუნთი, ტესტირების პასუხის გასაგებად, გთხოვთ, გაიარეთ ავტორიზაცია"
					redirectTo="/prof-test?hasWritten=true"
					onClose={props.onClose}
				/>
			)}
			{state === State.newUser && (
				<SuggestRegistration
					mobile={mobile}
					title="თქევნი ტელეფონის ნომრით ჰუბუზე არ არის ექაუნთი რეგისტრირებული, პასუხების გასაგებად, გთხოვთ, გაიაროთ რეგისტრაცია"
					redirectTo="/prof-test?hasWritten=true"
					onClose={props.onClose}
				/>
			)}
		</div>
	);
};

const SuggestLogin: React.FC<{
	onClose?: () => void;
	mobile: string;
	title: string;
	redirectTo: string;
}> = props => {
	const history = useHistory();
	const redirectToTest = useCallback(() => {
		history.push(props.redirectTo);
	}, [history, props.redirectTo]);

	return (
		<Popup onClose={props.onClose}>
			<PopupContent
				style={{
					backgroundColor: "#eaecf4",
				}}
			>
				<StudentLogin
					onSuccess={redirectToTest}
					divTitle={props.title}
					defaultCredentials={{
						password: "",
						mobile: props.mobile,
						mail: null,
					}}
					isRegisterPageOpen={false}
					hideRegisterPage={() => {
						//
					}}
					openRegisterPage={() => {
						//
					}}
				/>
			</PopupContent>
		</Popup>
	);
};

const SuggestRegistration: React.FC<{
	onClose?: () => void;
	mobile: string;
	title: string;
	redirectTo: string;
}> = props => {
	const history = useHistory();
	const redirectToTest = useCallback(() => {
		history.push(props.redirectTo);
	}, [history, props.redirectTo]);

	return (
		<Popup onClose={props.onClose}>
			<PopupContent
				style={{
					backgroundColor: "#eaecf4",
				}}
			>
				<StudentRegisterFirstStep
					userType={UserType.student}
					onSuccess={redirectToTest}
					componentName={props.title}
					defaultCredentials={{
						password: "",
						mobile: props.mobile,
						firstname: "",
						lastname: "",
						specialCode: null,
						isChecked: false,
						country: null,
						mail: null,
						registrationOrigin: getCurrentWebsite(),
					}}
				/>
			</PopupContent>
		</Popup>
	);
};

export const ProfOrientationInfoPopup: React.FC<{ onClose: () => void }> = ({
	onClose,
}) => {
	const [testState, setTestState] = useState<"result" | "test">();
	if (!testState) {
		return (
			<Popup onClose={onClose}>
				<PopupContentWithClose
					style={{
						backgroundColor: "#eaecf4",
					}}
					onClose={onClose}
				>
					<div className={styles.sponsorContainer}>
						<img src={touImg} alt="logo" /> თბილისი ღია
						უნივერსიტეტის თანადაფინანსებით
					</div>
					<div className={styles.profOrientationInfoContainer}>
						<div>
							<DesignFeedback />
						</div>
						<div>
							<span>
								გაიგე რომელი პროფესია შეგესაბამება პროფ
								ორიენტაციის ტესტის მეშვეობით
							</span>

							<div className={styles.buttons}>
								<button
									className={styles.startTestButton}
									onClick={() => setTestState("test")}
								>
									ტესტის დაწერა
								</button>
								<button
									className={styles.seeResultsButton}
									onClick={() => setTestState("result")}
								>
									ტესტის შედეგები
								</button>
							</div>
						</div>
					</div>
				</PopupContentWithClose>
			</Popup>
		);
	}
	if (testState === "result") {
		return <ProfOrientationLanding2 onClose={onClose} />;
	}
	return (
		<ProfOrientationAuthenticationRequestPopup
			onClose={onClose}
			testState={testState}
		/>
	);
};
//
export const ProfOrientationAuthenticationRequestPopup: React.FC<{
	onClose: () => void;
	testState: "result" | "test";
}> = props => {
	const {
		value: isRegistrationPopupOn,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);
	const { value: isLoginPopupOn, setTrue: openLoginPopup } = useBoolean(
		false
	);

	if (!isRegistrationPopupOn && !isLoginPopupOn) {
		return (
			<Popup onClose={props.onClose}>
				<PopupContentWithClose
					style={{
						backgroundColor: "#eaecf4",
						color: "#252423",
					}}
					onClose={props.onClose}
				>
					<div className={styles.profOrientationInfoContainer}>
						<div>
							<Loginllustration />
						</div>
						<div>
							<span>
								ტესტის დასაწყებად გაიარეთ ავტორიზაცია ან
								დარეგისტრირდით
							</span>
							<br />
							<br />
							<div className={styles.buttons}>
								<button
									className={styles.startTestButton}
									onClick={openLoginPopup}
								>
									შესვლა
								</button>
								<button
									className={styles.seeResultsButton}
									onClick={openRegistrationPopup}
								>
									რეგისტრაცია
								</button>
							</div>
						</div>
					</div>
				</PopupContentWithClose>
			</Popup>
		);
	}

	if (isRegistrationPopupOn) {
		return (
			<SuggestRegistration
				onClose={props.onClose}
				mobile=""
				title="რეგისტრაცია"
				redirectTo={
					props.testState === "result"
						? `/prof-test?hasWritten=true`
						: `/prof-test`
				}
			/>
		);
	}
	if (isLoginPopupOn) {
		return (
			<SuggestLogin
				onClose={props.onClose}
				mobile=""
				title="ავტორიზაცია"
				redirectTo={
					props.testState === "result"
						? `/prof-test?hasWritten=true`
						: `/prof-test`
				}
			/>
		);
	}

	return null;
};
