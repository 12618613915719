import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { CourseType } from "@app/api/courses-info/helper-schemas";
import { IRGETMyInfoAsStudent } from "@app/api/users/validators";
import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import {
	ClassTimesPreview,
	IClassTimesPreviewCoordinates,
} from "@app/components/teachers/main/tvschool/classtimes-preview";
import { isCertificateServicesAvaliable } from "@app/consts";
import { getCurrentWebsite, isLocalhost, WebsiteOrigin } from "@app/globals";
import { useGoToUrl } from "@app/hooks/front";
import { useGroupsByStudentId } from "@app/hooks/groups";
import { useLocale } from "@app/hooks/intl";
import { useMyInfoAsStudent } from "@app/hooks/student";
import { useSubjectsByIds } from "@app/hooks/subjects";
import { useUserShortInfo } from "@app/hooks/users";
import { useVideoLesson } from "@app/hooks/video-lessons";
import { Classroom } from "@app/models/classroom";
import { Group } from "@app/models/group";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { arrayToObject } from "@app/utils/common";
import { getFormattedMessage } from "@app/utils/locale";
import DuoIcon from "@material-ui/icons/Duo";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { HalfMoon } from "../../widgets/half-moon";
import { CognitiveBox } from "../cognitive-tests/cognitive-box";
import { OffersBox } from "../offers/box";
import { InvitedRegistrationPopupWrapper } from "../register-invitation/invited-popup";
import { StudentCertificateBox } from "./certificate";
import { LupiAd } from "./lupiAd";
import styles from "./styles/user-subject-list.module.css";

export const TVSchoolStudentMainPage = () => {
	const myInfo = useMyInfoAsStudent();
	const locale = useLocale();

	const classroomsById = useMemo(() => {
		if (!myInfo) return null;
		return arrayToObject(myInfo.classrooms, "_id");
	}, [myInfo]);

	const userId = myInfo?.userId;

	const groups = useGroupsByStudentId(userId);

	if (!myInfo || !classroomsById || !userId) return null;

	const ClassroomModel = inject("ClassroomModel");

	return (
		<div className={styles.userSubjectList_page}>
			<HalfMoon
				title={
					myInfo.classroomIds.length === 0
						? ""
						: getFormattedMessage("myClassrooms")
				}
			/>
			<InvitedRegistrationPopupWrapper />
			<div className={styles.userSubjectListBoxContainer}>
				{myInfo.classroomIds.length === 0 && (
					<ClassroomlessStudentWelcomeText />
				)}

				{groups.map(group => (
					<GroupBox key={group._id} group={group} />
				))}

				<TimeTableBox classrooms={myInfo.classrooms} />

				{myInfo.classroomIds.map(classroomId => {
					const classroom = classroomsById[classroomId];
					if (!classroom) return null;
					const classroomDoc = new ClassroomModel(classroom);
					if (!classroomDoc.isVisible()) return null;

					if (classroom.course.type === CourseType.cognitive) {
						return null;
					}
					return (
						<ClassroomBox key={classroomId} classroom={classroom} />
					);
				})}

				{locale === "ka" &&
					myInfo.classroomIds.length > 0 &&
					showOffersForStudent(userId) && <OffersBox />}
				{locale === "ka" && myInfo.classroomIds.length > 0 && (
					<CognitiveBox />
				)}
				{locale === "ka" &&
					isCertificateServicesAvaliable &&
					myInfo.classrooms.length > 0 && <StudentCertificateBox />}
				{locale === "ka" &&
					myInfo.classrooms.some(
						e => e.grade <= 9 && e.grade >= 2
					) && (
						<React.Fragment>
							<LupiAd
								title="დამატებითი მეცადინეობისთვის გამოიყენე"
								link="https://lupi.ge/?a=001#/"
							/>
						</React.Fragment>
					)}
			</div>
		</div>
	);
};

const GroupBox: React.FC<{
	group: Group;
}> = React.memo(({ group }) => {
	const videoLesson = useVideoLesson(
		group._id,
		VideoLessonResourceTypes.GROUP
	);
	const handleOpenVideolink = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		if (!videoLesson.isSuccessfullyLoaded || videoLesson.doc.url === "") {
			return;
		}
		window.open(videoLesson.doc.url, "_blank", "noopener,noreferrer");
	};

	const [lessonStatus, setLessonStatus] = useState(
		VideoLessonStatus.NOT_AVAILABLE
	);
	useEffect(() => {
		const status = videoLesson.doc?.getGroupStatus();
		if (status) setLessonStatus(status);
	}, [videoLesson.doc]);

	const mapStatusToStyles = useMemo(() => {
		switch (lessonStatus) {
			case VideoLessonStatus.NOT_AVAILABLE:
				return styles.nolink;
			case VideoLessonStatus.AVAILABLE:
				return styles.notActiveLesson;
			default:
				return "";
		}
	}, [lessonStatus]);
	return (
		<div className={styles.userSubjectListBox}>
			<img
				alt="buld"
				src="/imgs/classhead_illustration.svg"
				className={styles.illustration}
			/>

			<button
				onClick={
					lessonStatus !== VideoLessonStatus.NOT_AVAILABLE
						? handleOpenVideolink
						: undefined
				}
				className={classNames(styles.groupVideoLink, mapStatusToStyles)}
			>
				სადამრიგებლო საათი
			</button>
		</div>
	);
});

const TimeTableBox: React.FC<{ classrooms: IClassroom[] }> = React.memo(
	({ classrooms }) => {
		const hasToDisplayTimelineBox = classrooms
			.map(e => new Classroom(e))
			.filter(e => e.isVisible)
			.some(e => e.classTimes.length > 0);

		const redirect = useGoToUrl("/s/timetable");

		if (!hasToDisplayTimelineBox) return null;
		return (
			<div className={styles.userSubjectListBox} onClick={redirect}>
				<img
					alt="buld"
					src="/imgs/schedule_Illustration.svg"
					className={styles.illustration}
				/>

				<div className={styles.userSubjectListBox_btn}>ჩემი ცხრილი</div>
			</div>
		);
	}
);

const ClassroomBox: React.FC<{
	classroom: IRGETMyInfoAsStudent["classrooms"][number];
}> = React.memo(({ classroom }) => {
	const history = useHistory();
	const videoLesson = useVideoLesson(
		classroom._id,
		VideoLessonResourceTypes.CLASSROOM
	);
	const subjects = useSubjectsByIds(classroom.subjects || []);
	const teacherId = classroom.teacherId;
	const isDutch =
		useLocale() !== "ka" && getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const teacherInfo = useUserShortInfo(teacherId || null);
	const showTextFirst = isDutch;

	const isPremium = new Classroom(classroom).isPremium();

	const [classTimesPrev, setClassTimesPrev] = useState<
		IClassTimesPreviewCoordinates
	>();

	const enterClassroom = useCallback(() => {
		history.push(`/courses/${classroom.course.courseId}/my-assignments`);
	}, [history, classroom.course.courseId]);

	const handleOpenVideolink = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		if (!videoLesson.isSuccessfullyLoaded || videoLesson.doc.url === "") {
			return;
		}
		window.open(videoLesson.doc.url, "_blank", "noopener,noreferrer");
	};
	const [lessonStatus, setLessonStatus] = useState(
		VideoLessonStatus.NOT_AVAILABLE
	);
	useEffect(() => {
		const status = videoLesson.doc?.getClassroomStatus(
			classroom.classTimes
		);
		if (status) setLessonStatus(status);
	}, [classroom.classTimes, videoLesson.doc]);

	const mapStatusToStyles = useMemo(() => {
		switch (lessonStatus) {
			case VideoLessonStatus.NOT_AVAILABLE:
				return styles.nolink;
			case VideoLessonStatus.AVAILABLE:
				return styles.notActiveLesson;
			default:
				return "";
		}
	}, [lessonStatus]);

	if (
		!subjects.doc ||
		(teacherInfo.isIdentificationKnown && !teacherInfo.isSuccessfullyLoaded)
	) {
		return null;
	}

	const firstSubject = subjects.doc[0];
	const numNewHomeworks = classroom.numNewHomeworks;
	const numIncompleteHomeworks = classroom.numIncompleteHomeworks;
	return (
		<div className={styles.userSubjectListBox} onClick={enterClassroom}>
			{numNewHomeworks > 0 && (
				<span className={styles.task}>
					{getFormattedMessage("teacher:Assignment")}!
				</span>
			)}
			<div className={styles.userSubjectListBox_subjectInfo}>
				{firstSubject && firstSubject.photo && (
					<div
						className={styles.userSubjectListBox_subjectInfo__icon}
						style={{
							backgroundImage: `url(${firstSubject.photo})`,
						}}
					/>
				)}
				<div className={styles.userSubjectListBox_subjectInfo__box}>
					<p className={styles.userSubjectListBox_subjectInfo__title}>
						{subjects.doc.map(subject => subject.name).join(", ")}
					</p>
					{teacherInfo.isSuccessfullyLoaded && (
						<p
							className={
								styles.userSubjectListBox_subjectInfo__teacher
							}
						>
							{teacherInfo.doc.getFullName()}
						</p>
					)}
				</div>
			</div>
			{classTimesPrev && (
				<ClassTimesPreview
					{...classTimesPrev}
					classTimes={classroom.classTimes}
					displacement={15}
				/>
			)}

			{!isPremium ? (
				<div className={styles.taskBox}>
					<div
						className={classNames(
							styles.taskBox__newTask,
							styles.taskBox__num,
							!numNewHomeworks && styles.faded
						)}
					>
						{showTextFirst && (
							<span>
								<FormattedMessage id="newAssignmentForStudent" />
							</span>
						)}
						<span>{numNewHomeworks}</span>
						{!showTextFirst && (
							<FormattedMessage id="newAssignmentForStudent" />
						)}
					</div>

					<div
						className={classNames(
							styles.taskBox__unDoneTask,
							styles.taskBox__num,
							!numIncompleteHomeworks && styles.faded
						)}
					>
						{showTextFirst && (
							<span>
								<FormattedMessage id="isnotFinishedAssignment" />
							</span>
						)}
						<span>{numIncompleteHomeworks}</span>
						{!showTextFirst && (
							<FormattedMessage id="isnotFinishedAssignment" />
						)}
					</div>
				</div>
			) : (
				<button
					onClick={
						lessonStatus !== VideoLessonStatus.NOT_AVAILABLE
							? handleOpenVideolink
							: undefined
					}
					onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) => {
						if (classTimesPrev) return;
						setClassTimesPrev({
							top: e.clientY,
							left: e.clientX,
						});
					}}
					onMouseOut={() => setClassTimesPrev(undefined)}
					className={classNames(styles.videolink, mapStatusToStyles)}
				>
					ვიდეოგაკვეთილი
					<DuoIcon className={styles.icon} />
				</button>
			)}

			<div className={styles.userSubjectListBox_btn}>
				{isPremium ? (
					"დავალებების ნახვა"
				) : (
					<FormattedMessage id="enter" />
				)}
			</div>
		</div>
	);
});

const ClassroomlessStudentWelcomeText = React.memo(() => {
	return (
		<div className="main main2" style={{ marginBottom: 20 }}>
			<div style={{ textAlign: "center" }}>
				<div className={styles.classroomlessBox}>
					<span style={{ fontSize: 20 }}>
						<FormattedMessage id="studentSuccessfullyFinishesRegistration" />
					</span>
					<br />
					<br />

					<FormattedMessage
						id="suggestionAfterRegistration"
						values={{ br: <br /> }}
					/>
					<br />
					<br />
					<img
						style={{ width: 800, maxWidth: "90%" }}
						src="/imgs/students-chill.svg"
						alt="students chill"
					/>
				</div>
			</div>
		</div>
	);
});

const showOffersForStudent = (userId: number): boolean => {
	if (1 < 2) return true; // TODO: always return true
	if (isLocalhost()) return true;
	return [211].indexOf(userId) > -1;
};
