import { IStudentPermissions } from "@app/api/users/helper-schemas";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds, RCanAccess } from "./interfaces";

export class StudentPermissions extends UserPermissions {
	constructor(private readonly permissions: IStudentPermissions) {
		super();
	}

	getPacket() {
		return this.permissions.packet;
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	hasConfirmedChild(): boolean {
		return false;
	}

	getAvailableClassrooms(): ObjectId[] {
		return this.permissions.availableClassrooms;
	}

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(): boolean {
		return false;
	}

	canStudyInClassroom(classroomId: ObjectId): boolean {
		return (
			!!this.permissions &&
			this.permissions.availableClassrooms.some(id => id === classroomId)
		);
	}

	canAccessGroup(): boolean {
		return false;
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return {
			isKnown: true,
			hasAll: false,
			ids: this.permissions.availableCourses,
		} as const;
	}

	canAccessFolder({
		courseId,
		folderId,
	}: {
		courseId: ObjectId;
		folderId: ObjectId;
	}): RCanAccess {
		const canAccessCourse = this.canAccessCourse(courseId);
		if (!canAccessCourse.isKnown) {
			return { isKnown: false };
		}
		if (
			this.permissions.packet ||
			getCurrentWebsite() !== WebsiteOrigin.hubu
		) {
			return { isKnown: true, canAccess: true };
		}
		const avaiableFolderIds = [
			"5d6e40c4bc7fc46bbd1f95a8",
			"5e080af99f3efc7b5914c236",
			"5d6e6cdcbc7fc46bbd1f95af",
			"5d6e3596bc7fc46bbd1f95a1",
			"5e080e759f3efc7b5914cbc0",
			"5d6e3636bc7fc46bbd1f95a6",
			"5e33c4ecdd33074a75179ff9",
			"5e33cbccdd33074a7517a00a",
			"5e37c19ddd33074a7517b5f5",
			"5e4799a7e2b48c5377164060",
			"5e37c1f5dd33074a7517b5f7",
		]; // FIXME: remove this logic and replace with robust one
		if (avaiableFolderIds.indexOf(folderId) > -1) {
			return { isKnown: true, canAccess: true };
		}
		return { isKnown: true, canAccess: false };
	}
}
