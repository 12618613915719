import { IParentPermissions } from "@app/api/users/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds } from "./interfaces";

export class ParentPermissions extends UserPermissions {
	constructor(private readonly permissions: IParentPermissions) {
		super();
	}

	getAvailableClassrooms(): ObjectId[] {
		return [];
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(): boolean {
		return false;
	}

	canStudyInClassroom(): boolean {
		return false;
	}

	hasConfirmedChild(childId: number): boolean {
		return !!this.permissions.confirmedChildrenIds?.includes(childId);
	}

	canAccessGroup(): boolean {
		return false;
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return {
			isKnown: true,
			hasAll: false,
			ids: [],
		};
	}
}
