import FancyLoading from "@app/components/widgets/fancy-loading";
import Popup from "@app/components/widgets/popup";
import { useBoolean, useUnsafeDynamicRef } from "@app/hooks/general";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import classNames from "classnames";
import React, {
	useCallback,
	useContext,
	useEffect,
	useState,
	useMemo,
} from "react";
import {
	HomeworkContext,
	TaskTypesContext,
	TopicsContext,
	Content,
	initialEmptyContent,
	SortedContentList,
	SortedContentSingleItem,
	SortedContentNestedItem,
	ContentContext,
} from "..";
import { Selection } from "../../selections";
import styles from "../../styles/styles.module.css";
import { TopicsSelect } from "../small-components/topics-select";
import { CheckBox } from "./checkbox";
import {
	QuestionContent,
	getQuestionsCustomizedPropsForTeacher,
} from "./content";
import { BigActionButton } from "../second-step";
import { arrayToObject } from "@app/utils/common";
import CloseIcon from "@material-ui/icons/Close";
import {
	getInitialSortedContent,
	getPartitionedContent,
	getNewSelection,
} from "../helper-functions";
import { PartitionedContentList } from "./questions-sections";
import { ItemType } from "@app/api/folders/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { useLocale } from "@app/hooks/intl";
import { MarginContainer } from "@app/components/ui/containers";
import QuestionEditwrapper from "@tests-core/components/questions/contents/edit-wrapper";
import { ContentError } from "@tests-core/components/questions/contents/interfaces";
import styled from "@emotion/styled";

export interface SelectedContentArr {
	questions: ObjectId[];
	texts: ObjectId[];
}

interface AdditionalContentPopupProps {
	onClose: () => void;
	classroomId: ObjectId;
	onSubmit: (selectionContent: SelectedContentArr, content: Content) => void;
}

const initialSelectedContent: SelectedContent = {
	questions: {},
	texts: {},
};
export const AdditionalContentPopup: React.FC<AdditionalContentPopupProps> = React.memo(
	props => {
		const [homework] = useContext(HomeworkContext);
		const [, addExtraContent] = useContext(ContentContext);
		const [isLoading, setIsLoading] = useState(true);
		const homeworkRef = useUnsafeDynamicRef(homework);
		const topicsInfo = useContext(TopicsContext);
		const [localContent, setLocalContent] = useState<Content>(
			initialEmptyContent
		);
		const [sortedContent, setSortedContent] = useState<SortedContentList>();
		const [selectedTopics, setSelectedTopics] = useState([] as ObjectId[]);
		const [selectedContent, setSelectedContent] = useState(
			initialSelectedContent
		);
		const [selectedTaskTypes, setSelectedTaskTypes] = useState(
			{} as Record<string, true | undefined>
		);

		const selectedContentRef = useUnsafeDynamicRef(selectedContent);
		const localContentRef = useUnsafeDynamicRef(localContent);

		const onContentSelectionChange = useCallback(
			(contentItem: SortedContentList[number]) => {
				setSelectedContent(selectedContent => {
					return getNewSelection(
						contentItem,
						selectedContent,
						localContentRef.current
					);
				});
			},
			[localContentRef]
		);

		useEffect(() => {
			let isCancelled = false;
			setIsLoading(true);
			const AssignmentsController = inject("AssignmentsController");
			const taskTypes = Object.keys(selectedTaskTypes);
			AssignmentsController.getTaskTypeContents({
				classroomId: props.classroomId,
				getAnswers: true,
				numQuestions: {
					total: 30,
				},
				topicIds:
					selectedTopics.length > 0 ? selectedTopics : undefined,
				taskTypes: taskTypes.length > 0 ? taskTypes : undefined,
			})
				.then(data => {
					if (isCancelled) return;
					setLocalContent(oldContent => ({
						questions: {
							...oldContent.questions,
							...arrayToObject(
								data.questions as IFullQuestion[],
								"_id"
							),
						},
						texts: {
							...oldContent.texts,
							...arrayToObject(data.texts, "_id"),
						},
					}));
					setSortedContent(
						getInitialSortedContent({
							questions: data.questions as IFullQuestion[],
							texts: data.texts,
						})
					);
					setIsLoading(false);
				})
				.catch(() => {
					//
				});
			return () => {
				isCancelled = true;
			};
		}, [homeworkRef, selectedTopics, selectedTaskTypes, props.classroomId]);

		const onTopicsChange = useCallback((_ids: ObjectId[]) => {
			setSelectedTopics(_ids);
		}, []);

		const { onSubmit } = props;
		const submitSelectedQuestions = useCallback(() => {
			const cummulativeContent = addExtraContent(localContentRef.current);
			if (onSubmit) {
				onSubmit(
					{
						questions: Object.keys(
							selectedContentRef.current.questions
						),
						texts: Object.keys(selectedContentRef.current.texts),
					},
					cummulativeContent
				);
			}
		}, [addExtraContent, localContentRef, onSubmit, selectedContentRef]);

		const partitionedContent: PartitionedContentList | null = useMemo(
			() =>
				!sortedContent ? null : getPartitionedContent(sortedContent),
			[sortedContent]
		);

		return (
			<Popup onClose={props.onClose}>
				<div
					className={styles.popupContent}
					style={{
						textAlign: "left",
						padding: 20,
						backgroundColor: "#eceefd",
						position: "relative",
					}}
				>
					<CloseIcon
						style={{
							position: "absolute",
							right: 15,
							top: 15,
							cursor: "pointer",
						}}
						onClick={props.onClose}
					/>
					<SelectTaskTypes
						selectedTaskTypes={selectedTaskTypes}
						setSelectedTaskTypes={setSelectedTaskTypes}
					/>
					{topicsInfo && (
						<TopicsSelect
							className={styles.chooseTaskTypesSelectorContainer}
							onChange={onTopicsChange}
							topicsInfo={topicsInfo}
							multipleSelectClassName={
								styles.multipleSelectSearchBarClassName
							}
						/>
					)}
					{isLoading && <FancyLoading />}
					{!isLoading && partitionedContent && (
						<ContentContainer
							partitionedContent={partitionedContent}
							content={localContent}
							selectedContent={selectedContent}
							onSelectionChange={onContentSelectionChange}
						/>
					)}
					{partitionedContent && partitionedContent.length === 0 && (
						<FormattedMessage id="teacher:assignment.noQsFound" />
					)}
					{!isLoading && (
						<div className={styles.buttonSubmitContainer}>
							<BigActionButton
								onClick={submitSelectedQuestions}
								text={getFormattedMessage("formDoneCTA")}
							/>
						</div>
					)}
				</div>
			</Popup>
		);
	}
);

export type SelectedContent = Record<
	"questions" | "texts",
	Record<string, true | undefined>
>;

export const ContentContainer: React.FC<{
	partitionedContent: PartitionedContentList;
	content: Content;
	extraIconsComponent?: QuestionExtraIconsComponent;
	onDelete?: (questionId: ObjectId) => void;
	onEdit?: () => void;
} & (
	| { selectedContent?: undefined; onSelectionChange?: undefined }
	| {
			selectedContent: SelectedContent;
			onSelectionChange: (contentItem: SortedContentList[number]) => void;
	  }
)> = React.memo(props => {
	const { partitionedContent, content } = props;
	return (
		<div className={styles.additionalPopupContentContainer}>
			{partitionedContent.map(partition => {
				if (partition.type === "singles") {
					return (
						<SingleContentsContainer
							key={partition.id}
							singleContents={partition.items}
							content={content}
							selectedContent={props.selectedContent}
							onSelectionChange={props.onSelectionChange}
							extraIconsComponent={props.extraIconsComponent}
							onDelete={props.onDelete}
							onEdit={props.onEdit}
						/>
					);
				}
				return (
					<NestedContentsContainer
						key={partition.id}
						nestedContent={partition.item}
						content={content}
						selectedContent={props.selectedContent}
						onSelectionChange={props.onSelectionChange}
						extraIconsComponent={props.extraIconsComponent}
					/>
				);
			})}
		</div>
	);
});

const NestedContentsContainer: React.FC<{
	nestedContent: SortedContentNestedItem;
	content: Content;
	selectedContent?: SelectedContent;
	onSelectionChange?: (contentItem: SortedContentList[number]) => void;
	extraIconsComponent?: QuestionExtraIconsComponent;
}> = React.memo(props => {
	if (props.nestedContent.itemType !== ItemType.text) return null;
	const textId = props.nestedContent.itemId;
	const text = props.content.texts[textId];
	if (!text) return null;
	const isSelected = !!props.selectedContent?.texts[textId];
	return (
		<div style={{ padding: 10 }}>
			<div className={styles.textCotentContainer}>
				<TextContentContainer
					isSelected={!props.selectedContent ? undefined : isSelected}
					text={text}
					nestedContent={props.nestedContent}
					onSelectionChange={props.onSelectionChange}
				/>
				<div>
					<SingleContentsContainer
						singleContents={props.nestedContent.childItems}
						content={props.content}
						selectedContent={props.selectedContent}
						onSelectionChange={props.onSelectionChange}
						extraIconsComponent={props.extraIconsComponent}
					/>
				</div>
			</div>
		</div>
	);
});

export const TextContentContainer: React.FC<{
	isSelected?: boolean;
	text: IText;
	nestedContent: SortedContentNestedItem;
	onSelectionChange?: (contentItem: SortedContentList[number]) => void;
}> = props => {
	return (
		<div
			className={classNames(
				styles.textCotent,
				props.isSelected && styles.selectedContentContainer
			)}
		>
			{props.isSelected !== undefined && (
				<CheckBox
					id={null}
					isSelected={props.isSelected}
					onChange={() =>
						props.onSelectionChange?.(props.nestedContent)
					}
					checkmarkContainerClassName={[
						styles.checkMarkContainer,
						styles.questions,
					]}
					selectedCheckmarkContainerClassName={styles.isSelected}
				/>
			)}
			<div
				dangerouslySetInnerHTML={{ __html: props.text.text }}
				style={{ display: "inline", padding: 10, textAlign: "left" }}
			/>
		</div>
	);
};

const SingleContentsContainer: React.FC<{
	singleContents: SortedContentSingleItem[];
	content: Content;
	selectedContent?: SelectedContent;
	onSelectionChange?: (contentItem: SortedContentList[number]) => void;
	extraIconsComponent?: QuestionExtraIconsComponent;
	onDelete?: (questionId: ObjectId) => void;
	onEdit?: () => void;
}> = React.memo(props => {
	return (
		<>
			{props.singleContents.map(contentItem => {
				if (contentItem.itemType === ItemType.question) {
					const question =
						props.content.questions[contentItem.itemId];
					if (!question) return null;
					const isSelected = !!props.selectedContent?.questions[
						contentItem.itemId
					];
					return (
						<SingleQuestion
							key={`${contentItem.itemType}-${contentItem.itemId}`}
							question={question}
							isSelected={
								!props.selectedContent ? undefined : isSelected
							}
							onSelectionChange={props.onSelectionChange}
							contentItem={contentItem}
							extraIconsComponent={props.extraIconsComponent}
							onDelete={props.onDelete}
							onEdit={props.onEdit}
						/>
					);
				}
				return null;
			})}
		</>
	);
});

export interface QuestionExtraIconsComponentProps {
	question: IFullQuestion;
	isSelected?: boolean;
	onSelectionChange?: (contentItem: SortedContentList[number]) => void;
	isContentFullyVisible: boolean;
}
export type QuestionExtraIconsComponent = React.ComponentType<
	QuestionExtraIconsComponentProps
>;

interface SingleQuestionProps {
	question: IFullQuestion;
	isSelected?: boolean;
	contentItem: SortedContentSingleItem;
	onSelectionChange?: (contentItem: SortedContentList[number]) => void;
	extraIconsComponent?: QuestionExtraIconsComponent;
	onDelete?: (questionId: ObjectId) => void;
	onEdit?: () => void;
}

const SingleQuestion: React.FC<SingleQuestionProps> = React.memo(
	({
		onDelete,
		contentItem,
		question,
		extraIconsComponent: ExtraIconsComponent,
		isSelected,
		onSelectionChange,
		onEdit,
	}) => {
		const content = newContent(question.content);

		const {
			value: isContentFullyVisible,
			setTrue: showContentFully,
			setFalse: hideFullContent,
			switchValue: switchContent,
		} = useBoolean();

		const { value: isEditing, switchValue: switchIsEditing } = useBoolean();

		const VisibilityComponent = isContentFullyVisible
			? VisibilityOff
			: Visibility;

		const locale = useLocale();

		const customizedProps = useMemo(
			() => getQuestionsCustomizedPropsForTeacher(locale),
			[locale]
		);

		const onSave = useCallback(
			(errors: ContentError[] | null, newQuestion: IFullQuestion) =>
				console.log(newQuestion),
			[]
		);

		return (
			<div
				className={classNames(
					styles.questionContainer,
					isSelected && styles.selectedContentContainer
				)}
			>
				{isSelected !== undefined && (
					<CheckBox
						id={question._id}
						isSelected={isSelected}
						onChange={() => onSelectionChange?.(contentItem)}
						checkmarkContainerClassName={[
							styles.checkMarkContainer,
							styles.questions,
						]}
						selectedCheckmarkContainerClassName={styles.isSelected}
					/>
				)}
				{isEditing ? (
					<QuestionEditwrapper
						defaultQuestion={question}
						onSave={onSave}
					/>
				) : (
					<QuestionContent
						content={content}
						isContentFullyVisible={isContentFullyVisible}
						className={styles.textStyles}
						onClick={switchContent}
						customizedProps={customizedProps}
					/>
				)}
				<div className={styles.iconsContainer}>
					{ExtraIconsComponent && (
						<ExtraIconsComponent
							question={question}
							onSelectionChange={onSelectionChange}
							isSelected={isSelected}
							isContentFullyVisible={isContentFullyVisible}
						/>
					)}
					<MarginContainer
						itemsMargin={4}
						style={{ display: "flex" }}
					>
						<VisibilityComponent
							className={classNames(
								styles.iconsStyles,
								styles.create
							)}
							onClick={
								isContentFullyVisible
									? hideFullContent
									: showContentFully
							}
						/>
						{onDelete && (
							<DeleteIcon
								className={classNames(
									styles.iconsStyles,
									styles.create
								)}
								onClick={() => onDelete(question._id)}
							/>
						)}
						{onEdit && (
							<EditOutlinedIcon
								className={classNames(
									styles.iconsStyles,
									styles.create
								)}
								onClick={() => switchIsEditing()}
							/>
						)}
					</MarginContainer>
				</div>
			</div>
		);
	}
);

const SaveQuestionButton = styled.button`
	font-family: "Roboto Geo MT Bold";
	color: white;
	background: #5273e6;
	border: none;
	padding: 8px 35px;
	display: inline-flex;
	align-items: center;
	font-size: 19px;
	justify-content: center;
	border-radius: 13px;
	cursor: pointer;
	outline: none;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	&:hover {
		box-shadow: -3px 5px 10px 0 rgba(82, 115, 230, 0.5);
	}

	&:focus {
		outline: none;
	}
`;

interface ISelectTaskTypesProps {
	selectedTaskTypes: Record<string, true | undefined>;
	setSelectedTaskTypes: React.Dispatch<
		React.SetStateAction<Record<string, true | undefined>>
	>;
}

const SelectTaskTypes: React.FC<ISelectTaskTypesProps> = React.memo(props => {
	const [isSwitched, setIsSwitched] = useState(false);
	const taskTypeInfo = useContext(TaskTypesContext);
	const { selectedTaskTypes, setSelectedTaskTypes } = props;
	const taskTypes = taskTypeInfo === null ? null : taskTypeInfo.taskTypes;

	useEffect(() => {
		if (!taskTypes) return;
		const notEverythingIsSelected = taskTypes.some(
			taskType => !selectedTaskTypes[taskType._id]
		);
		setIsSwitched(!notEverythingIsSelected);
	}, [selectedTaskTypes, taskTypes]);

	const onTaskTypesChange = useCallback(
		(taskId: ObjectId) => {
			setSelectedTaskTypes(selectedTaskTypes => {
				const newSelectedTaskTypes = { ...selectedTaskTypes };
				if (newSelectedTaskTypes[taskId]) {
					delete newSelectedTaskTypes[taskId];
				} else newSelectedTaskTypes[taskId] = true;
				return newSelectedTaskTypes;
			});
		},
		[setSelectedTaskTypes]
	);

	const onSwitchedChange = () => {
		setIsSwitched(!isSwitched);
		if (!taskTypes) return null;
		taskTypes.forEach(
			taskType =>
				(selectedTaskTypes[taskType._id] === isSwitched ||
					selectedTaskTypes[taskType._id] === undefined) &&
				onTaskTypesChange(taskType._id)
		);
	};

	const switchContainerStyles = () => {
		if (isSwitched) {
			return classNames(styles.switchContainer, styles.on);
		}
		return styles.switchContainer;
	};

	const switchStyles = () => {
		if (isSwitched) {
			return classNames(styles.switcher, styles.on);
		}
		return styles.switcher;
	};

	return (
		<>
			<div className={switchContainerStyles()} onClick={onSwitchedChange}>
				<div className={switchStyles()} />
			</div>
			<span style={{ color: "#252423" }}>
				<FormattedMessage id="teacher:assignment.selectAllTaskTypes" />
			</span>
			{taskTypes && (
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "auto auto auto",
					}}
				>
					{taskTypes.map(taskType => (
						<Selection
							key={taskType._id}
							name={taskType.name}
							id={taskType._id}
							isSelected={!!selectedTaskTypes[taskType._id]}
							onChange={onTaskTypesChange}
							textClassName={[
								styles.checkboxText,
								styles.taskTypes,
							]}
							containerClassName={[
								styles.checkboxContainer,
								styles.taskTypes,
							]}
							checkmarkContainerClassName={[
								styles.checkMarkContainer,
								styles.taskTypes,
							]}
							selectedCheckmarkContainerClassName={
								styles.isSelected
							}
						/>
					))}
				</div>
			)}
		</>
	);
});
