import { Omit, Diff } from "./generics";

export function getUniqueId(currentIds: number[], numOfIds = 1): number[] {
	const ids = [...currentIds];
	const newIds: number[] = [];
	for (let i = 0; i < numOfIds; ++i) {
		let uid = 0;
		do {
			uid = Math.floor(Math.random() * 1e9);
		} while (ids.indexOf(uid) !== -1);
		newIds.push(uid);
		ids.push(uid);
	}
	return newIds;
}

export function compareUnorderedArray(
	arg1: any[],
	arg2: any[],
	areNumbers = false
): boolean {
	const sortFunction = areNumbers ? (a, b) => a - b : undefined;
	const arr1 = [...arg1].sort(sortFunction);
	const arr2 = [...arg2].sort(sortFunction);
	if (arr1.length !== arr2.length) return false;
	return arr1.every((e, i) => e === arr2[i]);
}

export function removeKeys<T, K extends keyof T>(
	obj: T,
	...keys: K[]
): Omit<T, K> {
	const obj2 = { ...obj };
	for (let i = 0; i < keys.length; ++i) {
		delete obj2[keys[i]];
	}
	return obj2;
}

export function objectValues<T extends {}>(
	obj: T
): Diff<T[keyof T], undefined>[] {
	const keys = Object.keys(obj);
	return keys.map(e => obj[e]) as any;
}

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: T[],
	mainKey: K
): { [key: string]: T | undefined } {
	const obj: { [key: string]: T | undefined } = {};
	for (let i = 0; i < arr.length; ++i) {
		obj[arr[i][mainKey as any]] = arr[i];
	}
	return obj;
}

export function getStatsFromText(
	str: string
): null | { stat: string; choices: string[] } {
	try {
		str = str.replace(/[\r\n]+/g, " ");
		if (!/(.{3,}?)\s*(\s\(?.{0,1}?[).]\s+?.{1,}){3,}/g.test(str)) {
			return null;
		}
		/* statement
		(a) choice
		(b) choice
		...
		---------------------OR---------------------
		statement
		a) choice
		b) choice
		...
		---------------------OR---------------------
		statement
		a. choice
		b. choice
		...
		*/
		const statLength = str.match(
			/(.{3,}?)\s*(\s\(?.{0,1}?[).]\s+?.{1,}){3,}/
		)![1].length;
		const choicesStatStr = str.substr(statLength).trim();
		let choicesStats: string[] = [];
		const regex1 = /(\(.{0,1}?[).]\s+?(.{1,}))/g;
		const regex2 = /(\(?.{0,1}?[).](.*?)(?=\(?([ა-ჰ|\w|\d][).].{1,}|$)))/g;
		if (regex2.test(choicesStatStr)) {
			choicesStats = choicesStatStr
				.match(regex2)!
				.map(e => e.replace(regex2, "$2"));
		} else if (regex1.test(choicesStatStr)) {
			// probably you can comment this if
			choicesStats = choicesStatStr
				.match(regex1)!
				.map(e => e.replace(regex1, "$2"));
		} else {
			return null;
		}
		let stat = str.substr(0, statLength).trim();
		const statPrefix = stat.match(/(\d+?\.\t+)/);
		if (statPrefix) {
			stat = stat.substr(statPrefix[1].length).trim();
		}
		return {
			stat: stat,
			choices: choicesStats.map(e => e.trim()).filter(e => !!e),
		};
	} catch (e) {
		console.error(e);
		return null;
	}
}

export function getOldIfUnchanged<T>(newObj: T, oldObj: T): T {
	if (JSON.stringify(newObj) === JSON.stringify(oldObj)) {
		return oldObj;
	} else {
		return newObj;
	}
}
