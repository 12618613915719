import Joi from "@app/utils/joi";
import {
	ExtendedCourseInfoSchema,
	IExtendedCourseInfo,
} from "../courses-info/helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";
import { ClassTimeSchema, IClassTime } from "./helper-schemas-2";

export enum ClassroomPacket {
	free = "free",
	premium = "premium",
}

export const clasroomPacketValues = [
	ClassroomPacket.free,
	ClassroomPacket.premium,
];

export enum ClassroomGroupRelation {
	Full = "FULL",
	Partial = "PART",
}
export const ClassroomGroupRelations = [
	ClassroomGroupRelation.Full,
	ClassroomGroupRelation.Partial,
];

export const ClassroomSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string().allow(""),
	grade: Joi.number()
		.integer()
		.greater(0)
		.required(),
	subjects: Joi.array()
		.items(Joi.objectId())
		.required(),
	course: ExtendedCourseInfoSchema.required(),
	classTimes: Joi.array()
		.items(ClassTimeSchema)
		.required(),
	teacherId: Joi.number()
		.integer()
		.required(),
	studentIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
	isProgrammaticallyHidden: Joi.boolean(),
	isArchived: Joi.boolean(),
	schoolId: Joi.number(),
	packet: Joi.any().valid(...clasroomPacketValues),
	groups: Joi.array().items(
		Joi.object({
			_id: Joi.objectId().required(),
			relationType: Joi.string()
				.valid(...ClassroomGroupRelations)
				.required(),
		})
	),
});
export interface IClassroom {
	_id: ObjectId;
	name: string;
	description?: string;
	grade: number;
	subjects: ObjectId[];
	course: IExtendedCourseInfo;
	classTimes: IClassTime[];
	teacherId: number;
	studentIds: number[];
	isProgrammaticallyHidden?: boolean;
	isArchived?: boolean;
	packet?: ClassroomPacket;
	schoolId?: number;
	groups?: {
		_id: ObjectId;
		relationType: ClassroomGroupRelation;
	}[];
}
