import {
	IADELETECard,
	IAGETCard,
	IAGETManyByIds,
	IAGETNamesByIds,
	IAPOSTCreateCard,
	IAPUTCard,
	IRGETCard,
	IRGETNamesByIds,
	IRPOSTCreateCard,
	RGETCardSchema,
	RGETManyByIdsSchema,
	RGETNamesByIdsSchema,
	RPOSTCreateCardSchema,
	IRGETManyByIds,
} from "./validators";
import { IRequest } from "../requests";
import { inject } from "@app/modules";
import { ItemType } from "../folders/helper-schemas";
import { PromisesKeeperAPI } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";
import { Card } from "@app/models/card";

export class CardsController {
	private readonly Request: IRequest;

	private readonly _CardModel = inject("CardModel");
	private readonly _FolderItemsService = inject("FolderItemsService");

	private cardPromises = new PromisesKeeperAPI<ObjectId, Card>();

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCard): Promise<Card> =>
		this.Request.send("POST", "/api/cards", args, null, {
			responseSchema: RPOSTCreateCardSchema,
		}).then((data: IRPOSTCreateCard) => {
			this._FolderItemsService.addItemInParentSync({
				parentFolderId: args.folderId,
				item: { id: data._id, name: data.name, type: ItemType.card },
				courseId: args.courseId,
			});
			return this._CardModel.loadOneSync(data);
		});

	update = async (args: IAPUTCard): Promise<Card | null> =>
		this.Request.send("PUT", "/api/cards/:_id", args).then(() => {
			const { courseId, folderId, ...obj } = args;
			this._FolderItemsService.updateItemSync({
				item: { id: args._id, type: ItemType.card, name: args.name },
				parentFolderId: folderId,
				courseId: courseId,
			});
			return this._CardModel.updateOneSync({ _id: obj._id }, obj);
		});

	getById = async (args: IAGETCard, loadFresh?: boolean): Promise<Card> => {
		if (!loadFresh) {
			const card = this._CardModel.findByIdSync(args._id);
			if (card) return card;
		}
		return this.cardPromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/cards/:_id", args, null, {
				responseSchema: RGETCardSchema,
			}).then((data: IRGETCard) => {
				return this._CardModel.loadOneSync(data);
			})
		);
	};

	getBulk = async (args: IAGETManyByIds): Promise<Card[]> => {
		return this.Request.send("POST", "/api/cards/get-bulk", args, null, {
			responseSchema: RGETManyByIdsSchema,
		}).then((data: IRGETManyByIds) => {
			return this._CardModel.loadManySync(data, args.ids);
		});
	};

	getNames = async (args: IAGETNamesByIds): Promise<IRGETNamesByIds> =>
		this.Request.send("POST", "/api/cards/get-names", args, null, {
			responseSchema: RGETNamesByIdsSchema,
		});

	deleteById = async (args: IADELETECard): Promise<void> =>
		this.Request.send("DELETE", "/api/cards/:_id", args).then(() => {
			this._CardModel.deleteByIdSync(args._id);
			this._FolderItemsService.deleteItemSync({
				courseId: args.courseId,
				deleteOnlyInParentFolder: true,
				itemId: args._id,
				parentFolderId: args.folderId,
				type: ItemType.card,
			});
		});
}
