import { useState, useEffect, useMemo } from "react";
import { inject } from "@app/modules";
import { getResourceLoadingInfo, useResourceInfoWithLoading } from "./fetch";
import {
	IRGETRawGrades,
	IAGETGradesWithCategories,
} from "@app/api/grades/validators";
import { useModelDocs } from "m-model-react";
import { useLocale } from "./intl";
import { getCurrentWebsite } from "@app/globals";
import { useClassroomsUser } from "./users";
import { useGradeCategories } from "./grade-categories";
import { arrayToObject, flatten } from "@app/utils/common";
import { GradeCategory } from "@app/models/grade-category";
import { Grade } from "@app/models/grade";

export const useRawGradesForAdmin = () => {
	const [grades, setGrades] = useState<IRGETRawGrades>();
	useEffect(() => {
		setGrades(undefined);
		inject("GradesController")
			.getRawGrades()
			.then(grades => setGrades([...grades].sort((a, b) => a.id - b.id)));
	}, []);

	return useMemo(
		() =>
			getResourceLoadingInfo({
				resource: grades,
				isIdentificationKnown: true,
				error: null,
				loadAgain: undefined,
			}),
		[grades]
	);
};

const fetchAllGrades = (
	args: IAGETGradesWithCategories,
	loadFresh?: boolean
) => {
	return inject("GradesController")
		.get(args, loadFresh)
		.then(e => e.grades);
};

export const useGrades = (
	args?: IAGETGradesWithCategories,
	loadFresh?: boolean
) => {
	const defaultQuery = useGradesDefaultQuery();
	const query = args || defaultQuery;
	const grades = useModelDocs(inject("GradeModel"));
	const gradesMapped = useMemo(
		() =>
			!query
				? grades
				: grades
						.filter(e => e.isMatched(query))
						.map(e => e.setMainQuery(query)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[grades]
	);

	const gradesFetchInfo = useResourceInfoWithLoading({
		resource: gradesMapped,
		fetchingArg: [query, loadFresh],
		multiArgs: true,
		fetch: fetchAllGrades,
		isIdentificationKnown: !!query,
		forcefullyFetch: true,
	});
	return gradesFetchInfo;
};

export const useGradesDefaultQuery = (): IAGETGradesWithCategories => {
	const language = useLocale();
	const website = getCurrentWebsite();
	const user = useClassroomsUser();
	const country = user?.country || null;
	return useMemo(
		() => ({
			country,
			language,
			website,
		}),
		[country, language, website]
	);
};

export const useGradeById = (
	id: number | null,
	query?: IAGETGradesWithCategories
) => {
	const defaultQuery = useGradesDefaultQuery();
	const rQuery = query || defaultQuery;
	const grades = useGrades(rQuery);
	return useMemo(() => {
		const grade = grades.doc?.find(e => e.id === id);
		return getResourceLoadingInfo({
			resource: grade,
			loadAgain: undefined,
			error: grades.hasFoundError ? grades.error : undefined,
			isIdentificationKnown: !!id && grades.isIdentificationKnown,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, grades, JSON.stringify(rQuery)]);
};

export const useGradeOptionsWithCategories = (
	query?: IAGETGradesWithCategories
) => {
	const defaultQuery = useGradesDefaultQuery();
	const grades = useGrades(query || defaultQuery);
	const categories = useGradeCategories();

	return useMemo(() => {
		if (!categories.doc || !grades.doc) return null;
		const categoriesByIds = arrayToObject(categories.doc, "id");

		return grades.doc.map(grade => {
			const categories = grade
				.getCategoryIds()
				.filter(catId => categoriesByIds[catId])
				.map(catId => categoriesByIds[catId]!.name)
				.join(", ");
			return {
				value: grade.id,
				label: `${grade.getName()}${
					categories ? ` (${categories})` : ""
				}`,
			};
		});
	}, [categories.doc, grades.doc]);
};

const fetchAllGradesAndCategories = (
	args: IAGETGradesWithCategories,
	loadFresh?: boolean
) => {
	return inject("GradesController").get(args, loadFresh);
};

export const useGradesAndCategories = (
	args?: IAGETGradesWithCategories,
	loadFresh?: boolean
) => {
	const defaultQuery = useGradesDefaultQuery();
	const query = args || defaultQuery;
	const grades = useModelDocs(inject("GradeModel"));
	const [error, setError] = useState<any>();

	useEffect(() => {
		setError(undefined);
		fetchAllGradesAndCategories(query, loadFresh).catch(setError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(query), loadFresh]);

	const gradesMapped = useMemo(
		() =>
			!query
				? null
				: grades
						.filter(e => e.isMatched(query))
						.map(e => e.setMainQuery(query)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[grades]
	);
	const CategoryModel = inject("GradeCategoryModel");
	const docs = useModelDocs(CategoryModel);
	const categories = useMemo(
		() =>
			!gradesMapped
				? null
				: CategoryModel.getCategoriesWithAncestorsSync([
						...new Set(
							flatten(gradesMapped.map(e => e.getCategoryIds()))
						),
				  ]),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[CategoryModel, docs, gradesMapped]
	);

	const result = useMemo((): GradesAndCategories | null => {
		if (!categories || !gradesMapped) return null;

		return {
			categories,
			grades: gradesMapped,
			categoriesByIds: arrayToObject(categories, "id"),
			gradesByIds: arrayToObject(gradesMapped, "id"),
			gradesByCategories: arrayToObject(
				flatten(
					gradesMapped.map(e =>
						e.getCategoryIds().map(catId => ({ catId, item: e }))
					)
				) as { catId: number; item: Grade }[],
				e => ({
					key: e.catId,
					value: e.item,
				}),
				true
			),
			crategorylessGrades: gradesMapped.filter(
				e => e.getCategoryIds().length === 0
			),
		};
	}, [categories, gradesMapped]);

	const isQuerySet = !!query;
	return useMemo(
		() =>
			getResourceLoadingInfo({
				resource: error ? undefined : result,
				isIdentificationKnown: isQuerySet,
				error,
				loadAgain: undefined,
			}),
		[result, isQuerySet, error]
	);
};

export interface GradesAndCategories {
	categories: GradeCategory[];
	grades: Grade[];
	categoriesByIds: Record<string, GradeCategory | undefined>;
	gradesByIds: Record<string, Grade | undefined>;
	gradesByCategories: Record<number, Grade[] | undefined>;
	crategorylessGrades: Grade[];
}
