// import areIntlLocalesSupported from "intl-locales-supported";
import { IAnyObj } from "@app/utils/generics";

// (window as any).areIntlLocalesSupported = areIntlLocalesSupported;

export async function loadLocale(
	locale: string
): Promise<{ messages: IAnyObj }> {
	const promises: Promise<any>[] = [];
	// const supported = areIntlLocalesSupported(locale);
	// if (!Intl.PluralRules) {
	/* promises.push(
		loadScript(
			`https://unpkg.com/intl-plural-rules-polyfill@1.0.3/bundle/polyfill.js`
		).then(() => {
			if (!supported) {
				return loadScript(
					`https://unpkg.com/@formatjs/intl-pluralrules@1.3.0/dist/locale-data/${locale}.js`
				);
			}
		})
	);
	// }
	console.log("aaa");

	// if (!(Intl as any).RelativeTimeFormat) {
	promises.push(
		loadScript(
			`https://unpkg.com/relative-time-format@1.0.5/bundle/relative-time-format.js`
		).then(() => {
			if (!supported) {
				return loadScript(
					`https://unpkg.com/@formatjs/intl-relativetimeformat@4.2.0/dist/locale-data/${locale}.js`
				);
			}
		})
	); */
	// }
	return Promise.all([loadJSONs(locale), ...promises]).then(([messages]) => ({
		messages,
	}));
}

/* const loadScript = (url: string) => {
	return new Promise(function(resolve, reject) {
		const s = document.createElement("script");
		s.src = url;
		s.onload = resolve;
		s.onerror = reject;
		document.head.appendChild(s);
	});
}; */

export function readTextFile(fileUrl: string) {
	return new Promise<string>((resolve, reject) => {
		const rawFile = new XMLHttpRequest();
		rawFile.overrideMimeType("application/json");
		rawFile.open("GET", fileUrl, true);
		rawFile.onreadystatechange = function() {
			if (rawFile.readyState === 4) {
				if (rawFile.status >= 200 && rawFile.status < 300) {
					resolve(rawFile.responseText);
				}
				if (rawFile.status >= 300) {
					reject();
				}
			}
		};
		rawFile.send(null);
	});
}

const version = 4;

const loadJSONs = async (locale: string) => {
	const c = flattenObject(
		JSON.parse(
			await readTextFile(`/locales/${locale}/index.json?v=${version}`)
		)
	);
	return c;
};

const flattenObject = (obj: IAnyObj, separator = ".", prefix = "") => {
	const newObj: IAnyObj = {};
	for (const key in obj) {
		const value = obj[key];
		if (
			typeof value === "object" &&
			value !== null &&
			!Array.isArray(value)
		) {
			const flatValue = flattenObject(value, separator, prefix);
			for (const subKey in flatValue) {
				newObj[prefix + key + separator + subKey] = flatValue[subKey];
			}
		} else {
			newObj[prefix + key] = value;
		}
	}
	return newObj;
};
