import React from "react";
import styles from "../styles/index.module.css";
import { HeadmasterGroupBox } from "./directorate-box";
import { useGroupsBySchoolId } from "@app/hooks/groups";
import Loading from "@app/components/widgets/raw-loading";
import { useClassroomsUser } from "@app/hooks/users";

export const HeadmasterMainPage: React.FC = props => {
	const user = useClassroomsUser()!;
	const schoolGroups = useGroupsBySchoolId(user.school, true);

	if (!schoolGroups.isSuccessfullyLoaded) {
		return <Loading />;
	}

	return (
		<div className={styles.mainContainer}>
			<div className={styles.heading}>ჩემი კლასები</div>
			<div className={styles.boxesWrapper}>
				{schoolGroups.doc.map(e => (
					<HeadmasterGroupBox key={e._id} group={e} />
				))}
			</div>
		</div>
	);
};
