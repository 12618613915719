import Joi from "@app/utils/joi";
import { ObjectId, OptionalKeys } from "@app/utils/generics";
import { IStudentGradebook, StudentGradebookSchema } from "./helper-schemas";
import { IStudentGrade } from "./grades/helper-schemas";

export const APOSTStudentGradesSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.required(),
				grade: Joi.object({
					id: Joi.objectId().optional(),
					categoryId: Joi.objectId().required(),
					value: Joi.alternatives(
						Joi.number(),
						Joi.string(),
						Joi.boolean()
					).required(),
					date: Joi.date().required(),
				}),
			})
		)
		.required(),
	includeDisabled: Joi.boolean(),
});

export interface IAPOSTStudentGrades {
	classroomId: ObjectId;
	grades: {
		userId: number;
		grade: Omit<OptionalKeys<IStudentGrade, "id">, "gradedAt">;
	}[];
	includeDisabled?: boolean;
}

export const RPOSTStudentGradesSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRPOSTStudentGrades = IStudentGradebook[];

///

export const AGETClassroomStudentGradebooksSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	includeDisabled: Joi.boolean(),
});
export interface IAGETClassroomStudentGradebooks {
	classroomId: ObjectId;
	includeDisabled?: boolean;
}

export const RGETClassroomStudentGradebooksSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRGETClassroomStudentGradebooks = IStudentGradebook[];

///

export const AGETStudentGradebookSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
});
export interface IAGETStudentGradebook {
	classroomId: ObjectId;
	userId: number;
}

export const RGETStudentGradebookSchema = StudentGradebookSchema;
export type IRGETStudentGradebook = IStudentGradebook;

export interface IStudentGradebookUpdatedGrade {
	userId: number;
	grade: {
		id: ObjectId;
		categoryId: ObjectId;
		value: number | string | boolean;
		date: Date;
	};
	isNewGrade: boolean;
}

export const ADELETEStudentGradesSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.min(0)
					.required(),
				id: Joi.objectId().required(),
			})
		)
		.required(),
});
export interface IADELETEStudentGrades {
	classroomId: ObjectId;
	grades: {
		userId: number;
		id: ObjectId;
	}[];
}

export const RDELETEStudentGradesSchema = Joi.array().items(
	StudentGradebookSchema
);
export type IRDELETEStudentGrades = IStudentGradebook[];

export const APUTUpdateGradesDisabilitySchema = Joi.object({
	disabilityStatus: Joi.boolean().required(),
	classroomId: Joi.objectId().required(),
	grades: Joi.array()
		.items(
			Joi.object({
				userId: Joi.number()
					.integer()
					.min(0)
					.required(),
				id: Joi.objectId().required(),
			})
		)
		.required(),
});
export interface IAPUTUpdateGradesDisability {
	disabilityStatus: boolean;
	classroomId: ObjectId;
	grades: {
		userId: number;
		id: ObjectId;
	}[];
}

export const RUpdateGradesDisabilityschema = Joi.array().items(
	StudentGradebookSchema
);
export type IRPUTUpdateGradesDisability = IStudentGradebook[];
