import { UserType } from "@app/api/helper-schemas";
import { useHistory, useWindowSize } from "@app/hooks/front";
import triggerEvent from "@app/utils/events";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StudentLogin } from "../auth/login";
import { StudentRegisterFirstStep } from "../auth/student-register-page";
import { ReactComponent as Undo } from "./styles/img/leftReturnArrow.svg";
import student from "./styles/img/student.png";
import styles from "./styles/landing-main-page.module.css";
import { registrationClassNames, DivReference } from ".";
import { LandingButton } from "./buttons";
import { useBoolean } from "@app/hooks/general";
import { RegisterPopup } from "./register-type-selection";
import { LoginPopup } from "./login";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";

const responsiveWidth = 1050;
const responsiveWidthForRegisterTypesContainer = 460;
const headerScrollTreshold = 170;

export const StudentDiv: React.FC<{
	containerRef?: DivReference;
}> = props => {
	const history = useHistory();
	const redirectToRegistration = useCallback(() => {
		history.push(`/registration`);
	}, [history]);
	const redirectToLogin = useCallback(() => {
		history.push(`/login`);
	}, [history]);
	const [isRegisterClicked, setIsRegisterClicked] = useState(false);
	const { width } = useWindowSize();

	const onRegister = useCallback(() => {
		setIsRegisterClicked(true);
		triggerEvent(
			{
				category: "Inner Popup",
				action: "open registration popup",
				label: "landing page",
			},
			{ userType: "student" }
		);
	}, []);

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);

	return (
		<div
			className={
				width > responsiveWidth
					? styles.infoDivs + " " + styles.studentInfoDiv
					: styles.infoDivs
			}
			style={{ marginBottom: 160, paddingBottom: 100 }}
			ref={props.containerRef}
		>
			{!isLoginPopupOn && !isRegistrationPopupOn && (
				<div
					className={
						width > responsiveWidth
							? styles.infoDivsText + " " + styles.studentInfoDiv
							: styles.infoDivsText
					}
					style={
						width > 400 ? { height: 370 } : { marginBottom: -30 }
					}
				>
					<h2>
						<FormattedMessage id="student" />
						<div className={styles.headerUnderline}></div>
					</h2>
					<div className={styles.bulletsContainer}>
						<FormattedMessage id="landing:registerTitle" />

						<ul>
							<li>
								<FormattedMessage id="landing:student.firstSentence" />
							</li>
							<li>
								<FormattedMessage id="landing:student.secondSentence" />
							</li>
							<li>
								<FormattedMessage id="landing:student.thirdSentence" />
							</li>
						</ul>
					</div>
					<div className={styles.registerAndLoginButtons}>
						<LandingButton onClick={openLogin}>
							<FormattedMessage id="signIn" />
						</LandingButton>
						<LandingButton onClick={openRegisterPage}>
							<FormattedMessage id="registration" />
						</LandingButton>
					</div>
				</div>
			)}{" "}
			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
				/>
			)}
			{width > responsiveWidth && (
				<div className={styles.studentSVGDiv}>
					<img src={student} alt="Student" width="500px" />
				</div>
			)}
		</div>
	);
};
