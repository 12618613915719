import React, { useMemo } from "react";
import { History } from "history";
import { match } from "react-router-dom";
import { useClassroomsUser } from "@app/hooks/users";
import { ObjectId } from "@app/utils/generics";
import {
	isStudentSidebarPath,
	getStudentSidebarItems,
	StudentSidebarType,
	isGradebookHiddenForClassroom,
} from "./sidebar-items";
import { useSetIsNewSidebarVisible } from "@app/components/general-context";
import { SideBar } from "@app/components/layouts/sidebar";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { isTestUser } from "@app/utils/testing";

export const StudentSideBarWrapper = React.memo<{
	history: History;
	match: match<any>;
}>(({ history, match }) => {
	const user = useClassroomsUser()!;
	const isTest = isTestUser(user);
	const courseId: ObjectId | null = match.params.courseId || null;
	const classroom = useClassroomByCourseId(courseId);
	const displaySideBar =
		!!courseId &&
		isStudentSidebarPath(match.path) &&
		!isGradebookHiddenForClassroom(classroom.doc);
	useSetIsNewSidebarVisible(displaySideBar);

	const items = useMemo(() => {
		if (courseId) {
			return getStudentSidebarItems({
				type: StudentSidebarType.course,
				id: courseId,
				isTestUser: isTest,
				classroom: classroom.doc,
			});
		}
	}, [classroom, courseId, isTest]);

	if (!displaySideBar || !items) {
		return null;
	}

	return <SideBar items={items} history={history} match={match} />;
});
