import classNames from "classnames";
import React from "react";
import Popup from "../../widgets/popup";
import { CreateClassroomPage } from "../classrooms/create";
import styles from "./styles/classrooms.module.css";
import { ReactComponent as NewClassroomIcon } from "./styles/img/add-classroom.svg";
import { useBoolean } from "@app/hooks/general";
import { FormattedMessage } from "react-intl";

export const NewClassroomBox: React.FC = React.memo(props => {
	const {
		value: isAddingClassroom,
		setTrue: onAddClassroom,
		setFalse: onPopupClose,
	} = useBoolean(false);

	return (
		<>
			<div
				className={classNames(
					styles.newClassroomContainer,
					styles.classroomBox
				)}
				onClick={onAddClassroom}
			>
				<div className={styles["newgroup-picture"]}>
					<NewClassroomIcon />
				</div>
				<br />
				<div style={{ textAlign: "center" }}>
					<FormattedMessage id="teacher:createClassroom" />
				</div>
			</div>
			{isAddingClassroom && (
				<Popup onClose={onPopupClose}>
					<CreateClassroomPage onClassroomAdd={onPopupClose} />
				</Popup>
			)}
		</>
	);
});
