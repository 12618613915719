import { useState, useEffect, useMemo } from "react";
import { IClassroomJoinRequest } from "@app/api/classrooms/join-requests/helper-schemas";
import { inject } from "@app/modules";
import { useClassroomsUser, useClassroomsUserId } from "./users";

export const useClassroomInvitations = ({
	excludeUserAsRequester,
}: {
	excludeUserAsRequester: boolean;
}): IClassroomJoinRequest[] | undefined | null => {
	const ClassroomJoinRequest = inject("ClassroomJoinRequestModel");
	const user = useClassroomsUser();
	const [requests, setRequests] = useState<
		undefined | null | IClassroomJoinRequest[]
	>(user ? null : undefined);
	useEffect(() => {
		if (!user) {
			setRequests(undefined);
			return;
		}
		let isCancelled = false;
		const ClassroomJoinRequestsController = inject(
			"ClassroomJoinRequestsController"
		);
		setRequests(null);
		ClassroomJoinRequestsController.getAll().then(() => {
			if (isCancelled) return;
			setRequests(
				ClassroomJoinRequest.getUserRequestsSync(
					user,
					excludeUserAsRequester
				)
			);
		});
		const cancelSubscription = ClassroomJoinRequest.subscribeChange(() => {
			if (isCancelled) return;
			setRequests(
				ClassroomJoinRequest.getUserRequestsSync(
					user,
					excludeUserAsRequester
				)
			);
		});
		return () => {
			isCancelled = true;
			cancelSubscription();
		};
	}, [ClassroomJoinRequest, excludeUserAsRequester, user]);

	return requests;
};

export const useMyClassroomInvitationsAsRequester = () => {
	const userid = useClassroomsUserId();
	const invitations = useClassroomInvitations({
		excludeUserAsRequester: false,
	});
	return useMemo(() => {
		if (invitations) {
			return invitations.filter(e => e.requester.id === userid);
		}
		return undefined;
	}, [invitations, userid]);
};
