import { NewDesignBackground } from "@app/components/backgrounds";
import { GeneralContext } from "@app/components/general-context";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { VideoInstruction } from "@app/components/tv-school-landing/video-instruction";
import { CallsPopup } from "@app/components/widgets/calls";
import { MenuOptions } from "@app/components/widgets/hoverable-select";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useGoToUrl, useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { IAnyObj } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import classNames from "classnames";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as HubuLogo } from "../../landing/styles/img/hubu-logo.svg";
import { ReactComponent as Hubu } from "../../landing/styles/img/Hubu.svg";
import { ReactComponent as LupiLogo } from "../../landing/styles/img/lupi-ai.svg";
import HeaderStyles from "../../users/header/styles/header.module.css";
import LogOutComponent from "../../widgets/LogOutComponent";
import { TeacherSchoolsSelector } from "./schools-selector";
import { ReactComponent as BurgerIconBlue } from "./styles/imgs/Burger_blue.svg";
import { ReactComponent as TVschoolNLIcon } from "./styles/imgs/Logo.svg";
import TeacherHeaderStyles from "./styles/teacher-header.module.css";

export const TeacherTransparentHeader: React.FC<IAnyObj> = props => {
	const loc = useLocation();
	const locale = useLocale();
	const isGeo = locale === "ka";
	const { width } = useWindowSize();

	const currentWebsite = getCurrentWebsite();

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const isMobile = width < 930;

	const showValidateNumeberNotification = useMemo(
		() => !loc.pathname.includes("profile"),
		[loc.pathname]
	);

	const showValidateNumeberTextNotification = useMemo(
		() => loc.pathname === "/",
		[loc.pathname]
	);

	if (isHeaderHidden) return null;

	return (
		<>
			<NewDesignBackground />
			<div
				style={{
					backgroundColor:
						props.backgroundColor || "rgba(239, 241, 247)",
				}}
				className={
					currentWebsite === WebsiteOrigin.tvSchool
						? TeacherHeaderStyles.header
						: width > 500
						? HeaderStyles.container
						: classNames(
								HeaderStyles.container,
								TeacherHeaderStyles.container
						  )
				}
			>
				<div className={TeacherHeaderStyles.logoWithCalls}>
					<HeaderLogo />
					<TeacherSchoolsSelector />
				</div>
				<div
					className={
						TeacherHeaderStyles.inviteStudentsInHeaderAndUserLogoContainer
					}
				>
					<TeacherGradebookOptions
						isTvschool={WebsiteOrigin.tvSchool && isMobile && isGeo}
						showValidateNumeberNotification={
							showValidateNumeberNotification
						}
						showValidateNumeberTextNotification={
							showValidateNumeberTextNotification
						}
						isVideoInstructionVissible={true}
						menuStyles={{}}
					/>
				</div>
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};

const HeaderLogo = React.memo(() => {
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const { width } = useWindowSize();

	const currentWebsite = getCurrentWebsite();
	const gotoMainPage = useGoToUrl("/");

	return (
		<div className={HeaderStyles.logo} onClick={gotoMainPage}>
			{currentWebsite === WebsiteOrigin.hubu && width >= 500 && (
				<HubuLogo
					style={{
						height: "100%",
						width: 112,
					}}
				/>
			)}
			{currentWebsite === WebsiteOrigin.tvSchool && (
				<>
					{isGeo && (
						<TVSchoolLogo
							className={TeacherHeaderStyles.tvschoolLogo}
							style={{ color: "#5273e6" }}
						/>
					)}
					{isNl && (
						<TVschoolNLIcon
							className={TeacherHeaderStyles.tvschoolLogo}
							style={{ color: "#5273e6" }}
						/>
					)}
					{!isGeo && !isNl && (
						<LupiLogo
							style={{
								height: "100%",
								width: 112,
								color: "#5273e6",
							}}
						/>
					)}
				</>
			)}
			{currentWebsite === WebsiteOrigin.hubu && width < 500 && (
				<Hubu
					style={{
						height: "100%",
						width: 50,
						color: "white",
					}}
				/>
			)}
			{currentWebsite === WebsiteOrigin.hubuExams && (
				<HubuLogo
					style={{
						height: "100%",
						fill: "#5273e6",
						width: 112,
					}}
				/>
			)}
		</div>
	);
});

enum UserOption {
	profile,
	logout,
}

const useTeacherHeaderOptions = () => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: UserOption.profile,
				label: getFormattedMessage("profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

enum TvschoolUserOption {
	profile,
	logout,
	videoInstruction,
	faq,
	calls,
	assignments,
}

const useTvschoolTeacherHeaderOptions = (
	isAllAssignmentsAndNotProfileInfo?: boolean
) => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: TvschoolUserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: TvschoolUserOption.calls,
				label: "ცხელი ხაზი",
			},
			{
				value: TvschoolUserOption.videoInstruction,
				label: getFormattedMessage("instruction"),
			},
			{
				value: TvschoolUserOption.faq,
				label: getFormattedMessage("faq"),
			},
			{
				value: isAllAssignmentsAndNotProfileInfo
					? TvschoolUserOption.assignments
					: TvschoolUserOption.profile,
				label: isAllAssignmentsAndNotProfileInfo
					? getFormattedMessage("teacher:createAssignment")
					: getFormattedMessage("profile"),
			},
			{
				value: TvschoolUserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

export const TeacherGradebookOptions: React.FC<{
	isTvschool?: boolean;
	isVideoInstructionVissible?: boolean;
	showValidateNumeberNotification?: boolean;
	showValidateNumeberTextNotification?: boolean;
	icon?: JSX.Element;
	isAllAssignmentsAndNotProfileInfo?: boolean;
	menuStyles?: Record<string, any>;
}> = props => {
	const videoId = useLocale() === "en" ? "-RLUtRSKc-4" : "zTIoUoGzzqE";
	const history = useHistory();
	const userHeaderOptions = useTeacherHeaderOptions();
	const tvschoolHeadetOptions = useTvschoolTeacherHeaderOptions(
		props.isAllAssignmentsAndNotProfileInfo
	);
	const {
		value: isOptionsVisible,
		setTrue: showOptions,
		setFalse: hideOptions,
	} = useBoolean(false);

	const user = useClassroomsUser()!;

	const hasNotVarifiedMobile = useLocale() === "ka" && !user.isValidMobile;

	const showValidateNumeberTextNotification =
		props.showValidateNumeberTextNotification && !isOptionsVisible;

	const { width } = useWindowSize();
	const gotoFaqPage = useCallback(() => {
		history.push(`/faq`);
	}, [history]);

	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				///
			});
		history.push(`/`);
	}, [history]);

	const onGoToProfile = useCallback(() => history.push(`/profile`), [
		history,
	]);

	const gotoNewAssignmentsPage = useCallback(() => {
		history.push(`/assignments/new`);
	}, [history]);

	const {
		value: isCallsPopupOpen,
		setTrue: setCallsPopupOpen,
		setFalse: closeCallsPopup,
	} = useBoolean(false);

	const {
		value: isVideoInstructioVissible,
		setFalse: hideVideoInstruction,
		setTrue: setVideoInstructionVissible,
	} = useBoolean(false);

	const onSelect = useCallback(
		(option: {
			value: UserOption | TvschoolUserOption;
			label: JSX.Element | string;
		}) => {
			if (option.value === UserOption.profile) {
				onGoToProfile();
			} else if (option.value === UserOption.logout) {
				onLogout();
			} else if (option.value === TvschoolUserOption.faq) {
				gotoFaqPage();
			} else if (
				option.value === TvschoolUserOption.videoInstruction &&
				props.isVideoInstructionVissible
			) {
				setVideoInstructionVissible();
			} else if (option.value === TvschoolUserOption.calls) {
				setCallsPopupOpen();
			} else if (option.value === TvschoolUserOption.assignments) {
				gotoNewAssignmentsPage();
			}
		},
		[
			onGoToProfile,
			onLogout,
			gotoFaqPage,
			setCallsPopupOpen,
			setVideoInstructionVissible,
			props.isVideoInstructionVissible,
			gotoNewAssignmentsPage,
		]
	);

	const menuStyles = useMemo(
		(): React.CSSProperties => ({
			right: 0,
			width: "max-content",
			fontFamily: "Roboto Geo Nus",
			color: "#5273e6",
			minWidth: 150,
			fontSize: 14,
			...props.menuStyles,
		}),
		[props.menuStyles]
	);

	const isMobile = width < 930;
	return (
		<>
			<div>
				{props.icon ? (
					<div onClick={showOptions}> {props.icon} </div>
				) : isMobile && props.isTvschool ? (
					<BurgerIconBlue
						onClick={width > 1200 ? onGoToProfile : showOptions}
						className={TeacherHeaderStyles.burgerIconContainer}
					/>
				) : (
					<div className={HeaderStyles.teacherInfo_wrapper}>
						<div
							style={{
								display: "inline-block",
								fontFamily: "Roboto Geo Caps",
								verticalAlign: "middle",
								textAlign: "right",
								color: "#626a84",
							}}
						>
							<span style={{ fontSize: 12 }}>მასწავლებელი</span>
							<br />
							<span style={{ fontSize: 15, fontWeight: "bold" }}>
								{user.firstname + " " + user.lastname}
							</span>
						</div>

						<div
							className={
								width > 1024
									? HeaderStyles.teacherUserICon
									: classNames(
											HeaderStyles.teacherUserICon,
											TeacherHeaderStyles.teacherIcon
									  )
							}
							onClick={width > 1200 ? onGoToProfile : showOptions}
							style={{
								backgroundColor: !isOptionsVisible
									? "white"
									: "#5273e6",
								width: 60,
								height: 60,
								display: "inline-block",
								verticalAlign: "middle",
							}}
						>
							<img
								src="/imgs/TeacherAvatar.svg"
								alt=""
								className={HeaderStyles.iconImg}
							/>
							{hasNotVarifiedMobile &&
								props.showValidateNumeberNotification && (
									<div
										// onMouseEnter={showValidateMobileWarning}
										// onMouseLeave={hideValidateMobileWarning}
										onMouseEnter={hideOptions}
										onClick={onGoToProfile}
										className={
											TeacherHeaderStyles.alertIconContainer
										}
									>
										!
									</div>
								)}
						</div>
						<LogOutComponent />
					</div>
				)}

				{isOptionsVisible && (
					<MenuOptions
						onSelect={onSelect}
						options={
							props.isTvschool
								? tvschoolHeadetOptions
								: userHeaderOptions
						}
						style={menuStyles}
					/>
				)}
				{isCallsPopupOpen && <CallsPopup onClose={closeCallsPopup} />}
			</div>
			{hasNotVarifiedMobile && showValidateNumeberTextNotification && (
				<div className={TeacherHeaderStyles.mobileVerificationWarning}>
					გთხოვთ, დაადასტუროთ მობილურის ნომერი
				</div>
			)}
			{isVideoInstructioVissible && (
				<VideoInstruction
					onClose={hideVideoInstruction}
					videoId={videoId}
				/>
			)}
		</>
	);
};
