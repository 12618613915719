import { FullCircularProgress } from "@app/components/widgets/circular-progress-bar";
import { useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import { useFolderProgress } from "@app/hooks/user-folder-progress";
import { roundNumberDecimalPlaces } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import React from "react";
import styles from "./styles/lessons.module.css";
import { UserFolderLevel } from "@app/models/user-folder-level";

export const useOverallScorePercentage = ({
	folderLevels,
	parentFolderId,
	coursesUserId,
	courseId,
}: {
	parentFolderId: ObjectId;
	folderLevels: UserFolderLevel;
	courseId: ObjectId;
	coursesUserId: number;
}) => {
	const myLevel = folderLevels.getLevel(parentFolderId);

	const folderProgress = useFolderProgress({
		coursesUserId,
		folderId: parentFolderId,
		courseId,
	});
	if (!folderProgress.isSuccessfullyLoaded) {
		if (
			!folderProgress.hasFoundError ||
			!folderProgress.error.cannotBeFound
		) {
			return null;
		}
	}
	const progress = folderProgress.doc ? folderProgress.doc.progress : 0;

	const level = myLevel.totalLevel;

	const score = progress * level * 100;

	const roundedScore = roundNumberDecimalPlaces(score, 2);
	return roundedScore;
};

export const NationExamsPreparednessScore: React.FC<{
	parentFolderId: ObjectId;
	folderLevels: UserFolderLevel;
	courseId: ObjectId;
	coursesUserId: number;
}> = props => {
	const locale = useLocale();
	const score = useOverallScorePercentage({
		parentFolderId: props.parentFolderId,
		folderLevels: props.folderLevels,
		courseId: props.courseId,
		coursesUserId: props.coursesUserId,
	});
	const { width } = useWindowSize();
	if (locale !== "ka") return null;
	if (score === null) return null;

	return (
		<div className={styles.nExamsScoreContainer}>
			<span style={{ fontFamily: "Roboto Geo Nus" }}>
				ეროვნულებისთვის მზადყოფნის პროცენტული მაჩვენებელია:
			</span>
			{width > 501 ? (
				<>{score}%</>
			) : (
				<div>
					<FullCircularProgress
						progress={score}
						strokeWidth={6}
						progressStrokeWidth={6}
						progressStrokeColor="white"
						endCircleRadius={0}
						endCircleStrokeWidth={0}
						radius={30}
						strokeColor="#ffffff26"
						size={90}
					>
						<div>{score}%</div>
					</FullCircularProgress>
				</div>
			)}
		</div>
	);
};
