import { IRequest } from "../requests";
import {
	ConsultationRequestsSchema,
	IConsultationRequests,
} from "./helper-schemas";
import {
	IRGETAllConsultationRequests,
	RGETAllConsultationRequestsSchema,
} from "./validators";

const URL = "/api/consultation-requests";

export class ConsultationRequestsController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	save = async (): Promise<void> => this.Request.send("PUT", URL, {}, null);

	getOne = async (): Promise<IConsultationRequests> =>
		this.Request.send("GET", URL, {}, null, {
			responseSchema: ConsultationRequestsSchema,
		});

	getAll = async (): Promise<IRGETAllConsultationRequests> =>
		this.Request.send("GET", `${URL}/all`, {}, null, {
			responseSchema: RGETAllConsultationRequestsSchema,
		});
}
