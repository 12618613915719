import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { useUserActions } from "@app/hooks/user-actions";
import { useClassroomsUser } from "@app/hooks/users";
import React from "react";
import FancyLoading from "../../widgets/fancy-loading";
import Popup, { PopupContent } from "../../widgets/popup";
import { GrantInformation, RequestGrant } from "./popups";

export const GrantPopups: React.FC = () => {
	const user = useClassroomsUser();
	const userActions = useUserActions(user ? user.id : null);
	const isMissingPacket = !!user && !!user.isStudent() && !user.getPacket();

	return (
		<>
			<DefaultPopupPrioritizer
				preemptive={true}
				priority={200}
				enabled={
					isMissingPacket &&
					userActions.isIdentificationKnown &&
					!userActions.isSuccessfullyLoaded
				}
			>
				<PopupWithLoading />
			</DefaultPopupPrioritizer>
			<DefaultPopupPrioritizer
				preemptive={true}
				priority={200.1}
				enabled={
					isMissingPacket &&
					userActions.isSuccessfullyLoaded &&
					!userActions.doc.actions.grant
				}
			>
				<Popup>
					<PopupContent>
						<RequestGrant />
					</PopupContent>
				</Popup>
			</DefaultPopupPrioritizer>
			<DefaultPopupPrioritizer
				preemptive={true}
				priority={200.1}
				enabled={
					!!user &&
					user.isStudent() &&
					userActions.isSuccessfullyLoaded &&
					!!user.getPacket() &&
					(!userActions.doc.actions.grant ||
						!userActions.doc.actions.grant.claimAt)
				}
			>
				<UserGrantCongrats
					grant={
						userActions.isSuccessfullyLoaded
							? userActions.doc.actions.grant || null
							: null
					}
				/>
			</DefaultPopupPrioritizer>
		</>
	);
};

const UserGrantCongrats: React.FC<{ grant: Record<any, any> | null }> = ({
	grant,
}) => {
	return (
		<Popup>
			<PopupContent>
				<GrantInformation grantAction={grant} />
			</PopupContent>
		</Popup>
	);
};

const PopupWithLoading = () => (
	<Popup>
		<FancyLoading />
	</Popup>
);
