import { IRequest } from "../requests";
import { IInviteFriendRequest } from "./validators";

export default class FriendInvitationController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}
	sendInvitationToFriend = (args: IInviteFriendRequest): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/invite-friend/send-invitation-to-friend",
			args
		);
	sendInvitationToFriends = (args: IInviteFriendRequest[]): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/invite-friend/send-invitation-to-friends",
			args
		);
}
