import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { useBoolean } from "@app/hooks/general";
import { useClassroomsUser } from "@app/hooks/users";
import React, { useCallback } from "react";
import touImg from "../users/grants/imgs/tou-people.png";

const TeacherGreetingPopup: React.FC<{}> = () => {
	const user = useClassroomsUser();
	const { value: display, setFalse: closePopup } = useBoolean(true);
	const onClose = useCallback(() => {
		sessionStorage.removeItem("justRegistered");
		closePopup();
	}, [closePopup]);
	const firstTime = sessionStorage.getItem("justRegistered");
	if (!display || !user?.city || !user.school || firstTime !== "true") {
		return null;
	}
	return (
		<Popup>
			<PopupContentWithClose onClose={onClose}>
				<img src={touImg} alt="tou" style={{ margin: "0 auto" }} />
				{user?.firstname || "ძვირფასო"} მასწავლებელო, თბილისის ღია
				უნივერსიტეტის სახელით გილოცავთ! თქვენი პროფილი ჰუბუზე წარმატებით
				შეიქმნა! იმედი გვაქვს, ჰუბუს დახმარებით სასწავლო პროცესს
				გაგიმარტივებთ! გისურვებთ წარმატებას!
			</PopupContentWithClose>
		</Popup>
	);
};

export default TeacherGreetingPopup;
