import { IRootState } from "@app/redux";
import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { UserType } from "./api/helper-schemas";
import { IUserPermissions } from "./api/users/helper-schemas";
import { GeneralContextProvider } from "./components/general-context";
import { HeadmasterMainPage } from "./components/headmaster/main";
import { MainLayout } from "./components/layouts";
import { PlayGround } from "./components/playground";
import { MissingProfilePopups } from "./components/profile/missing-profile-popup";
import TeacherGreetingPopup from "./components/profile/teacher-greeting-popup";
import { StudentProfileUpdated } from "./components/profiles/student-profile";
import TeacherProfileNew from "./components/profiles/teacher-profile/profile";
import { TeacherMainPage } from "./components/teachers/main";
import TeacherProposalPopup from "./components/teachers/propose";
import { GrantPopups } from "./components/users/grants";
import { TVSchoolParentMainPage } from "./components/users/parent-loading-page";
import { StudentLoadingPage } from "./components/users/student-loading-page";
import { TermsPopupWrapper } from "./components/users/terms/terms-popup";
import { FacebookLikePopup } from "./components/widgets/facebook-like";
import { MessengerChat } from "./components/widgets/messenger-chat";
import { Prioritizer } from "./components/widgets/prioritizer";
import { TopGe } from "./components/widgets/top-ge";
import { isFacebookPopupDisabled } from "./consts";
import { getCurrentWebsite, WebsiteOrigin } from "./globals";
import { HeaderDistributorWrapper } from "./routes/containers/common";
import UserRoute from "./routes/containers/user-route";

export const HistoryContext = React.createContext<History>(
	(null as any) as History
);
const emptyComponent: React.FC = () => {
	return null;
};

interface AppProps {
	location: History["location"];
	isTeacher: boolean;
	isStudent: boolean;
	isParent: boolean;
	isMainAdmin: boolean;
	isHeadmaster: boolean;
	isAuthenticated: boolean;
	history: History;
	locale: string;
}

const App = ({
	location,
	isTeacher,
	isStudent,
	isParent,
	isMainAdmin,
	isHeadmaster,
	isAuthenticated,
	history,
	locale,
}: AppProps) => {
	return (
		<HistoryContext.Provider value={history}>
			<GeneralContextProvider>
				<Prioritizer>
					<Route
						location={location}
						path="/"
						component={HeaderDistributorWrapper}
					/>
					<PlayGround />
					<TermsPopupWrapper />
					<Route
						location={location}
						path=""
						exact={false}
						component={MainLayout}
					/>
					<UnresolvedRouted
						location={location}
						isAuthenticated={isAuthenticated}
						isStudent={isStudent}
						isTeacher={isTeacher}
						isParent={isParent}
						isHeadmaster={isHeadmaster}
						isMainAdmin={isMainAdmin}
					/>

					{isAuthenticated && <MissingProfilePopups />}
					{isAuthenticated &&
						isStudent &&
						getCurrentWebsite() === WebsiteOrigin.hubu && (
							<GrantPopups />
						)}
					{isAuthenticated &&
						isTeacher &&
						getCurrentWebsite() === WebsiteOrigin.hubu && (
							<TeacherGreetingPopup />
						)}
					{getCurrentWebsite() === WebsiteOrigin.tvSchool &&
						locale === "ka" && (
							<>
								<TopGe />
								<MessengerChat />
								{!isFacebookPopupDisabled && (
									<FacebookLikePopup />
								)}
								{isTeacher && <TeacherProposalPopup />}
							</>
						)}
				</Prioritizer>
			</GeneralContextProvider>
		</HistoryContext.Provider>
	);
};

const UnresolvedRouted = ({
	location,
	isAuthenticated,
	isStudent,
	isTeacher,
	isParent,
	isMainAdmin,
	isHeadmaster,
}: Pick<
	AppProps,
	| "location"
	| "isAuthenticated"
	| "isStudent"
	| "isTeacher"
	| "isParent"
	| "isMainAdmin"
	| "isHeadmaster"
>) => {
	let mainComponent: React.ComponentType<any>;
	if (isStudent) {
		mainComponent = StudentLoadingPage;
	} else if (isTeacher) {
		mainComponent = TeacherMainPage;
	} else if (isParent) {
		mainComponent = TVSchoolParentMainPage;
	} else if (isHeadmaster) {
		mainComponent = HeadmasterMainPage;
	} else {
		mainComponent = emptyComponent;
	}
	return (
		<>
			<UserRoute
				location={location}
				path="/"
				exact={true}
				dontRedirectIfNotAuthenticated={true}
				component={mainComponent}
			/>

			<UserRoute
				location={location}
				path="/profile"
				exact={true}
				component={
					isStudent ? StudentProfileUpdated : TeacherProfileNew
				}
				hideHeader={true}
			/>
		</>
	);
};

const mapStateToProps = (state: IRootState) => {
	const permissions = state.user.userData?.permissions;
	return {
		isAuthenticated: !!state.user.userId,
		isTeacher: isOfType(permissions, UserType.teacher),
		isStudent: isOfType(permissions, UserType.student),
		isParent: isOfType(permissions, UserType.parent),
		isMainAdmin: isOfType(permissions, UserType.mainAdmin),
		isHeadmaster: isOfType(permissions, UserType.headmaster),
		locale: state.intl.locale,
	};
};

const isOfType = (
	permissions: IUserPermissions | undefined,
	type: UserType
) => {
	if (!permissions) return false;
	return permissions.userType === type;
};

export default connect(mapStateToProps)(App);
