import { User } from "@app/user";
import { isLocalhost } from "@app/globals";

export const testTvSchoolTeacherIds = [
	5,
	101854,
	12047,
	103671,
	201266,
	12101,
	14690,
	16237,
	16238,
	347650,
];
export const isTestTeacher = (teacherId: number): boolean =>
	!!testTvSchoolTeacherIds.find(e => e === teacherId);

export const testTvSchoolStudentIds = [12102, 12028, 12029, 12030, 15981];
export const isTestStudent = (studentId: number): boolean =>
	!!testTvSchoolStudentIds.find(e => e === studentId);

export const isTestUser = (
	user: User | undefined,
	considerLocalhostAsTest = true
): boolean => {
	if (!user) return false;
	if (considerLocalhostAsTest && isLocalhost()) {
		return true;
	}
	if (user.isTeacher()) {
		return isTestTeacher(user.id);
	}
	return isTestStudent(user.id);
};
