import {
	IAPOSTCreateClassroom,
	IAPUTUpdateClassroom,
} from "@app/api/classrooms/validators";
import { addLoader } from "@app/common-javascript";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import React, { useEffect, useState } from "react";
import styles from "../../assignments/styles/styles.module.css";
import { ClassroomName } from "./classroom-name";
import { SelectSubjectsAndGrade } from "./classroom-selectors";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import FancyLoading from "@app/components/widgets/fancy-loading";

interface ICreateClassroomPageProps {
	classroomId?: ObjectId;
	onClassroomAdd?: () => void;
	createClassroomContainerClassname?: string;
	firstShadowContainerClassname?: string;
	secondShadowContainerClassname?: string;
	isFirstClassroom?: boolean;
	classroomNameContainer?: string;
	inputStyles?: string;
}

const CreateClassroomPage: React.FC<ICreateClassroomPageProps> = props => {
	const ClassroomsController = inject("ClassroomsController");
	const user = useClassroomsUser();
	const userId = user?.id;

	const { classroomId } = props;

	const [isLoaded, setIsLoaded] = useState(!props.classroomId);

	const [name, setName] = useState<string>("");

	const [selectedSubjects, setSelectedSubjects] = useState<ObjectId[]>();

	const [selectedGrade, setSelectedGrade] = useState<number>();

	const [selectedCourse, setSelectedCourse] = useState<ObjectId>();

	useEffect(() => {
		if (userId) {
			const ClassroomsController = inject("ClassroomsController");
			ClassroomsController.getAll({ teacherId: userId });
		}
	}, [user, userId]);

	const country = user?.country;
	useEffect(() => {
		const CoursesInfoController = inject("CoursesInfoController");
		CoursesInfoController.getAll({
			country,
		});
	}, [country]);

	const validateInputs = (): boolean => {
		if (!userId) return false;
		if (!name) {
			openConfirmationPopup({
				text: getFormattedMessage("teacher:enterClassroomName"),
			});
			return false;
		}
		if (!selectedGrade) {
			openConfirmationPopup({
				text: getFormattedMessage("teacher:chooseGrade"),
			});
			return false;
		}
		if (!selectedSubjects || selectedSubjects.length === 0) {
			openConfirmationPopup({
				text: getFormattedMessage("teacher:chooseSubjects"),
			});
			return false;
		}
		if (!selectedCourse) {
			openConfirmationPopup({
				text: getFormattedMessage("chooseCourse"),
			});
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (props.classroomId) {
			const ClassroomsController = inject("ClassroomsController");
			ClassroomsController.getById({ _id: props.classroomId }, true).then(
				classroom => {
					setName(classroom.name);
					setSelectedSubjects(classroom.subjects);
					setSelectedGrade(classroom.grade);
					setSelectedCourse(classroom.course.courseId);
					setIsLoaded(true);
				}
			);
		} else {
			setIsLoaded(true);
		}
	}, [props.classroomId]);

	const onCreate = () => {
		if (!validateInputs()) return;
		const addingClassroom: IAPOSTCreateClassroom = {
			name,
			originalCourseId: selectedCourse!,
			grade: +selectedGrade!,
			subjects: selectedSubjects!,
			classTimes: [],
			teacherId: userId!,
		};

		const removeLoader = addLoader();

		ClassroomsController.add(addingClassroom)
			.then(() => {
				removeLoader();
				if (props.onClassroomAdd) {
					props.onClassroomAdd();
				}
			})
			.catch(e => {
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	};

	const onUpdate = () => {
		if (!validateInputs()) return;

		const updatingClassroom: IAPUTUpdateClassroom = {
			_id: classroomId!,
			name,
			grade: +selectedGrade!,
			subjects: selectedSubjects!,
			teacherId: userId!,
		};

		const removeLoader = addLoader();

		ClassroomsController.update(updatingClassroom)
			.then(() => {
				removeLoader();
				if (props.onClassroomAdd) {
					props.onClassroomAdd();
				}
			})
			.catch(e => {
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	};

	if (!isLoaded) {
		return <FancyLoading />;
	}

	return (
		<>
			<div
				className={
					props.firstShadowContainerClassname
						? props.firstShadowContainerClassname
						: styles.createClassroomContainerFirstShadow
				}
			/>
			<div
				className={
					props.secondShadowContainerClassname
						? props.secondShadowContainerClassname
						: styles.createClassroomContainerSecondShadow
				}
			/>
			<div
				className={
					props.createClassroomContainerClassname
						? props.createClassroomContainerClassname
						: styles.createClassroomContainer
				}
			>
				{props.isFirstClassroom && (
					<div className={styles.firstClassroomText}>
						<FormattedMessage id="teacher:createFirstClassroom" />
					</div>
				)}
				<ClassroomName
					onChange={e => setName(e.target.value)}
					value={name}
					classroomNameContainer={props.classroomNameContainer}
					inputStyles={props.inputStyles}
				/>
				<SelectSubjectsAndGrade
					selectedGrade={selectedGrade}
					onSelectedGradesChange={setSelectedGrade}
					selectedSubjects={selectedSubjects}
					onChangeSubjects={setSelectedSubjects}
					onChangeCourse={
						!classroomId ? setSelectedCourse : undefined
					}
					classroomId={classroomId}
					selectedCourse={selectedCourse}
				/>
				<button
					onClick={props.classroomId ? onUpdate : onCreate}
					className={styles.buttonStyles}
				>
					{props.classroomId
						? getFormattedMessage("teacher:editClassroomCTA")
						: getFormattedMessage("teacher:createClassroomCTA")}
				</button>
			</div>
		</>
	);
};

export { CreateClassroomPage };
