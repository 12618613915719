import {
	IADELETECardTemplate,
	IAGETCardTemplate,
	IAPOSTCreateCardTemplate,
	IAPUTCardTemplate,
	IRGETAllCardTemplates,
	IRGETCardTemplate,
	IRPOSTCreateCardTemplate,
	RGETAllCardTemplatesSchema,
	RGETCardTemplateSchema,
	RPOSTCreateCardTemplateSchema,
} from "./validators";
import { IRequest } from "../requests";
import { inject } from "@app/modules";
import { PromisesKeeperAPI } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";
import { CardTemplate } from "@app/models/card-template";

export class CardTemplatesController {
	private readonly Request: IRequest;

	private readonly _CardTemplateModel = inject("CardTemplateModel");

	private cardTemplatePromises = new PromisesKeeperAPI<
		ObjectId,
		CardTemplate
	>();

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCardTemplate): Promise<CardTemplate> =>
		this.Request.send("POST", "/api/card-templates", args, null, {
			responseSchema: RPOSTCreateCardTemplateSchema,
		}).then((data: IRPOSTCreateCardTemplate) => {
			return this._CardTemplateModel.loadOneSync(data);
		});

	update = async (args: IAPUTCardTemplate): Promise<CardTemplate | null> =>
		this.Request.send("PUT", "/api/card-templates/:_id", args).then(() =>
			this._CardTemplateModel.updateOneSync({ _id: args._id }, args)
		);

	getById = async (
		args: IAGETCardTemplate,
		loadFresh?: boolean
	): Promise<CardTemplate> => {
		if (!loadFresh) {
			const cardTemplate = this._CardTemplateModel.findByIdSync(args._id);
			if (cardTemplate) return cardTemplate;
		}
		return this.cardTemplatePromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/card-templates/:_id", args, null, {
				responseSchema: RGETCardTemplateSchema,
			}).then((data: IRGETCardTemplate) => {
				return this._CardTemplateModel.loadOneSync(data);
			})
		);
	};

	getAll = async (): Promise<CardTemplate[]> =>
		this.Request.send("GET", "/api/card-templates/", undefined, null, {
			responseSchema: RGETAllCardTemplatesSchema,
		}).then((data: IRGETAllCardTemplates) => {
			this._CardTemplateModel.meta.updateLoadTime();
			return this._CardTemplateModel.loadManySync(data, "replaceAll");
		});

	deleteById = async (args: IADELETECardTemplate): Promise<void> =>
		this.Request.send("DELETE", "/api/card-templates/:_id", args).then(() =>
			this._CardTemplateModel.deleteByIdSync(args._id)
		);
}
