import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { useModelDocByQuery } from "m-model-react";
import { useMemo, useEffect } from "react";
import { useResourceInfoWithLoading } from "./fetch";

const fetchFolderLevels = (courseId: ObjectId | null) => {
	const FoldersController = inject("FoldersController");
	if (!courseId) return;
	return FoldersController.getLevels({
		courseId,
	}).then(data => {
		const UserFolderLevelModel = inject("UserFolderLevelModel");
		return new UserFolderLevelModel(data);
	});
};

export const useUserFolderLevels = (
	coursesUserId: number | null,
	courseId: ObjectId | null
) => {
	const query = useMemo(
		() =>
			!coursesUserId || !courseId
				? null
				: { userId: coursesUserId, courseId },
		[courseId, coursesUserId]
	);
	const folderLevels = useModelDocByQuery(
		inject("UserFolderLevelModel"),
		query
	);

	const needsRecalculation = !!(
		folderLevels && folderLevels.needsRecalculation
	);

	useEffect(() => {
		if (!needsRecalculation || !courseId) return;
		fetchFolderLevels(courseId);
	}, [courseId, needsRecalculation]);

	const resourceInfo = useResourceInfoWithLoading({
		resource: folderLevels,
		fetchingArg: courseId!,
		fetch: fetchFolderLevels,
		isIdentificationKnown: !!coursesUserId && !!courseId,
	});
	return resourceInfo;
};
