import { VideoLesson } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import validateSchema from "@app/utils/validate-schema";
import { PromisesKeeperAPI } from "../promises-keeper";
import { IRequest } from "../requests";
import {
	ITemporaryVideoLink,
	IVideoLesson,
	TemporaryVideoLinkSchema,
	VideoLessonResourceTypes,
	VideoLessonSchema,
} from "./helper-schemas";
import {
	IAGETPopTemporaryVideoLink,
	IAGETVideoLesson,
	IAGETVideoLessonsMany,
	IAPOSTAddTemporaryVideoLinks,
	IAPUTSaveTeacherVisitTime,
	IAPUTSaveVideoLesson,
	IRGETAllTemporaryLinkRequests,
	IRGETVideoLesson,
	IRGETVideoLessonsMany,
	IRPUTSaveVideoLesson,
	IRRGETPopTemporaryVideoLink,
	RGETAllTemporaryLinkRequestsSchema,
	RGETVideoLessonSchema,
	RGETVideoLessonsManySchema,
} from "./validators";

export class VideoLessonsController {
	private readonly Request: IRequest;

	private readonly _VideoLessonModel = inject("VideoLessonModel");
	constructor(request: IRequest) {
		this.Request = request;
	}

	private videoPromises = new PromisesKeeperAPI<ObjectId, VideoLesson>(
		docs => {
			const mainPromise = this.getMany({
				resourceIds: docs.map(e => e.id),
				resourceType: VideoLessonResourceTypes.CLASSROOM,
			});
			return docs.map(
				(doc): Promise<VideoLesson> => {
					return mainPromise.then(videoLessons => {
						const resourceId = doc.id;
						const videoLesson = videoLessons.find(
							e => e.resourceId === resourceId
						);
						if (videoLesson) return videoLesson;
						return this._VideoLessonModel.loadEmpty(
							resourceId,
							VideoLessonResourceTypes.CLASSROOM
						);
					});
				}
			);
		},
		15
	);

	add = async (args: IAPUTSaveVideoLesson): Promise<VideoLesson> =>
		this.Request.send("PUT", "/api/video-lessons/", args, null, {
			responseSchema: VideoLessonSchema,
		}).then((data: IRPUTSaveVideoLesson) => {
			return this._VideoLessonModel.loadOneSync(data);
		});

	requestTemporaryLink = async (
		args: IAGETPopTemporaryVideoLink
	): Promise<IRRGETPopTemporaryVideoLink> =>
		this.Request.send(
			"GET",
			"/api/temporary-video-links/",
			args,
			null
		).then((data: IRRGETPopTemporaryVideoLink) => {
			return this._VideoLessonModel.loadOneSync(data);
		});

	getMany = async (args: IAGETVideoLessonsMany): Promise<VideoLesson[]> =>
		this.Request.send("GET", "/api/video-lessons", args, null, {
			responseSchema: RGETVideoLessonsManySchema,
		}).then((data: IRGETVideoLessonsMany) =>
			this._VideoLessonModel.loadManySync(data)
		);

	get = async (
		args: IAGETVideoLesson,
		loadFresh?: boolean
	): Promise<VideoLesson> => {
		if (!loadFresh) {
			const doc = this._VideoLessonModel.findByResourceId(
				args.resourceId
			);
			if (doc) return doc;
		}
		return this.videoPromises.getOrSetPromise(args.resourceId, () =>
			this.Request.send(
				"GET",
				"/api/video-lessons/:resourceId",
				args
			).then((data: IRGETVideoLesson | null) => {
				if (!data) {
					return this._VideoLessonModel.loadEmpty(
						args.resourceId,
						args.resourceType
					);
				}
				data = validateSchema(data, RGETVideoLessonSchema);
				return this._VideoLessonModel.loadOneSync(data!);
			})
		);
	};

	saveTemporaryLinks = async (
		args: IAPOSTAddTemporaryVideoLinks
	): Promise<void> =>
		this.Request.send("POST", "/api/temporary-video-links/", args, null);

	getAllLinkRequests = async (): Promise<IRGETAllTemporaryLinkRequests> =>
		this.Request.send("GET", "/api/temporary-link-requests/", {}, null, {
			responseSchema: RGETAllTemporaryLinkRequestsSchema,
		});

	getAllTemporaryLinks = async (): Promise<ITemporaryVideoLink> =>
		this.Request.send("GET", "/api/temporary-video-links/all", {}, null, {
			responseSchema: TemporaryVideoLinkSchema,
		});

	saveTeacherVisitTime = async (
		args: IAPUTSaveTeacherVisitTime
	): Promise<VideoLesson> => {
		return this.Request.send(
			"PUT",
			"/api/video-lessons/teacher-visited",
			args,
			null,
			{ responseSchema: VideoLessonSchema }
		).then((data: IVideoLesson) => {
			return this._VideoLessonModel.loadOneSync(data);
		});
	};
}
