import { Classroom } from "@app/models/classroom";
import { ObjectId } from "@app/utils/generics";
import {
	ISidebarItem,
	IDisplaySideBarPaths,
} from "@app/components/layouts/sidebar/helpers/interfaces";
import { createSidebarIconRenderer } from "@app/components/layouts/sidebar/helpers/functions";
import { SidebarSentHomeworkIcon, SidebarGradebookIcon } from "@app/icons";

export enum StudentSidebarType {
	course = "student-course",
}

const gradebookHiddenSchools: number[] = [4428];

type IStudentDisplaySideBarPath = IDisplaySideBarPaths<StudentSidebarType>;
export const StudentSidebarItems: IStudentDisplaySideBarPath[] = [
	{
		type: StudentSidebarType.course,
		paths: [
			{
				name: "დავალებები",
				href: "/courses/:courseId/my-assignments",
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarSentHomeworkIcon
				),
			},
			{
				name: "ჩემი ნიშნები",
				href: "/courses/:courseId/gradebook",
				notAvailableSchoolIds: gradebookHiddenSchools,
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarGradebookIcon
				),
			},
		],
	},
];

export const isGradebookHiddenForClassroom = (
	classroom: Classroom | undefined
) => {
	if (
		!classroom ||
		gradebookHiddenSchools.some(e => classroom.matchesSchoolId(e))
	) {
		return true;
	}
	return false;
};

const getSidebarItemsForConcreteClassroom = (classroom?: Classroom) =>
	!classroom || classroom.grade <= 5
		? StudentSidebarItems.map(e => ({
				...e,
				paths: e.paths.map(e => {
					if (e.name === "ჩემი ნიშნები") {
						return { ...e, name: "ჩემი დღიური" };
					}
					return e;
				}),
		  }))
		: StudentSidebarItems;

export const isStudentSidebarPath = (path: string) => {
	for (let i = 0; i < StudentSidebarItems.length; i++) {
		const element = StudentSidebarItems[i];
		for (let a = 0; a < element.paths.length; a++) {
			const curPath = element.paths[a];
			if (curPath.href === path) {
				return true;
			}
		}
	}
	return false;
};

export const shoudlRenderNewStudentHeader = (path: string) => {
	return isStudentSidebarPath(path);
};

const getKey = (type: StudentSidebarType) => {
	switch (type) {
		case StudentSidebarType.course:
			return "courseId";
		default:
			return;
	}
};

export const getStudentSidebarItems = ({
	type,
	id,
	isTestUser,
	classroom,
}: {
	type: StudentSidebarType;
	id: ObjectId;
	isTestUser: boolean;
	classroom: Classroom | undefined;
}): ISidebarItem[] => {
	const res: ISidebarItem[] = [];
	const formatedPaths = getSidebarItemsForConcreteClassroom(classroom);
	const paths = formatedPaths.find(e => e.type === type)?.paths || [];
	const key = getKey(type)!;

	for (let i = 0; i < paths.length; i++) {
		const element = paths[i];
		const newItem = {
			name: element.name,
			href: element.href.replace(`:${key}`, id),
		};
		let show = true;
		if (element.isAvaliableForOnlyTestUsers && !isTestUser) {
			show = false;
		}
		if (
			element.notAvailableSchoolIds &&
			isGradebookHiddenForClassroom(classroom)
		) {
			show = false;
		}

		if (show) {
			res.push(newItem);
		}
	}
	return res;
};
