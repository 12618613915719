import { useLocale } from "@app/hooks/intl";
import Copyright from "@material-ui/icons/Copyright";
import MailIcon from "@material-ui/icons/Mail";
import LocationIcon from "@material-ui/icons/Room";
import {
	animateWindowScroll,
	getHTMLElementCoords,
} from "@tests-core/utils/dom";
import React, { useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { DivReference } from ".";
import { ReactComponent as ContactIcon } from "./styles/img/hubu-info.svg";
import styles from "./styles/landing-main-page.module.css";

export const BottomPageBanner: React.FC<{}> = () => {
	const contactRef = useRef<HTMLDivElement | null>(null);
	const local = useLocale();
	const scrollToReference = useCallback((ref: DivReference) => {
		if (!ref.current) return;
		const coordinates = getHTMLElementCoords(ref.current);
		animateWindowScroll(coordinates.top - 100, 300);
	}, []);

	return (
		<div className={styles.bottomPage} ref={contactRef}>
			<div className={styles.bottomPageFirstText}>
				<div className={styles.bottomPageTextWithIcon}>
					<div className={styles.bottomPageIcon}>
						<LocationIcon />
					</div>
					<div className={styles.bottomPageText}>
						<div>
							<FormattedMessage id="landing:adress.mainTitle" />
						</div>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:adress.city" />
						</div>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:adress.street" />
						</div>
					</div>
				</div>
				<div className={styles.bottomPageTextWithIcon}>
					<div className={styles.bottomPageIcon}>
						<MailIcon />
					</div>
					<div className={styles.bottomPageText}>
						<div>
							<FormattedMessage id="landing:mail" />
						</div>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:mailAddress" />
						</div>
					</div>
				</div>
				<div
					style={{
						fontSize: 12,
						display: "flex",
						alignItems: "center",
						color: "white",
					}}
				>
					<Copyright
						style={{ width: 13, height: 13, marginRight: 2 }}
					/>{" "}
					<span>
						<FormattedMessage id="landing:copyright" />
					</span>
				</div>
				{local === "ka" && (
					<div style={{ color: "white", fontSize: 12 }}>
						Powered By Murtsku
					</div>
				)}
			</div>

			<div
				className={styles.viewContactButton}
				onClick={() => scrollToReference(contactRef)}
			>
				<ContactIcon className={styles.viewContactIcon} />
			</div>
		</div>
	);
};

export const ResponsiveFooter: React.FC<{}> = props => {
	const contactRef = useRef<HTMLDivElement | null>(null);
	const local = useLocale();
	const scrollToReference = useCallback((ref: DivReference) => {
		if (!ref.current) return;
		const coordinates = getHTMLElementCoords(ref.current);
		animateWindowScroll(coordinates.top - 100, 300);
	}, []);

	return (
		<div className={styles.bottomPage} ref={contactRef}>
			<div className={styles.bottomPageResponsiveFirstText}>
				<div className={styles.bottomPageTextWithIcon}>
					<div className={styles.bottomPageIcon}>
						<LocationIcon />
					</div>
					<div className={styles.bottomPageText}>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:adress.city" />
						</div>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:adress.street" />
						</div>
					</div>
				</div>
				<div
					className={styles.bottomPageTextWithIcon}
					style={{ alignItems: "center" }}
				>
					<div className={styles.bottomPageIcon}>
						<MailIcon />
					</div>
					<div className={styles.bottomPageText}>
						<div className={styles.secondText}>
							<FormattedMessage id="landing:mailAddress" />
						</div>
					</div>
				</div>
			</div>
			{local === "ka" && (
				<div style={{ color: "white", fontSize: 12, marginTop: 8 }}>
					Powered By Murtsku
				</div>
			)}
			<div
				style={{
					fontSize: 12,
					display: "flex",
					alignItems: "center",
					color: "white",
				}}
			>
				<Copyright style={{ width: 13, height: 13, marginRight: 2 }} />{" "}
				<span style={{ fontSize: 10 }}>
					<FormattedMessage id="landing:copyright" />
				</span>
			</div>

			<div
				className={styles.viewContactButton}
				onClick={() => scrollToReference(contactRef)}
			>
				<ContactIcon className={styles.viewContactIcon} />
			</div>
		</div>
	);
};
