import React, { useCallback, useState } from "react";
import { HeaderWithLogInAndRegister } from "../landing/header";
import { BackgroundOneWave } from "../backgrounds";
import styles from "./styles/about-us.module.css";
import curveIllustration from "./styles/imgs/curve.svg";
import { FormattedMessage } from "react-intl";
import Murtsku from "./styles/imgs/Murtsku_logo.svg";
import MurtskuLat from "./styles/imgs/murtskuLat.svg";
import Unicat from "./styles/imgs/unicat.svg";
import { ReactComponent as AboutUsIcon } from "./styles/imgs/start-up (1).svg";
import HubuIcon from "./styles/imgs/HubuLogo.svg";

export const AboutUs: React.FC<{}> = props => {
	return (
		<>
			<HeaderWithLogInAndRegister />
			<div className={styles.aboutUsMainComponent}>
				<div className={styles.title}>
					<FormattedMessage id="aboutUs.title" />
				</div>
				<CurveAboutUs />
				<ExplanationText />
				<OurProducts />
			</div>
		</>
	);
};

const CurveAboutUs: React.FC<{}> = props => {
	return (
		<div className={styles.curveAndPoints}>
			<img
				src={curveIllustration}
				alt="Curve Illustration"
				width="769px"
				height="223px"
			/>
			<Points />
		</div>
	);
};

const Points = props => {
	return (
		<>
			<div className={styles.point} style={{ top: 62, left: 175 }}>
				<FormattedMessage id="aboutUs.points.murtsku" />
			</div>
			<div className={styles.point} style={{ top: 110, left: 311 }}>
				<FormattedMessage id="aboutUs.points.uniCat" />
			</div>
			<div className={styles.point} style={{ top: 45, left: 482 }}>
				<FormattedMessage id="aboutUs.points.murtskuLat" />
			</div>
			<div className={styles.point} style={{ top: 85, right: 175 }}>
				<FormattedMessage id="aboutUs.points.hubu" />
			</div>
		</>
	);
};

const ExplanationText: React.FC<{}> = props => {
	return (
		<div className={styles.explanationText}>
			<div className={styles.explanationTextPart1}>
				<FormattedMessage id="aboutUs.mainText1" />
			</div>
			<div className={styles.explanationTextPart2}>
				<FormattedMessage id="aboutUs.mainText2" />
			</div>
		</div>
	);
};

const OurProducts: React.FC<{}> = props => {
	return (
		<div className={styles.ourProducts}>
			<div className={styles.text}>
				<AboutUsIcon
					style={{ width: 25, height: 25, marginBottom: 8 }}
				/>
				<FormattedMessage id="aboutUs.products.text" />
			</div>
			<div className={styles.productsContainer}>
				<img
					src={Murtsku}
					style={{ width: 104, cursor: "pointer" }}
					alt="Murtsku"
					onClick={() =>
						window.open("https://www.murtsku.com", "_blank")
					}
				/>
				<img
					src={MurtskuLat}
					alt="MurtskuLat"
					style={{ width: 145, marginTop: 13, cursor: "pointer" }}
					onClick={() =>
						window.open("https://unicatalog.ge/", "_blank")
					}
				/>
				<img
					src={Unicat}
					alt="UniCat"
					style={{ width: 150, marginTop: 12, cursor: "pointer" }}
					onClick={() =>
						window.open("https://unicatalog.ge/", "_blank")
					}
				/>
				<div className={styles.logoAndTextContainer}>
					<img src={HubuIcon} alt="Hubu" style={{ width: 34 }} />
					<FormattedMessage id="aboutUs.points.hubu" />
				</div>
			</div>
		</div>
	);
};
