import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import { useFolder } from "../../../hooks/folders";
import { useCurrentLessonInfo } from "../../../hooks/lessons";
import { useHistory, useWindowSize } from "../../../hooks/front";
import { useBoolean } from "../../../hooks/general";
import { CurrentLessonCircularProcess } from "../lessons/current-lesson-circular-progress";
import LessonTestsPopup from "../lessons/lesson-tests";
import HomeworkStyles from "../styles/homework.module.css";
import styles from "./styles/students-main-page.module.css";
import { CurrentLessonLoader } from "./loaders";
import {
	ICourseInfo,
	IExtendedCourseInfo,
} from "@app/api/courses-info/helper-schemas";
import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { useCoursesUserId } from "@app/hooks/users";
import { useUserFolderLevels } from "@app/hooks/user-folder-level";

interface IMainPageCurrentLesson {
	courseId: ObjectId;
	courseInfo: ICourseInfo | IExtendedCourseInfo;
	containerRef?: React.MutableRefObject<HTMLElement | null>;
}

export const MainPageCurrentLesson: React.FC<IMainPageCurrentLesson> = React.memo(
	// eslint-disable-next-line max-lines-per-function
	props => {
		const { width } = useWindowSize();
		const coursesUserId = useCoursesUserId()!;
		let CircularBarNumberFontSize = 44;
		const isMobile = width < 600;
		let size = 196;
		let strokeWidth = 30;
		if (width <= 600) {
			CircularBarNumberFontSize = 30;
			size = 130;
			strokeWidth = 20;
		}
		if (width <= 500) {
			CircularBarNumberFontSize = 28;
			size = 114;
			strokeWidth = 15.5;
		}

		const {
			value: isLessonsTestPopupOpen,
			setTrue: openLessonsTestsPopup,
			setFalse: onLessonsTestsPopupClose,
		} = useBoolean(false);
		const history = useHistory();
		const { currentLesson } = useCurrentLessonInfo({
			courseId: props.courseId,
		});

		const lessonsRootFolderId = (props.courseInfo as IExtendedCourseInfo)
			.customFolderIds
			? (props.courseInfo as IExtendedCourseInfo).customFolderIds.lessons
			: props.courseInfo.rootFolder;

		const lessonsRootFolder = useFolder({
			folderId: lessonsRootFolderId || null,
			courseId: props.courseId,
		});

		const lessonId = currentLesson ? currentLesson.folder._id : null;
		const progress = currentLesson ? currentLesson.progress : 0;

		const isFirstWritableChild =
			lessonId && lessonsRootFolder.isSuccessfullyLoaded
				? isFirstWritableChildOfFolder(lessonId, lessonsRootFolder.doc)
				: false;

		const levels = useUserFolderLevels(
			coursesUserId || null,
			props.courseId
		);

		const onStartLesson = React.useCallback(() => {
			openLessonsTestsPopup();
			triggerEvent({
				category: "Button",
				action: "start current lesson ",
				label: "from main page",
			});
		}, [openLessonsTestsPopup]);

		if (!lessonId || !currentLesson || !levels.isSuccessfullyLoaded) {
			return (
				<CurrentLessonLoader
					courseId={props.courseId}
					isMobile={isMobile}
				/>
			);
		}
		const lessonLevel = levels.doc.getLevel(lessonId);

		let textColor = "";
		textColor = "#536edb";
		let fontSize = 20;
		if (currentLesson.folder.name.length > 65) {
			fontSize = 16;
		}
		if (currentLesson.folder.name.length > 100) {
			fontSize = 14;
		}

		if (width <= 600) {
			fontSize = 16;
		}

		return (
			<div
				className={styles.currentLesson}
				ref={
					props.containerRef as React.MutableRefObject<HTMLDivElement | null>
				}
			>
				{isLessonsTestPopupOpen && (
					<LessonTestsPopup
						courseId={props.courseId}
						folderId={lessonId}
						onClose={onLessonsTestsPopupClose}
					/>
				)}
				<div className={styles.currentLessonText}>
					<div className={HomeworkStyles.currentLessonName}>
						{isFirstWritableChild
							? getFormattedMessage("student:firstLesson")
							: getFormattedMessage("student:currentLesson")}
					</div>
					<div
						className={HomeworkStyles.topicName}
						style={
							currentLesson.folder.name.length >= 100
								? { fontSize, margin: "3px 0px" }
								: { fontSize }
						}
					>
						{currentLesson.folder.name}
					</div>
					<div>
						<button
							className={styles.button}
							onClick={onStartLesson}
						>
							{progress
								? getFormattedMessage("continue")
								: getFormattedMessage("start")}
						</button>
					</div>
					{/*  */}
					{width > 500 && (
						<div
							className={styles.buttonAll}
							style={{
								justifyContent: "flex-start",
								alignItems: "center",
							}}
							onClick={() => {
								history.push(
									`/courses/${props.courseId}/lessons`
								);
							}}
						>
							<FormattedMessage id="seeAll" />

							<ArrowRightIcon style={{ fontSize: 20 }} />
						</div>
					)}
				</div>

				<div className={styles.progressBarAndButton}>
					<CurrentLessonCircularProcess
						size={size}
						progressStrokeWidth={strokeWidth}
						progress={lessonLevel.totalLevel}
					>
						<div
							className={styles.taskImg}
							style={{
								color: textColor,
								fontSize: CircularBarNumberFontSize,
							}}
						>
							{lessonLevel.getFormattedTotalLevel()}
						</div>
					</CurrentLessonCircularProcess>
					{width <= 500 && (
						<div className={styles.buttonAllContainer}>
							<div
								className={styles.buttonAll}
								style={{
									justifyContent: "flex-start",
									alignItems: "center",
								}}
								onClick={() => {
									history.push(
										`/courses/${props.courseId}/lessons`
									);
								}}
							>
								<FormattedMessage id="seeAll" />

								<ArrowRightIcon style={{ fontSize: 20 }} />
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
);

const isFirstWritableChildOfFolder = (
	folderId: ObjectId,
	parentFolder: Pick<IRFolder, "items">
): boolean | null => {
	if (!parentFolder.items) return false;
	for (const item of parentFolder.items) {
		if (item.type !== ItemType.folder || item.isHidden || item.isOptional) {
			continue;
		}
		if (item.id === folderId) return true;
		const FolderModel = inject("FolderModel");
		const childFolder = FolderModel.findByIdSync(item.id);
		if (!childFolder) return null;
		return isFirstWritableChildOfFolder(folderId, childFolder);
	}
	return false;
};
