import { ITopic } from "@app/api/topics/helper-schemas";
import { IHierarchyInfo } from "@app/services/hierarchy-info/interfaces";
import { ObjectId } from "@app/utils/generics";
import React, { useMemo } from "react";
import { IMultipleSelectItem } from "../../../../widgets/deep-multiple-select";
import { DeepMultipleSelectWithSearch } from "../../../../widgets/deep-multiple-select-wth-search";
import { getItemsAndHierarchyWithoutRoot } from "@app/utils/hierarchies";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import { getFormattedMessage } from "@app/utils/locale";

export const TopicsSelect: React.FC<{
	topicsInfo: {
		topics: ITopic[];
		hierarchyInfo: IHierarchyInfo;
	} | null;
	defaultSelectedTopicIds?: ObjectId[];
	onChange: (topicIds: ObjectId[]) => void;
	multipleSelectClassName?: string;
	className?: string;
	iconClassName?: string;
	style?: React.CSSProperties;
}> = React.memo(props => {
	const { topicsInfo } = props;

	const { hierarchyInfo, items } = useMemo((): {
		items: IMultipleSelectItem<string>[] | null;
		hierarchyInfo: IHierarchyInfo | null;
	} => {
		return getItemsAndHierarchyWithoutRoot((!topicsInfo
			? null
			: {
					items: topicsInfo.topics,
					hierarchyInfo: topicsInfo.hierarchyInfo,
			  }) as null);
	}, [topicsInfo]);

	return (
		<div className={props.className} style={props.style}>
			{topicsInfo && items && hierarchyInfo && (
				<DeepMultipleSelectWithSearch
					items={items}
					hierarchyInfo={hierarchyInfo}
					defaultSelectedItemIds={props.defaultSelectedTopicIds}
					onChange={props.onChange}
					placeholder={getFormattedMessage("chooseTopics")}
					icon={<DownArrowIcon />}
					iconClassName={props.iconClassName}
					className={props.multipleSelectClassName}
				/>
			)}
		</div>
	);
});
