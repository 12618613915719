import { SPECIALITIES } from "@app/consts";
import { inject } from "@app/modules";
import { css } from "emotion";
import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import Select from "react-select";

// eslint-disable-next-line max-lines-per-function
const ChooseSpecialityPage: React.FC<{ code: string }> = ({ code }) => {
	const [speciality, setSpeciality] = useState<number>();
	const [isSent, setIsSentStatus] = useState<boolean>(false);

	const onChooseSpeciality = spec => {
		setSpeciality(spec.value);
	};
	const options = useMemo(
		() => SPECIALITIES.map(el => ({ value: el.id, label: el.name })),
		[]
	);

	const onConfirm = useCallback(() => {
		if (speciality === undefined) alert("მიუთითეთ სპეციალობა");
		else {
			const UsersController = inject("UsersController");
			UsersController.saveUserSpeciality({
				speciality,
				code,
			})
				.then(() => setIsSentStatus(true))
				.catch(() => alert("დაფიქსირდა შეცდომა"));
		}
	}, [code, speciality]);

	if (isSent) {
		return (
			<div style={containerStyles}>
				<div style={finalPageStyles}>
					თქვენი არჩევანის შესახებ ინფორმაცია მიღებულია
					<br /> ჰუბუ ყველა საჭირო ინფორმაციას მოგაწვდის!
				</div>
			</div>
		);
	}

	return (
		<div style={containerStyles}>
			<div style={headerContainerStyles}>
				<h1 style={h1Styles}>
					აბიტურიენტობის ნახევარი უკვე გავლილია, პიკში შევედით!
				</h1>
				<h1 style={h1Styles}>
					ახლა მთავარი ამოცანა პროფესიის და უნივერსიტეტის არჩევაა!
				</h1>
				<h1 style={h1Styles}>
					სწორი გადაწყვეტილების მისაღებად "ჰუბუ" საჭირო ინფორმაციებით
					მოგამარაგებს!
				</h1>
			</div>
			<div style={mainStyles}>
				ჰუბუ გაგიმარტივებს არჩევანის გაკეთებას! <br />
				<br />
				<strong>მონიშნე</strong> შენთვის საინტერესო{" "}
				<strong>სპეციალობა</strong> და <strong>ჩვენ მოგაწვდით</strong>{" "}
				დეტალურ ინფორმაციას ამ ფაკულტეტზე!
				<div style={chooseSubjectStyles}>
					<strong>აირჩიე სპეციალობა</strong>
					<div style={selectStyles}>
						<Select
							options={options}
							placeholder={"აირჩიეთ სპეციალობა"}
							onChange={onChooseSpeciality}
						/>
					</div>
				</div>
				{speciality !== undefined && (
					<button className={buttonStyles} onClick={onConfirm}>
						დასტური
					</button>
				)}
			</div>
		</div>
	);
};

type T = CSSProperties;

const containerStyles: T = {
	fontFamily: "Roboto Geo Nus",
	maxWidth: 1200,
	margin: "0 auto",
};

const headerContainerStyles: T = {
	fontWeight: "bold",
	fontSize: 18,
	fontFamily: "Roboto Geo Caps",
	display: "inline-block",
	margin: "40px 0px",
	color: "#5273e6",
};

const h1Styles: T = { marginBottom: 10 };

const mainStyles: T = { textAlign: "left" };

const chooseSubjectStyles: T = { marginTop: 20 };

const selectStyles: T = { marginTop: 20, maxWidth: 600 };

const buttonStyles = css`
	width: 100px;
	height: 40px;
	outline: none;
	border: none;
	border-radius: 6px;
	color: white;
	margin: 20px 0px;
	background-color: #5273e6;
	&:hover {
		transform: scale(1.1);
		transition-duration: 0.3s;
	}
`;

const finalPageStyles: T = {
	margin: "0 auuto",
	marginTop: 100,
};

export default ChooseSpecialityPage;
