import {
	IAssignment,
	IRUserWrittenAssignment,
} from "@app/api/assignments/helper-schemas";
import { IStudentHomeworkListItem } from "@app/api/assignments/validators";
import {
	addLeadingZeroes,
	animateWindowScroll,
	getHTMLElementCoords,
} from "@app/common-javascript";
import { useStudentClassroomAssignmentsInfo } from "@app/hooks/assignments";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import React, { useCallback, useMemo, useRef } from "react";
import { BigBackgroundUp } from "../../backgrounds";
import { ReactComponent as CalendarIcon } from "../../styles/img/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../styles/img/icons/clock.svg";
import { ReactComponent as DeadlineIcon } from "../../styles/img/icons/deadline.svg";
import { ReactComponent as QuestionIcon } from "../../styles/img/icons/question.svg";
import { ProgressBar } from "../../widgets/progress-bar";
import exerciseTaskStyles from "../main/styles/students-main-page.module.css";
import HomeworkStyles from "../styles/homework.module.css";
import { StudentAssignmentLoader } from "./loaders";
import { useBoolean } from "@app/hooks/general";
import { TeacherInvitationPopup } from "../invitations/outgoing";
import styles from "../exercises/styles/exercise-page.module.css";
import { ReactComponent as NoAssignmentsIcon } from "./styles/img/no-assignment.svg";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { useDays } from "@app/hooks/dates";
import classNames from "classnames";

export const StudentAssignmentsPage: React.FC<{
	courseId: ObjectId;
}> = props => {
	const history = useHistory();
	const classroom = useClassroomByCourseId(props.courseId || null);
	const classroomId = classroom.doc ? classroom.doc._id : null;
	const studentAssignmentsInfo = useStudentClassroomAssignmentsInfo(
		classroomId
	);
	const allAssignmentsRef = useRef<HTMLElement>(null);
	const {
		value: areOldAssignmentsVisible,
		setTrue: showOldAssignments,
	} = useBoolean(false);

	const showAllAssignments = useCallback(() => {
		showOldAssignments();
		setTimeout(() => {
			if (allAssignmentsRef.current) {
				animateWindowScroll(
					getHTMLElementCoords(allAssignmentsRef.current).top - 100,
					300
				);
			}
		}, 0);
	}, [showOldAssignments]);

	const {
		value: isInvitationPopupOpen,
		setTrue: openInvitationPopup,
		setFalse: closeInvitationPopup,
	} = useBoolean(false);

	const gotoMainPage = useCallback(() => {
		history.push(`/courses/${props.courseId}`);
	}, [history, props.courseId]);

	const mainAssignmentsInfo = useMemo(
		() =>
			!studentAssignmentsInfo
				? []
				: studentAssignmentsInfo.filter(assignmentInfo => {
						const {
							deadlinePassed,
							isSubmitted,
						} = getAssignmentAditionalInfo(assignmentInfo);

						return !deadlinePassed && !isSubmitted;
				  }),
		[studentAssignmentsInfo]
	);
	const allAssignmentsInfo = useMemo(
		() =>
			!studentAssignmentsInfo
				? []
				: studentAssignmentsInfo.filter(assignmentInfo => {
						const {
							deadlinePassed,
							isSubmitted,
						} = getAssignmentAditionalInfo(assignmentInfo);

						return deadlinePassed || isSubmitted;
				  }),
		[studentAssignmentsInfo]
	);

	if (classroom.isIdentificationKnown && !studentAssignmentsInfo) {
		return (
			<div>
				<BigBackgroundUp />
				<StudentAssignmentLoader />
				<StudentAssignmentLoader />
				<StudentAssignmentLoader />
			</div>
		);
	}

	return (
		<>
			<div>
				<BigBackgroundUp />
				{mainAssignmentsInfo && mainAssignmentsInfo.length === 0 && (
					<MissingItems
						title={
							!classroom.isIdentificationKnown
								? ""
								: getFormattedMessage(
										"student:noIncomplitAssignments"
								  )
						}
						onClick={
							!classroom.isIdentificationKnown
								? openInvitationPopup
								: allAssignmentsInfo.length === 0
								? gotoMainPage
								: showAllAssignments
						}
						actionTitle={
							!classroom.isIdentificationKnown
								? getFormattedMessage("student:joinClassroom")
								: allAssignmentsInfo.length === 0
								? getFormattedMessage("gotoHomePage")
								: getFormattedMessage(
										"student:viewOldAssignemnts"
								  )
						}
					/>
				)}
				{studentAssignmentsInfo && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
							alignItems: "center",
						}}
					>
						<div style={{ width: "100%" }}>
							{mainAssignmentsInfo.map((assignmentInfo, i) => {
								return (
									<div key={i} style={{ width: "100%" }}>
										<Homework
											assignmentInfo={assignmentInfo}
											courseId={props.courseId}
										/>
									</div>
								);
							})}
						</div>
						{studentAssignmentsInfo &&
							!!mainAssignmentsInfo.length &&
							studentAssignmentsInfo.length >
								mainAssignmentsInfo.length && (
								<button
									className={
										HomeworkStyles.viewAllHomeworksButton
									}
									onClick={showAllAssignments}
								>
									<FormattedMessage id="student:viewAllAssignments" />
								</button>
							)}
						{areOldAssignmentsVisible && (
							<div
								style={{ width: "100%" }}
								ref={
									allAssignmentsRef as React.MutableRefObject<
										HTMLDivElement
									>
								}
							>
								{allAssignmentsInfo.map((assignmentInfo, i) => {
									return (
										<div key={i}>
											<Homework
												assignmentInfo={assignmentInfo}
												courseId={props.courseId}
												isDeadlineInvisible={true}
												bannerClassName={
													HomeworkStyles.bannerClassName
												}
												homeworkHeadlineClassName={
													HomeworkStyles.homeworkHeadlineClassName
												}
												isCurrentLessonClassnameInvisible={
													true
												}
												topicNameClassName={
													HomeworkStyles.topicNameClassName
												}
												isNumOfQuestionsTextInvisible={
													true
												}
												isButtonDisplayedLeft={true}
												progressNumberAndBarContainerClassName={
													HomeworkStyles.progressNumberAndBarContainerClassName
												}
												progressBackgroundClassname={
													HomeworkStyles.progressBackgroundClassname
												}
												progressNumberClassname={
													HomeworkStyles.progressNumberClassname
												}
												homeworkInfoDivClassname={
													HomeworkStyles.homeworkInfoDivClassname
												}
											/>
										</div>
									);
								})}
							</div>
						)}
					</div>
				)}
			</div>
			{isInvitationPopupOpen && (
				<TeacherInvitationPopup onClose={closeInvitationPopup} />
			)}
		</>
	);
};

export const getAssignmentAditionalInfo = (
	assignmentInfo: IStudentHomeworkListItem
) => {
	const { assignment } = assignmentInfo;
	const AssignmentPermissionsService = inject("AssignmentPermissionsService");
	const {
		classroomEdge,
		studentEdge,
	} = AssignmentPermissionsService.getClassroomAndStudentEdge(
		assignmentInfo.edges
	);
	const settings = AssignmentPermissionsService.getSettings({
		assignmentSettings: assignment.settings,
		classroomEdge,
		studentEdge,
	});
	const {
		deadlinePassed,
		isSubmitted,
	} = AssignmentPermissionsService.getAssignmentStatus({
		settings,
		studentEdge,
	});
	return {
		classroomEdge,
		studentEdge,
		settings,
		deadlinePassed,
		isSubmitted,
	};
};

export const getNumStatsOfStudentAssignment = ({
	assignment,
	writtenAssignment,
}: {
	assignment: IAssignment;
	writtenAssignment: IRUserWrittenAssignment | null;
}): { totalNumOfQuestions: number; answeredNumOfQuestions: number } => {
	return {
		totalNumOfQuestions: writtenAssignment
			? writtenAssignment.numOfQuestions
			: assignment.numQuestions,
		answeredNumOfQuestions: writtenAssignment
			? Object.keys(writtenAssignment.answeredQuestions).length
			: 0,
	};
};

interface IHomeworkProps {
	assignmentInfo: IStudentHomeworkListItem;
	courseId: ObjectId;
	isDeadlineInvisible?: boolean;
	bannerClassName?: string;
	homeworkHeadlineClassName?: string;
	homeworkInfoDivClassname?: string;
	isCurrentLessonClassnameInvisible?: boolean;
	topicNameClassName?: string;
	isNumOfQuestionsTextInvisible?: boolean;
	isButtonDisplayedLeft?: boolean;
	progressNumberAndBarContainerClassName?: string;
	progressBackgroundClassname?: string;
	progressNumberClassname?: string;
}

export const ActionButton: React.FC<{
	handleAssignmentRedirection: () => void;
	deadlinePassed: boolean;
	isSubmitted: boolean;
	writtenAssignment: IRUserWrittenAssignment;
}> = props => {
	return (
		<div>
			<button
				className={exerciseTaskStyles.button}
				onClick={props.handleAssignmentRedirection}
			>
				{props.deadlinePassed || props.isSubmitted
					? getFormattedMessage("review")
					: props.writtenAssignment
					? getFormattedMessage("continue")
					: getFormattedMessage("start")}
			</button>
		</div>
	);
};

export const Homework: React.FC<IHomeworkProps> = props => {
	const history = useHistory();

	const { assignmentInfo } = props;
	const { assignment, writtenAssignment } = assignmentInfo;
	const {
		classroomEdge,
		studentEdge,
		settings,
		deadlinePassed,
		isSubmitted,
	} = getAssignmentAditionalInfo(assignmentInfo);
	const { width } = useWindowSize();
	const { totalNumOfQuestions, answeredNumOfQuestions } = useMemo(
		() =>
			getNumStatsOfStudentAssignment({
				assignment: assignment,
				writtenAssignment: writtenAssignment,
			}),
		[assignment, writtenAssignment]
	);
	const handleAssignmentRedirection = useCallback(() => {
		history.push(
			`/courses/${props.courseId}/assignments/${assignment._id}`
		);
	}, [history, assignment._id, props.courseId]);

	if (!classroomEdge && !studentEdge) return null;
	const deadline = settings.deadline;

	return (
		<div className={HomeworkStyles.background}>
			<BigBackgroundUp />
			<div
				className={
					!props.bannerClassName
						? HomeworkStyles.banner
						: props.bannerClassName
				}
			>
				<div
					className={
						!props.homeworkHeadlineClassName
							? HomeworkStyles.homeworkHeadline
							: classNames(
									props.homeworkHeadlineClassName,
									HomeworkStyles.homeworkHeadline
							  )
					}
				>
					{!props.isCurrentLessonClassnameInvisible && (
						<div className={HomeworkStyles.currentLessonName} />
					)}

					<div
						className={
							!props.topicNameClassName
								? HomeworkStyles.topicName
								: classNames(
										props.topicNameClassName,
										HomeworkStyles.topicName
								  )
						}
					>
						{assignment.name}
					</div>
					{!props.isButtonDisplayedLeft && width > 600 && (
						<div>
							<button
								className={
									exerciseTaskStyles.buttonReviewAssignment
								}
								onClick={handleAssignmentRedirection}
							>
								{deadlinePassed || isSubmitted
									? getFormattedMessage("review")
									: writtenAssignment
									? getFormattedMessage("continue")
									: getFormattedMessage("start")}
							</button>
						</div>
					)}
				</div>
				<div
					className={
						!props.homeworkInfoDivClassname
							? HomeworkStyles.homeworkInfoDiv
							: props.homeworkInfoDivClassname
					}
				>
					{!props.isDeadlineInvisible && deadline && (
						<AssignmentDeadline deadline={deadline} />
					)}
					{!props.isDeadlineInvisible && (
						<div className={HomeworkStyles.greyLine}></div>
					)}

					<AssignentProgress
						totalNumOfQuestions={totalNumOfQuestions}
						answeredNumOfQuestions={answeredNumOfQuestions}
						isNumOfQuestionsTextInvisible={
							props.isNumOfQuestionsTextInvisible
						}
						progressNumberAndBarContainerClassName={
							props.progressNumberAndBarContainerClassName
						}
						progressBackgroundClassname={
							props.progressBackgroundClassname
						}
						progressNumberClassname={props.progressNumberClassname}
					/>
				</div>
				{props.isButtonDisplayedLeft && width > 600 && (
					<div>
						<button
							className={
								exerciseTaskStyles.buttonReviewAssignment
							}
							onClick={handleAssignmentRedirection}
						>
							{deadlinePassed || isSubmitted
								? getFormattedMessage("review")
								: writtenAssignment
								? getFormattedMessage("continue")
								: getFormattedMessage("start")}
						</button>
					</div>
				)}
				{width <= 600 && (
					<div style={{ textAlign: "center" }}>
						<button
							className={exerciseTaskStyles.button}
							onClick={handleAssignmentRedirection}
							style={
								width > 400
									? { width: 160, height: 36, fontSize: 18 }
									: { width: 99, height: 30, fontSize: 15 }
							}
						>
							{deadlinePassed || isSubmitted
								? getFormattedMessage("review")
								: writtenAssignment
								? getFormattedMessage("continue")
								: getFormattedMessage("start")}
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

interface IAssignmentDeadlineProps {
	deadline: Date;
}

const AssignmentDeadline: React.FC<IAssignmentDeadlineProps> = React.memo(
	props => {
		const days = useDays();
		const { deadline } = props;
		const { width } = useWindowSize();
		return (
			<div className={HomeworkStyles.deadlineDiv}>
				<div
					style={{
						display: "flex",
						width: "100px",
						margin: " 0px auto",
					}}
				>
					<div
						style={{
							color: "#58607C",
							width: "12px",
							height: "12px",
							marginRight: "4px",
						}}
					>
						{width > 400 && <DeadlineIcon />}
					</div>
					<div
						style={{ fontSize: 15 }}
						className={HomeworkStyles.questions}
					>
						<FormattedMessage id="deadline" />
					</div>
				</div>
				<div className={HomeworkStyles.deadlines}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<div
							style={{
								color: "#58607C",
								width: "20px",
								height: "20px",
								marginRight: "14px",
							}}
						>
							{width > 400 && <CalendarIcon />}
						</div>
						<div>
							<div className={HomeworkStyles.deadlineDay}>
								{days[deadline.getDay()]}
							</div>
							<div className={HomeworkStyles.deadlineDate}>
								<span>
									{addLeadingZeroes(deadline.getDate(), 2)}
								</span>
								/
								<span>
									{addLeadingZeroes(
										deadline.getMonth() + 1,
										2
									)}
								</span>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						{width > 400 && (
							<div
								style={{
									color: "#58607C",
									width: "15px",
									height: "15px",
									marginRight: "14px",
								}}
							>
								<ClockIcon />
							</div>
						)}
						<div className={HomeworkStyles.deadlineHour}>
							<span>
								{addLeadingZeroes(deadline.getHours(), 2)}
							</span>
							:
							<span>
								{addLeadingZeroes(deadline.getMinutes(), 2)}
							</span>
						</div>
					</div>
					<div></div>
				</div>
			</div>
		);
	}
);

const AssignentProgress: React.FC<{
	totalNumOfQuestions: number;
	answeredNumOfQuestions: number;
	isNumOfQuestionsTextInvisible?: boolean;
	progressNumberAndBarContainerClassName?: string;
	progressBackgroundClassname?: string;
	progressNumberClassname?: string;
}> = props => {
	const { totalNumOfQuestions } = props;
	const answeredNumOfQuestions = Math.min(
		props.answeredNumOfQuestions,
		totalNumOfQuestions
	);
	const { width } = useWindowSize();

	return (
		<div className={HomeworkStyles.numQuestionsDiv}>
			{!props.isNumOfQuestionsTextInvisible && (
				<div
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					{width > 400 && (
						<div
							style={{
								color: "#58607C",
								width: "15px",
								height: "15px",
								marginRight: "4px",
							}}
						>
							<QuestionIcon />
						</div>
					)}

					<div className={HomeworkStyles.questions}>
						<FormattedMessage id="numOfQuestions" />
					</div>
				</div>
			)}
			<div
				className={
					!props.progressNumberAndBarContainerClassName
						? HomeworkStyles.progressNumberAndBarContainer
						: classNames(
								props.progressNumberAndBarContainerClassName,
								HomeworkStyles.progressNumberAndBarContainer
						  )
				}
			>
				<ProgressBar
					progress={
						totalNumOfQuestions === 0
							? 0
							: answeredNumOfQuestions / totalNumOfQuestions
					}
					progressBackgroundClassname={
						props.progressBackgroundClassname
					}
					progressNumberClassname={props.progressNumberClassname}
				>
					{answeredNumOfQuestions} / {totalNumOfQuestions}
				</ProgressBar>
			</div>
		</div>
	);
};

interface IMissingItemsProps {
	title: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	actionTitle: string;
}

const MissingItems: React.FC<IMissingItemsProps> = props => {
	const { width } = useWindowSize();
	return (
		<div className={styles.exerciseAndText} style={{ marginTop: 150 }}>
			<div className={styles.noExerciseText}>
				<span>{props.title}</span>
				<button
					className={styles.startExercising}
					onClick={props.onClick}
				>
					{props.actionTitle}
				</button>
			</div>
			{width > 650 && (
				<div>
					<NoAssignmentsIcon
						className={styles.noExerciseIllustration}
					/>
				</div>
			)}
		</div>
	);
};
