import * as React from "react";
import FillingBalnksEditor, {
	IFBEditPassableProps,
} from "./filling-blanks/edit";
import GroupingItemsEditor, {
	IGIEditPassableProps,
} from "./grouping-items/edit";
import MultipleChoiceEditor, {
	IMCEditPassableProps,
} from "./multiple-choice/edit";
import MultipleContentsEditor from "./multiple-contents/edit";
import SortItemsEditor, { ISIEditPassableProps } from "./sort-items/edit";
import { AnyComponent } from "../../../utils/generics";
import { ContentType } from "../../../schemas/questions/contnets/common-schemas";
import { fromHTMLToState, fromStateToHTML } from "../../editor/html";
import { getUniqueId } from "../../../utils/common";
import {
	IContentEditor,
	IContentEditorProps,
	ContentError,
} from "./interfaces";
import { IFillingBlanksContent } from "../../../schemas/questions/contnets/filling-blanks/schema";
import { IGalleryComponentProps } from "./common/items-edit";
import { IGroupingItemsContent } from "../../../schemas/questions/contnets/grouping-items/schema";
import { IMultipleChoiceContent } from "../../../schemas/questions/contnets/multiple-choice/schema";
import { IMultipleContents } from "../../../schemas/questions/contnets/multiple-contents/schema";
import { IRawQuestionContent } from "../../../schemas/questions/contnets/schemas";
import { ISortItemsContent } from "../../../schemas/questions/contnets/sort-items/schema";

interface ICommonStyles {
	saveButton?: string;
	addAnotherQuestion?: string;
}

export interface ICommonTexts {
	saveButton?: string;
	addAnotherQuestion?: string;
	contentTypes?: {
		selectPlaceholder?: string;
		MultipleChoice?: {
			main?: string;
			twoColumns?: string;
			dataSufficiency?: string;
		};
		SortItems?: { main?: string };
		FillingBlanks?: {
			main?: string;
			essay?: string;
			essayWithFiles?: string;
		};
		MultipleContents?: { main?: string };
	};
}

interface ICommonComponents {
	beforeSaveButton?: AnyComponent<any>;
	beforeSaveButtonOfText?: AnyComponent<any>;
}

// tslint:disable:max-union-size
export type IEditCommonProps = IContentEditorProps & {
	toHTML: (e: any) => string;
	galleryComponent: AnyComponent<IGalleryComponentProps>;
	commonStyles?: ICommonStyles;
	commonTexts?: ICommonTexts;
	commonComponents?: ICommonComponents;
};
export interface IQContentEditPassableProps {
	mcProps?: IMCEditPassableProps;
	siProps?: ISIEditPassableProps;
	fbProps?: IFBEditPassableProps;
	giProps?: IGIEditPassableProps;
	commonProps?: Partial<IEditCommonProps>;
}

interface IProps {
	contentType?: ContentType;
	content?: IRawQuestionContent;
	customized?: IQContentEditPassableProps;
}

class QuestionContentEditMode extends React.PureComponent<IProps> {
	ref: React.RefObject<any> = React.createRef();

	generateUniqueIds: IContentEditorProps["generateUniqueIds"] = (
		numOfIds?: number
	) => {
		if (this.ref.current) {
			const component = this.ref.current as IContentEditor<
				IRawQuestionContent
			>;
			return getUniqueId(component.getCurrentIds(), numOfIds);
		}
		return getUniqueId([], numOfIds);
	};

	getData = () => {
		if (!this.ref.current) return;
		const component = this.ref.current as IContentEditor<
			IRawQuestionContent
		>;
		return component.getData();
	};

	getErrors = (): ContentError[] | null => {
		if (!this.ref.current) return null;
		const component = this.ref.current as IContentEditor<
			IRawQuestionContent
		>;
		const errors = component.getErrors();
		return errors.length > 0 ? errors : null;
	};

	render() {
		const common = {
			toHTML: fromStateToHTML as (e: any) => string,
			toEditorState: fromHTMLToState as (h: string) => any,
			generateUniqueIds: this.generateUniqueIds,
		};
		if (
			this.props.contentType === undefined &&
			this.props.content === undefined
		) {
			throw new Error("Neither contentType nor content is provided");
		}
		const type =
			this.props.contentType === undefined
				? this.props.content!.type
				: this.props.contentType;
		switch (type) {
			case ContentType.MultipleChoice:
				return (
					<MultipleChoiceEditor
						ref={this.ref}
						content={this.props.content as IMultipleChoiceContent}
						{...((this.props.customized &&
							this.props.customized.commonProps) as any)}
						{...(this.props.customized &&
							this.props.customized.mcProps)}
						{...common}
					/>
				);
			case ContentType.SortItems:
				return (
					<SortItemsEditor
						ref={this.ref}
						content={this.props.content as ISortItemsContent}
						{...((this.props.customized &&
							this.props.customized.commonProps) as any)}
						{...(this.props.customized &&
							this.props.customized.siProps)}
						{...common}
					/>
				);
			case ContentType.FillingBlanks:
				return (
					<FillingBalnksEditor
						ref={this.ref}
						content={this.props.content as IFillingBlanksContent}
						{...((this.props.customized &&
							this.props.customized.commonProps) as any)}
						{...(this.props.customized &&
							this.props.customized.fbProps)}
						{...common}
					/>
				);
			case ContentType.GroupingItems:
				return (
					<GroupingItemsEditor
						ref={this.ref}
						content={this.props.content as IGroupingItemsContent}
						{...((this.props.customized &&
							this.props.customized.commonProps) as any)}
						{...(this.props.customized &&
							this.props.customized.giProps)}
						{...common}
					/>
				);
			case ContentType.MultipleContents:
				return (
					<MultipleContentsEditor
						ref={this.ref}
						content={this.props.content as IMultipleContents}
						{...((this.props.customized &&
							this.props.customized.commonProps) as any)}
						{...this.props.customized}
						{...common}
					/>
				);
			default:
				return null;
		}
	}
}

export default QuestionContentEditMode;
