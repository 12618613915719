import { ObjectId } from "@app/utils/generics";
import React from "react";
import { IHWSettings, ShowAnswersStandardTime } from "../";
import { Selection } from "../../selections";
import styles from "../../styles/styles.module.css";
import { SettingsAndIds } from "./advanced-settings";
import { getFormattedMessage } from "@app/utils/locale";

export interface IAssignmentGeneralSettingProps {
	settings: IHWSettings;
	onChange: (id: ObjectId) => void;
}

export const AssignmentGeneralSetting: React.FC<
	IAssignmentGeneralSettingProps
> = React.memo(props => {
	return (
		<div
			style={{
				display: "flex",
				width: "90%",
				flexDirection: "column",
				margin: "0 auto",
			}}
		>
			<Selection
				isSelected={
					props.settings.showAnswersAt ===
					ShowAnswersStandardTime.submission
				}
				name={getFormattedMessage(
					"select.seeResultsAfterFinishingTest"
				)}
				onChange={props.onChange}
				id={SettingsAndIds.showAnswersAt}
				textClassName={[styles.checkboxText, styles.settings]}
				containerClassName={[styles.checkboxContainer, styles.settings]}
				checkmarkContainerClassName={[
					styles.checkMarkContainer,
					styles.settings,
				]}
				selectedCheckmarkContainerClassName={styles.isSelected}
			/>
			<Selection
				isSelected={props.settings.shuffleQuestions}
				name={getFormattedMessage("select.randomSequenceOfQuestion")}
				onChange={props.onChange}
				id={SettingsAndIds.ShuffleQuestions}
				textClassName={[styles.checkboxText, styles.settings]}
				containerClassName={[styles.checkboxContainer, styles.settings]}
				checkmarkContainerClassName={[
					styles.checkMarkContainer,
					styles.settings,
				]}
				selectedCheckmarkContainerClassName={styles.isSelected}
			/>
			<Selection
				isSelected={props.settings.shuffleAnswers}
				name={getFormattedMessage("select.randomSequenceOfAnswers")}
				onChange={props.onChange}
				id={SettingsAndIds.ShuffleAnswers}
				textClassName={[styles.checkboxText, styles.settings]}
				containerClassName={[styles.checkboxContainer, styles.settings]}
				checkmarkContainerClassName={[
					styles.checkMarkContainer,
					styles.settings,
				]}
				selectedCheckmarkContainerClassName={styles.isSelected}
			/>
		</div>
	);
});
