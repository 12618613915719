import { useGoToUrl } from "@app/hooks/front";
import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./styles/tvschool-classroom.module.css";

export const CreateClassroomBox: React.FC = () => {
	const handleRedirection = useGoToUrl("/new-classroom");

	return (
		<div className={styles.createClassroom} onClick={handleRedirection}>
			<div className={styles.createClassroomBg} />
			<span className={styles.title}>
				<FormattedMessage id="createClassroom" />
			</span>
		</div>
	);
};
