import {
	SortedContentList,
	IHWState,
	SortedContentSingleItem,
	Content,
	SortedContentNestedItem,
} from ".";
import {
	PartitionedContentList,
	PartitionedSingles,
	droppableIdPrefix,
} from "./second-step-components/questions-sections";
import { DropResult } from "react-beautiful-dnd";
import {
	IFullQuestion,
	IQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { ItemType } from "@app/api/folders/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import {
	SelectedContentArr,
	SelectedContent,
} from "./second-step-components/additional-content";
import { INumQuestionsSettings } from "@app/api/assignments/helper-schemas";
import { IClassTime } from "@app/api/classrooms/helper-schemas-2";

export const getPartitionedContent = (
	sortedContent: SortedContentList
): PartitionedContentList => {
	const partitionedContentList: PartitionedContentList = [];
	let lastType: PartitionedContentList[number]["type"] | null = null;
	let partitionIdCounter = 1;
	for (let i = 0; i < sortedContent.length; ++i) {
		const content = sortedContent[i];
		if (content.type === "nested") {
			partitionedContentList.push({
				type: "nested",
				item: content,
				id: partitionIdCounter++,
			});
			lastType = "nested";
			continue;
		}
		if (lastType === "singles") {
			(partitionedContentList[
				partitionedContentList.length - 1
			] as PartitionedSingles).items.push(content);
		} else {
			partitionedContentList.push({
				type: "singles",
				items: [content],
				id: partitionIdCounter++,
			});
		}
		lastType = "singles";
	}
	return partitionedContentList;
};

export const partitionedContentToSortedContent = (
	partitionedContent: PartitionedContentList
): SortedContentList => {
	const sortedContent: SortedContentList = [];
	for (const partition of partitionedContent) {
		if (partition.type === "singles") {
			sortedContent.push(...partition.items);
		} else {
			sortedContent.push(partition.item);
		}
	}
	return sortedContent;
};

export const getQuestionsFromSortedContent = (
	sortedContent: SortedContentList,
	content: Content
): (IQuestion | IShortQuestion)[] => {
	const questions: (IQuestion | IShortQuestion)[] = [];
	const addItems = (sortedContentList: SortedContentList) => {
		for (const item of sortedContentList) {
			if (item.type === "single" && item.itemType === ItemType.question) {
				const q = content.questions[item.itemId];
				if (q) questions.push(q);
			} else if (item.type === "nested") {
				addItems(item.childItems);
			}
		}
	};
	addItems(sortedContent);

	return questions;
};

export const getNewContentAfterDropEnd = (
	result: DropResult,
	sortedContent: SortedContentList,
	partitionedContent: PartitionedContentList
): SortedContentList => {
	if (!result.destination) return sortedContent;
	if (result.destination.droppableId !== result.source.droppableId) {
		return sortedContent;
	}
	const partitionId = +result.destination.droppableId.substr(
		droppableIdPrefix.length
	);
	const partition = partitionedContent.find(e => e.id === partitionId);
	if (!partition) {
		return sortedContent;
	}
	if (partition.type === "singles") {
		const sourceItem = partition.items[result.source.index];
		const destinationItem = partition.items[result.destination.index];
		const reorderedArr = getReorderedArray(
			sortedContent,
			sourceItem,
			destinationItem
		);
		return reorderedArr;
	} else {
		const sourceItem = partition.item.childItems[result.source.index];
		const destinationItem =
			partition.item.childItems[result.destination.index];
		return sortedContent.map((e, i) =>
			e !== partition.item
				? e
				: {
						...e,
						childItems: getReorderedArray(
							e.childItems,
							sourceItem,
							destinationItem
						),
				  }
		);
	}
};

export function getReorderedArray<T>(arr: T[], source: T, destination: T): T[] {
	const sourceIndex = arr.findIndex(e => e === source);
	const destinationIndex = arr.findIndex(e => e === destination);
	if (sourceIndex === -1 || destinationIndex === -1) {
		return arr;
	}
	const newArray = [...arr];
	newArray.splice(sourceIndex, 1);
	newArray.splice(destinationIndex, 0, source);
	return newArray;
}

const areSortedItemsEqual = (
	a: SortedContentSingleItem | SortedContentNestedItem,
	b: SortedContentSingleItem | SortedContentNestedItem
) => {
	return (
		a.type === b.type && a.itemType === b.itemType && a.itemId === b.itemId
	);
};

export const getRemovedContent = (
	contentList: SortedContentList,
	contentItemToRemove: SortedContentList[number]
): SortedContentList => {
	for (const contentItem of contentList) {
		if (areSortedItemsEqual(contentItem, contentItemToRemove)) {
			return contentList.filter(
				e => !areSortedItemsEqual(e, contentItemToRemove)
			);
		}
		if (contentItem.type === "nested") {
			for (const childItem of contentItem.childItems) {
				if (areSortedItemsEqual(childItem, contentItemToRemove)) {
					if (contentItem.childItems.length === 1) {
						// if the last question is to be deleted, delete the whole text too
						return contentList.filter(
							e => !areSortedItemsEqual(e, contentItem)
						);
					}
					return contentList.map(e =>
						e !== contentItem
							? e
							: {
									...e,
									childItems: e.childItems.filter(
										e =>
											!areSortedItemsEqual(
												e,
												contentItemToRemove
											)
									),
							  }
					);
				}
			}
		}
	}
	return contentList;
};

export const getNumQuestionsByTaskTypesFromHomework = (
	taskTypesInAdvancedSettings: IHWState["taskTypesInAdvancedSettings"]
) => {
	let numQuestionsByTaskTypes: INumQuestionsSettings["byTaskTypes"];
	if (taskTypesInAdvancedSettings) {
		numQuestionsByTaskTypes = {};
		for (const taskInfo of taskTypesInAdvancedSettings) {
			const taskTypeId = taskInfo._id;
			if (taskInfo.count <= 0) {
				continue;
			}
			if (taskInfo.countType === "question") {
				numQuestionsByTaskTypes[taskTypeId] = {
					questions: taskInfo.count,
				};
			} else {
				numQuestionsByTaskTypes[taskTypeId] = {
					texts: taskInfo.count,
				};
			}
		}
	}
	return numQuestionsByTaskTypes;
};

export const getNextDayOfClassroom = (classTimes: IClassTime[]): Date => {
	const today = new Date();
	let minimumDate = Infinity;
	for (const classTime of classTimes) {
		const dayInterval = (classTime.day - today.getDay() + 7) % 7;
		const deadline = new Date();
		const hour = classTime.start.hour;
		const minute = classTime.start.minute;
		deadline.setDate(deadline.getDate() + dayInterval);
		deadline.setHours(hour);
		deadline.setMinutes(minute);
		deadline.setSeconds(0);
		deadline.setMilliseconds(0);
		if (deadline < today) {
			deadline.setDate(deadline.getDate() + 7);
		}
		if (deadline.getTime() < minimumDate) {
			minimumDate = deadline.getTime();
		}
	}
	if (!Number.isFinite(minimumDate)) {
		return new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
	}
	return new Date(minimumDate);
};

export const getInitialSortedContent = ({
	questions,
	texts,
	skipEmptyNestedContent = true,
}: {
	questions: IFullQuestion[];
	texts: IText[];
	skipEmptyNestedContent?: boolean;
}): SortedContentList => {
	const contentList: SortedContentList = [];
	const questionsByText: Record<
		string,
		SortedContentSingleItem[] | undefined
	> = {};
	for (const question of questions) {
		if (!question.textId) {
			contentList.push({
				type: "single",
				itemType: ItemType.question,
				itemId: question._id,
			});
		} else {
			if (!questionsByText[question.textId]) {
				questionsByText[question.textId] = [];
			}
			questionsByText[question.textId]!.push({
				type: "single",
				itemId: question._id,
				itemType: ItemType.question,
			});
		}
	}
	for (const text of texts) {
		const childItems = questionsByText[text._id] || [];
		if (skipEmptyNestedContent && childItems.length === 0) {
			continue;
		}
		contentList.push({
			type: "nested",
			itemType: ItemType.text,
			itemId: text._id,
			childItems,
		});
	}
	return contentList;
};

const extractRawContentFromSortedContentList = (
	sortedContentList: SortedContentList,
	content: Content
): {
	questions: IFullQuestion[];
	texts: IText[];
} => {
	const rawContent: {
		questions: IFullQuestion[];
		texts: IText[];
	} = {
		questions: [],
		texts: [],
	};
	const addSingleItemToRawContent = (item: SortedContentSingleItem) => {
		if (item.itemType !== ItemType.question) return;
		const question = content.questions[item.itemId];
		if (!question) return;
		rawContent.questions.push(question);
	};

	const addNestedItemToRawContent = (item: SortedContentNestedItem) => {
		if (item.itemType !== ItemType.text) return;
		const text = content.texts[item.itemId];
		if (!text) return;
		rawContent.texts.push(text);
		for (const childItem of item.childItems) {
			addSingleItemToRawContent(childItem);
		}
	};

	for (const item of sortedContentList) {
		if (item.type === "single") {
			addSingleItemToRawContent(item);
		} else {
			addNestedItemToRawContent(item);
		}
	}
	return rawContent;
};

export const getSortedQuestionIdsFromContentList = (
	contentList: SortedContentList
): ObjectId[] => {
	const qIds: ObjectId[] = [];
	for (const content of contentList) {
		if (content.type === "single") {
			qIds.push(content.itemId);
		} else {
			qIds.push(
				...content.childItems
					.filter(e => e.itemType === ItemType.question)
					.map(e => e.itemId)
			);
		}
	}
	return qIds;
};

export const getNewSelection = (
	contentItem: SortedContentList[number],
	selectedContent: SelectedContent,
	content: Content
) => {
	let key: "questions" | "texts";
	if (contentItem.itemType === ItemType.question) {
		key = "questions";
	} else {
		key = "texts";
	}
	const newContent = {
		...selectedContent,
		questions: {
			...selectedContent.questions,
		},
		texts: {
			...selectedContent.texts,
		},
	};
	const isAlreadyChecked = !!selectedContent[key][contentItem.itemId];
	if (!isAlreadyChecked) {
		newContent[key][contentItem.itemId] = true;
	} else {
		delete newContent[key][contentItem.itemId];
	}
	if (key === "texts") {
		const text = content.texts[contentItem.itemId];
		if (text) {
			for (const questionId of text.questions) {
				if (!isAlreadyChecked) {
					newContent.questions[questionId] = true;
				} else {
					delete newContent.questions[questionId];
				}
			}
		}
	} else {
		const question = content.questions[contentItem.itemId];
		if (question && question.textId) {
			const textId = question.textId;
			const text = content.texts[textId];
			if (text) {
				if (isAlreadyChecked) {
					delete newContent.texts[textId];
				} else {
					const isEveryQuestionOfTextSelected = text.questions.every(
						e => newContent.questions[e]
					);
					if (isEveryQuestionOfTextSelected) {
						newContent.texts[textId] = true;
					}
				}
			}
		}
	}
	return newContent;
};

export const getInsertedContent = ({
	contentList,
	selectedContent,
	content,
	additionalContentListToTakeIntoConsideration,
}: {
	contentList: SortedContentList;
	selectedContent: SelectedContentArr;
	content: Content;
	additionalContentListToTakeIntoConsideration?: SortedContentList;
}): SortedContentList => {
	let indexToInsertQuestionsAt = 0;
	let hasAtLeastOneNested = false;
	for (let i = 0; i < contentList.length; ++i) {
		indexToInsertQuestionsAt = i;
		if (contentList[i].type === "nested") {
			hasAtLeastOneNested = true;
			break;
		}
	}
	if (!hasAtLeastOneNested) {
		indexToInsertQuestionsAt = contentList.length;
	}

	const alreadyChosenContent: Record<
		"questions" | "texts",
		Record<string, true | undefined>
	> = {
		questions: {},
		texts: {},
	};

	const items = additionalContentListToTakeIntoConsideration
		? contentList.concat(additionalContentListToTakeIntoConsideration)
		: contentList;
	for (const contentItem of items) {
		if (contentItem.type === "single") {
			if (contentItem.itemType === ItemType.question) {
				alreadyChosenContent.questions[contentItem.itemId] = true;
			}
		} else {
			if (contentItem.itemType === ItemType.text) {
				alreadyChosenContent.texts[contentItem.itemId] = true;
			}
			for (const childItem of contentItem.childItems) {
				if (childItem.itemType === ItemType.question) {
					alreadyChosenContent.questions[childItem.itemId] = true;
				}
			}
		}
	}

	const textIds = [...selectedContent.texts];

	const newQuestionsByTexts: Record<string, Set<string> | undefined> = {};
	const questionsToInsert = new Set<string>();
	for (const qId of selectedContent.questions) {
		if (alreadyChosenContent.questions[qId]) continue;
		const question = content.questions[qId];
		if (!question || !question.textId) {
			questionsToInsert.add(qId);
		} else {
			if (!newQuestionsByTexts[question.textId]) {
				if (textIds.indexOf(question.textId) === -1) {
					textIds.push(question.textId);
				}
				newQuestionsByTexts[question.textId] = new Set();
			}
			newQuestionsByTexts[question.textId]!.add(qId);
		}
	}

	const qIdstoContentItems = (qId: string): SortedContentSingleItem => ({
		type: "single",
		itemId: qId,
		itemType: ItemType.question,
	});

	const newContentList = contentList
		.slice(0, indexToInsertQuestionsAt)
		.concat([...questionsToInsert].map(qIdstoContentItems))
		.concat(contentList.slice(indexToInsertQuestionsAt));

	for (const textId of textIds) {
		const textItemIndex = contentList.findIndex(
			e => e.itemType === ItemType.text && e.itemId === textId
		);
		const questionsSet = newQuestionsByTexts[textId];
		if (!questionsSet || questionsSet.size === 0) continue;
		const questions = [...questionsSet];
		if (textItemIndex > -1) {
			(newContentList[textItemIndex] as SortedContentNestedItem) = {
				...(newContentList[textItemIndex] as SortedContentNestedItem),
				childItems: [
					...(newContentList[
						textItemIndex
					] as SortedContentNestedItem).childItems,
					...questions.map(qIdstoContentItems),
				],
			};
		} else {
			newContentList.push({
				childItems: questions.map(qIdstoContentItems),
				itemId: textId,
				itemType: ItemType.text,
				type: "nested",
			});
		}
	}

	return newContentList;
};

export const getReplacedContent = ({
	replaceableContent,
	contentList,
	selectedContent,
	content,
}: {
	replaceableContent: SortedContentList[number];
	contentList: SortedContentList;
	selectedContent: SelectedContentArr;
	content: Content;
}): SortedContentList => {
	const getRemovedContentList = getRemovedContent(
		contentList,
		replaceableContent
	);
	const maxSize = Math.max(getRemovedContentList.length, contentList.length);
	for (let i = 0; i < maxSize; ++i) {
		if (getRemovedContentList[i] === contentList[i]) continue;
		const replacedContentList = getRemovedContentList
			.slice(0, i)
			.concat(
				getInsertedContent({
					contentList: [],
					selectedContent,
					content,
					additionalContentListToTakeIntoConsideration: getRemovedContentList,
				})
			)
			.concat(getRemovedContentList.slice(i));
		return getInitialSortedContent(
			extractRawContentFromSortedContentList(replacedContentList, content)
		);
	}
	return contentList;
};
