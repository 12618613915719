import {
	ITestType,
	ITestTypeSettings,
} from "@app/api/test-types/helper-schemas";
import { IRTest, IUserTest } from "@app/api/tests/helper-schemas";
import { IRGETTestContents, IRGETUserTest } from "@app/api/tests/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useCallback, useEffect, useState } from "react";
import { FetchingDoc, useResourceInfoWithLoading } from "./fetch";

export const useTestById = (testId: ObjectId | null) => {
	const [test, setTest] = useState<IRTest>();
	useEffect(() => {
		if (!testId) {
			setTest(undefined);
			return;
		}
		const TestsController = inject("TestsController");
		TestsController.getById({ _id: testId }).then(setTest);
	}, [testId]);

	return test;
};

export const useTestContent = (
	testId: ObjectId,
	folderId: ObjectId,
	courseId: ObjectId
) => {
	const [testContent, setTestContent] = useState<{
		test: IRTest;
		testType: ITestType | undefined;
		testTypeSettings: ITestTypeSettings;
		content: IRGETTestContents;
	}>();
	useEffect(() => {
		const TestsController = inject("TestsController");
		TestsController.loadTest({
			courseId,
			folderId,
			loadContent: true,
			testId: testId,
			getAnswers: true,
		}).then(setTestContent);
	}, [courseId, folderId, testId]);
	return testContent;
};

export const useTestsByCourseId = (courseId: ObjectId | null) => {
	const [tests, setTests] = useState<IRTest[]>();
	useEffect(() => {
		if (!courseId) {
			setTests(undefined);
			return;
		}
		const TestsController = inject("TestsController");
		TestsController.getAll().then(setTests);
	}, [courseId]);

	return tests;
};

export const useUserTest = (
	testId: ObjectId | null,
	folderId: ObjectId | "none" | null,
	courseId: ObjectId | null
) => {
	const [userTest, setUserTest] = useState<FetchingDoc<IUserTest> | null>(
		null
	);

	useEffect(() => {
		if (!testId || !folderId || !courseId) return;
		const TestsController = inject("TestsController");
		TestsController.getUserTest({
			courseId,
			folderId: folderId === "none" ? null : folderId,
			testId,
			attempt: 1,
		}).then(userTest => {
			if (userTest) {
				setUserTest({
					hasFound: true,
					doc: userTest,
				});
			} else {
				setUserTest({
					hasFound: false,
				});
			}
		});
	}, [folderId, courseId, testId]);

	return userTest;
};

export type UserTestFetchInfo = FetchingDoc<IRGETUserTest, "test">;

export const useStudentTestByUserTestId = (userTestId: ObjectId | null) => {
	const [studentTest, setStudentTest] = useState<UserTestFetchInfo>();

	const getStudentTest = useCallback((userTestId: ObjectId):
		| undefined
		| Promise<UserTestFetchInfo> => {
		if (!userTestId) return;
		const TestsController = inject("TestsController");
		return TestsController.getUserTestById({ _id: userTestId })
			.then(data => {
				if (data) {
					return { hasFound: true as true, test: data };
				}
				return { hasFound: false as false };
			})
			.catch(e => {
				return { hasFound: false as false };
			})
			.then(state => {
				setStudentTest(state);
				return state;
			});
	}, []);

	return useResourceInfoWithLoading<
		UserTestFetchInfo,
		{},
		Parameters<typeof getStudentTest>[0],
		any
	>({
		resource: studentTest,
		fetchingArg: userTestId!,
		fetch: getStudentTest,
		isIdentificationKnown: !!userTestId,
	});
};
