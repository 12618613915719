import { ISubject } from "./helper-schemas";
import {
	IRPOSTCreateSubject,
	IAPOSTCreateSubject,
	RPOSTCreateSubjectSchema,
	IAGETSubjects,
	IAPUTCreateSubject,
	RPUTCreateSubjectSchema,
	IRPUTCreateSubject,
	IAGETManySubjectsByIds,
	RGETManySubjectsByIdsSchema,
	IRGETManySubjectsByIds,
} from "./validators";
import { IRequest } from "../requests";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { Subject } from "@app/models/subject";
import { PromisesKeeperAPI, getManyResources } from "../promises-keeper";

export class SubjectsController {
	private readonly Request: IRequest;

	private readonly _SubjectModel = inject("SubjectModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	private subjectPromises = new PromisesKeeperAPI<ObjectId, Subject>(
		getManyResources(
			docs => this.getManyByIds(docs.map(e => e.id)),
			(data, id) => data.find(e => e._id === id)
		),
		15
	);

	add = (args: IAPOSTCreateSubject): Promise<Subject> =>
		this.Request.send("POST", "/api/subjects", args, null, {
			responseSchema: RPOSTCreateSubjectSchema,
		}).then((data: IRPOSTCreateSubject) => {
			return this._SubjectModel.loadOneSync(data);
		});

	update = (args: IAPUTCreateSubject): Promise<Subject> =>
		this.Request.send("PUT", "/api/subjects", args, null, {
			responseSchema: RPUTCreateSubjectSchema,
		}).then((data: IRPUTCreateSubject) => {
			return this._SubjectModel.loadOneSync(data);
		});

	getManyByIds = async (
		ids: IAGETManySubjectsByIds,
		loadFresh = false
	): Promise<Subject[]> => {
		if (ids.length === 0) return [];
		if (ids.length === 1) {
			return this.getById({ _id: ids[0] }).then(subject => [subject]);
		}
		if (!loadFresh) {
			const subjects = this._SubjectModel.findManyByIdsSync(ids);
			if (subjects.length === ids.length) return subjects;
		}
		return this.Request.send("POST", "/api/subjects/by-ids", ids, null, {
			responseSchema: RGETManySubjectsByIdsSchema,
		}).then((data: IRGETManySubjectsByIds) => {
			return this._SubjectModel.loadManySync(data, ids);
		});
	};

	getById = async (
		args: { _id: ObjectId },
		loadFresh = false
	): Promise<Subject> => {
		if (!loadFresh) {
			const subject = this._SubjectModel.findByIdSync(args._id);
			if (subject) return subject;
		}
		return this.subjectPromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/subjects/:_id", args).then(
				(data: ISubject) => {
					return this._SubjectModel.loadOneSync(data);
				}
			)
		);
	};

	getAll = async (
		query: IAGETSubjects,
		loadFresh = false
	): Promise<Subject[]> => {
		if (!loadFresh && this._SubjectModel.meta.data.lastFullLoadTime) {
			return this._SubjectModel.searchSync(query);
		}
		return this.Request.send("GET", "/api/subjects", query).then(
			(data: ISubject[]) => {
				if (Object.keys(query).length === 0) {
					this._SubjectModel.meta.updateLoadTime();
				}
				return this._SubjectModel.loadManySync(data);
			}
		);
	};

	deleteById = (args: { _id: ObjectId }): Promise<void> =>
		this.Request.send("DELETE", "/api/subjects/:_id", args).then(() => {
			this._SubjectModel.deleteByIdSync(args._id);
		});
}
