import { UserType } from "@app/api/helper-schemas";
import { IRGETMyInfoAsStudent } from "@app/api/users/validators";
import { inject } from "@app/modules";
import { useEffect, useState } from "react";
import { useClassroomsUserId } from "./users";

export const useMyInfoAsStudent = () => {
	const classroomUserId = useClassroomsUserId();
	const [studentInfo, setStudentInfo] = useState<IRGETMyInfoAsStudent | null>(
		null
	);

	useEffect(() => {
		if (!classroomUserId) return;
		const UsersController = inject("UsersController");
		UsersController.getMyInfo(UserType.student);
		return UsersController.myInfoSubscription.subscribe<
			IRGETMyInfoAsStudent
		>(setStudentInfo);
	}, [classroomUserId]);

	return studentInfo;
};
