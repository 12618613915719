/* eslint-disable max-lines-per-function */
import React, { useMemo, useState, useRef, useEffect } from "react";
import { UserType } from "@app/api/helper-schemas";
import { match, useLocation } from "react-router-dom";
import { TeacherSideBarWrapper } from "@app/components/teachers/sidebar";
import { History } from "history";
import { StudentSideBarWrapper } from "@app/components/users/sidebar";
import { ISideBarProps } from "./helpers/interfaces";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import styles from "./styles/index.module.scss";
import { useWindowSize } from "@app/hooks/front";
import { SidebarUpIcon } from "@app/icons";
import { useSetIsSidebarOpen } from "@app/components/general-context";
import { HeadmasterSideBarWrapper } from "@app/components/headmaster/sidebar";

export const SideBarDistributor = React.memo<{
	userType: UserType;
	history: History;
	match: match<any>;
}>(({ userType, history, match }) => {
	return useMemo(() => {
		const props = { history, match };
		switch (userType) {
			case UserType.teacher:
				return <TeacherSideBarWrapper {...props} />;
			case UserType.student:
				return <StudentSideBarWrapper {...props} />;
			case UserType.headmaster:
				return <HeadmasterSideBarWrapper {...props} />;
			default:
				return null;
		}
	}, [history, match, userType]);
});

export const SideBar = React.memo<ISideBarProps>(
	({ children, items, history }) => {
		const { width } = useWindowSize();
		const path = useLocation();
		const [isExpanded, onSetExpanded] = useState(true);

		useSetIsSidebarOpen(isExpanded);

		const expandedArrowWidth = width > 1400 ? 400 : 320;
		const autoCloseSidebar = useRef(true);

		useEffect(() => {
			if (width < 600 && autoCloseSidebar.current) {
				onSetExpanded(false);
				autoCloseSidebar.current = false;
			}
		}, [width]);

		return (
			<div
				className={classnames(
					styles["sidebar-placeholder"],
					!isExpanded && styles["collapsed"],
					isExpanded && styles["expanded"]
				)}
			>
				<Helmet>
					<style type="text/css">
						{`
						.user-page-without-header { display: flex; flex-direction: row; }
						.user-page-without-header>*:last-child { flex: 1; }
					    `}
					</style>
				</Helmet>
				<div
					className={styles["teacher-sidebar-container"]}
					id={styles["sidebar"]}
					style={!isExpanded ? { left: -expandedArrowWidth } : {}}
				>
					<div
						className={styles["expand-less"]}
						onClick={() => onSetExpanded(cur => !cur)}
						style={
							!isExpanded
								? { position: "absolute", right: -114 }
								: {}
						}
					>
						<span style={{ paddingRight: 15 }}>
							{isExpanded ? "ჩაკეცვა" : "გახსნა"}
						</span>
						<SidebarUpIcon className={styles["arrow"]} />
					</div>

					<div style={{ height: 30 }} />

					{items.map((item, index) => (
						<div
							key={index}
							onClick={() => history.push(item.href)}
							className={styles["sidebar-item"]}
							style={
								item.href === path.pathname
									? { color: "#5273e6", paddingRight: "50px" }
									: undefined
							}
						>
							{item.name}
						</div>
					))}
					{children}
				</div>

				<div
					className={classnames(
						styles.expanded_sideBar,
						!isExpanded && styles.open_expanded_sidebar
					)}
				>
					{items.map((item, index) =>
						item.iconRendererForCollapsedView ? (
							<div
								key={index}
								className={
									styles.expanded_sideBar__icon_container
								}
								onClick={() => history.push(item.href)}
							>
								{item.iconRendererForCollapsedView(
									styles.expanded_sideBar__icon
								)}
							</div>
						) : null
					)}
				</div>
			</div>
		);
	}
);
