import classNames from "classnames";
import React, { useCallback, useContext, useEffect } from "react";
import "react-day-picker/lib/style.css";
import { HomeworkContext } from ".";
import styles from "../styles/styles.module.css";
import { ReactComponent as SendIcon } from "../styles/imgs/send.svg";
import { QuestionsSection } from "./second-step-components/questions-sections";
import { Deadline } from "./second-step-components/deadline";
import { ClassroomGroup } from "./second-step-components/classrooms";
import { ObjectId } from "@app/utils/generics";
import { useBoolean } from "@app/hooks/general";
import { Button, IButtonProps } from "@app/components/auth/input-forms";
import { getFormattedMessage } from "@app/utils/locale";

interface IOwnProps {
	onPreviousPage: () => void;
	onHomeworkSubmit: () => void;
	isSecondStepSubmitted: boolean;
	classroomId: ObjectId;
	courseId: ObjectId;
}

export const CreatingAssignmentSecondStep = (props: IOwnProps) => {
	const [homework, dispatch] = useContext(HomeworkContext);
	const { value: isLoading, setValue: setIsLoading } = useBoolean(false);

	const onDateChange = useCallback(
		(date: Date) => {
			dispatch({
				type: "CHANGE_HW_DEADLINE",
				date: date,
			});
		},
		[dispatch]
	);
	useEffect(() => {
		if (props.isSecondStepSubmitted) {
			setIsLoading(true);
		}
	}, [props.isSecondStepSubmitted, setIsLoading]);

	return (
		<div>
			<div className={styles.topParentContainer}>
				<div
					className={classNames(
						styles.container,
						styles.secondPage,
						styles.deadlineAndGroupsContainer
					)}
				>
					<Deadline
						date={homework.settings.deadline}
						onDateChange={onDateChange}
						classroomId={props.classroomId}
					/>
					<ClassroomGroup classroomId={props.classroomId} />
				</div>
			</div>
			<QuestionsSection
				onPreviousPage={props.onPreviousPage}
				isCreating={true}
				classroomId={props.classroomId}
				courseId={props.courseId}
			/>
			<div className={styles.buttonSendContainer}>
				<Button
					loaderClassName={styles.loaderSize}
					onClick={props.onHomeworkSubmit}
					isLoading={isLoading}
					className={classNames(
						styles.buttonStyles,
						styles.sendButton
					)}
					text={getFormattedMessage("teacher:assignment.send")}
				>
					<SendIcon className={styles.buttonSendIcon} />
				</Button>
			</div>
		</div>
	);
};

export const BigActionButton: React.FC<IButtonProps> = props => {
	return (
		<Button
			loaderClassName={styles.loaderSize}
			className={props.className || styles.buttonStyles}
			{...props}
		/>
	);
};
