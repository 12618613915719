import { NewDesignBackground } from "@app/components/backgrounds";
import { GeneralContext } from "@app/components/general-context";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { ReactComponent as TVSchoolNL } from "@app/components/tv-school-landing/styles/imgs/tv-school-nl.svg";
import { ReactComponent as TVSchoolEn } from "@app/components/tv-school-landing/styles/imgs/tv-school-en.svg";
import { ReactComponent as LupiLogo } from "@app/components/landing/styles/img/lupi-ai.svg";
import { useSeeingSpecialCourse } from "@app/hooks/special-courses";
import { useStudentInfo, useClassroomsUser } from "@app/hooks/users";
import { ObjectId, IAnyObj } from "@app/utils/generics";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory, useWindowSize } from "../../../hooks/front";
import { getLastCourseId } from "../last-course-saver";
import { HeaderOptions } from "./options";
import HeaderStyles from "./styles/header.module.css";
import { FormattedMessage } from "react-intl";
import { inject } from "@app/modules";
import { useLocale } from "@app/hooks/intl";
import classNames from "classnames";
import LogOutComponent from "@app/components/widgets/LogOutComponent";

export const StudentTransparentHeader: React.FC<IAnyObj> = props => {
	const history = useHistory();
	const { width } = useWindowSize();
	const isMobile = width < 600;
	const locale = useLocale();
	const isGeo = locale === "ka";
	const isNl = locale === "nl";
	const isEn = locale === "en";
	const user = useClassroomsUser()!;

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const courseId = props.courseId || getLastCourseId();

	useSeeingSpecialCourse(courseId);

	const studentInfo = useStudentInfo();
	const courseIds = studentInfo ? studentInfo.courseIds : undefined;
	useEffect(() => {
		if (!courseIds || !courseId) return;
		if (courseIds.indexOf(courseId) === -1) {
			history.push("");
		}
	}, [history, courseId, courseIds]);
	const onGoToProfile = useCallback(() => history.push(`/profile`), [
		history,
	]);

	const gotoMainPage = useCallback(() => {
		history.push(`/`);
	}, [history]);

	if (isHeaderHidden) {
		return null;
	}

	return (
		<>
			<NewDesignBackground />
			<div
				className={HeaderStyles.container}
				style={{
					padding: "10px 30px",
					backgroundColor:
						props.backgroundColor || "rgba(239, 241, 247)",
				}}
			>
				<div className={HeaderStyles.coursesAndLogoContainer}>
					<div className={HeaderStyles.logo} onClick={gotoMainPage}>
						{isGeo ? (
							<TVSchoolLogo
								style={{
									height: "100%",
									width: 112,
									color: "#5273e6",
								}}
							/>
						) : isNl ? (
							<TVSchoolNL
								style={{ height: "100%", width: 112 }}
							/>
						) : (
							<LupiLogo style={{ height: "100%", width: 112 }} />
						)}
					</div>
				</div>

				<div className={HeaderStyles.faqAndOptionsContainer}>
					<div className={HeaderStyles.teacherInfo_wrapper}>
						<div
							style={{
								display: "inline-block",
								fontFamily: "Roboto Geo Caps",
								verticalAlign: "middle",
								textAlign: "right",
								color: "#626a84",
							}}
						>
							<span style={{ fontSize: 12 }}>მოსწავლე</span>
							<br />
							<span style={{ fontSize: 15, fontWeight: "bold" }}>
								{user.firstname + " " + user.lastname}
							</span>
						</div>

						<div
							className={
								width > 1024
									? HeaderStyles.teacherUserICon
									: classNames(HeaderStyles.teacherUserICon)
							}
							onClick={width > 1200 ? onGoToProfile : undefined}
							style={{
								backgroundColor: "white",
								width: 50,
								height: 50,
								display: "inline-block",
								verticalAlign: "middle",
							}}
						>
							<img
								src="/imgs/StudentAvatar.svg"
								alt=""
								className={HeaderStyles.iconImg}
							/>
						</div>
						<LogOutComponent />
					</div>
				</div>
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};
