import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { useClassroomInvitations } from "@app/hooks/classroom-join-requests";
import { useBoolean } from "@app/hooks/general";
import React, { useEffect, useCallback } from "react";
import styles from "./styles/index.module.css";
import { IncomingInvitationOfTeacher } from "./incoming";
import { FormattedMessage } from "react-intl";

export const TeachersInvitations: React.FC<{
	classrooms: IClassroom[];
}> = props => {
	const invitations = useClassroomInvitations({
		excludeUserAsRequester: true,
	});
	const { value: managedByHand, setTrue: manageByHand } = useBoolean(
		!!invitations && invitations.length > 0
	);
	const {
		value: areInvitationsListVisible,
		switchValue: switchInvitationsVisibility,
		setTrue: showInvitationsList,
	} = useBoolean(!!invitations && invitations.length > 0);

	const switchedByHand = useCallback(() => {
		switchInvitationsVisibility();
		manageByHand();
	}, [switchInvitationsVisibility, manageByHand]);

	useEffect(() => {
		if (!invitations || managedByHand) return;
		if (invitations.length > 0) {
			showInvitationsList();
		}
	}, [invitations, managedByHand, showInvitationsList]);

	if (!invitations || invitations.length === 0) return null;

	return (
		<div>
			<div onClick={switchedByHand} className={styles.invitationSentence}>
				<FormattedMessage id="teacher:invitations.firstWord" />{" "}
				{invitations.length}{" "}
				<FormattedMessage id="teacher:invitations.secondWord" />
			</div>
			<div>
				{areInvitationsListVisible &&
					invitations.map(e => (
						<IncomingInvitationOfTeacher
							key={e._id}
							info={e}
							classrooms={props.classrooms}
						/>
					))}
			</div>
		</div>
	);
};
