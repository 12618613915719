import EditIcon from "@material-ui/icons/Create";
import classNames from "classnames";
import React, { useRef, useCallback } from "react";
import styles from "../../assignments/styles/styles.module.css";
import { getFormattedMessage } from "@app/utils/locale";

interface IClassroomNameProps {
	onChange: (e: any) => void;
	value: string;
	classroomNameContainer?: string;
	inputStyles?: string;
}

export const ClassroomName: React.FC<IClassroomNameProps> = React.memo(
	props => {
		const inputRef = useRef<HTMLInputElement>(null);

		const focusMethod = useCallback(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, []);

		return (
			<div
				className={
					props.classroomNameContainer
						? props.classroomNameContainer
						: classNames(styles.inputContainer2, styles.edit)
				}
			>
				<input
					ref={inputRef}
					className={
						props.inputStyles
							? props.inputStyles
							: styles.inputStyle2
					}
					style={{ flex: 1, textAlign: "center" }}
					value={props.value}
					onChange={props.onChange}
					placeholder={getFormattedMessage(
						"teacher:enterClassroomName"
					)}
				></input>
				<EditIcon
					className={styles.createStyle}
					onClick={focusMethod}
				/>
			</div>
		);
	}
);
