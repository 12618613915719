import { GeneralContext } from "@app/components/general-context";
import Popup from "@app/components/widgets/popup";
import {
	useSeeingSpecialCourse,
	useUnseenSpecialCourses,
} from "@app/hooks/special-courses";
import { useStudentInfo } from "@app/hooks/users";
import { ObjectId } from "@app/utils/generics";
import React, { useContext, useEffect } from "react";
import { useHistory, useWindowSize } from "../../../hooks/front";
import { useBoolean } from "../../../hooks/general";
import { ReactComponent as NewIcon } from "../../styles/img/new.svg";
import { getLastCourseId } from "../last-course-saver";
import { LogoAndCourses } from "./courses";
import { HeaderOptions } from "./options";
import { ResponsiveHeader } from "./responsive";
import HeaderStyles from "./styles/header.module.css";
import { ReactComponent as BurgerIcon } from "./styles/img/burger-icon.svg";

const responsiveWidth = 1200;

export const Header: React.FC<{
	isPrimariOptionsInvisible?: boolean;
	courseId?: ObjectId;
}> = props => {
	const history = useHistory();
	const { width } = useWindowSize();

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const {
		value: isResponsiveOptionsVisible,
		setTrue: showResponsiveOptions,
		setFalse: hideResponsiveOptions,
	} = useBoolean();

	const courseId = props.courseId || getLastCourseId();

	useSeeingSpecialCourse(courseId);

	const studentInfo = useStudentInfo();
	const courseIds = studentInfo ? studentInfo.courseIds : undefined;
	useEffect(() => {
		if (!courseIds || !courseId) return;
		if (courseIds.indexOf(courseId) === -1) {
			history.push("");
		}
	}, [history, courseId, courseIds]);

	if (isHeaderHidden) {
		return null;
	}

	return (
		<>
			<div className={HeaderStyles.container}>
				<LogoAndCourses
					courseId={props.courseId || null}
					width={width}
				/>

				{width > responsiveWidth && (
					<>
						<HeaderOptions
							courseId={courseId}
							isPrimariOptionsInvisible={
								props.isPrimariOptionsInvisible
							}
						/>
					</>
				)}

				{width <= responsiveWidth && (
					<MenuIcon onClick={showResponsiveOptions} />
				)}
				{isResponsiveOptionsVisible && width <= responsiveWidth && (
					<Popup onClose={hideResponsiveOptions}>
						<ResponsiveHeader
							courseId={courseId}
							onClose={hideResponsiveOptions}
						/>
					</Popup>
				)}
			</div>
			<div className={HeaderStyles.containerPlaceholder} />
		</>
	);
};

const MenuIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	const unseenSpecialCourses = useUnseenSpecialCourses();
	return (
		<button className={HeaderStyles.burgerButton} onClick={onClick}>
			{unseenSpecialCourses && unseenSpecialCourses.length > 0 && (
				<div
					style={{
						color: "white",
						width: 33,
						position: "absolute",
						marginLeft: -19,
						marginTop: 13,
					}}
				>
					<NewIcon />
				</div>
			)}
			<BurgerIcon className={HeaderStyles.burgerIcon} />
		</button>
	);
};
