import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./styles/classrooms.module.css";
import { ReactComponent as Clock } from "./styles/img/clock.svg";
import { ReactComponent as User } from "./styles/img/user.svg";

export const ClassroomBoxLoader: React.FC<{}> = () => {
	return (
		<div className={styles.classroomBox}>
			<div style={{ display: "flex" }}>
				<div className={styles.classroomName}>
					<ContentLoader
						height={21}
						style={{ width: 200, height: 21 }}
						width={200}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="200"
							height="21"
						/>
					</ContentLoader>
				</div>
				<div className={styles.settingsIcon}>
					<ContentLoader
						style={{ width: 20, height: 20 }}
						width={20}
						height={20}
					>
						<circle
							cx={10}
							cy={10}
							r={10}
							stroke={"transparent"}
							strokeWidth={0}
							fill="white"
						/>
					</ContentLoader>
				</div>
			</div>

			<div className={styles.lists}>
				<div className={styles.classroomTimes}>
					<div className={styles.listTextandIcon}>
						<Clock style={{ width: 20, height: 20 }} />
						<p className={styles.listText}>
							<ContentLoader
								height={21}
								style={{ width: 100, height: 21 }}
								width={100}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="5"
									width="100"
									height="21"
								/>
							</ContentLoader>
						</p>
					</div>
					{new Array(3).fill(0).map((e, i) => {
						return (
							<div
								className={styles.classroomTimeListLine}
								key={i}
							>
								<p className={styles.classTimeDay}>
									<ContentLoader
										height={21}
										style={{ width: 130, height: 21 }}
										width={130}
									>
										<rect
											x="0"
											y="0"
											rx="5"
											ry="5"
											width="130"
											height="21"
										/>
									</ContentLoader>
								</p>
							</div>
						);
					})}
				</div>
				<div className={styles.ClassroomStudents}>
					<div className={styles.listTextandIcon}>
						<User style={{ width: 20, height: 20 }} />
						<p className={styles.listText}>
							<ContentLoader
								height={21}
								style={{ width: 100, height: 21 }}
								width={100}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="5"
									width="100"
									height="21"
								/>
							</ContentLoader>
						</p>
					</div>
					{new Array(3).fill(0).map((e, i) => {
						return (
							<div className={styles.classroomUserLine} key={i}>
								<ContentLoader
									height={21}
									style={{ width: 130, height: 21 }}
									width={130}
								>
									<rect
										x="0"
										y="0"
										rx="5"
										ry="5"
										width="130"
										height="21"
									/>
								</ContentLoader>
							</div>
						);
					})}
				</div>
			</div>
			<div className={styles.classroomButtonContainer}>
				<div>
					<ContentLoader
						height={38}
						style={{ width: 117, height: 38 }}
						width={117}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="117"
							height="38"
						/>
					</ContentLoader>
				</div>
			</div>
		</div>
	);
};
