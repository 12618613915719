import React from "react";

export const playground1Id = "classrooms_app_playground1";

export const PlayGround = React.memo(() => {
	return (
		<div>
			<div id={playground1Id} />
			<></>
		</div>
	);
});
