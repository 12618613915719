import { SubjectSchema, ISubject } from "@app/api/subjects/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { isSuitable } from "./utils/match";
import { getMaxPriority } from "./utils/priorities";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ISubject;
export type IStateSubjects = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(SubjectSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("subject");
const storageSettings = getDefaultStorageSettings("subjects");
const metaInformationName = "subjectsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().subjects) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", SubjectSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Subject extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static searchSync(query: SubjectAvailabilityQuery, sort = true): Subject[] {
		const docs = this.getAllSync();
		const filteredDocs = docs.filter(doc => {
			const matchingAvailabilities = doc.getMatchingAvailabilities(query);
			return !matchingAvailabilities || matchingAvailabilities.length > 0;
		});

		if (!sort) {
			return filteredDocs;
		}
		return filteredDocs
			.map(subject => {
				return {
					subject,
					matchingAvailabilities: subject.getMatchingAvailabilities(
						query
					),
				};
			})
			.sort(
				(a, b) =>
					getMaxPriority(b.matchingAvailabilities || null) -
					getMaxPriority(a.matchingAvailabilities || null)
			)
			.map(e => e.subject);
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);

	getMatchingAvailabilities(query: SubjectAvailabilityQuery | null) {
		if (!query) return this.availableIn;
		if (!this.availableIn) return null;
		return this.availableIn.filter(availability => {
			if (!isSuitable(query.grade, availability.grades)) {
				return false;
			}
			if (!isSuitable(query.language, availability.languages)) {
				return false;
			}
			if (!isSuitable(query.country, availability.countries)) {
				return false;
			}
			if (!isSuitable(query.website, availability.websites)) {
				return false;
			}
			return true;
		});
	}
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Subject.meta);

export const subjectsReducer = getDefaultReducer(
	storageSettings,
	() => Subject
);

///

export interface SubjectAvailabilityQuery {
	grade?: number | number[] | null;
	language?: string | string[] | null;
	country?: string | string[] | null;
	website?: string | string[] | null;
}
