import * as React from "react";
import { connect } from "react-redux";
import { History, Path } from "history";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";
import { IRootState } from "@app/redux";
import { UserType } from "@app/api/helper-schemas";
import { WebsiteOrigin, getCurrentWebsite } from "@app/globals";
import { removeKeys } from "@app/utils/common";
import { HeaderHider } from "./common";
import { SideBarDistributor } from "@app/components/layouts/sidebar";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	availableUserTypes?: UserType | UserType[];
	availableWebsite?: WebsiteOrigin | WebsiteOrigin[];
	redirectIfNotAuthorizedUserType?: boolean;
	dontRedirectIfNotAuthenticated?: boolean;
	hideHeader?: boolean; // TODO: remove this property
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const UserRoute = (props: IProps) => {
	return (
		<Route {...removeKeys(props as any, "component", "children")}>
			<RouteRendered {...props} />
		</Route>
	);
};

const RouteRendered = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	userType,
	availableUserTypes,
	availableWebsite,
	redirectIfNotAuthorizedUserType,
	dontRedirectIfNotAuthenticated,
	hideHeader,
	...rest
}: IProps) => {
	const history = useHistory();
	const match = useRouteMatch();
	if (
		availableWebsite !== undefined &&
		!areWebsitesMatched(availableWebsite)
	) {
		return null;
	}

	if (
		(!isAuthenticated || userType === null || !user.userData) &&
		!dontRedirectIfNotAuthenticated
	) {
		return (
			<Redirect to={`/login?redirect=${encodeURI(location.pathname)}`} />
		);
	}
	if (availableUserTypes) {
		if (
			(Array.isArray(availableUserTypes) &&
				availableUserTypes.indexOf(userType!) === -1) ||
			(!Array.isArray(availableUserTypes) &&
				availableUserTypes !== userType)
		) {
			if (!redirectIfNotAuthorizedUserType) {
				return null;
			}
			return <Redirect to="/" />;
		}
	}
	if (userType === null) {
		return null;
	}

	return (
		<div>
			<HeaderHider hideHeader={hideHeader} />
			<div className="user-page">
				<div
					className={
						"user-page-without-header " +
						(userType === UserType.teacher &&
						getCurrentWebsite() === WebsiteOrigin.tvSchool
							? " tvschool-teacher-page"
							: "")
					}
				>
					<SideBarDistributor
						history={history}
						match={match}
						userType={userType!}
					/>

					<div>
						<Component
							userType={userType}
							{...rest}
							history={history}
							location={history.location}
							match={match}
							{...match.params}
							userData={user.userData}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const areWebsitesMatched = (
	availableWebsite: WebsiteOrigin | WebsiteOrigin[]
) => {
	const currentWebsite = getCurrentWebsite();
	if (
		Array.isArray(availableWebsite) &&
		availableWebsite.indexOf(currentWebsite) === -1
	) {
		return false;
	} else if (
		!Array.isArray(availableWebsite) &&
		availableWebsite !== currentWebsite
	) {
		return false;
	}
	return true;
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
	userType: state.user.userData?.permissions?.userType || null,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserRoute
);
