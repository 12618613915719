import React, { useMemo } from "react";
import styles from "../../styles/index.module.css";

export enum HeadmasterGroupBoxInfoType {
	mentor = "mentor",
	students = "students",
}

interface GroupInfoContainerProps {
	info:
		| { type: HeadmasterGroupBoxInfoType.mentor; name: string }
		| { type: HeadmasterGroupBoxInfoType.students; quantity: number };
}

export const HeadmasterGroupInfoContainer: React.FC<GroupInfoContainerProps> = ({
	info,
}) => {
	const details = useMemo(() => {
		if (info.type === HeadmasterGroupBoxInfoType.mentor) {
			return info.name;
		}
		if (info.type === HeadmasterGroupBoxInfoType.students) {
			return `${info.quantity} მოსწავლე`;
		}
		return null;
	}, [info]);

	const avatarSrc = useMemo(() => {
		if (info.type === HeadmasterGroupBoxInfoType.mentor) {
			return "/imgs/TeacherAvatar.svg";
		}
		return "/imgs/StudentAvatar.svg";
	}, [info.type]);

	return (
		<div className={styles.groupInfoContainer}>
			<div className={styles.avatarContainer}>
				<img className={styles.avatar} src={avatarSrc} alt="avatar" />
			</div>
			<div className={styles.details}>{details}</div>
		</div>
	);
};
