import React, { useCallback, useState, useEffect } from "react";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { useBoolean } from "@app/hooks/general";
import styles from "./styles/index.module.scss";
import { useWindowSize } from "@app/hooks/front";
import { IRGETProposal } from "@app/api/proposals/validators";
import { inject } from "@app/modules";

const TeacherProposalPopup = () => {
	const { value: isOpen, setFalse: closePopup } = useBoolean(true);
	const [selected, setSelected] = useState<string[]>([]);
	const [proposal, setProposal] = useState<IRGETProposal | null>(null);
	const { width } = useWindowSize();

	useEffect(() => {
		const loadData = async () => {
			const actions = (
				await inject("UserActionsController").getUserAction()
			).actions;

			if (actions.teacherProposal || actions.hasSeenTeacherProposal) {
				return;
			}
			await inject("ProposalsController")
				.getProposal()
				.catch(e => console.log(e))
				.then(e => setProposal(e as any));
		};
		loadData();
	}, []);

	const handleClose = useCallback(() => {
		inject("UserActionsController").performUserAction({
			data: true,
			name: "hasSeenTeacherProposal",
		});
		closePopup();
	}, [closePopup]);

	const isChecked = useCallback(
		(topicName: string) => {
			return selected.find(e => e === topicName) ? true : false;
		},
		[selected]
	);

	const handleTopicClick = useCallback(
		(topicName: string) => {
			if (isChecked(topicName)) {
				setSelected(x => x.filter(e => e !== topicName));
			} else {
				setSelected([...selected, topicName]);
			}
		},
		[isChecked, selected]
	);

	const handleSend = useCallback(() => {
		inject("UserActionsController").performUserAction({
			data: {
				chosenTopics: selected,
				comment: "",
			},
			name: "teacherProposal",
		});
		closePopup();
	}, [closePopup, selected]);

	const smallerFont = width < 1000;

	if (
		!proposal ||
		!proposal.hasProposal ||
		!proposal.proposal.proposedTopics
	) {
		return null;
	}

	return isOpen && proposal && proposal.hasProposal ? (
		<Popup onClose={() => handleClose()}>
			<PopupContentWithClose onClose={() => handleClose()}>
				<div
					className={styles.proposalContainer}
					style={{ width: `${(width * 85) / 100}px` }}
				>
					<div
						className={styles.heading}
						style={{ fontSize: smallerFont ? "16px" : undefined }}
					>
						წერილი Tvschool.ge-ის ადმინისტრაციისგან!
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexFlow: "wrap",
							margin: "30px 0px 60px 0px",
						}}
					>
						<p
							className={styles.proposalText}
							style={{
								fontSize: smallerFont ? "14px" : undefined,
							}}
						>
							Tvschool.ge-ს ადმინისტრაცია მადლობას გიხდით, რომ
							დისტანციურ სასწავლო პროცესს წარმატებით წარმართავთ.
						</p>
						<p
							className={styles.proposalText}
							style={{
								fontSize: smallerFont ? "14px" : undefined,
							}}
						>
							ჩვენმა გუნდმა უმოკლეს დროში შექმნა ძალიან ეფექტიანი
							პლატფორმა, რომელსაც დღეს 14 000-მდე მასწავლებელი
							იყენებს. დღეს თქვენი მხარდაჭერა მნიშვნელოვანია.
							24/7-ზე მუშაობის მიუხედავად, ტესტების შექმნა იმ
							ფონზე, რომ სახელმძღვანელოები სხვადასხვაა, ძალიან დიდ
							სირთულეებთან არის დაკავშირებული. მოგმართავთ თხოვნით
							თქვენი წვლილი შეიტანოთ პლატფორმის განვითარებაში.
							ყველა პედაგოგი, რომელიც აქტუალურ დავალებებზე
							მოგვაწვდის ტესტებს დაჯილდოვდება tvschool.ge - ს
							მადლობის სერტიფიკატით!
						</p>
						<p
							className={styles.proposalText}
							style={{
								fontSize: smallerFont ? "14px" : undefined,
							}}
						>
							გთხოვთ, აირჩიოთ თემა, რომელზეც შეგიძლიათ ტესტების
							მოწოდება. თემის არჩევის შემდეგ 24 საათის
							განმავლობაში დაგიკავშირდებათ ჩვენი წარმომადგენელი და
							მოგაწვდით დეტალურ ინფორმაციას!
						</p>
						<p
							className={styles.proposalText}
							style={{
								fontSize: smallerFont ? "14px" : undefined,
							}}
						>
							გმადლობთ თანამშრომლობისთვის!
						</p>
					</div>
					<div className={styles.choose}>აირჩიეთ:</div>
					<div className={styles.topicsContainer}>
						{proposal.proposal.proposedTopics.map((e, i) => (
							<div
								key={i}
								className={styles.topic}
								style={{
									margin: smallerFont
										? "12px 0px 12px 0px"
										: undefined,
								}}
								onClick={() => handleTopicClick(e)}
							>
								<div
									className={styles.checkRect}
									style={{
										backgroundColor: isChecked(e)
											? "#5273e6"
											: undefined,
										width: smallerFont ? "20px" : undefined,
										height: smallerFont
											? "20px"
											: undefined,
										borderRadius: smallerFont
											? "5px"
											: undefined,
										margin: smallerFont
											? "0px 18px 0px 18px"
											: undefined,
									}}
								></div>
								<div
									className={styles.topicName}
									style={{
										fontSize: smallerFont
											? "14px"
											: undefined,
									}}
								>
									{e}
								</div>
							</div>
						))}
						<div
							className={styles.sendButton}
							style={{
								width: smallerFont ? "200px" : undefined,
								height: smallerFont ? "40px" : undefined,
								fontSize: smallerFont ? "16px" : undefined,
							}}
							onClick={() => handleSend()}
						>
							გაგზავნა
						</div>
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	) : null;
};

export default TeacherProposalPopup;
