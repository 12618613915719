import * as React from "react";
import CancelIcon from "@material-ui/icons/CancelRounded";
import {
	Cancel,
	PopupBody,
	PopupCSS,
	PopupHeader,
	IconContainer,
} from "./styles/styles";
import "./styles/popup.css";
import * as classNames from "classnames";
import Portal from "@material-ui/core/Portal";

interface IPopupProps {
	onClose?: () => void;
	className?: string;
	style?: React.CSSProperties;
}

class Popup extends React.Component<IPopupProps, { show: boolean }> {
	_isMounted = false;

	state = {
		show: false,
	};

	popupRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		this._isMounted = true;
		document.body.style.overflow = "hidden";

		const popupDiv = document.createElement("div");
		(this.popupRef as any).current = popupDiv;
		document.body.appendChild(popupDiv);
		this.forceUpdate();
		setTimeout(() => {
			this.setState({ show: true });
		}, 1);
	}

	componentWillUnmount() {
		this.onClose();
	}

	onClose = () => {
		this._isMounted = false;
		document.body.style.overflow = "auto";
		if (this.popupRef.current) {
			this.popupRef.current.remove();
		}
	};

	private popupBlackClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget && this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		if (!this.popupRef) return null;
		return (
			<React.Fragment>
				<Portal container={this.popupRef.current}>
					<div
						className={classNames(
							"popupBlackDiv",
							this.props.className
						)}
						onClick={this.popupBlackClick}
						style={this.props.style}
					>
						{this.state.show && this.props.children}
					</div>
				</Portal>
			</React.Fragment>
		);
	}
}

interface IPopupContentProps {
	scrollable?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

export const PopupContent: React.FC<IPopupContentProps> = ({
	scrollable = true,
	style,
	className,
	children,
}) => (
	<div
		className={classNames(
			"popup-content",
			scrollable && "scrollable",
			className
		)}
		style={style}
	>
		{children}
	</div>
);

interface IPopupContentWithCloseProps {
	onClose: () => void;
	style?: React.CSSProperties;
	className?: string;
	icon?: JSX.Element;
	popupBodyClassname?: string;
}

export const PopupContentWithClose: React.FC<IPopupContentWithCloseProps> = props => (
	<div className={classNames(PopupCSS, props.className)} style={props.style}>
		{props.icon ? (
			<div onClick={props.onClose} className={IconContainer}>
				{props.icon}
			</div>
		) : (
			<div className={PopupHeader}>
				<CancelIcon className={Cancel} onClick={props.onClose} />
			</div>
		)}

		<div className={props.popupBodyClassname || PopupBody}>
			{props.children}
		</div>
	</div>
);

export default Popup;
