import { IClassroom } from "@app/api/classrooms/helper-schemas";
import {
	ICourseInfo,
	IExtendedCourseInfo,
} from "@app/api/courses-info/helper-schemas";
import { IUserFolderLevel } from "@app/api/folders/helper-schemas";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { useCourseInfo, useFullCourseSkeleton } from "@app/hooks/courses";
import { IResourceLoadingInfo } from "@app/hooks/fetch";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useUserFolderLevels } from "@app/hooks/user-folder-level";
import { useCoursesUserId } from "@app/hooks/users";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { ObjectId } from "@tests-core/utils/joi";
import classNames from "classnames";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { GeorgianCourseId, useCourseOptionsAsStudent } from "../header/courses";
import { TeacherInvitationPopup } from "../invitations/outgoing";
import { CurrentLessonCircularProcess } from "../lessons/current-lesson-circular-progress";
import { useOverallScorePercentage } from "../lessons/nexams-percentage";
import HomeworkStyles from "../styles/homework.module.css";
import styles from "./styles/students-main-page.module.css";
import { UserFolderLevel } from "@app/models/user-folder-level";

export const CurrentCourse: React.FC<{
	courseId: ObjectId;
	courseInfo: ICourseInfo | IExtendedCourseInfo;
	classroom: IResourceLoadingInfo<IClassroom>;
	// TODO: Fis this later
	// eslint-disable-next-line max-lines-per-function
}> = React.memo(props => {
	const { width } = useWindowSize();

	const {
		value: isInvitationPopupOpen,
		setTrue: openInvitationPopup,
		setFalse: closeInvitationPopup,
	} = useBoolean(false);

	const course = useCourseInfo(props.courseId);

	const history = useHistory();

	const isRegistered = props.classroom.isIdentificationKnown;

	const coursesUserId = useCoursesUserId()!;
	const classroom = useClassroomByCourseId(props.courseId || null);
	const courseId = props.courseId;
	const courseInfo = useCourseInfo(props.courseId);
	useFullCourseSkeleton(courseId);
	const lessonsRootFolder = classroom.isIdentificationKnown
		? classroom.isSuccessfullyLoaded
			? classroom.doc.course.customFolderIds.lessons
			: null
		: courseInfo.isSuccessfullyLoaded
		? courseInfo.doc.rootFolder
		: null;

	const folderLevels = useUserFolderLevels(coursesUserId || null, courseId);

	const { courseOptions } = useCourseOptionsAsStudent(courseId);

	const changeCourse = useCallback(
		(courseId: ObjectId) => {
			let suffix = "";
			const regexMatch = /\/courses\/[\d\w]{24}\/(.+?)(\/|$)/.exec(
				history.location.pathname
			); // search for token after courseId
			if (regexMatch) {
				const token = regexMatch[1];
				if (
					["lessons", "assignments", "exercises"].indexOf(token) > -1
				) {
					suffix = "/" + token;
				}
			}
			history.push(`/courses/${courseId}${suffix}`);
		},
		[history]
	);

	const isMobile = width < 500;

	if (!courseOptions) return null; // TODO: show content loader

	return (
		<div className={styles.currentLesson}>
			<div
				className={
					isRegistered
						? classNames(
								styles.currentCourseText,
								styles.currentLessonText
						  )
						: classNames(
								styles.currentCourseTextIsntRegistered,
								styles.currentCourseText,
								styles.currentLessonText
						  )
				}
			>
				<div
					className={
						isRegistered
							? classNames(
									styles.currentCourseName,
									HomeworkStyles.currentLessonName
							  )
							: HomeworkStyles.currentLessonName
					}
				>
					<FormattedMessage id="currentCourse" />
				</div>
				{course.doc && (
					<div
						className={classNames(
							styles.currentTopicName,
							HomeworkStyles.topicName
						)}
					>
						{course.doc.name}
					</div>
				)}
				{isRegistered ? (
					<div className={styles.alreadyInClass}>
						<FormattedMessage id="alreadyInClass" />
					</div>
				) : (
					<div>
						{/* <button
							className={styles.button}
							onClick={openInvitationPopup}
						>
							<FormattedMessage id="student:joinClassroom" />
						</button> */}
					</div>
				)}
				{!isMobile && (
					<>
						<ClickableCourses
							options={courseOptions}
							onChange={course => {
								if (course.value === GeorgianCourseId) {
									history.push(`/english-course`);
								} else changeCourse(course.value);
							}}
							courseId={courseId}
						/>
					</>
				)}
			</div>
			<div className={styles.levelsConainer}>
				{lessonsRootFolder &&
					folderLevels.isSuccessfullyLoaded &&
					courseId && (
						<div className={styles.progressBarAndButton}>
							<CourseLevelContainer
								parentFolderId={lessonsRootFolder}
								folderLevels={folderLevels.doc}
								courseId={props.courseId}
								coursesUserId={coursesUserId}
							/>
							{isMobile && (
								<div>
									<ClickableCourses
										options={courseOptions}
										onChange={course => {
											if (
												course.value ===
												GeorgianCourseId
											) {
												history.push(`/english-course`);
											} else changeCourse(course.value);
										}}
										courseId={courseId}
									/>
								</div>
							)}
						</div>
					)}
			</div>
			{isInvitationPopupOpen && (
				<TeacherInvitationPopup onClose={closeInvitationPopup} />
			)}
		</div>
	);
});

const CourseLevelContainer: React.FC<{
	parentFolderId: ObjectId;
	folderLevels: UserFolderLevel;
	courseId: ObjectId;
	coursesUserId: number;
}> = props => {
	const score = useOverallScorePercentage({
		parentFolderId: props.parentFolderId,
		folderLevels: props.folderLevels,
		courseId: props.courseId,
		coursesUserId: props.coursesUserId,
	});
	const { width } = useWindowSize();

	const {
		value: isInfoVisible1,
		setTrue: showInfo1,
		setFalse: hideInfo1,
	} = useBoolean(false);

	if (score === null) return null;

	let CircularBarNumberFontSize = 44;
	let size = 196;
	let strokeWidth = 30;
	if (width <= 600) {
		CircularBarNumberFontSize = 30;
		size = 130;
		strokeWidth = 20;
	}
	if (width <= 500) {
		CircularBarNumberFontSize = 28;
		size = 114;
		strokeWidth = 15.5;
	}

	let textColor = "";
	textColor = "#536edb";

	return (
		<div
			style={{ cursor: "pointer" }}
			onMouseEnter={showInfo1}
			onMouseLeave={hideInfo1}
		>
			<CurrentLessonCircularProcess
				size={size}
				progressStrokeWidth={strokeWidth}
				progress={score / 100}
				progressStrokeColor={["#ff8091", "#ff917d "]}
			>
				<div
					className={styles.taskImg}
					style={{
						color: textColor,
						fontSize: CircularBarNumberFontSize,
					}}
				>
					{Math.round(score) / 10}
				</div>
			</CurrentLessonCircularProcess>
			{isInfoVisible1 && (
				<div
					className={styles.hoverinfo}
					style={{
						display: isInfoVisible1 ? "flex" : "none",
					}}
				>
					<p>
						<FormattedMessage id="courseLevel" />
					</p>
				</div>
			)}
		</div>
	);
};

interface ClickableCousesProps<Value extends string | number> {
	options: { value: Value; label: JSX.Element | string }[];
	courseId: Value;

	onChange?: (selectedOption: {
		value: Value;
		label: JSX.Element | string;
	}) => void;
}

export const ClickableCourses = <Value extends string | number>(
	props: ClickableCousesProps<Value>
) => {
	const {
		value: isMenuVisible,
		setFalse: hideMenu,
		switchValue: switchMenu,
	} = useBoolean(false);

	if (props.options.length === 0) {
		return null;
	}

	return (
		<>
			<div
				className={classNames(
					styles.currentCourseButtonAll,
					styles.buttonAll
				)}
				onClick={switchMenu}
				style={{
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<FormattedMessage id="changeCourse" />
				<ArrowRightIcon style={{ fontSize: 20 }} />
			</div>
			{isMenuVisible && props.options.length > 0 && (
				<>
					<MenuOptions<Value>
						options={props.options}
						onSelect={(option, e) => {
							e.stopPropagation();
							hideMenu();
							if (props.onChange) {
								props.onChange(option);
							}
						}}
						selected={props.courseId}
						onHide={() => hideMenu()}
					/>
				</>
			)}
		</>
	);
};

interface MenuOptionsProps<Value extends string | number> {
	style?: React.CSSProperties;
	options: { value: Value; label: JSX.Element | string }[];
	onSelect: (
		option: { value: Value; label: JSX.Element | string },
		e: React.MouseEvent
	) => void;
	onHide: () => void;
	selected?: Value;
}

const MenuOptions = <Value extends string | number>(
	props: MenuOptionsProps<Value>
) => {
	return (
		<div className={styles.listContainer}>
			<div onClick={props.onHide} className={styles.cancelIconContainer}>
				{" "}
				<CancelIcon />
			</div>
			<div className={styles.list}>
				{props.options.map(option => (
					<div
						key={option.value}
						onClick={e => props.onSelect(option, e)}
						className={
							styles.eachCourse +
							(props.selected === option.value
								? " " + styles.selected
								: "")
						}
					>
						{option.label}
					</div>
				))}
			</div>
		</div>
	);
};
