import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { ReactComponent as HubuLogoIcon } from "@app/components/landing/styles/img/hubu-logo.svg";
import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/tv-school-logo.svg";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useMyClassroomsAsStudent } from "@app/hooks/classrooms";
import { useCoursesByIds } from "@app/hooks/courses";
import { useLocale } from "@app/hooks/intl";
import { useUnseenSpecialCourses } from "@app/hooks/special-courses";
import { useStudentInfo } from "@app/hooks/users";
import { Classroom } from "@app/models/classroom";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "../../../hooks/front";
import { ReactComponent as LupiLogo } from "../../landing/styles/img/lupi-ai.svg";
import { ReactComponent as NewIcon } from "../../styles/img/new.svg";
import { HoverableSelect } from "../../widgets/hoverable-select";
import { getCourseIdFromUrl } from "../last-course-saver";
import HeaderStyles from "./styles/header.module.css";

interface ILogoAndCourses {
	width: number;
	courseId: ObjectId | null;
}

export const LogoAndCourses: React.FC<ILogoAndCourses> = props => {
	const history = useHistory();
	const locale = useLocale();
	const courseId =
		props.courseId || getCourseIdFromUrl(history.location.pathname);
	const gotoMainPage = useCallback(() => {
		if (!courseId) {
			history.push(`/`);
			return;
		}
		history.push(`/courses/${courseId}`);
	}, [courseId, history]);
	return (
		<div className={HeaderStyles.coursesAndLogoContainer}>
			<div className={HeaderStyles.logo} onClick={gotoMainPage}>
				{getCurrentWebsite() === WebsiteOrigin.hubu &&
					locale === "ka" && (
						<HubuLogoIcon style={{ height: "100%", width: 112 }} />
					)}
				{getCurrentWebsite() === WebsiteOrigin.tvSchool && (
					<TVSchoolLogo style={{ height: "100%", width: 112 }} />
				)}
				{getCurrentWebsite() === WebsiteOrigin.tvSchool &&
					locale === "en" && (
						<LupiLogo style={{ height: "100%", width: 112 }} />
					)}
			</div>
			{props.width && props.width > 1200 && (
				<HeaderCoursesAndLogo courseId={courseId} />
			)}
		</div>
	);
};

export const HeaderCoursesAndLogo: React.FC<{
	courseId: ObjectId | null;
}> = ({ courseId }) => {
	const history = useHistory();
	const onCourseSelect = useCallback(
		(courseId: ObjectId) => {
			let suffix = "";
			const regexMatch = /\/courses\/[\d\w]{24}\/(.+?)(\/|$)/.exec(
				history.location.pathname
			); // search for token after courseId
			if (regexMatch) {
				const token = regexMatch[1];
				if (
					["lessons", "assignments", "exercises"].indexOf(token) > -1
				) {
					suffix = "/" + token;
				}
			}
			history.push(`/courses/${courseId}${suffix}`);
		},
		[history]
	);

	return (
		<>
			<div className={HeaderStyles.examsAndCourseContainer}>
				<CoursesSwitcher
					selectedCourseId={courseId || null}
					onCourseSelect={onCourseSelect}
				/>
			</div>
		</>
	);
};

export const CoursesSwitcher: React.FC<{
	selectedCourseId: ObjectId | null;
	onCourseSelect: (courseId: ObjectId) => void;
}> = React.memo(props => {
	const history = useHistory();
	const unseenSpecialCourses = useUnseenSpecialCourses();
	const { courseOptions, selectedCourse } = useCourseOptionsAsStudent(
		props.selectedCourseId
	);

	if (!courseOptions) return null;

	return (
		<>
			<HoverableSelect<ObjectId>
				title={
					selectedCourse
						? selectedCourse.label
						: getFormattedMessage("chooseCourse")
				}
				options={courseOptions}
				onChange={course => {
					if (course.value === GeorgianCourseId) {
						history.push(`/english-course`);
					} else props.onCourseSelect(course.value);
				}}
				selected={props.selectedCourseId || undefined}
				actOnHover={true}
				actOnHeaderClick={false}
				icon={<DownIcon />}
			/>

			{unseenSpecialCourses && unseenSpecialCourses.length > 0 && (
				<div style={{ color: "white", width: 40, marginLeft: -15 }}>
					<NewIcon />
				</div>
			)}
		</>
	);
});

const useClassroomsByCourses = () => {
	const classrooms = useMyClassroomsAsStudent();
	return useMemo(() => {
		if (!classrooms) return null;
		const classroomsObj: Record<string, Classroom | undefined> = {};
		for (const classroom of classrooms) {
			classroomsObj[classroom.course.courseId] = classroom;
		}
		return classroomsObj;
	}, [classrooms]);
};

export const useCourseOptionsAsStudent = (
	selectedCourseId: ObjectId | null
) => {
	const studentInfo = useStudentInfo();
	const courseIds = studentInfo ? studentInfo.courseIds : null;

	const courses = useCoursesByIds(courseIds);
	const unseenSpecialCourses = useUnseenSpecialCourses();

	const locale = useLocale();
	const classroomsByCourses = useClassroomsByCourses();

	const extendedCourses = useMemo(() => {
		if (!courses) return null;
		if (locale !== "ka") return courses;
		return courses;
	}, [courses, locale]);

	const courseOptions = useMemo(():
		| {
				value: ObjectId;
				label: JSX.Element | string;
		  }[]
		| null => {
		if (!extendedCourses || !classroomsByCourses) return null;
		return extendedCourses.map(course => {
			const classroom = classroomsByCourses![course._id];
			const isUnseenSpecialCourse = unseenSpecialCourses
				? unseenSpecialCourses.indexOf(course._id) > -1
				: false;
			const name = getCourseOptionFullName(course, classroom);
			return {
				value: course._id,
				label: !isUnseenSpecialCourse
					? name
					: getUnseenSpecialCourseLabel(name),
			};
		});
	}, [classroomsByCourses, extendedCourses, unseenSpecialCourses]);

	return useMemo(() => {
		return {
			courseOptions: courseOptions || null,
			selectedCourse: !courseOptions
				? null
				: courseOptions.find(
						course => course.value === selectedCourseId
				  ) || null,
		};
	}, [courseOptions, selectedCourseId]);
};

const getCourseOptionFullName = (
	course: { name: string },
	classroom: IClassroom | undefined
) => {
	if (!classroom) return course.name;
	return course.name + " (" + classroom.name + ")";
};

const getUnseenSpecialCourseLabel = (name: string) => {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{name}
			<div
				style={{
					color: "#5273e6",
					width: 20,
					marginLeft: 10,
				}}
			>
				<NewIcon />
			</div>
		</div>
	);
};

export const GeorgianCourseId = "5c4dcdac82de0e0d6897ebeb";
