import Joi from "@app/utils/joi";
import {
	ConsultationRequestsSchema,
	IConsultationRequests,
} from "./helper-schemas";

export const RGETAllConsultationRequestsSchema = Joi.array()
	.items(ConsultationRequestsSchema)
	.required();
export type IRGETAllConsultationRequests = IConsultationRequests[];
