import { useHistory } from "@app/hooks/front";
import React, { useLayoutEffect, useMemo } from "react";
import { useTeacherAssignmentsCount } from "@app/hooks/assignments";
import { FancyLoadingCenter } from "@app/components/users/student-loading-page";
import { useTeacherInfo } from "@app/hooks/teacher";
import { useMyClassroomsAsTeacher } from "@app/hooks/classrooms";
import {
	TVSchoolClassroomBoxesContainer,
	TVSchoolClassroomBoxesLoaderContainer,
} from "./classrooms";
import { Classroom } from "@app/models/classroom";
import touImg from "../../../users/tests/styles/imgs/tou-085.png";
import styles from "./styles/tvschool-classroom.module.css";
import { getFormattedMessage } from "@app/utils/locale";
import { useLocale } from "@app/hooks/intl";
import classnames from "classnames";
import { CertificatePopup } from "../../certificates/certificate-info-popup";
import { TeacherTVSchoolPopups } from "./popups";
import { inject } from "@app/modules";
import { isCertificateServicesAvaliable } from "@app/consts";
import { ArchivedClassroomsContainer } from "./archived-classrooms";
import { useTeacherSelectedSchoolId } from "../../contexts/teacher-school";
import { User } from "@app/user";

export const archiveClassroomUpperBoundDate = new Date(2020, 8, 6);

export const TVSchoolTeacherMainPage: React.FC<{ user: User }> = ({ user }) => {
	const locale = useLocale();
	const assignemntsCount = useTeacherAssignmentsCount();
	const history = useHistory();
	const teacherInfo = useTeacherInfo();

	useLayoutEffect(() => {
		if (!assignemntsCount.isSuccessfullyLoaded) return;
		if (!teacherInfo) return;
		const ClassroomModel = inject("ClassroomModel");
		const GroupModel = inject("GroupModel");
		const visibleClassroomsCount = teacherInfo.classrooms
			.map(e => new ClassroomModel(e))
			.filter(e => e.isVisible()).length;
		const visibleGroupsCount = teacherInfo.groups
			.map(e => new GroupModel(e))
			.filter(e => e.isVisible()).length;

		const shouldRedirectToNewAssignmentPage =
			assignemntsCount.doc.count === 0 &&
			visibleClassroomsCount === 0 &&
			visibleGroupsCount === 0;

		if (shouldRedirectToNewAssignmentPage) {
			sessionStorage.setItem("isFirstAssignment", "true");
			history.push(`/assignments/new`);
		}
	}, [
		history,
		assignemntsCount.isSuccessfullyLoaded,
		assignemntsCount.doc,
		teacherInfo,
	]);

	if (!assignemntsCount.isSuccessfullyLoaded || !teacherInfo) {
		return <FancyLoadingCenter />;
	}

	return (
		<>
			<TeacherClassroomsPage teacherInfo={teacherInfo} />
			{locale === "ka" && isCertificateServicesAvaliable && (
				<CertificatePopup />
			)}
			<TeacherTVSchoolPopups />
		</>
	);
};

const TeacherClassroomsPage = React.memo<{
	teacherInfo: ReturnType<typeof useTeacherInfo>;
}>(({ teacherInfo }) => {
	const myClassrooms = useMyClassroomsAsTeacher(true);
	const locale = useLocale();
	const isGeo = locale === "ka";
	const teacherSchoolId = useTeacherSelectedSchoolId();
	const visibleClassrooms = useMemo(
		() =>
			(myClassrooms || []).filter(
				e => e.isVisible() && e.matchesSchoolId(teacherSchoolId)
			),
		[myClassrooms, teacherSchoolId]
	);
	const displayTimetable = visibleClassrooms.some(
		e => e.classTimes.length > 0
	);
	const classroomIds = useMemo(
		() => visibleClassrooms.sort(compareClassrooms).map(e => e._id),
		[visibleClassrooms]
	);

	const visibleGroups = useMemo(() => {
		if (!teacherInfo) return null;
		const GroupModel = inject("GroupModel");
		return teacherInfo.groups
			.map(gr => new GroupModel(gr))
			.filter(gr => gr.isVisible() && gr.matchesSchoolId(teacherSchoolId))
			.sort((a, b) => {
				if (a.name && b.name) {
					return a.name > b.name ? 1 : -1;
				}
				return a.grade - b.grade;
			});
	}, [teacherInfo, teacherSchoolId]);

	const enableArchive = useMemo(() => {
		return visibleClassrooms.map(e => e.isPremium()).includes(false);
	}, [visibleClassrooms]);

	return (
		<div className={styles.classrooms}>
			<div className={styles.myClassrooms}>
				{getFormattedMessage("myClassrooms")}
			</div>
			<div className={styles.classroomBoxsContainers}>
				{!teacherInfo ? (
					<TVSchoolClassroomBoxesLoaderContainer />
				) : (
					<TVSchoolClassroomBoxesContainer
						classroomIds={classroomIds}
						enableArchive={enableArchive}
						groups={visibleGroups}
						displayTimetable={displayTimetable}
					/>
				)}
			</div>
			<div className="main" style={{ textAlign: "center", zIndex: 2 }}>
				<ArchivedClassroomsContainer />
			</div>
			{isGeo && (
				<div
					className={classnames(styles.touBanner)}
					onClick={() => window.open("https://tou.edu.ge/", "_blank")}
				>
					<img src={touImg} alt="logo" className={styles.touLogo} />
					<div className={styles.touText}>
						დისტანციური საგაკვეთილო პროცესის ეფექტიანობისთვის
						პროექტის მხარდამჭერია{" "}
						<div className={styles.tou}>
							თბილისის ღია უნივერსიტეტი
						</div>
					</div>
				</div>
			)}
		</div>
	);
});

const compareClassrooms = (cl1: Classroom, cl2: Classroom) => {
	return cl1.grade - cl2.grade;
};
