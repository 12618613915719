import { ObjectId } from "@app/utils/generics";
import Slider, { Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { IHWState, TaskTypesContext } from "../";
import { IMultipleSelectItem } from "../../../../widgets/deep-multiple-select";
import styles from "../../styles/styles.module.css";
import { IHWActions } from "../reducer";
import { useWindowSize } from "@app/hooks/front";
import { FormattedMessage } from "react-intl";

interface ITaskTypeSlidersProps {
	topics: any;
	dispatch: React.Dispatch<IHWActions>;
	homework: IHWState;
}

export const TaskTypeSliders: React.FC<ITaskTypeSlidersProps> = React.memo(
	props => {
		const taskTypeInfo = useContext(TaskTypesContext);
		const dispatch = props.dispatch;
		const homework = props.homework;

		const onSliderChange = useCallback(
			(taskType: IMultipleSelectItem<string>, value: number) => {
				dispatch({
					type: "CHANGE_TASKTYPES_COUNT",
					newCount: value,
					taskTypeId: taskType.id,
				});
			},
			[dispatch]
		);

		const taskTypeObj = useMemo((): {
			[taskTypeId: string]: { id: ObjectId; name: string } | undefined;
		} => {
			if (!taskTypeInfo) return {};
			const obj: {
				[taskTypeId: string]:
					| { id: ObjectId; name: string }
					| undefined;
			} = {};
			for (const taskType of taskTypeInfo.taskTypes) {
				obj[taskType._id] = { id: taskType._id, name: taskType.name };
			}
			return obj;
		}, [taskTypeInfo]);

		if (!homework.taskTypesInAdvancedSettings) {
			return null;
		}

		const findNumOfQuestions = React.useCallback(() => {
			let independentQuestions = 0;
			let texts = 0;
			let textQuestions = 0;
			for (const taskType of taskTypeInfo!.taskTypes) {
				const myTaskType = homework.taskTypesInAdvancedSettings!.find(
					e => e._id === taskType._id
				);
				if (!taskType.consistsOfMultipleQuestions) {
					independentQuestions += myTaskType!.count;
				} else if (
					taskType.consistsOfMultipleQuestions &&
					taskType.approximateNumOfQuestionsPerText
				) {
					texts += myTaskType!.count;
					textQuestions +=
						myTaskType!.count *
						taskType.approximateNumOfQuestionsPerText;
				}
			}
			return [
				independentQuestions,
				texts,
				textQuestions + independentQuestions,
			];
		}, [homework.taskTypesInAdvancedSettings, taskTypeInfo]);

		if (!taskTypeInfo) return null;
		const questions: ({
			_id: string;
			originalCourseId: string;
			name: string;
			author: number;
		} & {
			photo?: string | undefined;
			consistsOfMultipleQuestions?: boolean | undefined;
			approximateNumOfQuestionsPerText?: number | null | undefined;
		})[] = [];
		const texts: ({
			_id: string;
			originalCourseId: string;
			name: string;
			author: number;
		} & {
			photo?: string | undefined;
			consistsOfMultipleQuestions?: boolean | undefined;
			approximateNumOfQuestionsPerText?: number | null | undefined;
		})[] = [];
		taskTypeInfo.taskTypes.forEach(taskType => {
			const taskTypeInfo = taskTypeObj[taskType._id];
			if (!taskTypeInfo) return;
			const myTaskType = homework.taskTypesInAdvancedSettings!.find(
				e => e._id === taskType._id
			);
			if (!myTaskType) return;
			if (myTaskType.countType === "question") {
				questions.push(taskType);
			} else if (myTaskType.countType === "text") {
				texts.push(taskType);
			}
			return;
		});

		const [
			independentQuestionsCount,
			textsCount,
			totalCount,
		] = findNumOfQuestions();

		return (
			<>
				{questions.length > 0 && (
					<div style={{ margin: "40px 0" }}>
						<div style={{ display: "flex" }}>
							<div style={{ flex: 1, padding: "0px 20px" }}>
								სავარჯიშოს ტიპები
							</div>
							<div style={{ flex: 1, padding: "0px 20px" }}>
								<FormattedMessage id="numOfQuestions" />
							</div>
						</div>

						{taskTypeInfo &&
							taskTypeInfo.taskTypes.map(selection => {
								const taskTypeInfo = taskTypeObj[selection._id];
								if (!taskTypeInfo) return null;
								const myTaskType = homework.taskTypesInAdvancedSettings!.find(
									e => e._id === selection._id
								);
								if (!myTaskType) return null;
								if (myTaskType.countType === "question") {
									return (
										<TaskTypeSlider
											taskType={taskTypeInfo}
											key={selection._id}
											onChange={onSliderChange}
											count={myTaskType.count}
											countType={myTaskType.countType}
										/>
									);
								}
								return null;
							})}
						<div style={{ fontWeight: "bold" }}>
							სულ არჩეულია {independentQuestionsCount}{" "}
							დამოუკიდებელი კითხვა
						</div>
					</div>
				)}
				{texts.length > 0 && (
					<div style={{ margin: "40px 0" }}>
						<div style={{ display: "flex" }}>
							<div style={{ flex: 1, padding: "0px 20px" }}>
								სავარჯიშოს ტიპები
							</div>
							<div style={{ flex: 1, padding: "0px 20px" }}>
								ტექსტების რაოდენობა
							</div>
						</div>
						{taskTypeInfo &&
							taskTypeInfo.taskTypes.map(selection => {
								const taskTypeInfo = taskTypeObj[selection._id];
								if (!taskTypeInfo) return null;
								const myTaskType = homework.taskTypesInAdvancedSettings!.find(
									e => e._id === selection._id
								);
								if (!myTaskType) return null;

								if (myTaskType.countType === "text") {
									return (
										<TaskTypeSlider
											taskType={taskTypeInfo}
											key={selection._id}
											onChange={onSliderChange}
											count={myTaskType.count}
											countType={myTaskType.countType}
										/>
									);
								}
								return null;
							})}

						<div style={{ fontWeight: "bold" }}>
							სულ არჩეულია {textsCount} ტექსტი
						</div>
					</div>
				)}
				{textsCount > 0 && (
					<div style={{ fontWeight: "bold" }}>
						სულ არჩეულია დაახლოებით {totalCount} კითხვა
					</div>
				)}
				{independentQuestionsCount > 0 && !textsCount && (
					<div style={{ fontWeight: "bold" }}>
						სულ არჩეულია {totalCount} კითხვა
					</div>
				)}
			</>
		);
	}
);

interface ITaskTypeSliderProps {
	taskType: IMultipleSelectItem<string>;
	onChange: (topic: IMultipleSelectItem<string>, value: number) => void;
	count: number;
	countType: "text" | "question";
}

export const TaskTypeSlider = React.memo((props: ITaskTypeSliderProps) => {
	const [selectedValue, setSelectedValue] = useState(props.count);
	const { width } = useWindowSize();
	useEffect(() => {
		setSelectedValue(props.count);
	}, [props.count]);
	const handle = React.useCallback(props => {
		const { value, dragging, index, ...restProps } = props;
		return (
			<Tooltip
				prefixCls="rc-slider-tooltip"
				overlay={value}
				visible={dragging}
				placement="top"
				key={index}
			>
				<Handle value={value} {...restProps} />
			</Tooltip>
		);
	}, []);
	const onAfterChange = React.useCallback(
		(value: number) => {
			props.onChange(props.taskType, value);
		},
		[props]
	);
	const min = 0;
	const max = Math.max(25, selectedValue);
	return (
		<div>
			<div
				style={{
					display: "flex",
					margin: "10px 0px",
					background: "#daddfb",
				}}
				key={props.taskType.id}
			>
				<div className={styles.taskTypeName}>{props.taskType.name}</div>
				<div style={{ flex: 1, padding: "5px  20px" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Slider
							min={min}
							max={max}
							value={selectedValue}
							handle={handle}
							onChange={setSelectedValue}
							onAfterChange={onAfterChange}
							className={styles.sliderStyles}
							railStyle={{
								backgroundColor: "#bebebe",
								height: 5,
							}}
							trackStyle={{
								backgroundColor: "#5c5de8",
								height: 5,
							}}
							handleStyle={
								width > 600
									? {
											height: 40,
											width: 40,
											marginLeft: -23,
											marginTop: -17,
											backgroundColor: "rgb(92, 93, 232)",
											left: "100%",
											border:
												"7px solid rgb(206, 206, 248)",
											boxShadow: "none",
									  }
									: {
											height: 20,
											width: 20,
											marginLeft: -14,
											marginTop: -8,
											backgroundColor: "rgb(92, 93, 232)",
											left: "100%",
											border:
												"4px solid rgb(206, 206, 248)",
											boxShadow: "none",
									  }
							}
						/>
						<div className={styles.questionsContainer}>
							<div
								className={styles.questions}
								style={
									width > 600
										? {
												background: "white",
										  }
										: {
												background: "white",
												maxWidth: 20,
												padding: "3px 7px",
												marginLeft: 5,
												fontSize: 11,
										  }
								}
							>
								{selectedValue}
							</div>
						</div>
					</div>
					<div style={{ display: "flex" }}>
						<div className={styles.sliderMaxAndMinContainer}>
							<div className={styles.sliderMin}>{min}</div>
							<div className={styles.sliderMax}>{max}</div>
						</div>
						<div className={styles.freeSpace} />
					</div>
				</div>
			</div>
		</div>
	);
});
