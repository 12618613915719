import { ObjectId } from "@app/utils/generics";
import { UserPermissions } from ".";
import { IRAccessibleIds } from "./interfaces";

export class HeadmasterPermissions extends UserPermissions {
	constructor(private readonly schoolId: number | null) {
		super();
	}

	canAccessAllClassrooms(): boolean {
		return false;
	}

	canAccessClassroom(): boolean {
		return false;
	}

	canStudyInClassroom(): boolean {
		return false;
	}

	canAccessGroup(): boolean {
		return false;
	}

	getAvailableClassrooms(): ObjectId[] {
		return [];
	}

	getOwnClassrooms(): ObjectId[] {
		return [];
	}

	getOwnGroups(): ObjectId[] {
		return [];
	}

	hasConfirmedChild(): boolean {
		return false;
	}

	getAccessibleCourseIds(): IRAccessibleIds {
		return { isKnown: true, hasAll: false, ids: [] };
	}

	canViewSchool(schoolId: number): boolean {
		return this.schoolId === schoolId;
	}
}
