import { useEffect, useState, useMemo } from "react";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useClassroom, useClassroomByCourseId } from "./classrooms";
import { useCoursesUserId } from "./users";
import {
	ICourseAvailability,
	ICourseInfo,
	IExtendedCourseInfo,
} from "@app/api/courses-info/helper-schemas";
import {
	useModelDocById,
	useModelDocsByIds,
	useModelDocs,
} from "m-model-react";
import { ICourseFullSkeleton } from "@app/api/courses/fetch-controllers";
import {
	IResourceLoadingInfo,
	useResourceInfoWithLoading,
	getResourceLoadingInfo,
} from "./fetch";
import { useMountingInfo } from "@app/hooks/general";
import { Course } from "@app/models/course";
import { CourseInfo } from "@app/models/course-info";
import { IAGETAllCoursesInfo } from "@app/api/courses-info/validators";

const fetchCourse = (courseId: ObjectId) => {
	return inject("CoursesController").getById({ _id: courseId });
};

export function useCourse(
	courseId: ObjectId | null
): IResourceLoadingInfo<Course> {
	const course = useModelDocById(inject("CourseModel"), courseId);
	return useResourceInfoWithLoading({
		resource: course,
		fetchingArg: courseId!,
		fetch: fetchCourse,
		isIdentificationKnown: !!courseId,
	});
}

export function useCourseByClassroomId(
	classroomId: ObjectId | null
): IResourceLoadingInfo<Course> {
	const classroom = useClassroom(classroomId);
	const course = useCourse(
		classroom.isSuccessfullyLoaded ? classroom.doc.course.courseId : null
	);
	if (!classroom.isSuccessfullyLoaded && classroom.isIdentificationKnown) {
		return {
			doc: undefined,
			isSuccessfullyLoaded: false,
			hasFoundError: false,
			isIdentificationKnown: true,
		};
	}
	return course;
}

export function useFullCourseSkeleton(
	courseId: ObjectId | null
): IResourceLoadingInfo<ICourseFullSkeleton, undefined> {
	const coursesUserId = useCoursesUserId();
	const mountingInfo = useMountingInfo();
	const [courseFullInfo, setCourseFullInfo] = useState<ICourseFullSkeleton>();
	useEffect(() => {
		let isCanceled = false;
		if (!mountingInfo.isFirstMounting) {
			setCourseFullInfo(undefined);
		}
		if (!courseId || !coursesUserId) return;
		const CourseFetchingController = inject("CourseFetchingController");
		CourseFetchingController.loadWholeCourseSkeleton({
			courseId,
		}).then(data => {
			if (isCanceled) return;
			setCourseFullInfo(data);
		});
		return () => {
			isCanceled = true;
		};
	}, [courseId, coursesUserId, mountingInfo]);
	return useMemo(() => {
		return getResourceLoadingInfo<ICourseFullSkeleton, undefined>({
			resource: courseFullInfo,
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: courseId !== null,
		});
	}, [courseFullInfo, courseId]);
}

export function useCourseInfoByAvailability(
	grade: number,
	subject: ObjectId
): ICourseInfo[] {
	const [courseInfo, setCourseInfo] = useState<ICourseInfo[]>([]);
	useEffect(() => {
		const CoursesInfoController = inject("CoursesInfoController");
		CoursesInfoController.getAll({ grade, subject }).then(data =>
			setCourseInfo(data)
		);
	});
	return courseInfo;
}

export function useCourseInfo(
	courseId: ObjectId | null
): IResourceLoadingInfo<CourseInfo | IExtendedCourseInfo, undefined> {
	const CourseInfoModel = inject("CourseInfoModel");
	const classroom = useClassroomByCourseId(courseId);
	const [courseInfo, setCourseInfo] = useState<
		CourseInfo | IExtendedCourseInfo | null
	>(courseId ? CourseInfoModel.findByCourseIdSync(courseId) : null);

	const hasToLoadClassroom = classroom.isIdentificationKnown;

	useEffect(() => {
		let isCancelled = false;
		if (!courseId) {
			return;
		}
		const CoursesInfoController = inject("CoursesInfoController");
		const doc = CourseInfoModel.findByCourseIdSync(courseId);
		const cancelSubscription = CourseInfoModel.subscribeChangeById(
			courseId,
			doc => {
				if (isCancelled) return;
				setCourseInfo(doc);
			}
		);
		if (doc) {
			setCourseInfo(doc);
		} else {
			if (!hasToLoadClassroom) {
				CoursesInfoController.getCourseInfo({ courseId }).then();
			}
		}
		return () => {
			isCancelled = true;
			cancelSubscription();
		};
	}, [CourseInfoModel, courseId, hasToLoadClassroom]);
	return useMemo((): IResourceLoadingInfo<
		CourseInfo | IExtendedCourseInfo,
		undefined
	> => {
		if (!courseId) {
			return {
				doc: undefined,
				isSuccessfullyLoaded: false,
				hasFoundError: false,
				isIdentificationKnown: false,
			};
		}
		return getResourceLoadingInfo<
			CourseInfo | IExtendedCourseInfo,
			undefined
		>({
			resource: classroom.isIdentificationKnown
				? classroom.isSuccessfullyLoaded
					? classroom.doc.course
					: null
				: courseInfo,
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: true,
		});
	}, [classroom, courseId, courseInfo]);
}

const fetchAllCoursesInfo = (args: IAGETAllCoursesInfo) => {
	return inject("CoursesInfoController").getAll(args || {});
};

export const useCoursesInfo = (
	args: IAGETAllCoursesInfo | null,
	forcefullyFetch = false
) => {
	const coursesInfo = useModelDocs(inject("CourseInfoModel"));

	return useResourceInfoWithLoading({
		resource: coursesInfo,
		fetchingArg: args,
		fetch: fetchAllCoursesInfo,
		isIdentificationKnown: !!args,
		forcefullyFetch,
	});
};

export const useCoursesByIds = (courseIds: ObjectId[] | null) => {
	const CoursesModel = inject("CourseModel");
	const courses = useModelDocsByIds(CoursesModel, courseIds);

	useEffect(() => {
		if (!courseIds) return;
		const CoursesController = inject("CoursesController");
		CoursesController.getByIds({ ids: courseIds });
	}, [courseIds]);

	return courses;
};

const fetchAllCourses = () => {
	return inject("CoursesController").getAll();
};

export const useCourses = () => {
	const courses = useModelDocs(inject("CourseModel"));

	return useResourceInfoWithLoading({
		resource: courses,
		fetchingArg: undefined,
		fetch: fetchAllCourses,
		isIdentificationKnown: true,
		forcefullyFetch: true,
	});
};

const fetchCourseSettings = (courseId: ObjectId) => {
	return inject("CoursesInfoController").getCourseSettings({
		courseId,
	});
};

export function useCourseSettings(courseId: ObjectId | null) {
	const courseSettings = useModelDocById(
		inject("CourseSettingsModel"),
		courseId
	);

	return useResourceInfoWithLoading({
		resource: courseSettings,
		fetchingArg: courseId!,
		fetch: fetchCourseSettings,
		isIdentificationKnown: !!courseId,
	});
}
