import { Group } from "@app/models/group";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById } from "m-model-react";
import { useEffect, useState } from "react";
import { useFetch, useResourceLoading } from "./fetch3";

export const useGroupById = (groupId: ObjectId | null) => {
	const GroupModel = inject("GroupModel");
	const group = useModelDocById(GroupModel, groupId);
	return useResourceLoading(
		group,
		() => inject("GroupController").getById(groupId!),
		[group]
	);
};

export const useGroupsByStudentId = (studentId?: number) => {
	const [groups, setGroups] = useState<Group[]>([]);
	useEffect(() => {
		inject("GroupController")
			.getGroupsByStudentId({ studentId })
			.then((data: Group[]) => {
				setGroups(data);
			});
	}, [studentId]);
	return groups;
};

export const useGroupClassrooms = (groupId: ObjectId | null) =>
	useFetch(
		() =>
			inject("GroupController").getClassrooms({
				groupId: groupId!,
			}),
		[groupId]
	);

export const useGroupsBySchoolId = (
	school: number | null,
	loadFresh?: boolean
) =>
	useFetch(
		() =>
			inject("GroupController").getBySchool(
				{ schoolId: school! },
				loadFresh
			),
		!!school,
		[school, loadFresh]
	);
