import { isLocalhost } from "@app/globals";
import { Classroom } from "@app/models/classroom";
import { ObjectId } from "@app/utils/generics";
import {
	ISidebarItem,
	IDisplaySideBarPaths,
} from "@app/components/layouts/sidebar/helpers/interfaces";
import {
	SidebarSentHomeworkIcon,
	SidebarGradebookIcon,
	SidebarStudentListIcon,
} from "@app/icons";
import { createSidebarIconRenderer } from "@app/components/layouts/sidebar/helpers/functions";

export enum TeacherSidebarType {
	classroom = "teacher-classroom",
	group = "teacher-group",
}

type ITeacherDisplayBarPath = IDisplaySideBarPaths<TeacherSidebarType>;

export const TeacherSidebarItems: ITeacherDisplayBarPath[] = [
	{
		type: TeacherSidebarType.classroom,
		paths: [
			{
				name: "გაგზავნილი დავალებები",
				href: "/classrooms/:classroomId",
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarSentHomeworkIcon
				),
			},
			{
				name: "კლასის სია",
				href: "/classrooms/:classroomId/students",
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarStudentListIcon
				),
			},
			{
				name: "კლასის ჟურნალი",
				href: "/classrooms/:classroomId/gradebook",
				notAvailableSchoolIds: [620, 4431],
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarGradebookIcon
				),
			},
			{
				name: "სტუდენტის პორტფოლიო",
				href: "/classrooms/:classroomId/portfolio/:studentId",
				dontShowInSidebar: true,
			},
		],
	},
	{
		type: TeacherSidebarType.group,
		paths: [
			{
				name: "კლასის ცხრილი",
				href: "/group/:groupId/timetable",
			},
			{
				name: "კლასის ჟურნალი",
				href: "/group/:groupId/gradebook",
				iconRendererForCollapsedView: createSidebarIconRenderer(
					SidebarGradebookIcon
				),
			},
			{
				name: "კლასის სია",
				href: "/group/:groupId/student-list",
			},
		],
	},
];

export const isTeacherSidebarPath = (path: string) => {
	for (let i = 0; i < TeacherSidebarItems.length; i++) {
		const element = TeacherSidebarItems[i];
		for (let a = 0; a < element.paths.length; a++) {
			const curPath = element.paths[a];
			if (curPath.href === path) {
				return true;
			}
		}
	}
	return false;
};

const NewTeacherHeaderPaths: string[] = ["/", "/directorate"];

export const shoudlRenderNewTeacherHeader = (url: string) => {
	console.log(url);
	if (NewTeacherHeaderPaths.find(e => e === url)) {
		return true;
	}
	if (isTeacherSidebarPath(url)) {
		return true;
	}
	return false;
};

const getKey = (type: TeacherSidebarType) => {
	switch (type) {
		case TeacherSidebarType.classroom:
			return "classroomId";
		case TeacherSidebarType.group:
			return "groupId";
		default:
			return;
	}
};

export const getTeacherSidebarItems = (
	type: TeacherSidebarType,
	id: ObjectId,
	isTestUser: boolean,
	classroom?: Classroom
): ISidebarItem[] => {
	const res: ISidebarItem[] = [];
	const paths = TeacherSidebarItems.find(e => e.type === type)?.paths || [];
	const key = getKey(type)!;

	for (let i = 0; i < paths.length; i++) {
		const element = paths[i];
		const newItem: ISidebarItem = {
			...element,
			href: element.href.replace(`:${key}`, id),
		};
		if (element.dontShowInSidebar) continue;
		let show = true;
		if (element.isAvaliableForOnlyTestUsers && !isTestUser) {
			show = false;
		}
		if (element.notAvailableSchoolIds && classroom) {
			// delete this constraint when gradebook avaliable for all grades
			if (!isLocalhost() && !isTestUser) {
				if (
					!classroom.schoolId ||
					element.notAvailableSchoolIds.includes(classroom.schoolId)
				) {
					show = false;
				}
			}
		}
		if (show) {
			res.push(newItem);
		}
	}
	return res;
};
