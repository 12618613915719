import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import DownIcon from "@material-ui/icons/KeyboardArrowDown";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import React, { useCallback } from "react";
import { useHistory, useWindowSize } from "../../../hooks/front";
import { useBoolean } from "../../../hooks/general";
import { ReactComponent as UserIcon } from "../styles/img/user.svg";
import HeaderStyles from "./styles/header.module.css";
import { PrimaryOptions } from "./options";
import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { ReactComponent as NewIcon } from "../../styles/img/new.svg";
import { useUnseenSpecialCourses } from "@app/hooks/special-courses";
import { useCourseOptionsAsStudent } from "./courses";

export const ResponsiveHeader: React.FC<{
	courseId: ObjectId | null;
	onClose: () => void;
}> = ({ courseId, onClose }) => {
	const history = useHistory();
	const { height } = useWindowSize();
	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {
				//
			});
		history.push(`/`);
		onClose();
	}, [history, onClose]);

	const onCourseSelect = useCallback(
		(courseId: ObjectId) => {
			let suffix = "";
			const regexMatch = /\/courses\/[\d\w]{24}\/(.+?)(\/|$)/.exec(
				history.location.pathname
			); // search for token after courseId
			if (regexMatch) {
				const token = regexMatch[1];
				if (
					["lessons", "assignments", "exercises"].indexOf(token) > -1
				) {
					suffix = "/" + token;
				}
			}
			history.push(`/courses/${courseId}${suffix}`);
			onClose();
		},
		[history, onClose]
	);
	return (
		<div>
			<div
				className={HeaderStyles.userOptionsInvDiv}
				style={{ height: height }}
			>
				<div className={HeaderStyles.userOptions}>
					<div className={HeaderStyles.iconsAndNamesContainer}>
						<div>
							<ResponsiveUserOption courseId={courseId} />
						</div>
						<ResponsiveCourseOptions
							selectedCourseId={courseId || null}
							onCourseSelect={onCourseSelect}
						/>
						<PrimaryOptions courseId={courseId} />
					</div>
					<span onClick={onLogout}>
						<FormattedMessage id="logout" />
					</span>
				</div>
			</div>
		</div>
	);
};

export const ResponsiveCourseOptions: React.FC<{
	selectedCourseId: ObjectId | null;
	onCourseSelect: (courseId: ObjectId) => void;
}> = React.memo(props => {
	const { courseOptions, selectedCourse } = useCourseOptionsAsStudent(
		props.selectedCourseId
	);

	const {
		value: isCourseOptionsVisible,
		switchValue: switchCourseOptionsVisibility,
	} = useBoolean();

	const unseenSpecialCourses = useUnseenSpecialCourses();

	if (!courseOptions) return null; // TODO: show content loader

	return (
		<>
			<div className={HeaderStyles.courseTitleAndOptions}>
				<div
					className={HeaderStyles.courseTitle}
					onClick={
						courseOptions.length > 0
							? switchCourseOptionsVisibility
							: undefined
					}
				>
					{selectedCourse
						? selectedCourse.label
						: getFormattedMessage("chooseCourse")}
					{courseOptions.length > 0 ? (
						!isCourseOptionsVisible ? (
							<div style={{ cursor: "pointer" }}>
								<DownIcon />
							</div>
						) : (
							<div style={{ cursor: "pointer" }}>
								<UpIcon />
							</div>
						)
					) : null}
					{unseenSpecialCourses && unseenSpecialCourses.length > 0 && (
						<div style={{ color: "#5273e6", width: 30 }}>
							<NewIcon />
						</div>
					)}
				</div>
				{isCourseOptionsVisible && (
					<div
						className={HeaderStyles.courseOptionsInvDiv}
						style={{
							display: isCourseOptionsVisible ? "flex" : "none",
						}}
					>
						<div>
							{courseOptions.map(course => (
								<div
									className={HeaderStyles.courseOption}
									key={course.value}
									onClick={() =>
										props.onCourseSelect(course.value)
									}
								>
									{course.label}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
});
//
const ResponsiveUserOption: React.FC<{ courseId: ObjectId | null }> = props => {
	const user = useClassroomsUser();
	const history = useHistory();

	const onGoToProfile = useCallback(() => {
		if (props.courseId) {
			history.push(`/profile?courseId=${props.courseId}`);
		} else {
			history.push(`/profile`);
		}
	}, [history, props.courseId]);

	return (
		<div>
			<div className={HeaderStyles.userIcon}>
				<UserIcon />
			</div>
			<div onClick={onGoToProfile}>
				<div style={{ display: "flex", alignItems: "center" }}>
					{user ? (
						<span>
							{user.firstname} {user.lastname}
						</span>
					) : (
						<span>
							<FormattedMessage id="profile" />
						</span>
					)}

					<EditIcon style={{ fontSize: 15, marginBottom: "4px" }} />
				</div>
			</div>
		</div>
	);
};
