import React, { useState } from "react";
import styles from "./styles/time.module.scss";

interface TimePickerProps {
	className?: string;
	value?: Date;
	onChange: (newDate: Date) => void;
	showSecond?: boolean;
}

export const TimePicker = React.memo<TimePickerProps>(({}) => {
	return null;
});

export const TimeSelector = React.memo<{
	hour: number | null;
	minute: number | null;
	setHour: (newHour: number) => void;
	setMinute: (newMinute: number) => void;
	minuteInterval?: number;
	minHour?: number;
	maxHour?: number;
	isDisabled?: boolean;
}>(
	({
		hour,
		minute,
		setHour,
		setMinute,
		minuteInterval = 5,
		minHour = 0,
		maxHour = 23,
		isDisabled,
	}) => {
		return (
			<>
				<div className={styles.indHour}>
					<SelectorForHourOrMinute
						value={hour}
						label={"საათი"}
						onChange={setHour}
						numOfOptions={maxHour - minHour + 1}
						indexToValueFn={i => i + minHour}
						isDisabled={isDisabled}
					/>
				</div>
				<div className={styles.indMinute}>
					<SelectorForHourOrMinute
						value={minute}
						label={"წუთი"}
						onChange={setMinute}
						numOfOptions={Math.round(60 / minuteInterval)}
						indexToValueFn={i => i * minuteInterval}
						isDisabled={isDisabled}
					/>
				</div>
			</>
		);
	}
);

const SelectorForHourOrMinute: React.FC<{
	value: number | undefined | null;
	label: string;
	onChange: (value: number) => void;
	numOfOptions: number;
	indexToValueFn: (i: number) => number;
	isDisabled?: boolean;
}> = ({
	value,
	label,
	onChange,
	numOfOptions,
	indexToValueFn,
	isDisabled = false,
}) => {
	const [i_d] = useState(() => "" + Math.random());
	return (
		<>
			<label htmlFor={i_d} className={styles.label}>
				{label}
			</label>
			{isDisabled ? (
				<div className={styles.disabledSelect} />
			) : (
				<select
					value={value ?? undefined}
					id={i_d}
					onChange={e => onChange(+e.target.value)}
					className={styles.select}
				>
					{Array(numOfOptions)
						.fill(``)
						.map((_, i) => {
							const currOV = indexToValueFn(i);
							return (
								<option key={i} value={currOV}>
									{currOV > 9 ? currOV : `0${currOV}`}
								</option>
							);
						})}
				</select>
			)}
		</>
	);
};
