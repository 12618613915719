import { UserType } from "@app/api/helper-schemas";
import {
	getCurrentWebsite,
	techDiffForStudents,
	WebsiteOrigin,
} from "@app/globals";
import { useHistory, useWindowSize } from "@app/hooks/front";
import { useClassroomsUser, useStudentInfo } from "@app/hooks/users";
import { inject } from "@app/modules";
import { StudentsTechDiffPage } from "@app/tech-difficulties";
import { css } from "emotion";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BigBackground } from "../backgrounds";
import FancyLoading from "../widgets/fancy-loading";
import HubuExamsMainPage from "./hubu-exams-main-page";
import { StudentInvitationsContainer } from "./invitations/student";
import {
	AllTaskTypeLoader,
	CurrentCourseLoader,
	CurrentLessonLoader,
} from "./main/loaders";
import styles from "./main/styles/students-main-page.module.css";
import { TVSchoolStudentMainPage } from "./subjects/students-subjects";

export const StudentLoadingPage: React.FC<{}> = props => {
	const currentWebsite = getCurrentWebsite();
	const user = useClassroomsUser();
	if (!user) return null;
	if (currentWebsite === WebsiteOrigin.tvSchool) {
		if (techDiffForStudents) {
			return <StudentsTechDiffPage />;
		}
		return <TVSchoolStudentMainPage />;
	} else if (currentWebsite === WebsiteOrigin.hubuExams) {
		return <HubuExamsMainPage />;
	} else {
		return <HubuStudentLoadingPage />;
	}
};

const HubuStudentLoadingPage: React.FC<{}> = props => {
	const [hasCourse, setHasCourse] = useState<false | null>(null);
	const user = useClassroomsUser();
	const history = useHistory();
	const userId = user ? user.id : undefined;
	const studentInfo = useStudentInfo();
	const { width } = useWindowSize();
	const isMobile = width < 600;

	useEffect(() => {
		if (!userId) return;
		let isCancelled = false;
		const UsersController = inject("UsersController");
		UsersController.getMyInfo(UserType.student).then(data => {
			if (isCancelled) return;
			if (data.courseIds.length === 0) {
				setHasCourse(false);
				return;
			}
		});
		return () => {
			isCancelled = true;
		};
	}, [userId]);
	useEffect(() => {
		if (
			studentInfo &&
			studentInfo.courseIds &&
			studentInfo.courseIds.length > 0
		) {
			const firstCourseId = studentInfo.courseIds[0];
			history.replace(`/courses/${firstCourseId}`);
			return;
		}
	}, [history, studentInfo]);
	if (!user) return null;
	if (hasCourse === false) {
		return <CourselessStudent />;
	}
	return (
		<div className={styles.background}>
			<BigBackground />
			<div className={styles.banners}>
				<div className={styles.currentLessonAndCourseContainer}>
					<CurrentCourseLoader isMobile={isMobile} />
					<CurrentLessonLoader isMobile={isMobile} />
				</div>
			</div>
			<AllTaskTypeLoader />
		</div>
	);
};

const CourselessStudent: React.FC<{}> = props => {
	return (
		<div style={{ textAlign: "center" }}>
			<div style={{ textAlign: "left" }}>
				<StudentInvitationsContainer />
			</div>
			<FormattedMessage id="noMatchingCourseIsFound" />
		</div>
	);
};

export const FancyLoadingCenter = () => {
	return (
		<div className={center}>
			<FancyLoading />
		</div>
	);
};

const center = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
