import * as React from "react";
import {
	IFinishPageProps,
	FinishState,
} from "../../../../@tests-core/components/tests";
import { IRTest } from "@app/api/tests/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { MainButton } from "../styles/styledComponents";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { FormattedMessage } from "react-intl";

interface IStandardFinishComponentProps extends IFinishPageProps {
	onGotoNext: () => void;
	onResetTestProgress?: () => void;
	test: IRTest;
	testTypeSettings: ITestTypeSettings;
	currentAttempt: number;
}

export const StandardFinishComponent: React.FC<IStandardFinishComponentProps> = props => {
	const maxNumOfWritingTests =
		typeof props.testTypeSettings.maxNumOfWritingTests !== "number"
			? Infinity
			: props.testTypeSettings.maxNumOfWritingTests;
	if (props.finishState === FinishState.saving) {
		return <FancyLoading />;
	}
	if (props.finishState === FinishState.failed) {
		return (
			<div
				style={{
					padding: 30,
					textAlign: "center",
					fontSize: 20,
					fontFamily: "Roboto Geo Nus",
				}}
			>
				<FormattedMessage id="student:test.errorWhileSavingAndTryAgain" />
				<br />
				<MainButton
					onClick={() => props.retryFinishingTest().catch(() => null)}
				>
					<FormattedMessage id="student:test.trySavingTestAgain" />
				</MainButton>
			</div>
		);
	}

	if (
		props.testTypeSettings.showAnswersAt ===
		TestTypeAnswersShowTime.afterFinish
	) {
		return (
			<div
				style={{
					padding: 30,
					textAlign: "center",
					fontSize: 20,
					fontFamily: "Roboto Geo Nus",
				}}
			>
				<FormattedMessage id="student:test.successfullyinishedTest" />
				<br />
				<MainButton
					onClick={() =>
						props.onPageSelect({
							type: "question",
							index: 0,
						})
					}
					style={{
						background:
							"linear-gradient(to top right, #04a160, #0cda55)",
					}}
				>
					<FormattedMessage id="student:test.ReviewTestCA" />
				</MainButton>
				<br />
				<MainButton onClick={props.onGotoNext}>
					<FormattedMessage id="student:test.testNextCA" />
				</MainButton>
				{maxNumOfWritingTests > props.currentAttempt &&
					props.onResetTestProgress && (
						<>
							<br />
							<br />
							<div>
								<FormattedMessage id="student:test.wannaWriteTestAgain?" />
							</div>
							<MainButton
								onClick={props.onResetTestProgress}
								style={{ marginTop: 10 }}
							>
								<FormattedMessage id="student:test.resetTestCA" />
							</MainButton>
						</>
					)}
			</div>
		);
	}
	return (
		<div
			style={{
				padding: 30,
				textAlign: "center",
				fontSize: 20,
				fontFamily: "Roboto Geo Nus",
			}}
		>
			<FormattedMessage id="student:test.starndardTestFinishText" />
			<br />
			<MainButton onClick={props.onGotoNext}>
				<FormattedMessage id="student:test.testNextCA" />
			</MainButton>{" "}
			<br />
			{maxNumOfWritingTests > props.currentAttempt &&
				props.onResetTestProgress && (
					<>
						<br />
						<FormattedMessage id="student:test.wannaWriteTestAgain?" />
						<br />
						<MainButton onClick={props.onResetTestProgress}>
							<FormattedMessage id="student:test.resetTestCA" />
						</MainButton>
					</>
				)}
		</div>
	);
};

export const ModellingFinishComponent: React.FC<IStandardFinishComponentProps> = props => {
	const maxNumOfWritingTests =
		typeof props.testTypeSettings.maxNumOfWritingTests !== "number"
			? Infinity
			: props.testTypeSettings.maxNumOfWritingTests;
	return (
		<div
			style={{
				padding: 30,
				textAlign: "center",
				fontSize: 20,
				fontFamily: "Roboto Geo Nus",
			}}
		>
			{props.info.hasAssessableQuestion && !props.info.isFullyAssessed
				? "მოდელირება დასრულებულია. ყოვლისმცოდნე დავითი შენს ტესტს შეასწორებს 3 სამუშაო დღის ვადაში."
				: `თქვენ დააგროვეთ ${Math.floor(props.info.totalCredit)} ქულა`}
			<br />
			<MainButton
				onClick={() =>
					props.onPageSelect({
						type: "question",
						index: 0,
					})
				}
				style={{
					background:
						"linear-gradient(to top right, #04a160, #0cda55)",
				}}
			>
				კითხვების გადახედვა
			</MainButton>
			{maxNumOfWritingTests > props.currentAttempt && (
				<>
					<br />
					<br />
					<div>გსურთ ტესტის ახლიდან დაწყება?</div>
					<MainButton
						onClick={props.onResetTestProgress}
						style={{ marginTop: 10 }}
					>
						თავიდან დაწერა
					</MainButton>
				</>
			)}
		</div>
	);
};
