import {
	TransformPropertiesToOtherType,
	ReplaceKeys,
} from "@app/utils/generics";
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
	useLayoutEffect,
} from "react";
import { replaceKeysInArr } from "@app/utils/common";
import { CreateCusto } from "custo";

export function useForceUpdate() {
	const [, set] = useState(0);
	return useCallback(() => {
		set(v => v + 1);
	}, []);
}

export function useBoolean(defaultValue?: boolean) {
	const [value, setValue] = useState(defaultValue || false);
	const setTrue = useCallback(() => {
		setValue(true);
	}, []);
	const setFalse = useCallback(() => {
		setValue(false);
	}, []);
	const switchValue = useCallback(() => {
		setValue(c => !c);
	}, []);
	return { value, setValue, setTrue, setFalse, switchValue } as const;
}

export function useBooleanWithClick(
	defaultValue?: boolean,
	stopPropagation = true
) {
	const [value, setValue] = useState(defaultValue || false);
	const setTrue = useCallback(
		(e?: { stopPropagation: () => void }) => {
			if (stopPropagation && e) {
				e.stopPropagation();
			}
			setValue(true);
		},
		[stopPropagation]
	);
	const setFalse = useCallback(
		(e?: { stopPropagation: () => void }) => {
			if (stopPropagation && e) {
				e.stopPropagation();
			}
			setValue(false);
		},
		[stopPropagation]
	);
	const switchValue = useCallback(
		(e?: { stopPropagation: () => void }) => {
			if (stopPropagation && e) {
				e.stopPropagation();
			}
			setValue(c => !c);
		},
		[stopPropagation]
	);
	return { value, setValue, setTrue, setFalse, switchValue } as const;
}

export const useErrors = <T extends {}>(
	defaultErrors?: Partial<TransformPropertiesToOtherType<T, string>> | null
) => {
	type A = TransformPropertiesToOtherType<T, string>;
	type ErrorType = { [key in keyof A]?: A[key] | null };
	const [errors, setErrors] = useState<ErrorType | null>(
		defaultErrors || null
	);
	type ErrorKeys = keyof NonNullable<typeof errors>;
	const areErrorsEmpty = useRef(!errors);
	areErrorsEmpty.current = !errors;

	const removeErrorMessage = useCallback((keyName: ErrorKeys) => {
		if (!areErrorsEmpty.current) {
			setErrors(e => (!e ? null : { ...e, [keyName]: undefined }));
		}
	}, []);

	const setErrorMessage = useCallback((keyName: ErrorKeys, value: string) => {
		setErrors(e =>
			!e
				? ({ [keyName]: value } as ErrorType)
				: ({ ...e, [keyName]: value } as ErrorType)
		);
	}, []);

	return {
		errors,
		removeErrorMessage,
		setErrorMessage,
		setErrors,
	};
};

export const useUnsafeDynamicRef = <T extends any>(
	value: T
): { readonly current: T } => {
	const ref = useRef(value);
	ref.current = value;
	return ref;
};

export const useDynamicRef = <T extends any>(
	value: T
): { readonly current: T } => {
	const ref = useRef(value);
	useLayoutEffect(() => {
		ref.current = value;
	});
	return ref;
};

export interface IMountingInfo {
	isMounted: boolean;
	isFirstMounting: boolean;
	hasFinishedFirstMountingCycle: boolean;
}
export const useMountingInfo = (): IMountingInfo => {
	const isMounted = useState({
		isMounted: true,
		isFirstMounting: true,
		hasFinishedFirstMountingCycle: false,
	})[0];
	useEffect(() => {
		if (!isMounted.isFirstMounting) {
			isMounted.hasFinishedFirstMountingCycle = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMounted.isFirstMounting]);
	useEffect(() => {
		isMounted.isMounted = true;
		isMounted.isFirstMounting = false;
		setTimeout(() => {
			isMounted.hasFinishedFirstMountingCycle = true;
		}, 0);
		return () => {
			isMounted.isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return isMounted;
};

export const useDynamicValueChange = <T extends {} | null | undefined>(
	onChange: (newVal: (oldValue: T) => T) => void
): (<K extends keyof NonNullable<T>>(
	key: K
) => (
	value:
		| ((oldValue: NonNullable<T>[K]) => NonNullable<T>[K])
		| NonNullable<T>[K]
) => void) => {
	return useCallback(
		<K extends keyof NonNullable<T>>(key: K) => (
			value:
				| NonNullable<T>[K]
				| ((oldValue: NonNullable<T>[K]) => NonNullable<T>[K])
		) => {
			if (typeof value !== "function") {
				onChange(old =>
					old
						? {
								...old,
								[key]: value,
						  }
						: old
				);
			} else {
				onChange(old => {
					if (!old) return old;
					const newVal = (value as any)(
						old[key as any]
					) as NonNullable<T>[K];
					return {
						...old,
						[key]: newVal,
					};
				});
			}
		},
		[onChange]
	);
};

export const useValueChangeWithRef = <T extends {}>(
	onChange: (newVal: T) => void,
	ref: React.MutableRefObject<T>
): (<K extends keyof T>(
	key: K
) => (value: T[K] | ((oldVal: T[K]) => T[K])) => void) => {
	return useCallback(
		<K extends keyof T>(key: K) => (
			value: T[K] | ((oldVal: T[K]) => T[K])
		) => {
			if (typeof value !== "function") {
				onChange({
					...ref.current,
					[key]: value,
				});
			} else {
				onChange({
					...ref.current,
					[key]: (value as any)(ref.current[key]),
				});
			}
		},
		[onChange, ref]
	);
};

export const useFuncCall = <A extends any[], R>(
	func: (...args: A) => R,
	...args: A
): R => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => func(...args), [JSON.stringify(args), func]);
};

export const useFuncDistributor = <Arg2 extends any[], R>(
	fn: (index: number, ...arg2: Arg2) => R,
	num: number
): ((...arg: Arg2) => R)[] => {
	const fnRef = useRef(fn);
	fnRef.current = fn;
	return useMemo(() => {
		return new Array(num).fill(null).map((e, i) => {
			return (...arg2: Arg2) => {
				return fnRef.current(i, ...arg2);
			};
		});
	}, [num]);
};

export const useFuncDistributorByKey = <
	Arg2 extends any[],
	R,
	T extends Record<any, any>,
	K extends keyof T
>(
	fn: (val: T[K], ...arg2: Arg2) => R,
	arr: T[],
	key: K
): ((...arg: Arg2) => R)[] => {
	const num = arr.length;
	const fnRef = useRef(fn);
	fnRef.current = fn;
	const arrRef = useRef(arr);
	arrRef.current = arr;
	return useMemo(() => {
		return new Array(num).fill(null).map((e, i) => {
			return (...arg2: Arg2) => {
				const val = arrRef.current[i][key];
				return fnRef.current(val, ...arg2);
			};
		});
	}, [num, key]);
};

export const useMiniCallback = <Fn extends (...args: any[]) => any>(
	fn: Fn
): Chained<Fn> => {
	const fnRef = useRef<any>();
	fnRef.current = [fn];
	const miniCbRef = useRef<Chained<Fn>>();
	if (!miniCbRef.current) {
		miniCbRef.current = ((...args: any) => {
			const fns = fnRef.current;
			const mainFn = fns[0];
			let returnVal = mainFn(...args);
			for (let i = 1; i < fns.length; i++) {
				const { type, fn: fn2 } = fns[i];
				if (type === "chain") {
					returnVal = fn2(returnVal);
				} else {
					returnVal = fn2();
				}
			}
			return returnVal;
		}) as Chained<Fn>;
		miniCbRef.current.chain = (fn2: any) => {
			fnRef.current.push({ fn: fn2, type: "chain" });
			return miniCbRef.current as any;
		};
		miniCbRef.current.rawChain = (fn2: any) => {
			fnRef.current.push({ fn: fn2, type: "rawChain" });
			return miniCbRef.current as any;
		};
	}
	return miniCbRef.current;
};

type AreSame<T1, T2> = T1 extends T2 ? (T2 extends T1 ? true : false) : false;

type Chained<
	Fn extends (...args: any[]) => any,
	LastFn extends (...args: any[]) => any = Fn
> = {
	chain: <Fn2 extends (arg: ReturnType<LastFn>) => any>(
		fn2: Fn2
	) => Chained<Fn, Fn2>;
	rawChain: <Fn2 extends () => any>(fn2: Fn2) => Chained<Fn, Fn2>;
} & (AreSame<Fn, LastFn> extends true
	? Fn
	: (...args: Parameters<Fn>) => ReturnType<LastFn>);

export const useReplacedKeysInArr = <
	T extends {},
	K extends { [key in keyof Partial<T>]: PropertyKey }
>(
	arr: T[],
	keys: K
): ReplaceKeys<T, K>[] => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => replaceKeysInArr(arr, keys), [
		arr,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		JSON.stringify(keys),
	]);
};

export const toMemoizedHook = <Fn extends (...args: any[]) => any>(
	fn: Fn
): Fn => {
	return ((...args: any[]) => {
		const lastArgsRef = useRef<{ args: any[]; result: any }>();

		if (
			!lastArgsRef.current ||
			!areDepsEqual(args, lastArgsRef.current.args)
		) {
			const result = fn(...args);
			lastArgsRef.current = {
				args,
				result,
			};
		}
		return lastArgsRef.current.result;
	}) as any;
};

const areDepsEqual = <T extends readonly any[]>(
	args1: T,
	args2: T
): boolean => {
	if (args1.length !== args2.length) return false;
	for (let i = 0; i < args1.length; ++i) {
		if (args1[i] !== args2[i]) return false;
	}
	return true;
};

export const toMemoizedCustoHook = <Fn extends (...args: any[]) => any>(
	fn: Fn
) => {
	return CreateCusto.Hook(toMemoizedHook(fn));
};
