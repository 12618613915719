import { RequesteeInfoType } from "@app/api/classrooms/join-requests/helper-schemas";
import { IRPUTSendJoinRequest } from "@app/api/classrooms/join-requests/validators";
import { IUserShortInfo } from "@app/api/users/helper-schemas";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import * as React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useBoolean } from "../../../hooks/general";
import FancyLoading from "../../widgets/fancy-loading";
import invitationStyles from "./styles/index.module.css";

export const TeacherInvitation: React.FC<{}> = () => {
	const [isRegisterClicked, setIsRegisterClicked] = useState(false);
	const [isJoinClicked, setIsJoinClicked] = useState(false);
	const [teacherFirstName, setTeacherFirstName] = useState("");
	const [teacherLastName, setTeacherLastName] = useState("");
	const [teacherMobile, setTeacherMobile] = useState("");
	const [teacherInfo, setTeacherInfo] = useState<IUserShortInfo | null>();
	const [
		requesteeData,
		setRequesteeData,
	] = useState<IRPUTSendJoinRequest | null>(null);
	const { value: hasFoundError, setValue: setHasFoundError } = useBoolean();
	const {
		value: isLoading,
		setTrue: setLoading,
		setFalse: clearLoading,
	} = useBoolean();

	const sendRequest = React.useCallback(() => {
		if (
			teacherFirstName === "" ||
			teacherLastName === "" ||
			teacherMobile === ""
		) {
			openConfirmationPopup({
				text: getFormattedMessage(
					"student:joinClassroomRequest.fillAllBlanks"
				),
			});
			return;
		}
		setIsRegisterClicked(isRegisterClicked ? false : true);

		const UsersController = inject("UsersController");
		setLoading();
		UsersController.getUserByMobile({ mobile: teacherMobile })
			.then(teacherInfo => {
				triggerEvent(
					{
						category: "request",
						action: "fill teacher credentials",
						label: "for joining classroom",
					},
					{ userType: "student" }
				);
				setTeacherInfo(teacherInfo);
				clearLoading();
			})
			.catch(() => {
				setTeacherInfo(null);
				const TeacherRegistrationController = inject(
					"TeacherRegistrationController"
				);
				const ClassroomJoinRequestsController = inject(
					"ClassroomJoinRequestsController"
				);
				Promise.all([
					ClassroomJoinRequestsController.sendJoinRequest({
						requesteeInfo: {
							type: RequesteeInfoType.mobile,
							mobile: teacherMobile,
						},
					}),
					TeacherRegistrationController.create({
						teacherFirstame: teacherFirstName,
						teacherLastname: teacherLastName,
						teacherMobile,
					}),
				])
					.then(() => {
						triggerEvent(
							{
								category: "request",
								action: "send classroom join request",
								label: "to unregistered teacher",
							},
							{ requesterType: "student" }
						);
						clearLoading();
					})
					.catch(e => {
						console.log(e);
						setHasFoundError(true);
						clearLoading();
					});
			});
	}, [
		clearLoading,
		isRegisterClicked,
		setHasFoundError,
		setLoading,
		teacherFirstName,
		teacherLastName,
		teacherMobile,
	]);

	const joinRequest = React.useCallback(() => {
		if (!teacherInfo) return;
		setIsJoinClicked(true);
		const ClassroomJoinRequestsController = inject(
			"ClassroomJoinRequestsController"
		);

		setLoading();
		ClassroomJoinRequestsController.sendJoinRequest({
			requesteeInfo: {
				type: RequesteeInfoType.id,
				id: teacherInfo.id,
			},
		})
			.then((data: IRPUTSendJoinRequest) => {
				triggerEvent(
					{
						category: "request",
						action: "send classroom join request",
						label: "to teacher",
					},
					{ requesterType: "student", success: true }
				);
				setRequesteeData(data);
				clearLoading();
			})
			.catch(e => {
				triggerEvent(
					{
						category: "request",
						action: "send classroom join request",
						label: "to teacher",
					},
					{ requesterType: "student", success: false }
				);
				setHasFoundError(true);
				clearLoading();
			});
	}, [clearLoading, setHasFoundError, setLoading, teacherInfo]);

	return (
		<>
			{hasFoundError ? (
				<div>
					<span>
						<FormattedMessage id="errorAlert" />
					</span>
				</div>
			) : isLoading ? (
				<FancyLoading />
			) : (
				<>
					{!isRegisterClicked && !isJoinClicked && (
						<>
							<div className={invitationStyles.registerPopup}>
								<h3>
									<FormattedMessage id="student:joinClassroomRequest.fillTeachersIinfo" />
								</h3>
								<input
									style={{ padding: "0 10px", marginTop: 10 }}
									value={teacherFirstName}
									placeholder={getFormattedMessage(
										"inputs.firstname"
									)}
									onChange={e =>
										setTeacherFirstName(e.target.value)
									}
								/>
								<br />
								<input
									style={{ padding: "0 10px", marginTop: 10 }}
									value={teacherLastName}
									placeholder={getFormattedMessage(
										"inputs.lastname"
									)}
									onChange={e =>
										setTeacherLastName(e.target.value)
									}
								/>
								<br />
								<input
									style={{ padding: "0 10px", marginTop: 10 }}
									value={teacherMobile}
									placeholder={getFormattedMessage(
										"inputs.mobile"
									)}
									onChange={e =>
										setTeacherMobile(e.target.value)
									}
								/>
								<br />
							</div>
							<div
								className={
									invitationStyles.sendRequestButtonContainer
								}
							>
								<button
									className={
										invitationStyles.sendRequestButton
									}
									onClick={() => sendRequest()}
								>
									<FormattedMessage id="student:joinClassroomRequest.sendRequest" />
								</button>
							</div>
						</>
					)}
					{isRegisterClicked && teacherInfo && !isJoinClicked && (
						<div>
							<span style={{ display: "block", marginTop: 20 }}>
								<FormattedMessage id="student:joinClassroomRequest.teacherIsAlreadyRegistered" />
							</span>
							<span style={{ display: "block", marginTop: 20 }}>
								<FormattedMessage id="inputs.firstname" />:{" "}
								{teacherInfo.firstname}
							</span>
							<span style={{ display: "block", marginTop: 20 }}>
								<FormattedMessage id="inputs.lastname" />:{" "}
								{teacherInfo.lastname}
							</span>
							<button
								className={invitationStyles.sendRequestButton}
								onClick={() => joinRequest()}
							>
								<FormattedMessage id="student:joinClassroomRequest.sendRequest" />
							</button>
						</div>
					)}
					{isRegisterClicked &&
						teacherInfo === null &&
						!isJoinClicked && (
							<div>
								<span>
									<FormattedMessage id="student:joinClassroomRequest.thankYou" />
								</span>
							</div>
						)}
					{teacherInfo !== null &&
						isJoinClicked &&
						requesteeData !== null && (
							<div>
								<span>
									<FormattedMessage id="student:joinClassroomRequest.requestIsSent" />
								</span>
							</div>
						)}
				</>
			)}
		</>
	);
};

export const TeacherInvitationPopup: React.FC<{
	onClose: () => void;
}> = props => {
	return (
		<Popup onClose={props.onClose}>
			<PopupContentWithClose onClose={props.onClose} style={popupStyles}>
				<TeacherInvitation />
			</PopupContentWithClose>
		</Popup>
	);
};

const popupStyles = {
	width: 650,
	maxWidth: "90%",
	display: "block",
};
