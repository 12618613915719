import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { SelectionWithoutCheckbox } from "../../assignments/selections";
import styles from "../../assignments/styles/styles.module.css";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { useBoolean } from "@app/hooks/general";
import { BigActionButton } from "../../assignments/create/second-step";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { useDays, useDaysShort } from "@app/hooks/dates";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { Classroom } from "@app/models/classroom";
import { TimePicker } from "@app/components/widgets/time-picker";
import { iTimeToDate } from "@app/utils/dates";

interface IClassTime {
	start: {
		hour: number;
		minute: number;
	};
	end: {
		hour: number;
		minute: number;
	};
}
interface IWeekDays {
	indexOfTheDay: number;
	isSelected: boolean;
	classTimes: IClassTime[];
}

export const EditClassroomTime: React.FC<{
	classroomId: ObjectId;
	onSave?: () => void;
}> = React.memo(props => {
	const [classroom, setClassroom] = useState<Classroom>();
	const [selectedWeekdays, setSelectedWeekDays] = useState<IWeekDays[]>();
	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();
	useEffect(() => {
		const ClassroomsController = inject("ClassroomsController");
		ClassroomsController.getById({ _id: props.classroomId }).then(
			classroom => {
				setClassroom(classroom);
				setSelectedWeekDays(
					convertClassTimesToWeekDays(classroom.classTimes)
				);
			}
		);
	}, [props.classroomId]);

	const saveClassroom = () => {
		const ClassroomsController = inject("ClassroomsController");
		if (!selectedWeekdays) return;
		startLoading();
		ClassroomsController.update({
			_id: props.classroomId,
			classTimes: convertWeekDaysToClassTimes(selectedWeekdays),
		})
			.then(() => {
				stopLoading();
				if (props.onSave) {
					props.onSave();
				}
			})
			.catch(e => {
				console.log(e);
				stopLoading();
			});
	};

	return (
		<div>
			{!selectedWeekdays || !classroom ? (
				<FancyLoading />
			) : (
				<ClassroomTime
					selectedWeekdays={selectedWeekdays}
					onChange={setSelectedWeekDays}
					classroom={classroom}
				/>
			)}
			<br />
			<BigActionButton
				text={getFormattedMessage("save")}
				onClick={saveClassroom}
				isLoading={isLoading}
			/>
		</div>
	);
});

const weekdays: IWeekDays[] = [];

for (let i = 0; i < 7; i++) {
	weekdays.push({
		isSelected: false,
		indexOfTheDay: i,
		classTimes: [],
	});
}

interface IClassroomTimeProps {
	onChange: (newSelectedWeekdays: IWeekDays[]) => void;
	selectedWeekdays: IWeekDays[];
	classroom: { name: string };
}

const ClassroomTime: React.FC<IClassroomTimeProps> = React.memo(props => {
	const selectedWeekdays = props.selectedWeekdays;
	const { classroom, onChange } = props;
	const daysShort = useDaysShort();

	const onClassTimeAdd = useCallback(
		(indexOfTheDay: number, index: number) => {
			const myDay = selectedWeekdays.find(
				day => day.indexOfTheDay === indexOfTheDay
			);
			if (!myDay) return null;
			myDay.classTimes.push({
				start: {
					hour: 9,
					minute: 0,
				},
				end: {
					hour: 9,
					minute: 40,
				},
			});
			onChange(
				selectedWeekdays.map(day =>
					day.indexOfTheDay === indexOfTheDay ? myDay : day
				)
			);
		},
		[onChange, selectedWeekdays]
	);

	const onCheckboxChange = useCallback(
		(indexOfTheDay: number) => {
			const selectedDay = selectedWeekdays.find(
				day => day.indexOfTheDay === indexOfTheDay
			);
			if (!selectedDay) return null;
			selectedDay.isSelected = !selectedDay.isSelected;
			if (!selectedDay.isSelected) {
				selectedDay.classTimes = [];
			}
			onChange(
				selectedWeekdays.map(day =>
					day.indexOfTheDay === indexOfTheDay ? selectedDay : day
				)
			);
			if (selectedDay.isSelected) {
				onClassTimeAdd(indexOfTheDay, 0);
			}
		},
		[onChange, onClassTimeAdd, selectedWeekdays]
	);

	const onClassTimeChange = useCallback(
		(
			indexOfTheDay: number,
			value: Date,
			option: "start" | "end",
			index: number
		) => {
			const myDay = selectedWeekdays.find(
				day => day.indexOfTheDay === indexOfTheDay
			);
			if (!myDay) return null;

			const oldClassTime = myDay.classTimes.find(
				(classTime, i) => i === index
			);
			if (!oldClassTime) return null;

			const myClassTime = { ...oldClassTime };
			myClassTime.start = { ...myClassTime.start };
			myClassTime.end = { ...myClassTime.end };

			myClassTime[option].hour = value.getHours();
			myClassTime[option].minute = value.getMinutes();
			if (option === "start") {
				myClassTime.end.minute = myClassTime.start.minute + 40;
				if (myClassTime.end.minute >= 60) {
					myClassTime.end.hour =
						myClassTime.start.hour +
						Math.floor(myClassTime.end.minute / 60);
					myClassTime.end.minute %= 60;
				} else {
					myClassTime.end.hour = myClassTime.start.hour;
				}
			}
			const newMyDay = {
				...myDay,
				classTimes: myDay.classTimes.map((classTime, i) =>
					i === index ? myClassTime : classTime
				),
			};
			onChange(
				selectedWeekdays.map(day =>
					day.indexOfTheDay === indexOfTheDay ? newMyDay : day
				)
			);
		},
		[onChange, selectedWeekdays]
	);

	const onClassTimeDelete = useCallback(
		(indexOfTheDay: number, index: number) => {
			const myDay = selectedWeekdays.find(
				day => day.indexOfTheDay === indexOfTheDay
			);
			if (!myDay) return null;

			myDay.classTimes = myDay.classTimes.filter(
				(classTime, i) => i !== index
			);

			if (myDay.classTimes.length < 1) {
				myDay.isSelected = false;
			}
			onChange(
				selectedWeekdays.map(day =>
					day.indexOfTheDay === indexOfTheDay ? myDay! : day
				)
			);
		},
		[onChange, selectedWeekdays]
	);

	const selectedWeekdaysFromMonday = useMemo(() => {
		if (selectedWeekdays.length === 0) return selectedWeekdays;
		const arr = selectedWeekdays.slice(1, selectedWeekdays.length);
		arr.push(selectedWeekdays[0]);
		return arr;
	}, [selectedWeekdays]);

	return (
		<div className={styles.weekdaysContainer}>
			<div style={{ fontSize: 20, color: "#5c5de8", fontWeight: 600 }}>
				{/* <FormattedMessage id="teacher:addSchedulePopupTitle" />
				<br /> */}
				{classroom.name}
			</div>
			<div className={styles.weekdaysDiv}>
				{selectedWeekdaysFromMonday.map(day => (
					<SelectionWithoutCheckbox
						key={day.indexOfTheDay}
						id={day.indexOfTheDay}
						isSelected={day.isSelected}
						name={daysShort[day.indexOfTheDay]}
						onChange={() => onCheckboxChange(day.indexOfTheDay)}
						containerClassName={styles.weekDaySelection}
						isSelectedClassname={classNames(
							styles.isSelectedClassname,
							styles.weekDaySelection
						)}
					/>
				))}
			</div>
			<div className={styles.scheduleTimes}>
				{selectedWeekdaysFromMonday.map(day => {
					if (day.isSelected && day.classTimes.length > 0) {
						return (
							<ClassTimes
								day={day}
								onTimeChange={onClassTimeChange}
								key={day.indexOfTheDay}
								onClassTimeAdd={onClassTimeAdd}
								onClassTimeDelete={onClassTimeDelete}
							/>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
});

interface IClassTimesProp {
	day: IWeekDays;
	onTimeChange: (
		_id: number,
		value: Date,
		option: string,
		index: number
	) => void;
	onClassTimeAdd: (_id: number, index: number) => void;
	onClassTimeDelete: (indexOfTheDay: number, index: number) => void;
}

const ClassTimes: React.FC<IClassTimesProp> = React.memo(props => {
	const days = useDays();
	return (
		<div className={styles.classTimes}>
			<div
				className={classNames(
					styles.classTimesDay,
					styles.checkboxText,
					styles.taskTypes
				)}
			>
				{days[props.day.indexOfTheDay]}
			</div>
			<div className={styles.classTimesTimes}>
				<div className={styles.timeStyles3}>
					<div className={styles.timeStyles4}>
						<FormattedMessage id="start" />
					</div>
					<div className={styles.timeStyles4}>
						<FormattedMessage id="end" />
					</div>
				</div>
				{props.day.classTimes.map((classTime, index) => (
					<Times
						key={index}
						classTime={classTime}
						indexOfTheDay={props.day.indexOfTheDay}
						onTimeChange={props.onTimeChange}
						onClassTimeDelete={props.onClassTimeDelete}
						index={index}
					/>
				))}
			</div>
			<div style={{ flex: 1, position: "relative" }}>
				<Add
					className={styles.classTimesAdd}
					onClick={() =>
						props.onClassTimeAdd(
							props.day.indexOfTheDay,
							props.day.classTimes.length
						)
					}
				/>
			</div>
		</div>
	);
});

interface ITimesProps {
	isDefaultValueVisible?: boolean;
	classTime: IClassTime;
	indexOfTheDay: number;
	index: number;
	onTimeChange: (
		indexOfTheDay: number,
		value: Date,
		option: string,
		index: number
	) => void;
	onClassTimeDelete: (indexOfTheDay: number, index: number) => void;
}

const Times: React.FC<ITimesProps> = React.memo(props => {
	return (
		<div className={styles.timeStyles3}>
			<TimePicker
				className={classNames(styles.timeStyles2, styles.classroom)}
				value={
					props.classTime
						? iTimeToDate(props.classTime.start)
						: undefined
				}
				onChange={date => {
					props.onTimeChange(
						props.indexOfTheDay,
						date,
						"start",
						props.index
					);
				}}
				showSecond={false}
			/>
			<TimePicker
				className={classNames(styles.timeStyles2, styles.classroom)}
				value={
					props.classTime
						? iTimeToDate(props.classTime.end)
						: undefined
				}
				onChange={date =>
					props.onTimeChange(
						props.indexOfTheDay,
						date,
						"end",
						props.index
					)
				}
				showSecond={false}
			/>
			<Close
				className={styles.classTimesDelete}
				onClick={() =>
					props.onClassTimeDelete(props.indexOfTheDay, props.index)
				}
			/>
		</div>
	);
});

const convertClassTimesToWeekDays = (
	weekDays: IClassroom["classTimes"]
): IWeekDays[] => {
	const daysArr: IClassroom["classTimes"][] = [[], [], [], [], [], [], []];
	weekDays.forEach(weekday => daysArr[weekday.day].push(weekday));
	const newDaysArr: IWeekDays[] = [];

	for (let i = 0; i < daysArr.length; i++) {
		const day = daysArr[i];
		if (day.length > 0) {
			const newDay: IWeekDays = {
				isSelected: true,
				indexOfTheDay: i,
				classTimes: [],
			};

			newDay.classTimes = day.map(day => {
				return { start: day.start, end: day.end };
			});
			newDaysArr.push(newDay);
		} else {
			newDaysArr.push({
				isSelected: false,
				indexOfTheDay: i,
				classTimes: [],
			});
		}
	}
	return newDaysArr;
};

const convertWeekDaysToClassTimes = (selectedWeekdays: IWeekDays[]) => {
	const classTimes: {
		day: number;
		start: { hour: number; minute: number };
		end: { hour: number; minute: number };
	}[] = [];

	for (const day of selectedWeekdays) {
		for (const time of day.classTimes) {
			classTimes.push({
				day: day.indexOfTheDay,
				start: time.start,
				end: time.end,
			});
		}
	}
	return classTimes;
};
