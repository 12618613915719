import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";
import { useMemo } from "react";
import { useClassroomByCourseId } from "./classrooms";
import { useCourseInfo } from "./courses";
import { useWholeCourseSkeleton } from "./folders";
import { useUserCourseFolderProgresses } from "./user-folder-progress";
import { useCoursesUserId } from "./users";

interface FolderInfo {
	folder: IRFolder;
	parentFolderId: ObjectId;
	progress: number;
}

interface CurrentLessonInfo {
	currentLesson?: FolderInfo;
	previousLesson?: FolderInfo;
	nextLesson?: FolderInfo;
}

const emptyResults: CurrentLessonInfo = {
	currentLesson: undefined,
	nextLesson: undefined,
	previousLesson: undefined,
};

// eslint-disable-next-line max-lines-per-function
export function useCurrentLessonInfo({
	courseId,
	folderId,
	depth,
}: {
	courseId: ObjectId;
	folderId?: ObjectId;
	depth?: number;
}): CurrentLessonInfo {
	const coursesUserId = useCoursesUserId();
	const courseInfo = useCourseInfo(courseId);
	const classroom = useClassroomByCourseId(courseId);
	const lessonRootFolderId = classroom.isIdentificationKnown
		? classroom.isSuccessfullyLoaded
			? classroom.doc.course.customFolderIds.lessons
			: null
		: courseInfo.isSuccessfullyLoaded
		? courseInfo.doc.rootFolder
		: null;
	if (!folderId) {
		folderId = lessonRootFolderId || undefined;
	}
	const skeleton = useWholeCourseSkeleton({
		courseId,
		folderId,
		depth,
	});

	const docsByFolderIds = useUserCourseFolderProgresses(
		coursesUserId || null,
		courseId
	);

	const sortedFolders = useMemo(() => {
		if (!folderId || !skeleton) return undefined;
		return sortFolders(folderId, skeleton.folders).filter(e =>
			doesContainProgressableExceptFolder(e.folder)
		); // filter out folders that have only folder items
	}, [folderId, skeleton]);

	if (
		!coursesUserId ||
		!folderId ||
		!courseInfo ||
		!courseId ||
		!skeleton ||
		!sortedFolders ||
		!docsByFolderIds
	) {
		return emptyResults;
	}

	let maxUpdateDate = -Infinity;
	let lastWrittenFolder: FolderInfo | undefined;
	let firstUndoneFolder: FolderInfo | undefined;
	let lastWrittenFolderIndex = -1;
	const threshold = 5000;
	for (let i = 0; i < sortedFolders.length; ++i) {
		const folderInfo = sortedFolders[i];
		const { folder, parentFolderId } = folderInfo;
		if (!parentFolderId) continue;
		const folderId = folder._id;

		const doc = docsByFolderIds[folderId];
		if (doc) {
			const updatedAt =
				typeof doc.updatedAt === "string"
					? new Date(doc.updatedAt)
					: doc.updatedAt;
			if (updatedAt.getTime() > maxUpdateDate + threshold) {
				maxUpdateDate = updatedAt.getTime();
				lastWrittenFolder = {
					folder,
					parentFolderId,
					progress: doc.progress,
				};
				lastWrittenFolderIndex = i;
			}
		}
		if (!firstUndoneFolder && (!doc || doc.progress < 1)) {
			firstUndoneFolder = {
				folder,
				parentFolderId,
				progress: doc ? doc.progress : 0,
			};
		}
	}

	let currentLesson: FolderInfo | undefined = undefined;
	if (lastWrittenFolder && lastWrittenFolder.progress < 1) {
		currentLesson = lastWrittenFolder;
	} else if (lastWrittenFolder) {
		// search for next;
		let nextUndoneFolder: FolderInfo | undefined = undefined;
		for (
			let i = lastWrittenFolderIndex + 1;
			i < sortedFolders.length;
			++i
		) {
			const folderInfo = sortedFolders[i];
			const { folder, parentFolderId } = folderInfo;
			if (!parentFolderId) continue;
			const folderId = folder._id;
			const doc = docsByFolderIds[folderId];
			if (doc) {
				if (doc.progress === 1) continue;
			}
			nextUndoneFolder = {
				folder,
				parentFolderId,
				progress: doc ? doc.progress : 0,
			};
			break;
		}
		currentLesson = nextUndoneFolder || lastWrittenFolder;
	}
	if (!currentLesson) {
		currentLesson = firstUndoneFolder;
	}
	if (!currentLesson) {
		return emptyResults;
	}
	const currentFolderIndex = sortedFolders.findIndex(
		e => e.folder._id === currentLesson!.folder._id
	);
	if (currentFolderIndex === -1) {
		return emptyResults;
	}
	const nextFolders = [
		...sortedFolders.slice(currentFolderIndex + 1),
		...sortedFolders.slice(0, currentFolderIndex),
	];
	let nextLesson: FolderInfo | undefined = undefined;
	for (const folderInfo of nextFolders) {
		const { folder, parentFolderId } = folderInfo;
		if (!parentFolderId) continue;
		const doc = docsByFolderIds[folder._id];
		if (!doc || doc.progress < 1) {
			nextLesson = {
				folder,
				parentFolderId,
				progress: doc ? doc.progress : 0,
			};
			break;
		}
	}
	let previousLesson: FolderInfo | undefined = undefined;
	maxUpdateDate = -Infinity;
	for (const folderInfo of nextFolders) {
		const { folder, parentFolderId } = folderInfo;
		if (!parentFolderId) continue;
		if (currentLesson && currentLesson.folder._id === folder._id) {
			continue;
		}
		if (nextLesson && nextLesson.folder._id === folder._id) {
			continue;
		}
		const doc = docsByFolderIds[folder._id];
		if (!doc) continue;

		const updatedAt =
			typeof doc.updatedAt === "string"
				? new Date(doc.updatedAt)
				: doc.updatedAt;
		if (updatedAt.getTime() > maxUpdateDate + threshold) {
			if (folder.items) {
				const subFolders = folder.items.filter(
					e => e.type === ItemType.folder && !e.isHidden
				);
				if (subFolders.length > 0) continue; // leave only low level folders
			}

			maxUpdateDate = updatedAt.getTime();
			previousLesson = {
				folder,
				parentFolderId,
				progress: doc.progress,
			};
		}
	}
	return {
		currentLesson,
		nextLesson,
		previousLesson,
	};
}

const doesContainProgressableExceptFolder = (folder: IRFolder) => {
	if (!folder.items) return false;
	for (const item of folder.items) {
		if (item.isHidden || item.type === ItemType.folder) continue;
		if (item.type === ItemType.test || item.type === ItemType.file) {
			return true;
		}
	}
	return false;
};

const sortFolders = (
	folderId: ObjectId,
	folders: Record<string, IRFolder | undefined>
): { folder: IRFolder; parentFolderId: ObjectId | null }[] => {
	const arr: { folder: IRFolder; parentFolderId: ObjectId | null }[] = [];
	traverseFolders(folderId, null, folders, arr);
	return arr;
};

const traverseFolders = (
	folderId: ObjectId,
	parentFolderId: ObjectId | null,
	folders: Record<string, IRFolder | undefined>,
	arr: { folder: IRFolder; parentFolderId: ObjectId | null }[]
): void => {
	const folder = folders[folderId];
	if (!folder) return;
	if (folder.items) {
		for (const item of folder.items) {
			if (item.type !== ItemType.folder) continue;
			traverseFolders(item.id, folderId, folders, arr);
		}
	}
	arr.push({ folder, parentFolderId });
};
