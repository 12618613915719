import * as React from "react";
import fullCircularStyles from "./styles/circular-progress.module.css";
import uuid from "uuid";
import { useState, useEffect } from "react";

export interface IFullCircularProgressProps {
	progress: number;
	progressStrokeColor: string | [string, string];
	size?: number;
	strokeWidth?: number;
	progressStrokeWidth?: number;
	endCircleRadius?: number;
	endCircleStrokeWidth?: number;
	radius?: number;
	animateTiming?: number;
	strokeColor?: string;
	progressContainerClassname?: string;
	innerFontSize?: string;
}

export const FullCircularProgress: React.FC<IFullCircularProgressProps> = props => {
	const [isProgressShown, setIsProgressShown] = useState(
		!props.animateTiming ? true : false
	);
	useEffect(() => {
		if (!props.animateTiming) return;
		let isCancelled = false;
		setTimeout(() => {
			if (isCancelled) return;
			setIsProgressShown(true);
		}, 0);
		return () => {
			isCancelled = true;
		};
	}, [props.animateTiming]);
	const gradientRef = React.useRef(uuid());
	const strokeWidth = props.strokeWidth === undefined ? 3 : props.strokeWidth;
	const backgroundStrokeWidth =
		props.progressStrokeWidth === undefined ? 3 : props.progressStrokeWidth;
	const width = props.size === undefined ? 140 : props.size;
	const height = props.size === undefined ? 140 : props.size;
	const radius =
		props.radius === undefined
			? width * 0.4 - strokeWidth / 2
			: props.radius;

	const circleLength = 2 * radius * Math.PI;
	const progressLength = circleLength * (1 - props.progress / 100);
	const deg = (props.progress / 100) * 2 * Math.PI;
	const endCircleRadius =
		props.endCircleRadius === undefined ? 4 : props.endCircleRadius;
	const endCircleStrokeWidth =
		props.endCircleStrokeWidth === undefined
			? 4
			: props.endCircleStrokeWidth;

	const rotated = rotateAroundPoint(width / 2, height / 2, deg, {
		x: width / 2 + radius,
		y: height / 2,
	});

	return (
		<div
			className={
				props.progressContainerClassname
					? props.progressContainerClassname
					: fullCircularStyles.progressContainer
			}
		>
			<svg
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				className={fullCircularStyles.progress}
			>
				{Array.isArray(props.progressStrokeColor) && (
					<defs>
						<linearGradient
							id={gradientRef.current}
							x1="0"
							y1="0"
							x2="0"
							gradientUnits="userSpaceOnUse"
							y2={height}
						>
							<stop
								offset="0"
								style={{
									stopColor: props.progressStrokeColor[0],
									stopOpacity: 1,
								}}
							/>
							<stop
								offset="1"
								style={{
									stopColor: props.progressStrokeColor[1],
									stopOpacity: 1,
								}}
							/>
						</linearGradient>
					</defs>
				)}
				<circle
					cx={width / 2}
					cy={height / 2}
					r={radius}
					fill="none"
					stroke={props.strokeColor ? props.strokeColor : "#dcdcdc"}
					strokeWidth={strokeWidth}
				/>
				<circle
					cx={width / 2}
					cy={height / 2}
					r={radius}
					fill="none"
					stroke={
						typeof props.progressStrokeColor === "string"
							? props.progressStrokeColor
							: `url(#${gradientRef.current})`
					}
					strokeWidth={backgroundStrokeWidth}
					strokeDasharray={circleLength}
					strokeDashoffset={progressLength}
					strokeLinecap="round"
					style={{
						transition: props.animateTiming
							? props.animateTiming / 1000 + "s"
							: "0s",
						strokeDashoffset: !isProgressShown
							? circleLength + "px"
							: progressLength + "px",
					}}
				/>
				<circle
					cx={width / 2 + radius}
					cy={height / 2}
					r={endCircleRadius}
					fill={
						typeof props.progressStrokeColor === "string"
							? props.progressStrokeColor
							: `url(#${gradientRef.current})`
					}
				/>
				<circle
					cx={rotated.x}
					cy={rotated.y}
					r={endCircleRadius}
					stroke={
						typeof props.progressStrokeColor === "string"
							? props.progressStrokeColor
							: `url(#${gradientRef.current})`
					}
					strokeWidth={endCircleStrokeWidth}
					fill="white"
				/>
			</svg>
			<div
				className={fullCircularStyles.innerBody}
				style={{
					width: 2 * radius - strokeWidth,
					height: 2 * radius - strokeWidth,
					top: height / 2 + strokeWidth / 2 - radius,
					left: width / 2 + strokeWidth / 2 - radius,
				}}
			>
				{props.children}
			</div>
		</div>
	);
};

interface POINT {
	x: number;
	y: number;
}

const rotateAroundPoint = (
	cx: number,
	cy: number,
	angle: number,
	p: POINT
): POINT => {
	return {
		x: Math.cos(angle) * (p.x - cx) - Math.sin(angle) * (p.y - cy) + cx,
		y: Math.sin(angle) * (p.x - cx) + Math.cos(angle) * (p.y - cy) + cy,
	};
};
