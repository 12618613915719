import { useState, useEffect, useCallback } from "react";
import { inject } from "@app/modules";
import { ICity } from "@app/api/cities/helper-schemas";
import { useFetch } from "./fetch3";

export const useCitiesAndSchools = (country: string | null) => {
	const [cities, setCities] = useState<{ value: number; label: string }[]>();
	const [schools, setSchools] = useState<
		{
			value: number;
			cityId: number;
			label: string;
		}[]
	>();

	useEffect(() => {
		const CitiesController = inject("CitiesController");
		const SchoolsController = inject("SchoolsController");
		CitiesController.getAll({ country }).then(cities => {
			setCities(
				cities.map(city => ({ value: city.id, label: city.name }))
			);
		});
		SchoolsController.getAll().then(schools => {
			setSchools(
				schools.map(school => ({
					value: school.id,
					label: school.name,
					cityId: school.city_id,
				}))
			);
		});
	}, [country]);
	return !cities || !schools
		? { cities: undefined, schools: undefined }
		: { cities, schools };
};

export const useOnlyCities = (country: string | null) => {
	const [cities, setCities] = useState<ICity[]>();
	useEffect(() => {
		const CitiesController = inject("CitiesController");
		CitiesController.getAll({ country }).then(cities => {
			setCities(cities);
		});
	}, [country]);
	return cities;
};

export const useGetCityById = (country: string | null) => {
	const cities = useOnlyCities(country);
	const getCityById = useCallback(
		(id: number | undefined) => {
			return id ? cities?.find(city => city.id === id) : undefined;
		},
		[cities]
	);
	return getCityById;
};

export const useOnlySchools = (country: string | null) => {
	const [schools, setSchools] = useState<
		{
			value: number;
			cityId: number;
			label: string;
		}[]
	>();

	useEffect(() => {
		const SchoolsController = inject("SchoolsController");
		SchoolsController.getAll().then(schools => {
			setSchools(
				schools.map(school => ({
					value: school.id,
					label: school.name,
					cityId: school.city_id,
				}))
			);
		});
	}, [country]);

	return schools;
};

export const useManySchoolsByIds = (ids: number[] | null) => {
	const SchoolsController = inject("SchoolsController");
	return useFetch(() => SchoolsController.getManyByIds({ ids: ids! }), [ids]);
};

export const useSchoolById = (id: number | null | undefined) =>
	useFetch(() => inject("SchoolsController").getById(id!), [id]);
