export const builtInCategoryIds = {
	nl: {
		BASISSCHOOL: 1,
		MIDDELBARESCHOOL: 2,
	},
};

export const isCertificateServicesAvaliable = false;
export const isFacebookPopupDisabled = true;

export interface ISpeciality {
	id: number;
	name: string;
}
export const SPECIALITIES: ISpeciality[] = [
	{
		id: 1,
		name: "ბიზნესის ადმინისტრირება",
	},
	{
		id: 2,
		name: "ფსიქოლოგია",
	},
	{
		id: 3,
		name: "ტურიზმი",
	},
	{
		id: 4,
		name: "სამართალი",
	},
	{
		id: 5,
		name: "საერთაშორისო ურთიერთობები",
	},
	{
		id: 6,
		name: "ჟურნალისტიკა",
	},
	{
		id: 7,
		name: "ინგლისური ფილოლოგია",
	},
	{
		id: 8,
		name: "ეკონომიკა",
	},
	{
		id: 9,
		name: "სამედიცინო",
	},
	{
		id: 10,
		name: "IT ტექნოლოგიები",
	},
	{
		id: 0,
		name: "სხვა",
	},
];
