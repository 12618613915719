import { IRUser, IRCoursesUser } from "@app/api/users/helper-schemas";
import {
	LOAD_USER,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
	USERDATA_CHANGE,
} from "@app/redux/action-types";
import { UserActions } from "@app/redux/actions/user";
import {
	defaultSpecialItemNameOfOtherTabsActions,
	defaultSpecialActionKeyOfOtherTabsActions,
} from "m-model-common";

function getNewUserState(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	switch (action.type) {
		case USER_AUTHORIZATION_SUCCESS:
		case USERDATA_CHANGE:
			return {
				...state,
				loggedIn: true,
				userId: action.userData ? action.userData.id : state.userId!,
				coursesUserId: action.coursesUserData
					? action.coursesUserData.id
					: state.coursesUserId!,
				userData: action.userData ? action.userData : state.userData!,
				coursesUserData: action.coursesUserData
					? action.coursesUserData
					: state.coursesUserData!,
			};

		case LOAD_USER:
			return {
				...state,
				...(action.user as any),
			};
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function user(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	const newState = getNewUserState(state, action);

	if (
		newState !== state &&
		typeof (action as any)[defaultSpecialActionKeyOfOtherTabsActions] ===
			"undefined"
	) {
		localStorage.setItem("user", JSON.stringify(newState));
		localStorage.setItem(
			defaultSpecialItemNameOfOtherTabsActions,
			JSON.stringify({
				...action,
				[defaultSpecialActionKeyOfOtherTabsActions]: Math.random(),
			})
		);
	}
	return newState;
}

export interface IUnauthentificatedUser {
	loggedIn?: false;
	userId?: undefined;
	coursesUserId?: undefined;
	userData?: undefined;
	coursesUserData?: undefined;
}

export interface IAuthentificatedUser {
	loggedIn: true;
	userId: number;
	coursesUserId: number;
	userData: IRUser;
	coursesUserData: IRCoursesUser;
}

export type IStateUser = IUnauthentificatedUser | IAuthentificatedUser;
