import { useWindowSize } from "@app/hooks/front";
import { useBoolean } from "@app/hooks/general";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { DivReference } from ".";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { LandingButton } from "./buttons";
import { LoginPopup } from "./login";
import { RegisterPopup } from "./register-type-selection";
import teacher from "./styles/img/teacher.png";
import styles from "./styles/landing-main-page.module.css";
import { UserType } from "@app/api/helper-schemas";

const responsiveWidth = 1050;

export const TeacherDiv: React.FC<{
	containerRef?: DivReference;
	//TODO: Fix this later
	// eslint-disable-next-line max-lines-per-function
}> = props => {
	const { width } = useWindowSize();

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);

	return (
		<div className={styles.infoDivs} ref={props.containerRef}>
			{width > responsiveWidth && (
				<div className={styles.teacherDiv}>
					<div className={styles.teacherSVGDiv}>
						<img src={teacher} alt="Teacher" width="370px" />
					</div>
				</div>
			)}
			{width > responsiveWidth ? (
				<div className={styles.teacherInfoDiv}>
					<div className={styles.displaySVG}>
						<h2>
							<FormattedMessage id="teacher" />
							<div
								className={styles.headerUnderline}
								style={{ width: 210 }}
							></div>
						</h2>
						<div
							className={styles.bulletsContainer}
							style={{ width: 476 }}
						>
							<FormattedMessage id="landing:registerTitle" />
							<ul>
								<li>
									<FormattedMessage id="landing:teacher.firstSentence" />
								</li>
								<li>
									<FormattedMessage id="landing:teacher.secondSentence" />
								</li>
								{/* <li>
									<FormattedMessage id="landing:teacher.thirdSentence" />
								</li> */}
							</ul>
						</div>
					</div>
					<div className={styles.registerAndLoginButtons}>
						<LandingButton onClick={openLogin}>
							<FormattedMessage id="signIn" />
						</LandingButton>
						<LandingButton onClick={openRegisterPage}>
							<FormattedMessage id="registration" />
						</LandingButton>
					</div>
				</div>
			) : (
				<div className={styles.infoDivsText}>
					<h2>
						<FormattedMessage id="teacher" />
						<div className={styles.headerUnderline}></div>
					</h2>
					<div className={styles.bulletsContainer}>
						<FormattedMessage id="landing:registerTitle" />
						<ul>
							<li>
								<FormattedMessage id="landing:teacher.firstSentence" />
							</li>
							<li>
								<FormattedMessage id="landing:teacher.secondSentence" />
							</li>
							<li>
								<FormattedMessage id="landing:teacher.thirdSentence" />
							</li>
						</ul>
					</div>
					<div className={styles.registerAndLoginButtons}>
						<LandingButton onClick={openLogin}>
							<FormattedMessage id="signIn" />
						</LandingButton>
						<LandingButton onClick={openRegisterPage}>
							<FormattedMessage id="registration" />
						</LandingButton>
					</div>
				</div>
			)}

			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
					userType={UserType.teacher}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
				/>
			)}
		</div>
	);
};
