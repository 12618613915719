import { useClassroom } from "@app/hooks/classrooms";
import { ObjectId } from "@app/utils/generics";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import classNames from "classnames";
import React, { useCallback, useContext, useMemo } from "react";
import "react-day-picker/lib/style.css";
import Select from "react-select";
import { StylesConfig } from "react-select";
import { ClassroomsContext, HomeworkContext } from "../";
import styles from "../../styles/styles.module.css";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";

export const ClassroomGroup: React.FC<{ classroomId: ObjectId }> = React.memo(
	props => {
		const [homework, dispatch] = useContext(HomeworkContext);
		const classrooms = useContext(ClassroomsContext);
		const mainClassroom = useClassroom(props.classroomId);

		const onChange = useCallback(
			(selectedOptions: { value: ObjectId; label: string }[]) => {
				dispatch({
					type: "CHANGE_SELECTED_CLASSROOMS",
					classroomIds: selectedOptions.map(e => e.value),
				});
			},
			[dispatch]
		);

		const customStyles: StylesConfig = {
			option: (provided, state) => ({
				...provided,
				padding: "3px 0px",
				fontSize: 13,
			}),
			container: (provided, state) => ({
				...provided,
				width: "max-content",
				maxWidth: 250,
			}),
			control: () => ({
				border: "none",
				backgroundColor: "white",
				borderRadius: 8,
				marginLeft: "5px",
				fontSize: 13,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minWidth: 121,
			}),
			singleValue: (provided, state) => {
				const opacity = state.isDisabled ? 0.5 : 1;
				const transition = "opacity 300ms";

				return { ...provided, opacity, transition };
			},
			multiValue: (provided, state) => {
				return {
					...provided,
					alignItems: "center",
					justifyContent: "space-between",
					width: 80,
					margin: "0px 2px 0px 0px",
					fontSize: 13,
				};
			},
			indicatorSeparator: (provided, state) => {
				return { display: "none", padding: 0, margin: 0 };
			},
			placeholder: (provided, state) => {
				return {
					...provided,
					left: "0%",
					fontFamily: "Roboto Geo Nus",
				};
			},
			dropdownIndicator: (provided, state) => {
				return {
					...provided,
					color: "#4d3737",
					margin: 0,
				};
			},
			indicatorsContainer: (provided, state) => {
				return {
					...provided,
					padding: 0,
					margin: 0,
				};
			},
		};

		const classroomOptions = useMemo((): readonly {
			value: string;
			label: string;
		}[] => {
			if (!classrooms || !mainClassroom.isSuccessfullyLoaded) return [];
			return classrooms
				.filter(
					classroom => classroom.grade === mainClassroom.doc.grade
				)
				.map(classroom => {
					return { value: classroom._id, label: classroom.name };
				});
		}, [classrooms, mainClassroom]);

		const selectedClassrooms = useMemo(() => {
			return classroomOptions.filter(
				({ value: classroomId }) => !!homework.classrooms[classroomId]
			);
		}, [classroomOptions, homework.classrooms]);

		if (!mainClassroom.isSuccessfullyLoaded) {
			return null;
		}

		const ArrowDown = useCallback(
			() => (
				<HOComponent>
					<div>
						<ArrowDownIcon />
					</div>
				</HOComponent>
			),
			[]
		);

		return (
			<div className={classNames(styles.groups, styles.textStyles2)}>
				<div className={styles.groupsTitleAndCurrentClassroom}>
					<div className={styles.groupsTitle}>
						<FormattedMessage id="teacher:classrooms" />
					</div>
				</div>

				<Select
					options={classroomOptions}
					onChange={onChange}
					styles={customStyles}
					isMulti={true}
					value={selectedClassrooms}
					components={{
						DropdownIndicator: ArrowDown,
						ClearIndicator: undefined,
					}}
					closeMenuOnSelect={false}
					placeholder={getFormattedMessage("choose")}
					noOptionsMessage={() =>
						getFormattedMessage("teacher:allClassroomsChosen")
					}
				/>
			</div>
		);
	}
);

const HOComponent: React.FC<any> = props => {
	return <>{props.children}</>;
};
