import * as React from "react";
import SvgLoading from "../styles/img/loading";

const Loading = props => (
	<div style={{ textAlign: "center" }}>
		<SvgLoading />
	</div>
);

export default Loading;
