import React, { useState, useCallback } from "react";
import { ObjectId } from "@app/utils/generics";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { MobileInput } from "@app/components/auth/input-forms";
import CloseIcon from "@material-ui/icons/Close";
import PlusIcon from "@material-ui/icons/Add";
import styles from "./styles/index.module.css";
import { useWindowSize } from "@app/hooks/front";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
import { inject } from "@app/modules";
import { RequesteeInfoType } from "@app/api/classrooms/join-requests/helper-schemas";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { useBoolean } from "@app/hooks/general";

interface IInvitationsOfStudentsByTeacherProps {
	classroomId: ObjectId;
	onClose: () => void;
}

export const InvitationsOfStudentsByTeacher: React.FC<IInvitationsOfStudentsByTeacherProps> = props => {
	const {
		value: isInvitationsSuccessfull,
		setTrue: setInvitationsSuccessfull,
		setFalse: setInvitationsUnsuccesfull,
	} = useBoolean(false);

	const {
		value: isInputsVissible,
		setTrue: setInputsVissible,
		setFalse: HideInputsVissible,
	} = useBoolean(true);

	const [mobiles, setMobiles] = useState<{ id: number; value: string }[]>([
		{ id: 1, value: "" },
		{ id: 2, value: "" },
		{ id: 3, value: "" },
	]);

	const onMobileChange = useCallback(
		(id: number, e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			setMobiles(mobiles =>
				mobiles.map(mobile =>
					mobile.id !== id ? mobile : { ...mobile, value }
				)
			);
		},
		[]
	);

	const onMobileAdd = useCallback(() => {
		setMobiles(mobiles => [
			...mobiles,
			{ id: Math.max(...mobiles.map(m => m.id), 0) + 1, value: "" },
		]);
	}, []);

	const onMobileDelete = useCallback((id: number) => {
		setMobiles(mobiles => mobiles.filter(mobile => mobile.id !== id));
	}, []);
	const { width } = useWindowSize();

	const [areInvitationsSubmitting, setAreInvitationsSubmitting] = useState(
		false
	);

	const { onClose } = props;

	const handleSubmitInviations = useCallback(() => {
		setAreInvitationsSubmitting(true);
		const ClassroomJoinRequestsController = inject(
			"ClassroomJoinRequestsController"
		);

		const invitations = mobiles
			.filter(mobile => mobile.value !== "")
			.map(mobile => ({
				requesteeInfo: {
					type: RequesteeInfoType.mobile,
					mobile: mobile.value,
				} as const,
				classroomId: props.classroomId,
			}));
		ClassroomJoinRequestsController.sendMany(invitations)
			.then(() => {
				HideInputsVissible();
				setInvitationsSuccessfull();
				setTimeout(() => {
					onClose();
					setInvitationsUnsuccesfull();
					setInputsVissible();
				}, 2000);
			})
			.catch(() => {
				setAreInvitationsSubmitting(false);
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	}, [
		HideInputsVissible,
		mobiles,
		onClose,
		props.classroomId,
		setInputsVissible,
		setInvitationsSuccessfull,
		setInvitationsUnsuccesfull,
	]);

	return (
		<Popup onClose={props.onClose}>
			<PopupContentWithClose onClose={props.onClose}>
				<div className={styles.inviteStudentsInPopup}>
					<FormattedMessage id="teacher:inviteStudents" />
				</div>
				{isInputsVissible && (
					<div className={styles.inputContainerInPopup}>
						{mobiles.map((mobile, i) => (
							<div key={mobile.id}>
								<MobileInput
									placeholder={
										width > 430
											? getFormattedMessage(
													"teacher:studentMobilePlaceholder"
											  )
											: getFormattedMessage(
													"teacher:studentMobilePlaceholder"
											  )
									}
									onChange={e => onMobileChange(mobile.id, e)}
									isDisabled={
										areInvitationsSubmitting ? true : false
									}
									icon={
										<CloseIcon
											className={
												styles.popupStudentsDeleteIcon
											}
											onClick={() =>
												onMobileDelete(mobile.id)
											}
										/>
									}
									className={styles.inviteStudentsInput}
								/>
							</div>
						))}
						<button
							onClick={onMobileAdd}
							className={styles.addStudentsInPopupButton}
						>
							<FormattedMessage id="add" />{" "}
							<PlusIcon
								style={{
									width: 20,
									height: 20,
									marginLeft: 10,
								}}
							/>
						</button>
						<button
							className={styles.doneAddingStudentsPopupButton}
							onClick={handleSubmitInviations}
						>
							<FormattedMessage id="formCompleteCTA" />
							{areInvitationsSubmitting && (
								<SvgloadingWhite
									style={{
										width: 20,
										height: 20,
										marginLeft: 10,
									}}
								/>
							)}
						</button>
					</div>
				)}
				{isInvitationsSuccessfull && !isInputsVissible && (
					<div className={styles.successfullInviation}>
						<FormattedMessage id="successfullInviation" />
					</div>
				)}
			</PopupContentWithClose>
		</Popup>
	);
};
