import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const ConsultationRequestsSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	mobile: Joi.string()
		.required()
		.allow(null),
	firstName: Joi.string().required(),
	lastName: Joi.string().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IConsultationRequests {
	_id: ObjectId;
	userId: number;
	mobile: string | null;
	firstName: string;
	lastName: string;
	createdAt: Date;
	updatedAt: Date;
}
