import { DefaultPopupPrioritizer } from "@app/components/widgets/prioritizer";
import { useTeacherAssignmentsCount } from "@app/hooks/assignments";
import { inject } from "@app/modules";
import React, { useEffect, useState } from "react";
import { MainTextPopup } from "../../certificates/certificate-candidates";
import { useLocale } from "@app/hooks/intl";
import { isCertificateServicesAvaliable } from "@app/consts";

export const TeacherTVSchoolPopups = React.memo(() => {
	const locale = useLocale();
	return (
		<>
			{locale === "ka" && isCertificateServicesAvaliable && (
				<MainTextPopupWrapper />
			)}
		</>
	);
});

const MainTextPopupWrapper = React.memo(() => {
	const assignmentsCount = useTeacherAssignmentsCount();
	const [
		showTeacherStudentCandidatePopup,
		setTeacherStudentCandidatePopup,
	] = useState<boolean | null>(null);
	const count = assignmentsCount.doc?.count || 0;

	useEffect(() => {
		let isCancelled = false;
		const UserActionsController = inject("UserActionsController");

		UserActionsController.getUserAction().then(doc => {
			if (isCancelled) return;
			if (doc.actions.seenStCertificatePopup2) {
				setTeacherStudentCandidatePopup(false);
			} else {
				setTeacherStudentCandidatePopup(true);
			}
		});
		return () => {
			isCancelled = true;
		};
	}, [setTeacherStudentCandidatePopup]);

	const shouldRender = !!showTeacherStudentCandidatePopup && count >= 3;
	return (
		<DefaultPopupPrioritizer enabled={shouldRender} priority={300}>
			<MainTextPopup
				onClose={() => setTeacherStudentCandidatePopup(false)}
			/>
		</DefaultPopupPrioritizer>
	);
});
