import { BasicDocument } from "../interface";
import { markKeysOptional } from "../helper-schemas";
import { ObjectId, OptionalKeys } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { TimeSchema, ITime } from "../classrooms/helper-schemas-2";

export enum AssignmentEdgeType {
	classroom = "CLASSROOM",
	student = "STUDENT",
}
export const AssignmentEdgeTypes = [
	AssignmentEdgeType.classroom,
	AssignmentEdgeType.student,
];

export enum ShowAnswersStandardTime {
	deadline = "DEADLINE",
	submission = "SUBMIT",
	never = "NEVER",
	always = "ALWAYS",
}
export const ShowAnswersStandardTimeKeys = [
	ShowAnswersStandardTime.deadline,
	ShowAnswersStandardTime.submission,
	ShowAnswersStandardTime.never,
	ShowAnswersStandardTime.always,
];

export const AssignmentSettingsSchema = Joi.object({
	deadline: Joi.date(),
	shuffleQuestions: Joi.boolean(),
	shuffleAnswers: Joi.boolean(),
	timer: TimeSchema,
	availableFrom: Joi.date(),
	showAnswersAt: Joi.date()
		.allow(...ShowAnswersStandardTimeKeys)
		.required(),
	hasToBeWrittenInOneSession: Joi.boolean(),
});
export interface IAssignmentSettings {
	showAnswersAt: Date | ShowAnswersStandardTime;
	deadline?: Date;
	timer?: ITime;
	availableFrom?: Date;
	shuffleQuestions?: boolean;
	shuffleAnswers?: boolean;
	hasToBeWrittenInOneSession?: boolean;
}

export const AssignmentSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	classroomIds: Joi.array()
		.items(Joi.objectId().required())
		.allow(null),
	testId: Joi.objectId().required(),
	numQuestions: Joi.number()
		.integer()
		.required(),
	author: Joi.number().integer(),
	settings: AssignmentSettingsSchema.required(),
	code: Joi.string().allow(null),
	originalCourseId: Joi.objectId(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
	subjects: Joi.array().items(Joi.objectId()),
	grade: Joi.number().integer(),
	publicTestId: Joi.number().optional(),
	feedBackId: Joi.objectId().optional(),
	archived: Joi.boolean(),
	isManuallyClosed: Joi.boolean(),
});
export interface IAssignment extends BasicDocument {
	name: string;
	classroomIds?: ObjectId[] | null;
	testId: ObjectId;
	numQuestions: number;
	author?: number;
	code?: string | null;
	originalCourseId?: ObjectId;
	settings: IAssignmentSettings;
	grade?: number;
	subjects?: ObjectId[];
	archived?: boolean;
	publicTestId?: number;
	feedBackId?: ObjectId;
	isManuallyClosed?: boolean;
}
/* used for user_written_assignments collection */

export const QuestionInfoSchema = Joi.object({
	credit: Joi.number().required(),
	maxCredit: Joi.number().required(),
	timeSpent: Joi.number().required(),
	answeredAt: Joi.date().required(),
	hasGradableItem: Joi.boolean().empty(false),
	completedAnyGradableItem: Joi.boolean().empty(false),
});
export interface IQuestionInfo {
	credit: number;
	maxCredit: number;
	timeSpent: number;
	answeredAt: Date;
}

export const AnsweredQuestionsInfoSchema = Joi.object().pattern(
	/^[a-f\d]{24}$/i,
	QuestionInfoSchema
);
export interface IAnsweredQuestionsInfo {
	[x: string]:
		| {
				credit: number;
				maxCredit: number;
				timeSpent: number;
				answeredAt: Date;
				hasGradableItem?: boolean;
				completedAnyGradableItem?: boolean;
		  }
		| undefined;
}

export const AssignmentWriterCredentialsSchema = Joi.object({
	name: Joi.string().required(),
	mobile: Joi.string(),
});
export interface IAssignmentWriterCredentials {
	name: string;
	mobile?: string;
}

export const FileShortInfoSchema = Joi.object({
	fileName: Joi.string().required(),
	fileUrl: Joi.string().required(),
});

export interface IFileShortInfo {
	fileName: string;
	fileUrl: string;
}

export const TeacherFeedbackSchema = Joi.object({
	text: Joi.string().allow("", null),
	hasStudentSeen: Joi.boolean(),
	attachedFiles: Joi.array().items(FileShortInfoSchema),
});

export interface ITeacherFeedback {
	text: string;
	hasStudentSeen?: boolean;
	attachedFiles?: IFileShortInfo[];
}

export const UserWrittenAssignmentSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number().optional(),
	userCredentials: AssignmentWriterCredentialsSchema.optional(),
	assignmentId: Joi.objectId().required(),
	userTestId: Joi.objectId(),
	answeredQuestions: AnsweredQuestionsInfoSchema.required(),
	numOfQuestions: Joi.number().required(),
	startedAt: Joi.date().required(),
	finishedAt: Joi.date(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
	hasTeacherReviewed: Joi.boolean(),
	canBeRewritten: Joi.boolean(),
	teacherFeedBack: TeacherFeedbackSchema,
});
export interface IUserWrittenAssignment extends BasicDocument {
	userId?: number;
	userCredentials?: IAssignmentWriterCredentials;
	assignmentId: ObjectId;
	userTestId: ObjectId;
	numOfQuestions: number;
	answeredQuestions: IAnsweredQuestionsInfo;
	startedAt: Date;
	finishedAt?: Date;
	hasTeacherReviewed?: boolean;
	canBeRewritten?: boolean;
	teacherFeedBack?: ITeacherFeedback;
}

export const RUserWrittenAssignmentSchema = UserWrittenAssignmentSchema.keys({
	answeredQuestions: Joi.object()
		.pattern(
			/^[a-f\d]{24}$/i,
			QuestionInfoSchema.fork("credit", markKeysOptional)
		)
		.required(),
});

/** User credit may be removed from answered questions */
export type IRUserWrittenAssignment = Omit<
	IUserWrittenAssignment,
	"answeredQuestions"
> & {
	answeredQuestions: Record<
		string,
		OptionalKeys<IQuestionInfo, "credit"> | undefined
	>;
};

///

export const AnsweredQuestionSchema = Joi.object({
	id: Joi.objectId().required(),
	userAnswer: Joi.any().required(), // validate schema in other services
	numAttempts: Joi.number().required(),
	answeredAt: Joi.date().required(),
	timeSpent: Joi.number().required(),
	hasGradableItem: Joi.boolean().empty(false),
	completedAnyGradableItem: Joi.boolean().empty(false),
});
export interface IAnsweredQuestion {
	id: ObjectId;
	numAttempts: number;
	answeredAt: Date;
	timeSpent: number;
	userAnswer: any;
	hasGradableItem?: boolean;
	completedAnyGradableItem?: boolean;
}

export const CorrectedQuestionSchema = Joi.object({
	id: Joi.objectId().required(),
	credit: Joi.number().required(),
	maxCredit: Joi.number().required(),
});
export interface ICorrectedQuestion {
	id: ObjectId;
	credit: number;
	maxCredit: number;
}

/* assignment edge schemas */

export const ClassroomEdgeInfoSchema = Joi.object({
	type: Joi.string()
		.valid(AssignmentEdgeType.classroom)
		.required(),
	classroomId: Joi.objectId().required(),
});
export interface IClassroomEdgeInfo {
	type: AssignmentEdgeType.classroom;
	classroomId: ObjectId;
}

export const StudentEdgeInfoNotStartedSchema = Joi.object({
	type: Joi.string()
		.valid(AssignmentEdgeType.student)
		.required(),
	studentId: Joi.number().required(),
	hasStarted: Joi.boolean().valid(false),
	isSubmitted: Joi.boolean().valid(false),
	// FIXME: add required classroomId
});
export interface IStudentEdgeInfoNotStarted {
	type: AssignmentEdgeType.student;
	studentId: number;
	hasStarted?: false;
	isSubmitted?: false;
	classroomId?: ObjectId[]; // TODO: make required after migration
}

const StudentEdgeInfoStartedSchema = Joi.object({
	type: Joi.string()
		.valid(AssignmentEdgeType.student)
		.required(),
	studentId: Joi.number().required(),
	questions: Joi.array()
		.items(Joi.objectId())
		.required(),
	hasStarted: Joi.boolean()
		.valid(true)
		.required(),
	isSubmitted: Joi.boolean(),
	// FIXME: add required classroomId
});
export interface IStudentEdgeInfoStarted {
	type: AssignmentEdgeType.student;
	studentId: number;
	hasStarted: true;
	questions: ObjectId[];
	isSubmitted?: boolean;
	classroomId?: ObjectId[]; // TODO: make required after migration
}

export const StudentEdgeInfoSchema = Joi.alternatives(
	StudentEdgeInfoNotStartedSchema,
	StudentEdgeInfoStartedSchema
).byKey("hasStarted");
export type IStudentEdgeInfo =
	| IStudentEdgeInfoNotStarted
	| IStudentEdgeInfoStarted;

export type IAssignmentEdgeInfo = IStudentEdgeInfo | IClassroomEdgeInfo;

export const AssignmentEdgeSchema = Joi.object({
	_id: Joi.objectId().required(),
	assignmentId: Joi.objectId().required(),
	info: Joi.alternatives(
		ClassroomEdgeInfoSchema,
		StudentEdgeInfoSchema
	).required(),
	settings: AssignmentSettingsSchema,
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IAssignmentEdge extends BasicDocument {
	assignmentId: ObjectId;
	info: IAssignmentEdgeInfo;
	settings?: IAssignmentSettings;
}

///

export const ClassroomAssignmentEdgeSchema = AssignmentEdgeSchema.keys({
	info: ClassroomEdgeInfoSchema.required(),
});
export type IClassroomAssignmentEdge = Omit<IAssignmentEdge, "info"> & {
	info: IClassroomEdgeInfo;
};

export const StudentAssignmentEdgeSchema = AssignmentEdgeSchema.keys({
	info: StudentEdgeInfoSchema.required(),
});
export type IStudentAssignmentEdge = Omit<IAssignmentEdge, "info"> & {
	info: IStudentEdgeInfo;
};

export const StartedStudentAssignmentEdgeSchema = AssignmentEdgeSchema.keys({
	info: StudentEdgeInfoStartedSchema.required(),
});
export type IStartedStudentAssignmentEdge = Omit<IAssignmentEdge, "info"> & {
	info: IStudentEdgeInfoStarted;
};

///

export const CustomSettingsSchema = Joi.array().items(
	Joi.alternatives(
		Joi.object({
			type: Joi.string()
				.valid(AssignmentEdgeType.classroom)
				.required(),
			id: Joi.objectId().required(),
			settings: AssignmentSettingsSchema.required(),
		}),
		Joi.object({
			type: Joi.string()
				.valid(AssignmentEdgeType.student)
				.required(),
			id: Joi.number().required(),
			settings: AssignmentSettingsSchema.required(),
		})
	)
);
export type ICustomSettings = (
	| {
			type: AssignmentEdgeType.classroom;
			id: ObjectId;
			settings: IAssignmentSettings;
	  }
	| {
			type: AssignmentEdgeType.student;
			id: number;
			settings: IAssignmentSettings;
	  }
)[];

///
export const NumQuestionsSettingsSchema = Joi.object({
	total: Joi.number()
		.integer()
		.greater(0),
	byTaskTypes: Joi.object().pattern(
		/^[a-f\d]{24}$/i,
		Joi.alternatives(
			Joi.object({
				questions: Joi.number().required(),
			}),
			Joi.object({
				texts: Joi.number().required(),
			})
		)
	),
});
/** Used as an argument for fetching questions */
export interface INumQuestionsSettings {
	total?: number;
	byTaskTypes?:
		| {
				[taskType: string]:
					| {
							questions: number;
					  }
					| {
							texts: number;
					  }
					| undefined;
		  }
		| undefined;
}
