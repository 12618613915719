import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import React, { useCallback } from "react";
import profileStyles from "./styles/profile.module.css";
import { JSXElement } from "@babel/types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styles from "../teachers/assignments/styles/homework-list.module.css";
import { useWindowSize } from "@app/hooks/front";

interface IGoBackButtonProps {
	onClick?: () => void;
	className?: string;
	icon?: JSXElement;
	text?: string;
	classNameContainer?: string;
	isTextInvissible?: boolean;
	windowWidthTresholdToHide?: number;
}

export const GoBackButton: React.FC<IGoBackButtonProps> = React.memo(
	({ onClick, windowWidthTresholdToHide, ...props }) => {
		const history = useHistory();

		const onGotoBack = useCallback(() => {
			if (onClick) onClick();
			else {
				history.goBack();
			}
		}, [onClick, history]);

		const { width } = useWindowSize();

		if (windowWidthTresholdToHide && width < windowWidthTresholdToHide) {
			return null;
		}

		return (
			<div
				className={
					(props.classNameContainer ||
						profileStyles.exitButtonContainer) + " no-print"
				}
				onClick={onGotoBack}
			>
				<button
					className={
						props.className
							? props.className
							: profileStyles.exitButton
					}
				>
					<ArrowLeftIcon style={{ fontSize: 30 }} />
				</button>
				{!props.isTextInvissible && (
					<div>
						{props.text || <FormattedMessage id="returnBack" />}
					</div>
				)}
			</div>
		);
	}
);

export const TVSchoolGoBack: React.FC<IGoBackButtonProps> = React.memo(
	props => {
		return (
			<GoBackButton
				classNameContainer={styles.goBackButtonForTvschoolContainer}
				className={styles.goBackButtonForTvschool}
				{...props}
			/>
		);
	}
);
