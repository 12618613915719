import { useScrolledEnough, useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";
import { css, cx } from "emotion";
import React, { useMemo, useRef } from "react";
import Helmet from "react-helmet";
import { IStudentRegisterFirstStepProps } from "../auth/student-register-page";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { WaveWithPatterns } from "../backgrounds";
import { BottomPageBanner, ResponsiveFooter } from "./footer";
import { HeaderWithLogInAndRegister } from "./header";
import { ProjectDiv, ProjectDivEnVersion } from "./project";
import { StudentDiv } from "./student";
import styles from "./styles/landing-main-page.module.css";
import { TeacherDiv } from "./teacher";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { TVSchoolLanding } from "../tv-school-landing";

export type DivReference = React.MutableRefObject<HTMLDivElement | null>;

const headerScrollTreshold = 170;

const LandingMainPage: React.FC<{}> = () => {
	const currentWebsite = getCurrentWebsite();
	const isTvSchool = currentWebsite === WebsiteOrigin.tvSchool;
	return <>{isTvSchool ? <TVSchoolLanding /> : <HubuLandingMainPage />}</>;
};

const HubuLandingMainPage: React.FC<{}> = () => {
	const studentRef = useRef<HTMLDivElement | null>(null);
	const teacherRef = useRef<HTMLDivElement | null>(null);
	const schoolRef = useRef<HTMLDivElement | null>(null);
	const local = useLocale();
	const isScrolledEnough = useScrolledEnough(headerScrollTreshold);

	const refs = useMemo(
		() => ({
			studentRef,
			teacherRef,
			schoolRef,
		}),
		[]
	);
	const { width } = useWindowSize();

	return (
		<>
			<MetaInformation />
			<PaleBackground />
			<HeaderWithLogInAndRegister
				backgroundColor={
					local === "en"
						? isScrolledEnough
							? "rgba(82, 115, 230)"
							: "transparent"
						: undefined
				}
			/>

			{local === "en" && <WaveWithPatterns />}
			<div className={styles.landingContainer}>
				<div className={styles.mainDiv}>
					{local === "ka" ? (
						<ProjectDiv refs={refs} />
					) : (
						<ProjectDivEnVersion refs={refs} />
					)}

					{local === "ka" && (
						<>
							<StudentDiv containerRef={studentRef} />
							{/* <TeacherDiv containerRef={teacherRef} /> */}
							{/* <SchoolDiv containerRef={schoolRef} /> */}
						</>
					)}
					{local === "en" && (
						<>
							<TeacherDiv containerRef={teacherRef} />
							<StudentDiv containerRef={studentRef} />
						</>
					)}
				</div>
				{width > 700 ? <BottomPageBanner /> : <ResponsiveFooter />}
			</div>
		</>
	);
};

const MetaInformation: React.FC = React.memo(() => {
	const web = getCurrentWebsite();
	return (
		<Helmet>
			<title>{getFormattedMessage("metaInformation.title-" + web)}</title>
		</Helmet>
	);
});

export default LandingMainPage;

export const PaleBackground = React.memo(
	({ bgColor = "rgb(249, 247, 255)" }: { bgColor?: string }) => (
		<Helmet
			bodyAttributes={{
				style: `background-color: ${bgColor};` as any,
			}}
		/>
	)
);

export const registrationClassNames: NonNullable<IStudentRegisterFirstStepProps["classNames"]> = {
	button: css`
		background: #e84c89;
	`,
	container: css``,
	input: cx(
		StudentRegisterStyles.input,
		css`
			background: #e7e7e7;
		`
	),
};
