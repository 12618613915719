import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import styles from "../../styles/styles.module.css";
import { FormattedMessage } from "react-intl";
import { useWindowSize } from "@app/hooks/front";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

interface ITotalNumOfQuestionsSliderProps {
	value: number;
	onChange: (value: number) => void;
}

export const TotalNumOfQuestionsSlider: React.FC<
	ITotalNumOfQuestionsSliderProps
> = React.memo(props => {
	const { width } = useWindowSize();
	return (
		<div
			className={styles.container}
			style={width > 550 ? { width: "76%" } : { width: "88%" }}
		>
			<div className={styles.numOfQuestionsText}>
				<FormattedMessage id="teacher:assignment.numOfQuestions" />
			</div>
			<QuestionsCounter value={props.value} onChange={props.onChange} />
		</div>
	);
});

const QuestionsCounter: React.FC<{
	value: number;
	onChange: (value: number) => void;
}> = props => {
	return (
		<div className={styles.questionsCounterContainer}>
			<div
				className={styles.MinusButton}
				onClick={e => props.onChange(props.value - 1)}
			>
				<MinusIcon style={{ fontSize: 20 }} />
			</div>
			<input
				className={styles.numQuestionsInputContainer}
				onChange={e => props.onChange(+e.target.value)}
				value={props.value}
			/>

			<div
				className={styles.plusButton}
				onClick={e => props.onChange(props.value + 1)}
			>
				<AddIcon style={{ fontSize: 20 }} />
			</div>
		</div>
	);
};
