import { ObjectId } from "@app/utils/generics";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { useHistory } from "../../../hooks/front";
import HomeworkStyles from "../styles/homework.module.css";
import styles from "./styles/students-main-page.module.css";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { getFormattedMessage } from "@app/utils/locale";

export const AllTaskTypeLoader: React.FC<{}> = () => {
	return (
		<div className={styles.circularBars}>
			{new Array(6).fill(0).map((e, i) => (
				<div className={styles.task} key={i}>
					<TaskTypeLoader />
				</div>
			))}
		</div>
	);
};

const TaskTypeLoader: React.FC<{}> = () => {
	return (
		<div className={styles.taskNameContainer}>
			<div style={{ padding: 10 }}>
				<ContentLoader
					style={{ width: 120, height: 120 }}
					width={120}
					height={120}
				>
					<circle
						cx={60}
						cy={60}
						r={60}
						stroke={"transparent"}
						strokeWidth={0}
						fill="white"
					/>
				</ContentLoader>
			</div>
			<div className={styles.taskName}>...</div>
		</div>
	);
};

export const CurrentHomeworkLoader: React.FC<{
	courseId?: ObjectId;
	isMobile?: boolean;
}> = React.memo(props => {
	const history = useHistory();
	return (
		<div className={styles.currentHomeworkMainContainer}>
			<div className={styles.currentHomework}>
				<div className={styles.homeworkInnerBody}>
					<div className={styles.homeworkName}>
						<ContentLoader
							height={20}
							style={
								props.isMobile ? { width: 100 } : { width: 170 }
							}
							width={160}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width="150"
								height="20"
							/>
						</ContentLoader>
					</div>
					<div className={styles.progressContainer}>
						<div style={{ width: "100%" }}>
							<ContentLoader
								height={20}
								style={
									props.isMobile
										? { height: 10 }
										: { height: 20 }
								}
								width={140}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="5"
									width={props.isMobile ? 140 : 100}
									height={props.isMobile ? 10 : 20}
								/>
							</ContentLoader>
						</div>

						<ContentLoader
							height={20}
							style={
								props.isMobile ? { height: 20 } : { height: 30 }
							}
							width={130}
						>
							<rect
								x="0"
								y="0"
								rx="5"
								ry="5"
								width={70}
								height={20}
							/>
						</ContentLoader>
					</div>
					<div className={styles.currentHomeworkText}>
						<div>
							<ContentLoader
								height={20}
								style={{ width: 120, height: 20 }}
								width={130}
							>
								<rect
									x="0"
									y="0"
									rx="5"
									ry="5"
									width="100"
									height="20"
								/>
							</ContentLoader>
						</div>
						<div className={styles.homeworkCircle}></div>
						<div
							className={styles.buttonAllInCircle}
							style={{
								justifyContent: "flex-end",
								alignItems: "center",
							}}
							onClick={() => {
								history.push(
									`/courses/${props.courseId}/assignments`
								);
							}}
						>
							<FormattedMessage id="seeAll" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export const CurrentLessonLoader: React.FC<{
	courseId?: ObjectId;
	isMobile?: boolean;
}> = props => {
	const history = useHistory();

	return (
		<div className={styles.currentLesson}>
			<div className={styles.currentLessonText}>
				<div className={HomeworkStyles.currentLessonName}>
					<FormattedMessage id="student:currentLesson" />
				</div>
				<div className={HomeworkStyles.topicName}>
					<ContentLoader
						height={40}
						style={{ width: "100%" }}
						width={300}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="250"
							height="18"
						/>
						<rect
							x="0"
							y="23"
							rx="5"
							ry="5"
							width="210"
							height="18"
						/>
					</ContentLoader>
				</div>
				<div>
					<ContentLoader
						height={41}
						style={props.isMobile ? { width: 100 } : { width: 174 }}
						width={178}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="178"
							height="41"
						/>
					</ContentLoader>
				</div>
				<div
					className={styles.buttonAll}
					style={{
						justifyContent: "flex-start",
						alignItems: "center",
					}}
					onClick={() => {
						if (!props.courseId) return;
						history.push(`/courses/${props.courseId}/lessons`);
					}}
				>
					<FormattedMessage id="seeAll" />
					<ArrowRightIcon />
				</div>
			</div>

			<div>
				<ContentLoader
					style={
						props.isMobile
							? { width: 90, height: 90 }
							: { width: 194, height: 194 }
					}
					width={194}
					height={194}
				>
					<circle
						cx={97}
						cy={97}
						r={97}
						stroke={"transparent"}
						strokeWidth={0}
						fill="white"
					/>
				</ContentLoader>
			</div>
		</div>
	);
};

export const CurrentCourseLoader: React.FC<{ isMobile?: boolean }> = props => {
	return (
		<div className={styles.currentLesson}>
			<div
				className={classNames(
					styles.currentCourseText,
					styles.currentLessonText
				)}
			>
				<div
					className={classNames(
						styles.currentCourseName,
						HomeworkStyles.currentLessonName
					)}
				>
					<FormattedMessage id="currentCourse" />
				</div>

				<div
					className={classNames(
						styles.currentTopicName,
						HomeworkStyles.topicName
					)}
				>
					<ContentLoader
						height={40}
						style={{ width: "100%" }}
						width={300}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="250"
							height="18"
						/>
						<rect
							x="0"
							y="23"
							rx="5"
							ry="5"
							width="210"
							height="18"
						/>
					</ContentLoader>
				</div>
				<div>
					<ContentLoader
						height={41}
						style={props.isMobile ? { width: 100 } : { width: 174 }}
						width={178}
					>
						<rect
							x="0"
							y="0"
							rx="5"
							ry="5"
							width="178"
							height="41"
						/>
					</ContentLoader>
				</div>

				<div
					className={styles.buttonAll}
					style={{
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<FormattedMessage id="changeCourse" />
					<ArrowRightIcon />
				</div>
			</div>
			<div className={styles.levelsConainer}>
				<div className={styles.progressBarAndButton}>
					<ContentLoader
						width={194}
						height={194}
						style={
							props.isMobile
								? { width: 90, height: 90 }
								: { width: 194, height: 194 }
						}
					>
						<circle
							cx={97}
							cy={97}
							r={97}
							stroke={"transparent"}
							strokeWidth={0}
							fill="white"
						/>
					</ContentLoader>
				</div>
			</div>
		</div>
	);
};
