import { addBasePath, withParams } from "@app/routes/link-utils";
import { ObjectId } from "@app/utils/generics";

export const adminBasePath = "";

export const adminLinks = addBasePath(adminBasePath)({
	mainPage: "",
	classrooms: {
		bulkDelete: "bulk-delete-classrooms",
		updateSchools: "update-classroom-schools",
		importClassTimes: "import-class-times",
		premium: "classrooms/premium",
		import: "import-classrooms",
	},
	videoLessons: {
		import: "import-links",
	},
	cognitiveAssignments: {
		list: "cognitive-assignments",
		stats: "cognitive-assignments/stats",
	},
	complexAssignments: {
		utils: "complex-assignment-utils",
		create: "complex-assignments/create",
		review: {
			list: "complex-assignments/review",
			courseTests: withParams(
				(args: { courseId: ObjectId }) =>
					`complex-assignments/review/${args.courseId}`,
				`complex-assignments/review/:courseId`
			),
			verifiedCourseTests: withParams(
				(args: { courseId: ObjectId }) =>
					`complex-assignments/review/${args.courseId}/verified`,
				`complex-assignments/review/:courseId/verified`
			),
			test: withParams(
				(args: {
					courseId: ObjectId;
					publicTestId: number;
					complexAssignmentId: ObjectId;
					authorId: number;
				}) =>
					`complex-assignments/review/${args.courseId}/${args.publicTestId}/${args.complexAssignmentId}/${args.authorId}`,
				`complex-assignments/review/:courseId/:publicTestId/:complexAssignmentId/:authorId`
			),
		},
		results: {
			list: "complex-assignments/results",
			create: "complex-assignments/results/add",
			edit: withParams(
				(resultsGroupId: ObjectId) =>
					`complex-assignments/results/${resultsGroupId}/edit`,
				`complex-assignments/results/:resultsGroupId/edit`
			),
		},
		targetConcepts: {
			list: "complex-assignments/target-concepts",
			create: "complex-assignments/target-concepts/add",
			edit: withParams(
				(targetConceptId: ObjectId) =>
					`complex-assignments/target-concepts/${targetConceptId}/edit`,
				`complex-assignments/target-concepts/:targetConceptId/edit`
			),
		},
		topics: {
			list: "complex-assignments/topics",
			create: "complex-assignments/topics/add",
			edit: withParams(
				(topicId: ObjectId) =>
					`complex-assignments/topics/${topicId}/edit`,
				`complex-assignments/topics/:topicId/edit`
			),
		},
	},
	courses: {
		list: "courses",
		create: "courses/add",
		edit: withParams(
			(courseId: ObjectId) => `courses/${courseId}/edit`,
			`courses/:courseId/edit`
		),
	},
	feedbacks: {
		list: "feedbacks/list",
		create: "feedbacks/create",
		edit: withParams(
			(_id: ObjectId) => `feedbacks/${_id}/edit`,
			`feedbacks/:_id/edit`
		),
	},
	gradeCategories: {
		list: "grade-categories",
		crate: "grade-categories/add",
		edit: withParams(
			(categoryId: ObjectId) => `grade-categories/${categoryId}/edit`,
			`grade-categories/:categoryId/edit`
		),
	},
	grades: {
		list: "grades",
		create: "grades/add",
		edit: withParams(
			(gradeId: ObjectId) => `grades/${gradeId}/edit`,
			`grades/:gradeId/edit`
		),
	},
	linkRequests: {
		list: "link-requests/list",
	},
	facebookVideos: "facebook-videos",
	offerReservations: "offer-reservations",
	proposals: "proposals",
	publicTests: {
		courseList: "review",
		review: {
			test: withParams(
				(args: {
					courseId: ObjectId;
					folderId: ObjectId;
					testId: number;
					authorId: number;
					publicTestId: ObjectId;
				}) =>
					`review/${args.courseId}/${args.authorId}/${args.testId}/${args.folderId}/${args.publicTestId}`,
				`review/:courseId/:authorId/:testId/:folderId/:publicTestId`
			),
			course: withParams(
				(courseId: ObjectId) => `review/${courseId}`,
				`review/:courseId`
			),
			courseVerifiedTests: withParams(
				(courseId: ObjectId) => `review/${courseId}/verified`,
				`review/:courseId/verified`
			),
		},
	},
	users: {
		stats: "user-stats",
		bulkDelete: "bulk-delete-users",
		bulkGet: "bulk-get-users",
	},
	students: {
		fixStudentsInfo: "ensure-students-info",
	},
	subjects: {
		list: "subjects",
		create: "subjects/add",
		edit: withParams(
			(subjectId: ObjectId) => `subjects/${subjectId}/edit`,
			`subjects/:subjectId/edit`
		),
	},
	gradebookCategories: {
		list: "gradebook-categories",
		create: "gradebook-categories/add",
		edit: withParams(
			(gradebookGradeCategoryId: ObjectId) =>
				`gradebook-categories/${gradebookGradeCategoryId}/edit`,
			`gradebook-categories/:gradebookGradeCategoryId/edit`
		),
	},
	cities: {
		list: "admin-cities-list",
		add: "admin-cities-list/add",
		edit: withParams(
			(cityId: number) => `admin-cities-list/${cityId}/edit`,
			`admin-cities-list/:cityId/edit`
		),
	},
	schools: {
		list: "admin-schools-list",
		add: "admin-schools-list/add",
		edit: withParams(
			(schoolId: number) => `admin-schools-list/${schoolId}/edit`,
			`admin-schools-list/:schoolId/edit`
		),
	},
});
