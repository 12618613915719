import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const StudentGradeSchema = Joi.object({
	id: Joi.objectId().required(),
	categoryId: Joi.objectId().required(),

	value: Joi.alternatives(
		Joi.number(),
		Joi.boolean(),
		Joi.string()
	).required(),
	seen: Joi.boolean().optional(),

	comment: Joi.object({
		value: Joi.string().required(),
		seen: Joi.boolean().optional(),
	}).optional(),

	reaction: Joi.object({
		value: Joi.string().required(),
		seen: Joi.boolean().optional(),
	}).optional(),
	isDisabled: Joi.boolean().optional(),
	date: Joi.date().required(),
	gradedAt: Joi.date().required(),
});
export interface IStudentGrade {
	id: ObjectId;
	categoryId: ObjectId;
	value: number | string | boolean;
	seen?: boolean;
	comment?: {
		value: string;
		seen?: boolean;
	};
	reaction?: {
		value: string;
		seen?: boolean;
	};
	isDisabled?: boolean;
	date: Date;
	gradedAt: Date;
}
