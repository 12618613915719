import Joi from "@app/utils/joi";
import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
} from "../helper-schemas";
import { SchoolSchema, ISchool } from "./helper-schemas";

export const AGETSchoolSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
});
export interface IAGETSchool {
	id: number;
}

export const RGETSchoolSchema = SchoolSchema;
export type IRGETSchool = ISchool;

///

export const RGETSchoolsSchema = Joi.array().items(SchoolSchema);
export type IRGETSchools = ISchool[];

///

export const AGETSchoolsByCitySchema = Joi.object({
	cityId: Joi.number().required(),
});
export interface IAGETSchoolsByCity {
	cityId: number;
}

export const RGETSchoolsByCitySchema = Joi.array().items(SchoolSchema);
export type IRGETSchoolsByCity = ISchool[];

///

export const APOSTCreateSchoolShema = SchoolSchema.keys(insertStripKeys);
export type IAPOSTCreateSchool = ToInsertKeys<ISchool>;

export const RPOSTCreateSchoolSchema = SchoolSchema;
export type IRPOSTCreateSchool = ISchool;

///

export const APUTUpdateSchoolSchema = SchoolSchema.keys(updateStripKeys);
export type IAPUTUpdateSchool = ToUpdateKeys<ISchool>;

export const RPUTUpdateSchoolSchema = SchoolSchema;
export type IRPUTUpdateSchool = ISchool;

///

export const ADELETESchoolSchema = Joi.object({
	id: Joi.number().required(),
});

export interface IADELETESchool {
	id: number;
}

///

export const APOSTRegisterSchoolSchema = Joi.object({
	city: Joi.number().required(),
	school: Joi.number().required(),
	mail: Joi.string().required(),
	mobile: Joi.string().required(),
});

export interface IAPOSTRegisterSchool {
	city: number;
	school: number;
	mail: string;
	mobile: string;
}

///

export const APOSTImportClassroomSchema = Joi.object({
	city: Joi.number().required(),
	school: Joi.number().required(),
	country: Joi.string().required(),
	webOrigin: Joi.string().required(),
	language: Joi.string().required(),
	uniqueKey: Joi.string()
		.valid("mobile", "mail")
		.required(),
	options: Joi.object({
		ensureCourseUsers: Joi.boolean().required(),
		updateExistingUsersData: Joi.boolean().required(),
		removeOldStudentsFromClassroom: Joi.boolean().required(),
		updatePasswords: Joi.boolean().required(),
	}).required(),
});
export interface IAPOSTImportClassroom {
	city: number;
	school: number;
	country: string;
	language: string;
	webOrigin: string;
	uniqueKey: "mobile" | "mail";
	options: {
		ensureCourseUsers: boolean;
		updateExistingUsersData: boolean;
		removeOldStudentsFromClassroom: boolean;
		updatePasswords: boolean;
	};
}

///

export const AGETManySchoolsByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.number())
		.required(),
});
export interface IAGETManySchoolsByIds {
	ids: number[];
}

export const RGETManySchoolsByIdsSchema = Joi.array().items(SchoolSchema);
export type IRGETManySchoolsByIds = ISchool[];

///

export const AGETExportedClassroomXLSXSchema = Joi.object({
	schoolId: Joi.number()
		.integer()
		.min(0)
		.required(),
});
export interface IAGETExportedClassroomXLSX {
	schoolId: number;
}
