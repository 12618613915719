import { addBasePath, withParams } from "../link-utils";

export const publicLinks = addBasePath("")({
	landingPage: "/",
	login: "login",
	TOU: {
		video: "/open-uni/v",
		certificate: withParams(
			({ code }: { code: string }) => `open-uni/d/${code}`,
			`open-uni/d/:code`
		),
	},
	registration: {
		withCode: "registration-with-code",
	},
	chooseSpeciality: `choose-speciality/:code`,
});
