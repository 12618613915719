import { PopupsNullifier } from "@app/components/widgets/prioritizer";
import { IRootState } from "@app/redux";
import { removeKeys } from "@app/utils/common";
import { History, Path } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	priorityOfEmptyPopup?: number;
	headerProps?: Record<any, any>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const UserOrGuestRoute = (props: IProps) => {
	return (
		<Route {...removeKeys(props as any, "component", "children")}>
			<RouteRenderer {...props} />
		</Route>
	);
};

const RouteRenderer = React.memo(
	({
		component: Component,
		isAuthenticated,
		location,
		user,
		userType,
		priorityOfEmptyPopup,
		headerProps,
		...rest
	}: IProps) => {
		const match = useRouteMatch();
		const history = useHistory();

		return (
			<div>
				{priorityOfEmptyPopup && (
					<PopupsNullifier
						priority={priorityOfEmptyPopup}
						preemptive={true}
					/>
				)}
				<div className="user-page">
					<div className="user-page-without-header">
						<Component
							userType={userType}
							{...rest}
							history={history}
							location={history.location}
							match={match}
							{...match.params}
							userData={user.userData}
						/>
					</div>
				</div>
			</div>
		);
	}
);

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
	userType:
		(state.user.userData &&
			state.user.userData.permissions &&
			state.user.userData.permissions[0]?.userType) ||
		null,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserOrGuestRoute
);
