import { useCallback } from "react";
import { IUserAction } from "@app/api/user-actions/helper-schemas";
import { inject } from "@app/modules";
import { IResourceLoadingInfo, useResourceInfoWithLoading } from "./fetch";
import { useModelDocById } from "m-model-react";

export function useUserActions(
	userId: number | null
): IResourceLoadingInfo<IUserAction> {
	const userAction = useModelDocById(inject("UserActionModel"), userId);
	const fetchResource = useCallback((userId: number | null) => {
		if (!userId) return;
		const UserActionsController = inject("UserActionsController");
		return UserActionsController.getUserAction();
	}, []);
	const resourceInfo = useResourceInfoWithLoading({
		resource: userAction,
		fetchingArg: userId!,
		fetch: fetchResource,
		isIdentificationKnown: userId !== null,
	});
	return resourceInfo;
}
