import React, { useState, useEffect, useMemo } from "react";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useBoolean } from "@app/hooks/general";
import styles from "./styles/tvschool-classroom.module.css";
import classNames from "classnames";
import { getFormattedMessage } from "@app/utils/locale";
import { inject } from "@app/modules";
import { useClassroomsUserId } from "@app/hooks/users";
import { TVSchoolClassroomBox } from "./classrooms";
import { MarginContainer } from "@app/components/ui/containers";
import { ObjectId } from "@app/utils/generics";
import { CourseType } from "@app/api/courses-info/helper-schemas";
import { useModelDocsByIds } from "m-model-react";
import { useTeacherSelectedSchoolId } from "../../contexts/teacher-school";

export const ArchivedClassroomsContainer: React.FC = React.memo(() => {
	const isOpen = useBoolean();
	const userId = useClassroomsUserId();
	const [classroomIds, setClassroomIds] = useState(null as null | ObjectId[]);
	const classrooms = useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds
	);
	const teacherSchoolId = useTeacherSelectedSchoolId();

	const archivedClassroomIds = useMemo(() => {
		if (!classrooms) return null;
		return classrooms
			.filter(
				e =>
					e.isArchived &&
					e.course.type !== CourseType.cognitive &&
					e.matchesSchoolId(teacherSchoolId)
			)
			.map(e => e._id);
	}, [classrooms, teacherSchoolId]);

	useEffect(() => {
		const ClassroomsController = inject("ClassroomsController");
		ClassroomsController.getAll({ teacherId: userId! }).then(classrooms => {
			setClassroomIds(classrooms.map(e => e._id));
		});
	}, [userId]);

	return (
		<div>
			{archivedClassroomIds && archivedClassroomIds.length > 0 && (
				<ArchivedClassroomsLoader
					isOpen={isOpen.value}
					onClick={isOpen.switchValue}
				/>
			)}
			{isOpen.value && archivedClassroomIds && (
				<ArchivedClassroomsDisplay
					archivedClassroomIds={archivedClassroomIds}
					enableArchive={true}
				/>
			)}
			{isOpen.value && <br />}
			{isOpen.value && <br />}
		</div>
	);
});

const ArchivedClassroomsLoader: React.FC<{
	isOpen: boolean;
	onClick: () => void;
}> = React.memo(({ isOpen, onClick }) => {
	return (
		<div>
			<div
				className={classNames(
					styles.archivedClassrooms,
					isOpen
						? styles.archivedClassroomsOpen
						: styles.archivedClassroomsClosed
				)}
				onClick={onClick}
			>
				<span>{getFormattedMessage("classroomsArchive")}</span>
				{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
			</div>
		</div>
	);
});

const ArchivedClassroomsDisplay: React.FC<{
	archivedClassroomIds: ObjectId[];
	enableArchive?: boolean;
}> = React.memo(({ archivedClassroomIds, enableArchive = false }) => {
	return (
		<div>
			<div>
				<MarginContainer
					itemsMargin={20}
					style={{ textAlign: "center" }}
				>
					{archivedClassroomIds.map(e => (
						<TVSchoolClassroomBox
							classroomId={e}
							key={e}
							enableArchive={enableArchive}
						/>
					))}
				</MarginContainer>
			</div>
		</div>
	);
});
