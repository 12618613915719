import { useStoreProperty } from "@app/hooks/store";
import { UserAction } from "@app/models/user-action";
import { inject } from "@app/modules";
import validateSchema from "@app/utils/validate-schema";
import { PromisesKeeperAPI } from "../promises-keeper";
import { IRequest } from "../requests";
import { IUserAction } from "./helper-schemas";
import {
	IAPOSTUserAction,
	IRGETUserAction,
	IRPOSTUserAction,
	RGETUserActionSchema,
	RPOSTUserActionSchema,
} from "./validators";

export class UserActionsController {
	private readonly Request: IRequest;

	private readonly _UserActionModel = inject("UserActionModel");
	private readonly assertAndGetClassroomsUser = inject(
		"assertAndGetClassroomsUser"
	);

	private actionPromises = new PromisesKeeperAPI<number, UserAction>();

	constructor(request: IRequest) {
		this.Request = request;
	}

	async getUserAction(loadFresh = false): Promise<UserAction> {
		const user = this.assertAndGetClassroomsUser();
		if (!loadFresh) {
			const userAction = this._UserActionModel.findByIdSync(user.id);
			if (userAction) {
				return userAction;
			}
		}
		return this.actionPromises.getOrSetPromise(user.id, () =>
			this.Request.send("GET", "/api/user-actions").then(
				(data: IRGETUserAction) => {
					if (data) {
						data = validateSchema(data, RGETUserActionSchema);
						return this._UserActionModel.loadOneSync(data!);
					}
					const doc: IUserAction = {
						userId: user.id,
						actions: {},
						createdAt: new Date(),
						updatedAt: new Date(),
					};
					return this._UserActionModel.loadOneSync(doc);
				}
			)
		);
	}

	async performUserAction(args: IAPOSTUserAction): Promise<UserAction> {
		return this.Request.send("POST", "/api/user-actions/", args, null, {
			responseSchema: RPOSTUserActionSchema,
		}).then((data: IRPOSTUserAction) => {
			return this._UserActionModel.loadOneSync(data);
		});
	}

	async logVisit(): Promise<void> {
		this.Request.send("POST", "/api/user-actions/visit");
	}

	sendTextAfterGrantIsApproved = () => {
		this.Request.send("POST", "/api/user-actions/grant-information");
	};
}
