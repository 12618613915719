import * as React from "react";
import ProgressStyles from "./styles/progress-bar.module.css";
import { useState, useEffect } from "react";
import classNames from "classnames";
interface IProgressBarProps {
	progress: number;
	progressColor?: string;
	progressNumberClassname?: string;
	progressBackgroundClassname?: string;
}
export const ProgressBar: React.FC<IProgressBarProps> = props => {
	const progressColor = !props.progressColor
		? "linear-gradient(45deg, #606dd6, #8e62c1)"
		: props.progressColor;
	const [isProgressShown, setIsProgressShown] = useState(false);
	useEffect(() => {
		const planned = setTimeout(() => setIsProgressShown(true), 0);
		return () => {
			clearTimeout(planned);
		};
	}, []);
	return (
		<div className={ProgressStyles.progressBarAndNumber}>
			<div
				className={
					props.progressNumberClassname
						? props.progressNumberClassname
						: ProgressStyles.progressNumber
				}
			>
				{props.children}
			</div>
			<div
				className={
					props.progressBackgroundClassname
						? props.progressBackgroundClassname
						: ProgressStyles.progressBackground
				}
			>
				<div
					className={ProgressStyles.progressColor}
					style={{
						background: progressColor,
						transition: "1s",
						width: !isProgressShown
							? 0
							: `${props.progress * 100}%`,
					}}
				></div>
			</div>
		</div>
	);
};
