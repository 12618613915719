import {
	AttandanceIcon,
	CurrentGradeIcon,
	DoubleTickIcon,
	MarkIcon,
	WrittenHomeworksIcon,
	LockCutIcon,
} from "@app/icons";
import React from "react";
import { ReactComponent as BrandLogo } from "../profiles/general_components/general_imgs/TVSchoolLogoBlue.svg";
import { RightSide } from "./parent-right-side";
import { ReactComponent as Bell } from "./styles/img/bell.svg";
import styles from "./styles/parent.module.css";

export const TVSchoolParentMainPage: React.FC<{ history: History }> = ({
	history,
}) => {
	const items = [
		{
			label: "მიმდინარე ნიშანი",
			image: <CurrentGradeIcon style={{ width: 120 }} />,
		},
		{
			label: "დასწრება",
			image: <AttandanceIcon style={{ width: 130 }} />,
		},
		{
			label: "დაწერილი დავალება",
			image: <WrittenHomeworksIcon style={{ width: 136 }} />,
		},
	];

	return (
		<div className={styles.bothSideContainer}>
			<div className={styles.parentContainer}>
				<div className={styles.header}>
					<BrandLogo style={{ width: 100 }} />
					<div>
						<Bell style={{ width: 18, marginRight: 7 }} />0
					</div>
				</div>

				<StudentPermission />
				<div className={styles.divLine} />
				<div className={styles.childrenInfoContainer}>
					{items.map((it, i) => (
						<Box key={i} image={it.image} label={it.label} />
					))}
				</div>
			</div>
			<RightSide />
		</div>
	);
};

const StudentPermission: React.FC<{}> = props => {
	return (
		<div className={styles.userPermisionContainer}>
			<div>
				<DoubleTickIcon style={{ width: 85 }} />
				<span>
					მოსწავლის სტატისტიკის სანახავად აუცილებელია უფლების მიღება
					მოსწავლის ანგარიშიდან
				</span>
			</div>
			<div>
				<MarkIcon style={{ width: 60 }} />
				<span> მოთხოვნა გაგზავნილია</span>
				<button>მოთხოვნის განმეორება</button>
			</div>
			<LockCutIcon className={styles.lockIcon} />
		</div>
	);
};

const Box: React.FC<{ image: JSX.Element; label: string }> = props => {
	return (
		<div className={styles.studentInfoBox}>
			<span>{props.image}</span>
			<div>
				<span>{props.label}</span>
				<div />
			</div>
		</div>
	);
};
