import { History } from "history";
import { ObjectId } from "@app/utils/generics";
import { ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { getProgressAffectingItems } from "../lesson-tests";

export const redirectToItem = ({
	history,
	courseId,
	parentFolderId,
	itemId,
	itemType,
}: {
	history: History;
	courseId: ObjectId;
	parentFolderId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}) => {
	if (itemType === ItemType.test) {
		return redirectToTest({
			history,
			courseId,
			parentFolderId,
			testId: itemId,
		});
	}
	if (itemType === ItemType.file) {
		return redirectToFile({
			history,
			courseId,
			parentFolderId,
			fileId: itemId,
		});
	}
	if (itemType === ItemType.folder) {
		return redirectToUnit({
			history,
			courseId,
			folderId: itemId,
		});
	}
};

export const redirectToTest = ({
	history,
	courseId,
	parentFolderId,
	testId,
}: {
	history: History;
	courseId: ObjectId;
	parentFolderId: ObjectId;
	testId: ObjectId;
}) => {
	history.push(
		`/courses/${courseId}/folders/${parentFolderId}/tests/${testId}`
	);
};

export const redirectToFile = ({
	history,
	courseId,
	parentFolderId,
	fileId,
}: {
	history: History;
	courseId: ObjectId;
	parentFolderId: ObjectId;
	fileId: ObjectId;
}) => {
	history.push(
		`/courses/${courseId}/folders/${parentFolderId}/files/${fileId}`
	);
};

export const redirectToUnit = ({
	history,
	courseId,
	folderId,
}: {
	history: History;
	courseId: ObjectId;
	folderId: ObjectId;
}) => {
	history.push(`/courses/${courseId}/lessons/${folderId}/lesson-and-test`);
};

export const redirectToAncestor = async ({
	courseId,
	folderId,
	history,
	itemId,
	itemType,
}: {
	history: History;
	courseId: ObjectId;
	folderId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}) => {
	const CourseFetchingController = inject("CourseFetchingController");
	const data = await CourseFetchingController.loadWholeCourseSkeleton({
		courseId,
		folderId,
	});
	const hierarchy = data.hierarchies.folderHierarchy;
	const writableItems = getProgressAffectingItems(folderId, data.folders);
	const myIndex = writableItems.findIndex(
		e => e.item.type === itemType && e.item.id === itemId
	);
	const hasNextItemWhichIsNotFolder =
		myIndex !== -1 &&
		myIndex < writableItems.length - 1 &&
		writableItems[myIndex + 1].item.type !== ItemType.folder;

	if (hasNextItemWhichIsNotFolder) {
		const nextItem = writableItems[myIndex + 1];
		redirectToItem({
			history,
			courseId,
			parentFolderId: nextItem.parentFolderId,
			itemId: nextItem.item.id,
			itemType: nextItem.item.type,
		});
		return;
	}

	const parentHasFolderItem = writableItems.some(
		e => e.item.type === ItemType.folder
	);
	const parent = hierarchy.parentInfo[folderId];
	redirectToUnit({
		history,
		courseId,
		folderId: !parentHasFolderItem ? parent || hierarchy.rootId : folderId,
	});
};
