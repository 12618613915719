import * as ReactGA from "react-ga";
import { IRUser } from "@app/api/users/helper-schemas";
import { getLocale } from "@app/hooks/intl";
import { IStateUser } from "@app/redux/reducers/user";

interface IEvent {
	category: string;
	action: string;
	label: string;
	value?: number;
}

export default function triggerEvent(
	data: IEvent,
	extraInfo: { [key: string]: any } = {}
) {
	if (process.env.REACT_APP_CURRENT_ENVIRONMENT === "DEV") {
		console.log(data, extraInfo);
	}
	try {
		ReactGA.event(data);
		let eventname = data.action;
		if (data.label) eventname += " - " + data.label;
		(window as any).amplitude.getInstance().logEvent(eventname, {
			...extraInfo,
			env: process.env.REACT_APP_CURRENT_ENVIRONMENT,
			locale: getLocale(),
		});
	} catch (e) {
		console.error(e);
	}
}

export function setUserForAnalytics(user: IRUser | null) {
	try {
		if (!user) {
			clearUserForAnalytics();
			return;
		}
		if ((window as any).amplitude) {
			const amplitude = (window as any).amplitude;
			if (user) {
				amplitude.setUserId(user.id);
				amplitude.getInstance().setUserProperties({
					id: user.id,
				});
			}
		}
		if ((window as any).fcWidget) {
			const fcWidget = (window as any).fcWidget;
			if (user) {
				fcWidget.setExternalId(user.id);
				fcWidget.user.setFirstName(user.firstname);
				fcWidget.user.setLastName(user.lastname);
				fcWidget.user.setLocale(getLocale());
				fcWidget.user.setPhone(user.mobile);
				fcWidget.user.setProperties({
					type: user.permissions?.userType,
				});
			}
		}
	} catch (e) {
		console.error(e);
	}
}

function clearUserForAnalytics() {
	try {
		// (window as any).amplitude.regenerateDeviceId();
		(window as any).amplitude.setUserId(null);
	} catch (e) {
		console.error(e);
	}
}

(window as any).freshChatInitializationCB = () => {
	//
	const fcWidget = (window as any).fcWidget;
	if (!fcWidget) return;
	const user: IStateUser = JSON.parse(localStorage.user || "{}");
	setUserForAnalytics(user.userData || null);
};
