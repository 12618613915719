import { ObjectId } from "@app/utils/generics";
import { IHierarchyInfo } from "@app/services/hierarchy-info/interfaces";
import { IMultipleSelectItem } from "@app/components/widgets/deep-multiple-select";
import { useMemo } from "react";
import { getItemsAndHierarchyWithoutRoot } from "@app/utils/hierarchies";

export const useItemsAndHierarchyWithoutRoot = <
	Item extends { _id: ObjectId; name: string },
	Hierarchy extends IHierarchyInfo = IHierarchyInfo
>(
	items: Item[] | null,
	hierarchyInfo: Hierarchy | null
): {
	items: IMultipleSelectItem<string>[];
	hierarchyInfo: Hierarchy;
} | null => {
	const data = useMemo(() => {
		if (!items || !hierarchyInfo) return null;
		return getItemsAndHierarchyWithoutRoot({
			items: items,
			hierarchyInfo: hierarchyInfo,
		});
	}, [items, hierarchyInfo]);
	return data;
};
