import DoneIcon from "@material-ui/icons/Done";
import classNames from "classnames";
import * as React from "react";

interface ISelectionProps {
	id: string | number;
	isSelected: boolean;
	name: string;
	onChange: (_id: string | number) => void;
	showNameBeforeCheckbox?: boolean;
	containerClassName?: string | string[];
	checkmarkContainerClassName?: string | string[];
	selectedCheckmarkContainerClassName?: string | string[];
	textClassName?: string | string[];
	selectedTextClassName?: string | string[];
}

export const Selection = React.memo((props: ISelectionProps) => {
	const nameComponent = (
		<div
			className={classNames(
				props.textClassName,
				props.isSelected && props.selectedTextClassName
			)}
		>
			{props.name}
		</div>
	);
	return (
		<div
			className={classNames(props.containerClassName)}
			onClick={() => props.onChange(props.id)}
		>
			{props.showNameBeforeCheckbox && nameComponent}
			<div
				className={classNames(
					props.checkmarkContainerClassName,
					props.isSelected &&
						props.selectedCheckmarkContainerClassName
				)}
			>
				{props.isSelected && (
					<div>
						<DoneIcon />
					</div>
				)}
			</div>
			{!props.showNameBeforeCheckbox && nameComponent}
		</div>
	);
});

interface ISelectionWithoutCheckboxProps {
	id: string | number;
	isSelected: boolean;
	name: string;
	onChange: (_id: string | number) => void;
	containerClassName?: string | string[];
	textClassName?: string | string[];
	isSelectedClassname: string;
}

export const SelectionWithoutCheckbox = React.memo(
	(props: ISelectionWithoutCheckboxProps) => {
		return (
			<div
				className={
					!props.isSelected
						? classNames(props.containerClassName)
						: props.isSelectedClassname
				}
				onClick={() => props.onChange(props.id)}
			>
				{props.name}
			</div>
		);
	}
);
