import { MenuOptions } from "@app/components/widgets/hoverable-select";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useClassroomByCourseId } from "@app/hooks/classrooms";
import { useCourseSettings } from "@app/hooks/courses";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import { isTestStudent } from "@app/utils/testing";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { useHistory } from "../../../hooks/front";
import { useBoolean } from "../../../hooks/general";
import { ReactComponent as ExerciseIcon } from "../styles/img/exercise.svg";
import { ReactComponent as HomeworkIcon } from "../styles/img/homework.svg";
import { ReactComponent as LessonIcon } from "../styles/img/lesson.svg";
import { ReactComponent as SettingsIcon } from "../styles/img/settings.svg";
import { ReactComponent as UserIcon } from "../styles/img/user.svg";
import HeaderStyles from "./styles/header.module.css";
import { ReactComponent as BurgerIcon } from "./styles/img/burger-icon.svg";
import { ReactComponent as MedalIcon } from "../../landing/styles/img/medal.svg";

export const HeaderOptions: React.FC<{
	courseId: ObjectId | null;
	isPrimariOptionsInvisible?: boolean;
	isMobile?: boolean;
}> = props => {
	const loc = useLocation();

	const showValidateNumeberNotification = useMemo(
		() => !loc.pathname.includes("profile"),
		[loc.pathname]
	);

	const showValidateNumeberTextNotification = useMemo(
		() => loc.pathname === "/",
		[loc.pathname]
	);
	return (
		<div className={HeaderStyles.iconsAndNamesContainer}>
			{!props.isPrimariOptionsInvisible && (
				<PrimaryOptions courseId={props.courseId} />
			)}
			<div>
				<StudentOptions
					courseId={props.courseId}
					isMobile={props.isMobile}
					showValidateNumeberNotification={
						showValidateNumeberNotification
					}
					showValidateNumeberTextNotification={
						showValidateNumeberTextNotification
					}
				/>
			</div>
		</div>
	);
};

interface IHeaderOptionsProps {
	courseId: ObjectId | null;
}

// eslint-disable-next-line max-lines-per-function
export const PrimaryOptions: React.FC<IHeaderOptionsProps> = ({ courseId }) => {
	const history = useHistory();
	const classroom = useClassroomByCourseId(courseId);
	const courseSettings = useCourseSettings(courseId);
	const goToHowItWorks = useCallback(() => {
		history.push(`/how-student-page-works`);
		console.log(history);
	}, [history]);
	const gotoAllLessons = useCallback(() => {
		triggerEvent({
			category: "Button",
			action: "goto all lessons",
			label: "from header",
		});
		if (!courseId) return;
		history.push(`/courses/${courseId}/lessons`);
	}, [courseId, history]);
	const gotoAllAssignments = useCallback(() => {
		triggerEvent({
			category: "Button",
			action: "goto all assignments",
			label: "from header",
		});
		if (!courseId) return;
		history.push(`/courses/${courseId}/assignments`);
	}, [courseId, history]);
	const gotoAllExercises = useCallback(() => {
		triggerEvent({
			category: "Button",
			action: "goto all exercises",
			label: "from header",
		});
		if (!courseId) return;
		history.push(`/courses/${courseId}/exercises`);
	}, [courseId, history]);

	return (
		<div className={HeaderStyles.studentHeaderTasksContainer}>
			{getCurrentWebsite() === WebsiteOrigin.hubu && (
				<div
					className={HeaderStyles.profOrientTest}
					onClick={() => history.push(`/prof-test`)}
					style={{
						backgroundColor: "#5273e6",
						border: "2px solid white",
						borderTop: "none",
						color: "white",
					}}
				>
					<MedalIcon width={25} height={25} />
					<div>
						<FormattedMessage id="profOrientationTest" />
					</div>
				</div>
			)}
			<div className={HeaderStyles.homework} onClick={goToHowItWorks}>
				<div className={HeaderStyles.icon}>
					<SettingsIcon fill="#5273e6" />
				</div>

				<div>
					<FormattedMessage id="howItWorks.title" />
				</div>
			</div>
			{classroom.isIdentificationKnown && (
				<div
					className={HeaderStyles.homework}
					onClick={gotoAllAssignments}
				>
					<div className={HeaderStyles.icon}>
						<HomeworkIcon />
					</div>

					<div>
						<FormattedMessage id="student:assignments" />
					</div>
				</div>
			)}
			<div className={HeaderStyles.homework} onClick={gotoAllLessons}>
				<div className={HeaderStyles.icon}>
					<LessonIcon />
				</div>

				<div>
					<FormattedMessage id="student:lessons" />
				</div>
			</div>
			{!courseSettings.doc?.hideTaskTypes && (
				<div
					className={HeaderStyles.homework}
					onClick={gotoAllExercises}
				>
					<div className={HeaderStyles.icon}>
						<ExerciseIcon />
					</div>

					<div>
						<FormattedMessage id="student:exercises" />
					</div>
				</div>
			)}
			{/* <RedirectToBlogMainPage classname={HeaderStyles.blog} /> */}
		</div>
	);
};

enum UserOption {
	profile,
	logout,
	faq,
}

const useStudentHeaderOptions = (isFaqAndNotProfile?: boolean) => {
	const locale = useLocale();
	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: isFaqAndNotProfile ? UserOption.faq : UserOption.profile,
				label: isFaqAndNotProfile
					? getFormattedMessage("faq")
					: getFormattedMessage("profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[locale]
	);
};

const useStudentHeaderOptionsWithFaq = () => {
	return useMemo(
		(): {
			value: UserOption;
			label: JSX.Element | string;
		}[] => [
			{
				value: UserOption.faq,
				label: getFormattedMessage("faq"),
			},
			{
				value: UserOption.profile,
				label: getFormattedMessage("profile"),
			},
			{
				value: UserOption.logout,
				label: getFormattedMessage("logout"),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
};

export const StudentOptions: React.FC<{
	courseId: ObjectId | null;
	isMobile?: boolean;
	isFaqAndNotProfile?: boolean;
	icon?: JSX.Element;
	showValidateNumeberNotification?: boolean;
	showValidateNumeberTextNotification?: boolean;
}> = props => {
	const user = useClassroomsUser()!;
	const history = useHistory();
	const {
		value: isOptionsVisible,
		setTrue: showOptions,
		setFalse: hideOptions,
	} = useBoolean(false);

	const hasNotVarifiedMobile =
		useLocale() === "ka" && !user.isValidMobile && isTestStudent(user.id);

	const isTvschool = getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const userHeaderOptions = useStudentHeaderOptions(props.isFaqAndNotProfile);
	const tvschoolUserHeaderOptions = useStudentHeaderOptionsWithFaq();

	const onLogout = useCallback(() => {
		const AuthController = inject("AuthController");
		AuthController.logout()
			.then()
			.catch(() => {});
		history.push(`/`);
	}, [history]);

	const onGoToProfile = useCallback(() => {
		if (props.courseId) {
			history.push(`/profile?courseId=${props.courseId}`);
		} else {
			history.push(`/profile`);
		}
	}, [history, props.courseId]);
	const gotoFAQPage = useCallback(() => {
		history.push(`/faq`);
	}, [history]);

	const onSelect = useCallback(
		(option: { value: UserOption; label: JSX.Element | string }) => {
			if (option.value === UserOption.profile) {
				onGoToProfile();
			} else if (option.value === UserOption.logout) {
				onLogout();
			} else if (option.value === UserOption.faq) {
				gotoFAQPage();
			}
		},
		[onGoToProfile, onLogout]
	);

	const menuStyles = useMemo(
		(): React.CSSProperties => ({
			right: 0,
			width: "max-content",
			fontFamily: "Roboto Geo Nus",
			color: "#5273e6",
			minWidth: 150,
			fontSize: 14,
		}),
		[]
	);

	const showValidateNumeberTextNotification =
		props.showValidateNumeberTextNotification && !isOptionsVisible;

	return (
		<div>
			<div onMouseEnter={showOptions} onMouseLeave={hideOptions}>
				{props.icon ? (
					<div onClick={showOptions}> {props.icon} </div>
				) : (
					<div
						className={
							isTvschool
								? HeaderStyles.burgerIconTvSchool
								: HeaderStyles.userIcon
						}
						onClick={
							isTvschool && props.isMobile
								? showOptions
								: onGoToProfile
						}
						style={
							isTvschool && props.isMobile
								? { backgroundColor: "none" }
								: {
										backgroundColor: !isOptionsVisible
											? "#5273e6"
											: "white",
								  }
						}
					>
						{props.isMobile && isTvschool ? (
							<BurgerIcon />
						) : (
							<>
								<UserIcon
									style={{
										fill: isOptionsVisible
											? "#5273e6"
											: "white",
									}}
								/>
							</>
						)}
					</div>
				)}

				{isOptionsVisible && (
					<MenuOptions
						options={
							props.isMobile && isTvschool
								? tvschoolUserHeaderOptions
								: userHeaderOptions
						}
						onSelect={onSelect}
						style={menuStyles}
					/>
				)}
			</div>
			{hasNotVarifiedMobile && props.showValidateNumeberNotification && (
				<div
					className={HeaderStyles.alertIconContainer}
					onMouseEnter={hideOptions}
					onClick={onGoToProfile}
				>
					!
				</div>
			)}
			{hasNotVarifiedMobile && showValidateNumeberTextNotification && (
				<div className={HeaderStyles.mobileVerificationWarning}>
					გთხოვთ, დაადასტუროთ მობილურის ნომერი{" "}
				</div>
			)}
		</div>
	);
};
