import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import memoizeOne from "memoize-one";
import {
	addButtonClassName,
	IItemEditEditProps,
	ItemEdit,
	itemEditContainerClassName,
} from "../common/items-edit";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	Omit,
	OptionalKeys,
} from "../../../../utils/generics";
import { ContentType } from "../../../../schemas/questions/contnets/common-schemas";
import { css } from "emotion";
import {
	EditorText,
	mergeComponentObjects,
	mergeStylesObjects,
} from "../../../../utils";
import { getStatsFromText } from "../../../../utils/common";
import { HANDLED, NOT_HANDLED } from "../../../editor";
import { IContentEditor, IContentEditorProps } from "../interfaces";
import { IEditCommonProps } from "../edit";
import { IProps as SITestMode, SortItemsContentCont } from "./";
import {
	ISortItemsContent,
	ISortItemsUserAns,
} from "../../../../schemas/questions/contnets/sort-items/schema";
import { ItemAfterLabel } from "../common/items";

interface IChoiceTexts {
	placeholder: string;
	delete: string;
	add: string;
}

interface IStatementTexts {
	placeholder: string;
}

interface IExplanationTexts {
	placeholder: string;
}

interface ITexts {
	items?: IChoiceTexts;
	statement?: IStatementTexts;
	explanation?: IExplanationTexts;
}

type IProps = Partial<IEditCommonProps> &
	Omit<
		SITestMode,
		| ("content" | "disableEditingAnswer")
		| ("displayAnswer" | "onUserAnswerChange")
	> & {
		content: ISortItemsContent;
		toHTML: (editorState: EditorText) => string;
		toEditorState: (html: string) => EditorText;
		texts?: ITexts;
		components?: {
			items?: {
				innerItem?: {
					text?: AnyComponent<IItemEditEditProps>;
				};
			};
		};
	} & IContentEditorProps;

export type ISIEditPassableProps = OptionalKeys<
	Omit<IProps, "content">,
	("generateUniqueIds" | "texts") | "toEditorState" | "toHTML"
>;

interface IState {
	items: (Omit<ISortItemsContent["items"][number], "text"> & {
		text: EditorText;
	})[];
	statement: Omit<ISortItemsContent["statement"], "text"> & {
		text: EditorText;
	};
	correctOrder: ISortItemsContent["correctOrder"];
	explanation?: Omit<
		NotUndefined<ISortItemsContent["explanation"]>,
		"text"
	> & { text: EditorText };
}

const defaultLabelClassName = css({
	cursor: "initial !important",
	border: "1px solid #ccc",
	borderRadius: 20,
	paddingRight: 5,
	margin: "5px 0",
	"&:focus-within": {
		boxShadow: "0 0 2px rgba(0,0,0,0.4)",
	},
	".DraftEditor-root": {
		minWidth: 200,
	},
});

class SortItemsEditor extends React.PureComponent<IProps, IState>
	implements IContentEditor<ISortItemsContent> {
	defaultTexts = {
		items: {
			delete: "Delete item",
			add: "Add item",
			placeholder: "Enter item",
		},
		statement: {
			placeholder: "Enter statement",
		},
		explanation: {
			placeholder: "Enter explanation",
		},
	} as NotUndefinedAtAll<IProps["texts"]>;

	defaultStyles = {
		items: {
			innerItem: {
				label: defaultLabelClassName,
				container: {
					general: css`
						display: inline-flex;
						border: none !important;
					`,
				},
			},
		},
		statement: {
			container: itemEditContainerClassName,
		},
		explanation: {
			container: itemEditContainerClassName,
		},
	} as NotUndefined<IProps["styles"]>;

	getTexts = memoizeOne((texts: IProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	getStyles = memoizeOne((styles: IProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	getComponents = memoizeOne(
		(
			components: IProps["components"],
			texts: NotUndefined<IProps["texts"]>
		) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents(texts)
			);
		}
	);

	constructor(props: IProps) {
		super(props);
		this.state = {
			statement: {
				...props.content.statement,
				text: this.props.toEditorState(props.content.statement.text),
			},
			items: props.content.items.map(e => ({
				...e,
				text: this.props.toEditorState(e.text),
			})),
			correctOrder: props.content.correctOrder,
			explanation: props.content.explanation
				? {
						...props.content.explanation,
						text: this.props.toEditorState(
							props.content.explanation.text
						),
				  }
				: undefined,
		};
	}

	componentDidMount() {
		if (!this.state.explanation) {
			this.setState({
				explanation: {
					id: this.props.generateUniqueIds()[0],
					text: this.props.toEditorState(""),
				},
			});
		}
	}

	defaultComponents = (texts: NotUndefined<IProps["texts"]>) =>
		({
			statement: {
				text: (props: IItemEditEditProps) => (
					<ItemEdit
						{...props}
						onChange={this.onStatementChange}
						placeholder={texts.statement!.placeholder}
						galleryComponent={this.props.galleryComponent}
						onPaste={this.onPaste}
					/>
				),
			},
			items: {
				innerItem: {
					text: (props: IItemEditEditProps) => (
						<ItemEdit
							{...props}
							onChange={this.onItemChange}
							placeholder={texts.items!.placeholder}
							galleryComponent={this.props.galleryComponent}
						/>
					),
					containerAfter: props => (
						<ItemAfterLabel
							{...props}
							onDelete={this.onItemDelete}
							deleteText={texts.items!.delete}
						/>
					),
				},
				after: () => (
					<button
						onClick={this.onAddItem}
						className={addButtonClassName}
					>
						<AddIcon />
						<span>{texts.items!.add}</span>
					</button>
				),
			},
			container: SortItemsContentCont,
			explanation: props => (
				<>
					{props.explanation && (
						<ItemEdit
							stat={props.explanation as any}
							onChange={this.onExplanationChange}
							styles={props.styles}
							placeholder={texts.explanation!.placeholder}
							galleryComponent={this.props.galleryComponent}
						/>
					)}
				</>
			),
		} as NotUndefined<IProps["components"]>);

	onPaste = (text: string): HANDLED | NOT_HANDLED => {
		try {
			if (
				this.props.toHTML(this.state.statement.text).length === 0 &&
				this.state.items.every(
					ch => this.props.toHTML(ch.text).length === 0
				)
			) {
				// empty statement and choices. time to check whether there is some template
				const res = getStatsFromText(text);
				if (!res) return NOT_HANDLED;
				const ids = this.props.generateUniqueIds(res.choices.length);
				this.setState({
					statement: {
						...this.state.statement,
						text: this.props.toEditorState(res.stat),
					},
					items: ids.map((choId, index) => ({
						...this.state.items[index],
						id: choId,
						text: this.props.toEditorState(res.choices[index]),
					})),
					correctOrder: ids,
				});
				return HANDLED;
			}
		} catch (e) {
			return NOT_HANDLED;
		}
		return NOT_HANDLED;
	};

	getData = () => {
		const expText = this.state.explanation
			? this.props.toHTML(this.state.explanation.text)
			: undefined;
		return {
			...this.state,
			type: ContentType.SortItems as ContentType.SortItems,
			statement: {
				...this.state.statement,
				text: this.props.toHTML(this.state.statement.text),
			},
			items: this.state.items.map(e => ({
				...e,
				text: this.props.toHTML(e.text),
			})),
			explanation: expText
				? { ...this.state.explanation!, text: expText }
				: undefined,
		};
	};

	getErrors = () => [];

	onItemChange = ({ id, text }: { id: number; text: EditorText }) => {
		this.setState({
			items: this.state.items.map(c =>
				c.id === id ? { ...c, text } : c
			),
		});
	};

	onOrderChange = (newOrder: ISortItemsUserAns) => {
		this.setState({
			correctOrder: newOrder!,
		});
	};

	onItemDelete = (id: number) => {
		this.setState({
			items: this.state.items.filter(c => c.id !== id),
		});
	};

	getCurrentIds = () => {
		const ids: number[] = [];
		ids.push(this.state.statement.id);
		this.state.items.forEach(e => ids.push(e.id));
		if (this.state.explanation) ids.push(this.state.explanation.id);
		return ids;
	};

	onAddItem = () => {
		const [id] = this.props.generateUniqueIds();
		this.setState({
			items: [
				...this.state.items,
				{
					id,
					text: this.props.toEditorState("") as any,
				},
			],
			correctOrder: [...this.state.correctOrder, id],
		});
	};

	onStatementChange = (stat: EditorText) => {
		this.setState({
			statement: stat,
		});
	};

	onExplanationChange = (stat: EditorText) => {
		this.setState({
			explanation: stat,
		});
	};

	render() {
		const styles = this.getStyles(this.props.styles);
		const texts = this.getTexts(this.props.texts);
		const components = this.getComponents(this.props.components, texts);
		const QContainer = components.container!;
		const content = {
			...this.props.content,
			items: this.state.items,
			statement: this.state.statement,
			correctOrder: this.state.correctOrder,
			explanation: this.state.explanation,
		};
		return (
			<div>
				<QContainer
					content={content as any}
					components={components}
					onUserAnswerChange={this.onOrderChange}
					userAnswer={this.state.correctOrder}
					styles={styles}
					displayAnswer={true}
					disableEditingAnswer={false}
					hideViewMode={true}
					shuffleKey={0}
				/>
			</div>
		);
	}
}

export default SortItemsEditor;
